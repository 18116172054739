import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import { GetScheduleA } from '../../Actions/StudentA';
import ClassScheduler from '../../Components/Scheduler/Scheduler'
import useCheckLogin from '../../Helpers/CustomHooks/CheckLogin';
import { GetLocalStorage } from '../../Helpers/LocalStorage/LocalStorage';
import MyCourses from '../../Components/MyCourses/MyCourses';
import { EnrolledCourseButtonsInfo } from '../../PageNames';
import { GetInstituteInstructorsA } from '../../Actions/InstructorA';
import { CreateScheduleA, UpdateScheduleA } from '../../Actions/StudentA';
import { RRule, RRuleSet, rrulestr } from "rrule";

const ClassesSchedule = () => {
    const { EnrollmentId } = useParams()
    const { UserInfo } = useSelector(Store => Store.LoginSignupReducer);
    const { Schedule } = useSelector(Store => Store.StudentReducer);
    const [Events, setEvents] = useState([]);
    const [StudentPrefrredTimeTable, setStudentPrefrredTimeTable] = useState([]);
    const [newUpdatedEvents, setNewUpdatedEvents] = useState([]);
    const { Instructors, loading } = useSelector(
        (store) => store.InstructorReducer
    );
  console.log("EnrollmentId in params is:",EnrollmentId)
    const Dispatch = useDispatch()
    useEffect(() => {
        if (EnrollmentId){
            Dispatch(GetScheduleA(EnrollmentId))
        }
        // Define dummy filters with default values
        const dummyFilters = {
            sortByTime: "asc", // or "desc" depending on your needs
            sortBy: "name", // or any other field you want to sort by
            searchQuery: "", // Use search prop or an empty string
            suspend: "false", // or any other value required
        };

        // Call the action with the dummy filters
        Dispatch(GetInstituteInstructorsA(dummyFilters));
       /*  Dispatch(GetInstituteInstructorsA()); */
    }, [Dispatch, EnrollmentId])
    console.log("Instructors fetched are",Instructors)

    useEffect(() => {
        const EditableSchedule = JSON.parse(JSON.stringify(Schedule))
        setEvents(EditableSchedule.map(Value => {
            Value.endDate = new Date(Value.endDate)
            Value.startDate = new Date(Value.startDate)
            return Value
        }))
    }, [Schedule])

    const ClassType = [
        { id: "Driving", text: "Driving" },
        { id: "Online", text: "Online" },
        { id: "InClass", text: "InClass" },
      ];


    useCheckLogin(true, ["Student", "Institute"], ["Admin", "Staff"]);

    useEffect(() => {
        return () => {
          console.log("newUpdatedEvents in scheduler are:",newUpdatedEvents)
        }
      }, [newUpdatedEvents])

    const SubmitSchedule=() =>
        SubmitData(
        newUpdatedEvents,
        Dispatch,
        Instructors,
        false,
        EnrollmentId,
        )
     

    return (
        <MyCourses ButtonsInfo={EnrolledCourseButtonsInfo} PageName="UpcomingClasses" Param={EnrollmentId}>
            <div>
                {Events.length >= 0
                    ? <ClassScheduler Name={UserInfo?.FirstName + " " + UserInfo?.LastName}
                        Events={Events} setEvents={setEvents}
                        Edit={GetLocalStorage("UserInfo")?.User === "Institute"}
                        setPrefrredTimeTable={setStudentPrefrredTimeTable}
                        Resources={[
                            { fieldExpr: "Instructors", dataSource: [...Instructors] },
                           
                        ]}
                        ClassType={[
                            { fieldExpr: "ClassType", dataSource: [...ClassType] },
                        ]}
                        setNewUpdatedEvents={setNewUpdatedEvents}
                    />
                    : null
                }

            </div>

            <div className='w-full flex justify-center mt-[-40px]'>
            <button type="button" 
              className="rounded-[10px] bg-[#4C34AD] font-poppinssans w-[282px]  h-[48px] 2.5xl:h-[50px] text-[20px] 2.5xl:text-[24px] text-[#FFFFFF] px-[10px]"
            onClick={() => SubmitSchedule()}>
               Update Schedule
            </button>
            </div>
        </MyCourses>
    )
}

// const ClassesSchedule = () => <MyCourses ButtonsInfo={EnrolledCourseButtonsInfo} PageName="UpcomingClasses" Component={ClassesSchedule} />

export default ClassesSchedule

//expand recurring rule events;
function expandRecurringEvents(events) {
    let expandedEvents = [];
  
    events.forEach((event) => {
      if (event.recurrenceRule) {
        // Parse the recurrence rule
        let rule = rrulestr(`RRULE:${event.recurrenceRule}`, {
          dtstart: new Date(event.startDate),
        });
  
        // Generate occurrences
        let occurrences = rule.all();
  
        occurrences.forEach((date) => {
          let newEvent = { ...event };
          newEvent.startDate = new Date(date);
          let endDate = new Date(date);
          endDate.setMinutes(
            endDate.getMinutes() +
              (new Date(event.endDate) - new Date(event.startDate)) / 60000
          );
          newEvent.endDate = new Date(endDate);
          delete newEvent.recurrenceRule;
          expandedEvents.push(newEvent);
        });
      } else {
        expandedEvents.push(event);
      }
    });
  
    return expandedEvents;
  }

const SubmitData = (
    newUpdatedEvents,
    Dispatch,
    Instructors,
    OneInstructor,
    EnrollmentId,
    Student
  ) => {
    const InsId = GetLocalStorage("UserInfo")?.Institute?.InstituteId;
    console.log("EnrollmentId in the submit data is:",EnrollmentId)
      // Ensure EnrollmentId is correctly assigned
  newUpdatedEvents = newUpdatedEvents.map((event) => {
    if (!event.EnrollmentFK) {
      event.EnrollmentFK = EnrollmentId;
    }
    return event;
  });
    console.log("newUpdatedEvents in the submit data is:",newUpdatedEvents)
    let AssignedInstructors = [];
  
    Instructors.forEach((element) => {
      // console.log("element",element)
      if (!OneInstructor && element?.InstructorFK)
        AssignedInstructors.push({
          InstructorFK: element && element?.InstructorFK,
          EnrollmentFK: EnrollmentId,
        });
      else if (
        OneInstructor &&
        element &&
        element.InstructorType === "Driving" &&
        element?.InstructorFK
      )
        AssignedInstructors.push({
          InstructorFK: element?.InstructorFK,
          EnrollmentFK: EnrollmentId,
        });
    });
  
    console.log("AssignedInstructors=>", AssignedInstructors);
  
    const UserEmail = Student?.Email;
    const UserFirstName = Student?.FirstName;
    const UserLastName = Student?.LastName;
  
    newUpdatedEvents = newUpdatedEvents.map((value) => {
      value.EnrollmentFK = EnrollmentId;
      return value;
    });
  
    let expandedEvents = expandRecurringEvents(newUpdatedEvents);
  
    console.log("Expanded Events", expandedEvents);
    console.log("Events2", newUpdatedEvents);
  
    Dispatch(
        UpdateScheduleA({
        Events: newUpdatedEvents,
      })
    );
  };