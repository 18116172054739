import React, {useEffect, useState} from "react";
import SideBar from "../SideBar/SideBar";
import { useAuthContext } from "../../context/authContext";
import ForgotPassword from "../../popups/forget-password/ForgotPassword";
import OtpPopup from "../../popups/otpPopup/OtpPopup";
import NewPassword from "../../popups/newPassword/NewPassword";
import "./InstituteTemplate.css";
import { BaseUrl } from "../../Actions/Base";
import { GetLocalStorage } from "../../Helpers/LocalStorage/LocalStorage";
import { useLocation } from 'react-router-dom';


const InstituteTemplate = ({ Element, m_top, bg, mt, HideSideBar }) => {
  const { showSidebar, setShowSidebar, hideSidebar, setHideSidebar } =
    useAuthContext();
  const {
    setChangePassPopup,
    changePassPopup,
    changeOtpPopup,
    ChangeToNewPassWord,
  } = useAuthContext();

  if (!m_top) m_top = "mt-20";
  if (!bg) bg = "bg-white";
  let Styles = !HideSideBar
    ? "ml-0 sm:ml-[250px] sm:w-[calc(100%-250px)] max-w-[1900px]"
    : "";
  Styles = hideSidebar
    ? ""
    : "ml-0 sm:ml-[250px] sm:w-[calc(100%-250px)] max-w-[1900px]";

  const sidebarClick = () => {
    setShowSidebar(true);
    setHideSidebar(false);
  };


  const localStorage = GetLocalStorage('UserInfo')
  const bgImage = `url(${BaseUrl}/api/Images/InstituteLogo?url=${localStorage?.Institute?.InstituteLogo})`;

  const location = useLocation();
  const currentPath = location.pathname;

  // Define the path pattern for the specific route
  const isInVehicleClassRoute = /^\/in-vehicle-class\/[^/]+$/;

  // State to track screen size
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024); // lg breakpoint (1024px)

  useEffect(() => {
    // Update isDesktop state on window resize
    const handleResize = () => setIsDesktop(window.innerWidth >= 1024);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Conditionally show sidebar based on route, screen size, and HideSideBar
  const shouldShowSidebar = !HideSideBar && (!isInVehicleClassRoute.test(currentPath) || !isDesktop);

  return (
    <>
      {changePassPopup ? <ForgotPassword /> : null}
      {changeOtpPopup ? <OtpPopup /> : null}
      {ChangeToNewPassWord ? <NewPassword /> : null}

      {/* {!HideSideBar ? <SideBar /> : null} */}
      {shouldShowSidebar ? <SideBar /> : ''}
      
      <div
        className={`flex ${bg} relative flex-col ${m_top} w-full ${Styles}  mt-${mt} mx-auto`}
      >
        {/* <div className="w-full h-screen fixed -z-10"
                style={{ backgroundImage: bgImage, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundSize: '100%', backgroundPosition : 'fixed' }}

        >

        </div> */}
        {!showSidebar && (
          <>
            <div
              onClick={sidebarClick}
              className="icon-containerr flex  sm:hidden fixed left-[-20px] top-[3rem] z-[34234234234]  h-[55px] w-[55px] cursor-pointer items-center justify-center rounded-full border-4 border-solid border-white bg-[#4C34AD]"
            >
              <span class="material-symbols-outlined relative z-[34234234234] ml-2 cursor-pointer text-white">
                arrow_forward_ios
              </span>
            </div>
          </>
       )}  
       {hideSidebar && ( 
          <>
            <div
              onClick={sidebarClick}
              className="icon-containerr hidden sm:flex fixed left-[-20px] top-[3rem] z-[34234234234]  h-[55px] w-[55px] cursor-pointer items-center justify-center rounded-full border-4 border-solid border-white bg-[#4C34AD]"
            >
              <span class="material-symbols-outlined relative z-[34234234234] ml-2 cursor-pointer text-white">
                arrow_forward_ios
              </span>
            </div>
          </>
       )}  
       
        {/* <div className="fixed z-[1] opacity-5 h-screen w-full"><img className="w-full h-full object-contain" src={`${BaseUrl}/api/Images/InstituteLogo?url=${localStorage?.Institute?.InstituteLogo}`} alt="" /></div> */}
        <Element />
      </div>
      
    </>
  );
};
export default InstituteTemplate;
