// react States
import React, { Component } from "react";
import { Link } from "react-router-dom";

// Images
import addCourse3HeadImg from "./Assets/addCourse3HeadImg.png";

// components

import VehicleDetailTiles from "./components/VehicleDetailTiles.jsx";

// css
import "./SelectVehicle.css";
import "../.././ownBootstrap.css";

const SelectVehicle = ({
  Steps,
  setSteps,
  CourseData,
  setCourseData,
  StepsIndicator,
  PageNo,
  OnClickBack,
  OnClickSteps,
  CourseLicense,
  CourseInfo,
  update,
}) => {
  return (
    <div
      className={`addCoursesMainContainer ${Steps !== PageNo ? "hidden" : ""}`}
    >
      <div className="row addCourse1">
        <div className="col-12">
          <div className="mainContainerAddCourse">
            <StepsIndicator Steps={Steps} OnClickSteps={OnClickSteps} />
            <div className="mainContainer1White">
              {/* <center className="descriptionIconTxt addCOurse3Desc">
                                <img
                                    src={addCourse3HeadImg}
                                    style={{ width: "90px" }}
                                    alt="addCourse3HeadImg"
                                />
                                <h6 className="SemiBold">Select Vehicle</h6>
                            </center> */}
              <h2 className="mt-10  text-[28px] font-semibold text-[#4C34AD]">
                Select Vehicles
              </h2>
              <div className=" mt-5 flex flex-col   rounded-[10px] border border-solid border-[#D4D4D4] px-20 py-5">
                <VehicleDetailTiles
                  update={update}
                  CourseInfo={CourseInfo}
                  CourseData={CourseData}
                  setCourseData={setCourseData}
                  setSteps={setSteps}
                />
              </div>
            </div>
          </div>
          {/* <center>
                        <button
                            className="SaveAndContinueBtn cursor-pointer"
                            type="button"
                            onClick={() => setSteps(5)}
                        >
                            Save & Continue
                        </button>
                    </center> */}

          {!update && (
            <div className="flex items-center justify-center gap-5">
              <button
                className="SaveAndContinueBtn w-[12%]  cursor-pointer"
                type="button"
                onClick={() => OnClickBack(Steps)}
              >
                Back
              </button>
              <button
                className="SaveAndContinueBtn  cursor-pointer"
                type="button"
                onClick={() => setSteps(5)}
              >
                Save & Continue
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectVehicle;
