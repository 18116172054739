// react States
import React, { useState } from "react";
import "../coursePackaging.css";
const PackageInstallments = ({ Packages, setPackages, update }) => {
  const handleEditInstallment = () => {
    window.scrollTo(0, 0);
    let element = document.getElementById("installmentsEdit");

    if (element.style.display === "initial") {
      element.style.display = "none";
    } else {
      element.style.display = "initial";
    }
  };

  const [SPackage, setSPackage] = useState({});

  const OpenInstallmentWindow = (value, index) => {
    setSPackage({ ...value, Index: index });
    handleEditInstallment();
  };

  return (
    <>
      <div className="packageTable">
        <h2 className="font-semibold text-[#4C34AD]">Installments</h2>

        {/* <table className="addCourseTable addCourseTable2 table-fixed"> */}
        <table
          className="addCourse4Table "
          style={{
            background:
              "transparent linear-gradient(114deg, #ffffff 0%, #f6f6ff 14%, #d6d8ff 100%) 0% 0%no-repeat padding-box",
          }}
        >
          <thead>
            <tr className="">
              <th className="w-[45%] py-3 ">Package Names</th>
              <th className="w-[12%]  py-3">Fee</th>
              <th className="w-[12%]  py-3">Final Fee</th>
              <th className="w-[12%]  py-3">Installments</th>
              <th className="w-[19%] max-w-[246px] py-3">Payment Schedule</th>
              <th className="w-[10%]">Update</th>
            </tr>
          </thead>
          <tbody>
            {Packages.length > 0
              ? Packages?.map((value, index) => {
                  let totalFee = parseFloat(value.TotalFee);
                  let installments = parseFloat(value.Installments);
                  if (value.AddDiscount) {
                    let discountPercentage = parseFloat(
                      value.AddDiscount.replace("%", "")
                    );
                    let discountAmount = (totalFee * discountPercentage) / 100;
                    totalFee -= discountAmount;
                  }
                  let installmentsAmount = totalFee / installments;
                  if (value?.Installments === 1) return;
                  return (
                    <tr
                      className="border-0 border-b border-solid border-[#A5C9FF] p-2 py-3 "
                      key={index + Math.random()}
                    >
                      <td className=" ">
                        {/* {value?.DrivingHours} Hours
                                              Driving, {value?.InClassHours}{" "}
                                              Hours In Class,{" "}
                                              {value?.OnlineHours}
                                              Hours Online for {value?.TotalFee}
                                              $ */}
                        <div className="flex items-center justify-center">
                          {value.PackageName}
                        </div>
                      </td>

                      <td className=" ">
                        <div className="flex items-center justify-center">
                          {value.TotalFee}$
                        </div>
                      </td>
                      <td className=" ">
                        <div className="flex items-center justify-center">
                          {totalFee}$
                        </div>
                      </td>
                      <td className=" ">
                        <div className="flex items-center justify-center">
                          {value.Installments}
                        </div>
                      </td>
                      <td className="w-[19%] max-w-[246px]">
                        <div className="flex items-center justify-center">
                          {value?.AddDiscount !== "" ? (
                            <>
                              {value.InstallmentSchedule}, {totalFee}$,
                              {installmentsAmount}$
                            </>
                          ) : (
                            <>
                              {value.InstallmentSchedule}, {value.TotalFee}$,
                              {parseFloat(
                                Number(value.TotalFee) /
                                  Number(value.Installments)
                              ).toFixed(2)}
                              $
                            </>
                          )}
                        </div>
                      </td>
                      <td
                      //   className="statusAddCourseTableBtn"
                      //   onClick={() =>
                      //       OpenInstallmentWindow(
                      //           value,
                      //           index
                      //       )
                      //   }
                      >
                        <div
                          className="statusAddCourseTableBtn px-5 text-center "
                          onClick={() => {
                            if (!update) OpenInstallmentWindow(value, index);
                          }}
                        >
                          Update
                        </div>
                        {/* <img src={require('./Assets/Edit.svg').default} alt="" /> */}
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>

      <InstallmentUpdate
        SPackage={SPackage}
        setSPackage={setSPackage}
        Packages={Packages}
        setPackages={setPackages}
        handleEditInstallment={handleEditInstallment}
      />
    </>
  );
};

const InstallmentUpdate = ({
  SPackage,
  setSPackage,
  Packages,
  setPackages,
  handleEditInstallment,
}) => {
  const OnInstallmentChange = (e, name) =>
    setSPackage({ ...SPackage, [name]: e.target.value });

  const onScheduleChange = (e) =>
    setSPackage({ ...SPackage, InstallmentSchedule: e.target.value });

  const OnSave = (Save) => {
    if (Save) {
      let NewPackages = [...Packages];
      NewPackages[SPackage.Index] = SPackage;
      delete SPackage.Index;
      setPackages([...NewPackages]);
    }
    handleEditInstallment();
  };
  return (
    <div id="installmentsEdit">
      <div className="installmentsEditPopup">
        <h5>Installment Payment Schedule :</h5>
        <div className="installmentPopupflex">
          <span>Frequency</span>
          <div>
            <input
              type="radio"
              name="InstallmentType"
              value="Weekly"
              checked={SPackage?.InstallmentSchedule === "Weekly"}
              onChange={onScheduleChange}
            />
            <span>Weekly</span>
          </div>
          <div>
            <input
              type="radio"
              name="InstallmentType"
              value="Bi-Weekly"
              checked={SPackage?.InstallmentSchedule === "Bi-Weekly"}
              onChange={onScheduleChange}
            />
            <span>Bi-Weekly</span>
          </div>
          <div>
            <input
              type="radio"
              name="InstallmentType"
              value="Monthly"
              checked={SPackage?.InstallmentSchedule === "Monthly"}
              onChange={onScheduleChange}
            />
            <span>Monthly</span>
          </div>
        </div>

        <br />

        <div className="secinstallmentPopupflex hidden">
          <div className="flex items-center">
            <span>Total Amount</span>
            <input
              disabled={true}
              type="text"
              className="py-2 px-3 text-center text-base"
              value={`${SPackage.TotalFee}`}
              onChange={(e) => OnInstallmentChange(e, "TotalFee")}
            />
          </div>
          <div className="flex items-center">
            <span>Payment</span>
            <input
              disabled={true}
              type="text"
              className="py-2 px-3 text-center text-base"
              value={`${SPackage.Installments}`}
              onChange={(e) => OnInstallmentChange(e, "Installments")}
            />
          </div>
        </div>
        <p>
          {SPackage?.Installments} payments will be proceessed{" "}
          {SPackage?.InstallmentSchedule} in the amount of{" "}
        </p>
        <center className="installmentPopupButtons">
          <button type="button" onClick={() => OnSave(true)}>
            Save
          </button>
          <button type="button" onClick={() => OnSave()}>
            Cancel
          </button>
        </center>
      </div>
    </div>
  );
};
export default PackageInstallments;
