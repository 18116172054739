import React, { useState, useEffect } from "react";
import { InstructorLicenseDetail } from "../InstructorLicenseDetails/InstructorLicenseDetail";
import {
    Input,
    SelectList,
} from "../../../../../../../Components/InputComps/InputComps";
import { DateInput } from "../../../../../../../Components/InputComps/DateComps";
import { InstructorOnChange } from "../../../../../../AddCourse/Components/Course Packaging/Helpers/Events";
import { useParams } from "react-router-dom";
import { LicenseTypesOption } from "../../../OptionsArr/OptionsArr";
import LicenseImagePreviewer from "../../../../../../../Components/ImagePreviewer/LicenseImagePreviewer";
import LicenseWarn from "../LicenseWarnPopup/LicenseWarn";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { BaseUrl } from "../../../../../../../Actions/Base";

export const LicenseDetails = ({
    setLicenseImages,
    setInstructorLicenseDetails,
    InstructorLicenseDetails,
    Err,
    setErr,
    type,
    InstructorData,
    LicenseImages,
    readOnly,

    OnChange,
    // SpecialLicenseDetails,
    // setSpecialLicenseDetails,
    simpleLicense,
    updateOnly,
}) => {
    const [InstructorErrors, setInstructorErrors] = useState([]);
    const { InstructorId } = useParams();

    const [licenseNum, setLicenseNum] = useState("");
    const [licenseExp, setLicenseExp] = useState("");
    const [licenseTyp, setLicenseTyp] = useState("");
    const [licenseImg, setLicenseImg] = useState(null);
    const [NewFieldError, setNewFieldError] = useState("");

    console.log("licenseImage", licenseImg);
    const [showPopup, setShowPopup] = useState(false);
    const [PopupMessage, setPopupMessage] = useState("");

    // useEffect(() => {
    //     // Construct LicenseImages array with URLs from each record
    //     const updatedLicenseImages = [];
    //     if (updateOnly) {
    //         InstructorLicenseDetails.forEach((record) => {
    //             if (record.LicenseImage) {
    //                 updatedLicenseImages.push(
    //                     `${BaseUrl}/api/images/Instructors?url=${record.LicenseImage}`
    //                 );
    //             }
    //         });

    //         // Update LicenseImages state with the constructed array
    //         setLicenseImages(updatedLicenseImages);
    //     }
    // }, [InstructorLicenseDetails, setLicenseImages]);

    const handleLicenseType = (e) => {
        if (
            InstructorData?.InstructorLicenseDetails?.some(
                (license) => license.LicenseTypeFK === e.target.value
            )
        ) {
            setPopupMessage("Selected license type is already used");
            setShowPopup(true);
            return;
        }
        setLicenseTyp(e.target.value);
    };

    // const isLicenseExpired = () => {
    //     const currentDate = dayjs(); // Current date

    //     return licenseExpiryDate.isBefore(currentDate, "day");
    // };

    // alert(isLicenseExpired());
    console.log("line~67licenseimagesss", LicenseImages);
    const AddInstructor = (e) => {
        e.preventDefault();
        if (!licenseNum || !licenseExp || !licenseTyp || !licenseImg) {
            setNewFieldError("Please fill all fields marked with *");
            return;
        }

        const licenseExpiryDate = dayjs(licenseExp, "YYYY-MM-DD");
        const currentDate = dayjs(); // Current date

        if (licenseExpiryDate.isBefore(currentDate, "day")) {
            setPopupMessage(
                "License has expired or enter the correct license expiry date."
            );
            setShowPopup(true);
            return;
        }

        const NewInstructor = {
            InstructorFK: "",
            LicenseNumber: licenseNum,
            LicenceExpiry: licenseExp,
            LicenseTypeFK: licenseTyp,
            // LicenseImage: licenseImg,
        };

        // console.log("licenseIMgaaaa", licenseImg);
        // setLicenseImages([...LicenseImages,licens])
        setLicenseImages([...LicenseImages, licenseImg]);

        // if (updateOnly) {
        //     const updatedLicenseImages = [...LicenseImages];
        //     InstructorLicenseDetails.forEach((record) => {
        //         if (record.LicenseImage) {
        //             updatedLicenseImages.push(
        //                 `${BaseUrl}/api/images/Instructors?url=${record.LicenseImage}`
        //             );
        //         }
        //     });

        //     setLicenseImages(updatedLicenseImages);
        // }
        setInstructorLicenseDetails([
            ...InstructorLicenseDetails,
            NewInstructor,
        ]);

        setLicenseNum("");
        setLicenseExp("");
        setLicenseTyp("");
        setLicenseImg(null);
        setNewFieldError("");
        setErr({ ...Err, Instructor: [...InstructorErrors] });
    };
    const { LicenseTypes } = useSelector((Store) => Store.LicenseTypeReducer);

    const RemoveInstructor = (Index) => {
        let RemovedInstructorLicenseDetails = InstructorLicenseDetails.filter(
            (value, index) => Index !== index
        );
        let removedLicenseImages = LicenseImages?.filter(
            (value, index) => index !== Index
        );
        setLicenseImages(removedLicenseImages);
        setInstructorLicenseDetails(RemovedInstructorLicenseDetails);
        if (RemovedInstructorLicenseDetails.length <= 0) {
            setErr({
                ...Err,
                Instructor: "At least one License Detail is required",
            });
            setNewFieldError("");
        }
    };

    const onChange = (e, INDEX, ClassType, ClassName) => {
        let Data =
            e?.target?.files?.length > 0
                ? e?.target?.files[0]
                : e?.target?.value;

        const updatedLicenseDetails = [...InstructorLicenseDetails];

        updatedLicenseDetails[INDEX] = {
            ...updatedLicenseDetails[INDEX],
            [ClassType]: Data,
        };

        console.log("INDEX", INDEX);
        console.log("ClassType", ClassType);

        console.log(
            "updatedLicenseDetails[INDEX]",
            updatedLicenseDetails[INDEX]
        );

        // Set the updated state
        setInstructorLicenseDetails(updatedLicenseDetails);
    };

    useEffect(() => {
        setErr({ ...Err, Instructors: [...InstructorErrors] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [InstructorErrors]);

    return (
        <>
            {showPopup && (
                <LicenseWarn
                    setShowPopup={setShowPopup}
                    PopupMessage={PopupMessage}
                />
            )}
            <h3
                style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                className="2xl:text-md text-xs font-bold text-[#4C34AD] sm:text-sm md:text-base lg:text-[29px] xl:text-lg"
            >
                License Details
            </h3>

            <div
                className=" rounded-[23px] px-5 py-10 "
                style={{
                    boxShadow: "0 0 50px rgba(0, 0, 0, 0.3)",

                    background:
                        "transparent linear-gradient(90deg, #F9F9FF 0%, #DADCFF 100%) 0% 0% no-repeat padding-box",
                }}
            >
                <div class="">
                    <div class="-m-1.5 overflow-x-auto">
                        <div class="inline-block min-w-full p-1.5 align-middle">
                            <div class="overflow-hidden">
                                <table class="min-w-full border-collapse overflow-x-auto">
                                    <thead>
                                        <tr className="border-0 border-b border-solid border-[#A5C9FF]">
                                            <th
                                                scope="col"
                                                class="px-6 py-3 text-start text-xs font-medium text-[#404040] "
                                            >
                                                License Number *
                                            </th>
                                            <th
                                                scope="col"
                                                class="px-6 py-3 text-start text-xs font-medium text-[#404040] "
                                            >
                                                License Expiry *
                                            </th>
                                            <th
                                                scope="col"
                                                class="px-6 py-3 text-start text-xs font-medium text-[#404040] "
                                            >
                                                License Class *
                                            </th>
                                            <th
                                                scope="col"
                                                class="px-6 py-3 text-start text-xs font-medium text-[#404040] "
                                            >
                                                Attachable *
                                            </th>
                                            <th
                                                scope="col"
                                                class="px-1 py-3  text-xs font-medium text-[#404040] "
                                            >
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class=" ">
                                        {InstructorLicenseDetails &&
                                        InstructorLicenseDetails.length > 0
                                            ? InstructorLicenseDetails.map(
                                                  (value, index) => {
                                                      {
                                                          /* console.log(
                                                          "valueoflicesnedetails",
                                                          value
                                                      ); */
                                                      }

                                                      return (
                                                          <InstructorLicenseDetail
                                                              InstructorLicenseDetails={
                                                                  InstructorLicenseDetails
                                                              }
                                                              key={index}
                                                              updateOnly={
                                                                  updateOnly
                                                              }
                                                              licenseImg={
                                                                  licenseImg
                                                              }
                                                              LicenseImages={
                                                                  LicenseImages
                                                              }
                                                              setInstructorLicenseDetails={
                                                                  setInstructorLicenseDetails
                                                              }
                                                              setNewFieldError={
                                                                  setNewFieldError
                                                              }
                                                              InstructorData={
                                                                  InstructorData
                                                              }
                                                              readOnly={
                                                                  readOnly
                                                              }
                                                              RemoveInstructor={
                                                                  RemoveInstructor
                                                              }
                                                              InstructorErrors={
                                                                  InstructorErrors
                                                              }
                                                              setInstructorErrors={
                                                                  setErr
                                                              }
                                                              value={value}
                                                              index={index}
                                                              onChange={
                                                                  onChange
                                                              }
                                                          />
                                                      );
                                                  }
                                              )
                                            : null}

                                        <tr className=" border-0 border-b border-solid border-[#A5C9FF]">
                                            <td class="relative  px-6  text-3xs font-medium text-black">
                                                <div className="  mb-5 flex  w-full items-center justify-center [&>*]:max-w-full">
                                                    <Input
                                                        licenseInput={true}
                                                        Placeholder="0546"
                                                        State={licenseNum}
                                                        onChange={(e) =>
                                                            setLicenseNum(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </td>
                                            <td class="   px-6  text-3xs text-black">
                                                <div className="mb-3 flex items-center justify-center [&>*]:w-full [&>div>span>input]:shadow-none">
                                                    <DateInput
                                                        licenseInput={true}
                                                        type={"licence-date"}
                                                        Id={"LicenceExpiry"}
                                                        State={licenseExp}
                                                        onChange={(e) =>
                                                            setLicenseExp(
                                                                e.target.value
                                                            )
                                                        }
                                                        CurrentYear={dayjs().format(
                                                            "YYYY-MM-DD"
                                                        )}
                                                    />
                                                </div>
                                            </td>
                                            <td class=" px-6  text-3xs text-black">
                                                <div className="  mb-5 flex  w-full items-center justify-center [&>*]:max-w-full">
                                                    {/* {showPopup && (
                                                        <LicenseWarn
                                                            setShowPopup={
                                                                setShowPopup
                                                            }
                                                        />
                                                    )} */}
                                                    <SelectList
                                                        licenseInput={true}
                                                        Id="LicenseTypeFK"
                                                        defaultValue=""
                                                        Text="License Type"
                                                        OptionsArr={
                                                            LicenseTypesOption
                                                            //
                                                        }
                                                        State={licenseTyp}
                                                        onChange={
                                                            handleLicenseType
                                                            // handleLicenseTypeChange
                                                            // (e) =>
                                                            //     setLicenseTyp(
                                                            //         e.target
                                                            //             .value
                                                            //     )
                                                        }
                                                    />
                                                </div>
                                            </td>
                                            <td class=" px-6  text-3xs text-black">
                                                {/* <div className="w-full [&>*]:max-w-full "> */}
                                                <LicenseImagePreviewer
                                                    LicenseDetail={true}
                                                    readOnly={readOnly}
                                                    Id="licenseImg"
                                                    PreviewStyle=""
                                                    BrowseBtnStyle=""
                                                    ContainerStyle=""
                                                    onChange={(e) =>
                                                        setLicenseImg(
                                                            e.target.files[0]
                                                        )
                                                    }
                                                    // onChange={onChange}
                                                    ImgUrl={licenseImg}
                                                    licenseImg={licenseImg}
                                                />
                                                {/* </div> */}
                                            </td>
                                            <td class="relative whitespace-nowrap px-6 text-center text-3xs font-medium">
                                                <div className=" flex items-center justify-center ">
                                                    <button
                                                        onClick={AddInstructor}
                                                        type="button"
                                                        className=" bottom-[14px] left-0 mb-1 cursor-pointer  rounded-[10px] border-0 bg-[#4C34AD]  px-4 py-1 text-4xs font-medium text-white md:mb-[2px] md:px-8 md:py-2 lg:px-10 lg:py-2 "
                                                    >
                                                        Add
                                                    </button>
                                                </div>
                                                {/* <label
          className={`h-fit  w-fit cursor-pointer text-[14px] lg:text-[16px]  rounded-lg px-4 py-1  md:px-8 md:py-2 bg-[#4C34AD] text-white shadow-[5px_6px_6px_#00000016] `}
          htmlFor={Id}
        >
          Browse
        </label> */}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {InstructorErrors.map((value, index) => (
                                    <h3
                                        key={index + Object.keys(value)?.[0]}
                                        className="font-normal text-[red]"
                                    >
                                        {value?.[Object.keys(value)?.[0]]}{" "}
                                    </h3>
                                ))}
                                <h3 className="text-3xs font-normal text-[red]">
                                    {typeof Err?.Instructor === "string"
                                        ? Err?.Instructor
                                        : null}
                                    {NewFieldError && NewFieldError}
                                </h3>
                            </div>
                        </div>
                    </div>

                    {/* end */}
                </div>
            </div>
        </>
    );
};
