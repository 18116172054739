import axios from "axios";
import { BaseUrl } from "../../Actions/Base";

const axiosInstance = axios.create({
  baseURL: BaseUrl,
  withCredentials: true,
});
export const getAllCompleteEvents = async () => {
  try {
    const res = await axiosInstance.get("/api/completed-classes");
    return res;
  } catch (error) {
    return error;
  }
};

export const getCompletedLocation = async (key) => {
  try {
    const res = await axiosInstance.post(`/api/track-location`, { key });
    return res.data;
  } catch (error) {
    return error;
  }
};
