import React from "react";
import { Tiles } from "./Tiles";
import sublicenseCategoryImage from "../../Assets/sublicenseCategoryImage.svg";
import "./ForSubcategories.css";
import { DataForSubcategories } from "../../../../../dataString/dataForLicenseAndVehicles";
import { sublicenseTilesText } from "../../../../../dataString/SublicenseTilesText";

const ForSubcategories = ({ Type }) => {
  const LicenseTypeName = Type?.LicenseTypeName;
  const LicenseTypeImage = Type?.LicenseTypeImage;
  const LicenseTypeId = Type?.LicenseTypeId;
  console.log("Type=>>", Type);
  console.log("License Type Image",LicenseTypeImage)
  return (
    <>
      <div
        className={`bilall relative flex h-fit min-h-[675px] flex-col items-center overflow-x-hidden bg-backgroundWhiteColor  
                       xl:min-h-[750px]  2xl:min-h-[860px]`}
      >
        {/* <div className={`absolute -z-10    
      w-full                                  xl:w-full min-h-[750px]
      h-[calc(100vh_-_100px)]       sm:h-full xl:h-auto
      ClassGLicensingMobileGradient`}>
                <img className='hidden sm:block w-auto xl:w-full h-full xl:h-auto' src={require('../../Assets/Category G License.png')} alt="" />
            </div> */}

        {/* <div className="my-2 hidden w-[85%] items-start sm:flex ">
        <Link className="SemiBold text-[21px] text-white no-underline">
          Home {">"} Vehicle License Type {">"} {Type?.LicenseTypeName}
        </Link>
      </div> */}

        <MainPart
          LicenseTypeName={LicenseTypeName}
          LicenseTypeImage={LicenseTypeImage}
          SubLicenseTypes={Type?.SubLicenseTypes}
          LicenseTypeId={LicenseTypeId}
        />
      </div>
    </>
  );
};

export default ForSubcategories;

function MainPart({
  SubLicenseTypes,
  LicenseTypeImage,
  LicenseTypeName,
  LicenseTypeId,
}) {
  console.log("license types image in main part is:",LicenseTypeImage)
  let HeadingText;
  let SubHeading;

  switch (LicenseTypeName) {
    case "G":
      HeadingText = DataForSubcategories?.licenseTypeG?.HeadingText;
      SubHeading = DataForSubcategories?.licenseTypeG?.SubHeading;
      break;
    case "M":
      HeadingText = DataForSubcategories?.licenseTypeM?.HeadingText;
      SubHeading = DataForSubcategories?.licenseTypeM?.SubHeading;
      break;
    default:
      HeadingText =
        "Vehicle Learning Innovation Offers complete range of Driving Courses for all Vehicles";
      SubHeading =
        "Discover comprehensive Complete range of driving courses for all vehicle typesat Vehicle Learning Innovation, your centralized hub for mastering the road.";
  }

  return (
    <>
      <div className="main-subcategory flex w-full  flex-col justify-center text-white md:flex-row  md:justify-around">
        <div className="text-div sm-p-[1%] flex basis-[60%] flex-col items-center justify-center p-[2%] md:p-[2%]">
          <h2 className="LandingPagetext 2xl:text-md xl:text-md pb-5 text-center text-[20px] text-white md:w-auto  md:text-left md:text-base md:text-[text-sm] lg:text-lg">
            {HeadingText}
          </h2>
          <p className="Regularfont text-center text-4xs font-thin text-white md:text-left md:text-3xs lg:text-2xs xl:text-base ">
            {SubHeading}
          </p>
        </div>
        <div className="image-div flex basis-[40%] items-center  justify-center p-[2%]">
          <img
            className="w-[256px] md:w-[300px] lg:w-[500px]"
            src={sublicenseCategoryImage}
            alt=""
          />
        </div>
      </div>

      <div className="mt-10 flex w-11/12 flex-col gap-[88px] sm:mt-0 ">
        {/*      
      <div className="flex w-full justify-center">
        <h1 className="HeadingBorder SemiBoldItalic p-5 pt-0 text-center text-white">
          Class G Licensing
        </h1>
      </div> */}

        <div
          className={`relative mt-10 flex w-full flex-wrap justify-center 
                gap-4 sm:gap-5 md:gap-10 lg:gap-10 xl:gap-12 2xl:gap-14 
    `}
        >
          {SubLicenseTypes.map((value, index) => {


            let SublicenseName = value?.SubLicenseTypeName.toLowerCase();
            let text;

            
            console.log("SublicenseName==>", SublicenseName);

            switch (SublicenseName) {
              case "class g1":
                text = sublicenseTilesText?.classG1;
                break;
              case "class g2":
                text = sublicenseTilesText?.classG2;
                break;
              case "class g":
                text = sublicenseTilesText?.classG;
                break;
              case "class m":
                text = sublicenseTilesText?.classM;
                break;
              case "class m1":
                text = sublicenseTilesText?.classM1;
                break;
              case "class m2":
                text = sublicenseTilesText?.classM2;
                break;
              case "m, m1, or m2 with condition l (lsm/moped)":
                text = sublicenseTilesText?.classMwithL;
                break;
              case "m, m1, or m2 with condition 1 (motor tricycle)":
                text = sublicenseTilesText?.classMwithL;
                break;
              default:
                text = "sublicense Text";
            }
            console.log("value=>..", value);
            return (
              <Tiles
                LicenseTypeName={value?.SubLicenseTypeName}
                LicenseTypeImage={value?.SubLicenseTypeImage}
                Heading={value?.SubLicenseTypeName}
                Detail={text}
                IMG={require("../../Assets/Level1Icon.svg").default}
                index={index}
                LicenseTypeId={LicenseTypeId}
              />
            );
          })}

          {/* <Tiles Heading='Class G2 Licensing' Detail='Level Two of graduated licensing.' IMG={require('../../Assets/Level2Icon.svg').default} />
            <Tiles Heading='Class G Licensing' Detail='Allowed to drive any car.' /> */}
        </div>
      </div>
    </>
  );
}
