import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonsSide from "../ButtonsSide/ButtonsSide";

const ContentSide = ({ value, OnClick, Color, allCourses }) => {
  const Navigate = useNavigate();
  return (
    <>
    <div
      onClick={() => Navigate(`/ViewCourse/${value?.InstituteCourseId}`)}
      className="my-2 flex h-96 max-h-[136px] w-full max-w-[535px] cursor-pointer flex-col items-center justify-around pl-[21px] pr-[27px] lg:w-[70%] lg:items-start lg:self-stretch"
    >
      <div className="flex flex-col items-center lg:items-start">
        <p className="cardHeading font-segoe-ui font-semibold text-7xs 2xs:text-6xs xs:text-5xs sm:text-4xs  3xl:text-3xs">
          {value?.Course?.CourseName}
        </p>
        <div className="flex flex-col items-center justify-center gap-2 2xs:flex-row">
          <p className="text-7xs 2xs:text-6xs xs:text-5xs sm:text-4xs font-semibold font-segoe-ui 3xl:text-3xs">
            {" "}
            L Type - {value?.Course?.LicenseType?.LicenseTypeName}
          </p>
          <p className="hidden text-7xs 2xs:inline-block 2xs:text-6xs xs:text-5xs sm:text-4xs md:text-3xs lg:text-2xs xl:text-sm 2xl:text-base">
            {" "}
            |
          </p>
          <p className="text-7xs 2xs:text-6xs xs:text-5xs sm:text-4xs font-segoe-ui 3xl:text-3xl font-medium">
            {" "}
            V Type - {value?.Course?.VehicleType?.VehicleTypeName}
          </p>
        </div>
      </div>

      <p className="break-all pr-2 text-center text-[14px] 2xs:pr-3 font-segoe-ui  xs:pr-5  sm:pr-6 md:pr-7 md:text-[14px]">
        {value?.ShortDescription}
      </p>

     
    </div>
    
   </>
  );
};

export default ContentSide;
