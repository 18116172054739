import React, { useState, useContext, useEffect, useRef  } from "react";
import { useDispatch, useSelector} from "react-redux";
import { GeneralEvent } from "../../../../../Actions/Events/FilterA";
import { SocketContext } from "../../../../../App";
import {
  GetLocalStorage,
  SetLocalStorage,
} from "../../../../../Helpers/LocalStorage/LocalStorage";
import ProfileDropdown from "../../ProfileDropDown";
import DropDownOptions from "./DropDownOptions";
import Notification from "../../../../Notification/Notification";
const RightSide = ({ setShowSidebar }) => {
  const [reRender, setreRender] = useState();
  const [ShowNotifications, setShowNotifications] = useState(false);
  const unreadNotificationsCount = useRef(0);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(unreadNotificationsCount.current > 0 ? true : false);
  const { Notifications } = useSelector((Store) => Store?.NotificationReducer);
  const Socket = useContext(SocketContext);
  const Dispatch = useDispatch();
  console.log("NotificationsNotifications", Notifications);
  useEffect(() => {
    if(Socket){
      if(!Socket.connected){Socket.connect()}
    }
    let NotifiArr;
    Socket?.on("Notifications", (data) => {
      console.log("Notificationsdata", data);
      NotifiArr = [
        ...Notifications.map((value) => {
          return value;
        }),
      ];
      NotifiArr.unshift(data[0]);
      // Increment the unreadNotificationsCount
    unreadNotificationsCount.current += 1;
      setHasUnreadNotifications(true);
      Dispatch(GeneralEvent(NotifiArr, "GetNotificationsSuccess"));
      let UserInfo = { ...GetLocalStorage("UserInfo") };
      delete UserInfo.Notifications;
      SetLocalStorage("UserInfo", { ...UserInfo, Notifications: NotifiArr });

      // return ()=> {Socket.disconnect()}
    });
  }, [Socket, Dispatch, Notifications]);

  useEffect(()=> {
    return ()=> {
      if(Socket){
        Socket.disconnect()}
      }
  }, [])

  // useEffect(() => {
  //   let NotifiArr;
  //   Socket?.on("Notifications", (data) => {
  //     console.log("Notificationsdata",data)
  //     NotifiArr = [
  //       ...Notifications.map((value) => {
  //         return value;
  //       }),
  //     ];
  //     NotifiArr.unshift(data[0]);
  //     Dispatch(GeneralEvent(NotifiArr, "GetNotificationsSuccess"));
  //     let UserInfo = { ...GetLocalStorage("UserInfo") };
  //     delete UserInfo.Notifications;
  //     SetLocalStorage("UserInfo", { ...UserInfo, Notifications: NotifiArr });

  //     // return ()=> {Socket.disconnect()}
  //   });
  // }, [Socket, Dispatch, Notifications]);
  const handleToggleNotifications =()=> {
    setHasUnreadNotifications(false);
  setShowNotifications(!ShowNotifications);
  }

  return (
    <div className="flex w-1/2 justify-center md:justify-end lg:gap-10 gap-4 px-11">
      <span className="relative">
        {/* <span className="absolute -top-[1px] right-[1px] flex h-3 w-3 rounded-full border-[2px] border-solid          border-white bg-[#A1A3EF]"></span> */}
        {/* <img
          className="h-[30px] cursor-pointer"
          src={require("../Assets/Notification.svg").default}
          alt=""
          onClick={() => setShowNotifications(!ShowNotifications)}
        /> */}
        <span
          onClick={handleToggleNotifications }
          class="material-symbols-outlined cursor-pointer text-white"
          style={{ fontSize: "38px" }}
        >
        {`${hasUnreadNotifications ? 'notifications_unread' : 'notifications'} `}
        </span>
        {/* class="material-symbols-outlined text-[#6D6FFE] cursor-pointer" style={{fontSize: '38px'}}>notifications</span> */}
        {/* <span class="material-symbols-outlined">notifications</span> */}
        <span className="fixed right-10 top-16">
          {ShowNotifications ? (
            <Notification
              Notifications={Notifications}
              setShowNotifications={setShowNotifications}
            />
          ) : null}
        </span>
      </span>

      <ProfileDropdown
        setShowSidebar={setShowSidebar}
        DropDownOptions={DropDownOptions}
      />
    </div>
  );
};

export default RightSide;
