import React from "react";
import ImagePreviewerForAwards from "../../../Components/ImagePreviewer/ImagePreviewerForAwards";
import { Description } from "@mui/icons-material";

const AwardForm = ({setAwardForm, awardData, setAwardData, Err, setErr, handlesubmit, submitData}) => {

    const OnChange = e => {

        if (e.target.files.length > 0) {
            delete Err?.AwardThumbnail
            setErr(Err)
            setAwardData({ ...awardData, "AwardThumbnail": e.target.files[0] })
        }
        else if (e.target.files.length <= 0)
            setErr({ ...Err, AwardThumbnail: "Award Thumbnail is required" })
    }
    const awardChange =(e, name)=> {
        if(name){
            delete Err[name]
        }
          setAwardData({...awardData, [name]: e.target.value})
          
    }

    const Awardsubmit=(e)=> {
        e.preventDefault()
        console.log("Awardsubmit")
        submitData()

    }

    const handleAwardCancel=()=> {
        setAwardForm(false)
        setAwardData({
            AwardThumbnail: "",
        Title: "",
        Description: ""
        })
        setErr({})
    }

  return (
    <>
      <div className="fixed px-6 inset-0 z-[99999999] flex items-center justify-center bg-[#bababa80]">
        <form onSubmit={(e)=>Awardsubmit(e)} className="awardForm flex relative max-w-[473px] flex-col justify-center items-center gap-5 p-8 h-auto w-[] rounded-[19px] bg-[white] shadow-lg">
          <span
          onClick={() => setAwardForm(false)}
          className="material-symbols-outlined absolute top-1 right-1 cursor-pointer text-[29px] font-semibold text-violet-100"
        >
          cancel
        </span>
          <h2 className="text-black text-2xs font-segoe-ui front-bold">Create Award</h2>
          <p className="text-6xs lg:text-4xs text-center">
            Add a certificate Title, badge and short description of your
            certificate.
          </p>
          <div>
            {Err?.AwardThumbnail && <p className="text-error text-6xs pb-2">{Err.AwardThumbnail}</p> }
            <ImagePreviewerForAwards
            Id="AwardThumbnail"
            OnChange={OnChange}
            ImgUrl={awardData.AwardThumbnail}
            />
          </div>
          <div className="w-full">
            {Err?.Title && <p className="text-error text-6xs pb-2">{Err.Title}</p> }
            <input 
            onChange={(e)=> awardChange(e, "Title")}
            value={awardData.Title} type="text" id="title" className="bg-gray-50 border shadow-none text-6xs md:text-4xs xl:text-2xs border-purpal text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Award Title" />
          </div>
          <div className="w-full">
          {Err?.Description && <p className="text-error text-6xspb-2">{Err.Description}</p> }
            <textarea
            onChange={(e)=> awardChange(e, "Description")}
            value={awardData.Description} id="message" rows="4" className="block p-2.5 w-full text-6xs md:text-4xs xl:text-2xs text-gray-900 bg-gray-50 rounded-lg border border-purpal focus:ring-blue-500 focus:border-blue-500" placeholder="Write your description here..."></textarea>

          </div>
          <div className="buttons flex justify-center gap-8">
          <button onClick={handleAwardCancel} type="button" className="py-2.5 px-5 text-4xs xl:text-3xs font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-purpal hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 cursor-pointer">Cancel</button>

          <button type="submit" className="text-white border-0 bg-purpal hover:bg-purpal/70 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-4xs xl:text-3xs px-10 py-2.5 cursor-pointer">Add</button>

          </div>
        </form>
      </div>
    </>
  );
};

export default AwardForm;
