import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { OpenLoginSignUp } from '../../../../Actions/ToggleSignupA';
import { SetLocalStorage } from '../../../../Helpers/LocalStorage/LocalStorage';
import { lowestPrice } from '../../../CourseEnrollment/Helpers/calculateDiscount';


export function BuyCourse({ CourseInfo, downloadCurriculum }) {
    let ButtonStyle = `text-white text-center w-fit min-w-[250px] py-3 md:py-6 rounded-[9px] whitespace-nowrap border-none px-4 md:px-10 cursor-pointer SemiBold
    text-3xs md:text-3xs lg:text-2xs xl:text-xs 
    `
    const { Authenticated } = useSelector((Store) => Store.LoginSignupReducer)
    const Navigate = useNavigate()
    const Dispatch = useDispatch();

    const NavigateToEnrollment = () => {
        
        if (Authenticated && CourseInfo?.Enrolled)
            Navigate('/mycourses/enrolledcourses')

        else if (Authenticated)
            Navigate('/Enrollment/Course', { state: { InstituteCourseId: CourseInfo?.InstituteCourseId } })

        else {
            SetLocalStorage("navigateToEnroll", CourseInfo?.InstituteCourseId)
            Dispatch(OpenLoginSignUp("Sign In", false))
        }

    }


    console.log("CourseInfoCourseInfoCourseInfo=>", CourseInfo)
     
    let lowPrice;
    if(!CourseInfo?.Enrolled && CourseInfo) { lowPrice = lowestPrice(CourseInfo)}
    

    return <span className="flex gap-5 mt-10 flex-col items-center sm:flex-row">
        <button onClick={NavigateToEnrollment} className={`${ButtonStyle} bg-[#A1A3EF]`}>
            {CourseInfo?.Enrolled ? "Enrolled" : `Enroll for Course` }
        </button>
        <button onClick={downloadCurriculum} className={`${ButtonStyle} text-white sm:text-[#A1A3EF] bg-[#A1A3EF] sm:bg-white`}>
            Download Curriculum
        </button>
    </span>
}