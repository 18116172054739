import axios from 'axios'
import { useState } from 'react'
import { useEffect } from 'react'
import { BaseUrl } from '../../Actions/Base'
const axiosInstance = axios.create({
    baseURL: BaseUrl,
    withCredentials: true,
})
const useAxios = (Method, Url, body) => {
    const [response, setResponse] = useState({});
    const [Loading, setLoading] = useState(true);
    const [Done, setDone] = useState(false);
    const [Errors, setErrors] = useState();
    useEffect(() => {
        if (Method && Url && !Done) {
            setDone(true);

            if (Method.toLowerCase() === "get") {
                axiosInstance.get(Url)
                    .then(res => {
                        setResponse(res);
                        setErrors()
                    })
                    .then(() => setLoading(false))
                    .catch(err => {
                        setResponse()
                        setErrors(err)
                        setLoading(false)
                    })
            } else if (Method === "post") {

                axiosInstance.post(Url, body)
                    .then(res => {
                        setResponse(res);
                        setErrors();
                        setLoading(false)
                    })
                    .catch(err => {
                        setResponse()
                        setErrors(err)
                        setLoading(false)
                    })

            }
            else if (Method === "pt") {

                axiosInstance.post(Url, body)
                    .then(res => {
                        setResponse(res);
                        setErrors();
                        setLoading(false)
                    })
                    .catch(err => {
                        setResponse()
                        setErrors(err)
                        setLoading(false)
                    })

            }
            else if (Method === "delete") {
                let dataBody;
                if (body)
                    dataBody = { data: body }


                axiosInstance.delete(Url, dataBody)
                    .then(res => {
                        setResponse(res);
                        setErrors();
                        setLoading(false)
                    })
                    .catch(err => {
                        setResponse()
                        setErrors(err)
                        setLoading(false)
                    })

            }
        }
        
    }, [Method, Url, Done, body])

    return [response, Errors, Loading]
}

export default useAxios