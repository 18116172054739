import { useEffect, useRef, useState } from "react";

export default function CustomDropdown({ buttonText, children }) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className="relative  z-20 " ref={dropdownRef}>
            <span
                className=" HeaderRightSide-LinkStyle SemiBold cursor-pointer"
                onClick={() => setIsOpen(!isOpen)}
            >
                {buttonText}
            </span>
            {isOpen && (
                <div className="absolute -left-16 top-10 w-fit  bg-white p-2 shadow-[0px_2px_10px_#00000030] transition-opacity duration-300 ">
                    <span>
                        <img
                            className=" absolute -top-2 left-20 z-10 drop-shadow "
                            src={require("./Assets/PointIcon.svg").default}
                            alt=""
                        />
                    </span>
                    <div onClick={(e) => e.stopPropagation()}>{children}</div>
                </div>
            )}
        </div>
    );
}
