export function calculateLastActiveTime(lastActive) {
    const currentTime = Date.now();
    const timeDifference = currentTime - lastActive;
    const seconds = Math.floor(timeDifference / 1000);

    if (seconds < 60) {
        return `${seconds} seconds ago`;
    } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        return `${minutes} minutes ago`;
    } else if (seconds < 86400) {
        const hours = Math.floor(seconds / 3600);
        return `${hours} hours ago`;
    } else {
        // Display the date if more than a day ago
        const options = { year: "numeric", month: "long", day: "numeric" };
        return new Date(lastActive).toLocaleDateString(undefined, options);
    }
}
