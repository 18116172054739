import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import JwtDecode from 'jwt-decode';
import { LoginWithGoogleAction, SignUpWithGoogleAction } from '../../../../../../../Actions/UserA';


const ContinueWithGoogle = () => {
    const [GoogleBtn, setGoogleBtn] = useState({});
    const Dispatch = useDispatch();
    const { AuthPageName } = useSelector(Store => Store.ToggleSignupReducer);

    useEffect(() => {

        if (GoogleBtn.id !== undefined && window?.google !== undefined) {

            function handleCredentialResponse(response) {
                const Decoded = JwtDecode(response.credential);
                if (AuthPageName === "Sign In")
                    Dispatch(LoginWithGoogleAction(Decoded, Dispatch, AuthPageName))
                else if (AuthPageName === "Sign Up")
                    Dispatch(SignUpWithGoogleAction(Decoded, Dispatch, AuthPageName))
            }
            function LoadButton() {
                window?.google?.accounts?.id.initialize({

                    client_id: "930835429220-57v4696c4vm8qrv033qq010t5jlsjp3a.apps.googleusercontent.com",
                    // client_id: "934608889790-8cbnrmlc4go5jmuumt301fhhm3o1reg5.apps.googleusercontent.com", 
                    callback: handleCredentialResponse
                });

                window.google.accounts.id.renderButton(
                    document.getElementById(GoogleBtn?.id),
                    { theme: "outline", size: "large", width: "full" } // customization attributes
                );
                // also display the One Tap dialog
            }
            LoadButton()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [GoogleBtn, Dispatch]);

    // useEffect(() => {
    //     // Initialize Google One Tap API here
    //     if (window?.google?.accounts?.id) {
    //         window.google.accounts.id.initialize({
    //             client_id: "930835429220-57v4696c4vm8qrv033qq010t5jlsjp3a.apps.googleusercontent.com",
    //             callback: handleCredentialResponse
    //         });
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [Dispatch]);

    // const handleCredentialResponse = (response) => {
    //     const Decoded = JwtDecode(response.credential);
    //     if (AuthPageName === 'Sign In') Dispatch(LoginWithGoogleAction(Decoded, Dispatch, AuthPageName));
    //     else if (AuthPageName === 'Sign Up') Dispatch(SignUpWithGoogleAction(Decoded, Dispatch, AuthPageName));
    // };


    // const handleCustomButtonClick = () => {
    //     // Trigger the Google One Tap dialog programmatically
    //     window.google.accounts.id.prompt();
    //     // setGoogleBtn(true)
        
    // };

  
    const HandleOnClick = () => { window.google.accounts.id.prompt(); console.log(12) }
    return (
       
        

        <button className={`SignUpSigninPageButtons border-none md:border-solid bg-[#ff000000] md:bg-white   w-auto md:w-full DropShadow`} type="button" onClick={HandleOnClick}>
            <div  id="buttonDiv"
                className='w-full  flex justify-center' ref={(e) => { setGoogleBtn(e) }}
            ></div>
            {/* <picture>
                <source media="(min-width:768px)" srcSet={require("../../../../../Assets/Google Icon.png")} />
                <img
                    className='w-9 md:w-7 xl:w-10'
                    src={require("../../../../../Assets/Google Icon @mobile.png")} alt="" />
            </picture>
            <h5 className={`SignUpSigninPageButtonText hidden md:inline-block`}
            // onClick={() => Click("GoogleLogin")}

            >
                Continue With Google
            </h5> */}

          
        </button>


       

    )
}

export default ContinueWithGoogle;






// <button
// onClick={HandleOnClick}
// className={`SignUpSigninPageButtons DropShadow mt-5 w-auto border-none bg-[#ff000000] md:mt-0   md:w-full md:border-solid md:bg-white`}
// type="button"
// >
// {/* <div id="buttonDiv"
//         className='w-full flex' ref={(e) => { setGoogleBtn(e) }}
//     ></div> */}
// <div id='buttonDiv
   
//    ' className='flex w-full items-center justify-center' ref={(e) => { setGoogleBtn(e) }}>
//   <picture className="pl-10">
//     <source
//       media="(min-width:768px)"
//       srcSet={require("../../../../../Assets/Google Icon.png")}
//     />
//     <img
//       className="w-9 md:w-[25px] xl:w-[30px]"
//       src={require("../../../../../Assets/Google Icon @mobile.png")}
//       alt=""
//     />
//   </picture>
//   <h5
//     className={`SignUpSigninPageButtonText  border-0 pl-5 text-primary  outline-none md:inline-block`}
//     // onClick={handleClick}
//   >
//     Continue With Google
//   </h5>
// </div>
// </button>