import axios from "axios";
import { CheckLoginServer } from "../Helpers/CheckLogin";
import { BaseUrl } from "./Base";
import { type } from "jquery";

const axiosInstance = axios.create({
  baseURL: BaseUrl,
  withCredentials: true,
});

export const CreateAward =(awardData)=> async (dispatch) => {
    try{
        dispatch({
            type: "CreateAwardRequest"
        })
        const {data} = await axiosInstance.post(
            `/api/institute/awards/add`,
            awardData
        );
        dispatch({
                    type: "CreateAwardSuccess",
                    payload: data,
                  });
    }catch(error){
        CheckLoginServer(error?.response?.data, dispatch);
              dispatch({
                type: "CreateAwardFailure",
                payload: error,
              });
    }
}


// export const EnrollIntoCourse = (EnrollmentData) => async (dispatch) => {
//     try {
//       dispatch({
//         type: "BuyCourseRequest",
//       });
//       const { data } = await axiosInstance.post(
//         `/api/buy/course`,
//         EnrollmentData
//       );
  
//       dispatch({
//         type: "BuyCourseSuccess",
//         payload: data,
//       });
//       // Navigate("/mycourses/enrolledcourses");
//     } catch (error) {
//       CheckLoginServer(error?.response?.data, dispatch);
//       dispatch({
//         type: "BuyCoursefailure",
//         payload: error,
//       });
//     }
//   };