import React from "react";
import { BaseUrl } from "../../../../../../Actions/Base";
import ButtonsSide from "./ButtonsSide/ButtonsSide";
import ContentSide from "./ContentSide/ContentSide";
import "./CoursesTiles.css";
import { GetLocalStorage } from "../../../../../../Helpers/LocalStorage/LocalStorage";
import { Link } from "react-router-dom";
const CoursesTiles = ({ Courses, DeleteCourse, OnClick, loading }) => {

  return Courses?.length > 0
    ? Courses?.map((value) => {
        let Color =
          value?.Publish === "Approved"
            ? "text-white bg-[#AEB0FF]"
            : value?.Publish === "Rejected"
            ? "bg-[#B05AC4] text-white"
            : "bg-[#7F82F9] text-white";
        return (
          <div
            key={value?.InstituteCourseId}
            // onClick={""}
            className="course_card mb-[23px] bg-white w-full rounded-[15px] px-[4px] py-[6px] shadow 2xs:max-w-[300px] xs:max-w-[400px] sm:max-w-[500px] lg:max-w-[100%] "
          >
            <div className="flex max-w-[1300px] flex-col items-center gap-5 xl:flex-row">
              <div className="flex h-[142px]  w-[248px] items-center justify-center overflow-hidden rounded-lg">
                <img
                  className="h-full w-full max-w-[295px]"
                  src={`${BaseUrl}/api/Thumbnail/course?url=${value?.Course?.CourseThumbnail}`}
                  alt="Automotive Course"
                />
              </div>
              <div className="max-  flex w-full flex-col items-center justify-between lg:flex-row">
                {/* middle section */}
                <ContentSide value={value} />
                {/* right section */}
                <ButtonsSide
                  value={value}
                  OnClick={OnClick}
                  //   DeleteCourse={DeleteCourse}
                  Color={Color}
                />
                {GetLocalStorage("UserInfo").InstituteUserType === "Admin" && (
                  <div className="  flex flex-col gap-8">
                    <Link
                      to={`/UpdateCourse/${value?.InstituteCourseId}`}
                      className="rounded- ml-2 cursor-pointer bg-[#4C34AD] px-5 py-2 text-center text-xs font-bold text-white no-underline "
                    >
                      Edit
                    </Link>

                    <button
                      onClick={() => DeleteCourse(value?.InstituteCourseId)}
                      className=" rounded- ml-2 cursor-pointer bg-[#4C34AD] px-5 py-2 text-xs font-bold text-white "
                    >
                      Delete
                    </button>
                  </div>
                )}
                ;
              </div>
            </div>
          </div>
        );
      })
    : null;
};
export default CoursesTiles;
