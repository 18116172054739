import React from 'react'
import ImagePreviewerForEnrollmentLicense from '../../../../Components/ImagePreviewer/ImagePreviewerForEnrollmentLicense'

const LatestLicenseImage = ({LatestLicense, setLatestLicense, Err, setErr, UserInformation}) => {
    const onChangee = (e) => {
      console.log("Latest Image")
        if (e.target.files.length > 0) {
          delete Err?.LatestLicense;
          setErr(Err);
          const selectedImage = e.target.files[0];
          setLatestLicense(selectedImage);
        } else {
          setErr({ ...Err, LatestLicense: "Latest Image is required" });
        }


        console.log("LatestLicense", LatestLicense)
      };
    
  return (
    <div className="">
          <div className="student-PreviewContainer-Container w-full">
            <h3 className="text-5xs font-normal md:text-2xs lg:text-xs xl:text-xs  2xl:text-sm pb-2">
            Attach Your Latest License/Learner
            </h3>
            <ImagePreviewerForEnrollmentLicense
              UserInformation={UserInformation}
              Id="LicenseImage"
              PreviewStyle="Instructor_ImagePreview"
              BrowseBtnStyle="Instructor_BrowseBtn"
              ContainerStyle="Instructor_PreviewContainer"
              OnChange={onChangee}
              ImgUrl={LatestLicense}
            />
            <p className="h-[14px] text-[14px] font-normal text-[red]">
              {Err?.LatestLicense}
            </p>
          </div>
        </div>
  )
}

export default LatestLicenseImage
