import axios from "axios";
import polyline from "polyline-encoded";

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

export const directionsCallback = (response) => {
  if (response !== null) {
    if (response.status === "OK") {
      const route = response.routes[0];
      const leg = route.legs[0];
      const steps = leg.steps;
      const defaultWaypoints = steps.map((step) => ({
        location: {
          lat: step.end_location.lat(),
          lng: step.end_location.lng(),
        },
        stopover: true,
      }));
      return defaultWaypoints;
    } else {
      alert("Directions request failed due to " + response.status);
    }
  }
};

export const simulateMovement = () => {
  const startPoint = { lat: 43.65107, lng: -79.347015 }; // Starting point
  const endPoint = { lat: 43.6459, lng: -79.4111 }; // Ending point
  const numPoints = 20; // Number of points to simulate

  // Calculate increments for latitude and longitude for the outbound trip
  const latIncrementOutbound = (endPoint.lat - startPoint.lat) / numPoints;
  const lngIncrementOutbound = (endPoint.lng - startPoint.lng) / numPoints;

  // Generate points along the road for the outbound trip
  const pointsOutbound = [];
  for (let i = 0; i <= numPoints; i++) {
    const lat = startPoint.lat + i * latIncrementOutbound;
    const lng = startPoint.lng + i * lngIncrementOutbound;
    pointsOutbound.push({ lat, lng });
  }

  // Define intermediary points for the return trip
  const intermediaryPoints = [
    { lat: 43.655, lng: -79.36 },
    { lat: 43.655, lng: -79.37 },
    { lat: 43.655, lng: -79.38 },
    // Add more intermediary points as needed
  ];

  // Calculate increments for latitude and longitude for the return trip
  const latIncrementReturn = (startPoint.lat - endPoint.lat) / numPoints;
  const lngIncrementReturn = (startPoint.lng - endPoint.lng) / numPoints;

  // Generate points along the road for the return trip
  const pointsReturn = [];
  for (let i = 0; i <= numPoints; i++) {
    const lat = endPoint.lat + i * latIncrementReturn;
    const lng = endPoint.lng + i * lngIncrementReturn;
    pointsReturn.push({ lat, lng });
  }

  // Concatenate the points for both trips
  const points = pointsOutbound.concat(intermediaryPoints, pointsReturn);

  return points;
};

export const fixedSimulateMovement = () => {
  // Define waypoints along the route
  const waypoints = [
    { lat: 43.65107, lng: -79.347015 }, // Starting point
    { lat: 43.6515, lng: -79.3485 },
    { lat: 43.652, lng: -79.35 },
    { lat: 43.6525, lng: -79.3515 },
    { lat: 43.653, lng: -79.353 },
    { lat: 43.6535, lng: -79.3545 },
    { lat: 43.654, lng: -79.356 },
    { lat: 43.6545, lng: -79.3575 },
    { lat: 43.655, lng: -79.359 },
    { lat: 43.6555, lng: -79.3605 },
    { lat: 43.656, lng: -79.362 },
    { lat: 43.6565, lng: -79.3635 },
    { lat: 43.657, lng: -79.365 },
    { lat: 43.6575, lng: -79.3665 },
    { lat: 43.658, lng: -79.368 },
    { lat: 43.6585, lng: -79.3695 },
    { lat: 43.659, lng: -79.371 },
    { lat: 43.6595, lng: -79.3725 },
    { lat: 43.66, lng: -79.374 },
    { lat: 43.6605, lng: -79.3755 },
    { lat: 43.661, lng: -79.377 }, // Ending point
  ];

  return waypoints;
};

export const wayPointByRoad = () => {
  return [
    { lat: 43.689173, lng: -79.486018 },
    { lat: 43.689475, lng: -79.485677 },
    { lat: 43.68987, lng: -79.485198 },
    { lat: 43.69028, lng: -79.48468 },
    { lat: 43.690641, lng: -79.484222 },
    { lat: 43.691041, lng: -79.483744 },
    { lat: 43.691427, lng: -79.483275 },
    { lat: 43.691808, lng: -79.482815 },
    { lat: 43.69218, lng: -79.482364 },
    { lat: 43.692548, lng: -79.481921 },
    { lat: 43.692901, lng: -79.481493 },
    { lat: 43.693251, lng: -79.481069 },
    { lat: 43.693598, lng: -79.480647 },
    { lat: 43.693946, lng: -79.480227 },
    { lat: 43.694298, lng: -79.479801 },
    { lat: 43.694646, lng: -79.479377 },
    { lat: 43.694994, lng: -79.478951 },
    { lat: 43.695345, lng: -79.478523 },
    { lat: 43.695697, lng: -79.478098 },
    { lat: 43.696049, lng: -79.477674 },
    { lat: 43.696399, lng: -79.47725 },
    { lat: 43.696748, lng: -79.476828 },
    { lat: 43.6971, lng: -79.476405 },
    { lat: 43.697448, lng: -79.475981 },
    { lat: 43.6978, lng: -79.475556 },
    { lat: 43.698152, lng: -79.475134 },
    { lat: 43.698504, lng: -79.474711 },
    { lat: 43.698856, lng: -79.474286 },
    { lat: 43.699208, lng: -79.473861 },
    { lat: 43.69956, lng: -79.473437 },
    { lat: 43.69991, lng: -79.473016 },
    { lat: 43.70026, lng: -79.472596 },
    { lat: 43.70061, lng: -79.472176 },
    { lat: 43.70096, lng: -79.471756 },
    { lat: 43.70131, lng: -79.471336 },
    { lat: 43.70166, lng: -79.470916 },
    { lat: 43.70201, lng: -79.470496 },
    { lat: 43.70236, lng: -79.470076 },
    { lat: 43.70271, lng: -79.469656 },
    { lat: 43.70306, lng: -79.469236 },
    { lat: 43.70341, lng: -79.468816 },
    { lat: 43.70376, lng: -79.468396 },
    { lat: 43.70411, lng: -79.467976 },
    { lat: 43.70446, lng: -79.467556 },
    { lat: 43.70481, lng: -79.467136 },
    { lat: 43.70516, lng: -79.466716 },
    { lat: 43.70551, lng: -79.466296 },
    { lat: 43.70586, lng: -79.465876 },
    { lat: 43.70621, lng: -79.465456 },
    { lat: 43.70656, lng: -79.465036 },
    { lat: 43.70691, lng: -79.464616 },
    { lat: 43.70726, lng: -79.464196 },
    { lat: 43.70761, lng: -79.463776 },
    { lat: 43.70796, lng: -79.463356 },
    { lat: 43.70831, lng: -79.462936 },
    { lat: 43.70866, lng: -79.462516 },
    { lat: 43.70901, lng: -79.462096 },
    { lat: 43.70936, lng: -79.461676 },
    { lat: 43.70971, lng: -79.461256 },
    { lat: 43.71006, lng: -79.460836 },
    { lat: 43.71041, lng: -79.460416 },
    { lat: 43.71076, lng: -79.459996 },
    { lat: 43.71111, lng: -79.459576 },
    { lat: 43.71146, lng: -79.459156 },
    { lat: 43.71181, lng: -79.458736 },
    { lat: 43.71216, lng: -79.458316 },
    { lat: 43.71251, lng: -79.457896 },
    { lat: 43.71286, lng: -79.457476 },
    { lat: 43.71321, lng: -79.457056 },
    { lat: 43.71356, lng: -79.456636 },
    { lat: 43.71391, lng: -79.456216 },
    { lat: 43.71426, lng: -79.455796 },
    { lat: 43.71461, lng: -79.455376 },
    { lat: 43.71496, lng: -79.454956 },
    { lat: 43.71531, lng: -79.454536 },
    { lat: 43.71566, lng: -79.454116 },
    { lat: 43.71601, lng: -79.453696 },
    { lat: 43.71636, lng: -79.453276 },
    { lat: 43.71671, lng: -79.452856 },
    { lat: 43.71706, lng: -79.452436 },
    { lat: 43.71741, lng: -79.452016 },
    { lat: 43.71776, lng: -79.451596 },
    { lat: 43.71811, lng: -79.451176 },
    { lat: 43.71846, lng: -79.450756 },
    { lat: 43.71881, lng: -79.450336 },
    { lat: 43.71916, lng: -79.449916 },
    { lat: 43.71951, lng: -79.449496 },
    { lat: 43.71986, lng: -79.449076 },
    { lat: 43.72021, lng: -79.448656 },
    { lat: 43.72056, lng: -79.448236 },
    { lat: 43.72091, lng: -79.447816 },
    { lat: 43.72126, lng: -79.447396 },
    { lat: 43.72161, lng: -79.446976 },
    { lat: 43.72196, lng: -79.446556 },
    { lat: 43.72231, lng: -79.446136 },
    { lat: 43.72266, lng: -79.445716 },
    { lat: 43.72301, lng: -79.445296 },
    { lat: 43.72336, lng: -79.444876 },
    { lat: 43.72371, lng: -79.444456 },
    { lat: 43.72406, lng: -79.444036 },
    { lat: 43.72441, lng: -79.443616 },
    { lat: 43.72476, lng: -79.443196 },
    { lat: 43.72511, lng: -79.442776 },
    { lat: 43.72546, lng: -79.442356 },
  ];
};

export const fetchCoordinates = async (address) => {
  const response = await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json`,
    {
      params: {
        address,
        key: apiKey,
      },
    }
  );
  return response.data.results[0].geometry.location;
};

const fetchRoutePoints = async (origin, destination) => {
  const response = await axios.get(
    `https://maps.googleapis.com/maps/api/directions/json`,
    {
      params: {
        origin: `${origin.lat},${origin.lng}`,
        destination: `${destination.lat},${destination.lng}`,
        mode: "driving",
        key: apiKey,
      },
    }
  );
  const encodedPoints = response.data.routes[0].overview_polyline.points;
  return polyline.decode(encodedPoints);
};

const selectRoutePoints = (points, numPoints) => {
  const step = Math.max(1, Math.floor(points.length / numPoints));
  return points.filter((_, index) => index % step === 0).slice(0, numPoints);
};

export const fetchRouteData = async () => {
  const originAddress = "572 Rogers Rd, York, ON M6M 1B6, Canada";
  const destinationAddress = "L4C 6Y2, Canada"; // This is the red marker location from the image

  try {
    const originCoords = await fetchCoordinates(originAddress);
    const destinationCoords = await fetchCoordinates(destinationAddress);

    const routePoints = await fetchRoutePoints(originCoords, destinationCoords);
    const selectedPoints = selectRoutePoints(routePoints, 150);

    return selectedPoints;
  } catch (error) {
    return error.message;
  }
};
