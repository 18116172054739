import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import "../CourseAbout/CourseAbout.css";
import { BaseUrl } from '../../../Actions/Base';
import { RatingAndReviewd } from '../CourseIntro/Components/RatingAndReview';

const CourseInstructors = ({ InstructorDetails, InstructorRef }) => {
    const [Instructors, setInstructors] = useState([]);
    useEffect(() => {
        setInstructors(InstructorDetails)
    }, [Instructors, InstructorDetails])
    return (
        <div ref={InstructorRef}>
            <h3 className='text-[38px] text-center font-bold'>Instructors</h3>
           
                <div className='bg-white  flex justify-center'>
                    <div className='flex w-[70%] justify-center'>
                        <div className='flex w-max flex-wrap  justify-center gap-x-10 gap-y-16 py-8 '>
                            {Instructors?.map((value, index) => {
                                let Style
                                if (index === 2 || index === 3) Style = "relative -left-11"
                                
                                const Image = `${BaseUrl}/api/images/Instructors?url=${value?.ProfileImage}`;
                                const licenses = value?.InstructorLicenses.map((val, i)=> (val?.LicenseType?.LicenseTypeName)).join(", ")
                               
                                return (<div key={value.InstructorId} className={`boxShadow pb-3 rounded-lg text-black flex flex-col w-[261px] items-center gap-4`}>
                                    <span className='w-full rounded-t-lg'> <img className='w-full h-[168px] rounded-t-lg'  src={Image} alt="" /></span>
                                    <span className='flex flex-col text-center   gap-2'>
                                        <h3 className='text-2xs font-semibold text-[#2D2366]'>
                                            {value?.User?.FirstName + " " + value?.User?.LastName}
                                        </h3>
                                        <p className='text-[#2D2366] text-center' >
                                        Certified in class <br /> <span>{licenses}</span> Licensing
                                        </p>
                                        <div className='self-center w-full'>
                                            <div className='flex w-full text-center justify-center'>

                                            <RatingAndReviewd Instructor={true} />
                                            </div>
                                        <p className='text-[#2D2366] font-semibold' >
                                        (Reviewed by 2000 Students)
                                        </p>
                                        </div>
                                    </span>
                                </div>
                                )

           
                            })}
                        </div>
                    </div>
           
            </div>
        </div>
    )
}

export default CourseInstructors