import React from "react";
import "./GetReady.css";
import PrepareTest from "./Components/PrepareTest";
import LicenseTypes from "../LandingPage/LicenseTypes/LicenseTypes";
import LicenseTypesGetReady from "./Components/LicenseTypesGetReady";
import img from "./Assets/landingContainer.png";
import Footer from "../../Components/Footer/Footer";
import SearchTiles from "../LandingPage/SearchTiles/SearchTiles";
import GetReadyTiles from "./Components/GetReadyTiles/GetReadyTIles";
import GetReadyKnowledge from "./Components/GetReadyKnowledge/GetReadyKnowledge";
import GetReadyKnowledgeTest from "./Components/GetReadyKnowledgeTest/GetReadyKnowledgeTest";
import GetReadyRoadTest from "./Components/GetReadyRoadTest/GetReadyRoadTest";
// import useCheckLogin from "../../Helpers/CustomHooks/CheckLogin";

function Getready() {
    // useCheckLogin(true, ["Student"]);

    return (
        <div className=" mt-20 ">
            <div className="relative  flex h-[697px]   w-full flex-col lg:items-center lg:justify-center   ">
                <img
                    loading="lazy"
                    className=" absolute  h-full   w-full md:inline lg:inline"
                    src={require("./Assets/landingcar.png")}
                    alt=""
                />
                <img
                    className=" absolute h-full w-full   lg:inline lg:hidden"
                    src={require("./Assets/mobile1Group 47724.png")}
                    alt=""
                />
                <div className="absolute top-20 z-10 flex flex-col gap-5 px-5 text-center xs:top-10 xs:w-full sm:top-10 sm:w-full md:top-10 md:w-full lg:top-auto lg:mx-auto lg:w-4/5 lg:text-left">
                    <h2 className="LandingPagetext text-[20px] text-white xs:text-[30px] sm:text-[30px] md:text-[45px] lg:text-[45px]">
                        Your Ultimate Destination for &nbsp;
                        <br className="" />
                        Driving Success
                    </h2>
                    <p className="Regularfont w-full text-white xs:text-[26px] sm:text-[26px] md:text-[26px] lg:mx-0 lg:w-1/2 lg:text-[26px]">
                        Discover Vehicle Learning Innovation, your go-to
                        platform for driving success. With years of expertise,
                        we specialize in providing essential driving knowledge
                        and road test practice for new drivers in Canada. Our
                        goal? Ensuring you ace your driving test on the first
                        try.
                    </p>
                </div>
            </div>

            <GetReadyTiles />
            <GetReadyKnowledge />
            <GetReadyKnowledgeTest />
            <GetReadyRoadTest />
            <Footer />
        </div>
    );
}

export default Getready;
