import React from "react";
import GeoGraph from "./GeoGraph";
import { Link } from "react-router-dom";



function ManageLogin({staff, heading, paragraph, link, btnText}) {
    return (
        <div className="manage_login_card shadow mt-4 md:mt-16  w-[332px] md:w-[380px]  rounded-[15px] border-[1px] border-solid  border-[#D4D4D4] bg-white px-[26px] py-[37px] drop-shadow-[0px_2px_3px_rgba(0,0,0,0.04)] xs:self-center sm:self-center min-w-[320px]  md:max-w-[400px]">
            <h3
                style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                className="heading_2 font-bold text-[#4C34AD]"
            >
                {/* Home Pickup */}
               {heading}
            </h3>

            <p className="py-4 font-segoe-ui text-7xs 2xs:text-6xs xs:text-5xs sm:text-4xs  2xl:ManageLogin">
                {/* Enable home pickup service for the student. */}
                {paragraph}
            </p>
            <div className=" flex w-full justify-center ">
                <div className="img w-[60%] py-6">
                    <img className="w-full" src={require("./images/Mask Group 219.png")} alt="" />
                </div>
            </div>

            <div className="text-center">
                <Link to={`${link}`}>
                    <button
                        type="button"
                        class="focus:ring-blue-300 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mt-5 mb-2 cursor-pointer rounded-lg border-0 bg-[#4c34ad] px-10 py-2 text-4xs font-medium text-white outline-none  hover:bg-[#6266e8] transition-all hover:scale-95 focus:outline-none focus:ring-4 md:text-2xs"
                    >
                        {/* Pickup */}
                         {btnText}
                    </button>
                </Link>
            </div>
        </div>
    );
}

export default ManageLogin;
