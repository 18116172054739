import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LogOut } from "../../../../../Actions/UserA";

import {
    auth,
    provider,
} from "../../../../../Pages/LoginAndRegister/Components/UserSigning/Components/LeftSide/ContinueWIth/config";
import { signOut } from "firebase/auth";
import { GetLocalStorage } from "../../../../../Helpers/LocalStorage/LocalStorage";
import ForgotPassword from "../../../../../popups/forget-password/ForgotPassword";
import { useAuthContext } from "../../../../../context/authContext";
import { SocketContext } from "../../../../../App";

const DropDownOptions = ({ HideOnClick }) => {
    const socket = useContext(SocketContext);
    const { UserInfo } = useSelector((state) => state.LoginSignupReducer);

    const { setChangePassPopup, changePassPopup } = useAuthContext();
    const Dispatch = useDispatch();
    const LougOutUser = () => {
        signOut(auth, provider)
            .then(() => {
                console.log("Sign-out successful.");
                socket?.emit("offline", { UserId: UserInfo?.UserId });
                // window.location.reload()
            })
            .catch((error) => {
                // An error happened.
            });
        Dispatch(LogOut());
    };

    const ClickMethod = (e, cb) => HideOnClick(e, cb);
    let LinkStyle =
        "text-2xs text-black lg:text-black md:text-xs lg:text-3xs xl:text-2xs 2xl:text-[21px] hover:text-[#A1A3EF]  no-underline SemiBold ";

    const handleChangePass = () => {
        setChangePassPopup(true);
    };
    return (
        <>
            <div className="z-20 flex w-fit  flex-col items-start bg-white px-3 py-2">
                {GetLocalStorage("UserInfo")?.InstituteUserType === "Staff" ||
                GetLocalStorage("UserInfo")?.InstituteUserType ===
                    "Supervisor" ||
                GetLocalStorage("UserInfo")?.InstituteUserType ===
                    "Instructor" ? (
                    <>
                        <div>
                            <div class="mb-4 flex items-center justify-between"></div>
                            <div class="flow-root ">
                                <ul
                                    role="list"
                                    class="divide-inherit  list-none divide-y dark:divide-gray-700"
                                >
                                    <li class="py-3 sm:py-4">
                                        <div class="flex items-center">
                                            <div class="flex-shrink-0"></div>
                                            <div class="ms-4 min-w-0 flex-1  ">
                                                <p class=" text-4xs font-semibold  text-[#9e57f3]  lg:text-sm">
                                                    {GetLocalStorage("UserInfo")
                                                        .FirstName +
                                                        " " +
                                                        GetLocalStorage(
                                                            "UserInfo"
                                                        ).LastName}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="py-3 sm:py-4">
                                        <div class="flex items-center">
                                            <div class="flex-shrink-0"></div>
                                            <div class="ms-4 min-w-0 flex-1">
                                                <p class="truncate text-4xs text-gray-500 hover:text-[#9e57f3] dark:text-gray-400 lg:text-sm">
                                                    {/* {GetLocalStorage("UserInfo")
                                                        ?.Staff?.Role &&
                                                        GetLocalStorage(
                                                            "UserInfo"
                                                        )
                                                            ?.Staff?.Role.charAt(
                                                                0
                                                            )
                                                            .toUpperCase() +
                                                            GetLocalStorage(
                                                                "UserInfo"
                                                            )?.Staff?.Role.slice(
                                                                1
                                                            )} */}

                                                    {GetLocalStorage("UserInfo")
                                                        ?.Staff?.Role
                                                        ? GetLocalStorage(
                                                              "UserInfo"
                                                          )
                                                              ?.Staff?.Role.charAt(
                                                                  0
                                                              )
                                                              .toUpperCase() +
                                                          GetLocalStorage(
                                                              "UserInfo"
                                                          )?.Staff?.Role.slice(
                                                              1
                                                          )
                                                        : "Instructor"}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    {/* {GetLocalStorage("UserInfo")
                                        ?.InstituteUserType ===
                                    "Instructor" ? null : ( */}
                                    <li class="py-3 sm:py-4">
                                        <div class="flex items-center ">
                                            <div class="flex-shrink-0"></div>
                                            <div class="ms-4 min-w-0 flex-1">
                                                <p
                                                    class="cursor-pointer truncate text-4xs text-gray-500 hover:text-[#9e57f3] dark:text-gray-400 lg:text-sm"
                                                    onClick={handleChangePass}
                                                >
                                                    Change password
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    {/* )} */}
                                    {/* <li class="py-3 sm:py-4">
                                        <div class="flex items-center ">
                                            <div class="flex-shrink-0"></div>
                                            <div class="ms-4 min-w-0 flex-1">
                                                <p
                                                    class="cursor-pointer truncate text-4xs text-gray-500 hover:text-[#9e57f3] dark:text-gray-400 lg:text-sm"
                                                    onClick={handleChangePass}
                                                >
                                                    Change password
                                                </p>
                                            </div>
                                        </div>
                                    </li> */}
                                    <li class="py-3 sm:py-4">
                                        <div class="flex items-center">
                                            <div class="flex-shrink-0"></div>
                                            <div class="ms-4 min-w-0 flex-1">
                                                <p
                                                    class="cursor-pointer truncate text-4xs text-gray-500 hover:text-[#9e57f3] dark:text-gray-400 lg:text-sm"
                                                    onClick={(e) =>
                                                        ClickMethod(
                                                            e,
                                                            LougOutUser
                                                        )
                                                    }
                                                >
                                                    Logout
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </>
                ) : (
                    <Link
                        className={`${LinkStyle} font-normal`}
                        onClick={(e) => ClickMethod(e, LougOutUser)}
                    >
                        Logout
                    </Link>
                )}
            </div>
        </>
    );
};

export default DropDownOptions;
