import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import PaymentTop from "./Components/PaymentTop";
import ReactDOM from "react-dom";
import { PaypalBtn } from "./Components/PaypalPayment/PaypalPayment";
import CourseEnrollmentPopup from "../CourseEnrollmentPopup";
import { useDispatch, useSelector } from "react-redux";
import PaymentsDetailsSection from "./Components/paymentDetails/PaymentsDetailsSection";


const Payment = ({ StudentImage, Package, EnrollmentData, Err, setErr, StudentImageCheck, UserInformation, PaymentDetailPopup, setPaymentDetailPopup, LatestLicense }) => {
    const dispatch = useDispatch()
    const [PaymentMethod, setPaymentMethod] = useState();
    const [Installment, setInstallment] = useState();
    const Navigate = useNavigate();
    const [Success, setSuccess] = useState();
    const [showPopup, setShowPopup] = useState(false);
    const [popupContent, setPopupContent] = useState(null);
    const PaypalBtnRef = useRef();
    const PayPalButton = window.paypal?.Buttons?.driver("react", {
        React,
        ReactDOM,
    });
    // useEffect(() => {
    //     if (Success) alert("form submitted successfully");

    //     // Navigate("/mycourses/enrolledcourses");
    // }, [Success]);
  
    let PricesPlusTax;   //complete price of COURSE PACKAGE with tax(if available) and discount(if available) 
    console.log("PopupContent", popupContent);
    console.log("EnrollmentDataaaaa=>", EnrollmentData);
    const handleEnrollmentSuccess = (courseDetail) => {
        setPopupContent(courseDetail);
        setShowPopup(true);
    };
    const onConfirm = () => {
        Navigate("/mycourses/enrolledcourses");
        setShowPopup(false);
    };
    console.log("PaymentDetailPopup", PaymentDetailPopup)
    return (
        <div className="flex flex-col items-center">
            {/* {Success && alert("form submitted successfully")} */}
            <PaymentTop
            StudentImageCheck={StudentImageCheck}
                setInstallment={setInstallment}
                Installment={Installment}
                Err={Err}
                setErr={setErr}
                EnrollmentData={EnrollmentData}
                StudentImage={StudentImage}
                LatestLicense={LatestLicense}
                dispatch={dispatch}
                setPaymentDetailPopup={setPaymentDetailPopup}
                PricesPlusTax={PricesPlusTax}
            />

        
            {  PaymentDetailPopup && <div className="w-full bg-[#F6F5F5] flex justify-center px-3 sm:px-0 pb-4">
               <PaymentsDetailsSection PricesPlusTax={PricesPlusTax} Installment={Installment} PaymentDetailPopup={PaymentDetailPopup} setPaymentDetailPopup={setPaymentDetailPopup} EnrollmentData={EnrollmentData}  />
                </div>
            }
            <div className="flex w-full flex-col gap-11 py-10">
                <h1 className="SemiBoldItalic m-0 text-center text-xs text-black sm:text-base md:text-lg xl:text-[38px]">
                    Set up your Credit or Debit card Details
                </h1>
                <PaymentGatewaysImages
                    setPaymentMethod={setPaymentMethod}
                    Err={Err}
                    setErr={setErr}
                    Installment={Installment}
                />
            </div>

            <div className="z-10 flex w-full flex-col items-center bg-[#F6F5F5] py-6">
                {PaymentMethod === "Paypal" ? (
                    <PaypalBtn
                        handleEnrollmentSuccess={handleEnrollmentSuccess}
                        setSuccess={setSuccess}
                        Package={Package}
                        Installment={Installment}
                        PayPalButton={PayPalButton}
                        PaypalBtnRef={PaypalBtnRef}
                        EnrollmentData={EnrollmentData}
                        StudentImage={StudentImage}
                        LatestLicense={LatestLicense}
                        currency="USD"
                        setErr={setErr}
                        Err={Err}
                        UserInformation={UserInformation}
                        dispatch={dispatch}
                    />
                ) : null}
                {showPopup && <CourseEnrollmentPopup onConfirm={onConfirm} />}
            </div>
        </div>
    );
};

function PaymentGatewaysImages({ setPaymentMethod, Err, setErr, Installment }) {
    const OnClick = (Method) => {
        if (!Installment) {
            setErr({ ...Err, PaymentTypeErr: "Please select payment type" });
            return;
        }
        setPaymentMethod(Method);
    };

    let ImageStyle =
        "h-[75px] sm:h-24 md:h-28 lg:h-[120px] xl:h-36 2xl:h-[145px] cursor-pointer";
    return (
        <div className=" flex h-32 w-full justify-center gap-6 overflow-hidden lg:gap-4 xl:gap-10 2xl:gap-11">
            <img
                className={`${ImageStyle} `}
                src={require("./Assets/DebitCardIconL.png")}
                alt=""
            />
            <img
                className={`${ImageStyle} hidden md:inline-block`}
                src={require("./Assets/BitcoinIconL.png")}
                alt=""
            />
            <img
                className={`${ImageStyle} hidden lg:inline-block`}
                src={require("./Assets/JCBIconL.png")}
                alt=""
            />
            <img
                className={`${ImageStyle}`}
                src={require("./Assets/PaypalIconL.png")}
                alt=""
                onClick={() => OnClick("Paypal")}
            />
            <img
                className={`${ImageStyle} hidden 2xl:inline-block`}
                src={require("./Assets/DinersClubIconL.png")}
                alt=""
            />
            <img
                className={`${ImageStyle}`}
                src={require("./Assets/CreditCardIconL.png")}
                alt=""
            />
        </div>
    );
}

export default Payment;
