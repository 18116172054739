import { useEffect } from "react";
import img from "../CourseAbout/Assets/award.png";
import { BaseUrl } from "../../../Actions/Base";

const AwardsSection = ({ AwardSectionRef, CourseInfo }) => {
  const data = [
   
    {
      image: img,
      head: "Winner 2024 Consumer Choice Award",
      para: "Participated in Driver Training Program in 2024 in Canada Lorem Ipsum.",
    },
    {
      image: img,
      head: "Winner 2024 Consumer Choice Award",
      para: "Participated in Driver Training Program in 2024 in Canada Lorem Ipsum.",
    },
    {
      image: img,
      head: "Winner 2024 Consumer Choice Award",
      para: "Participated in Driver Training Program in 2024 in Canada Lorem Ipsum.",
    },
    {
      image: img,
      head: "Winner 2024 Consumer Choice Award",
      para: "Participated in Driver Training Program in 2024 in Canada Lorem Ipsum.",
    },
    {
      image: img,
      head: "Winner 2024 Consumer Choice Award",
      para: "Participated in Driver Training Program in 2024 in Canada Lorem Ipsum.",
    },
    {
      image: img,
      head: "Winner 2024 Consumer Choice Award",
      para: "Participated in Driver Training Program in 2024 in Canada Lorem Ipsum.",
    },
    
  ];

  return (
    <>
      <div className={`flex w-full justify-center mb-10`} ref={AwardSectionRef}>
        <div className="flex w-11/12  flex-col gap-10 md:w-4/5">
          <div className="flex w-full flex-col gap-6 md:w-2/6 ">
            <span className="flex items-end gap-2">
              <span className="relative bottom-[5px] h-1 w-16 bg-[#A1A3EF]"></span>
              <h4 className="SemiBold text-4xs text-[#A1A3EF] md:text-3xs xl:text-2xs">
                Awards
              </h4>
            </span>
            <div>
              <h1 className=" w-[400px] text-start m-0 text-lg  text-lightBlack md:text-xl lg:text-[40px]">
                Excellence Rewarded
              </h1>
            </div>
          </div>

          <div className="flex flex-wrap justify-center gap-10">
            {CourseInfo?.Institute?.Awards.map((data) => {
              const AwardImage = `${BaseUrl}/api/award?url=${data?.AwardThumbnail}`
              return (
                <>
                  <div className="award-card boxShadow flex w-[426px] gap-4  rounded-lg p-2">
                    <div className="img flex items-center justify-center">
                      <img
                        className="h-[129px] w-[126px]"
                        src={AwardImage}
                        alt="awardimage"
                      />
                    </div>
                    <div className="content flex flex-col gap-2 font-segoe-ui">
                      <p className="text-2xs font-semibold md:text-xs xl:text-base">
                        {data?.Title}
                      </p>
                      <p className="text-2xs  xl:text-sm font">
                        {data?.Description}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default AwardsSection;
