import React from "react";
import { useNavigate } from "react-router-dom";
import "./InstructorPopup.css";
const InstructorPopup = ({ message, type }) => {
    const Navigate = useNavigate();
    const OnClick = () => Navigate("/instructors/list");
    // const staffNavigate
    const staffNavigate = () => Navigate("/staff/List");

    return (
        <div className="fixed left-0 top-0 z-30 flex h-screen w-screen items-center justify-center bg-[#00000029]">
            <div className="relative flex aspect-[353_/_221] h-fit w-full max-w-[360px] flex-col gap-[10px] rounded-[20px] bg-white px-8 py-5">
                <div className="flex flex-col items-center gap-3">
                    <h3 className="HeadingBorder text-5xs font-normal">
                        Congratulations !!
                    </h3>
                    <h3 className="text-6xs font-normal">
                        {message
                            ? message
                            : "An Instructor has been added to the Inventory"}
                    </h3>
                </div>
                <div className="flex flex-col items-center gap-[6px]">
                    <img
                        className="h-20"
                        src={require("./Assets/Instructor Success.svg").default}
                        alt=""
                    />
                    <button
                        type="button"
                        className="BrandingButton w-fit cursor-pointer rounded-[14px] py-1 px-8 text-6xs"
                        // onClick={OnClick}
                        onClick={type ? staffNavigate : OnClick}
                    >
                        Done
                    </button>
                </div>
                <img
                    className="absolute right-3 top-[6px] cursor-pointer"
                    src={require("./Assets/Cross.svg").default}
                    alt=""
                    onClick={type ? staffNavigate : OnClick}
                />
            </div>
        </div>
    );
};

export default InstructorPopup;
