import React, { createContext, useContext, useState } from 'react';

// Create a context
const CourseContext = createContext();

// Create a provider component
export const CourseProvider = ({ children }) => {
  // State to store driving, in-class, and online hours
  const [drivingHours, setDrivingHours] = useState(0);
  const [inClassHours, setInClassHours] = useState(0);
  const [onlineHours, setOnlineHours] = useState(0);

  return (
    <CourseContext.Provider
      value={{
        drivingHours,
        setDrivingHours,
        inClassHours,
        setInClassHours,
        onlineHours,
        setOnlineHours,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
};

// Custom hook to use the Course context
export const useCourse = () => {
  return useContext(CourseContext);
};
