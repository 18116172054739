import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SocketContext } from "../../../../../App";
import "./Popups.css";
import { useAuthContext } from "../../../../../context/authContext";
const Popups = ({ setEdit, InstituteCourseId }) => {
  const [Response, setResponse] = useState();
  const { UserInfo } = useSelector((Store) => Store.LoginSignupReducer);
  const Socket = useContext(SocketContext);

  const Close = () => setEdit(false);

  const RejectResponse = () => setResponse("reject");

  const ApproveResponse = () => {
    setResponse("approve");
    Socket.emit("ApproveCourse", {
      InstituteCourseId,
      InstituteId: UserInfo?.Institute?.InstituteId,
    });
  };
  return (
    <div className="fixed left-0 top-0 z-30 flex h-screen w-screen items-center justify-center bg-[#00000029]">
      {!Response ? (
        <ResponsePopup
          Close={Close}
          RejectResponse={RejectResponse}
          ApproveResponse={ApproveResponse}
        />
      ) : null}
      {Response === "reject" ? (
        <Reject Close={Close} InstituteCourseId={InstituteCourseId} />
      ) : null}
      {Response === "approve" ? (
        <Approved InstituteCourseId={InstituteCourseId} setEdit={setEdit} />
      ) : null}
    </div>
  );
};

const ResponsePopup = ({ Close, RejectResponse, ApproveResponse }) => {
  const { popupType } = useAuthContext();

  return (
    <div className="CourseResponsePopupContainer items-center gap-3 pb-7">
      <img
        className="absolute top-2 right-5 h-[22px] cursor-pointer"
        src={require("./Assets/Cross.svg").default}
        alt=""
        onClick={Close}
      />
      <div className="text-center text-xs">Change status</div>
      <img src={require("./Assets/Approve.svg").default} alt="" />
      <div className="text-center text-3xs">Action needed</div>
      <div className="flex w-full justify-center gap-6">
        {popupType === "rejectCourse" ? (
          <button
            className="ApprovePopupBtn bg-[#B05AC4] "
            onClick={RejectResponse}
          >
            Reject
          </button>
        ) : null}

        {popupType === "approveCourse" ? (
          <button
            className="ApprovePopupBtn bg-[#AEB0FF]"
            onClick={ApproveResponse}
          >
            Approve
          </button>
        ) : null}
      </div>
    </div>
  );
};
const Reject = ({ Close, InstituteCourseId }) => {
  const { UserInfo } = useSelector((Store) => Store.LoginSignupReducer);
  const Socket = useContext(SocketContext);
  const [Reason, setReason] = useState();
  const SubmitRejection = () => {
    Socket.emit("RejectCourse", {
      PublishResponse: Reason,
      InstituteCourseId,
      InstituteId: UserInfo?.Institute?.InstituteId,
    });
    Close();
  };
  return (
    <div className="CourseResponsePopupContainer gap-4 px-9 py-7">
      <img
        className="absolute top-2 right-5 h-[22px] cursor-pointer"
        src={require("./Assets/Cross.svg").default}
        alt=""
        onClick={Close}
      />
      <div className="text-2xs text-black">Rejection Reason</div>
      <label htmlFor="RejectionReason" className="text-3xs text-black">
        Enter a rejection reason for the staff.{" "}
      </label>
      <textarea
        id="RejectionReason"
        className="w-full resize-none px-3 text-[17px]"
        rows="4"
        placeholder="Enter a rejection reason for the staff."
        onClick={(e) => setReason(e.target.value)}
      >
        Enter a rejection reason for the staff.
      </textarea>
      <div className="flex w-full justify-center gap-6">
        <button className="ApprovePopupBtn bg-[#B05AC4] " onClick={Close}>
          Cancel
        </button>
        <button
          className="ApprovePopupBtn bg-[#B05AC4] "
          onClick={SubmitRejection}
        >
          Submit
        </button>
      </div>
    </div>
  );
};
const Approved = ({ setEdit }) => {
  useEffect(() => {
    setTimeout(() => {
      setEdit(false);
    }, [1700]);
  }, [setEdit]);
  return (
    <div className="flex gap-2 bg-white px-5 py-6">
      <img className="w-5" src={require("./Assets/Tick.svg").default} alt="" />
      <div className="text-7xs text-[#AEB0FF]">SUBMITTED</div>
    </div>
  );
};

export default Popups;
