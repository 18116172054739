import React, { useState, useEffect } from "react";
import CourseSearch from "./CourseSearch/CourseSearch";
import "./LandingPage.css";
import LicenseTypes from "./LicenseTypes/LicenseTypes";
import Footer from "../../Components/Footer/Footer";
import SearchTiles from "./SearchTiles/SearchTiles.jsx";
import VehicleType from "./VehicleType/VehicleType";
import useCheckLogin from "../../Helpers/CustomHooks/CheckLogin";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { OpenLoginSignUp } from "../../Actions/ToggleSignupA";
import ForgotPassword from "../../popups/forget-password/ForgotPassword.jsx";
import NewPassword from "../../popups/newPassword/NewPassword.jsx";
import OtpPopup from "../../popups/otpPopup/OtpPopup.jsx";
import { useAuthContext } from "../../context/authContext.js";
import Terms from "../../popups/termsAndConditons/Terms.jsx";
import Courses from "./CourseSearch/Courses.jsx";
import Stars from "../../Components/starsAndReviews/Stars.jsx";
import { useLocationContext } from '../../context/locationContext.js'
import axios from 'axios';


const LandingPage = ({ children }) => {
  useCheckLogin(false, []);
  const activeValue = sessionStorage.getItem("searchTiles")
  const [activeSection, setActiveSection] = useState(
    activeValue ? activeValue : null
  );
  const [searchCourse, setSearchCourse] = useState("");

  const { forgotPass, resetPass, terms, otpPopup } = useAuthContext();
  const { UserInfo } = useSelector((Store) => Store.LoginSignupReducer);
  const { location, setLocation } = useLocationContext(); // Use the context

  console.log("searchCourse", searchCourse);

  // Check if location is already set
  const isLocationSet = location?.country || location?.state || location?.city || location?.postalCode;

  //to get user lat and long and to convert them in user current location
  useEffect(() => {
    if (!isLocationSet) {
    const fetchLocation = async () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            try {
              const response = await axios.get(
                `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
              );
              const address = response.data.address || {}; // Use an empty object as a default
              const { country, city, state, postcode } = address;
              
              // Log the location details to the console
              console.log('Country:', country || 'Country not available');
              console.log('State/Region:', state || 'State/Region not available');
              console.log('City:', city || 'City not available');
              console.log('Postal Code:', postcode || 'Postal code not available');

               // Update context with the location details
              setLocation({
                country: country || '',
                state: state || '', // Depending on the response, `state` or `region` might be available
                city: city || '',
                postalCode: postcode || '', // Use `postcode` for postal code
              });
            } catch (error) {
              console.error('Error fetching location details:', error);
            }
          },
          (error) => {
            console.error('Error getting location:', error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    };
  
    fetchLocation();
  }
  }, []);


/*    useEffect(() => {
    const fetchLocation = async () => {
      const response = await fetch(`http://api.ipstack.com/check?access_key=7218d158080172064176211ab89df0e2`);
      const data = await response.json();
      console.log("Ip address data",data); // Contains country, state, city, latitude, longitude, etc.
    };
    fetchLocation();
  }, []); 

  useEffect(() => {
    // Function to fetch the user's IP and location
    const fetchLocation = async () => {
      try {
        const token = 'bcea08fed6a1ee'; // Replace with your actual token from ipinfo.io
        const response = await fetch(`https://ipinfo.io?token=${token}`);
        
        if (!response.ok) {
          throw new Error('Failed to fetch location data');
        }
        
        const data = await response.json();
        
        console.log("Ip address in ip info is data",data); // Outputs location data including city, region, and postal code
      } catch (err) {
        console.error('Error fetching location:', err);
      }
    };

    fetchLocation();
  }, []); */
  

  return (
    <>
      {terms ? <Terms /> : null}
      {forgotPass && <ForgotPassword />}
      {otpPopup ? <OtpPopup /> : null}
      {resetPass ? <NewPassword /> : null}
      <div className="">
        <div className="bgGradient absolute -z-10 h-[745px] w-full rounded-b-[300px]"></div>
        <div className="relative mb-40 flex   w-full flex-col items-center md:mb-10 md:block">
          <picture className="-z-10 hidden overflow-hidden md:block">
            <img
              className="LandingContainerLite relative -top-5 -left-28"
              onLoad={() => console.log("load")}
              onError={(e) => console.log("error", e)}
              src={require("./Assets/LiteLandingContainer.svg").default}
              alt=""
            />
          </picture>

          <div className="h- -top-0 w-full rounded-b-[300px] md:absolute md:h-auto md:min-h-fit md:rounded-[0px] md:bg-[#291c5f00] xl:w-full ">
            {UserInfo?.UserName ? null : <LoginRegister />}
            <picture className=" ">
              <source
                media="(max-width:635px)"
                className=""
                srcSet={require("./Assets/Rectangle.svg").default}
              />

              <source
                media="(min-width:768px)"
                srcSet={require("./Assets/LandingContainer.svg").default}
              />
              <img
                className="hidden w-full md:inline-block"
                loading="lazy"
                src={require("./Assets/LandingContainer.svg").default}
                alt=""
              />
            </picture>
          </div>
          
          <div className="top-32  left-0 mt-[150px] flex w-full flex-col items-center justify-around md:absolute md:top-[5rem] md:mt-0 md:flex-row">
            <div className=" mx-1 flex w-[80%]  md:w-[65%] lg:w-[55%] xl:w-[50%] flex-col gap-10 sm:-top-0 md:relative  md:left-10 md:z-10 md:m-0   md:gap-1 md:px-10 md:pb-4 lg:gap-10 lg:pb-4  xl:pb-1 ">
              <h2 className="LandingPagetext text-pretty text-center   text-white md:w-auto  text-2xs xl:text-sm md:text-left  2xl:text-lg ">
                Welcome to world’s first marketplace to facilitate you to obtain
                driving/operator license innovatively!!!
                
              </h2>
              
              <p className="Regularfont text-pretty text-center   font-thin text-white md:text-left text-4xs xl:text-2xs   2xl:text-sm ">
                Do you want to learn driving and obtain driving license? <br />
                You are at the right place to explore various options at your
                fingertips. Navigate below to discover the new world of vehicle
                learning experience.
              </p>
            </div>

            <div className="flex justify-center items-center w-[70%] md:w-[35%] lg:w-[45%] xl:w-[50%]">
              <img
                className=" mt-4 w-full md:mt-0 relative lg:top-[3.5rem] lg:right-[2rem]"
                src={require("./Assets/LandingPageCar.png")}
                alt=""
              />
            </div>
          </div>
        </div>
        <SearchTiles
          activeSection={activeSection}
          setActiveSection={setActiveSection}
        />
        {activeSection === "vehicle" && <VehicleType />}
        {activeSection === "license" && <LicenseTypes />}
        {activeSection === "courses" && (
          <CourseSearch
            setSearchCourse={setSearchCourse}
            searchCourse={searchCourse}
          />
        )}
        {activeSection === "courses" && searchCourse && (
          <Courses searchCourse={searchCourse} />
        )}
        {/* <LicenseTypes /> */}
        {/* <PopularCourses /> */}
        {/* <SpecialCourses /> */}
        {/* <CourseSearch /> */}
        {/* <LatestNews /> */}
        <Footer FooterBgColor="#F1FAFF" />
      </div>
    </>
  );
};

function LoginRegister() {
  const Dispatch = useDispatch();

  return (
    <nav
      id="RegisterPortion"
      className="absolute right-7 top-24 z-20 hidden w-auto items-center justify-center gap-9 lg:flex"
    >
      <Link
        className="Regularfont  text-xs text-white no-underline xl:text-2xs"
        onClick={() => Dispatch(OpenLoginSignUp("Sign In", true))}
      >
        Login
      </Link>
      <Link
        className={`Regularfont rounded-[25px] bg-[#EA52C6] text-center text-xs text-white
                                  no-underline     lg:py-3         lg:px-7
                                  xl:py-4     xl:px-10        xl:text-2xs 
                      2xl:py-5                 2xl:px-12       `}
        onClick={() => Dispatch(OpenLoginSignUp("Sign Up", true))}
      >
        Register
      </Link>
    </nav>
  );
}
export default LandingPage;
