import React, { useEffect, useState } from "react";
import "./TeacherDashboard.css";
import DashboardTop from "./Components/DashboardTop/DashboardTop.jsx";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { GetInstructorStudents } from "../../../Actions/InstructorA";
import { GetLocalStorage } from "../../../Helpers/LocalStorage/LocalStorage";
import TeacherDashboardTable from "../Teacher Dashboard/Components/TeacherDashboardTable/TeacherDashboardTable.jsx";
import { BaseUrl } from "../../../Actions/Base.js";
import useAxios from "../../../Helpers/CustomHooks/useAxios.js";
import { GetScheduleA } from "../../../Actions/StudentA.js";
import { getTeacherSchedule } from "../../../Actions/ScheduleA.js";
const TeacherDashboard = () => {
  const { Students } = useSelector((Store) => Store?.InstructorReducer);
  const [filter, setFilter] = useState("inProgress"); // State to manage the current filter
  const { schedule, loading, error } = useSelector(
    (Store) => Store?.ScheduleReducer
  );
  // Function to handle filter change
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };
  // console.log("Students", Students);
  // Filtered list of students based on the selected filter
  const filteredStudents =
    filter === "all"
      ? Students
      : filter === "completed"
      ? Students?.filter((student) => student.Status === "Completed")
      : Students?.filter((student) => student.Status === "InProgress");

  const Dispatch = useDispatch();

  const [classTypeCounts, setClassTypeCounts] = useState({
    online: 0,
    physical: 0,
    inclass: 0,
  });

  const InstructorId = GetLocalStorage("UserInfo")?.Instructor?.InstructorId;

  const [enrollmentCountMap, setEnrollmentCountMap] = useState({});
  const [completedClassCountsMap, setCompletedClassCountsMap] = useState({});
  const countClassTypes = () => {
    const counts = {
      online: 0,
      physical: 0,
      inclass: 0,
    };
    const enrollmentCountMap = {};
    const completedClassCountsMap = {};
    setEnrollmentCountMap(enrollmentCountMap);
    setCompletedClassCountsMap(completedClassCountsMap);

    // Iterate over your array
    // teacherClassTypes?.data?.forEach((obj) => {
    schedule?.forEach((obj) => {
      const enrollmentId = obj?.EnrollmentFK;
      if (enrollmentCountMap[enrollmentId]) {
        enrollmentCountMap[enrollmentId]++;
      } else {
        enrollmentCountMap[enrollmentId] = 1;
      }

      if (obj?.ClassType && obj?.classStatus === "Delivered") {
        const classType = obj?.ClassType.toLowerCase(); // Ensure consistency in case

        // Increment count for this class type
        if (classType === "online") {
          counts.online++;
        } else if (classType === "driving") {
          counts.physical++;
        } else if (classType === "inclass") {
          counts.inclass++;
        }

        // Increment completed class count for this enrollment ID
        if (completedClassCountsMap[enrollmentId]) {
          completedClassCountsMap[enrollmentId]++;
        } else {
          completedClassCountsMap[enrollmentId] = 1;
        }
      }
    });

    return counts;
  };

  useEffect(() => {
    if (InstructorId) Dispatch(GetInstructorStudents(InstructorId));
    if (InstructorId) Dispatch(getTeacherSchedule(InstructorId));
    // Call countClassTypes and update state with the counts
  }, [Dispatch, InstructorId]);
  // console.log("Students", Schedule);
  const uniqueUserFK = new Set(schedule?.map((item) => item.UserFK));

  // console.log("teacherClassTypes", teacherClassTypes);
  return (
    <div className=" InstructorDashboardContainer ">
      <DashboardTop
        data={schedule}
        // teacherClassTypes={teacherClassTypes}
        classTypeCounts={classTypeCounts}
        studentCount={uniqueUserFK?.length}
      />
      <div
        className="mx-4 sm:mx-0 my-4 flex flex-col  gap-5 bg-[#FFFFFF]  pt-8 shadow overflow-x-scroll rounded-lg"
      >
        <div className="px-4">
          <h3 className="heading_2 font-bold text-[#4C34AD] px-4">Students</h3>
        </div>
        <div>
          <div className="px-4">
            <div className=" flex w-fit rounded-tl-lg rounded-tr-lg border-2 border-solid border-[#B5AAFF] ">
              <div
                onClick={() => handleFilterChange("all")}
                className={`cursor-pointer ${
                  filter === "all"
                    ? "bg-[#B5AAFF] p-5 font-bold text-white"
                    : "p-5 font-bold text-[#B5AAFF]"
                }`}
              >
                Show All
              </div>

              <div
                onClick={() => handleFilterChange("inProgress")}
                className={`cursor-pointer ${
                  filter === "inProgress"
                    ? " bg-[#B5AAFF] p-5 font-bold text-white"
                    : "border-l-2 border-r-0 border-t-0 border-b-0 border-solid border-[#B5AAFF] p-5 font-bold text-[#B5AAFF]"
                }`}
              >
                In Progress
              </div>
              <div
                onClick={() => handleFilterChange("completed")}
                className={`cursor-pointer ${
                  filter === "completed"
                    ? "bg-[#B5AAFF] p-5 font-bold text-white"
                    : "border-l-2 border-r-0 border-t-0 border-b-0 border-solid border-[#B5AAFF] p-5 font-bold text-[#B5AAFF]"
                }`}
              >
                Completed
              </div>
            </div>
          </div>

          <div className="bg-color px-4">
            <TeacherDashboardTable
              filteredStudents={filteredStudents}
              Students={Students}
              enrollmentCountMap={enrollmentCountMap}
              completedClassCountsMap={completedClassCountsMap}
            />
          </div>
        </div>

        {/* <table className="">
                    <thead>
                        <tr>
                            <th className="w-[3%] text-center text-2xs font-normal text-[#174A84]">
                                Name
                            </th>
                            <th className="w-[10%] text-center text-2xs font-normal text-[#174A84]"></th>
                            <th className="w-[16.5%] text-center text-2xs font-normal text-[#174A84]">
                                Email
                            </th>
                            <th className="w-[14%] text-center text-2xs font-normal text-[#174A84]">
                                Status
                            </th>
                            <th className="w-[9%] text-center text-2xs font-normal text-[#174A84]">
                                Joining Date
                            </th>
                            <th className="w-[16%] text-center text-2xs font-normal text-[#174A84]">
                                Next Class
                            </th>
                            <th className="w-[8%] text-center text-2xs font-normal text-[#174A84]">
                                Total Classes
                            </th>
                            <th className="w-[3.5%] text-center text-2xs font-normal text-[#174A84]"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Students &&
                            Students?.map((value, index) => {
                                console.log("valieu", value);

                                return (
                                    <InstructorRowTemplate
                                        key={index}
                                        FirstName={value?.User?.FirstName}
                                        LastName={value?.User?.LastName}
                                        Email={value?.User?.Email}
                                        Status={
                                            value?.User?.CourseEnrollment
                                                ?.Status
                                        }
                                        createdAt={
                                            value?.User?.CourseEnrollment
                                                ?.TimeTable?.startDate
                                        }
                                        // FirstName, LastName, Status, createdAt, Email, LastComp, StatusColor, NextClass, PaymentOrClientsColor, OnClick, TrStyle, ImgUrl
                                    />
                                );
                            })}
                    </tbody>
                </table> */}
      </div>
    </div>
  );
};

// export const InstructorRowTemplate = ({
//     FirstName,
//     LastName,
//     Status,
//     createdAt,
//     Email,
//     LastComp,
//     StatusColor,
//     NextClass,
//     PaymentOrClientsColor,
//     OnClick,
//     TrStyle,
//     ImgUrl,
// }) => {
//     return (
//         <tr
//             onClick={OnClick}
//             className={`${TrStyle} [&>*]:text-2xs [&>*]:text-[#174A84]`}
//         >
//             <td className="text-center">
//                 {}
//                 <img
//                     className="addCourseinstructorImg"
//                     src={ImgUrl}
//                     alt="img"
//                 />
//             </td>
//             <td className="text-center">
//                 {FirstName} {LastName}
//             </td>
//             <td className="text-center">{Email}</td>

//             <td className="text-center">
//                 <div className={`addCourse4Status ${StatusColor}`}>
//                     {Status}
//                 </div>
//             </td>

//             <td className="text-center">
//                 {dayjs(new Date(createdAt)).format("DD/MM/YYYY")}
//             </td>

//             <td className="relative text-center">
//                 <div className={`addCourse4Status ${PaymentOrClientsColor}`}>
//                     {NextClass}
//                 </div>
//             </td>

//             <td className="text-center">04/10</td>
//             <td className="text-center">{LastComp ? <LastComp /> : null}</td>
//         </tr>
//     );
// };

export default TeacherDashboard;
