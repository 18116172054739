import React from 'react'
import LeftSide from './Component/LeftSide'
import RightSide from './Component/RightSide'


const OthersHeader = ({ setShowSidebar, ShowSidebar }) => {
  

    return (
        <div className='flex w-full  h-full bg-[#AEB0FF44]'>
            {/* <div className='flex w-24 justify-center items-center'>
                <img className='cursor-pointer h-5' src={require('./Assets/HamBurger.svg').default} alt="" />
            </div> */}
            <div className='flex justify-center sm:justify-end items-center w-full  sm:w-[calc(100%_-_96px)] h-full'>
               
                <RightSide setShowSidebar={setShowSidebar} ShowSidebar={ShowSidebar} />
            </div>
        </div>
    )
}

export default OthersHeader