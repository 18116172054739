import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GetSingleEnrolledCourse } from "../../../Actions/CourseA";
import MyCourses from "../../../Components/MyCourses/MyCourses";
import useCheckLogin from "../../../Helpers/CustomHooks/CheckLogin";
import { TeacherButtons } from "../../../PageNames";
import SEnrolledCourseInfo from "../../SingleEnrolledCourse/Components/EnrolledCourseInfo/EnrolledCourseInfo";
import ClassColorAndDetail from "../../SingleEnrolledCourse/Components/EnrolledCourseInfo/Components/ClassColorAndDetail";
import ClassDetails from "../../SingleEnrolledCourse/Components/EnrolledCourseInfo/Components/ClassDetails";
import CourseModuleDetail from "./CourseModuleDetail";

const CourseModuleChild = ({ setName }) => {
    const { EnrollmentId } = useParams();
    const Dispatch = useDispatch();
    const { SEnrolledCourse, error } = useSelector(
        (Store) => Store.CourseReducer
    );

    const { Authenticated, UserInfo } = useSelector(
        (Store) => Store.LoginSignupReducer
    );
    console.log("SSEnrolledCourse", SEnrolledCourse);
    console.log("SSEnroCourse", error);

    useEffect(() => {
        if (EnrollmentId && Authenticated && UserInfo.User === "Institute")
            Dispatch(GetSingleEnrolledCourse(EnrollmentId));
    }, [Dispatch, EnrollmentId, Authenticated, UserInfo]);
    useCheckLogin(true, ["Institute"], ["Instructor"]);

    useEffect(() => {
        if (SEnrolledCourse?.CoursePackage?.InstituteCourse?.Course?.CourseName)
            setName(
                SEnrolledCourse?.CoursePackage?.InstituteCourse?.Course
                    ?.CourseName
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SEnrolledCourse]);
    let divWidth =
        "w-full sm:w-11/12 md:w-[89%] lg:w-[86%] xl:w-[83%] 2xl:w-4/5";

    return (
        <div className="mt-10 mb-10 flex flex-col items-center">
            <div className="flex w-11/12 flex-col items-start gap-5 md:w-4/5">
                {UserInfo.InstituteUserType === "Instructor" && (
                    <div className="w-full">
                        <h2 className=" Blackfont text-xs text-[#4C34AD] sm:text-sm md:text-base lg:text-[28px] xl:text-[30px] 2xl:text-lg">
                            Courses
                        </h2>
                        <div className="w-full ">
                            <CourseModuleDetail
                                SEnrolledCourse={SEnrolledCourse}
                            />
                        </div>
                    </div>
                )}
                <h2 className="Blackfont text-xs text-[#4C34AD] sm:text-sm md:text-base lg:text-[28px] xl:text-[30px] 2xl:text-lg">
                    Module 1
                </h2>
                <div
                    style={{
                        background:
                            " transparent linear-gradient(90deg, #FFFFFF 0%, #D6D8FF 100%) 0% 0% no-repeat padding-box",
                        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                    }}
                    className="flex flex-col items-center gap-10 rounded-[13px] bg-[#F0F0F7]  px-3 py-20"
                >
                    <div
                        className={`flex flex-col items-end gap-6 ${divWidth}`}
                    >
                        <ClassColorAndDetail />
                    </div>

                    {SEnrolledCourse?.CoursePackage?.InstituteCourse?.ClassSchedules.map(
                        (value, index, arr) => (
                            <div
                                className={`relative mt-20 flex flex-col gap-4 ${divWidth}`}
                                key={value.ClassScheduleId}
                            >
                                {arr[index]?.WeekNumber !==
                                arr[index - 1]?.WeekNumber ? (
                                    <h4
                                        className={`SemiBold 
                            absolute  -top-10   left-0  text-3xs    sm:-top-11  sm:-left-4  
                            sm:text-2xs   md:-top-12    md:-left-8  md:text-xs    lg:-top-14
                            lg:-left-12    lg:text-sm    xl:-top-16  xl:-left-16   xl:text-base   2xl:-left-20
                            2xl:text-[30px]`}
                                    >
                                        Week {value.WeekNumber}
                                    </h4>
                                ) : null}

                                {arr.map((ChildeValue) => {
                                    if (
                                        arr[index]?.WeekNumber !==
                                        arr[index - 1]?.WeekNumber
                                    )
                                        return (
                                            <ClassDetails
                                                ClassDetail={ChildeValue}
                                                key={
                                                    ChildeValue.ClassScheduleId +
                                                    1
                                                }
                                            />
                                        );
                                    else return null;
                                })}
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

const CourseModule = () => {
    return (
        <MyCourses
            ButtonsInfo={TeacherButtons}
            PageName="TeacherCourseModule"
            Component={CourseModuleChild}
            HideFooter={true}
        />
    );
};

export default CourseModule;
