// import React, { useEffect, useState } from "react";
// import { GetLocalStorage } from "../../../../../../../Helpers/LocalStorage/LocalStorage";
// import "./ButtonsSide.css";
// import { useAuthContext } from "../../../../../../../context/authContext";
// const ButtonsSide = ({ value, OnClick, Color }) => {


//   return (
//     <div className="InsAdminCourseTilesBorder w-full px-2 py-2 lg:w-[27%] lg:max-w-[230px] ">
//       <div className="cardFoter flex  flex-col items-center">
//         <p className="text-7xs 2xs:text-6xs xs:text-5xs font-medium sm:text-4xs font-segoe-ui 3xl:text-3xs ">
//          Current Status
//         </p>
//         <button
//           className={`mt-[10px] mb-[10px] rounded border-none  py-[3px] 
//                     px-1 text-center text-6xs 2xs:px-[6px] 2xs:text-6xs xs:px-2 
//                      md:py-[4px] md:px-[10px]
//                     lg:px-3 xl:py-[4px] xl:px-[14px] xl:text-4xs 3xl:text-3xs 2xl:py-[6px] 2xl:px-4 
//                     ${Color}`}
//         >
//           {value && value?.Publish === "false"
//             ? "Under Review"
//             : value?.Publish}
//         </button>

//       </div>
//     </div>
//   );
// };

// export default ButtonsSide;


import React, { useEffect, useState } from "react";
import { GetLocalStorage } from "../../../../../../../Helpers/LocalStorage/LocalStorage";
import "./ButtonsSide.css";
import { useAuthContext } from "../../../../../../../context/authContext";
const ButtonsSide = ({ value, OnClick, Color }) => {
  const { setPopupType } = useAuthContext();

  const [leftActive, setLeftActive] = useState(
    value && value.Publish === "Rejected" ? true : false
  );
  const [midActive, setMidActive] = useState(
    value && value.Publish === "false" ? true : false
  );
  const [rightActive, setRightActive] = useState(
    value && value.Publish === "Approved" ? true : false
  );

  const handleLeft = () => {
    // OnClick(value?.InstituteCourseId)
    setLeftActive(true);
    setMidActive(false);
    setRightActive(false);
    setPopupType("rejectCourse");
  };

  const handleMid = () => {
    if (value && value.Publish === "false") {
      setMidActive(true);
    }
  };

  const handleRight = () => {
    setRightActive(true);
    setMidActive(false);
    setLeftActive(false);
    setPopupType("approveCourse");
  };

  return (
    <div className="InsAdminCourseTilesBorder w-full px-2 py-2 pr-2 xs:pr-3 sm:pr-4 md:pr-5 lg:w-[27%] lg:max-w-[230px] lg:pr-6 xl:mt-[24px] xl:pr-7 2xl:pr-[30px]  ">
      <div className="cardFoter flex  flex-col items-center">
        <p className="text-7xs 2xs:text-6xs xs:text-5xs sm:text-4xs md:text-3xs lg:text-2xs xl:text-sm ">
          Status
        </p>
        <button
          className={`mt-[10px] mb-[10px] rounded-[6px] border-none  py-[3px] 
                    px-1 text-center text-7xs 2xs:px-[6px] 2xs:text-6xs xs:px-2 
                    xs:text-5xs sm:text-4xs md:py-[4px] md:px-[10px]
                    md:text-[16px] lg:px-3 xl:py-[4px] xl:px-[14px] xl:text-3xs 2xl:py-[6px] 2xl:px-4 
                    ${Color}`}
        >
          {value && value?.Publish === "false"
            ? "Under Review"
            : value?.Publish}
        </button>

        {GetLocalStorage("UserInfo").InstituteUserType === "Admin" ? (
          // <div className="flex gap-[15px]">
          //     <img
          //         className="w-5 sm:w-6 md:w-7 cursor-pointer"
          //         src={require("../images/edit_icon.svg").default}
          //         alt="Edit Icon"
          //         onClick={() => OnClick(value?.InstituteCourseId)}
          //     />

          //     <img
          //         className="w-3 xs:w-[14px] sm:w-4 md:w-[19px] cursor-pointer"
          //         src={require("../images/delete_icon.svg").default}
          //         alt="Delete Icon"
          //         onClick={() =(value.InstituteCourseId)}
          //     />
          // </div>
          <>
            <div className="tog-btn flex h-[36px] w-[99px] items-center justify-center rounded-[18px] border-[1px] border-[#787878] bg-white  drop-shadow-2xl">
              <div
                onClick={() => {
                  OnClick(value?.InstituteCourseId);
                  handleLeft();
                }}
                className={`left my-[1.5px] h-[30px] w-[30px] rounded-[50%] ${
                  leftActive === true ? "bg-[#B05AC4] " : null
                }`}
              ></div>
              <div
                onClick={(value) => handleMid(value)}
                className={`left my-[1.5px] h-[30px] w-[30px] rounded-[50%] ${
                  midActive === true ? "bg-[#7F82F9] " : null
                }`}
              ></div>
              <div
                onClick={() => {
                  OnClick(value?.InstituteCourseId);
                  handleRight();
                }}
                className={`left my-[1.5px] h-[30px] w-[30px] rounded-[50%] ${
                  rightActive === true ? "bg-[#AEB0FF] " : null
                }`}
              ></div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ButtonsSide;

