import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { SetImagePreview } from "../../Helpers/ImagePreview/ImagePreview";
import { useAuthContext } from "../../context/authContext";
import { ImagePreviewModal } from "./LicenseImagePreviewer";
import { BaseUrl } from "../../Actions/Base";
import { Modal } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";


function ImagePreviewerEnrollmentForm({
    Id,
    PreviewStyle,
    BrowseBtnStyle,
    ContainerStyle,
    OnChange,
    ImgUrl,
    readOnly,
}) {
    // const {Image, setImage} = useAuthContext()
    const { UserInfo } = useSelector((store) => store.LoginSignupReducer);
    const [isHovered, setIsHovered] = useState(false);
    const [isCameraActive, setIsCameraActive] = useState(false);
    const [videoStream, setVideoStream] = useState(null);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const inputFileRef = useRef(null);

    const [Image, setImage] = useState();
    const studentImage = (UserInfo?.User &&
        `${BaseUrl}/api/images/Student?url=${UserInfo?.StudentImage}`);
  

    const PreviewImage = (e) => {
        const file = e.target.files[0];

        if (OnChange) OnChange(e);

        if (e.target.files[0].type === "application/pdf")
            setImage(require("./Assets/PDF.svg").default);
        else SetImagePreview(setImage, file);
    };

    if (!PreviewStyle) PreviewStyle = "w-52 h-36 ";
    if (!BrowseBtnStyle) BrowseBtnStyle = "text-3xs bg-[#F3F6F7]";
    if (!ContainerStyle) ContainerStyle = "flex gap-5 ";

   /*   useEffect(() => {
        if (ImgUrl) {
            if (typeof ImgUrl === "string" && ImgUrl?.length > 1)
                setImage(ImgUrl);
            else SetImagePreview(setImage, ImgUrl);
        }
    }, [ImgUrl, Image]);  */

    //changed the useffect to replace the file image with the one captured using device camera
    useEffect(() => {
        if (ImgUrl) {
            if (typeof ImgUrl === "string" && ImgUrl?.length > 1)
                setImage(ImgUrl);
        } else if (ImgUrl && ImgUrl instanceof File) {
            SetImagePreview(setImage, ImgUrl);
        }
    }, [ImgUrl]);

    const startCamera = async () => {
        setIsCameraActive(true);
        console.log("start camera called");
    
        // Clear the file input if it's present
        if (inputFileRef.current) {
            inputFileRef.current.value = null;
        }
    
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "user" } });
            console.log("Video Stream:", stream); // Log to verify stream
    
            setVideoStream(stream);
    
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                videoRef.current.play();
            } else {
                console.log("videoRef not attached");
            }
        } catch (error) {
            console.error("Error accessing the camera:", error);
        }
    };
    

    const captureImage = () => {
        console.log("capture camera called")
        const context = canvasRef.current.getContext("2d");
        context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
        const imageData = canvasRef.current.toDataURL("image/png");
        setImage(imageData);
        stopCamera();
    };

    const stopCamera = () => {
        if (videoStream) {
            videoStream.getTracks().forEach(track => track.stop());
        }
        setIsCameraActive(false);
    };

    

    // if (isHovered) {
    //     return <ImagePreviewModal Image={Image} />;
    // }
   

    return (
        <div className={ContainerStyle}>
            <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                
                className={`flex items-center justify-center overflow-hidden bg-[#A1A3EF]   ${PreviewStyle} ${
                        "h-[180px min-w-[180px] md:h-[180px] md:min-w-[180px]"
                }`}
            >
                {!Image && !UserInfo?.StudentImage &&
                     <><div><span className="material-symbols-outlined text-white text-[130px]">
                    person
                    </span></div></>
                }
                {
                    UserInfo?.StudentImage ? <img
                    className="h-[100%] w-[100%] border-[0px] border-none outline-none"
                    src={studentImage}
                    alt=""
                /> : Image  && (
                    <img
                        className="h-[100%] w-[100%] border-[0px] border-none outline-none"
                        src={Image}
                        alt=""
                    />)
                }
                
                {isHovered && Image  && (
                    <ImagePreviewModal Image={Image} />
                )}

                {/* {isCameraActive && (
                   
                     <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex flex-col items-center justify-center"
                    style={{zIndex: 9999}}> 
                        <video ref={videoRef} className="w-auto h-auto max-h-full max-w-full"
                        autoPlay 
                        muted ></video>
                        <canvas ref={canvasRef} className="hidden"></canvas>
                        <PhotoCamera
                            onClick={captureImage}
                            className="mt-[-50px] cursor-pointer text-white"
                            style={{ fontSize: "48px" }}
                        />
                    </div>
                )} */}

                <Modal
                open={isCameraActive}
                onClose={stopCamera}
                aria-labelledby="camera-modal"
                aria-describedby="capture-image-from-camera"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <div className="bg-white p-4 rounded-md"
                 style={{
                    display: "flex",
                    flexDirection:"column",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    <video ref={videoRef} className="w-full max-h-[400px]" autoPlay muted></video>
                    <canvas ref={canvasRef} className="hidden"></canvas>
                    <PhotoCamera
                        onClick={captureImage}
                        className="text-gray-700 mt-4 cursor-pointer"
                        style={{ fontSize: "48px" }}
                    />
                </div>
            </Modal>

                
                <input
                    type="file"
                    accept="image/*"
                    capture
                    className="hidden"
                    onChange={PreviewImage}
                    id={Id}
                    ref={inputFileRef} // Attach ref to the file input
                />
            </div>
            <div>
                {!readOnly && (
                    <div
                        className={"flex flex-col items-center gap-2"}
                    >
                        <label
                            className={`h-fit  w-fit cursor-pointer rounded-lg bg-[#A1A3EF]  px-4 py-1 text-[14px]  text-white shadow-[5px_6px_6px_#00000016] md:px-8 md:py-2 lg:text-[16px] `}
                            htmlFor={Id}
                        >
                            Upload Photo
                        </label>

                        <p className="text-[12px] md:text-[13px]">
                            Jpeg, PNG or Giff
                        </p>
                    </div>
                )}
                
                <div //added the div to capture the image
                        className={"flex flex-col items-center gap-2 mt-[10px]"}
                    >
                        <p className="text-[12px] md:text-[13px]">
                            or
                        </p>
                        <button
                            className={`h-fit  w-fit cursor-pointer rounded-lg bg-[#A1A3EF]  px-4 py-1 text-[14px]  text-white shadow-[5px_6px_6px_#00000016] md:px-8 md:py-2 lg:text-[16px] `}
                            onClick={startCamera}
                        >
                           Capture Image
                        </button>

                        
                </div>
            </div>
        </div>
    );
}
export default ImagePreviewerEnrollmentForm;
