import React from "react";
import rectangleImage from "./Assets/Rectangle 23980.svg";
function GetReadyKnowledgeTest() {
    return (
        <div className="relative h-fit bg-gradient-to-r from-[#372354]  to-[#582E92] py-10">
            <h2 className=" SemiBoldItalic mmd:text-[38px] text-center text-white lg:text-[38px]">
                Knowledge Test Quiz
            </h2>
            <div className="mt-10 flex  flex-col xs:flex-col   lg:flex-row lg:items-center lg:justify-center lg:gap-10  ">
                <div className="flex w-full items-center justify-center">
                    <img
                        className="h-[300px] w-[300px]  xs:h-[300px] xs:w-[300px] sm:h-[400px] sm:w-[400px]"
                        src={require("./Assets/tractor.png")}
                        alt=""
                    />
                </div>
                <div className="flex w-full flex-col  gap-5 px-5 text-white">
                    <p className="text-[24px]">
                        Challenge yourself with our knowledge test quizzes. Take
                        interactive quizzes to assess your understanding and
                        receive instant feedback with correct answers. Sharpen
                        your skills and boost your confidence—all for free.
                    </p>
                    <div>
                        <h2 className="text-white">1. Assessment</h2>
                        <p className="text-[24px]">
                            Knowledge test quizzes provide an effective means to
                            assess one's understanding of driving concepts and
                            regulations accurately.
                        </p>
                    </div>
                    <div>
                        <h2 className="text-white">2. Retention</h2>
                        <p className="text-[24px]">
                            Engaging in quizzes enhances information retention,
                            reinforcing learning through active recall and
                            practice.
                        </p>
                    </div>

                    <div>
                        <h2 className="text-white">3. Feedback</h2>
                        <p className="text-[24px]">
                            Quizzes offer immediate feedback, allowing
                            individuals to identify areas of weakness and focus
                            on improving specific knowledge gaps.
                        </p>
                    </div>
                    <div>
                        <h2 className="text-white">4. Confidence</h2>
                        <p className="text-[24px]">
                            Successful completion of quizzes boosts confidence
                            levels, instilling a sense of preparedness and
                            readiness for real-world driving challenges.
                        </p>
                    </div>
                    <div class="inline-block w-fit cursor-pointer cursor-pointer rounded-[11px] bg-[#A1A3EF] py-3 px-5 text-[22px] font-normal text-white shadow-lg transition duration-300 hover:shadow-xl">
                        Start Quiz{" "}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GetReadyKnowledgeTest;
