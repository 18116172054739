import React, { useState } from "react";
import { BaseUrl } from "../../../../../../Actions/Base";
import ButtonsSide from "./ButtonsSide/ButtonsSide";
import ContentSide from "./ContentSide/ContentSide";
import "./CoursesTiles.css";
import { GetLocalStorage } from "../../../../../../Helpers/LocalStorage/LocalStorage";
import { Link } from "react-router-dom";
import BottonSideForAllCourses from "./ButtonsSide/BottonSideForAllCourses";
import { useAuthContext } from "../../../../../../context/authContext";
const CoursesTilesForAllCourses = ({
  Courses,
  UpdateStatus,
  OnClick,
  loading,
  allCourses,
}) => {
  const [updateStatus, setUpdateStatus] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const { setPopupType } = useAuthContext();
  const buttonStyles = `ml-2 cursor-pointer rounded-md px-5 py-3 text-6xs xl:text-4xs no-underline font-semibold w-[160px] text-white text-center hover:scale-105`;
  function UpdateStatusFunc(value) {
    console.log("Clicked");
    UpdateStatus(value);
    setSelectedCourseId(value);
    setUpdateStatus(true);
  }

  function handleClose(e) {
    e.stopPropagation();
    setUpdateStatus(false);
    setSelectedCourseId(null);
  }

  function openPopup(e) {
    e.stopPropagation();
    setPopupType("approveCourse")
  }
  console.log("updateStatus", updateStatus);
  return Courses?.length > 0
    ? Courses?.map((value) => {
        let Color =
          value?.Publish === "Approved"
            ? "text-white bg-[#AEB0FF]"
            : value?.Publish === "Rejected"
            ? "bg-[#B05AC4] text-white"
            : "bg-[#7F82F9] text-white";
        return (
          <div
            key={value?.InstituteCourseId}
            // onClick={""}
            className="course_card mb-[23px] w-full rounded-[15px] bg-white px-[4px] py-[6px] shadow 2xs:max-w-[300px] xs:max-w-[400px] sm:max-w-[500px] lg:max-w-[100%] "
          >
            <div className="bilalll flex max-w-[1150px] flex-col items-center gap-5 xl:flex-row">
              <div className="flex h-[142px]  w-[248px] items-center justify-center overflow-hidden rounded-lg">
                <img
                  className="h-full w-full max-w-[295px]"
                  src={`${BaseUrl}/api/Thumbnail/course?url=${value?.Course?.CourseThumbnail}`}
                  alt="Automotive Course"
                />
              </div>
              <div className="max-  flex w-full flex-col items-center justify-between lg:flex-row">
                {/* middle section */}
                <ContentSide
                  value={value}
                  allCourses={allCourses}
                  OnClick={OnClick}
                  Color={Color}
                />
                {/* right section */}
                <BottonSideForAllCourses
                  value={value}
                  OnClick={OnClick}
                  Color={Color}
                  allCourses={allCourses}
                />
                {GetLocalStorage("UserInfo").InstituteUserType === "Admin" && (
                  <div className="relative  flex flex-col gap-4 px-4">
                    {updateStatus && selectedCourseId === value?.InstituteCourseId ? (
                      <>
                        <span onClick={(e)=> handleClose(e)} className="material-symbols-outlined z-[51] text-error  absolute left-[145px] top-[-83px] text-xs w-fit cursor-pointer hover:scale-110">close</span>
                        <div className="absolute top-[-83px] left-[44px] z-50 flex w-fit flex-col bg-white px-8 pt-4 pb-3 text-black shadow">
                          <p onClick={(e)=> openPopup(e)} className="cursor-pointer text-center font-semibold text-purpal hover:scale-105">
                            Approve
                          </p>
                          <hr className="my-1 w-full text-gray-500" />
                          <p className="cursor-pointer text-center font-semibold text-purpal hover:scale-105">Reject</p>
                        </div>
                      </>
                    ) : null}
                    <button
                      onClick={() => UpdateStatusFunc(value?.InstituteCourseId)}
                      className={`${buttonStyles} bg-violet-100 `}
                    >
                      Update Status
                    </button>

                    <Link
                      to={`/UpdateCourse/${value?.InstituteCourseId}`}
                      className={`${buttonStyles} bg-purpal`}
                    >
                      Edit Course
                    </Link>

                    {/* <button
                      onClick={() => DeleteCourse(value?.InstituteCourseId)}
                      className=" rounded- ml-2 cursor-pointer bg-[#4C34AD] px-5 py-2 text-xs font-bold text-white "
                    >
                      Edit Course
                    </button> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })
    : null;
};
export default CoursesTilesForAllCourses;
