// react States
import React, { useState } from "react";
import addCOursePricingPlus from "../../CourseDetails/Assets/addCOursePricingPlus.png";
// css
// import "../Pricing.css";
import removeCourse from "../Assets/minus.png";

const WeeklyCourseModule = ({
  CourseDetailsData,
  setCourseDetailsData,
  CourseDetailsErr,
  setCourseDetailsErr,
  update,
}) => {
  const [schedule, setSchedule] = useState({
    ClassDescription: "",
    ClassNumber: "",
    WeekNumber: "",
  });

  const OnChange = (e, key) => {
    const { value } = e.target;
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [key]: value,
    }));
  };

  const AddTopic = () => {
    setCourseDetailsData((prevData) => ({
      ...prevData,
      ClassSchedule: [...prevData.ClassSchedule, schedule],
    }));
    setSchedule({
      ClassDescription: "",
      ClassNumber: "",
      WeekNumber: "",
    });
  };

  const RemoveTopic = (index) => {
    setCourseDetailsData((prevData) => ({
      ...prevData,
      ClassSchedule: prevData.ClassSchedule.filter((item, i) => i !== index),
    }));
  };
  return (
    <div className="packageTable">
      <table
        className="addCourse4Table"
        style={{
          background:
            "transparent linear-gradient(114deg, #ffffff 0%, #f6f6ff 14%, #d6d8ff 100%) 0% 0%no-repeat padding-box",
        }}
      >
        <thead>
          <tr className="addCourseTableTH">
            <th className="w-[16%]">Select week</th>
            <th className="w-[16%]">Select class</th>
            <th className="w-[44%]">Description</th>
            <th className="w-[10%]">Action</th>
          </tr>
        </thead>
        <tbody>
          {CourseDetailsData?.ClassSchedule?.filter(
            (item) =>
              item?.ClassDescription?.trim() !== "" ||
              item?.ClassNumber?.trim() !== "" ||
              item?.WeekNumber?.trim() !== ""
          ).map((value, index, arr) => {
            return (
              <tr key={index + "a"}>
                <td className="text-center">
                  {/* <input
                                        type="number"
                                        placeholder=""
                                        value={value?.WeekNumber}
                                        className="RemoveInputNumArrows w-full border-[1px] border-solid border-[#00000000] px-2 py-[1px] text-center text-xs focus:border-black"
                                        onChange={(e) =>
                                            OnChange(e, index, "WeekNumber")
                                        }
                                    /> */}
                  {value?.WeekNumber}
                </td>

                <td className="text-center">
                  {/* <input
                                        type="number"
                                        placeholder=""
                                        value={value?.ClassNumber}
                                        className="RemoveInputNumArrows w-full border-[1px] border-solid border-[#00000000] px-2 py-[1px] text-center text-xs focus:border-black"
                                        onChange={(e) =>
                                            OnChange(e, index, "ClassNumber")
                                        }
                                    /> */}
                  {value?.ClassNumber}
                </td>
                <td className="text-center">
                  {/* <input
                                        type="text"
                                        placeholder=""
                                        value={value?.ClassDescription}
                                        className="w-full border-[1px] border-solid border-[#00000000] px-2 py-[1px] text-center text-xs focus:border-black"
                                        onChange={(e) =>
                                            OnChange(
                                                e,
                                                index,
                                                "ClassDescription"
                                            )
                                        }
                                    /> */}
                  {value?.ClassDescription}
                </td>

                <td>
                  <div
                    className="statusAddCourseTableBtn text-center"
                    onClick={() => {
                      if (!update) RemoveTopic(index);
                    }}
                  >
                    Delete
                  </div>
                </td>
              </tr>
            );
          })}
          {!update && (
            <tr>
              <td>
                <input
                  type="number"
                  placeholder=""
                  value={schedule?.WeekNumber}
                  className="RemoveInputNumArrows w-full border-[1px] border-solid border-[#00000000] px-2 py-[1px] text-center text-xs focus:border-black"
                  onChange={(e) => OnChange(e, "WeekNumber")}
                />
              </td>

              <td style={{ textAlign: "start" }}>
                <input
                  type="number"
                  placeholder=""
                  value={schedule?.ClassNumber}
                  className="RemoveInputNumArrows w-full border-[1px] border-solid border-[#00000000] px-2 py-[1px] text-center text-xs focus:border-black"
                  onChange={(e) => OnChange(e, "ClassNumber")}
                />
              </td>
              <td>
                <input
                  type="text"
                  placeholder=""
                  value={schedule?.ClassDescription}
                  className="w-full border-[1px] border-solid border-[#00000000] px-2 py-[1px] text-center text-xs focus:border-black"
                  onChange={(e) => OnChange(e, "ClassDescription")}
                />
              </td>
              <td>
                <div
                  className="statusAddCourseTableBtn text-center "
                  onClick={AddTopic}
                >
                  Add
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default WeeklyCourseModule;

//////////////////////////////////////////////////////////////
// // react States
// import React from "react";
// import addCOursePricingPlus from "../../CourseDetails/Assets/addCOursePricingPlus.png";
// // css
// // import "../Pricing.css";
// import removeCourse from "../Assets/minus.png";

// const WeeklyCourseModule = ({
//     CourseDetailsData,
//     setCourseDetailsData,
//     CourseDetailsErr,
//     setCourseDetailsErr,
// }) => {
//     const OnChange = (e, Index, key) => {
//         let NewWeeklyModule = [...CourseDetailsData?.ClassSchedule];
//         NewWeeklyModule[Index][key] = e.target.value;
//         setCourseDetailsData({
//             ...CourseDetailsData,
//             ClassSchedule: [...NewWeeklyModule],
//         });
//     };
//     const AddTopic = () => {
//         let NewClass = {
//             ClassDescription: "",
//             WeekNumber: "",
//             ClassNumber: "",
//         };
//         setCourseDetailsData({
//             ...CourseDetailsData,
//             ClassSchedule: [...CourseDetailsData?.ClassSchedule, NewClass],
//         });
//     };
//     const RemoveTopic = (index) => {
//         CourseDetailsData?.ClassSchedule.splice(index, 1);
//         setCourseDetailsData({
//             ...CourseDetailsData,
//             ClassSchedule: [...CourseDetailsData?.ClassSchedule],
//         });
//     };

//     return (
//         <div className="packageTable">
//             <table
//                 className="addCourse4Table"
//                 style={{
//                     background:
//                         "transparent linear-gradient(114deg, #ffffff 0%, #f6f6ff 14%, #d6d8ff 100%) 0% 0%no-repeat padding-box",
//                 }}
//             >
//                 <thead>
//                     <tr className="addCourseTableTH">
//                         <th className="w-[16%]">Select week</th>
//                         <th className="w-[16%]">Select class</th>
//                         <th className="w-[44%]">Description</th>
//                         <th className="w-[10%]">Action</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {CourseDetailsData?.ClassSchedule?.map(
//                         (value, index, arr) => {
//                             return (
//                                 <tr key={index + "a"}>
//                                     <td>
//                                         <input
//                                             type="number"
//                                             placeholder=""
//                                             value={value?.WeekNumber}
//                                             className="RemoveInputNumArrows w-full border-[1px] border-solid border-[#00000000] px-2 py-[1px] text-center text-xs focus:border-black"
//                                             onChange={(e) =>
//                                                 OnChange(e, index, "WeekNumber")
//                                             }
//                                         />
//                                     </td>

//                                     <td style={{ textAlign: "start" }}>
//                                         <input
//                                             type="number"
//                                             placeholder=""
//                                             value={value?.ClassNumber}
//                                             className="RemoveInputNumArrows w-full border-[1px] border-solid border-[#00000000] px-2 py-[1px] text-center text-xs focus:border-black"
//                                             onChange={(e) =>
//                                                 OnChange(
//                                                     e,
//                                                     index,
//                                                     "ClassNumber"
//                                                 )
//                                             }
//                                         />
//                                     </td>
//                                     <td>
//                                         <input
//                                             type="text"
//                                             placeholder=""
//                                             value={value?.ClassDescription}
//                                             className="w-full border-[1px] border-solid border-[#00000000] px-2 py-[1px] text-center text-xs focus:border-black"
//                                             onChange={(e) =>
//                                                 OnChange(
//                                                     e,
//                                                     index,
//                                                     "ClassDescription"
//                                                 )
//                                             }
//                                         />
//                                     </td>
//                                     {index === arr.length - 1 ? (
//                                         <td
//                                         // style={{
//                                         //     backgroundColor: "#ffffff00",
//                                         // }}
//                                         // className="statusAddCourseTableBtn"
//                                         // onClick={AddTopic}
//                                         >
//                                             {/* <img
//                                                 src={addCOursePricingPlus}
//                                                 style={{ marginTop: "8px" }}
//                                                 alt="addCOursePricingPlus"
//                                             /> */}
//                                             <div
//                                                 className="statusAddCourseTableBtn text-center "
//                                                 onClick={AddTopic}
//                                             >
//                                                 Add
//                                             </div>
//                                         </td>
//                                     ) : (
//                                         <td
//                                         // className="statusAddCourseTableBtn"
//                                         // onClick={() => RemoveTopic(index)}
//                                         >
//                                             {/* <img
//                                                 src={removeCourse}
//                                                 style={{ marginTop: "4px" }}
//                                                 alt="RemoveCourseMinus"
//                                             /> */}
//                                             <div
//                                                 className="statusAddCourseTableBtn text-center"
//                                                 onClick={() =>
//                                                     RemoveTopic(index)
//                                                 }
//                                             >
//                                                 Delete
//                                             </div>
//                                         </td>
//                                     )}
//                                 </tr>
//                             );
//                         }
//                     )}
//                 </tbody>
//             </table>
//         </div>
//     );
// };

// export default WeeklyCourseModule;
