import React from 'react'
import StudentsList from '../../../Institute/StudentsList/StudentsList'
import InstituteTemplate from '../../../../Components/InstituteTemplate/InstituteTemplate';
import { StudentsListChild } from '../../../Institute/StudentsList/StudentsList'; 

const InstructorStudentsList = () => {
  return (
    <>
      <StudentsListChild />
    </>
  )
}

const InstructorStudentList = () => (
    <InstituteTemplate
      Element={InstructorStudentsList}
      bg="bg-[#F7F7F7] min-h-screen items-center"
    />
  );
  
  export default InstructorStudentList;


