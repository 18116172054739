// react States
import React, { useEffect, useRef, useState } from "react";

// Images
import idCardIcon from "../Assets/idCardIcon.png";

import { GetVehicleA } from "../../../../../Actions/VehicleA";
import { useDispatch, useSelector } from "react-redux";
import { BaseUrl } from "../../../../../Actions/Base";

const VehicleDetailTiles = ({
  update,
  CourseData,
  setCourseData,
  setSteps,
  CourseInfo,
}) => {
  const [sortByTime, setSortByTime] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [gearType, setGearType] = useState("");
  const [types, setTypes] = useState("");
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const Dispatch = useDispatch();
  const { Vehicles } = useSelector((Store) => Store.VehicleReducer);
  useEffect(() => {
    Dispatch(GetVehicleA({ searchQuery, sortBy, sortByTime, types, gearType }));
  }, [Dispatch, searchQuery, sortBy, sortByTime, types, gearType]);

  console.log("CourseInfo", CourseInfo);
  useEffect(() => {
    setSelectedVehicles(CourseInfo?.Vehicles || []);

    selectedVehicles?.forEach((vehicle) => isSelected(vehicle.VehicleId));
  }, [CourseInfo?.Vehicles]);
  console.log("selectedVehicles", selectedVehicles);

  const AddVehicle = (VehicleId) => {
    const updatedVehicles = CourseData?.Vehicles
      ? [...CourseData.Vehicles]
      : [];
    // if (CourseData?.Vehicles) {
    //   updatedVehicles = [...CourseData.Vehicles];
    // }
    const index = updatedVehicles.findIndex(
      (vehicle) =>
        vehicle.VehicleId === VehicleId || vehicle.VehicleFK === VehicleId
    );
    if (index !== -1) {
      updatedVehicles.splice(index, 1);
    } else {
      updatedVehicles.push({ VehicleFK: VehicleId });
    }
    setCourseData({ ...CourseData, Vehicles: updatedVehicles });
    // setSelectedVehicles(updatedVehicles);
    // setSelectedVehicles([...selectedVehicles, { VehicleId: VehicleId }]);
    // Check if the vehicle is already in the selectedVehicles array
    const selectedVehicleIndex = selectedVehicles.findIndex(
      (vehicle) => vehicle.VehicleId === VehicleId
    );

    // If it is, remove it; if it isn't, add it
    if (selectedVehicleIndex !== -1) {
      setSelectedVehicles(
        selectedVehicles.filter((_, index) => index !== selectedVehicleIndex)
      );
    } else {
      setSelectedVehicles([...selectedVehicles, { VehicleId: VehicleId }]);
    }
  };

  console.log("CourseVehicels", CourseData);
  function isSelected(VehicleId) {
    return selectedVehicles?.some((vehicle) => vehicle.VehicleId === VehicleId);
  }
  // console.log("Vehicles", Vehicles);
  return (
    <div
      className="addCourse3Main rounded-[23px] p-5"
      style={{
        background:
          "transparent linear-gradient(107deg, #ffffff 0%, #f6f6ff 14%, #d6d8ff 100%) 0% 0% no-repeat padding-box",
        // boxShadow: "0px 0px 30px 30px rgba(161, 146, 221, 0.4)",
      }}
    >
      <div className="row">
        {Vehicles?.length > 0
          ? Vehicles?.map((value) => {
              return (
                <VehicleTile
                  update={update}
                  key={value.VehicleId}
                  Image={value.VehicleImages?.[0]?.VehicleImageLink}
                  OnClick={AddVehicle}
                  Model={value.Model}
                  Year={value.Year}
                  PlateNumber={value.PlateNumber}
                  Description={value.Description}
                  VehicleId={value.VehicleId}
                  isSelected={isSelected(value.VehicleId)}
                />
              );
            })
          : null}
      </div>
    </div>
  );
};

const VehicleTile = ({
  OnClick,
  Model,
  Year,
  PlateNumber,
  Description,
  VehicleId,
  Image,
  isSelected,
  update,
}) => {
  const PRef = useRef();
  const [DescText, setDescText] = useState();
  useEffect(() => {
    PRef.current.innerHTML = Description;
  }, [Description]);
  useEffect(() => {
    if (PRef.current?.innerText) setDescText(PRef.current?.innerText);
  }, []);

  return (
    <div className="col-3 ">
      <div ref={PRef} className="hidden"></div>
      <div className="vehicalBox  bg-white">
        <div className="vehicalImgConainer  p-2 ">
          <div className="flex h-[240px] items-center justify-center overflow-hidden rounded-[23px]">
            <img
              src={`${BaseUrl}/api/Vehicleimage?url=${Image}`}
              alt="Vehicle"
            />
          </div>
        </div>
        <span className="vehicalDetail">
          <span className="vehicalName">{Model}</span>
          <span className="vehicalModel">{Year}</span>
        </span>
        <hr className="vehicalHr" />
        <center>
          <span className="idCardSection">
            <img src={idCardIcon} alt="idCardIcon" /> <span>{PlateNumber}</span>
          </span>
        </center>
        <hr className="vehicalHr" />
        <p className="discOfVehical h-14 ">{DescText}</p>
        <hr className="vehicalHr" />
        <center>
          <button
            disabled={update}
            className={
              !isSelected
                ? "vehicalSelectBtn cursor-pointer"
                : " vehicleSelected cursor-pointer"
            }
            type="button"
            onClick={() => OnClick(VehicleId)}
          >
            {isSelected ? "Selected" : "Select Vehicle"}
          </button>
        </center>
      </div>
    </div>
  );
};
export default VehicleDetailTiles;

// const AddVehicle = (VehicleId) => {
//     let updatedVehicleFK = [];
//     if (CourseData?.VehicleFK) {
//         updatedVehicleFK = [...CourseData.VehicleFK];
//     }
//     updatedVehicleFK.push(VehicleId);
//     setCourseData({ ...CourseData, VehicleFK: updatedVehicleFK });
//     setSelectedVehicles(updatedVehicleFK);
// };

// const AddVehicle = (VehicleId) => {
//     let updatedVehicleFK = [];
//     if (CourseData?.VehicleFK) {
//         updatedVehicleFK = [...CourseData.VehicleFK];
//     }
//     const index = updatedVehicleFK.indexOf(VehicleId);
//     if (index !== -1) {
//         // Vehicle is already selected, so remove it
//         updatedVehicleFK.splice(index, 1);
//     } else {
//         // Vehicle is not selected, so add it
//         updatedVehicleFK.push(VehicleId);
//     }
//     setCourseData({ ...CourseData, VehicleFK: updatedVehicleFK });
//     setSelectedVehicles(updatedVehicleFK);
// };
