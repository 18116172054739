import React, { useState } from "react";
import Footer from "../../../../Components/Footer/Footer";
import QuizeResults from "./QuizeResults";
import { useEffect } from "react";
import { quizData } from "./quizData";
function AttemptQuize() {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);
    const [results, setResults] = useState([]);
    const [quizCompleted, setQuizCompleted] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const currentQuestion = quizData[currentQuestionIndex];
    const [showResults, setShowResults] = useState(false);
    // useEffect(() => {
    //     quizData = shuffleArray(quizData);
    // }, []);
    const [shuffledQuizData, setShuffledQuizData] = useState([]);

    useEffect(() => {
        setShuffledQuizData(shuffleArray(quizData));
    }, []);

    function shuffleArray(array) {
        // Fisher-Yates shuffle algorithm
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }
    const handleOptionSelect = (optionIndex) => {
        if (selectedOptionIndex !== null) return; // Prevent further selection once an option is chosen

        setSelectedOptionIndex(optionIndex);

        const selectedOption = currentQuestion.options[optionIndex];
        const correctOption = currentQuestion.correctOption;
        const isCorrect = selectedOption === correctOption;
        const result = {
            question: currentQuestion.question,
            selectedOption: selectedOption,
            correctOption: correctOption,
            isCorrect: isCorrect,
        };

        const updatedResults = [...results];
        updatedResults[currentQuestionIndex] = result;
        setResults(updatedResults);
    };
    console.log("selectedopiton index", selectedOptionIndex);
    console.log("RESULTS ", results);

    const moveToNextQuestion = () => {
        const notAttempted = selectedOptionIndex === null;
        console.log("notattempted", notAttempted);

        if (notAttempted) {
            const result = {
                question: currentQuestion.question,
                notAttempted,
                // isCorrect: isCorrect,
                // isInCorrect: isInCorrect,
            };

            setResults([...results, result]);
        }

        setSelectedOptionIndex(null);

        if (currentQuestionIndex === quizData.length - 1) {
            setQuizCompleted(true);
            setShowModal(true);
            // Show alert when quiz is completed
            // alert("Quiz completed!");
        } else {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    const handleRetakeQuiz = () => {
        setCurrentQuestionIndex(0);
        setResults([]);
        setQuizCompleted(false);
        setShowModal(false);
        setShowResults(false);
        // quizData = shuffleArray(quizData);
        setShuffledQuizData(shuffleArray(quizData));
    };

    const Iterations = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
    return (
        <div className="mt-20 ">
            {showResults ? (
                <QuizeResults
                    results={results}
                    handleRetakeQuiz={handleRetakeQuiz}
                />
            ) : (
                <div className=" mx-auto mt-20 flex w-[90%] flex-col items-center justify-center gap-10 py-10  lg:flex-row">
                    {/* <div className="  mx-auto mt-20 flex w-[90%] gap-20  py-10"> */}

                    {showModal && (
                        <Modal
                            onSeeResults={() => {
                                setShowModal(false);
                            }}
                            handleRetakeQuiz={handleRetakeQuiz}
                            seeResults={() => setShowResults(true)}
                        />
                    )}

                    <div className="flex min-h-[431px]  w-[300px] flex-col gap-5 xs:w-[400px] sm:w-[450px] md:w-[600px]  lg:w-[500px]">
                        <div
                            onClick={handleRetakeQuiz}
                            // onClick={() => Navigate("/get-ready/knowledge-test")}
                            class="inline-block w-fit w-fit cursor-pointer rounded-[11px] bg-[#A1A3EF] py-3 px-5 text-[22px]   font-normal text-white shadow-lg transition duration-300 hover:shadow-xl "
                        >
                            Retake
                        </div>
                        <div
                            className=" relative flex min-h-[431px] w-[300px]  flex-col items-center gap-5 rounded-[17px] p-5 xs:w-[400px] sm:w-[450px] md:w-[600px] lg:w-[500px]  "
                            style={{
                                boxShadow: "0px 0px 22px #00000042",
                            }}
                        >
                            <div className="flex  flex-wrap gap-2">
                                {quizData.map((item, index) => (
                                    <div
                                        key={index}
                                        className={`flex h-[52px] w-[53px] flex-wrap items-center justify-center rounded-[10px]
               ${
                   results.length === 0 // Check if results array is empty
                       ? "border border-solid border-[#834AFF] bg-[#D5D6FF]"
                       : results[index]?.isCorrect !== undefined // Check if this question has been attempted
                       ? results[index]?.isCorrect // Check if there is a result for this question and if it's correct
                           ? "border border-solid border-[#8DF4A9] bg-[#D6FFE1]"
                           : "border border-solid border-[#FF7C7C] bg-[#FFCBCB]"
                       : "border border-solid border-[#834AFF] bg-[#D5D6FF]" // Default color for attempted but not answered questions
               }`}
                                    >
                                        <h2 className="text-[25px] font-bold">
                                            {index + 1}
                                        </h2>
                                    </div>
                                ))}
                            </div>

                            <div className=" mt-20 flex flex-col items-center gap-8 p-3 lg:absolute lg:left-0  lg:bottom-0 lg:mb-5 lg:flex-row">
                                <div className="flex items-center justify-center gap-3">
                                    <div className="h-[10px] w-[10px] rounded-full bg-[#8DF4A9]" />
                                    <h2 className="text-[23px]">Correct</h2>
                                </div>

                                <div className="flex items-center justify-center gap-3">
                                    <div className="h-[10px] w-[10px] rounded-full bg-[#FF7C7C]" />
                                    <h2 className="text-[23px]">In-Correct</h2>
                                </div>

                                <div className="flex items-center justify-center gap-3">
                                    <div className="h-[10px] w-[10px] rounded-full bg-[#D5D6FF]" />
                                    <h2 className="text-[23px]">
                                        Not Answered
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-10  px-5 sm:w-[450px] md:w-[600px] lg:w-[500px]">
                            <h2 className="text-[38px]">
                                Hire an instructor for Class B licensing
                            </h2>
                            <p className="text-justify text-[24px]">
                                For expert guidance and a safe testing
                                experience, consider hiring a qualified
                                instructor to conduct your road test. Their
                                expertise ensures you're well-prepared and
                                confident, maximizing your chances of success
                                while prioritizing safety.
                            </p>
                            <div
                                // onClick={() => Navigate("/get-ready/knowledge-test")}
                                class="inline-block w-fit  cursor-pointer rounded-[11px] bg-[#A1A3EF] py-3 px-5 text-right text-[22px] font-normal text-white shadow-lg transition duration-300 hover:shadow-xl"
                            >
                                Hire Instructor
                            </div>
                        </div>
                    </div>

                    <div
                        className="  mx-auto flex h-fit w-[80%] flex-col items-center justify-center gap-10 rounded-[17px] py-10 px-2 xs:px-5 sm:px-5 md:px-10  lg:px-20"
                        style={{
                            boxShadow: "0px 0px 22px #00000042",
                        }}
                    >
                        <h2 className="text-[28px] text-[#161616]">
                            {currentQuestionIndex + 1}.{" "}
                            {currentQuestion?.question}
                        </h2>
                        <div className="mx-auto text-center">
                            <img
                                loading="lazy"
                                src={require("./Assets/Mask Group 232.png")}
                                alt=""
                                className="h-[273px] w-[200px]  xs:h-[273px] xs:w-[200px]   sm:h-[273px] sm:w-[372px] md:h-[273px] md:w-[372px]  lg:h-[273px] lg:w-[372px]   "
                            />
                        </div>
                        <div className="flex w-full flex-col gap-10">
                            {currentQuestion.options.map((option, index) => (
                                <div
                                    key={index}
                                    className={` cursor-pointer rounded-[17px] p-5 ${
                                        selectedOptionIndex === index
                                            ? results[currentQuestionIndex]
                                                  ?.isCorrect
                                                ? "border border-solid border-[#8DF4A9] bg-[#D6FFE1] "
                                                : "border border-solid  border-[#FF7C7C] bg-[#FFCBCB] "
                                            : " border border-solid border-[#834AFF] bg-[#D5D6FF]"
                                    }`}
                                    onClick={() => handleOptionSelect(index)}
                                >
                                    <div className="flex items-center gap-2">
                                        <div
                                            className={` flex h-10 w-10 items-center justify-center rounded-full ${
                                                selectedOptionIndex === index
                                                    ? results[
                                                          currentQuestionIndex
                                                      ]?.isCorrect
                                                        ? "border border-solid border-[#8DF4A9] bg-[#D6FFE1] "
                                                        : "border border-solid  border-[#FF7C7C] bg-[#FFCBCB] "
                                                    : " border border-solid border-[#834AFF] bg-[#D5D6FF]"
                                            }`}
                                        >
                                            <h2 className="text-[23px] text-[#161616]">
                                                {String.fromCharCode(
                                                    65 + index
                                                )}
                                                {/* 'A' + index */}
                                            </h2>
                                        </div>
                                        <h2 className="text-[23px] text-[#161616]">
                                            {option}
                                        </h2>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div
                            onClick={moveToNextQuestion}
                            class="  w-[20%] cursor-pointer rounded-[11px] bg-[#A1A3EF]   py-3 px-5 text-center  text-[22px] font-normal text-white shadow-lg transition duration-300 hover:shadow-xl"
                        >
                            Next
                        </div>
                    </div>
                </div>
            )}

            <Footer />
        </div>
    );
}

export default AttemptQuize;

const Modal = ({ onSeeResults, handleRetakeQuiz, seeResults }) => {
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden  outline-none focus:outline-none">
            <div className="relative mx-auto my-6  w-auto min-w-[30%]">
                <div className="w-full rounded-lg  bg-[#a8aad7] p-5 shadow-lg">
                    {/* <div className="flex justify-end">
                        <button
                            onClick={onClose}
                            className="text-gray-400 hover:text-gray-600 focus:outline-none"
                        >
                            <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div> */}
                    <div className="mt-4 flex flex-col items-center justify-center gap-10">
                        <h2 className="text-xl font-semibold text-[#333697]">
                            Congratulations!
                        </h2>
                        <h2 className="text-xl font-semibold text-[#333697]">
                            Quiz completed!
                        </h2>
                        {/* <p className="mt-2">
                            Congratulations, you have completed the quiz!
                        </p> */}
                        <div className="flex gap-5 ">
                            <div
                                onClick={() => {
                                    onSeeResults();
                                    seeResults();
                                }}
                                class="inline-block w-fit  cursor-pointer rounded-[11px] bg-[#A1A3EF] py-3 px-5 text-right text-[22px] font-normal text-white shadow-lg transition duration-300 hover:shadow-xl"
                            >
                                See Results
                            </div>
                            <div
                                onClick={handleRetakeQuiz}
                                class="inline-block w-fit  cursor-pointer rounded-[11px] bg-[#A1A3EF] py-3 px-5 text-right text-[22px] font-normal text-white shadow-lg transition duration-300 hover:shadow-xl"
                            >
                                Retake Quize
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// import React, { useState } from "react";
// import Footer from "../../../../Components/Footer/Footer";
// import quizData from "./quizData.json";
// function AttemptQuize() {
//     const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
//     const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);
//     const [results, setResults] = useState([]);
//     const [quizCompleted, setQuizCompleted] = useState(false);

//     const currentQuestion = quizData[currentQuestionIndex];

//     const handleOptionSelect = (optionIndex) => {
//         if (selectedOptionIndex !== null) return; // Prevent further selection once an option is chosen

//         setSelectedOptionIndex(optionIndex);

//         const selectedOption = currentQuestion.options[optionIndex];
//         const correctOption = currentQuestion.correctOption;
//         const isCorrect = selectedOption.startsWith(correctOption + ".");

//         const result = {
//             question: currentQuestion.question,
//             selectedOption: selectedOption,
//             correctOption: correctOption,
//             isCorrect: isCorrect,
//         };

//         const updatedResults = [...results];
//         updatedResults[currentQuestionIndex] = result;
//         setResults(updatedResults);
//     };

//     const moveToNextQuestion = () => {
//         const selectedOption = currentQuestion.options[selectedOptionIndex];
//         const correctOption = currentQuestion.correctOption;
//         const isCorrect = selectedOption === correctOption;
//         const isInCorrect = !isCorrect;

//         const result = {
//             question: currentQuestion.question,
//             selectedOption: selectedOption,
//             correctOption: correctOption,
//             // isCorrect: isCorrect,
//             // isInCorrect: isInCorrect,
//         };

//         setResults([...results, result]);
//         setSelectedOptionIndex(null);

//         if (currentQuestionIndex === quizData.length - 1) {
//             setQuizCompleted(true);
//         } else {
//             setCurrentQuestionIndex(currentQuestionIndex + 1);
//         }
//     };

//     const handleRetakeQuiz = () => {
//         setCurrentQuestionIndex(0);
//         setResults([]);
//         setQuizCompleted(false);
//     };
//     console.log("currentQuetion", currentQuestion);
//     console.log("results", results);

//     const Iterations = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
//     return (
//         <div className="mt-20 ">
//             <div className=" lg mx-auto mt-20 flex w-[90%] gap-20  py-10">
//                 <div className="flex w-[500px] flex-col  gap-5">
//                     <div
//                         // onClick={() => Navigate("/get-ready/knowledge-test")}
//                         class="inline-block w-fit cursor-pointer rounded-[11px] bg-[#A1A3EF] py-3 px-5 text-[22px] font-normal text-white shadow-lg transition duration-300 hover:shadow-xl"
//                     >
//                         Retake
//                     </div>
//                     <div
//                         className=" relative h-[431px] w-[500px] rounded-[17px] p-5 "
//                         style={{
//                             boxShadow: "0px 0px 22px #00000042",
//                         }}
//                     >
//                         <div className="flex  flex-wrap gap-2">
//                             {quizData.map((item, index) => (
//                                 <div className="flex h-[52px] w-[53px] items-center justify-center rounded-[10px] bg-[#A1A3EF]">
//                                     <h2 className="text-[25px] font-bold">
//                                         {index}
//                                     </h2>
//                                 </div>
//                             ))}
//                         </div>

//                         <div className=" absolute left-0 bottom-0 mb-5 flex gap-8 p-3">
//                             <div className="flex items-center justify-center gap-3">
//                                 <div className="h-[10px] w-[10px] rounded-full bg-[#8DF4A9]" />
//                                 <h2 className="text-[23px]">Correct</h2>
//                             </div>

//                             <div className="flex items-center justify-center gap-3">
//                                 <div className="h-[10px] w-[10px] rounded-full bg-[#FF7C7C]" />
//                                 <h2 className="text-[23px]">In-Correct</h2>
//                             </div>

//                             <div className="flex items-center justify-center gap-3">
//                                 <div className="h-[10px] w-[10px] rounded-full bg-[#D5D6FF]" />
//                                 <h2 className="text-[23px]">Not Answered</h2>
//                             </div>
//                         </div>
//                     </div>
//                     <h2 className="text-[38px]">
//                         Hire an instructor for Class B licensing
//                     </h2>
//                     <p className="text-justify text-[24px]">
//                         For expert guidance and a safe testing experience,
//                         consider hiring a qualified instructor to conduct your
//                         road test. Their expertise ensures you're well-prepared
//                         and confident, maximizing your chances of success while
//                         prioritizing safety.
//                     </p>
//                     <div
//                         // onClick={() => Navigate("/get-ready/knowledge-test")}
//                         class="inline-block w-fit  cursor-pointer rounded-[11px] bg-[#A1A3EF] py-3 px-5 text-right text-[22px] font-normal text-white shadow-lg transition duration-300 hover:shadow-xl"
//                     >
//                         Hire Instructor
//                     </div>
//                 </div>

//                 <div
//                     className="flex h-fit w-[80%] flex-col gap-10 rounded-[17px] px-20 py-10"
//                     style={{
//                         boxShadow: "0px 0px 22px #00000042",
//                     }}
//                 >
//                     <h2 className="text-[28px]">{currentQuestion?.question}</h2>
//                     <div className="text-center">
//                         <img
//                             loading="lazy"
//                             src={require("./Assets/Mask Group 232.png")}
//                             // src={currentQuestion?.image}
//                             alt=""
//                             className="h-[273px] w-[372]"
//                         />
//                     </div>
//                     <div className="flex flex-col gap-10">
//                         {currentQuestion.options.map((option, index) => (
//                             <div
//                                 key={index}
//                                 className={`cursor-pointer rounded-[17px] p-5 ${
//                                     selectedOptionIndex === index
//                                         ? results[currentQuestionIndex]
//                                               ?.isCorrect
//                                             ? "bg-[green] text-white"
//                                             : "bg-[red] text-white"
//                                         : " bg-[#a6a7db]"
//                                 }`}
//                                 onClick={() => handleOptionSelect(index)}
//                             >
//                                 <h2 className="text-[23px]">{option}</h2>
//                             </div>
//                         ))}
//                     </div>

//                     <div
//                         onClick={moveToNextQuestion}
//                         class="inline-block w-fit  cursor-pointer rounded-[11px] bg-[#A1A3EF] py-3 px-5 text-right text-[22px] font-normal text-white shadow-lg transition duration-300 hover:shadow-xl"
//                     >
//                         Next
//                     </div>
//                 </div>
//             </div>
//             <Footer />
//         </div>
//     );
// }

// export default AttemptQuize;
// //
