import { Background } from 'devextreme-react/range-selector'
import React, { useState } from 'react'
import { Input } from '../../../../Components/InputComps/InputComps';

export default function SignUpPopup({setShowSignUpPopup}) {
    const [selectedRole, setSelectedRole] = useState('admin');

    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };
    return (
        <div className='fixed inset-0 z-[999999] h-screen w-screen flex items-center justify-center bg-black/50 '>
            <div className='rounded-[20px] flex flex-col h-[500px] w-[800px] overflow-hidden'>
                <div className='h-[40%] bg-repeat-x bg-contain'
                    style={{ backgroundImage: "url('https://assets.gqindia.com/photos/600ebe0b9fcf12125e40094f/16:9/w_2560%2Cc_limit/car.jpg')" }}
                >
                </div>
                <div className='h-[60%] bg-white flex flex-col gap-4 px-12 py-6'>
                    {/* choose role */}
                    <div className='flex items-center justify-between text-3xs'>
                        <p>Choose role for user</p>
                        <div className='min-w-[450px]'>
                        <div className='flex gap-4 w-full'>
                            <div>
                                <label className='space-x-5 flex justify-center items-center'>
                                    <input
                                        className='accent-black w-5 h-5 shadow-none'
                                        type="radio"
                                        value="admin"
                                        checked={selectedRole === 'admin'}
                                        onChange={handleRoleChange}
                                    />
                                    <span>Create Admin</span>
                                </label>
                            </div>
                            <div>
                                <label className='space-x-5 flex justify-center items-center'>
                                    <input
                                        className='accent-black w-5 h-5 shadow-none'
                                        type="radio"
                                        value="staff"
                                        checked={selectedRole === 'staff'}
                                        onChange={handleRoleChange}
                                    />
                                    <span> Create Staff</span>
                                </label>
                            </div>
                        </div>
                        </div>
                        
                    </div>
                    {/* email */}
                    <div className='flex items-center justify-between text-3xs '>
                        <label className='whitespace-nowrap' htmlFor="email">Email Address</label>
                        <div className='min-w-[450px]'>
                        <input id='email' type="text" placeholder={"Email Address"}
                            className={`py-2 px-4 w-full  rounded-md bg-[#F3F6F7]  shadow-[4px_5px_6px_#00000029] border-[#E2E2E2] border-[1px] border-solid`}
                             />
                        </div>
                    </div>
                    {/* password */}
                    <div className='flex items-center justify-between text-3xs '>
                        <label className='whitespace-nowrap' htmlFor="email">Password</label>
                        <div className='min-w-[450px]'>
                        <input id='password' type="password" placeholder={"Password"}
                            className={`py-2 px-4 w-full  rounded-md bg-[#F3F6F7]  shadow-[4px_5px_6px_#00000029] border-[#E2E2E2] border-[1px] border-solid`}
                             />
                        </div>
                    </div>
                    {/* confirm password */}
                    <div className='flex items-center justify-between text-3xs '>
                        <label className='whitespace-nowrap' htmlFor="email">Confirm Password</label>
                        <div className='min-w-[450px]'>
                        <input id='confirmPassword' type="password" placeholder={"Confirm Password"}
                            className={`py-2 px-4 w-full  rounded-md bg-[#F3F6F7]  shadow-[4px_5px_6px_#00000029] border-[#E2E2E2] border-[1px] border-solid`}
                             />
                        </div>
                    </div>
                    <div className='flex items-center justify-end gap-4 mt-3'>
                    <button onClick={()=> setShowSignUpPopup(false)} className='border-[3px] bg-black/0 border-solid cursor-pointer border-[#4C34AD] rounded-xl w-[160px] h-[45px] text-3xs text-[#4C34AD]'>
                        Cancel
                    </button>
                    <button onClick={()=> setShowSignUpPopup(false)} className='border-[3px] capitalize border-solid cursor-pointer bg-[#4C34AD] border-[#4C34AD] rounded-xl w-[160px] h-[45px] text-3xs text-white'>
                        Create {selectedRole}
                    </button>
                    </div>
                </div>
                
            </div>

        </div>
    )
}
