
//Calculate Discount
export function calculateDiscount(totalFee, discountPercentage) {
    const discountAmount = (totalFee * discountPercentage) / 100;
    const discountedPrice = totalFee - discountAmount;
    return {
      discountAmount: discountAmount,
      discountedPrice: discountedPrice
    };
  }


  //Find Lowest Value among Packages
  export const lowestPrice =(value)=> {
    // Calculate the discounted total fee if there's a discount
   
    const discountedPackages = value?.CoursePackages.map(packagee => {
      if (packagee.DiscountType === "add_discount" && packagee.DiscountType !== "") {
          const discountPercentage = parseFloat(packagee.AddDiscount) || 0;
          const discountAmount = (packagee.TotalFee * discountPercentage) / 100;
          // Create a new object with the updated TotalFee
          return { ...packagee, TotalFee: packagee.TotalFee - discountAmount };
      }
      return packagee;
  });
  
  
  const lowestTotalFeePackage = discountedPackages?.reduce((prev, current) =>
      (prev.TotalFee < current.TotalFee) ? prev : current
  );

  return lowestTotalFeePackage.TotalFee;
  }


  //Calculate Tax
  export function calculateTax(value, taxPercent) {
    // Convert tax percentage to decimal
    var taxRate = taxPercent / 100;
    
    // Calculate the tax amount
    var taxAmount = value * taxRate;
    
    // Return the calculated tax
    return taxAmount;
}


