export const quizData = [
    {
        question:
            "When approaching a pedestrian crossing at night, what should you do?",
        options: [
            "Speed up to quickly pass the crossing.",
            "Keep your lights on low beam to avoid dazzling pedestrians.",
            "Flash your headlights to alert pedestrians.",
            "Close your eyes until you pass the crossing.",
        ],
        correctOption:
            "Keep your lights on low beam to avoid dazzling pedestrians.",
    },
    {
        question:
            "What is the recommended action when driving in foggy conditions?",
        options: [
            "Use your high beams for better visibility.",
            "Drive at a faster speed to get through the fog quickly.",
            "Use your low beams and fog lights, if available.",
            "Keep your eyes closed to avoid being blinded by the fog.",
        ],
        correctOption: "Use your low beams and fog lights, if available.",
    },
    {
        question:
            "When driving in heavy rain, what should you do to improve visibility?",
        options: [
            "Turn on your hazard lights to alert other drivers.",
            "Tailgate the vehicle in front to follow their lights.",
            "Use your windshield wipers and defroster.",
            "Roll down your windows to see better.",
        ],
        correctOption: "Roll down your windows to see better.",
    },
    {
        question:
            "If you encounter a vehicle with its high beams on at night, what is the appropriate response?",
        options: [
            "Flash your high beams back at them.",
            "Turn your lights off until the vehicle passes.",
            "Look to the right side of the road to avoid glare.",
            "Stare directly into the headlights to assert dominance.",
        ],
        correctOption: "Flash your high beams back at them.",
    },
    {
        question:
            "When driving in snowy conditions, what can help improve traction?",
        options: [
            "Speed up to maintain momentum.",
            "Turn off traction control to allow better wheel spin.",
            "Use snow chains or winter tires.",
            "Slam on the brakes to slow down quickly.",
        ],
        correctOption: "Use snow chains or winter tires.",
    },
];
