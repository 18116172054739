import React, {useState} from "react";
import TimeInfo from "./TimeInfo";
import { BaseUrl } from "../../../Actions/Base";
import { useParams, useNavigate } from "react-router-dom";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { UpdateStudentAboutInstructorLocation } from "../../../Actions/InstructorA";
import { useDispatch, useSelector } from "react-redux";

function MobileTopBtns({ setClassTimingDialog, setShowTopButtonNumber, showTopButtonNumber,
    setBottomCarMinimizingIcon, setOpenPopup, ClassStartTime, ClassEndTime, onStop,
    StudentFirstName, StudentLastName, StudentImage, StudentEmail, setOpenCancelClassPopup,
    minimizeStudentImage, setMinimizeStudentImage,  paused }) {
    

    const navigate = useNavigate();
    const Dispatch = useDispatch();


    const handleTopButtonClicks = () =>{
        // Check if it's the "Left (Location)" button click
        if (showTopButtonNumber === 0) {
            // Dispatch the action for "Left (Location)"
            if (StudentEmail) Dispatch(UpdateStudentAboutInstructorLocation(StudentEmail, StudentFirstName, StudentLastName));
        }

        setShowTopButtonNumber((prev) => prev + 1);
        setBottomCarMinimizingIcon(false)
        setClassTimingDialog(true)
      }

    const handlePickupStudentClicks = () =>{
        setOpenPopup(true);
      }

    const ImgUrl = `${BaseUrl}/api/images/Student?url=${StudentImage}`;


    const handleCancelClassClick = () => {
        setOpenCancelClassPopup(true)
    }

    const handleMinimizeClick = () =>{
        setMinimizeStudentImage(!minimizeStudentImage)
      }


  return (
    <div className="w-full fixed lg:hidden px-[20px] pb-[10px] top-[80px] bg-[#000000] bg-opacity-60 backdrop-blur-[3px] backdrop-brightness-41">
        <div className="w-full flex justify-between items-center">
            <div className={`${minimizeStudentImage ? "mt-[10px]" : "mt-[-20px]"}`}>
            {(showTopButtonNumber <= 2) && 
                <button className="bg-[#FFFFFF] rounded-[7px] text-[#AF91EA] text-[11px] h-[30px] w-[90px]"
                    onClick={handleCancelClassClick}>
                        Cancel Class
                </button>
            }
            {(showTopButtonNumber === 3) && 
                <button className="bg-[#FFFFFF] rounded-[7px] text-[#AF91EA] text-[11px] h-[30px] w-[90px] "
                        onClick={onStop}
                    >
                            End Class
                </button>
            }
            </div>

            <div className="flex flex-col justify-center items-center gap-[2px] mt-[5px]">
           
                <div  className="relative flex items-center justify-center overflow-hidden rounded-full text-center h-[40px] w-[40px]"
                style={{border:'2px solid #FFFFFF'}}>
                    <img
                        className="absolute w-full"
                        src={ImgUrl}
                        alt={StudentFirstName + "img"}
                    /> 
                </div>

                {!minimizeStudentImage && (
                <>
                    <p className="text-[14px] text-[#FFFFFF]">{StudentFirstName} {StudentLastName}</p>
                    {(showTopButtonNumber === 0) && (
                    <p className="text-[14px] text-[#FFFFFF]"> Left (Location)</p>
                    )}
                    {(showTopButtonNumber === 1) && (
                    <p className="text-[14px] text-[#FFFFFF] text-center"> Reached To Pickup Zone</p>
                    )}
                    {(showTopButtonNumber === 2) && (
                    <p className="text-[14px] text-[#FFFFFF]"> Class Initiation</p>
                    )}
                    {(showTopButtonNumber === 4) && (
                    <p className="text-[14px] text-[#FFFFFF]">Class Marked as Completed</p>
                    )}
                </>
                )}
            </div>

            <div className={`flex flex-col ${minimizeStudentImage ? 'gap-[10px]' : 'gap-[23px]'} mt-[17px]`}>
            {!minimizeStudentImage && (
                <>
                    {(showTopButtonNumber === 0) && (
                    <button className="bg-[#FFFFFF] rounded-[7px] text-[#AF91EA] text-[11px] h-[30px] w-[90px]"
                        onClick={handleTopButtonClicks}
                    >
                            Left (Location)
                    </button>
                    )}

                    {(showTopButtonNumber === 1) && (
                    <button className="bg-[#FFFFFF] rounded-[7px] text-[#AF91EA] text-[11px] h-[30px] w-[90px]"
                        onClick={handleTopButtonClicks}
                    >
                            Reached To Location
                    </button>
                    )}

                    {(showTopButtonNumber === 2) && (
                    <button className="bg-[#FFFFFF] rounded-[7px] text-[#AF91EA] text-[11px] h-[30px] w-[90px]"
                        onClick={handlePickupStudentClicks}
                    >
                            Pickup Student
                    </button>
                    )}

                    {(showTopButtonNumber === 3) && (
                    <div className="flex flex-col justify-center items-center gap-[10px]">
                        {/* <button className="bg-[#FFFFFF] rounded-[7px] text-[#AF91EA] text-[11px] h-[30px] w-[90px] "
                            onClick={onStop}
                        >
                                End Class
                        </button> */}

                        <TimeInfo
                        ClassStartTime={ClassStartTime}
                        ClassEndTime={ClassEndTime}
                        paused={paused} />
                    </div>
                    )}
                </>
            )}

                <div className="flex w-full justify-end">
                    <button className="flex items-center bg-[#FFFFFF] rounded-[5px] text-[#4C34AD] text-[10px] h-[20px] w-[65px]"
                     onClick={handleMinimizeClick} >
                        {minimizeStudentImage ? <KeyboardArrowDownIcon sx={{ color: '#4C34AD', fontSize: 20 }} /> :  <KeyboardArrowUpIcon sx={{ color: '#4C34AD', fontSize: 20 }} /> }
                        {minimizeStudentImage ? "Maximize" : "Minimize"}
                    </button>
                </div>
            </div>
        </div>
      
    </div>
  );
}

export default MobileTopBtns;
