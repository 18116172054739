import React, { useEffect, useMemo, useState } from "react";
import RecordingsList from "./RecordingsList";
import { Avatar, Box } from "@mui/material";
import { liveData } from "./dataSource";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CustomeMediaPlayer from "./MediaPlayer";
import { socket } from "../../Components/InVehicleClass/socket";
import { transformData } from "../../Helpers/utils/liveData";
import { getAllCompleteEvents } from "./api";
import AdminGoogleMap from "./AdminGoogleMap";

const RecordingListCnt = () => {
  const [liveClasses, setLiveClasses] = useState();
  const [liveStream, setLiveStream] = useState();
  const [completedClasses, setCompletedClasses] = useState();
  const [compClassError, setCompClassError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [map, setMap] = useState(null);
  const [locationModal, setLocationModal] = useState("");
  const [currentPosition, setCurrentPosition] = useState();
  const [currentRoute, setCurrentRoute] = useState([]);
  const [savedLocationModal, setSavedLocationModal] = useState("");
  const [videoChunks, setVideoChunks] = React.useState([]);


  useEffect(() => {
    getLiveSession();
    socket.on("live-finish", (data) => {
      if (data.message === "completed") {
        setLiveClasses();
      }
    });

    socket.on("track-location", (data) => {
      if (data) {
        setCurrentRoute((old) => [...old, data]);
        setCurrentPosition(data);
      }
    });

    socket.on("track-stop", () => {
      console.log(currentRoute);
    });
  }, [liveStream, currentPosition]);

  useEffect(() => {
    getcompletedEvents();
  }, []);

  const getcompletedEvents = () => {
    setIsLoading(true);
    getAllCompleteEvents()
      .then((res) => {
        setIsLoading(false);
        if (res.data.message === "success")
          setCompletedClasses(transformData(res.data.data));
        else setCompClassError(res.data.error_message);
      })
      .catch((error) => {
        setIsLoading(false);
        setCompClassError(error.message);
      });
  };

  const getLiveSession = () => {
    socket.on("live-stream", async (liveEvent) => {
      if (liveEvent) {
        console.log("liveEvent",liveEvent);
       // try {
          // const blob = new Blob([liveEvent], { type: "video/webm" });
          // const videoURL = URL.createObjectURL(blob);
          // setLiveStream(videoURL);
       // } catch (error) {
       //   console.error("Error decoding video frame:", error);
       // }
       try {
        console.log("liveEvent received", liveEvent);
  
        // Append the new video chunk to the videoChunks array
        setVideoChunks((prevChunks) => [...prevChunks, liveEvent]);
  
        // Convert the chunks to a Blob and create an Object URL
        const videoBlob = new Blob(videoChunks, { type: "video/mp4" }); // Make sure MIME type matches your stream format
        const videoURL = URL.createObjectURL(videoBlob);
  
        // Set the object URL as the video source
        setLiveStream(videoURL);
  
      } catch (error) {
        console.error("Error processing live stream:", error);
      }
      }
    });

    socket.on("live-classes", (classes) => {
      const transformedData = transformData(classes);
      if (classes) setLiveClasses(transformedData);
    });
  };

  const liveClassesColumns = useMemo(() => {
    return [
      { label: "S.no", field: "sno" },
      {
        label: "Student Name",
        field: "studentName",
        render: (value, row) => (
          <div className="flex items-center">
            <Avatar
              src={`http://localhost:9100/api/images/Student?url=${row.studentAvatar}`}
              alt={value}
              className="mr-2"
            />
            {value}
          </div>
        ),
      },
      {
        label: "Instructor Name",
        field: "instructorName",
        render: (value, row) => (
          <div className="flex items-center">
            <Avatar
              src={`http://localhost:9100/api/images/Instructors?url=${row.instructorAvatar}`}
              alt={value}
              className="mr-2"
            />
            {value}
          </div>
        ),
      },
      { label: "Current Status", field: "status" },
      { label: "Start Time", field: "startTime" },
      { label: "Timer", field: "timer" },
      { label: "S. Emergency Contact", field: "emergencyContact" },
      {
        label: "Action",
        field: "eventId",
        render: (value, row) => {
          return (
            <div className="flex justify-between">
              <Box
                component="span"
                className="flex flex-1 cursor-pointer items-center justify-center gap-1"
                onClick={() => setLocationModal(value)}
              >
                <TrackChangesIcon fontSize="large" />
                <p className="text-2xs">Track</p>
              </Box>

              <Box
                component="span"
                className="flex flex-1 cursor-pointer items-center justify-center gap-1"
              >
                <PlayCircleIcon fontSize="large" />
                <p className="text-2xs">Play</p>
              </Box>
            </div>
          );
        },
      },
    ];
  }, []);

  const completedClassesColumns = useMemo(() => {
    return [
      { label: "S.no", field: "sno" },
      {
        label: "Student Name",
        field: "studentName",
        render: (value, row) => (
          <div className="flex items-center">
            <Avatar
              src={`http://localhost:9100/api/images/Student?url=${row.studentAvatar}`}
              alt={value}
              className="mr-2"
            />
            {value}
          </div>
        ),
      },
      {
        label: "Instructor Name",
        field: "instructorName",
        render: (value, row) => (
          <div className="flex items-center">
            <Avatar
              src={`http://localhost:9100/api/images/Instructors?url=${row.instructorAvatar}`}
              alt={value}
              className="mr-2"
            />
            {value}
          </div>
        ),
      },
      { label: "Current Status", field: "status" },
      { label: "Start Time", field: "startTime" },
      { label: "Timer", field: "timer" },
      { label: "S. Emergency Contact", field: "emergencyContact" },
      {
        label: "Action",
        field: "videoUrl",
        render: (value, row) => {
          return (
            <div className="flex justify-between">
              <Box
                component="span"
                className="flex flex-1 cursor-pointer items-center justify-center gap-1"
                onClick={() =>
                  row?.locationKey && setSavedLocationModal(row?.locationKey)
                }
              >
                <TrackChangesIcon fontSize="large" />
                <p className="text-2xs">Track</p>
              </Box>

              <Box
                component="span"
                className="flex flex-1 cursor-pointer items-center justify-center gap-1"
                onClick={() => {
                  setLiveStream(value);
                }}
              >
                <PlayCircleIcon fontSize="large" />
                <p className="text-2xs">Play</p>
              </Box>
            </div>
          );
        },
      },
    ];
  }, []);
  
  return (
    <div className=" flex w-full flex-col gap-4 p-4">
      <h2 className="mx-5 text-purpal ">Lesson Records</h2>
      <div className=" flex w-full flex-col gap-10">
        <RecordingsList
          title="Live Recordings"
          dataSource={liveClasses}
          columns={liveClassesColumns}
        />
        <RecordingsList
          title="Completed Recordings"
          dataSource={completedClasses}
          columns={completedClassesColumns}
        />
      </div>
      {liveStream && (
        <CustomeMediaPlayer
          open={Boolean(liveStream)}
          onClose={() => setLiveStream("")}
          media={liveStream}
          controls={true}
        />
      )}
      {locationModal && (
        <AdminGoogleMap
          currentPosition={currentPosition}
          currentRoute={currentRoute}
          onLoadMap={(map) => setMap(map)}
          modal={{
            open: Boolean(locationModal),
            onClose: () => setLocationModal(""),
          }}
        />
      )}
      {savedLocationModal && (
        <AdminGoogleMap
          // currentPosition={currentPosition}
          // currentRoute={currentRoute}
          onLoadMap={(map) => setMap(map)}
          locationKey={savedLocationModal}
          modal={{
            open: Boolean(savedLocationModal),
            onClose: () => setSavedLocationModal(""),
          }}
        />
      )}
    </div>
  );
};

export default RecordingListCnt;
