import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteInstituteCoursesA,
  InstituteCoursesA,
  InstitutePendingCoursesA,
} from "../../../../../Actions/CourseA";
import LoadingSpinner from "../../../../../Components/LoadingSpinner/LoadingSpinner";
import NoDataResponse from "../../../../../Components/NoDataResponse";
import CoursesTiles from "./Components/CoursesTiles";
import "./recentCourses.css";

function RecentCoursesMainPage({ setInstituteCourseId, setEdit }) {

  const { PendingCourses, loading } = useSelector((Store) => Store.CourseReducer);
  const Dispatch = useDispatch();

  const [DeleteCourseId, setDeleteCourseId] = useState();
  const [showPopup, setShowPopup] = useState(false);


  const handleDeleteCourse = () => {
    Dispatch(DeleteInstituteCoursesA(DeleteCourseId)).then(() =>
      Dispatch(InstituteCoursesA())
    );
    setShowPopup(false);
 
  };
  const DeleteCourse = (InstituteCourseId) => {
    setDeleteCourseId(InstituteCourseId);
    setShowPopup(true);

  };

  useEffect(() => {
    Dispatch(InstitutePendingCoursesA());
  }, [Dispatch]);
  

  const CourseEditClick = (Id) => {
    setEdit(true);
    setInstituteCourseId(Id);
  };


// // xl:px-9 2xl:px-10 3xl:px-11
  return (
    <div className="max-w-[1300px] px-3 pt-5 2xs:px-4 xs:px-5 sm:px-6 md:px-7 lg:px-0 
    
    ">
      {/* top */}
      {showPopup && (
        <CourseDeletePopup
          type={"course"}
          onConfirm={handleDeleteCourse}
          onCancel={() => setShowPopup(false)}
        />
      )}
      <div className="mr-4 flex justify-center lg:justify-start px-4">
        <h3
          style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
          className="heading_2 font-bold text-[#4C34AD]"
        >
          Recent Courses
        </h3>

        <div></div>

        
      </div>
     
      {/* recent courses list */}
      <div className="flex flex-col items-center bg-backgroundWhiteColor  pt-2   ">
        {loading ? (
          <LoadingSpinner
            Position="fixed"
            Left="0"
            Bg="transparent"
            Height="screen"
            Width="full"
          />
        ) : PendingCourses?.length === 0 ? (
          <NoDataResponse
            topText="Sorry, courses aren't published yet!"
            bottomText="Admin/Institute can add courses by click on the 'Create Course' button from Dashboard."
          />
        ) : (
          <CoursesTiles
            Courses={PendingCourses}
            OnClick={CourseEditClick}
            DeleteCourse={DeleteCourse}
          />
        )}
      </div>
    </div>
  );
}

export default RecentCoursesMainPage;

const CustomButton = ({ text, CourseType, OnClick }) => {
  const BgColor = CourseType !== text ? "bg-white" : "bg-[#C7DDFC]";
  return (
    <button
      className={`${BgColor} w-[19%] cursor-pointer rounded-t-[12px] border-none px-[25px] py-[31px] text-center text-3xs text-[#576271]`}
      onClick={OnClick}
    >
      {text}
    </button>
  );
};

export const CourseDeletePopup = ({ popupDetail, onCancel, onConfirm, type }) => {
  return (
    <div className="fixed left-0 top-0 z-30 flex h-screen w-screen items-center justify-center bg-[#00000029]">
      <div className="relative flex aspect-[353_/_221] h-fit w-full max-w-[360px] flex-col gap-[10px] rounded-[20px] bg-white px-8 py-5">
        <div className="flex flex-col items-center gap-3">
          <h3 className="HeadingBorder text-5xs font-normal">
            Delete Course !!
          </h3>
          <h3 className="mt-10 text-4xs font-normal ">
            Are you sure you want to delete
          </h3>
        </div>
        <div className="mt-5 flex flex-col items-center gap-[6px]">
          {/* )} */}
          <div className="flex items-center justify-center gap-3">
            <button
              type="button"
              className="BrandingButton SemiBold w-fit cursor-pointer rounded-[14px] py-1 px-8 text-6xs"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="SemiBold w-fit  cursor-pointer  rounded-[14px] bg-[#4C34AD] py-1 px-8 text-6xs text-white"
              onClick={onConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
