import React, { useState, useEffect } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import completedIcon from '../Assets/CompletedClass.svg';


function MobileLowerInfotab({setBottomCarMinimizingIcon, setClassTimingDialog, setLeftLocationCurrentTime,
    leftLocationCurrentTime, showTopButtonNumber, setShowTopButtonNumber, setReachedTPpickupZoneCurrentTime,
    reachedTPpickupZoneCurrentTime, setPickupStudentCurrentTime, pickupStudentCurrentTime,
    setCompletedInvehicleCurrentTime, completedInvehicleCurrentTime, PickupService}) {
    const [currentDate, setCurrentDate] = useState('');

  useEffect(() => {
      const today = new Date();
      const formattedDate = today.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
      });
      setCurrentDate(formattedDate);
  }, []);

  useEffect(() => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const currentTime = `${hours}:${minutes}`;

    if (showTopButtonNumber === 1 && !leftLocationCurrentTime) {
      setLeftLocationCurrentTime(currentTime);
      localStorage.setItem("leftLocationCurrentTime", currentTime);
    } else if (showTopButtonNumber === 2 && !reachedTPpickupZoneCurrentTime) {
      setReachedTPpickupZoneCurrentTime(currentTime);
      localStorage.setItem("reachedTPpickupZoneCurrentTime", currentTime);
    } else if (showTopButtonNumber === 3 && !pickupStudentCurrentTime) {
      setPickupStudentCurrentTime(currentTime);
      localStorage.setItem("pickupStudentCurrentTime", currentTime);
    } else if (showTopButtonNumber === 4 && !completedInvehicleCurrentTime) {
      setCompletedInvehicleCurrentTime(currentTime);
      localStorage.setItem("completedInvehicleCurrentTime", currentTime);
    }
  }, [showTopButtonNumber]);


//   useEffect(() => {
//     // Get the current time in HH:MM format when the component mounts
//     const now = new Date();
//     const hours = String(now.getHours()).padStart(2, "0");
//     const minutes = String(now.getMinutes()).padStart(2, "0");
//     if (showTopButtonNumber === 1 && !leftLocationCurrentTime) {
//     setLeftLocationCurrentTime(`${hours}:${minutes}`);
//   } else if (showTopButtonNumber === 2 && !reachedTPpickupZoneCurrentTime) {
//     setReachedTPpickupZoneCurrentTime(`${hours}:${minutes}`);
//   } else if (showTopButtonNumber === 3 && !pickupStudentCurrentTime) {
//     setPickupStudentCurrentTime(`${hours}:${minutes}`);
//   } else if (showTopButtonNumber === 4 && !completedInvehicleCurrentTime) {
//     setCompletedInvehicleCurrentTime(`${hours}:${minutes}`);
//   }
//   }, [showTopButtonNumber]); 

  const handleMinimizeClick = () =>{
    setClassTimingDialog(false)
    setBottomCarMinimizingIcon(true)
  }

  return (
    <div className="w-full fixed lg:hidden p-[20px]  bottom-0 left-0 ">
        <div className="w-[250px] p-[10px] rounded-[11px] bg-[#4C34AD] bg-opacity-60 backdrop-blur-[6px] backdrop-brightness-50">
            <div className="w-full flex items-center justify-between  px-[10px]">
            <span className="text-[#FFFFFF] text-[16px]">{currentDate}</span>
                <button className="flex items-center bg-[#FFFFFF] rounded-[5px] text-[#4C34AD] text-[10px] h-[20px] w-[65px]"
                 onClick={handleMinimizeClick}>
                       <KeyboardArrowDownIcon sx={{ color: '#4C34AD', fontSize: 20 }} />
                        Minimize
                </button>
            </div>
            {showTopButtonNumber >= 1 && PickupService==="Yes" && (
            <div className="mt-[15px] flex items-center justify-between px-[10px]">
                <p className="text-[16px] text-[#FFFFFF]">Left ( Location )</p>
                <p className="text-[16px] text-[#FFFFFF] font-bold">{leftLocationCurrentTime}</p>
            </div>
            )}

            {showTopButtonNumber >= 2 && PickupService==="Yes" && (
                <div className="mt-[15px] flex items-center justify-between px-[10px]">
                    <p className="text-[16px] text-[#FFFFFF] w-[100px]">Reached To pickup Zone</p>
                    <p className="text-[16px] text-[#FFFFFF] font-bold">{reachedTPpickupZoneCurrentTime}</p>
                </div>
            )}

            {showTopButtonNumber >= 3 && (
                <div className="mt-[15px] flex items-center justify-between px-[10px]">
                    <p className="text-[16px] text-[#FFFFFF]">Picked Student</p>
                    <p className="text-[16px] text-[#FFFFFF] font-bold">{pickupStudentCurrentTime}</p>
                </div>
            )}

            {showTopButtonNumber >= 4 && (
                <div className="mt-[15px] flex items-center justify-between px-[10px]">
                    <p className="text-[16px] text-[#FFFFFF] w-[100px]">Completed In Vehicle Class</p>
                    <p className="text-[16px] text-[#FFFFFF] font-bold">{completedInvehicleCurrentTime}</p>
                </div>
            )}

            {showTopButtonNumber >= 4 && (
                <div className="mt-[15px] flex items-center justify-between px-[10px]">
                    <p className="text-[16px] text-[#FFFFFF] w-[130px]">Class Marked as Completed</p>
                    <img src={completedIcon} alt="completedicon" className="w-[37px] h-[37px]" />
                </div>
            )}

        </div>
      
    </div>
  );
}

export default MobileLowerInfotab;
