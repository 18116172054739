import axios from "axios";
import { BaseUrl } from "./Base";
import { CheckLoginServer } from "../Helpers/CheckLogin";

const axiosInstance = axios.create({
  baseURL: BaseUrl,
  withCredentials: true,
});

// export const getStudentSchedule = (enrollmentId) => async (dispatch) => {
//   try {
//     dispatch({
//       type: "GetStudentScheduleRequest",
//     });

//     const { data } = await axiosInstance.get(
//       `/api/schedule/student/${enrollmentId}`
//     );

//     dispatch({
//       type: "GetStudentScheduleSuccess",
//       payload: data,
//     });
//   } catch (error) {
//     CheckLoginServer(error?.response?.data, dispatch);
//     dispatch({
//       type: "GetStudentScheduleFailure",
//       payload: error,
//     });
//   }
// };

export const getTeacherSchedule = (InstructorId) => async (dispatch) => {
  try {
    dispatch({
      type: "GetTeacherScheduleRequest",
    });

    const { data } = await axiosInstance.get(
      `/api/teacher/schedule/${InstructorId}`
    );

    console.log("Dataofteacher", data);
    dispatch({
      type: "GetTeacherScheduleSuccess",
      payload: data,
    });
  } catch (error) {
    CheckLoginServer(error?.response?.data, dispatch);
    dispatch({
      type: "GetTeacherScheduleFailure",
      payload: error,
    });
  }
};

export const getInstituteStudentSchedule = (InstituteId) => async (dispatch) => {
  try {
    dispatch({
      type: "GetInstituteStudentsScheduleRequest",
    });
    console.log("InstituteId in the action is:",InstituteId)
    const { data } = await axiosInstance.get(
      `/api/institute/students/schedule/${InstituteId}`
    );

    console.log("Dataofteacher", data);
    dispatch({
      type: "GetInstituteStudentsScheduleSuccess",
      payload: data,
    });
  } catch (error) {
    CheckLoginServer(error?.response?.data, dispatch);
    dispatch({
      type: "GetInstituteStudentsScheduleFailure",
      payload: error,
    });
  }
};

export const updateSchedule =
  ({ EventId, newStatus, ClassFeedback }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "UpdateScheduleRequest",
      });

      const { data } = await axiosInstance.put(
        `/api/update/schedule/${EventId}`,
        {
          newStatus,
          ClassFeedback,
        }
      );

      dispatch({
        type: "UpdateScheduleSuccess",
        payload: data,
      });
    } catch (error) {
      CheckLoginServer(error?.response?.data, dispatch);
      dispatch({
        type: "UpdateScheduleFailure",
        payload: error,
      });
    }
  };

export const sendClassMissedEmail = (feedback, studentEmail, studentFirstName, studentLastName, studentClassStartDate,studentClassStartTime) => async (dispatch) => {
  try {
    dispatch({
      type: "SendClassMissedEmailRequest",
    });

    const { data } = await axiosInstance.post('/api/send/class-missed-email', {
      feedback,
      studentEmail,
      studentFirstName,
      studentLastName, 
      studentClassStartDate,
      studentClassStartTime
    });

    dispatch({
      type: "SendClassMissedEmailSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "SendClassMissedEmailFailure",
      payload: error,
    });
  }
};
