import React from "react";
import Footer from "../../../../Components/Footer/Footer";

function GetReadyLicenseDetail() {
    return (
        <div className="mt-20">
            <div className="relative  flex h-[697px]   w-full flex-col lg:items-center lg:justify-center   ">
                <img
                    loading="lazy"
                    className=" absolute h-full   w-full lg:inline"
                    src={require("./Assets/landingContainer.png")}
                    alt=""
                />
                <img
                    className=" absolute h-full w-full   lg:inline lg:hidden"
                    src={require("./Assets/mobile1Group 47724.png")}
                    alt=""
                />
                <div className="absolute top-20 z-10 flex flex-col gap-5 px-5 text-center xs:top-10 xs:w-full sm:top-10 sm:w-full md:top-10 md:w-full lg:top-auto lg:mx-auto lg:w-4/5 lg:text-left">
                    <h2 className="LandingPagetext text-[20px] text-white xs:text-[30px] sm:text-[30px] md:text-[45px] lg:text-[45px]">
                        Your Ultimate Destination for &nbsp;
                        <br className="" />
                        Driving Success
                    </h2>
                    <p className="Regularfont w-full text-white xs:text-[26px] sm:text-[26px] md:text-[26px] lg:mx-0 lg:w-1/2 lg:text-[26px]">
                        Discover Vehicle Learning Innovation, your go-to
                        platform for driving success. With years of expertise,
                        we specialize in providing essential driving knowledge
                        and road test practice for new drivers in Canada. Our
                        goal? Ensuring you ace your driving test on the first
                        try.
                    </p>
                </div>
            </div>
            <div className="mx-auto flex w-[80%] flex-col gap-10 py-10">
                <div>
                    <h2 className="SemiBoldItalic  text-[38px]">
                        Heading 1 Goes Here
                    </h2>
                    <p className="text-justify  text-[24px] ">
                        Data demonstrates that new operators of industrial
                        machinery, regardless of age, are at a higher risk of
                        being involved in serious or fatal accidents compared to
                        experienced operators.
                        <br />
                        <br />
                        In order to assist new operators in cultivating safer
                        operational habits, Ontario implemented a graduated
                        licensing program in 1994 for all individuals seeking
                        their initial license to operate industrial machinery.
                        This program allows operators to gradually acquire
                        operational skills and experience in environments with
                        lower risk factors. The program consists of a two-step
                        licensing process which typically spans a minimum of 20
                        months and includes two practical assessments.
                        Successful completion of the Level Two (G2) assessment
                        grants operators full Class G operational privileges.
                        <br />
                        <br />
                        While the Level One assessment focuses on foundational
                        operational skills, the Level Two assessment addresses
                        advanced knowledge and skills typically gained through
                        operational experience. During the assessment, an
                        examiner provides instructions while observing the
                        operator's performance to ensure proficiency in
                        operational tasks.
                        <br />
                        <br />
                        The G2 assessment includes a component involving
                        operation in expressway-like conditions. To proceed with
                        this component, operators must complete and sign a
                        "Declaration of Highway Operating Experience" form,
                        confirming sufficient experience in similar operational
                        environments. The form requires operators to indicate
                        the frequency and average duration of operations on
                        highways or expressways with a speed limit of at least
                        80 km/h within the preceding three months. If operators
                        lack adequate highway operating experience, the examiner
                        must declare the assessment as "out-of-order" and cancel
                        it, resulting in a 50% forfeiture of the prepaid
                        assessment fee. Operators must pay the forfeited portion
                        of the fee to reschedule the assessment. It is crucial
                        to obtain the necessary highway operating experience
                        before rescheduling the assessment.
                        <br />
                        <br />
                        This chapter provides guidance on the various tasks and
                        actions that operators will be expected to perform
                        during the Level Two assessment. However, it is
                        important to note that this serves only as a guide, and
                        operators should refer to chapters 2 and 3 for more
                        detailed information on operational tasks.
                    </p>
                </div>
                <div className="flex  flex-col items-center justify-center gap-20 lg:flex-row">
                    <div>
                        <img
                            className="h-[300px] w-[280px]  xs:h-[300px] xs:w-[500px] sm:h-[400px] sm:w-[700px] lg:h-[534px] lg:w-[802px]"
                            src={require("./Assets/licensedetail.png")}
                            alt=""
                        />
                    </div>
                    <div className="flex flex-col gap-10 px-5">
                        <h2 className="SemiBoldItalic text-center text-[42px]">
                            Hire an instructor for Industrial Machinery
                        </h2>
                        <p className="text-[24px]">
                            For expert guidance and a safe testing experience,
                            consider hiring a qualified instructor to conduct
                            your road test. Their expertise ensures you're
                            well-prepared and confident, maximizing your chances
                            of success while prioritizing safety.
                        </p>
                        <div
                            // onClick={() => Navigate("#")}
                            class="inline-block w-fit cursor-pointer rounded-[11px] bg-[#A1A3EF] py-3 px-5 text-[22px] font-normal text-white shadow-lg transition duration-300 hover:shadow-xl"
                        >
                            Hire Instructor
                        </div>
                    </div>
                </div>
                <div>
                    <h2 className="SemiBoldItalic  text-[38px]">
                        Heading 2 Goes Here
                    </h2>
                    <p className="text-justify text-[24px]">
                        Data demonstrates that new operators of industrial
                        machinery, regardless of age, are at a higher risk of
                        being involved in serious or fatal accidents compared to
                        experienced operators.
                        <br />
                        <br />
                        In order to assist new operators in cultivating safer
                        operational habits, Ontario implemented a graduated
                        licensing program in 1994 for all individuals seeking
                        their initial license to operate industrial machinery.
                        This program allows operators to gradually acquire
                        operational skills and experience in environments with
                        lower risk factors. The program consists of a two-step
                        licensing process which typically spans a minimum of 20
                        months and includes two practical assessments.
                        Successful completion of the Level Two (G2) assessment
                        grants operators full Class G operational privileges.
                        <br />
                        <br />
                        While the Level One assessment focuses on foundational
                        operational skills, the Level Two assessment addresses
                        advanced knowledge and skills typically gained through
                        operational experience. During the assessment, an
                        examiner provides instructions while observing the
                        operator's performance to ensure proficiency in
                        operational tasks.
                        <br />
                        <br />
                        The G2 assessment includes a component involving
                        operation in expressway-like conditions. To proceed with
                        this component, operators must complete and sign a
                        "Declaration of Highway Operating Experience" form,
                        confirming sufficient experience in similar operational
                        environments. The form requires operators to indicate
                        the frequency and average duration of operations on
                        highways or expressways with a speed limit of at least
                        80 km/h within the preceding three months. If operators
                        lack adequate highway operating experience, the examiner
                        must declare the assessment as "out-of-order" and cancel
                        it, resulting in a 50% forfeiture of the prepaid
                        assessment fee. Operators must pay the forfeited portion
                        of the fee to reschedule the assessment. It is crucial
                        to obtain the necessary highway operating experience
                        before rescheduling the assessment.
                        <br />
                        <br />
                        This chapter provides guidance on the various tasks and
                        actions that operators will be expected to perform
                        during the Level Two assessment. However, it is
                        important to note that this serves only as a guide, and
                        operators should refer to chapters 2 and 3 for more
                        detailed information on operational tasks.
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default GetReadyLicenseDetail;
