import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  OpenLoginSignUp,
  ResetLoginSignUp,
} from "../../../../../../Actions/ToggleSignupA";
import { OnLogin, OnRegister, OtherOption } from "../MiniComponents";
import UserInput from "./UserInput";
import { useState } from "react";
import { useEffect } from "react";
import { SubmitButton } from "../../../../LoginAndRegister";
import ContinueWithGoogle from "../LeftSide/ContinueWIth/ContinueWithGoogle";
import ReCAPTCHA from "react-google-recaptcha-v2";
import "./rightSide.css";
import { useAuthContext } from "../../../../../../context/authContext";

const SigninRightSide = ({
  TopLoginRegisterBtns,
  Credentials,
  setCredentials,
  PasswordError,
  captchaError,
}) => {
  const Dispatch = useDispatch();
  const { AuthPageName } = useSelector((Store) => Store.ToggleSignupReducer);
  const [Errors, setErrors] = useState();
  const { error } = useSelector((Store) => Store.LoginSignupReducer);

  const { setCaptcha } = useAuthContext();

  function onChange(value) {
    setCaptcha(value);
  }

  let SignInbtnColor =
    AuthPageName === "Sign Up"
      ? "bg-[#EAEFF4] text-black"
      : "bg-white text-[#A1A3EF]";
  let Gap = AuthPageName === "Sign Up" ? "gap-4 xl:gap-7" : "gap-2 xl:gap-4";
  let ButtonStyle = `flex items-center gap-5 justify-center
    border-[1px] border-[#FEFEFE]
  
    cursor-pointer  lg:min-w-[400px]  max-w-[625px]
    font-normal rounded-lg
    md:py-2                 xl:py-3 
    md:px-6     lg:px-10    xl:px-16    2xl:px-20 
    DropShadow`;
  
    // DropShadow
    let h4Style = `text-left font-normal  whitespace-nowrap 
        text-4xs md:text-[19px] lg:text-xs   xl:text-base  2xl:text-[30px]
        w-auto   md:w-[220px]   lg:w-[250px] xl:w-[290px]  2xl:w-[350px]`;

  useEffect(() => {
    setErrors(JSON.parse(JSON.stringify(error)));
  }, [error]);
  const OnClick = () => {
    Dispatch(OpenLoginSignUp("Sign In", true));
    setCredentials({
      Email: "",
      Password: "",
      ConfirmPassword: "",
      FirstName: "",
      LastName: "",
    });
  };

  return (
    <div
      className={`relative flex w-full flex-col 
                        justify-start gap-2 px-3
                        py-4 pb-5 xs:px-6 sm:px-9 md:w-[56%]  md:basis-[56%] md:pl-8  md:pr-12     lg:pl-8 lg:pr-14  xl:gap-4    xl:py-8
                        xl:pl-8                xl:pr-16     2xl:p-12 
                        2xl:py-14               2xl:pr-24 `}
    >
      <span
        onClick={() => {
          Dispatch(ResetLoginSignUp(false));
          setCredentials({
            Email: "",
            Password: "",
            ConfirmPassword: "",
            FirstName: "",
            LastName: "",
          });
        }}
        class="material-symbols-outlined absolute right-[1rem] top-[1rem] hidden cursor-pointer font-semibold text-primary md:block"
        style={{
          fontSize: "30px",
        }}
      >
        close
      </span>
      <h4 className=" w-full text-[18px] font-bold  text-[white] md:text-[#5f56a8]	 lg:text-[24px] xl:text-[30px]">
        Client {AuthPageName === "Sign Up" ? "Sign Up" : "Sign in"}
      </h4>

      <h4 className=" w-full text-3xs  font-bold text-white underline decoration-4 underline-offset-8	 md:text-black lg:text-[20px] xl:text-[30px]">
        {AuthPageName === "Sign Up" ? "Sign Up " : AuthPageName + " "}
        With E-Mail
      </h4>

      {/* <button type='button' className={`${TopLoginRegisterBtns} ${SignInbtnColor} -left-[1px] `}
                onClick={OnClick}
            >
                Sign In
            </button> */}

      <div>
        <div className={`flex flex-col items-start justify-between ${Gap}`}>
          <UserInput
            AuthPageName={AuthPageName}
            Credentials={Credentials}
            setCredentials={setCredentials}
            Errors={Errors}
            setErrors={setErrors}
            PasswordError={PasswordError}
          />

          <div className={`flex flex-col ${Gap}`}>
            {AuthPageName === "Sign Up" ? (
              <>
                <div className="">
                  {captchaError ? (
                    <h4 className="font-normal text-[red]"> {captchaError} </h4>
                  ) : null}
                  <ReCAPTCHA
                    sitekey="6Lecb_IoAAAAANjflXMmXPZiBMaU0clxSmxPG9f9"
                    onChange={onChange}
                  />
                </div>
                <OnRegister
                  MobileDisplay={"hidden"}
                  DesktopDisplay={"inline"}
                  TermsDisplay={"flex"}
                  AuthPageName={AuthPageName}
                />
              </>
            ) : (
              <>
                <OnLogin setCredentials={setCredentials} />
                <div className="">
                  {captchaError ? (
                    <h4 className="font-normal text-[red]"> {captchaError} </h4>
                  ) : null}
                  <ReCAPTCHA
                    sitekey="6Lecb_IoAAAAANjflXMmXPZiBMaU0clxSmxPG9f9"
                    onChange={onChange}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <SubmitButton ButtonType="submit" AuthPageName={AuthPageName} />

        <div className="flex w-full items-center justify-center">
          <h4 className="orlines text-center text-primary">Or</h4>
        </div>

        <div className="flex items-center justify-center">
          <ContinueWithGoogle />
        </div>

        <div className="flex w-full items-center justify-center">
          <OtherOption
            MobileDisplay={"hidden"}
            setCredentials={setCredentials}
            Credentials={Credentials}
            DesktopDisplay={"inline"}
            AuthPageName={AuthPageName}
          />
        </div>
        {
                    AuthPageName === "Sign Up" ?
                        <>

                         

                            <button className={`w-full ${ButtonStyle} justify-start py-2 px-0 bg-white `}
                                onClick={() => {
                                    Dispatch(OpenLoginSignUp("Register", true));
                                    setCredentials({
                                        Email: "",
                                        Password: "",
                                        ConfirmPassword: "",
                                        FirstName: "",
                                        LastName: ""
                                    })
                                }}
                                type="button"
                            >
                                <img className='w-6 xl:w-10'
                                    src={require('../../../../Assets/Institute Icon.png')} alt=""
                                />
                                <h5 className={` ${h4Style} inline-block`}>
                                    Register as a Partner
                                </h5>
                            </button>

                        </>
                        : null
                }

        {/* <button
          type="submit"
          className="Boldfont mt-6 inline-block w-full cursor-pointer rounded-[12px] border-none py-3 text-4xs text-[#521E9D] md:hidden"
        >
          {AuthPageName === "Sign Up" ? "Sign Up" : "Sign in"}
        </button> */}
      </div>
    </div>
  );
};
export default SigninRightSide;
