// react States
import React, { useEffect, useState } from "react";
import $ from 'jquery'
import './jquery.hislide.js'

import studenInstCarIcon from "./Assets/studenInstCarIcon.png";
import studenInstEmailIcon from "./Assets/studenInstEmailIcon.png";
import studenInstCallIcon from "./Assets/studenInstCallIcon.png";
import { useDispatch, useSelector } from "react-redux";

// css
import './ManageStudentCarousel.css'
import { GetCourseInstructorA, GetInstructorsA } from "../../../../../Actions/InstructorA.js";
import { BaseUrl } from "../../../../../Actions/Base.js";
import ClassScheduler from "../../../../../Components/Scheduler/Scheduler.jsx";
import { useParams } from "react-router-dom";
import axios from "axios";
import useAxios from "../../../../../Helpers/CustomHooks/useAxios.js";

const CourseSlider = ({ Instructors: InstructorState, InstructorType, setInstructors, setShowSlider, Events, setEvents, setPrefrredTimeTable, PrefrredTimeTable, SubmitSchedule, setInsEvent }) => {
    // const [StudentPrefrredTimeTable, setStudentPrefrredTimeTable] = useState([]);
    const { Instructors, CourseInstructors } = useSelector(Store => Store.InstructorReducer)
    const { Student, ScheduleTimeTable, CoursePackage } = useSelector(Store => Store.StudentReducer)
    const Dispatch = useDispatch()
    setTimeout(() => {
        $('.slide')?.hiSlide();
    }, 100);
    const { EnrollmentId } = useParams();
    useEffect(() => {
        if (EnrollmentId)
            Dispatch(GetInstructorsA(EnrollmentId))
            Dispatch(GetCourseInstructorA(EnrollmentId))
    }, [Dispatch, EnrollmentId])
    // useEffect(() => {
    //     if (CoursePackage)
    //         Dispatch(GetCourseInstructorA(EnrollmentId))
    // }, [Dispatch, CoursePackage])
    // const [res, , loading] = useAxios("get", `/api/teacher/schedule/${InstrucId}`)
    // useEffect(() => {
    //     if (res?.data)
    //       setEvents(...StudentPrefrredTimeTable ,res?.data.map(value => {
    //         value.endDate = new Date(value.endDate)
    //         value.startDate = new Date(value.startDate)
    //         return value
    //       }))
    //   }, [res])

    console.log("CourseInstructorsss", CourseInstructors)
    // console.log("CoursePackage", CoursePackage)
    const SelectInstructor = async (e, value, Src, Name) => {
        await showSchedule(e, value)
        const InstructorTypes = ["Driving", "Online", "InClass"];
        let Index = InstructorTypes.indexOf(InstructorType);

        InstructorState[Index] = {

            id: value?.Instructor?.InstructorId,
            InstructorImage: Src,
            InstructorName: Name,
            text: Name + " - " + InstructorType,
            InstructorType,
            LicenseTypeName: value?.Instructor?.InstructorLicenses.map((val)=> (val?.LicenseType?.LicenseTypeName)).join(", "),
            Email: value?.Email,
            PhoneNumber: value?.PhoneNumber,
            InstructorFK: value?.Instructor?.InstructorId
        }
        setInstructors([...InstructorState,]);


        // Add a delay of 3 seconds before calling setShowSlider
    setTimeout(() => {
        setShowSlider({ Show: false, InstructorType: "" });
    }, 3000); // 3000 milliseconds = 3 seconds
    }


    // console.log("StudentPrefrredTimeTable", StudentPrefrredTimeTable)

    // useEffect(() => {
    //     if (ScheduleTimeTable) {
    //       console.log("schedule time table is:", ScheduleTimeTable);
    //       const editAble = JSON.parse(JSON.stringify(ScheduleTimeTable));
      
    //       // Function to get the upcoming Monday from the current date
    //       const getNextMonday = (date) => {
    //         const currentDay = date.getDay(); // 0 is Sunday, 6 is Saturday
    //         const daysUntilMonday = (currentDay === 0 ? 1 : (currentDay > 1 ? 7 - currentDay : 0)); // Adjust logic here
    //         const nextMonday = new Date(date);
    //         nextMonday.setDate(date.getDate() + daysUntilMonday); // Set to next Monday
    //         nextMonday.setHours(0, 0, 0, 0); // Set time to the start of the day (00:00)
    //         return nextMonday;
    //       };
      
    //       // Function to move the event to the next appropriate day
    //       const addDays = (date, days) => {
    //         const newDate = new Date(date);
    //         newDate.setDate(date.getDate() + days);
    //         return newDate;
    //       };
      
    //       // Function to match schedule events with the next week's correct day
    //       const convertedEvents = editAble.map((value, index) => {
    //         let startDate = new Date(value.start);
      
    //         // Calculate how many days to add based on the schedule
    //         const originalEventDay = new Date(value.start).getDay(); // Get the day of the event (0-6)
    //         const daysFromStart = originalEventDay; // Move to the corresponding day in the next week
      
    //         // Set startDate to the correct day next week
    //         startDate = addDays(getNextMonday(new Date()), daysFromStart);
      
    //         // Set the start time based on the original event
    //         const originalStartTime = new Date(value.start);
    //         startDate.setHours(originalStartTime.getHours(), originalStartTime.getMinutes(), originalStartTime.getSeconds());
      
    //         // Set endDate to exactly 1 hour after startDate
    //         let endDate = new Date(startDate);
    //         endDate.setHours(startDate.getHours() + 1); // Add exactly 1 hour to the start time
      
      
    //         return {
    //           ...value,
    //           startDate,
    //           endDate,
    //           allDay: false,  // Assume non-all-day event
    //           description: value.description || "",
    //           text: value.text || "",
    //         };
    //       });
      
    //       console.log("converted events are:", convertedEvents);
    //       setStudentPrefrredTimeTable(convertedEvents);
    //     }
    //   }, [ScheduleTimeTable]);

      const [InstrucId, setInstrucId] = useState();
    const [data, setData] = useState([]);
   

    const showSchedule = async (e, value) => {
        const instructorId = value?.Instructor?.InstructorId;

        // Update state with the selected instructor ID
        setInstrucId(instructorId);

        // Fetch data from the API and update state with the fetched data
        axios.get(`/api/teacher/schedule/${instructorId}`)
            .then((response) => {
                if (response.data && Array.isArray(response.data)) {
                    console.log("response.data in the show schedule is:",response.data)
                    setData(response.data);
                    if (response.data && response.data.length > 0) {
                        console.log("data.length in the show schedule is:",response.data)
                        const transformedData = data.map((value) => {
                            value.endDate = new Date(value.endDate);
                            value.startDate = new Date(value.startDate);
                            return value;
                        });
                        setInsEvent(transformedData);
                        
                    }
                } else {
                    console.error('Invalid response structure:', response);
                }
            })
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        if (data && data.length > 0) {
            console.log("data.length in the show schedule is:",data)
            const transformedData = data.map((value) => {
                value.endDate = new Date(value.endDate);
                value.startDate = new Date(value.startDate);
                return value;
            });
            setInsEvent(transformedData);
            
        }
    }, [data, InstrucId]);

 

      

    return (
        <div className="border-[red] border-[10px] border-solid" >


            <div style={{
                backgroundColor: '#454545b3', paddingTop: '50px', position: "fixed",
                 overflowY: "scroll",
            }} className=" h-[calc(100vh_-_80px)] w-screen mt-20 top-0 left-0 z-[1500]">
                <div className="slide hi-slide 2xs:flex 2xs:justify-center">
                    <div className="hi-prev"></div>
                    <div className="hi-next"></div>
                    <ul className="2xs:flex 2xs:flex-row flex-wrap 2xs:justify-center">
                        {CourseInstructors?.map(value => {

                            const Src = `${BaseUrl}/api/images/Instructors?url=${value?.Instructor?.ProfileImage}`

                            const Name = value?.FirstName + " " + value?.LastName
                            return <li key={value?.Instructor?.InstructorId}>
                                <div className="studenCarouselBoxes">
                                    <div className="vehicalBox">
                                        <div className="vehicalImgConainer">
                                            <center className="h-[170px]  lg:h-[270px]" >
                                                <img src={Src} alt="InstructorImage" style={{ width: '100%' }} />
                                            </center>
                                        </div>
                                        <div className="managingStudentCarouselTxt">
                                            <p className="">{Name}</p>
                                            <center>
                                                <button className="cauraselInstructorTxt">
                                                    Instructor
                                                </button>
                                            </center>
                                            <hr className="vehicalHr" style={{ marginBottom: '7px' }} />
                                            <div className="infoCaurasel">
                                                <img src={studenInstCarIcon} alt="studenInstCarIcon" />
                                                <span>
                                                    Type:
                                                    {` ${value?.Instructor?.InstructorLicenses.map((val)=> (val?.LicenseType?.LicenseTypeName)).join(", ")}`}
                                                </span>
                                                <br />
                                            </div>
                                            <div className="infoCaurasel">
                                                <img src={studenInstEmailIcon} alt="studenInstEmailIcon" style={{ width: '26px', height: '15px' }} /> <span>{value?.Email}</span><br />
                                            </div>
                                            <div className="infoCaurasel">
                                                <img src={studenInstCallIcon} alt="studenInstCallIcon" style={{ width: '20px' }} /> <span>{value?.PhoneNumber}</span><br />
                                            </div>
                                            <hr className="vehicalHr" style={{ marginTop: '12px' }} />
                                            <center className="mt-1">
                                                <button className=" cardsBtn cursor-pointer mx-0 my-1" onClick={(e) =>
                                                    SelectInstructor(e, value, Src, Name)
                                                }
                                                >
                                                    Select Instructor
                                                </button>
                                                {/* <button className=" cardsBtn  cursor-pointer"
                                                onClick={(e)=> showSchedule(e, value)}
                                                >
                                                    Show Scehdule
                                                </button> */}
                                            </center>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        })}
                     

                    </ul>
                </div>

                {/* <SchedulerCompleted Student={Student} Events={[...InsEvent, ...PrefrredTimeTable]} setEvents={setEvents} setPrefrredTimeTable={setPrefrredTimeTable} PrefrredTimeTable={PrefrredTimeTable} SubmitSchedule={SubmitSchedule} studentSchedule={'lastFiveValues'}   Edit={true}/> */}
            </div>
            {/********************************** Calender Ends *****************************************/}
        </div >
    );
}

export const SchedulerCompleted = ({ Student, Events, setEvents, setPrefrredTimeTable, PrefrredTimeTable, SubmitSchedule, Edit, studentSchedule  }) => {

// console.log("Eventssss", Events)

    return <ClassScheduler
        Name={Student?.FirstName + " " + Student?.LastName}
        setEvents={setEvents} Events={Events}
        setPrefrredTimeTable={setPrefrredTimeTable}
        Edit={Edit}
        studentSchedule={studentSchedule}
        PrefrredTimeTable={PrefrredTimeTable}
    />
}
export default CourseSlider;
