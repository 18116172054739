import React, { useEffect, useState } from "react";

function PrepareTest() {
    return (
        <div className="flex items-center justify-center bg-[#F9F9F9]">
            <div className="flex  w-[1483px] flex-col items-center gap-5 overflow-hidden bg-[#F9F9F9] xs:mt-10 sm:mt-10 md:mt-10 lg:mt-10  lg:gap-5">
                <h2 className="SemiBoldItalic xs:text-center xs:text-xs sm:text-center lg:text-[38px]">
                    Prepare for Your Canadian Driving Test Experience
                </h2>
                <p className=" px-20 text-center text-xs  font-normal  ">
                    Feeling a bit overwhelmed on your Canadian driving test day
                    is normal. To ensure you're fully prepared, it's essential
                    to familiarize yourself with the test day requirements for
                    your particular Canadian province and the type of driver's
                    license you're aiming for. To assist you, we've outlined
                    some key points to keep in mind for your Canadian driving
                    test.
                </p>
                <div className=" mt-10 mb-20 flex flex-wrap items-center justify-center gap-10 px-5">
                    <Card
                        title="Required Documentation"
                        Description="The driver's license type you're applying for in Canada will dictate the specific documents you must bring to the driver examination center. These may encompass various forms of identification, proof of residency, parental consent for drivers under the age of 18, and citizenship documentation, among others."
                        src={require("../Assets/Required Documentation.png")}
                    />
                    <Card
                        title="Parental Authorization"
                        Description="In each province, there exists a designated minimum age requirement to initiate the graduated driver licensing process. Furthermore, typically for drivers below the age of 18, parental or legal guardian consent is mandatory before obtaining their initial driver's license."
                        src={require("../Assets/Parental Authorization.png")}
                        Style="text-white"
                        DivStyle="bg-[#A1A3EF] "
                    />
                    <Card
                        title="Associated Costs"
                        Description="Various fees are linked to testing and obtaining driver's license cards. It's essential to review the applicable license fees for your specific license type and inquire about the accepted payment methods at your designated driver examination center."
                        src={require("../Assets/Associated Costs.png")}
                    />
                    <Card
                        title="Vehicle Requirements"
                        Description="You are responsible for providing your test vehicle. Alongside valid vehicle registration and Canadian car insurance, you must also ensure that your vehicle is mechanically sound and capable of undergoing the road test."
                        src={require("../Assets/Group 47653.png")}
                        Style={"text-white"}
                        DescriptionStyle="bg-[#A1A3EF] text-white"
                        DivStyle="bg-[#A1A3EF] "
                    />
                    <Card
                        title="Retaking the Test"
                        Description="In the event that you do not pass your Canadian driving test on the initial try, you will have the chance to retake the test at a later time. The duration of the waiting period between test attempts can be obtained from your local driver examination center."
                        src={require("../Assets/Retaking the Test.png")}
                    />
                    <Card
                        title="Stay Refreshed and Calm"
                        Description="Prior to any driving test in Canada, it's crucial to ensure you're well-rested and relaxed. Avoid staying up late the night before and aim to arrive early for your driving test appointment. Rushing can lead to increased stress and may affect your performance negatively."
                        src={require("../Assets/Stay Refreshed and Calm.png")}
                        Style="bg-[#A1A3EF] text-white"
                        DivStyle="bg-[#A1A3EF] "
                    />
                </div>
            </div>
        </div>
    );
}

export default PrepareTest;
function Card({ title, Description, src, Style, DivStyle }) {
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div
            className={`rounded p-5 ${DivStyle} ${
                dimensions.width < 418
                    ? "h-fit w-[300px]"
                    : "h-[289px] w-[388px]"
            }`}
            style={{
                boxShadow: "5px 5px 19px #00000038",
            }}
        >
            <img src={src} className="" alt="" />
            <h2 className={`${Style}`}>{title}</h2>
            <p className={`text-wrap mt-2 text-justify ${Style}`}>
                {Description}
            </p>
        </div>
    );
}
