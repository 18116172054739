import React, {useContext, useState, useEffect} from 'react';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { StudentClassesContext } from '../../context/StudentClassesContext';
import Drivingicon from "./Assets/DrivingIcon.png"
import Onlineicon from "./Assets/Onlineicon.png"
import inClassIcon from "./Assets/inClassIcon.png"



const StudentTimeTableClassesindicator = ({ text, CoursePackage, timeTable, Indicator, selectedDate, setSelectedDate, setShowOwnTimeFields, showStudentButton, StudentName}) => {
    let SpanStyle = `whitespace-nowrap text-white border-[1px] border-solid border-[#D7DAE2] h-fit
    py-3        sm:py-2         lg:py-3      xl:py-4
    px-4        sm:px-3         lg:px-4      xl:px-5
    px-4        md:px-5
    text-3xs`;

      // Function to handle date selection by the user
   const handleDateChange = (date) => {
    // 'date' is passed directly from the DatePicker
    setSelectedDate(date);
  };

  const handleAddClassChange = (event) => {
    setShowOwnTimeFields(true);
};

const { studentClassesScheduleAppointments, setStudentClassesScheduleAppointments } = useContext(StudentClassesContext);
// State variables to store scheduled hours
const [drivingScheduledHours, setDrivingScheduledHours] = useState(0);
const [onlineScheduledHours, setOnlineScheduledHours] = useState(0);
const [inClassScheduledHours, setInClassScheduledHours] = useState(0);
    // Helper function to calculate total scheduled hours
    const calculateScheduledHours = (classType) => {
    return studentClassesScheduleAppointments
        .filter(appointment => appointment.ClassType === classType)
        .reduce((totalHours, appointment) => {
        const startDate = new Date(appointment.start);
        const endDate = new Date(appointment.end);
        const durationInHours = (endDate - startDate) / (1000 * 60 * 60); // convert milliseconds to hours
        return totalHours + durationInHours;
        }, 0);
    };

    // Use useEffect to update hours when studentClassesScheduleAppointments changes
  useEffect(() => {
    const drivingHours = calculateScheduledHours('Driving');
    const onlineHours = calculateScheduledHours('Online');
    const inClassHours = calculateScheduledHours('InClass');

    setDrivingScheduledHours(drivingHours);
    setOnlineScheduledHours(onlineHours);
    setInClassScheduledHours(inClassHours);
  }, [studentClassesScheduleAppointments]); // Dependency array includes studentClassesScheduleAppointments


    return (
        // <div className='flex justify-center md:justify-end w-fit '>
        //     <span className={`bg-[#A1A3EF] ${SpanStyle} ${text} rounded-l-md`}>Driving</span>
        //     <span className={`bg-[#7F82F9] ${SpanStyle} ${text}`}>Online</span>
        //     <span className={`bg-[#5456C7] ${SpanStyle} ${text} rounded-r-md`}>In Class</span>
        // </div>
        <div className='flex flex-col'>
            <div className='w-[100%] flex justify-between gap-[50px]'>
                <div className='flex flex-col gap-[15px]'>
                    <p style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                        className="text-[20px] 2xl:text-[24px] text-[#4D4F5C]">
                     {StudentName} Schedule
                    </p>
                    <div className='flex items-center gap-[10px]'>
                        <div className='w-[170px] flex flex-col gap-[10px]'>
                            <div className='flex items-center gap-[10px]'>
                                <img src={Drivingicon} alt="drivingicon" />
                                <p style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                                    className="text-[17px] text-[#A1A3EF]">Driving |
                                </p>
                                <span style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                                    className="text-[17px] text-[#A1A3EF]">
                                    {CoursePackage?.DrivingHours ? `${CoursePackage?.DrivingHours} / ${drivingScheduledHours}` : `0 / ${drivingScheduledHours}`}
                                </span>
                            </div>
                            <div className='w-full h-[8px] rounded-[3px] bg-[#A1A3EF]'>
                            </div>
                        </div>

                        <div className='w-[200px] flex flex-col gap-[15px] mt-[5px]'>
                            <div className='flex items-center gap-[10px]'>
                                <img src={Onlineicon} alt="Onlineicon" />
                                <p style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                                    className="text-[17px] text-[#7F82F9]">Online/Other |
                                </p>
                                <span style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                                    className="text-[17px] text-[#7F82F9]">
                                    {CoursePackage?.OnlineHours ? `${CoursePackage?.OnlineHours} / ${onlineScheduledHours}` : `0 / ${onlineScheduledHours}`}
                                </span>
                            </div>
                            <div className='w-full h-[8px] rounded-[3px] bg-[#7F82F9]'>
                            </div>
                        </div>

                         <div className='w-[170px] flex flex-col gap-[10px]'>
                            <div className='flex items-center gap-[10px]'>
                                <img src={inClassIcon} alt="Onlineicon" />
                                <p style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                                    className="text-[17px] text-[#5456C7]">In Class |
                                </p>
                                <span style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                                    className="text-[17px] text-[#5456C7]">
                                    {CoursePackage?.InClassHours ? `${CoursePackage?.InClassHours} / ${inClassScheduledHours}` : `0 / ${inClassScheduledHours}`}
                                </span>
                            </div>
                            <div className='w-full h-[8px] rounded-[3px] bg-[#5456C7]'>
                            </div>
                        </div> 
                    </div>
                    {/* <span className='flex justify-center md:justify-end  '>
                        <span className='flex flex-col gap-3 items-center'>
                            <button className={`${SpanStyle} ${text} bg-[#A1A3EF] rounded-l-md`}>Driving</button>
                        {timeTable === "assignSchedule" ? (
                            <span>
                                {CoursePackage?.DrivingHours ? `${CoursePackage?.DrivingHours} / ${drivingScheduledHours}` : `0 / ${drivingScheduledHours}`}
                            </span>
                            ) : null}
                        </span>

                        <span className='flex flex-col gap-3 items-center'>
                            <button className={`${SpanStyle} ${text} bg-[#7F82F9]`}>
                                Online
                            </button>
                            {timeTable === "assignSchedule" ? (
                                <span>
                                    {CoursePackage?.OnlineHours ? `${CoursePackage?.OnlineHours} / ${onlineScheduledHours}` : `0 / ${onlineScheduledHours}`}
                                </span>
                                ) : null}
                        </span>

                        <span className='flex flex-col gap-3 items-center'>
                            <button className={`${SpanStyle} ${text} bg-[#5456C7] rounded-r-md`}>In Class</button>
                            {timeTable === "assignSchedule" ? (
                                <span>
                                    {CoursePackage?.InClassHours ? `${CoursePackage?.InClassHours} / ${inClassScheduledHours}` : `0 / ${inClassScheduledHours}`}
                                </span>
                                ) : null}
                        </span>
                    </span> */}
                </div>

                {timeTable === "assignSchedule" ?
                <div className='w-[800px] flex justify-center ml-[-80px]'>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        value={selectedDate}
                        onChange={handleDateChange}
                        // onChange={(date) => {
                        //   setSelectedDate(date);
                        // }}
                        renderInput={(params) => <TextField {...params} style={{ height: '30px', width: '300px' }} />}
                    />
                </LocalizationProvider>
                </div> : null}

                <div className='flex items-start'>
                    {timeTable === "assignSchedule" ?
                        <span className='flex flex-col gap-3 items-center'>
                            <button className={`${SpanStyle} ${text} bg-[#1EC1FE] rounded-l-md`}>
                                Student
                            </button>
                        </span>
                    : null }
                    {timeTable === "assignSchedule" ?
                    <span className='flex flex-col gap-3 items-center'>
                        <button className={`${SpanStyle} ${text} bg-[#34ADAD] rounded-r-md`}>
                            Instructor
                        </button>
                    </span>
                    : null }
                </div>
            </div>

            {showStudentButton &&(
                <div className="w-full flex justify-end items-center mt-[-15px]">
                <button type="button"
                    className="gap-[10px] rounded-[10px] bg-[#4C34AD] font-poppinssans w-[121px]  h-[43px] 2.5xl:h-[43px] text-[#FFFFFF] text-[19px]"
                    onClick={() => handleAddClassChange()} // Save schedule on click
                        >
                        + Add Class
                    </button>
                </div>
            )}
      </div>
    )
}

export default StudentTimeTableClassesindicator