import React from 'react'
import ImagePreviewer from '../../../../../../Components/ImagePreviewer/ImagePreviewer'

const LicenseUpdatePopup = ({setLicenseUpdate}) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 "
    style={{zIndex: '200000'}}
    >
      <div
    
        className="BG_IMAGEE w-[80%] rounded-[24px]  bg-[#e1e7f0] py-10 px-10 text-white sm:w-[380px] sm:p-10 md:w-[686px] md:px-[4rem] 2xl:pb-14 3xl:pb-14"
      >
        <span
        onClick={()=> setLicenseUpdate(false)}
          class="material-symbols-outlined absolute right-[1rem] top-[1rem] cursor-pointer font-semibold text-primary"
          style={{
            fontSize: "30px",
          }}
        >
          close
        </span>
        <h2 className='text-black text-center'>Change your last license picture.</h2>
        <div>
        <ImagePreviewer />
        </div>

        </div>
        </div>
  )
}

export default LicenseUpdatePopup
