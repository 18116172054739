// export const transformData = (classes) => {
//   return classes?.map((item) => ({
//     Student: {
//       UserId: item.CourseEnrollment.User.UserId,
//       FirstName: item.CourseEnrollment.User.FirstName,
//       LastName: item.CourseEnrollment.User.LastName,
//       UserName: item.CourseEnrollment.User.UserName,
//       Email: item.CourseEnrollment.User.Email,
//       PhoneNumber: item.CourseEnrollment.User.PhoneNumber,
//       User: item.CourseEnrollment.User.User,
//       lastSeen: item.CourseEnrollment.User.lastSeen,
//       StudentImage: item.CourseEnrollment.User.StudentImage,
//       createdAt: item.CourseEnrollment.User.createdAt,
//     },
//     Instructor: {
//       InstructorId: item.Instructor.InstructorId,
//       UserFK: item.Instructor.UserFK,
//       InstructorIdCardNo: item.Instructor.InstructorIdCardNo,
//       Address: item.Instructor.Address,
//       PostalCode: item.Instructor.PostalCode,
//       Province: item.Instructor.Province,
//       City: item.Instructor.City,
//       DOB: item.Instructor.DOB,
//       PhoneNumber: item.Instructor.PhoneNumber,
//       EmergencyPhoneNumber: item.Instructor.EmergencyPhoneNumber,
//       Gender: item.Instructor.Gender,
//       UnderTrainingStudents: item.Instructor.UnderTrainingStudents,
//       ProfileImage: item.Instructor.ProfileImage,
//       TrainerPermitImage: item.Instructor.TrainerPermitImage,
//       Status: item.Instructor.Status,
//       Suspend: item.Instructor.Suspend,
//       Country: item.Instructor.Country,
//       Speciality: item.Instructor.Speciality,
//       InstituteFK: item.Instructor.InstituteFK,
//       createdAt: item.Instructor.createdAt,
//       FromInstitute: item.Instructor.FromInstitute,
//       User: {
//         UserId: item.Instructor.User.UserId,
//         FirstName: item.Instructor.User.FirstName,
//         LastName: item.Instructor.User.LastName,
//         UserName: item.Instructor.User.UserName,
//         Email: item.Instructor.User.Email,
//         PhoneNumber: item.Instructor.User.PhoneNumber,
//         User: item.Instructor.User.User,
//         lastSeen: item.Instructor.User.lastSeen,
//         StudentImage: item.Instructor.User.StudentImage,
//         createdAt: item.Instructor.User.createdAt,
//       },
//     },
//     event: {
//       EventId: item.EventId,
//       EnrollmentFK: item.EnrollmentFK,
//       InstructorFK: item.InstructorFK,
//       text: item.text,
//       startDate: item.startDate,
//       endDate: item.endDate,
//       classStatus: item.classStatus,
//       ClassNo: item.ClassNo,
//       ClassType: item.ClassType,
//       description: item.description,
//       allDay: item.allDay,
//       recurrenceRule: item.recurrenceRule,
//       UserEmail: item.UserEmail,
//       createdAt: item.createdAt,
//     },
//   }));
// };

export const transformData = (data) => {
  return data.map((item, index) => {
    const transformedItem = {
      sno: index + 1,
      studentName: `${item.CourseEnrollment.User.FirstName} ${item.CourseEnrollment.User.LastName}`,
      studentAvatar: item.CourseEnrollment.User.StudentImage,
      instructorName: `${item.Instructor.User.FirstName} ${item.Instructor.User.LastName}`,
      instructorAvatar: item.Instructor.ProfileImage,
      status: item.classStatus,
      startTime: new Date(item.startDate).toLocaleString(),
      timer: "N/A", // You can add logic to calculate the timer if needed
      emergencyContact: item.Instructor.EmergencyPhoneNumber,
      eventId: item.EventId, // Assuming media field is represented by EventId for simplicity
      videoUrl: item?.VideoUrl,
      locationKey: item?.LocationKey,
    };

    return transformedItem;
  });
};
