import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useEffect, useState } from 'react';

const CancelClassModal = ({open, setOpenCancelClassPopup, setClassSummaryDialog}) => {
    
  return (
    <Dialog open={open}>
        <DialogTitle className='text-center'>Cancel This Class?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className='text-center'>
          Do you want to cancel this class?
          </DialogContentText>
        </DialogContent>
        
           
            <DialogActions>
                <div className='w-full flex justify-center items-center gap-4'>
                <Button variant='outlined' className='bg-primary px-5 py-1' onClick={()=>setOpenCancelClassPopup(false)}>No</Button>
                <Button variant='contained' className='bg-primary px-5 py-1' onClick={()=>setClassSummaryDialog(true)} >Yes</Button>
                </div>
            </DialogActions>
        
    </Dialog>

  )
}

export default CancelClassModal