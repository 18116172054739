import React, { useState } from "react";

import { DateInput } from "../../../../../../../Components/InputComps/DateComps";
import {
    Input,
    SelectList,
} from "../../../../../../../Components/InputComps/InputComps";
import { LicenseTypesOption } from "../../../OptionsArr/OptionsArr";
import ImagePreviewer from "../../../../../../../Components/ImagePreviewer/ImagePreviewer";
import LicenseImagePreviewer from "../../../../../../../Components/ImagePreviewer/LicenseImagePreviewer";
import { BaseUrl } from "../../../../../../../Actions/Base";

export const InstructorLicenseDetail = ({
    value,
    index,
    onChange,
    InstructorData,
    RemoveInstructor,
    readOnly,
    setInstructorErrors,
    setNewFieldError,
    LicenseImages,
    updateOnly,
    licenseImg,
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const LicenseImage = `${BaseUrl}/api/images/Instructors?url=${value?.LicenseImage}`;

    // console.log("isHovered", isHovered);
    return (
        <>
            <tr className=" border-0 border-b border-solid border-[#A5C9FF]">
                <td class=" h-full px-6 text-3xs font-medium text-black">
                    <div className=" mb-5 flex  w-full items-center justify-center [&>*]:max-w-full">
                        <Input
                            licenseInput={true}
                            readOnly={true}
                            Placeholder="0546"
                            State={value?.LicenseNumber || ""}
                            // onChange={(e) =>
                            //     onChange(
                            //         e,
                            //         index,
                            //         "LicenseNumber",
                            //         "License Number"
                            //     )
                            // }
                        />
                    </div>
                </td>
                <td class="  px-6  text-3xs text-black">
                    <div className="mb-3 [&>*]:w-full [&>div>span>input]:shadow-none">
                        <DateInput
                            licenseInput={true}
                            type={"licence-date"}
                            Id={"LicenceExpiry"}
                            readOnly={true}
                            State={
                                value?.LicenceExpiry ||
                                value?.LicenseExpiry ||
                                ""
                            }
                            // onChange={(e) =>
                            //     onChange(
                            //         e,
                            //         index,
                            //         "LicenceExpiry",
                            //         "License Date"
                            //     )
                            // }
                        />
                    </div>
                </td>
                <td class=" px-6  text-3xs text-black">
                    <div className="mb-5 w-full [&>*]:max-w-full">
                        <SelectList
                            licenseInput={true}
                            readOnly={true}
                            // Label="Speciality"
                            Id="LicenseTypeFK"
                            defaultValue=""
                            Text="License Type"
                            OptionsArr={LicenseTypesOption}
                            State={value?.LicenseTypeFK || ""}
                            onChange={(e) =>
                                onChange(
                                    e,
                                    index,
                                    "LicenseTypeFK",
                                    "LicenseDetails Speciality"
                                )
                            }
                        />
                    </div>
                </td>
                <td
                    class=" px-6  text-3xs text-black  transition-transform duration-300 ease-in-out "
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    {/* <div className="w-full [&>*]:max-w-full"> */}
                    {/* {!isHovered && ( */}
                    <LicenseImagePreviewer
                        LicenseDetail={false}
                        readOnly={readOnly}
                        Id={"LicenseImage"}
                        // isHovered={isHovered}
                        PreviewStyle=""
                        BrowseBtnStyle=""
                        ContainerStyle=""
                        onChange={onChange}
                        ImgUrl={
                            updateOnly
                                ? LicenseImage || LicenseImages[index]
                                : LicenseImages[index]
                        }
                    />

                    {isHovered && (
                        <LicenseImagePreviewer
                            LicenseDetail={true}
                            isHovered={isHovered}
                            readOnly={readOnly}
                            Id="LicenseImage"
                            PreviewStyle=""
                            BrowseBtnStyle=""
                            ContainerStyle=""
                            onChange={(e) =>
                                onChange(
                                    e,
                                    index,
                                    "LicenseImage",
                                    "LicenseImage"
                                )
                            }
                            ImgUrl={
                                updateOnly
                                    ? LicenseImage || LicenseImages[index]
                                    : LicenseImages[index]
                            }
                        />
                    )}
                    {/* </div> */}
                </td>
                <td class="relative whitespace-nowrap px-6 text-center text-3xs font-medium">
                    <button
                        onClick={() => RemoveInstructor(index)}
                        type="button"
                        className=" bottom-[14px] left-0 cursor-pointer  rounded-[10px] border-0 bg-[#4C34AD]  px-4 py-1 text-4xs font-medium text-white md:mb-[2px] md:px-8 md:py-2 lg:py-2"
                    >
                        Delete
                    </button>
                    {/* <button
                          onClick={AddInstructor}
                          type="button"
                          className="absolute bottom-[14px] left-0 mb-1 md:mb-[2px]  cursor-pointer px-4 py-1  md:px-8 md:py-2 rounded-[10px] border-0 bg-[#4C34AD] text-2xs font-medium text-white"
                        >
                          Add
                        </button> */}
                </td>
            </tr>
        </>
    );
};
