import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import EnrolledCourseDetails from "../../../EnrolledCourses/Component/EnrolledCourseInfo/Components/EnrolledCourseDetails";
import EnrolledCourseHrs from "../../../EnrolledCourses/Component/EnrolledCourseInfo/Components/EnrolledCourseHrs";
import { GetCoursesOfSInstructorA } from "../../../../Actions/InstructorA";
import { useEffect } from "react";
import { BaseUrl } from "../../../../Actions/Base";
import useAxios from "../../../../Helpers/CustomHooks/useAxios";
import LoadingSpinner from "../../../../Components/LoadingSpinner/LoadingSpinner";

const InstructorAssignedCourses = ({
    InstructorCourses,
    Course,
    error,
    loading,
    setShowSubscription,
}) => {
    const { UserInfo } = useSelector((Store) => Store.LoginSignupReducer);
    const { InstructorId } = useParams();
    let ButtonBorders = `whitespace-nowrap py-2 px-3  text-white border-[1px] border-solid border-[#D7DAE2]
    px-2        md:px-3 
    text-5xs    md:text-4xs     xl:text-3xs`;
    if (error && error.response && error.response.status === 404) {
        return (
            <div className="flex min-h-[50vh] items-center justify-center text-[30px] ">
                {error.response.data.message}
            </div>
        );
    }
    if (loading) {
        return <LoadingSpinner />;
    }
    return (
        <div className=" p-8 ">
            {InstructorCourses?.InstructorEnrolledCourses &&
                InstructorCourses?.InstructorEnrolledCourses?.map((values) => {
                    const { ShortDescription, LongDescription } = values;
                    const { CourseName, Description, CourseThumbnail } =
                        values?.Course;
                    {
                        /* const packagesHours = 
                    console.log("packagesHours", packagesHours);
                    const { DrivingHours, InClassHours, OnlineHours } =
                        packagesHours; */
                    }

                    return (
                        <div
                            className={`  mt-10 flex min-h-[205px] flex-col items-center  justify-between rounded-[10px] border-[1px] border-solid border-[#D6D6D6]   bg-white bg-opacity-25 p-6 py-7  px-5
 shadow md:px-10 lg:flex-row`}
                        >
                            <div className="flex w-[80%] flex-col items-center justify-between gap-5  xl:flex-row">
                                <div className="flex w-[64%] flex-col items-center gap-5 md:flex-row">
                                    <span className="flex h-full max-h-[150px] w-4/5 max-w-[230px] items-center justify-center overflow-hidden">
                                        <img
                                            className="w-full"
                                            // src=""
                                            src={`${BaseUrl}/api/Thumbnail/course?url=${CourseThumbnail}`}
                                            alt="Course Thumbnail"
                                        />
                                    </span>
                                    <CorseDetailsText
                                        Description={Description}
                                        CourseName={CourseName}
                                    />
                                </div>
                                {/* <EnrolledCourseDetails
                    InstituteCourse={Course?.CoursePackage?.InstituteCourse}
                />  */}
                                {/* <EnrolledCourseHrs CoursePackage={Course?.CoursePackage} /> */}
                                {values?.CoursePackages.map((c) => {
                                    const {
                                        DrivingHours,
                                        InClassHours,
                                        OnlineHours,
                                    } = c;

                                    return (
                                        <span className="flex w-[34%]">
                                            <span className="flex flex-col items-center gap-3">
                                                <button
                                                    className={`${ButtonBorders} rounded-l-md bg-[#A1A3EF]`}
                                                >
                                                    Driving
                                                </button>
                                                <span className="SemiBold">
                                                    {/* {CoursePackage?.DrivingHours
                                ? CoursePackage?.DrivingHours
                                : 0} */}
                                                    {DrivingHours}
                                                </span>
                                            </span>

                                            <span className="flex flex-col items-center gap-3">
                                                <button
                                                    className={`${ButtonBorders} bg-[#7F82F9]`}
                                                >
                                                    Online
                                                </button>
                                                <span className="SemiBold">
                                                    {OnlineHours}
                                                </span>
                                            </span>
                                            <span className="flex flex-col items-center gap-3">
                                                <button
                                                    className={`${ButtonBorders} rounded-r-md bg-[#5456C7]`}
                                                >
                                                    In Class
                                                </button>
                                                <span className="SemiBold">
                                                    {InClassHours}
                                                </span>
                                            </span>
                                        </span>
                                    );
                                })}
                            </div>

                            <hr className="h-0 w-3/5  lg:min-h-[150px] lg:w-0" />

                            <span className="  flex w-full flex-col items-end justify-center gap-3 sm:w-1/4">
                                <Link
                                    to={
                                        UserInfo.InstituteUserType ===
                                        "Instructor"
                                            ? `/enrolledcourse/coursemodule/${Course?.EnrollmentId}`
                                            : `/About/Instructor/schedule/${InstructorId}`
                                    }
                                    className={`w-full max-w-[185px] cursor-pointer whitespace-nowrap rounded-xl border-none bg-[#4C34AD] py-3 px-2 text-center
                                text-3xs    font-semibold text-white no-underline
                                sm:max-w-[215px]    md:text-xs 
                        `}
                                >
                                    View Calendar
                                </Link>
                            </span>
                        </div>
                    );
                })}
        </div>
    );
};

export default InstructorAssignedCourses;

function CorseDetailsText({ Description, CourseName }) {
    // let Description = InstituteCourse?.Course?.Description;
    if (Description?.length >= 220)
        Description = Description.split("").slice(0, 215).join("") + "...";
    // let CourseName = InstituteCourse?.Course?.CourseName;
    if (CourseName?.length >= 40)
        CourseName = CourseName.split("").slice(0, 37).join("") + "...";

    return (
        <span className="flex flex-col    items-center justify-between gap-2 sm:gap-5 md:gap-8 lg:gap-11 xl:gap-14 2xl:gap-16">
            <h3
                className={`Boldfont           text-center  text-3xs
                        md:text-2xs    lg:text-start                 xl:text-xs `}
            >
                {CourseName}
            </h3>

            <p
                className={`w-11/12
                       max-w-[650px]      text-center  text-4xs 
                       sm:w-11/12                 md:w-auto 
                       md:text-3xs                           lg:text-start`}
            >
                {Description}
            </p>
        </span>
    );
}
