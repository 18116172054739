import React, { useContext, useEffect, useState } from 'react'
import SideFilters from '../../../../../../Components/SideFilter/SideFilters'
import './index.css'
import { useDispatch } from 'react-redux'
import { SocketContext } from '../../../../../../App'
import { FilterCourses } from '../../../../../../Actions/Events/FilterA'
import useAxios from '../../../../../../Helpers/CustomHooks/useAxios'
import { BaseUrl } from '../../../../../../Actions/Base'
import { Option, Select } from '../../../../../../Components/SelectList';

const dateModifyArr = ["new to old", "old to new"];
const alphabeticalOrderArr = ["A to Z", "Z to A"];

const CoursesFilters = () => {
  const Dispatch = useDispatch()
  const [Filters, setFilters] = useState([])

  const Socket = useContext(SocketContext);

  const [licenseTypesRes] = useAxios("get", `${BaseUrl}/api/licensetypes`)
  const [vehicleTypesRes] = useAxios("get", `${BaseUrl}/api/AllVehicleTypes`)

  const OnChange = (Value, key) => setFilters({ ...Filters, [key]: Value.value })

  const SubmitCourseFilter = () => {
    Dispatch(FilterCourses(null, "FilterCoursesRequest"))
    Socket?.emit("FilterVLIAdminCourses", Filters);
  }

  useEffect(() => {
    Socket?.on("FilteredVLIAdminCourses", (Courses) => Dispatch(FilterCourses(Courses, "FilterCoursesSuccess")))
  }, [Socket, Dispatch])



  return (
    <SideFilters Title="Filter Course" WidthStyle="w-full max-w-[380px]" OnClick={SubmitCourseFilter} >

      <FilterTemplate Arr={vehicleTypesRes?.data} optionText="VehicleTypeName" optionValue="VehicleTypeId"
        Heading="Vehicle Type" placeholder="Vehicle Type G"
        onChange={Value => OnChange(Value, "VehicleTypeFK")}
        value={Filters?.VehicleTypeFK}
      />

      <FilterTemplate Arr={licenseTypesRes?.data} optionText="LicenseTypeName" optionValue="LicenseTypeId"
        Heading="License Type" placeholder="Lincense Type M"
        onChange={Value => OnChange(Value, "LicenseTypeFK")}
        value={Filters?.LicenseTypeFK}
      />

      <FilterTemplate Arr={dateModifyArr}
        Heading="Sort by date" placeholder="New to Old"
        onChange={Value => OnChange(Value, "createdAt")}
        value={Filters?.createdAt}
      />

      <FilterTemplate Arr={alphabeticalOrderArr}
        Heading="sort alphabetically" placeholder="A to Z"
        onChange={Value => OnChange(Value, "CourseName")}
        value={Filters?.CourseName}
      />

    </SideFilters>
  )
}
const FilterTemplate = ({ Arr, onChange, value, Heading, placeholder, optionValue, optionText }) => {

  return (
    <span className='flex flex-col gap-[18px]'>
      <h3 className='font-normal text-4xs border-[#0000001a]'>{Heading} </h3>
      <Select SelectedContainerStyles="CourseFilterResultContainer" ListContainerStyles=""
        placeholder={placeholder} value={value}
        onChange={onChange}
      >
        {Arr?.map(value => {
          let Value = value;
          let Text = value;
          if (optionValue)
            Value = value[optionValue];

          if (optionText)
            Text = value[optionText];

          return <Option key={Value} value={Value}>
            {Text}
          </Option>
        }
        )}
      </Select>
    </span>
  )
}
export default CoursesFilters