import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Store from "./Store";
import { Provider } from "react-redux";
import { AuthProvider } from "./context/authContext";
import { LocationProvider } from "./context/locationContext";
import { TimetableProvider } from './context/timetableContext'; // Adjust the path as needed
import { StudentClassesProvider } from "./context/StudentClassesContext";
import { CourseProvider } from "./context/courseHoursContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={Store}>
      <LocationProvider>
        <AuthProvider>
          <TimetableProvider>
            <StudentClassesProvider>
              <CourseProvider>
                <App />
              </CourseProvider>
            </StudentClassesProvider>
          </TimetableProvider>
        </AuthProvider>
      </LocationProvider>
    </Provider>
   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
