import React from 'react'
import './TimeSlots.css'
import { useTimeSlotsHider } from '../../../Helpers/CustomHooks/Hide Elements/useTimeSlotsHidder';
import { useState, useEffect } from 'react';
import { CheckSchedule } from '../Payment/Components/Helper/CheckSchedule';
import { DaysAndTimeSlot } from './Components/DaysAndTimeSlot';

// Helper function to format time from 24-hour to 12-hour format
const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    const period = +hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (+hours % 12) || 12;  // Convert to 12-hour format
    return `${formattedHours}:${minutes} ${period}`;
};

// Helper function to convert 12-hour time to 24-hour format
const to24HourFormat = (time) => {
    let [timeStr, period] = time.split(' ');
    let [hours, minutes] = timeStr.split(':');
    hours = +hours;
    if (period === 'PM' && hours !== 12) hours += 12;
    if (period === 'AM' && hours === 12) hours = 0;
    return `${hours.toString().padStart(2, '0')}:${minutes}`;
};

// Function to compare time slots
const compareTimeSlots = (a, b) => {
    const [startA] = a.split(' - ');
    const [startB] = b.split(' - ');
    return to24HourFormat(startA).localeCompare(to24HourFormat(startB));
};

const TimeSlots = ({ ShowTimeSlots, setShowTimeSlots, setEnrollmentData, EnrollmentData, setClassHoursData, classHoursData, Err, setErr, setShowSchedule, isClassHours, timeSlots }) => {
    console.log("timeslots in the timeslot components are:", timeSlots)
    // let TimeSlotsArr = ["", "09:00 AM - 10:00 AM", "11:00 AM - 12:00 PM", "01:00 PM - 02:00 PM", "03:00 PM - 04:00 PM", "05:00 PM - 06:00 PM"];
    const [TimeSlotsArr, setTimeSlotsArr] = useState([""]);

    let Days = ["", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
    let BorderStyle = "border-[#E6E6E6] border-solid border-[2px] border-l-0 border-t-0";
    const [InsideReference, setInsideReference] = useState();
    const [OutsideReference, setOutsideReference] = useState();

    useEffect(() => {
        if (timeSlots && timeSlots.length > 0) {
            // Process time slots data to create a dynamic array
            const formattedSlots = [];
    
            // Extract and format time slots
            timeSlots.forEach(slot => {
                if (slot.StartTime && slot.EndTime) {
                    const formattedTimeSlot = `${formatTime(slot.StartTime)} - ${formatTime(slot.EndTime)}`;
                    if (!formattedSlots.includes(formattedTimeSlot)) {
                        formattedSlots.push(formattedTimeSlot);
                    }
                }
            });

            // Sort time slots by start time
            console.log("formattedSlots are:", formattedSlots)
            const sortedSlots = formattedSlots.sort(compareTimeSlots);
            setTimeSlotsArr(["", ...sortedSlots]);
        }
    }, [timeSlots]);


    useTimeSlotsHider(OutsideReference, InsideReference, setShowTimeSlots, ShowTimeSlots)

    // Function to set the schedule depending on isEnrollment
    const SetSchedule = (day, TimeSlot) => {
        console.log("Updating schedule for:", day, TimeSlot);

        if (isClassHours) {
                // Update classHoursData if isEnrollment is false
                setClassHoursData({
                    ...classHoursData,
                    DayandHoursData: {
                        ...classHoursData.DayandHoursData,
                        Schedule: { ...classHoursData.DayandHoursData.Schedule, [day]: TimeSlot }
                    }
                });
        } else {
             // Update EnrollmentData if isEnrollment is true
            setEnrollmentData({
                ...EnrollmentData,
                StudentData: {
                    ...EnrollmentData.StudentData,
                    Schedule: { ...EnrollmentData.StudentData.Schedule, [day]: TimeSlot }
                }
            });
           
        }
    };

    // const SetSchedule = (day, TimeSlot) => {
    //     setEnrollmentData({ ...EnrollmentData, StudentData: { ...EnrollmentData.StudentData, Schedule: { ...EnrollmentData.StudentData.Schedule, [day]: TimeSlot } } })
    // }

    const [FirstTimeAttempted, setFirstTimeAttempted] = useState(false);

    const OnSelectSchedule = () => {
        setFirstTimeAttempted(true);
    
        // Pass classHoursData if isClassHours is true, else pass EnrollmentData
        let Data = isClassHours ? classHoursData : EnrollmentData;
    
        // Call CheckSchedule with the appropriate data based on isClassHours
        let CheckErr = CheckSchedule(Data, Err, setErr, null, FirstTimeAttempted, isClassHours);
    
        if (Object.entries(CheckErr).length !== 0) return;
    
        if (Err?.Schedule) {
            delete Err?.Schedule;
            setErr({ ...Err });
        }
    
        setShowTimeSlots(false);
        setShowSchedule(true);
    
        console.log(
            isClassHours ? "classHoursData.DayandHoursData.Schedule" : "EnrollmentData.StudentData.Schedule",
            isClassHours ? classHoursData.DayandHoursData.Schedule : EnrollmentData.StudentData.Schedule
        );
    };
    


    // const OnSelectSchedule = () => {
    //     setFirstTimeAttempted(true)
    //     let CheckErr = CheckSchedule(EnrollmentData, Err, setErr, null, FirstTimeAttempted)
    //     if (Object.entries(CheckErr).length !== 0)
    //         return
    //     if (Err?.Schedule) {
    //         delete Err?.Schedule
    //         setErr({ ...Err })
    //     }
    //     setShowTimeSlots(false);
    //     setShowSchedule(true)
    //     console.log("EnrollmentData.StudentData.Schedule", EnrollmentData.StudentData.Schedule)
    // }

    return (
        ShowTimeSlots
            ? <div
                ref={(e) => setOutsideReference(e)}
                className='flex flex-col fixed justify-center  items-center top-0 z-20 h-[calc(100vh_-_80px)] min-h-fit bg-[#00000040] w-full mt-20'
            >


                <div className='bg-white rounded-[40px] gap-6 flex flex-col justify-center items-center w-[95%] xl:w-fit mx-5 overflow-hidden px-2 md:px-14 py-14 '
                    ref={(e) => setInsideReference(e)}
                >

                    <div className='flex w-full h-fit overflow-x-scroll overflow-y-auto py-2 xl:justify-center'
                        id='TimeSlotScrollbar'
                    >
                        <div className='flex flex-col w-fit '>


                            {TimeSlotsArr.map((TimeSlot, TimeIndex, TimeArr) => {
                                return <div className='flex justify-end' key={TimeSlot}>
                                    <DaysAndTimeSlot Days={Days} TimeIndex={TimeIndex} TimeSlot={TimeSlot} BorderStyle={BorderStyle}
                                        TimeArr={TimeArr} SetSchedule={SetSchedule} Err={Err} setErr={setErr} EnrollmentData={EnrollmentData} 
                                        classHoursData={classHoursData} isClassHours={isClassHours} FirstTimeAttempted={FirstTimeAttempted} timeSlots={timeSlots} />
                                </div>
                            })}

                        </div>


                    </div>


                    <button type='button' onClick={OnSelectSchedule} className='text-base text-white bg-[#A1A3EF] px-[18px] py-[9px]   rounded-xl cursor-pointer w-fit'>
                        Select Schedule
                    </button>
                </div>
            </div>
            : null
    )
}

export default TimeSlots