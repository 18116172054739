import React from 'react';

export function ClassesCheckboxBtns({
  Days,
  BorderStyle,
  TimeIndex,
  Change,
  classHoursData, // Pass the correct data
  DaysIndex,
  TimeArr,
}) {
  // Check if the current time slot is selected (array of selected time slots)
  const isChecked = classHoursData?.DayandHoursData?.Schedule?.[Days[TimeIndex]]?.includes(TimeArr[DaysIndex]);

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    const currentSchedule = classHoursData?.DayandHoursData?.Schedule?.[Days[TimeIndex]] || [];

    if (checked) {
      // Add the time slot if checked
      Change(Days[TimeIndex], [...currentSchedule, TimeArr[DaysIndex]]);
    } else {
      // Remove the time slot if unchecked
      Change(Days[TimeIndex], currentSchedule.filter((time) => time !== TimeArr[DaysIndex]));
    }
  };

  return (
    <span className={`w-52 flex justify-center py-5 ${BorderStyle}`}>
      <label className='Checkbox-Btn'>
        <input
          className='relative left-1/2 -translate-x-1/2'
          value={TimeArr[DaysIndex]}
          type="checkbox"
          name={`${Days[TimeIndex]}`}
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <span className='CheckMark'></span>
      </label>
    </span>
  );
}
