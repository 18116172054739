import React, {useEffect} from "react";

import ImageAndPrice from "./ImageAndPrice";
import { CourseInfo, RatingBtns } from "./Navigation";
import { Link } from "react-router-dom";
import './CourseTiles.css'
import { useLocationContext } from '../../../../context/locationContext';


export function CourseTiles({ CoursesInfo, setref, setFilteredCount  }) {

    // Access the user's location from the context
    const { location } = useLocationContext();
    console.log("location in course tiles is:",location)

    //to count the filtered courses to manage the show more button
   /*  useEffect(() => {
        let count = 0;
        
        CoursesInfo?.forEach((Course) => {
            Course?.InstituteCourses?.forEach((InstituteCourse) => {
                const courseCity = InstituteCourse?.Institute?.City;
                const courseState = InstituteCourse?.Institute?.State;

                if ((location.city && courseCity && location.city.toLowerCase() === courseCity.toLowerCase()) ) {
                    count++;
                }

                 if ((location.city && courseCity && location.city.toLowerCase() === courseCity.toLowerCase()) ||
                    (location.state && courseState && location.state.toLowerCase() === courseState.toLowerCase())) {
                    count++;
                } 
            });
        });

        setFilteredCount(count); // Pass the filtered count to the parent component
    }, [CoursesInfo, location, setFilteredCount]); */


    useEffect(() => {
        let count = 0;
    
        CoursesInfo?.forEach((Course) => {
            Course?.InstituteCourses?.forEach((InstituteCourse) => {
                const courseCity = InstituteCourse?.Institute?.City;
                const courseState = InstituteCourse?.Institute?.State;
                const courseCountry = InstituteCourse?.Institute?.Country; // Assuming Country exists
                const locationCity = location?.city?.toLowerCase();
                const locationState = location?.state?.toLowerCase();
                const locationCountry = location?.country?.toLowerCase();
    
                // Step 1: Check if both cities are present
                if (locationCity && courseCity) {
                    // Step 2: Check if cities match
                    if (locationCity === courseCity.toLowerCase()) {
                        count++;
                        console.log("Count incremented due to city match:", count);
                        return; // Stop further checks for this InstituteCourse
                    } else {
                        return; // Cities are present but don't match, stop further checks
                    }
                }
    
                // Check by state if city is not matched or not available
                if (locationState && courseState) {
                    if (locationState === courseState.toLowerCase()) {
                        count++;
                        console.log("Count incremented due to state match:", count);
                        return; // Stop further checks for this InstituteCourse
                    } else {
                        return; // States are present but don't match, stop further checks
                    }
                }
    
                // Check by country if neither city nor state matches
                if (locationCountry && courseCountry) {
                    if (locationCountry === courseCountry.toLowerCase()) {
                        count++;
                        console.log("Count incremented due to country match:", count);
                        return; // Stop further checks for this InstituteCourse
                    } else {
                        return; // Countries are present but don't match, stop further checks
                    }
                }
            });
        });
    
        setFilteredCount(count); // Pass the filtered count to the parent component
    }, [CoursesInfo, location, setFilteredCount]);
    
    

    return (
        <>
            {CoursesInfo?.map((Course, index) => {
                return Course?.InstituteCourses?.filter((InstituteCourse) => {
                    const courseCity = InstituteCourse?.Institute?.City;
                    const courseState = InstituteCourse?.Institute?.State;
                    const courseCountry = InstituteCourse?.Institute?.Country;

                    // Check if the course matches the user's city or, if not present, the state
                    if (location.city && courseCity) {
                        return location.city.toLowerCase() === courseCity.toLowerCase();
                    } else if (location.state && courseState) {
                        return location.state.toLowerCase() === courseState.toLowerCase();
                    } else if (location.country && courseCountry) {
                        console.log("country is",courseCountry.toLowerCase())
                        console.log("country in location is",location.country.toLowerCase())
                        return location.country.toLowerCase() === courseCountry.toLowerCase();
                    }
                    return false;
                }).map((InstituteCourses) => {
                    return (
                        <Link
                            to={`/course/${InstituteCourses?.InstituteCourseId}`}
                            className={`box-shadow-course-card relative flex w-1/5 min-w-[252px]
                                max-w-[275px] flex-col  items-center  rounded-[20px] bg-white
                                px-2 pb-1 text-black no-underline duration-300 hover:bg-[#BCBEFF] md:pb-2
                                lg:max-w-[300px] lg:px-3 lg:pb-3 xl:max-w-[325px] xl:px-4 2xl:max-w-[360px] 2xl:px-5 2xl:pb-4`}
                            ref={(e) => {
                                setref(e);
                            }}
                            key={Number(index)}
                        >
                            <ImageAndPrice
                                value={InstituteCourses}
                                Image={Course.CourseThumbnail}
                            />

                            <CourseInfo
                                value={Course}
                                Path={`/course/${InstituteCourses?.InstituteCourseId}`}
                            />

                            <RatingBtns
                                value={InstituteCourses}
                                Path={`/course/${InstituteCourses?.InstituteCourseId}`}
                            />
                        </Link>
                    );
                });
               
               {/*  return Course?.InstituteCourses?.map((InstituteCourses) => {
                 
                    return <Link
                        to={`/course/${InstituteCourses?.InstituteCourseId}`}
                        className={`box-shadow-course-card relative flex w-1/5 min-w-[252px]
                    max-w-[275px] flex-col  items-center  rounded-[20px] bg-white
                      px-2 pb-1 text-black

                    no-underline                        duration-300             hover:bg-[#BCBEFF]             md:pb-2
                    lg:max-w-[300px]            lg:px-3     lg:pb-3                                 xl:max-w-[325px]
                    xl:px-4               2xl:max-w-[360px]    2xl:px-5    2xl:pb-4 
            `}
                        ref={(e) => {
                            setref(e);
                        }}
                        key={Number(index)}
                    >
                        <ImageAndPrice
                            value={InstituteCourses}
                            Image={Course.CourseThumbnail}
                        />

                        <CourseInfo
                            value={Course}
                            Path={`/course/${InstituteCourses?.InstituteCourseId}`}
                        />

                        <RatingBtns
                            value={InstituteCourses}
                            Path={`/course/${InstituteCourses?.InstituteCourseId}`}
                        />
                    </Link>
                   
                }); */}
            })}
        </>
    );
}

export default CourseTiles;
