import { useEffect, useState } from "react";
import CircularProgress from "./Components/CircularProgress";
import "./CoursesProgresReport.css";
function CoursesProgresReport({
    ClassType,
    TotalClasses,
    ClassesTaken,
    Index,
    Color,
}) {
    const [PercentageCompletion, setPercentageCompletion] = useState();

    useEffect(() => {
        setPercentageCompletion(
            Math.floor((ClassesTaken / TotalClasses) * 100)
        );
    }, [TotalClasses, ClassesTaken]);

    // console.log("TotalClasses", TotalClasses);
    // console.log("ClassesTaken", ClassesTaken);
    // console.log("TotalClasses", TotalClasses);
    // console.log("TotalClasses", TotalClasses);

    return (
        <div
            className={`flex w-full flex-col   items-center justify-between gap-8 rounded-3xl border-[1px] border-solid border-[#CACED5] bg-white px-6 py-10 shadow-[4px_5px_6px_#00000009]
    md:flex-row    md:gap-3    md:px-12
    lg:px-16
    `}
        >
            <div className="flex w-11/12 flex-col  gap-7 md:w-3/4 md:gap-20  lg:w-4/5">
                <div className="flex flex-col gap-4">
                    <h2 className="Boldfont text-center text-[30px] md:text-left md:text-xs lg:text-sm xl:text-base 2xl:text-[30px]">
                        {ClassType}
                    </h2>
                    <p className="text-center text-xs md:text-left md:text-[19px] lg:text-2xs xl:text-[21px] 2xl:text-xs">
                        This Portion shows the overall Content which you have
                        completed. This Portion shows the overall.
                    </p>
                </div>

                <Classes
                    TotalClasses={TotalClasses}
                    ClassesTaken={ClassesTaken}
                />
            </div>

            <CircularProgress
                Percentage={PercentageCompletion}
                Index={Index}
                Color={Color}
            />
        </div>
    );
}
export default CoursesProgresReport;

function Classes({ TotalClasses, ClassesTaken }) {
    return (
        <div className="flex justify-center gap-5 md:justify-start">
            <span className="flex flex-col">
                <span className="text-xs">Total Classes</span>
                <span className="whitespace-nowrap text-xs">
                    Completed Classes
                </span>
            </span>
            <span className="flex flex-col">
                <span className="text-xs">
                    {TotalClasses ? TotalClasses : 0}
                </span>
                <span className="text-xs">
                    {ClassesTaken ? ClassesTaken : 0}
                </span>
            </span>
        </div>
    );
}
