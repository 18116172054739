import React from "react";
import Left from "./Left/Left";
import RightCards from "./RightCards/RightCards";
import { GetLocalStorage } from "../../../../../Helpers/LocalStorage/LocalStorage";
import { BaseUrl } from "../../../../../Actions/Base";

const DashboardTop = ({
  studentCount,
  classTypeCounts,
  teacherClassTypes,
  data,
}) => {
 
  //local storage
    const localStorage = GetLocalStorage('UserInfo');
    const Instructor = localStorage?.InstituteUserType === "Instructor";
    const InstituteName = localStorage?.Institute?.InstituteName;

  return (
    <>
      {Instructor ? <>
                <div className="intitute_logo_section flex gap-4 py-4 justify-center md:justify-start">
                    <div className="h-20 w-20 object-cover Logo_image border-2 border-solid border-violet-100 rounded-full">
                       <img className="w-full rounded-full h-full" src={`${BaseUrl}/api/Images/InstituteLogo?url=${localStorage?.Institute?.InstituteLogo}`} alt="InstituteLogo" />
                    </div>
                 <h2
                        style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                        className=" heading_1 text-center content-center md:text-start font-bold text-[#4C34AD] pb-4"
                    >
                        {InstituteName}
                    </h2>
                    </div>
                    </> : null}

      <div className="flex w-full flex-col gap-5 xl:flex-row  rounded-[15px]lg:px-[2rem]">
                <div className="h-fit min-w-[300px] self-center  shadow w-[332px] md:w-full lg:max-w-[100%] gap-5 rounded-[15px] border-[1px] bg-white border-solid border-[#D4D4D4] px-[26px] py-[20px] ">
          <>
            <h2
              style={{
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
              className="heading_2 text-center font-semibold text-[#4C34AD] md:truncate md:text-start md:hover:text-clip "
            >
              Select an attribute for more information
            </h2>
          </>

          <Left studentCount={studentCount} data={data} />
        </div>
        <RightCards classTypeCounts={classTypeCounts} data={data} />
      </div>
    </>
  );
};

export default DashboardTop;

// import { Link } from "react-router-dom";
// import TeacherCalender from "../Calender/DashboardCalender";
// import { GetLocalStorage } from "../../../../../Helpers/LocalStorage/LocalStorage";
// const DashboardTop = ({ studentCount }) => {
//     const InstrucId = GetLocalStorage("UserInfo")?.Instructor?.InstructorId;
//     return (
//         <div className="flex h-fit w-[95%] gap-4 bg-[#F7F7F7]">
//             <div className="flex w-4/5 justify-between gap-10 rounded-3xl bg-white py-16 px-11  shadow-[3px_4px_6px_#00000029]">
//                 <Link
//                     to="/instructorr/studentsListing"
//                     className="TeacherCards StudentNumberCardBg no-underline"
//                 >
//                     <img
//                         className="w-28"
//                         src={require("./Assets/Students.svg").default}
//                         alt=""
//                     />
//                     <div className="flex flex-col items-center gap-5">
//                         <h4 className="Boldfont text-center text-4xs text-white">
//                             Students
//                         </h4>
//                         <p className="text-[38px] font-normal text-white">
//                             {studentCount}
//                         </p>
//                     </div>

//                     <p className="max-w-[200px] text-center text-4xs text-white">
//                         You will see the current enrolled students list here
//                     </p>
//                 </Link>

//                 <Link
//                     to={`/teacher/UpcomingClasses/${InstrucId}`}
//                     className="TeacherCards WeekCardBg no-underline"
//                 >
//                     <img
//                         className="w-[70px]"
//                         src={require("./Assets/Calender.svg").default}
//                         alt=""
//                     />
//                     <div className=" flex flex-col items-center gap-5 decoration-0">
//                         <h4 className="Boldfont text-center text-4xs text-white">
//                             Weekly Schedule
//                         </h4>
//                     </div>

//                     <p className="max-w-[200px] text-center text-4xs text-white">
//                         You can see the Upcoming weekly classes schedule here
//                     </p>
//                 </Link>

//                 <div className="TeacherCards TimeCardBg">
//                     <img
//                         className="w-[70px]"
//                         src={require("./Assets/Clock.svg").default}
//                         alt=""
//                     />

//                     <h4 className="Boldfont text-center text-4xs text-white">
//                         Available Hours
//                     </h4>

//                     <p className="max-w-[200px] text-center text-4xs text-white">
//                         You can add a leave or change your available hours from
//                         here
//                     </p>
//                 </div>

//                 <div className="TeacherCards CompleteCourseCardBg">
//                     <img
//                         className="w-[70px]"
//                         src={require("./Assets/Complete Course.svg").default}
//                         alt=""
//                     />
//                     <div className="flex flex-col items-center gap-5">
//                         <h4 className="Boldfont text-center text-4xs text-white">
//                             Completed Courses
//                         </h4>
//                         <p className="text-[38px] font-normal text-white">2</p>
//                     </div>

//                     <p className="max-w-[200px] text-center text-4xs text-white">
//                         You can view the completed course and students review
//                         from here
//                     </p>
//                 </div>
//             </div>

//             <div className="calenderClassTecherDashDiv flex w-1/5 items-center justify-center bg-white">
//                 <TeacherCalender />
//             </div>
//         </div>
//     );
// };

// export default DashboardTop;
