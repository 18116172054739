
// export const CheckSchedule = (EnrollmentData, isClassHours, Err, setErr, DayName, Attempted) => {
//     let Errors = {}
//     let ScheduleData = isClassHours ? classHoursData.DayandHoursData.Schedule : EnrollmentData.StudentData.Schedule;
//     for (const [key, value] of Object.entries(EnrollmentData.StudentData.Schedule)) {
//         if (value === undefined) {

//             if (Err) {
//                 Errors = { ...Errors, ScheduleDays: { ...Errors.ScheduleDays, [key]: true } }
//             } else if(!Err) {
//                 Errors = { ...Errors, ScheduleDays: { ...Errors.ScheduleDays, [key]: false } }
//             }
//             Errors = { ...Errors, Schedule: "Complete Schedule is required." }
//         }
//     }

//     if (Err) {
//         if (DayName)
//             Errors = { ...Errors, ScheduleDays: { ...Errors.ScheduleDays, [DayName]: false } }

//         if (!DayName || Attempted)
//             setErr({ ...Err, ...Errors })

//     }

//     return Errors
// }

export const CheckSchedule = (Data, Err, setErr, DayName, Attempted, isClassHours) => {
    let Errors = {};
    let ScheduleData = isClassHours ? Data.DayandHoursData.Schedule : Data.StudentData.Schedule;

    for (const [key, value] of Object.entries(ScheduleData)) {
        if (value === undefined || value === "") {
            Errors = { 
                ...Errors, 
                ScheduleDays: { ...Errors.ScheduleDays, [key]: true }, 
                Schedule: "Complete Schedule is required." 
            };
        }
    }

    if (Err) {
        if (DayName) {
            Errors = { ...Errors, ScheduleDays: { ...Errors.ScheduleDays, [DayName]: false } };
        }
        if (!DayName || Attempted) {
            setErr({ ...Err, ...Errors });
        }
    }

    return Errors;
};



