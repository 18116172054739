import axios from "axios";
import { BaseUrl } from "./Base";
import { CheckLoginServer } from "../Helpers/CheckLogin";

const axiosInstance = axios.create({
    baseURL: BaseUrl,
    withCredentials: true,
});

export const CreateInstituteStaffsA = (formData) => async (dispatch) => {
    try {
        dispatch({
            type: "CreateStaffsRequest",
        });

        const { data } = await axiosInstance.post(`/api/staff/add`, formData);

        dispatch({
            type: "CreateStaffsSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "CreateStaffsFailure",
            payload: error,
        });
    }
};

export const GetInstituteStaffsA = () => async (dispatch) => {
    try {
        dispatch({
            type: "GetStaffsRequest",
        });

        const { data } = await axiosInstance.get(`/api/Staffs`);

        dispatch({
            type: "GetStaffsSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetStaffsFailure",
            payload: {
                message: error.message, // Store only the message
                status: error.response?.status || 500, // Store the status code or default to 500
            },
        });
    }
};

export const GetUpdateInstituteStaffsA = (formData) => async (dispatch) => {
    try {
        dispatch({
            type: "UpdateStaffsRequest",
        });

        const { data } = await axiosInstance.put(`/api/staff/update`, formData);

        dispatch({
            type: "UpdateStaffsSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "UpdateStaffsFailure",
            payload: error,
        });
    }
};

export const GetSingleInstituteStaffMemberA = (StaffId) => async (dispatch) => {
    try {
        dispatch({
            type: "GetSingleStaffRequest",
        });

        const { data } = await axiosInstance.get(`/api/Staff/${StaffId}`);
        console.log("singlestaffaction", data);
        dispatch({
            type: "GetSingleStaffSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetSingleStaffFailure",
            payload: error,
        });
    }
};

export const DeleteStaffA = (StaffId) => async (dispatch) => {
    try {
        dispatch({
            type: "DeleteStaffRequest",
        });

        const { data } = await axiosInstance.delete(
            `/api/staff/delete/${StaffId}`
        );

        dispatch({
            type: "DeleteStaffSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "DeleteStaffFailure",
            payload: error,
        });
    }
};
