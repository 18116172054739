import React, { useEffect, useState } from "react";
import InstituteTemplate from "../../../Components/InstituteTemplate/InstituteTemplate";
import ImagePreviewer from "../../../Components/ImagePreviewer/ImagePreviewer";
import { Input, SelectList } from "../../../Components/InputComps/InputComps";
import { GenderOptions, RoleOptions } from "./OptionsArr/OptionsArr";
import "./AddStaff.css";
import { DateInput } from "../../../Components/InputComps/DateComps";
import SearchLocationByInput from "../AddInstructor/Components/InstructorInfo/components/GooglePlacesApi/SearchLocationByInput";
import { CountryOptions } from "../AddInstructor/Components/OptionsArr/OptionsArr";

const StaffInfo = ({
    setSelectedCoordinates,
    Err,
    inputValues,
    setInputvalues,
    setErr,
    CityOptions,
    ProvinceOptions,
    OnChange,
    readOnly,
    updateOnly,
}) => {
    // console.log("staffInfor", inputValues);
    return (
        <div className="staff_form_page mb-2 flex w-full flex-col flex-wrap justify-between gap-5 gap-y-6 p-16  sm:w-full md:w-[95%] md:flex-row lg:w-[100%]">
            <div className="flex w-full flex-col items-center justify-center gap-5 sm:flex-row sm:gap-5 md:flex-row md:justify-between md:gap-10 lg:flex-row">
                <div className="w-full md:w-1/2">
                    <Input
                        Err={Err?.FirstName}
                        Label="First name *"
                        Placeholder="John"
                        Id="FirstName"
                        name="FirstName"
                        State={inputValues?.FirstName}
                        onChange={(e) => OnChange(e, "FirstName")}
                        readOnly={readOnly}
                        autoComplete="off" // Override default to "off"
                    />
                </div>

                <div className="w-full md:w-1/2">
                    <Input
                        Label="Last name *"
                        Placeholder="Smith"
                        Id="LastName"
                        name="LastName"
                        Err={Err?.LastName}
                        State={inputValues.LastName}
                        onChange={(e) => OnChange(e, "LastName")}
                        readOnly={readOnly}
                        autoComplete="off" // Override default to "off"
                    />
                </div>
            </div>
            {/* <div className="w-full [&>*]:max-w-full [&>div>span>input]:shadow-none"> */}
            <div className="flex w-full flex-col items-center justify-center gap-5 sm:flex-row sm:gap-5 md:flex-row md:justify-between md:gap-10 lg:flex-row">
                <div className="w-full md:w-1/2">
                    <SearchLocationByInput
                        InstructorData={inputValues}
                        setInstructorData={setInputvalues}
                        Err={Err?.Address}
                        setErr={setErr}
                        readOnly={readOnly}
                        updateOnly={updateOnly}
                        setSelectedCoordinates={setSelectedCoordinates}
                        Id="Address"
                    />
                </div>

                <div className="w-full md:w-1/2">
                    <SelectList
                        readOnly={readOnly}
                        Label="Country *"
                        Id="Country"
                        defaultValue=""
                        Text="Country"
                        Err={Err?.Country}
                        OptionsArr={CountryOptions}
                        State={inputValues?.Country}
                        onChange={(e) => OnChange(e, "Country")}
                    />
                </div>
            </div>
            <div className="flex w-full flex-col items-center justify-center gap-5 sm:flex-row sm:gap-5 md:flex-row md:justify-between md:gap-10 lg:flex-row">
                <div className="w-full md:w-1/2">
                    <SelectList
                        readOnly={readOnly}
                        Label="Province / Territory *"
                        Id="Province"
                        defaultValue=""
                        Text="Province / Territory"
                        Err={Err?.Province}
                        OptionsArr={ProvinceOptions}
                        State={inputValues?.Province}
                        onChange={(e) => OnChange(e, "Province")}
                    />
                </div>
                <div className="w-full md:w-1/2">
                    <Input
                        Label="Postal Code *"
                        Placeholder="A1A 1A1"
                        readOnly={readOnly}
                        Id="PostalCode"
                        Err={Err?.PostalCode}
                        State={inputValues.PostalCode}
                        onChange={(e) => OnChange(e, "PostalCode")}
                        autoComplete="off" // Override default to "off"
                    />
                </div>
            </div>
            <div className="flex w-full flex-col items-center justify-center gap-5 sm:flex-row sm:gap-5 md:flex-row md:justify-between md:gap-10 lg:flex-row">
                <div className="w-full md:w-1/2">
                    <SelectList
                        readOnly={readOnly}
                        Label="City *"
                        Id="City"
                        defaultValue=""
                        Text="City"
                        Err={Err?.City}
                        OptionsArr={CityOptions}
                        State={inputValues?.City}
                        onChange={(e) => OnChange(e, "City")}
                    />
                </div>
                <div className="w-full md:w-1/2">
                    <DateInput
                        staff={true}
                        readOnly={readOnly}
                        Err={Err?.DOB}
                        Label="Date of Birth (DD/MM/YYYY)"
                        Placeholder="(DD/MM/YYYY)"
                        Id="DOB"
                        State={inputValues.DOB}
                        Src={require("./Assets/Birthday.svg").default}
                        onChange={(e) => OnChange(e, "DOB")}
                    />
                </div>{" "}
            </div>
            <div className="flex w-full flex-col items-center justify-center gap-5 sm:flex-row sm:gap-5 md:flex-row md:justify-between md:gap-10 lg:flex-row">
                <div className="w-full md:w-1/2">
                    <Input
                        readOnly={readOnly}
                        Err={Err?.Email}
                        Label="Email *"
                        Placeholder="abc@gmail.com"
                        Id="Email"
                        State={inputValues.Email}
                        onChange={(e) => OnChange(e, "Email")}
                        autoComplete="off" // Override default to "off"
                    />
                </div>
                <div className="w-full md:w-1/2">
                    <Input
                        readOnly={readOnly}
                        Err={Err?.PhoneNumber}
                        Label="Phone Number *"
                        Placeholder="123-456-7890"
                        Id="PhoneNumber"
                        State={inputValues.PhoneNumber}
                        onChange={(e) => OnChange(e, "PhoneNumber")}
                        autoComplete="off" // Override default to "off"
                    />
                </div>
            </div>
            <div className="flex w-full flex-col items-center justify-center gap-5 sm:flex-row sm:gap-5 md:flex-row md:justify-between md:gap-10 lg:flex-row">
                <div className="w-full md:w-1/2">
                    <Input
                        readOnly={readOnly}
                        Label="Emergency Phone Number *"
                        Placeholder="123-456-7890"
                        Id="EmergencyPhoneNumber"
                        name="EmergencyPhoneNumber"
                        Err={Err?.EmergencyPhoneNumber}
                        State={inputValues.EmergencyPhoneNumber}
                        onChange={(e) => OnChange(e, "EmergencyPhoneNumber")}
                        autoComplete="off" // Override default to "off"
                    />
                </div>
                <div className="w-full md:w-1/2">
                    <SelectList
                        readOnly={readOnly}
                        Label="Gender *"
                        Id="Gender"
                        Text="Gender"
                        Err={Err?.Gender}
                        OptionsArr={GenderOptions}
                        State={inputValues.Gender}
                        onChange={(e) => OnChange(e, "Gender")}
                    />
                </div>
            </div>
            <div className="flex w-full flex-col items-center justify-center gap-5 sm:flex-row sm:gap-5 md:flex-row md:justify-between md:gap-10 lg:flex-row">
                <div className="w-full md:w-1/2">
                    <SelectList
                        readOnly={readOnly}
                        Label="Role *"
                        Id="Role"
                        defaultValue="Staff"
                        Text="Role"
                        Placeholder="Select Role"
                        Err={Err?.Role}
                        OptionsArr={RoleOptions}
                        State={inputValues.Role}
                        onChange={(e) => OnChange(e, "Role")}
                    />
                </div>
            </div>

            <div className="flex w-full flex-col items-center justify-center sm:flex-row md:flex-wrap">
                {" "}
               <p className="text-error relative top-4"> {Err?.ProfileImage ? Err?.ProfileImage : null}</p>
                <div className="mt-6 md:flex md:gap-16">
                    <p className="text-22 text-404040 font-segoe-ui font-normal">
                        Staff Profile
                    </p>
                    <ImagePreviewer
                        readOnly={readOnly}
                        Id="ProfileImage"
                        isStaffProfile={true}
                        OnChange={(e) => OnChange(e, "ProfileImage")}
                        ImgUrl={inputValues?.ProfileImage}
                        Err={Err?.ProfileImage}
                    />
                    
                </div>
            </div>
        </div>
    );
};

export default StaffInfo;
