import axios from "axios";
import { BaseUrl } from "../../Actions/Base";
import Store from "../../Store";
import { CheckLoginServer } from "../CheckLogin";
const axiosInstance = axios.create({
    baseURL: BaseUrl,
    withCredentials: true,
})
export default async function axiosRequest(Method, Url, body, cb) {

    let Res, Err
    try {
        if (Method === "get") {
            const { data } = await axiosInstance.get(Url, body);
            Res = data;
        }
        else if (Method === "post") {
            const { data } = await axiosInstance.post(Url, body);
            Res = data;
        }
        else if (Method === "put") {
            const { data } = await axiosInstance.put(Url, body);
            Res = data;
        }
        else if (Method === "delete") {
            const { data } = await axiosInstance.delete(Url, { data: body });
            Res = data;
        }

        if (cb)
            cb()

        return [Res, Err]

    } catch (error) {
        Store.dispatch(CheckLoginServer(error?.response?.data))
        Err = error
        return [Res, Err]
    }
}

