import React from 'react'
import "./InstructorCardTemplate.css"

const TabsSection = ({setActiveTab, activeTab, handleTabClick}) => {
  return (
    <div>
        <div className={` tabs-section flex justify-center gap-5 lg:gap-10 mt-5 md:mt-10`}>
            <p  onClick={()=> handleTabClick('client')} className={`${activeTab === 'client' ? 'active' : ''} font-semibold font-segoe-ui text-white decoration-0 no-underline cursor-pointer`}>About Client</p>
            <p onClick={()=> handleTabClick('instructors')}  className={`${activeTab === 'instructors' ? 'active' : ''} font-semibold font-segoe-ui text-white decoration-0 no-underline cursor-pointer`}>Assign Instructors</p>
            <p  onClick={()=> handleTabClick('timetable')}   className={`${activeTab === 'timetable' ? 'active' : ''} font-semibold font-segoe-ui text-white decoration-0 no-underline cursor-pointer`}>set Timetable</p>
        </div>
    </div>
  )
}

export default TabsSection
