import React, {useState, useEffect} from "react";
import { NavLink } from "react-router-dom";
import { BaseUrl } from "../../Actions/Base";
import { UpdateStudentAboutInstructorLocation } from "../../Actions/InstructorA";
import { useDispatch, useSelector } from "react-redux";
import completedIcon from './Assets/CompletedClass.svg';
import TimeInfo from "./GoogleMap/TimeInfo";

const InVehicleClassSidebar = ({StudentFirstName, StudentLastName, StudentImage, showTopButtonNumber,
    setShowTopButtonNumber, StudentEmail, setLeftLocationCurrentTime,
    leftLocationCurrentTime, setReachedTPpickupZoneCurrentTime, reachedTPpickupZoneCurrentTime,
    setPickupStudentCurrentTime, pickupStudentCurrentTime, setCompletedInvehicleCurrentTime,
    completedInvehicleCurrentTime, setOpenCancelClassPopup, setOpenPopup, ClassStartTime,
    ClassEndTime, onStop,  paused, PickupService}) => {
    const Dispatch = useDispatch();
    const [currentDate, setCurrentDate] = useState('');

  useEffect(() => {
      const today = new Date();
      const formattedDate = today.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
      });
      setCurrentDate(formattedDate);
  }, []);

    const ImgUrl = `${BaseUrl}/api/images/Student?url=${StudentImage}`;

    const handleTopButtonClicks = () =>{
        // Check if it's the "Left (Location)" button click
        if (showTopButtonNumber === 0) {
            // Dispatch the action for "Left (Location)"
            if (StudentEmail) Dispatch(UpdateStudentAboutInstructorLocation(StudentEmail, StudentFirstName, StudentLastName));
        }
        setShowTopButtonNumber((prev) => prev + 1);
      }

     // Effect to update times and store them in local storage
  useEffect(() => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const currentTime = `${hours}:${minutes}`;

    if (showTopButtonNumber === 1 && !leftLocationCurrentTime) {
      setLeftLocationCurrentTime(currentTime);
      localStorage.setItem("leftLocationCurrentTime", currentTime);
    } else if (showTopButtonNumber === 2 && !reachedTPpickupZoneCurrentTime) {
      setReachedTPpickupZoneCurrentTime(currentTime);
      localStorage.setItem("reachedTPpickupZoneCurrentTime", currentTime);
    } else if (showTopButtonNumber === 3 && !pickupStudentCurrentTime) {
      setPickupStudentCurrentTime(currentTime);
      localStorage.setItem("pickupStudentCurrentTime", currentTime);
    } else if (showTopButtonNumber === 4 && !completedInvehicleCurrentTime) {
      setCompletedInvehicleCurrentTime(currentTime);
      localStorage.setItem("completedInvehicleCurrentTime", currentTime);
    }
  }, [showTopButtonNumber]);


      const handleCancelClassClick = () => {
        setOpenCancelClassPopup(true)
    }

    const handlePickupStudentClicks = () => {
        setOpenPopup(true);
      }
 

  return (
    <div
      className={`hidden lg:flex fixed left-0 top-0  z-[1499]  h-screen w-[180px] sm:w-[250px] overflow-y-scroll  flex-col pt-8 `}
      style={{
        background:
          "transparent linear-gradient(162deg, #AEB0FF 0%, #B05AC4 100%) 0% 0% no-repeat padding-box",
      }}
    >
    <div className="flex flex-col justify-between h-full">
        <div>
            <div className="w-full flex justify-center">
                <img
                className=" mb-[2rem] mt-[1rem] h-[42px] w-[90%]"
                src="/assets/institute-dashboard-logo.png"
                alt="logoo"
                />
            </div>

            <div className="w-full flex gap-[10px] items-center p-[15px]"
                style={{
                    borderBlock: "1px solid rgba(255, 255, 255, 0.3)",
                }}>
                <div  className="relative flex items-center justify-center overflow-hidden rounded-full text-center h-[50px] w-[50px]"
                        style={{border:'2px solid #FFFFFF'}}>
                            <img
                                className="absolute w-full"
                                src={ImgUrl}
                                alt={StudentFirstName + "img"}
                            /> 
                    </div>
                    <p className="text-[20px] text-[#FFFFFF]">{StudentFirstName} {StudentLastName}</p>
            </div>

            <div 
                className="p-[15px]"
                style={{
                borderBlock: "1px solid rgba(255, 255, 255, 0.3)",
                }}>
                             <span className="text-[#FFFFFF] text-[16px]">{currentDate}</span>
            </div>

            {showTopButtonNumber >= 1 && PickupService ==="Yes" && (
                <div 
                style={{
                borderBlock: "1px solid rgba(255, 255, 255, 0.3)",
                }}>
                        <div className="flex items-center justify-between p-[15px]">
                            <p className="text-[18px] text-[#FFFFFF]">Left ( Location )</p>
                            <p className="text-[18px] text-[#FFFFFF] font-bold">{leftLocationCurrentTime}</p>
                        </div>
                </div>
            )}

            {showTopButtonNumber >= 2 && PickupService ==="Yes" && (
                <div 
                style={{
                borderBlock: "1px solid rgba(255, 255, 255, 0.3)",
                }}>
                    <div className="flex items-center justify-between p-[15px]">
                        <p className="text-[18px] text-[#FFFFFF] w-[100px]">Reached To pickup Zone</p>
                        <p className="text-[18px] text-[#FFFFFF] font-bold">{reachedTPpickupZoneCurrentTime}</p>
                    </div>
                 </div>
            )}

            {showTopButtonNumber >= 3 && (
                <div 
                style={{
                borderBlock: "1px solid rgba(255, 255, 255, 0.3)",
                }}>
                    
                    <div className="flex items-center justify-between p-[15px]">
                        <p className="text-[18px] text-[#FFFFFF]">Picked Student</p>
                        <p className="text-[18px] text-[#FFFFFF] font-bold">{pickupStudentCurrentTime}</p>
                    </div>
                </div>
            )}

            {showTopButtonNumber >= 4 && (
                <div 
                style={{
                borderBlock: "1px solid rgba(255, 255, 255, 0.3)",
                }}>
                    <div className="flex items-center justify-between p-[15px]">
                        <p className="text-[18px] text-[#FFFFFF] w-[140px]">Completed In Vehicle Class</p>
                        <p className="text-[18px] text-[#FFFFFF] font-bold">{completedInvehicleCurrentTime}</p>
                    </div>
                 </div>
            )}

            {showTopButtonNumber >= 4 && (
            <div 
            style={{
               borderBlock: "1px solid rgba(255, 255, 255, 0.3)",
            }}>
                    <div className="flex items-center justify-between p-[15px]">
                        <p className="text-[18px] text-[#FFFFFF] w-[140px]">Class Marked as Completed</p>
                        <img src={completedIcon} alt="completedicon" className="w-[37px] h-[37px]" />
                    </div>
            </div>
            )}

            
                {(showTopButtonNumber === 0) && (
                    <div className="flex flex-col p-[15px]"
                        style={{
                        borderBlock: "1px solid rgba(255, 255, 255, 0.3)",
                        }}>
                        <button className="bg-[#FFFFFF] rounded-[7px] text-[#AF91EA] text-[18px] h-[40px] w-[145px]"
                            onClick={handleTopButtonClicks}
                        >
                                Left (Location)
                        </button>
                    </div>
                )}

                {(showTopButtonNumber === 1) && (
                    <div className="flex flex-col p-[15px]"
                        style={{
                        borderBlock: "1px solid rgba(255, 255, 255, 0.3)",
                        }}>
                            <button className="bg-[#FFFFFF] rounded-[7px] text-[#AF91EA] text-[18px] h-[40px] w-[145px]"
                                onClick={handleTopButtonClicks}
                            >
                                    At Pickup Zone
                            </button>
                    </div>
                )}

                {(showTopButtonNumber === 2) && (
                    <div className="flex flex-col p-[15px]"
                        style={{
                        borderBlock: "1px solid rgba(255, 255, 255, 0.3)",
                        }}>
                            <button className="bg-[#FFFFFF] rounded-[7px] text-[#AF91EA] text-[18px] h-[40px] w-[145px]"
                                onClick={handlePickupStudentClicks}
                            >
                                    Pick Student
                            </button>
                    </div>
                )}
        </div>

        {(showTopButtonNumber === 3) && (
                <div className="fixed top-[80px] right-[60px] flex flex-col justify-center items-center gap-[10px]">
                    

                    <TimeInfo
                    ClassStartTime={ClassStartTime}
                    ClassEndTime={ClassEndTime}
                    paused={paused} />

                    {/* <button className="bg-[#B061C9] rounded-[7px] text-[#FFFFFF] text-[18px] h-[35px] w-[125px]"
                        onClick={onStop}
                    >
                            End Class
                    </button> */}
                </div>
                )}

        <div>

            <div className="flex p-[15px]"
                style={{
                borderBlock: "1px solid rgba(255, 255, 255, 0.3)",
                }}>
                         {(showTopButtonNumber <= 2) && (
                        <button className="bg-[#FFFFFF] rounded-[7px] text-[#AF91EA] text-[18px] h-[40px] w-[145px]"
                            onClick={handleCancelClassClick}
                        >
                                Cancel Class
                        </button>
                         )}

                         {(showTopButtonNumber === 3) && (
                        <button className="bg-[#FFFFFF] rounded-[7px] text-[#AF91EA] text-[18px] h-[40px] w-[145px]"
                            onClick={onStop}
                        >
                                End Class
                        </button>
                         )}
            </div>
        </div>

    </div> 
    </div>
  );
};

export default InVehicleClassSidebar;

