import React, {useState} from "react";

const UserInput = ({
  AuthPageName,
  Credentials,
  setCredentials,
  Errors,
  setErrors,
  PasswordError
}) => {

  const [view, setView] = useState(true)
  const [cview, setCivew] = useState(true)
  let InputBoxStyle = `w-full rounded-[6px] outline-none bg-[#F3F6F7] text-gray-400
    text-4xs md:text-[16px]   lg:text-[16px]     xl:[16px]      2xl:text-[21px]
    py-[6px]                    xl:py-3
    px-[8px]                    xl:px-[22px]    2xl:px-7`;

  const borderr = {
    border: "1px solid #BCBCBC",
  };

  let labelStyle = `text-4xs md:text-[16px] text-white md:text-black   lg:text-[16px]     xl:[16px]      2xl:text-[21px]  pb-2`;

  const OnPasswordChange = (e) => {
    delete Errors?.Invalid;
    let Check = true;
    if (AuthPageName === "Sign Up") {
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/;
      Check = regex.test(e.target.value);
    }

    setCredentials({ ...Credentials, Password: e.target.value });

    if (e.target.value === "") setErrors({ ...Errors, Password: true });
    else if (!Check)
      setErrors({
        ...Errors,
        Password:
          "Password is must contain a capital letter, a small letter,a number and a special character with minimum length 8",
      });
    else {
      delete Errors?.Password;
      setErrors(Errors);
    }
  };


  const OnChange = (e, name) => {
    setCredentials({ ...Credentials, [name]: e.target.value });
    if (name === "Password" || name === "Email") delete Errors?.Invalid;

    if (e.target.value === "") setErrors({ ...Errors, [name]: true });
    else {
      delete Errors?.[name];
      setErrors({ ...Errors });
    }
  };
const mar = `${AuthPageName === 'Sign Up' ? 'mt-[3rem]' : ''}`
  return (
    <div className={`${mar} flex w-full flex-col gap-3`}>
      {AuthPageName !== "Sign Up" ? (
        <h4 className="font-normal text-[red]"> {Errors?.Invalid} </h4>
      ) : null}

      <Name
        AuthPageName={AuthPageName}
        Errors={Errors}
        InputBoxStyle={InputBoxStyle}
        Credentials={Credentials}
        setCredentials={setCredentials}
        OnChange={OnChange}
        label="Name"
      />

      <span className="flex flex-col">
        <label className={`${labelStyle}`} htmlFor="EmailAddress">
          Email Address
        </label>
        <h4 className="font-normal text-[red]">
          {" "}
          {AuthPageName === "Sign Up" ? Errors?.Email : null}{" "}
        </h4>

        <input
          id="EmailAddress"
          className={`${
            Errors?.Email === true
              ? "border-[1px]  border-solid border-[red]"
              : " border-[1px]  border-solid border-[#BCBCBC]"
          } ${InputBoxStyle} max-w-[650px]`}
          type="email"
          placeholder="Email Address"
          required
          value={Credentials?.Email}
          onChange={(e) => OnChange(e, "Email")}
        />
      </span>

      <span>
        <label className={`${labelStyle}`} htmlFor="Password">
          Password
        </label>
        <h4 className="font-normal text-[red]"> {Errors?.Password} </h4>

        <input
          id="Password"
          className={`${
            Errors?.Password === true
              ? "border-[1px]  border-solid border-[red]"
              : " border-[1px]  border-solid border-[#BCBCBC]"
          }  ${InputBoxStyle} mt-1 max-w-[650px]`}
          type={view ? `password` : 'text'}
          placeholder={!view ? `password` : "************"}
          required
          value={Credentials?.Password}
          onChange={OnPasswordChange}
        />
        <div className="w-full relative " style={{top: '-38px', position: 'relative'}}> 
          {
            view ?
            <span onClick={()=> setView(prev=> !prev)} className="material-symbols-outlined cursor-pointer absolute top-[5px] md:top-[5px] xl:top-0 right-[13px] text-[16px] text-gray-400" style={{fontSize:'30px'}} >visibility</span> 
            :
            <span onClick={()=> setView(prev=> !prev)} className="material-symbols-outlined cursor-pointer absolute top-[5px] md:top-[5px] xl:top-0 right-[13px] text-[16px] text-gray-400" style={{fontSize:'30px'}}>visibility_off</span>
          }
          </div>
      </span>
      {AuthPageName === "Sign Up" ? (
        <span className="flex flex-col">
          <label className={`${labelStyle}`} htmlFor="ConfirmPassword">
            Confirm Password
          </label>
          <h4 className="font-normal text-[red]">
            {AuthPageName === "Sign Up" ? Errors?.ConfirmPassword : null}
            {AuthPageName === "Sign Up" && PasswordError && PasswordError}
          </h4>

          <input
            id="ConfirmPassword"
            className={`${
              Errors?.ConfirmPassword === true
                ? "border-[1px]  border-solid border-[red]"
                : " border-[1px]  border-solid border-[#BCBCBC]"
            } ${InputBoxStyle} max-w-[650px]`}
            type={cview ? `password` : 'text'}
            placeholder={!cview ? `Confirm Password` : "************"}
            required
            value={Credentials.ConfirmPassword}
            onChange={(e) => OnChange(e, "ConfirmPassword")}
          />
          <div className="w-full relative " style={{top: '-38px', position: 'relative'}}> 
          {
            cview ?
            <span onClick={()=> setCivew(prev=> !prev)} className="material-symbols-outlined cursor-pointer absolute top-[5px] md:top-[5px] xl:top-0 right-[13px] text-[16px] text-gray-400" style={{fontSize:'30px'}} >visibility</span> 
            :
            <span onClick={()=> setCivew(prev=> !prev)} className="material-symbols-outlined cursor-pointer absolute top-[5px] md:top-[5px] xl:top-0 right-[13px] text-[16px] text-gray-400" style={{fontSize:'30px'}}>visibility_off</span>
          }
          </div>
          

        </span>
      ) : null}
    </div>
  );
};
function Name({
  AuthPageName,
  InputBoxStyle,
  Credentials,
  OnChange,
  Errors,
  label,
}) {
  return AuthPageName === "Sign Up" ? (
    <>
      <div className="absolute">
        <label
          className="relative text-white md:text-black top-[-1.75rem] left-0 text-[15px] font-normal "
          htmlFor="fName"
        >
          {label}
        </label>
      </div>
      <span className="flex w-full gap-2 xs:gap-3 sm:gap-2 md:gap-2 lg:gap-8 xl:gap-10 2xl:gap-12">
        <span className="flex basis-[50%] flex-col">
          <h4 className="font-normal text-[red]">
            {" "}
            {AuthPageName === "Sign Up" ? Errors?.FirstName : null}{" "}
          </h4>

          <input
            id="fName"
            className={`${
              Errors?.FirstName === true
                ? "border-[1px]  border-solid border-[red]"
                : " border-[1px]  border-solid border-[#BCBCBC]"
            } ${InputBoxStyle}  `}
            type="text"
            placeholder="First Name"
            required
            value={Credentials.FirstName}
            onChange={(e) => OnChange(e, "FirstName")}
          />
        </span>

        <span className="basis-[50%]">
          <h4 className="font-normal text-[red]">
            {" "}
            {AuthPageName === "Sign Up" ? Errors?.LastName : null}{" "}
          </h4>

          <input
            className={`${
              Errors?.LastName === true
                ? "border-[1px]  border-solid border-[red]"
                : " border-[1px]  border-solid border-[#BCBCBC]"
            } ${InputBoxStyle}  basis-[50%] md:text-[16px]`}
            type="text"
            placeholder="Last Name"
            required
            value={Credentials.LastName}
            onChange={(e) => OnChange(e, "LastName")}
          />
        </span>
      </span>
    </>
  ) : null;
}
export default UserInput;
