import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./CourseEnrollmentPopup.css";
import { BaseUrl } from "../../Actions/Base";
import { useDispatch, useSelector } from "react-redux";
const CourseEnrollmentPopup = ({ popupDetail, onCancel, onConfirm, type }) => {
    const [profile, setProfile] = useState();

    const Dispatch = useDispatch();
    const { SEnrolledCourse } = useSelector((Store) => Store.CourseReducer);
    const { Authenticated, UserInfo } = useSelector(
        (Store) => Store.LoginSignupReducer
    );

    // useEffect(() => {
    //   if (EnrollmentId && Authenticated && UserInfo.User === "Student")
    //     Dispatch(GetSingleEnrolledCourse(EnrollmentId))
    // }, [Dispatch, EnrollmentId, Authenticated, UserInfo])
    // useCheckLogin(true, ["Student", "Institute"], ["Admin", "Staff"])

    useEffect(() => {
        const ImageUrl =
            type === "Staff"
                ? `${BaseUrl}/api/images/Staff?url=${popupDetail?.Profile}`
                : `${BaseUrl}/api/images/Instructors?url=${popupDetail?.Profile}`;
        setProfile(ImageUrl);
    }, [popupDetail]);
    console.log("profile", profile);
    const Navigate = useNavigate();
    // const OnClick = () => Navigate("/instructors/list");

    return (
        <div className="fixed left-0 top-0 z-[100] flex h-screen w-screen items-center justify-center bg-[#00000029]">
            <div className="relative flex aspect-[353_/_221] h-fit w-full max-w-[360px] flex-col gap-[10px] rounded-[20px] bg-white px-8 py-5">
                <div className="flex flex-col items-center gap-3">
                    <h3 className="HeadingBorder text-5xs font-normal">
                        {popupDetail && popupDetail?.Heading
                            ? popupDetail?.Heading
                            : "Congratulations !!"}
                    </h3>
                    <h3 className=" text-4xs font-normal "></h3>
                </div>
                <div className="flex flex-col items-center gap-[30px]">
                    {/* <div className="h-20 w-20">
                        {profile && (
                            <img
                                className="h-20 w-20 "
                                src={profile}
                                alt="profile-img"
                            />
                        )}
                    </div> */}
                    <h3 className=" Semibold text-4xs ">
                        Hi,{" "}
                        {UserInfo &&
                            UserInfo.FirstName &&
                            UserInfo.LastName &&
                            `${UserInfo.FirstName} ${UserInfo.LastName}`}
                        {/* {popupDetail?.InstructorName || popupDetail?.StaffName} */}
                    </h3>

                    <h3 className=" Semibold text-4xs ">
                        You are enrolled successfully
                        {/* {popupDetail?.InstructorName || popupDetail?.StaffName} */}
                    </h3>
                    {/* )} */}
                    <div className="flex items-center justify-center gap-3">
                        {/* <button
                            type="button"
                            className="BrandingButton SemiBold w-fit cursor-pointer rounded-[14px] py-1 px-8 text-6xs"
                            onClick={onCancel}
                        >
                            Cancel
                        </button> */}
                        <button
                            type="button"
                            className="SemiBold w-fit cursor-pointer cursor-pointer  rounded-[14px] bg-[#4C34AD] py-1 px-8 text-6xs text-white"
                            onClick={onConfirm}
                        >
                            OK
                        </button>
                    </div>
                </div>
                <img
                    className="absolute right-3 top-[6px] cursor-pointer"
                    src={require("./Assets/Cross.svg").default}
                    alt=""
                    onClick={onCancel}
                />
            </div>
        </div>
    );
};

export default CourseEnrollmentPopup;
