// react States
import React, { useEffect, useState } from "react";

// Images
import addCOursePricingPlus from "../Assets/addCOursePricingPlus.png";
import removeCourse from "../Assets/minus.png";

// css
import "../courseDetails.css";

const SyllabusDescription = ({
  CourseDetailsData,
  setCourseDetailsData,
  CourseDetailsErr,
  setCourseDetailsErr,
  update,
}) => {
  const [SyllabusErr, setSyllabusErr] = useState([]);

  const [CourseSyllabus, setCourseSyllabus] = useState({
    CourseDescription: "",
  });
  console.log("CourseDetailsData", CourseDetailsData);
  const OnChange = (e, Name) => {
    let data = e.target.value;
    console.log("data", data, "name", Name);
    setCourseSyllabus({ ...CourseSyllabus, [Name]: data });
  };
  const AddSyllabus = () => {
    if (CourseSyllabus.CourseDescription.trim() === "") {
      return;
    }

    const updatedCourseSyllabus = [
      ...CourseDetailsData.CourseSyllabus,
      CourseSyllabus,
    ];
    const updatedCourseDetailsData = {
      ...CourseDetailsData,
      CourseSyllabus: updatedCourseSyllabus,
    };
    setCourseDetailsData(updatedCourseDetailsData);

    // Clear the input field after adding the syllabus description
    setCourseSyllabus({ CourseDescription: "" });
  };

  const RemoveSyllabus = (indexToRemove) => {
    const updatedCourseSyllabus = CourseDetailsData.CourseSyllabus.filter(
      (_, index) => index !== indexToRemove
    );
    const updatedCourseDetailsData = {
      ...CourseDetailsData,
      CourseSyllabus: updatedCourseSyllabus,
    };
    setCourseDetailsData(updatedCourseDetailsData);
  };

  return (
    <div className="packageTable">
      <h2 className="font-semibold text-[#4C34AD]">Syllabus</h2>

      <table
        className="addCourse4Table"
        style={{
          background:
            "transparent linear-gradient(114deg, #ffffff 0%, #f6f6ff 14%, #d6d8ff 100%) 0% 0%no-repeat padding-box",
        }}
      >
        <thead>
          <tr className="">
            <th className="w-[10%] py-3">S/no</th>
            <th className="w-[58%] py-3 ">Description</th>
            <th className="w-[8%] max-w-[150px] py-3">Actions</th>
          </tr>
        </thead>
        <tbody>
          {CourseDetailsData?.CourseSyllabus?.filter(
            (item) => item.CourseDescription.trim() !== ""
          ).map((value, index, arr) => {
            console.log("value", value);
            return (
              <tr key={index}>
                <td>
                  <div className="flex items-center justify-center">
                    {index + 1}
                  </div>
                </td>
                <td style={{ textAlign: "start" }}>
                  <div className="flex items-center justify-center">
                    {value?.CourseDescription}
                  </div>
                </td>

                <td>
                  <div
                    disabled={update}
                    onClick={() => {
                      if (!update) RemoveSyllabus(index);
                    }}
                    className="statusAddCourseTableBtn  text-center "
                  >
                    Delete
                  </div>
                </td>
              </tr>
            );
          })}
          {/* {CourseDetailsData.CourseSyllabus.map((item, index) => ( */}
          {!update && (
            <tr>
              <td>
                <input
                  type="text"
                  className="w-full border-[1px] border-solid border-[#00000000] px-2 py-[1px] text-center text-xs focus:border-black"
                  placeholder="#"
                  disabled={true}
                  // value={CourseSyllabus.CourseDescription}
                />
              </td>
              <td style={{ textAlign: "start" }}>
                <input
                  type="text"
                  className="w-full border-[1px] border-solid border-[#00000000] px-2 py-[1px] text-xs focus:border-black"
                  placeholder=""
                  value={CourseSyllabus.CourseDescription}
                  onChange={(e) => OnChange(e, "CourseDescription")}
                />
              </td>
              <td>
                <div
                  className="statusAddCourseTableBtn px-5 text-center"
                  onClick={AddSyllabus}
                >
                  Add
                </div>
              </td>
            </tr>
          )}

          {/* ))} */}
        </tbody>
      </table>
      {SyllabusErr.map((value) => (
        <h4 className="mb-1 font-normal text-[red]">{value.CourseSyllabus}</h4>
      ))}
    </div>
  );
};

export default SyllabusDescription;

// // react States
// import React, { useEffect, useState } from "react";

// // Images
// import addCOursePricingPlus from "../Assets/addCOursePricingPlus.png";
// import removeCourse from "../Assets/minus.png";

// // css
// import "../courseDetails.css";

// const SyllabusDescription = ({
//     CourseDetailsData,
//     setCourseDetailsData,
//     CourseDetailsErr,
//     setCourseDetailsErr,
// }) => {
//     const [SyllabusErr, setSyllabusErr] = useState([]);

//     const OnChange = (e, Index) => {
//         let NewCourseDes = [...CourseDetailsData?.CourseSyllabus];
//         NewCourseDes[Index].CourseDescription = e.target.value;

//         setCourseDetailsData({
//             ...CourseDetailsData,
//             CourseSyllabus: [...NewCourseDes],
//         });
//         if (e.target.value === "") {
//             let New;
//             const MappedErrs = SyllabusErr.map((value) => {
//                 if (value?.CourseSyllabus && value.Index === Index) New = true;
//                 return value;
//             });
//             if (New) setSyllabusErr(MappedErrs);
//             else {
//                 const Sorted = SyllabusErr.sort((a, b) => a.Index - b.Index);
//                 setSyllabusErr([
//                     ...Sorted,
//                     {
//                         Index,
//                         CourseSyllabus: `Syllabus Description in  point ${
//                             1 + Index
//                         } cannot be empty`,
//                     },
//                 ]);
//             }
//         } else if (e.target.value !== "") {
//             const RemovedErrs = SyllabusErr.filter(
//                 (ErrObj) => ErrObj.Index !== Index
//             );
//             const Sorted = RemovedErrs.sort((a, b) => a.Index - b.Index);
//             setSyllabusErr(Sorted);
//         }
//     };
//     const AddTopic = () => {
//         let NewTopic = { CourseDescription: "" };
//         setCourseDetailsData({
//             ...CourseDetailsData,
//             CourseSyllabus: [...CourseDetailsData?.CourseSyllabus, NewTopic],
//         });
//     };
//     const RemoveTopic = (INDEX) => {
//         const RemovedErrs = SyllabusErr.filter((ErrObj) => {
//             let CheckIndex = ErrObj.Index;
//             if (INDEX < ErrObj.Index) {
//                 ErrObj.Index = ErrObj.Index - 1;
//                 ErrObj.CourseSyllabus = `Syllabus Description in  point ${
//                     1 + ErrObj.Index
//                 } cannot be empty`;
//             }
//             return CheckIndex !== INDEX;
//         });
//         const Sorted = RemovedErrs.sort((a, b) => a.Index - b.Index);
//         setSyllabusErr(Sorted);
//         CourseDetailsData?.CourseSyllabus.splice(INDEX, 1);
//         setCourseDetailsData({
//             ...CourseDetailsData,
//             CourseSyllabus: [...CourseDetailsData?.CourseSyllabus],
//         });
//     };

//     useEffect(() => {
//         setCourseDetailsErr({
//             ...CourseDetailsErr,
//             SyllabusDescription: SyllabusErr,
//         });
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [SyllabusErr]);
//     console.log(CourseDetailsData?.CourseSyllabus);

//     return (
//         <div className="packageTable">
//             <h2 className="font-semibold text-[#4C34AD]">Syllabus</h2>

//             <table
//                 className="addCourse4Table"
//                 style={{
//                     background:
//                         "transparent linear-gradient(114deg, #ffffff 0%, #f6f6ff 14%, #d6d8ff 100%) 0% 0%no-repeat padding-box",
//                 }}
//             >
//                 <thead>
//                     <tr className="">
//                         <th className="w-[10%] py-3">S/no</th>
//                         <th className="w-[58%] py-3 ">Description</th>
//                         <th className="w-[12%] max-w-[150px] py-3">Actions</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {CourseDetailsData.length > 0
//                         ? CourseDetailsData?.CourseSyllabus?.map(
//                               (value, index, arr) => {
//                                   return (
//                                       <tr key={index}>
//                                           <td>
//                                               <div className="flex items-center justify-center">
//                                                   {index + 1}
//                                               </div>
//                                           </td>
//                                           <td style={{ textAlign: "start" }}>
//                                               <input
//                                                   type="text"
//                                                   className="w-full border-[1px] border-solid border-[#00000000] px-2 py-[1px] text-xs focus:border-black"
//                                                   placeholder=""
//                                                   value={value?.CourseSyllabus}
//                                                   onChange={(e) =>
//                                                       OnChange(e, index, value)
//                                                   }
//                                               />
//                                           </td>
//                                           {/* {index === arr.length - 1 ? ( */}
//                                           <td
//                                           // className="statusAddCourseTableBtn"
//                                           // onClick={AddTopic}
//                                           >
//                                               {/* <img
//                                                 src={addCOursePricingPlus}
//                                                 style={{ marginTop: "8px" }}
//                                                 alt="addCOursePricingPlus"
//                                             /> */}
//                                               <div
//                                                   className="statusAddCourseTableBtn px-5 text-center "
//                                                   onClick={AddTopic}
//                                               >
//                                                   Add
//                                               </div>
//                                           </td>
//                                           {/* ) : ( */}
//                                           <td
//                                           // className="statusAddCourseTableBtn"
//                                           // onClick={() => RemoveTopic(index)}
//                                           >
//                                               {/* <img
//                                                 src={removeCourse}
//                                                 style={{ marginTop: "4px" }}
//                                                 alt="addCOursePricingPlus"
//                                             /> */}
//                                               <div
//                                                   className="statusAddCourseTableBtn  text-center "
//                                                   onClick={() =>
//                                                       RemoveTopic(index)
//                                                   }
//                                               >
//                                                   Delete
//                                               </div>
//                                           </td>
//                                           {/* )} */}
//                                       </tr>
//                                   );
//                               }
//                           )
//                         : null}
//                     <tr>
//                         <td>
//                             <div className="flex items-center justify-center">
//                                 {index + 1}
//                             </div>
//                         </td>
//                         <td style={{ textAlign: "start" }}>
//                             <input
//                                 type="text"
//                                 className="w-full border-[1px] border-solid border-[#00000000] px-2 py-[1px] text-xs focus:border-black"
//                                 placeholder=""
//                                 value={value?.CourseSyllabus}
//                                 onChange={(e) => OnChange(e, index, value)}
//                             />
//                         </td>
//                         {/* {index === arr.length - 1 ? ( */}
//                         <td
//                         // className="statusAddCourseTableBtn"
//                         // onClick={AddTopic}
//                         >
//                             {/* <img
//                                                 src={addCOursePricingPlus}
//                                                 style={{ marginTop: "8px" }}
//                                                 alt="addCOursePricingPlus"
//                                             /> */}
//                             <div
//                                 className="statusAddCourseTableBtn px-5 text-center "
//                                 onClick={AddTopic}
//                             >
//                                 Add
//                             </div>
//                         </td>
//                         {/* ) : ( */}
//                         <td
//                         // className="statusAddCourseTableBtn"
//                         // onClick={() => RemoveTopic(index)}
//                         >
//                             {/* <img
//                                                 src={removeCourse}
//                                                 style={{ marginTop: "4px" }}
//                                                 alt="addCOursePricingPlus"
//                                             /> */}
//                             <div
//                                 className="statusAddCourseTableBtn  text-center "
//                                 onClick={() => RemoveTopic(index)}
//                             >
//                                 Delete
//                             </div>
//                         </td>
//                         {/* )} */}
//                     </tr>
//                 </tbody>
//             </table>
//             {SyllabusErr.map((value) => (
//                 <h4 className="mb-1 font-normal text-[red]">
//                     {value.CourseSyllabus}
//                 </h4>
//             ))}
//         </div>
//     );
// };

// export default SyllabusDescription;
