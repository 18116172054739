import React from "react";
import { useNavigate } from "react-router-dom";

function GetReadyKnowledge() {
    const Navigate = useNavigate();
    return (
        <div className="relative  ">
            <h2 className="SemiBoldItalic text-center md:text-[38px] lg:text-[38px]">
                Knowledge Test
            </h2>
            <div className="mx-auto mt-10 mb-10 flex h-fit w-[80%] flex-col items-center justify-between  lg:flex-row">
                <div className="flex flex-col gap-5">
                    <p className=" text-[24px]">
                        Explore our comprehensive resources to enhance your
                        understanding of specific license categories. Access all
                        the necessary driving knowledge for free and prepare
                        yourself for success on the road
                    </p>
                    <div className="flex w-full flex-col gap-5">
                        <div class="flex items-center justify-center gap-5">
                            {/* <div class="h-20 w-20 flex-shrink-0 rounded-full bg-[#A1A3EF]"></div> */}
                            <img
                                className=""
                                src={require("./Assets/Group 47725.png")}
                                alt=""
                            />
                            <div class=" flex w-full flex-col">
                                <h2 class="text-black">Preparation</h2>
                                <p className="text-[20px]">
                                    Knowledge tests aid in thorough preparation,
                                    familiarizing individuals with driving rules
                                    and regulations for increased confidence.
                                </p>
                            </div>
                        </div>
                        <div class="flex items-center justify-center gap-5">
                            <img
                                className=""
                                src={require("./Assets/Group 47726.png")}
                                alt=""
                            />
                            <div class=" flex w-full flex-col">
                                <h2 class="text-black">Understanding</h2>
                                <p className="text-[20px]">
                                    Structured knowledge tests deepen
                                    comprehension of driving principles,
                                    promoting responsible and informed driving
                                    behavior.
                                </p>
                            </div>
                        </div>
                        <div class="flex items-center justify-center gap-5">
                            <img
                                className=""
                                src={require("./Assets/Group 47727.png")}
                                alt=""
                            />
                            <div class=" flex w-full flex-col">
                                <h2 class="text-black">Safety</h2>
                                <p className="text-[20px]">
                                    Knowledge tests aid in thorough preparation,
                                    familiarizing individuals with driving rules
                                    and regulations for increased confidence.
                                </p>
                            </div>
                        </div>
                        <div class="flex items-center justify-center gap-5">
                            <img
                                className=""
                                src={require("./Assets/Group 47728.png")}
                                alt=""
                            />
                            <div class=" flex w-full flex-col">
                                <h2 class="text-black">Legal Compliance</h2>
                                <p className="text-[20px]">
                                    Knowledge tests aid in thorough preparation,
                                    familiarizing individuals with driving rules
                                    and regulations for increased confidence.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        onClick={() => Navigate("/get-ready/knowledge-test")}
                        class="inline-block w-fit cursor-pointer rounded-[11px] bg-[#A1A3EF] py-3 px-5 text-[22px] font-normal text-white shadow-lg transition duration-300 hover:shadow-xl"
                    >
                        Start Preparation
                    </div>
                </div>
                {/* <div className="  h-[700px] w-[1000px]"> */}
                <img
                    className="h-[300px] w-[300px]  xs:h-[300px] xs:w-[300px] sm:h-[400px] sm:w-[400px] lg:h-[631px] lg:w-[700px]"
                    src={require("./Assets/cars vehicle type-03.png")}
                    alt=""
                />
                {/* </div> */}
            </div>
        </div>
    );
}

export default GetReadyKnowledge;
