import axios from "axios";
import React, { useState } from "react";
import { BaseUrl } from "../../../../Actions/Base";
import { useEffect } from "react";
import { useContext } from "react";
import { SocketContext } from "../../../../App";
import { useDispatch, useSelector } from "react-redux";
import { calculateLastActiveTime } from "../../Helpers/utils";
import { GetInstituteStaffsA } from "../../../../Actions/StaffA";

function StaffContacts({
    setCurrentSelected,
    online,
    search,
    unreadMessages,
    handleReadMessage,
    lastseen,
}) {
    const [staffData, setStaffData] = useState(null);
    const [selectedContact, setSelectedContact] = useState(undefined);
    const { UserInfo } = useSelector((state) => state.LoginSignupReducer);
    const socket = useContext(SocketContext);
    const Dispatch = useDispatch();

    const { Staffs, loading } = useSelector((store) => store.StaffReducer);

    useEffect(() => {
        Dispatch(GetInstituteStaffsA());
    }, [Dispatch, socket]);
    // console.log("staffreducer", Staffs);
    const changeCurrentChat = (index, contact) => {
        setSelectedContact(index);
        setCurrentSelected(contact);
        if (unreadMessages[contact?.UserId] > 0) {
            // Call the ReadMessage function to handle read event
            handleReadMessage(contact?.UserId);
        }
    };

    const filteredStaff = Staffs?.filter((staff) =>
        staff?.FirstName?.toLowerCase().includes(search?.toLowerCase())
    );

    return (
        <div>
            {filteredStaff &&
                filteredStaff?.map((item, index) => {
                    const ImageUrl = `${BaseUrl}/api/images/Staff?url=${item?.Staff?.ProfileImage}`;
                    const unreadCount = unreadMessages[item?.UserId] || 0;

                    const date = new Date(item?.time);
                    const hours = date.getHours();
                    const minutes = date.getMinutes();
                    const formattedTime = `${
                        hours < 10 ? "0" + hours : hours
                    }:${minutes < 10 ? "0" + minutes : minutes}`;

                    console.log("lastSeentime", formattedTime);
                    const lastSeen =
                        lastseen &&
                        lastseen?.find((user) => user?.id === item?.UserId);

                    const lastActive = lastSeen ? lastSeen.time : null;
                    const lastActiveTimestamp = new Date(lastActive).getTime(); // Convert ISO timestamp to Unix timestamp (milliseconds)

                    const isOnline = online?.includes(item?.UserId);

                    // Skip rendering this item b/c it is the same person who is login
                    if (item?.UserId === UserInfo?.UserId) {
                        return null;
                    }
                    return (
                        <div
                            key={index}
                            onClick={() => changeCurrentChat(index, item)}
                            className={
                                selectedContact === index
                                    ? "  flex h-[106px]  cursor-pointer items-center justify-between  border   border-l-0 border-t-0 border-solid border-[#F1F1F3] bg-[#F5F6FA] p-2  "
                                    : "  flex h-[106px] cursor-pointer items-center justify-between   border   border-l-0 border-t-0 border-solid border-[#F1F1F3] p-2 hover:bg-[#F5F6FA]  "
                            }
                        >
                            <div className="ml-[15px] lg:ml-[40px] flex items-center  ">
                                <div className="relative ">
                                    {ImageUrl ? (
                                        <img
                                            className="rounded-full"
                                            src={ImageUrl}
                                            alt="img"
                                            width={60}
                                            height={60}
                                        />
                                    ) : (
                                        <img
                                            className="rounded-full"
                                            src={
                                                require("../../assets/AvatarIcon.svg")
                                                    .default
                                            }
                                            alt=""
                                            srcSet=""
                                            width={60}
                                            height={60}
                                        />
                                    )}
                                    {isOnline ? (
                                        <div className="absolute bottom-3 right-0 z-10 h-4 w-4 rounded-full bg-[#22c55e]"></div>
                                    ) : (
                                        <div className="absolute bottom-3 right-0 z-10 h-4 w-4 rounded-full bg-[#c56922]"></div>
                                    )}
                                </div>

                                <div className="ml-5 flex flex-col gap-3">
                                    <h4 className="  text-sm font-semibold  text-[#4D4F5C]">
                                        {item?.FirstName} {item?.LastName}
                                    </h4>
                                    <p className="  ">
                                        {!isOnline && lastActiveTimestamp
                                            ? calculateLastActiveTime(
                                                  lastActiveTimestamp
                                              )
                                            : ""}
                                    </p>
                                </div>
                            </div>
                            <div>
                                {/* {lastActive && (
                                    <p>
                                        Last Active:{" "}
                                        {calculateLastActiveTime(item.lastSeen)}
                                    </p>
                                )} */}
                                {/* <p>3 min ago</p> */}
                                {/* <p>{formattedTime}</p> */}
                                {unreadCount > 0 && (
                                    <div className="flex h-12 w-12 items-center justify-center rounded-full bg-[#22c55e] text-white">
                                        <p className="text-[25px] font-semibold">
                                            {unreadCount}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
        </div>
    );
}

export default StaffContacts;
