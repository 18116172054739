import { Country, State } from "country-state-city";
import React from "react";
import { useSelector } from "react-redux";

export const GenderOptions = () => {
    const Gender = [
        { value: "Male", Text: "Male" },
        { value: "Female", Text: "Female" },
        { value: "Nonbinary", Text: "Nonbinary" },
        // { value: "Others", Text: "Others" },

        // Prefer to self-describe
        // Prefer to self-describe
    ];
    return Gender?.map(({ value, Text }) => (
        <option value={value} key={value + Math.random()}>
            {Text}
        </option>
    ));
};

export const CountryOptions = () => {
    let countries = Country.getAllCountries();

    return countries?.map((country) => (
        <option value={country.name} key={country.isoCode}
        >
            {country?.name} {country?.flag}
        </option>
    ));
};


export const LicenseTypesOption = () => {
    const { LicenseTypes } = useSelector((Store) => Store.LicenseTypeReducer);
    return LicenseTypes?.map((value) => (
        <option value={value.LicenseTypeId} key={value.LicenseTypeId}>
            {value.LicenseTypeName}
        </option>
    ));
};
