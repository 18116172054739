import React from "react";

const ClassDetails = ({ ClassDetail }) => {
    return (
        <div className="flex flex-col gap-5 rounded-lg bg-[#A1A3EF] px-6 py-5">
            <span className="flex flex-col gap-4 md:gap-2">
                <h3 className="SemiBold text-3xs text-white sm:text-2xs md:text-xs lg:text-sm xl:text-base 2xl:text-[30px]">
                    Class {ClassDetail.ClassNumber}
                </h3>
                <p className="text-4xs text-white md:text-3xs  lg:text-2xs xl:text-xs">
                    {ClassDetail.ClassDescription}
                </p>
            </span>
            {/* <ClassDuration /> */}
        </div>
    );
};

function ClassDuration() {
    return (
        <span className="flex justify-end gap-2 md:gap-4 xl:gap-6">
            <span className="flex flex-col items-center">
                <h4 className="SemiBold  w-fit rounded-md bg-white px-2 py-[6px] text-3xs">
                    02
                </h4>
                <p className="SemiBold text-[12px] text-white">Days</p>
            </span>
            <span className="flex flex-col items-center">
                <h4 className="SemiBold  w-fit rounded-md bg-white px-2 py-[6px] text-3xs">
                    02
                </h4>
                <p className="SemiBold text-[12px] text-white">Hours</p>
            </span>
            <span className="flex flex-col items-center">
                <h4 className="SemiBold  w-fit rounded-md bg-white px-2 py-[6px] text-3xs">
                    02
                </h4>
                <p className="SemiBold text-[12px] text-white">Minutes</p>
            </span>
        </span>
    );
}

export default ClassDetails;
