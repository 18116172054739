/* import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

function TimeInfo({ ClassStartTime, ClassEndTime }) {
  const [seconds, setSeconds] = useState(0);
  const [classDuration, setClassDuration] = useState("");
  const [remainingTime, setRemainingTime] = useState(0); // Tracks remaining time
  const [showPopup, setShowPopup] = useState(false); // Controls popup visibility


  // Calculate class duration in HH:MM format based on ClassStartTime and ClassEndTime
 // Calculate class duration in hours, minutes, and seconds based on ClassStartTime and ClassEndTime
 useEffect(() => {
    const start = new Date(ClassStartTime);
    const end = new Date(ClassEndTime);
    const durationInSeconds = (end - start) / 1000;

    // Set the initial remaining time to the total duration in seconds
    setRemainingTime(durationInSeconds);

    // Convert duration from seconds to hours, minutes, and seconds
    // const hours = Math.floor(durationInSeconds / 3600);
    // const minutes = Math.floor((durationInSeconds % 3600) / 60);
    // const secs = durationInSeconds % 60;

    // // Format the duration string
    // const formattedDuration = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
    // setClassDuration(formattedDuration);
  }, [ClassStartTime, ClassEndTime]);

  // Countdown timer to decrease remaining time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime > 0) {
          const newTime = prevTime - 1;

          // Show the popup when 15 minutes (900 seconds) remain
          if (newTime === 900) {
            setShowPopup(true);
            // Hide the popup after 10 seconds
            setTimeout(() => setShowPopup(false), 10000);
          }

          return newTime;
        }
        return 0;
      });
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(timer);
  }, []);


  // Start the elapsed time counter
  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(timer);
  }, []);

  // Helper function to format elapsed time as HH:MM:SS
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  return (
    <div className="flex">
      

      {showPopup && (
        <div className="fixed top-[180px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[280px] bg-[#FFFFFF] py-[20px] px-[25px] rounded-[19px] shadow-lg z-50">
          <p className="text-[#464646] text-[14px] font-semibold text-center">Remainder</p>
          <p className="text-[#464646] text-[13px] text-center mt-[10px]">
          You have just 15 minutes left in this class! Please wrap up your activities 
          promptly within this time frame.
          </p>
        </div>
      )}

      <div className="flex flex-col justify-center items-center w-[54px] lg:w-[80px] h-[32px] lg:h-[45px] bg-[#AEB0FF] backdrop-blur-[10px] rounded-tl-[5px] rounded-bl-[5px]">
        <p className="text-[9px] lg:text-[14px] text-[#FFFFFF]">Remaining</p>
        <p className="text-[9px] lg:text-[14px] text-[#FFFFFF]">{formatTime(remainingTime)}</p>
      </div>

      <div className="flex flex-col justify-center items-center w-[54px] lg:w-[80px] h-[32px] lg:h-[45px] bg-[#6D6FFE] backdrop-blur-[10px] rounded-tr-[5px] rounded-br-[5px]">
            <p className="text-[9px] lg:text-[14px] text-[#FFFFFF]">Elapsed</p>
            <p className="text-[9px] lg:text-[14px] text-[#FFFFFF]">{formatTime(seconds)}</p>
      </div>
    </div>
  );
}

export default TimeInfo;
 */

import React, { useState, useEffect } from "react";

function TimeInfo({ ClassStartTime, ClassEndTime, paused }) {
  const [seconds, setSeconds] = useState(0); // Elapsed time
  const [remainingTime, setRemainingTime] = useState(0); // Remaining time
  const [showPopup, setShowPopup] = useState(false); // Controls popup visibility

  // Helper function to format time as HH:MM:SS
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  useEffect(() => {
    if (paused) return;
    
    const start = new Date(ClassStartTime);
    const end = new Date(ClassEndTime);
    const totalDurationInSeconds = (end - start) / 1000;

    // Check if elapsed and remaining times are stored in localStorage
    let storedElapsed = parseInt(localStorage.getItem("elapsedTime"), 10) || 0;
    let storedRemaining = parseInt(localStorage.getItem("remainingTime"), 10) || totalDurationInSeconds - storedElapsed;

    setSeconds(storedElapsed);
    setRemainingTime(storedRemaining);

    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        const newElapsed = prevSeconds + 1;
        localStorage.setItem("elapsedTime", newElapsed);
        return newElapsed;
      });

      setRemainingTime((prevRemainingTime) => {
        const newRemaining = prevRemainingTime - 1;
        localStorage.setItem("remainingTime", newRemaining);

        if (newRemaining === 900) { // Show popup when 15 minutes remain
          setShowPopup(true);
          setTimeout(() => setShowPopup(false), 10000);
        }

        return newRemaining > 0 ? newRemaining : 0;
      });
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  
  }, [ClassStartTime, ClassEndTime,paused ]);

  return (
    <div className="flex">
      {showPopup && (
        <div className="fixed top-[180px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[280px] bg-[#FFFFFF] py-[20px] px-[25px] rounded-[19px] shadow-lg z-50">
          <p className="text-[#464646] text-[14px] font-semibold text-center">Reminder</p>
          <p className="text-[#464646] text-[13px] text-center mt-[10px]">
            You have just 15 minutes left in this class! Please wrap up your activities 
            promptly within this time frame.
          </p>
        </div>
      )}

      <div className="flex flex-col justify-center items-center w-[54px] lg:w-[80px] h-[32px] lg:h-[45px] bg-[#AEB0FF] backdrop-blur-[10px] rounded-tl-[5px] rounded-bl-[5px]">
        <p className="text-[9px] lg:text-[14px] text-[#FFFFFF]">Remaining</p>
        <p className="text-[9px] lg:text-[14px] text-[#FFFFFF]">{formatTime(remainingTime)}</p>
      </div>

      <div className="flex flex-col justify-center items-center w-[54px] lg:w-[80px] h-[32px] lg:h-[45px] bg-[#6D6FFE] backdrop-blur-[10px] rounded-tr-[5px] rounded-br-[5px]">
        <p className="text-[9px] lg:text-[14px] text-[#FFFFFF]">Elapsed</p>
        <p className="text-[9px] lg:text-[14px] text-[#FFFFFF]">{formatTime(seconds)}</p>
      </div>
    </div>
  );
}

export default TimeInfo;
