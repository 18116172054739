import React, { useEffect } from 'react';
import PublicFeedback from './components/publicFeedback/PublicFeedback';
import CourseSection from './components/CourseSection/CourseSection';
import { useDispatch, useSelector } from 'react-redux';
import { GetFeedbackPageData } from '../../Actions/FeedbackA';


const Ratings = () => {
  const Dispatch = useDispatch()

  const {FeedbackPageData} = useSelector((Store)=> Store.FeedbackReducer)

console.log("FeedbackPageData", FeedbackPageData)
  const EnrollmentId = "c5a16f8d-9dd0-46b3-8daa-442a5a1dcda0";
  useEffect(()=> {
    Dispatch(GetFeedbackPageData(EnrollmentId))
  }, [Dispatch, EnrollmentId])
  
  return (
    <div className='w-full pt-32 px-[5%]  bg-white'>
      <div className='main-sec w-full flex flex-col lg:flex-row'>
          <div className='left-sec w-full lg:basis-[65%]'>
            <PublicFeedback FeedbackPageData={FeedbackPageData} />
          </div>
          <div className='right-sec w-full lg:basis-[35%] lg:mt-14'>
           <CourseSection FeedbackPageData={FeedbackPageData} />
          </div>
      </div>
    </div>
  )
}

export default Ratings;
