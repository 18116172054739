import React, { useEffect, useState } from "react";
import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import vehicleIcon from "../Assets/VehicleIconPNG.png"
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

// Helper function to create a custom icon canvas
const createCustomIcon = (color, rotation, isMirrored = false, isVerticallyFlipped = false) => {
  // const flipTransform = `${isMirrored ? 'scale(-1, 1)' : 'scale(1, 1)'} ${isVerticallyFlipped ? 'scale(1, -1)' : ''}`;

  // Define the flipping and rotation transformation
  const flipTransform = `${isMirrored ? 'scale(-1, 1)' : 'scale(1, 1)'} ${isVerticallyFlipped ? 'scale(1, -1)' : 'scale(1, 1)'}`;
  
  // Combine the transformations: first flip, then rotate
  const transform = `${flipTransform} rotate(${rotation}deg)`;

  //<svg xmlns="http://www.w3.org/2000/svg" width="110.224" height="62.645" viewBox="0 0 110.224 62.645" style="transform: ${flipTransform} rotate(${rotation}deg);">


  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="110.224" height="62.645" viewBox="0 0 110.224 62.645"  style="transform: ${transform}; transform-origin: center;">
  <g id="Group_47740" data-name="Group 47740" transform="translate(-10 -93.564)">
    <path id="Path_93725" data-name="Path 93725" d="M86.678,180.112l33.546-16.773L43.546,125,10,141.773Z" transform="translate(0 -23.904)" fill="#262626" opacity="0.3"/>
    <path id="Path_93726" data-name="Path 93726" d="M254.455,273.6l12.029-3.331-8.291-4.768L254,269.549Z" transform="translate(-185.533 -130.737)" fill="#2d3134"/>
    <path id="Path_93727" data-name="Path 93727" d="M254.455,273.6l12.029-3.331-8.291-4.768L254,269.549Z" transform="translate(-185.533 -130.737)" opacity="0.29"/>
    <path id="Path_93728" data-name="Path 93728" d="M62.979,176.223l12.029-3.355L66.693,168.1,62.5,172.15Z" transform="translate(-39.92 -56.676)" fill="#2d3134"/>
    <path id="Path_93729" data-name="Path 93729" d="M62.979,176.223l12.029-3.355L66.693,168.1,62.5,172.15Z" transform="translate(-39.92 -56.676)" opacity="0.29"/>
    <path id="Path_93730" data-name="Path 93730" d="M267.015,274.028a4.769,4.769,0,0,0-4.145-.407l-2.276,1.222a3.494,3.494,0,0,0-1.893,3.427c0,3.331,2.708,7.4,6.038,9.058a4.88,4.88,0,0,0,4.241.383s2.252-1.2,2.492-1.342a3.552,3.552,0,0,0,1.581-3.259c0-3.355-2.708-7.4-6.038-9.082Z" transform="translate(-189.107 -136.677)" fill="#2d3134"/>
    <path id="Path_93731" data-name="Path 93731" d="M301.9,305.546c.431-.216,2.2-1.174,2.42-1.318a3.552,3.552,0,0,0,1.581-3.259,8.947,8.947,0,0,0-1.174-4.169l-2.276,1.222a8.946,8.946,0,0,1,1.174,4.169A3.474,3.474,0,0,1,301.9,305.546Z" transform="translate(-221.956 -154.537)" opacity="0.29"/>
    <path id="Path_93732" data-name="Path 93732" d="M270.877,288.28c0,3.331-2.708,4.7-6.038,3.019s-6.038-5.727-6.038-9.057,2.708-4.7,6.038-3.019C268.169,280.876,270.877,284.949,270.877,288.28Z" transform="translate(-189.183 -140.626)" fill="#2d3134"/>
    <path id="Path_93733" data-name="Path 93733" d="M273.741,292.239c0,2.612-2.109,3.666-4.72,2.372s-4.72-4.481-4.72-7.093,2.109-3.666,4.72-2.372C271.608,286.465,273.741,289.627,273.741,292.239Z" transform="translate(-193.365 -145.256)" fill="#e5e5e5"/>
    <g id="Group_47736" data-name="Group 47736" transform="translate(71.414 139.881)">
      <path id="Path_93734" data-name="Path 93734" d="M292.186,315.8a2.49,2.49,0,0,0,1.15-2.324v-.264L290.7,312.8Z" transform="translate(-284.853 -306.585)" fill="#2d3134"/>
      <path id="Path_93735" data-name="Path 93735" d="M287.8,294.171l2.756.6a8.459,8.459,0,0,0-2.851-3.57Z" transform="translate(-282.572 -290.16)" fill="#2d3134"/>
      <path id="Path_93736" data-name="Path 93736" d="M271.156,289.89l.1-2.875a2.477,2.477,0,0,0-2.852.719Z" transform="translate(-267.897 -286.862)" fill="#2d3134"/>
      <path id="Path_93737" data-name="Path 93737" d="M266.3,296.7v.264a6.657,6.657,0,0,0,1.15,3.474l1.51-1.486Z" transform="translate(-266.3 -294.343)" fill="#2d3134"/>
      <path id="Path_93738" data-name="Path 93738" d="M278.829,314.2l-1.629,1.318a7.344,7.344,0,0,0,1.629,1.126,4.161,4.161,0,0,0,1.629.479Z" transform="translate(-274.588 -307.649)" fill="#2d3134"/>
    </g>
    <path id="Path_93739" data-name="Path 93739" d="M270.877,288.28c0,3.331-2.708,4.7-6.038,3.019s-6.038-5.727-6.038-9.057,2.708-4.7,6.038-3.019C268.169,280.876,270.877,284.949,270.877,288.28Z" transform="translate(-189.183 -140.626)" opacity="0.11"/>
    <path id="Path_93740" data-name="Path 93740" d="M74.915,177.328a4.769,4.769,0,0,0-4.145-.407l-2.276,1.222a3.494,3.494,0,0,0-1.893,3.427c0,3.331,2.708,7.4,6.038,9.058a4.88,4.88,0,0,0,4.241.383s2.252-1.2,2.492-1.342a3.552,3.552,0,0,0,1.581-3.259C80.953,183.055,78.245,179.005,74.915,177.328Z" transform="translate(-43.038 -63.148)" fill="#2d3134"/>
    <path id="Path_93741" data-name="Path 93741" d="M109.8,208.846c.431-.216,2.2-1.174,2.42-1.318a3.552,3.552,0,0,0,1.581-3.259,8.947,8.947,0,0,0-1.174-4.169l-2.276,1.222a8.947,8.947,0,0,1,1.174,4.169A3.54,3.54,0,0,1,109.8,208.846Z" transform="translate(-75.886 -81.008)" opacity="0.29"/>
    <path id="Path_93742" data-name="Path 93742" d="M78.677,191.48c0,3.331-2.708,4.7-6.038,3.019s-6.038-5.727-6.038-9.058,2.708-4.7,6.038-3.019C75.993,184.1,78.677,188.149,78.677,191.48Z" transform="translate(-43.038 -67.021)" fill="#2d3134"/>
    <path id="Path_93743" data-name="Path 93743" d="M81.541,195.539c0,2.612-2.109,3.666-4.72,2.372s-4.72-4.481-4.72-7.093,2.109-3.666,4.72-2.372S81.541,192.927,81.541,195.539Z" transform="translate(-47.22 -71.727)" fill="#e5e5e5"/>
    <g id="Group_47737" data-name="Group 47737" transform="translate(25.36 116.686)">
      <path id="Path_93744" data-name="Path 93744" d="M100.086,219a2.491,2.491,0,0,0,1.15-2.324v-.264L98.6,216Z" transform="translate(-92.729 -209.785)" fill="#2d3134"/>
      <path id="Path_93745" data-name="Path 93745" d="M95.6,197.371l2.756.6A8.459,8.459,0,0,0,95.5,194.4Z" transform="translate(-90.372 -193.36)" fill="#2d3134"/>
      <path id="Path_93746" data-name="Path 93746" d="M78.956,193.09l.1-2.875a2.477,2.477,0,0,0-2.851.719Z" transform="translate(-75.697 -190.061)" fill="#2d3134"/>
      <path id="Path_93747" data-name="Path 93747" d="M74.1,199.9v.264a6.657,6.657,0,0,0,1.15,3.474l1.51-1.486L74.1,199.9Z" transform="translate(-74.1 -197.542)" fill="#2d3134"/>
      <path id="Path_93748" data-name="Path 93748" d="M86.629,217.5,85,218.818a7.344,7.344,0,0,0,1.629,1.126,4.161,4.161,0,0,0,1.629.479Z" transform="translate(-82.388 -210.925)" fill="#2d3134"/>
    </g>
    <path id="Path_93749" data-name="Path 93749" d="M78.677,191.48c0,3.331-2.708,4.7-6.038,3.019s-6.038-5.727-6.038-9.058,2.708-4.7,6.038-3.019C75.993,184.1,78.677,188.149,78.677,191.48Z" transform="translate(-43.038 -67.021)" opacity="0.11"/>
    <path id="Path_93750" data-name="Path 93750" d="M140.514,122.422c-1.078-1.126-13.706-11.046-15.982-12.8-2.276-1.725-19.289-13.275-27.364-13.754s-9.7-.216-13.011-1.078-10.663-2.708-21.877.982S43.9,106.44,43.9,112.119a6.926,6.926,0,0,0,2.971,5.966,20.008,20.008,0,0,0,1.893,1.318,8.192,8.192,0,0,1-.1-1.078c0-2.54,1.486-4.169,3.786-4.169a6.557,6.557,0,0,1,2.9.767c3.474,1.725,6.278,5.966,6.278,9.441a5.1,5.1,0,0,1-.407,2.061l33.618,16.462a7.022,7.022,0,0,1-.144-1.39c0-2.54,1.486-4.169,3.786-4.169a6.557,6.557,0,0,1,2.9.767c3.474,1.725,6.278,5.966,6.278,9.441a6.6,6.6,0,0,1-.12,1.222,28.67,28.67,0,0,0,5.272,1.006c4.864.479,12.844-.982,19.984-4.768,8.147-4.313,11.334-8.458,11.693-10.615.407-2.157-2.9-10.831-3.978-11.957Z" transform="translate(-25.777 0)" fill="#fc0"/>
    <path id="Path_93751" data-name="Path 93751" d="M188.04,208.573c0,.695-.575.982-1.27.647a2.41,2.41,0,0,1-1.27-1.917c0-.695.575-.982,1.27-.647A2.41,2.41,0,0,1,188.04,208.573Z" transform="translate(-133.447 -85.887)" opacity="0.2"/>
    <path id="Path_93752" data-name="Path 93752" d="M183.931,208.785l.551-.623s-4-2.612-4.361-2.085C179.738,206.58,183.931,208.785,183.931,208.785Z" transform="translate(-129.338 -85.5)" fill="#fc0"/>
    <path id="Path_93753" data-name="Path 93753" d="M262.24,240.059c0,.695-.575.982-1.27.647a2.41,2.41,0,0,1-1.27-1.917c0-.719.575-.982,1.27-.647A2.446,2.446,0,0,1,262.24,240.059Z" transform="translate(-189.867 -109.825)" opacity="0.2"/>
    <path id="Path_93754" data-name="Path 93754" d="M258.14,240.385l.551-.623s-4-2.612-4.361-2.085C253.971,238.18,258.14,240.385,258.14,240.385Z" transform="translate(-185.768 -109.528)" fill="#fc0"/>
    <path id="Path_93755" data-name="Path 93755" d="M126.4,157.247l40.687,16.965s-3.954-14.377-17.061-19.673c-14.09-5.679-23.626,2.708-23.626,2.708Z" transform="translate(-88.508 -44.865)" fill="#2d3134"/>
    <path id="Path_93756" data-name="Path 93756" d="M145,161.02l14.545,6.062,5.056-8.554a7.322,7.322,0,0,0-.767-.312,25.054,25.054,0,0,0-9.417-1.917,22.2,22.2,0,0,0-7.955,1.486Z" transform="translate(-102.652 -47.704)" fill="#656565"/>
    <path id="Path_93757" data-name="Path 93757" d="M218.1,178.266l16.03,6.685.431-1.39a35.2,35.2,0,0,0-2.133-3.978,28.977,28.977,0,0,0-10.4-10.184Z" transform="translate(-158.236 -57.665)" fill="#656565"/>
    <path id="Path_93758" data-name="Path 93758" d="M154.539,103.944c.359-.575-11.046-.575-14.042-.192a37.486,37.486,0,0,0-12.508,3.978c-3.834,2.109-9.681,7.189-6.613,7.812s15.1-2.564,15.527-2.827a48.247,48.247,0,0,1,8.411-5.607C149.459,105.142,154.347,104.231,154.539,103.944Z" transform="translate(-84.035 -7.544)" fill="#2d3134"/>
    <path id="Path_93759" data-name="Path 93759" d="M159.372,105.468c.048-.024.12-.024.168-.048-1.1-.072-2.8-.12-5.008-.12-1.39,0-2.756.024-3.93.072a74.8,74.8,0,0,0-12.316,4.289c-3.714,1.773-7.236,3.93-8.986,6.9,4.1-.1,13.514-2.588,14.09-2.851.072-.048.335-.264.623-.479a40.811,40.811,0,0,1,7.836-5.128A47.914,47.914,0,0,1,159.372,105.468Z" transform="translate(-90.714 -8.924)" fill="#656565"/>
    <path id="Path_93760" data-name="Path 93760" d="M316.433,162.755c2.061.144,5.415,3.259,6.709,4.289,1.294,1.054,5.535,3.786,2.684,7.117s-7.524,5.415-11.837,6.542c-4.337,1.126-8.027,1.725-10.591-.431a21.376,21.376,0,0,1-4.649-6.422c-.431-.815-1.126-1.845,1.054-3.618,2.516-2.061,11.717-7.812,16.629-7.476Z" transform="translate(-219.213 -52.601)" fill="#2d3134"/>
    <path id="Path_93761" data-name="Path 93761" d="M319.163,164.465c1.965.12,5.152,3.091,6.4,4.1s5.272,3.618,2.54,6.781c-2.708,3.163-7.141,5.152-11.286,6.23-4.121,1.054-7.644,1.653-10.064-.407a20.648,20.648,0,0,1-4.433-6.11c-.407-.791-1.078-1.749,1.006-3.427C305.72,169.641,314.466,164.178,319.163,164.465Z" transform="translate(-221.942 -53.904)" fill="#656565"/>
    <path id="Path_93762" data-name="Path 93762" d="M329,172.778c-.312-1.941-2.564-3.427-3.427-4.121-1.246-1.006-4.433-3.954-6.4-4.1-4.673-.311-13.443,5.152-15.839,7.117-1.15.935-1.462,1.653-1.438,2.228A57.317,57.317,0,0,1,329,172.778Z" transform="translate(-221.952 -53.974)" opacity="0.2"/>
    <path id="Path_93763" data-name="Path 93763" d="M341.364,269.074a4.18,4.18,0,0,0,2.085.264c.911-.216,4.7-1.51,4.7-1.51s-1.581-5.559-3.331-7a4.411,4.411,0,0,0-4.121-.982C340.31,260.089,340.861,268.379,341.364,269.074Z" transform="translate(-251.356 -126.308)" fill="#cc291f"/>
    <path id="Path_93764" data-name="Path 93764" d="M442.363,221.292s2.971-1.653,2.971-2.348-1.294-5.966-2.229-7.069-1.725-1.725-2.085-1.51C440.638,210.557,442.339,220.957,442.363,221.292Z" transform="translate(-327.698 -88.782)" fill="#cc291f"/>
    <path id="Path_93765" data-name="Path 93765" d="M388.664,250.328c.359-.695,8.746-4.84,9.249-4.649s.719,3.714.12,4.5c-.623.791-6.637,4.145-7.812,3.93S388.352,250.951,388.664,250.328Z" transform="translate(-287.873 -115.661)" fill="#2d3134"/>
    <path id="Path_93766" data-name="Path 93766" d="M395.1,253.539l.647,3.283,6.182-3.187-.647-3.235Z" transform="translate(-292.823 -119.256)" fill="#e5e5e5"/>
    <path id="Path_93767" data-name="Path 93767" d="M372.2,265.01l.695,1.2a64.715,64.715,0,0,0,9.2-3.834,68.147,68.147,0,0,0,8.123-5.032L390.075,256a52.531,52.531,0,0,1-8.195,5.128,62.48,62.48,0,0,1-9.681,3.882Z" transform="translate(-275.41 -123.514)" fill="#2d3134"/>
    <path id="Path_93768" data-name="Path 93768" d="M65.4,158.1l14.617,3.355L120.7,178.42l10.615,7.38s-17.444-5.8-32.947-13.011S65.4,158.1,65.4,158.1Z" transform="translate(-42.125 -49.072)" fill="#fff" opacity="0.3"/>
    <path id="Path_93769" data-name="Path 93769" d="M158.5,188.035c-2.276,3.043-8.315,11-9.01,11.286-.192.024-1.653-.383-10.639-4.649a20.492,20.492,0,0,1-.407-7.308,14.365,14.365,0,0,1,2.181-6.781l-.168-.12a14.815,14.815,0,0,0-2.228,6.9,23.166,23.166,0,0,0,.359,7.189c-1.15-.551-2.42-1.15-3.834-1.821-7.165-3.451-14.113-6.9-14.353-7.069-.455-.311-1.8-3.067-1.629-7.165a12.892,12.892,0,0,1,1.749-6.278l-.168-.12a12.731,12.731,0,0,0-1.8,6.4c-.168,4.145,1.174,6.973,1.725,7.356.288.216,7.572,3.81,14.5,7.141,12.556,6.038,14.377,6.589,14.737,6.589.048,0,.072,0,.1-.024.887-.383,8.746-10.9,9.082-11.358Z" transform="translate(-82.532 -59.718)" opacity="0.2"/>
    <path id="Path_93770" data-name="Path 93770" d="M213.185,155.979c-2.2-5.08-16.27-16.629-26.837-14.928l-.048-.312c6.4-1.03,13.3,2.684,16.821,4.984,4.816,3.139,9.153,7.38,10.351,10.136Z" transform="translate(-134.055 -35.737)" fill="#2d3134"/>
    <path id="Path_93771" data-name="Path 93771" d="M288.386,119.089c-9.465-6.47-22.117-14.209-28.586-14.353V104.4c6.542.144,19.265,7.907,28.778,14.4Z" transform="translate(-189.943 -8.24)" fill="#2d3134"/>
    <path id="Path_93772" data-name="Path 93772" d="M135.07,169.428l.695-2.756s-1.222-.623-1.653-.288-1.366,3.067-.647,3.906.935.863.935.863Z" transform="translate(-93.68 -55.297)" fill="#2d3134"/>
    <path id="Path_93773" data-name="Path 93773" d="M380.006,255.612a7.8,7.8,0,0,0-.311-2.612,38.758,38.758,0,0,1-12.532,8.7,66.479,66.479,0,0,1-13.562,4.816c.12,1.2.072,3.211.12,4.361a43.034,43.034,0,0,0,14.569-4.625c8.147-4.337,11.334-8.458,11.717-10.639Z" transform="translate(-261.267 -121.233)" opacity="0.29"/>
    <path id="Path_93774" data-name="Path 93774" d="M52.159,141.424a6.83,6.83,0,0,1,.431-2.971c.551-.863.192-2.133-.455-1.989-.767.168-3.019,3.235-3.139,3.93C48.924,141.113,52.159,141.424,52.159,141.424Z" transform="translate(-29.651 -32.612)" fill="#e5e5e5"/>
    <path id="Path_93775" data-name="Path 93775" d="M56.657,140.666a2.02,2.02,0,0,1-1.078,1.629c-.6.312-1.078.048-1.078-.551a2.02,2.02,0,0,1,1.078-1.629C56.177,139.828,56.657,140.067,56.657,140.666Z" transform="translate(-33.837 -35.305)" opacity="0.2"/>
    <path id="Path_93776" data-name="Path 93776" d="M56.85,143.149a1.083,1.083,0,0,1-.575.863c-.311.168-.575.024-.575-.288a1.083,1.083,0,0,1,.575-.863C56.587,142.693,56.85,142.813,56.85,143.149Z" transform="translate(-34.749 -37.428)" fill="#fff" opacity="0.4"/>
    <path id="Path_93777" data-name="Path 93777" d="M57.15,149.5a1.083,1.083,0,0,1-.575.863c-.312.144-.575.024-.575-.288a1.083,1.083,0,0,1,.575-.863C56.887,149.065,57.15,149.184,57.15,149.5Z" transform="translate(-34.978 -42.265)" fill="#fff" opacity="0.4"/>
    <path id="Path_93778" data-name="Path 93778" d="M344.5,276.082l.264,1.51,1.222.144,3.618-1.222L349,275.1l-3.163,1.174Z" transform="translate(-254.348 -138.037)" fill="#fff" opacity="0.8"/>
    <path id="Path_93779" data-name="Path 93779" d="M445,229.31l2.2-1.51.455,1.27-2.324,1.8Z" transform="translate(-330.766 -102.071)" fill="#fff" opacity="0.8"/>
    <g id="Group_47738" data-name="Group 47738" transform="translate(41.15 119.068)">
      <path id="Path_93780" data-name="Path 93780" d="M140,205v2.4l2.4,1.2v-2.4Z" transform="translate(-140 -203.802)" fill="#2d3134"/>
      <path id="Path_93781" data-name="Path 93781" d="M152.4,201.2,150,200v2.4l2.4,1.2Z" transform="translate(-147.604 -200)" fill="#2d3134"/>
      <path id="Path_93782" data-name="Path 93782" d="M150,222.4l2.4,1.2v-2.4L150,220Z" transform="translate(-147.604 -215.208)" fill="#2d3134"/>
      <path id="Path_93783" data-name="Path 93783" d="M160,215v2.4l2.4,1.2v-2.4Z" transform="translate(-155.208 -211.406)" fill="#2d3134"/>
      <path id="Path_93784" data-name="Path 93784" d="M172.4,211.2,170,210v2.4l2.4,1.2Z" transform="translate(-162.811 -207.604)" fill="#2d3134"/>
      <path id="Path_93785" data-name="Path 93785" d="M170,232.4l2.4,1.2v-2.4L170,230Z" transform="translate(-162.811 -222.811)" fill="#2d3134"/>
      <path id="Path_93786" data-name="Path 93786" d="M180,225v2.4l2.4,1.2v-2.4Z" transform="translate(-170.415 -219.01)" fill="#2d3134"/>
      <path id="Path_93787" data-name="Path 93787" d="M192.4,221.2,190,220v2.4l2.4,1.2Z" transform="translate(-178.019 -215.208)" fill="#2d3134"/>
      <path id="Path_93788" data-name="Path 93788" d="M190,242.4l2.4,1.2v-2.4L190,240Z" transform="translate(-178.019 -230.415)" fill="#2d3134"/>
      <path id="Path_93789" data-name="Path 93789" d="M200,235v2.4l2.4,1.2v-2.4Z" transform="translate(-185.623 -226.613)" fill="#2d3134"/>
      <path id="Path_93790" data-name="Path 93790" d="M220,245v2.4l2.4,1.2v-2.4Z" transform="translate(-200.831 -234.217)" fill="#2d3134"/>
      <path id="Path_93791" data-name="Path 93791" d="M212.4,231.2,210,230v2.4l2.4,1.2Z" transform="translate(-193.227 -222.811)" fill="#2d3134"/>
      <path id="Path_93792" data-name="Path 93792" d="M210,252.4l2.4,1.2v-2.4L210,250Z" transform="translate(-193.227 -238.019)" fill="#2d3134"/>
      <path id="Path_93793" data-name="Path 93793" d="M240,255v2.4l2.4,1.2v-2.4Z" transform="translate(-216.038 -241.821)" fill="#2d3134"/>
      <path id="Path_93794" data-name="Path 93794" d="M232.4,241.2,230,240v2.4l2.4,1.2Z" transform="translate(-208.434 -230.415)" fill="#2d3134"/>
      <path id="Path_93795" data-name="Path 93795" d="M230,262.4l2.4,1.2v-2.4L230,260Z" transform="translate(-208.434 -245.623)" fill="#2d3134"/>
    </g>
    <g id="Group_47739" data-name="Group 47739" transform="translate(67.269 97.909)">
      <path id="Path_93796" data-name="Path 93796" d="M253.073,129.907l11.5-5.751L260.262,122,249,127.751Z" transform="translate(-249 -119.531)" fill="#2d3134"/>
      <path id="Path_93797" data-name="Path 93797" d="M261.533,111.866,254.1,115.58a1.641,1.641,0,0,0-.767.791l-1.438,3.067,3.355,1.677,10.543-5.272-1.653-3.091a1.547,1.547,0,0,0-.815-.743l-.431-.192A1.7,1.7,0,0,0,261.533,111.866Z" transform="translate(-251.205 -111.698)" fill="#e5e5e5"/>
      <path id="Path_93798" data-name="Path 93798" d="M255.071,129.627l-1.2-.527a1.337,1.337,0,0,0-.335.479l-1.438,3.067,3.355,1.677v-4.145A.708.708,0,0,0,255.071,129.627Z" transform="translate(-251.357 -124.93)" opacity="0.11"/>
      <path id="Path_93799" data-name="Path 93799" d="M274.966,114.655a1.679,1.679,0,0,0-.359-.455l-7.859,3.93a1.168,1.168,0,0,0-.647,1.054v3.834l10.543-5.272-1.677-3.091Z" transform="translate(-262.003 -113.6)" opacity="0.29"/>
      <path id="Path_93800" data-name="Path 93800" d="M279.189,122.917,272,126.511v-2.4L278.23,121Z" transform="translate(-266.489 -118.771)" fill="#2d3134"/>
    </g>
    <path id="Path_93801" data-name="Path 93801" d="M116.508,177.935c-.551-1.941-4.912-3.067-8.459-6.4-3.235-3.019-7.26-14.113-19.385-19.217-12.34-5.176-20.559-.072-26.981.647-4.385.479-11.094-1.51-16.03-3.067A9.7,9.7,0,0,0,44,154.956a6.926,6.926,0,0,0,2.971,5.966,20.007,20.007,0,0,0,1.893,1.318,8.192,8.192,0,0,1-.1-1.078c0-2.54,1.486-4.169,3.786-4.169a6.557,6.557,0,0,1,2.9.767c3.474,1.725,6.278,5.966,6.278,9.441a5.1,5.1,0,0,1-.407,2.061l33.618,16.462a7.022,7.022,0,0,1-.144-1.39c0-2.54,1.486-4.169,3.786-4.169a6.557,6.557,0,0,1,2.9.767c3.474,1.725,6.278,5.966,6.278,9.441a6.6,6.6,0,0,1-.12,1.222,28.67,28.67,0,0,0,5.272,1.006,25.736,25.736,0,0,0,5.367-.12c-.192-9.537-1.366-13.155-1.773-14.545Z" transform="translate(-25.853 -42.837)" opacity="0.11"/>
  </g>
    </svg>
    `
return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`;
};

const CustomeGoogleMap = (props) => {
  const {
    directionsRes,
    center,
    onLoadMap,
    zoom,
    currentPosition,
    currentRoute,
    isTrainingStart,
    map
  } = props;

  const calculateAngle = (pointA, pointB) => {
    const dy = pointB.lat - pointA.lat;
    const dx = pointB.lng - pointA.lng;
    let angle = (Math.atan2(dx, dy) * 180) / Math.PI; // Angle in degrees
  
    // Ensure angle is between 0 and 360
    if (angle < 0) {
      angle += 360;
    }
  
    return angle;
  };
  

 // Extract starting point (A) from either directions result or route path
 const getStartPosition = () => {
  if (directionsRes && directionsRes.routes.length > 0) {
    // Extract the start location from the directions result
    return {
      lat: directionsRes.routes[0].legs[0].start_location.lat(),
      lng: directionsRes.routes[0].legs[0].start_location.lng(),
    };
  } else if (currentRoute.length > 0) {
    // Use the first point in the currentRoute array
    return currentRoute[0];
  }
  return null;
};

const getEndPosition = () => {
  if (directionsRes && directionsRes.routes.length > 0) {
    // Extract the end location from the directions result
    return {
      lat: directionsRes.routes[0].legs[0].end_location.lat(),
      lng: directionsRes.routes[0].legs[0].end_location.lng(),
    };
  }
  return null;
};

const startPosition = getStartPosition();
const endPosition = getEndPosition();

// Calculate the angle for the vehicle's movement
const [vehicleRotation, setVehicleRotation] = useState(0);
const [iconUrl, setIconUrl] = useState('');

useEffect(() => {
  if (currentPosition && currentRoute.length > 1) {
    // Get the two latest positions in the route
    const lastPoint = currentRoute[currentRoute.length - 1];  // Most recent position
    const previousPoint = currentRoute[currentRoute.length - 2];  // Second most recent position

    // Calculate the angle between the last two points
    const angle = calculateAngle(previousPoint, lastPoint);
    console.log("Calculated angle:", angle);

    // const nextPoint = currentRoute[1]; // Assuming the second point is the next position
    // const angle = calculateAngle(currentPosition, nextPoint);
    setVehicleRotation(angle); // Set the rotation based on the direction of movement
  }
}, [currentPosition, currentRoute]);

useEffect(() => {
  // Logic for mirroring and vertical flipping
    const isMirrored = vehicleRotation > 90 && vehicleRotation < 270; // Horizontal flip for certain directions
    const isVerticallyFlipped = vehicleRotation < -180 || vehicleRotation > 180; // Vertical flip for certain angles
  // Update icon URL whenever rotation changes
  const customIconUrl = createCustomIcon('yellow', vehicleRotation,  isMirrored, isVerticallyFlipped);
  setIconUrl(customIconUrl);
}, [vehicleRotation]);

// Helper function to filter out consecutive duplicate points
const filterUniquePoints = (route) => {
  return route.filter((point, index, arr) => {
    if (index === 0) return true; // Always keep the first point
    const prevPoint = arr[index - 1];
    // Check if the current point is different from the previous one
    return point.lat !== prevPoint.lat || point.lng !== prevPoint.lng;
  });
};


useEffect(() => {
  if (currentPosition) {
    // Center the map on the current vehicle position
    map.panTo(currentPosition);
  }
}, [currentPosition, map]);

  

  return (
    <>
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: 800 }}
        center={center}
        onLoad={onLoadMap}
        zoom={12}
      >
        {directionsRes != null && !isTrainingStart && (
          <DirectionsRenderer directions={directionsRes}
          options={{
              suppressMarkers: true, // Suppress default A and B markers
            }} />
        ) }
        {/* {currentRoute.length > 0  && (
          <>
          <Polyline path={filterUniquePoints(currentRoute)} 
          options={{ strokeColor: "#36454F" }} />
          </>
        )} */}

         {/* Custom Marker for Point A (Start of the route) with vehicle icon */}
        {startPosition && !isTrainingStart && (
          <Marker
            position={startPosition} // Starting position of the route
            icon={{
              url: iconUrl, // Using the imported vehicle icon
              scaledSize: new window.google.maps.Size(50, 50), // Adjust size as needed
              // rotation: vehicleRotation,
            }}
          />
        )}

        {/* Default Marker for Point B (End of the route) */}
        {endPosition && !isTrainingStart && (
          <Marker
            position={endPosition} // End position of the route
          />
        )}

        {currentPosition && <Marker position={currentPosition}
         icon={{
              url: iconUrl, // URL of your vehicle image
              scaledSize: new window.google.maps.Size(50, 50), // Adjust size as needed
              anchor: new window.google.maps.Point(25, 25), // Set anchor to center
              // rotation: vehicleRotation, // Apply the calculated rotation angle
            }} />}
      </GoogleMap>
    </>
  );
};

export default CustomeGoogleMap;
