// react States
import React, { useEffect, useState } from "react";

// components
import InstructorsSelectionTable from "./components/InstructorsSelectionTable.jsx";
import InstructorsSelectionTableHeader from "./components/InstructorsSelectionTableHeader.jsx";

// css
import "./Instructors Selection.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../../../Components/LoadingSpinner/LoadingSpinner";
import TeacherFilter from "../../../Institute/Instructors/Components/TeacherFilter.jsx";

const InstructorSelection = ({
  Steps,
  setSteps,
  CourseData,
  setCourseData,
  StepsIndicator,
  PageNo,
  Success,
  OnClickSteps,
  OnClickBack,
  CourseLicense,
  CourseInfo,
  update,
}) => {
  const [MaxHeight, setMaxHeight] = useState("max-h-[0px]");

  const { loading } = useSelector((Store) => Store.CourseReducer);

  const OnClick = () => {
    if (Success) setSteps(6);
  };
  useEffect(() => {
    if (Success === true) setSteps(6);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Success]);
  return (
    <div
      className={`addCoursesMainContainer ${Steps < PageNo ? "hidden" : ""}`}
    >
      {!loading ? (
        <div className="row addCourse1">
          <div className="col-12">
            <div
              className=" mainContainerAddCourse px-5"
              // style={{ width: "90%" }}
            >
              <StepsIndicator Steps={Steps} OnClickSteps={OnClickSteps} />
              <h2 className="mt-20 mb-5 font-semibold text-[#4C34AD]">
                Instructor Selection
              </h2>
              <div className=" instructorDiv shadow overflow-x-scroll rounded-lg p-5">
                {/* <InstructorsSelectionTableHeader /> */}
                {/* <div className="flex w-full flex-col items-center px-6 sm:flex-row sm:px-8 md:px-10 lg:px-12 xl:px-14 2xl:px-16">
                                    <div className="Boldfont w-full text-center text-3xs  text-[#174A84] sm:w-1/5 sm:text-left md:w-1/4 lg:text-2xs xl:text-xs">
                                        Instructors
                                    </div>
                                    <div className="md::w-3/4 flex w-full justify-between gap-2 sm:w-4/5 sm:justify-end sm:gap-4 md:gap-6 lg:gap-8 xl:gap-10 2xl:gap-12">
                                        <Link
                                            to="/Instructor/add"
                                            className="InstructorPageBtns px-4 "
                                        >
                                            Add a New Instructor
                                        </Link>
                                        <span
                                            className="InstructorPageBtns  relative"
                                            onClick={OnClick}
                                            onMouseEnter={() =>
                                                setMaxHeight("max-h-[1000px]")
                                            }
                                            onMouseLeave={() =>
                                                setMaxHeight("max-h-[0px]")
                                            }
                                        >
                                            Filter Instructor
                                            <TeacherFilter
                                                handleApplyClick={
                                                    handleApplyClick
                                                }
                                                setSortBy={setSortBy}
                                                setSortByTime={setSortByTime}
                                                setSearchQuery={setSearchQuery}
                                                setSuspend={setSuspend}
                                                sortBy={sortBy}
                                                sortByTime={sortByTime}
                                                searchQuery={searchQuery}
                                                suspend={suspend}
                                                MaxHeight={MaxHeight}
                                                type={"InstructorList"}
                                                title={"Filter Teachers"}
                                            />
                                        </span>
                                    </div>
                                </div>
                                 */}
                <InstructorsSelectionTable
                  update={update}
                  CourseInfo={CourseInfo}
                  CourseLicense={CourseLicense}
                  CourseData={CourseData}
                  setCourseData={setCourseData}
                />
              </div>
            </div>

            <center>
              {Steps === 6 ? (
                <SuccssPopup setSteps={setSteps} Steps={Steps} />
              ) : null}

              {/* <button
                                className="SaveAndContinueBtn"
                                type="submit"
                                onClick={OnClick}
                            >
                                Save & Continue
                            </button> */}
            </center>
            {!update && (
              <div className="flex items-center justify-center gap-5">
                <button
                  className="SaveAndContinueBtn w-[12%]  cursor-pointer"
                  type="button"
                  onClick={() => OnClickBack(Steps)}
                >
                  Back
                </button>
                <button
                  className="SaveAndContinueBtn  cursor-pointer"
                  type="submit"
                  onClick={OnClick}
                >
                  Save & Continue
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

const SuccssPopup = ({ setSteps }) => {
  const Navigate = useNavigate();
  const OnClick = () => {
    setSteps(1);
    Navigate("/");
  };
  return (
    <div className="addCoursePage5Container z-30">
      <div className="addCourseFivePopup">
        <div className="addCourseFivePopupCross">
          <img
            className="cursor-pointer"
            src={require("./Assets/Cross.svg").default}
            alt="img"
            onClick={OnClick}
          />
        </div>
        <h6>Congratulations !!</h6>
        <p>Course has been added to the Queue Waiting for course approval</p>
        <div className="addCourseFivePopupVehicle">
          <img
            src={require("./Assets/Course Success.svg").default}
            alt="congratAddVehicleFive"
          />
        </div>
        <button type="button" className="cursor-pointer" onClick={OnClick}>
          Done
        </button>
      </div>
    </div>
  );
};

export default InstructorSelection;
