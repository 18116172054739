import "../../Conversation.css";
import React, { useContext, useEffect, useState } from "react";
import ChatInput from "./ChatInput";
import { useRef } from "react";
import ChatHeader from "../ChatHeader/ChatHeader";
import { BaseUrl } from "../../../../Actions/Base";
import axios from "axios";
import { useSelector } from "react-redux";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function ChatBody({
    online,
    active,
    currentSelected,
    socket,
    unreadMessages,
    updateUnreadCount,
    backtoChatList
}) {
    const [messages, setMessages] = useState([]);
    const [arrivalMessage, setArrivalMessage] = useState(null);
    const scrollRef = useRef();

    const { UserInfo } = useSelector((state) => state.LoginSignupReducer);

    useEffect(() => {
        const getMessages = async () => {
            try {
                const response = await axios.post(`${BaseUrl}/api/messages`, {
                    // params: {
                    from: UserInfo?.UserId,
                    to: currentSelected?.UserId,
                    // },
                });
                console.log("response", response);
                setMessages(response.data);
            } catch (error) {
                console.error(
                    `Error occurred while fetching messages ${error.message}`
                );
            }
        };

        // if (currentSelected) {
        getMessages();
        // }
    }, [currentSelected, socket]);

    const handleSendMessage = async (msg) => {
        socket?.emit("send-msg", {
            text: msg,
            from: UserInfo?.UserId,
            to: currentSelected?.UserId,
            time: new Date().toISOString(),
        });

        await axios.post(`${BaseUrl}/api/message`, {
            from: UserInfo?.UserId,
            to: currentSelected?.UserId,
            text: msg,
        });
        const msgs = [...messages];

        msgs.push({
            fromSelf: true,
            message: msg,
            time: new Date().toISOString(),
            from: UserInfo?.UserId,
            to: currentSelected?.UserId,
        });
        setMessages(msgs);
    };

    useEffect(() => {
        if (socket) {
            socket?.on("msg-received", async (msg) => {
                console.log("msg-received", msg);
                if (msg.from !== UserInfo?.UserId) {
                    if (currentSelected?.UserId !== msg.from || !active) {
                        await updateUnreadCount(
                            msg.from,
                            (unreadMessages[msg.from] || 0) + 1
                        );

                        await axios.put(
                            `${BaseUrl}/api/messages/unread-count`,
                            {
                                userId: msg.from,
                                unreadCountFrom:
                                    (unreadMessages[msg.from] || 0) + 1,
                            }
                        );
                    } else {
                        // If the conversation is active and the message is not from the current user,
                        // then the message is considered read, so update the unread count to 0
                        await updateUnreadCount(msg.from, 0);
                    }
                }
                setArrivalMessage({
                    fromSelf: false,
                    message: msg?.text,
                    time: msg?.time,
                    from: msg.from,
                    to: msg?.to,
                });
            });
        }
    }, [socket, unreadMessages, updateUnreadCount]);

    useEffect(() => {
        arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
    }, [arrivalMessage]);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    return (
        <>
            <div className="ml-[15px] lg:ml-[0px] flex h-full lg:h-[106px]   w-full items-center    border border-l-0  border-t-0 border-solid border-[#F1F1F3] ">
                <div className="lg:hidden">
                    <ArrowBackIosIcon onClick={backtoChatList}   />
                </div>
                <ChatHeader
                    online={online}
                    currentSelected={currentSelected}
                    active={active}
                />
            </div>

            <div className="mx-auto w-[100%] lg:w-[90%]">
                <div className="message__container">
                    {messages &&
                        messages?.map((message, index) => {
                            const date = new Date(message?.time);
                            const year = date.getFullYear();
                            const month = (date.getMonth() + 1)
                                .toString()
                                .padStart(2, "0");
                            const day = date
                                .getDate()
                                .toString()
                                .padStart(2, "0");
                            const hours = date
                                .getHours()
                                .toString()
                                .padStart(2, "0");
                            const minutes = date
                                .getMinutes()
                                .toString()
                                .padStart(2, "0");

                            const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;

                            {
                                /* const date = new Date(message?.time);
                                        const hours = date.getHours();
                                        const minutes = date.getMinutes();
                                        const formattedTime = `${
                                            hours < 10 ? "0" + hours : hours
                                        }:${
                                            minutes < 10
                                                ? "0" + minutes
                                                : minutes
                                        }`; */
                            }

                            // Check if the message is from today
                            {
                                /* const todayHeader =
                                            index === 0 ||
                                            (!isToday(
                                                new Date(
                                                    messages[index - 1]?.time
                                                )
                                            ) &&
                                                isToday(date)); */
                            }

                            // Check if the message is from a different day
                            {
                                /* const newDayHeader =
                                            index === 0 ||
                                            !isSameDay(
                                                new Date(
                                                    messages[index - 1]?.time
                                                ),
                                                date
                                            ); */
                            }

                            return message?.fromSelf ? (
                                <div
                                    className="message__chats"
                                    ref={scrollRef}
                                    key={index}
                                >
                                    {/* {todayHeader && (
                                                    <div className="message__date-header py-3 text-center">
                                                        <p>
                                                            {isToday(date)
                                                                ? "Today"
                                                                : format(
                                                                      date,
                                                                      "MMMM dd, yyyy"
                                                                  )}
                                                        </p>
                                                    </div>
                                                )} */}
                                    {/* Display full date header if it's a new day  */}
                                    {/* {newDayHeader &&
                                                    !isToday(date) && (
                                                        <div className="message__date-header text-center">
                                                            <p>
                                                                {format(
                                                                    date,
                                                                    "MMMM dd, yyyy"
                                                                )}
                                                            </p>
                                                        </div>
                                                    )} */}

                                    <div className="message__sender">
                                        <p className="text-[30px]">
                                            {message?.message}
                                        </p>
                                        {/* <p>{message?.time}</p> */}
                                        <p>{formattedDateTime}</p>
                                    </div>
                                </div>
                            ) : currentSelected?.UserId === message?.from ? (
                                <div className="message__chats" key={index}>
                                    {/* display received message if you open the chat between sender and receiver contact is selected  */}

                                    <div className="message__recipient">
                                        <p className="text-[30px]">
                                            {message?.message}
                                        </p>
                                        <p>
                                            <p>{formattedDateTime}</p>
                                            {/* <p>{message?.time}</p> */}
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                currentSelected?.UserId === message.to && (
                                    <div className="message__chats" key={index}>
                                        {/* display received message if you open the chat between sender and receiver contact is selected  */}

                                        <div className="message__recipient">
                                            <p className="text-[30px]">
                                                {message?.message}
                                            </p>
                                            <p>
                                                <p>{formattedDateTime}</p>
                                                {/* <p>{message?.time}</p> */}
                                            </p>
                                        </div>
                                    </div>
                                )
                            );
                        })}
                </div>

                <div className="   flex  h-[157px] flex-col justify-center bg-[#F5F6FA] px-10 ">
                    <ChatInput handleSendMessage={handleSendMessage} />
                </div>
            </div>
        </>
    );
}

export default ChatBody;
