// import React from "react";
// import { useState, useEffect } from "react";
// import SunEditor from "suneditor-react";
// import "suneditor/dist/css/suneditor.min.css";
// import "./RichTextEditor.css";

// const RichTextEditor = ({
//     readOnly,
//     Errors,
//     setErrors,
//     Data,
//     setData,
//     KeyName,
// }) => {
//     const [DescriptionErr, setDescriptionErr] = useState();
//     const [Description, setDescription] = useState();
//     useEffect(() => {
//         // Set initial Description state when Data.Description changes
//         if (Data.Description) {
//             setDescription(Data.Description);
//         }
//     }, [Data.Description]);

//     useEffect(() => {
//         if (DescriptionErr) {
//             if (Errors) {
//                 setErrors({ ...Errors, [KeyName]: DescriptionErr });
//             } else if (!DescriptionErr) {
//                 delete Errors[KeyName];
//                 setErrors({ ...Errors });
//             }
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [DescriptionErr]);

//     useEffect(() => {
//         const descriptionKey = KeyName ? KeyName : "Description";
//         setData((prevData) => ({ ...prevData, [descriptionKey]: Description }));
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [Description, KeyName]);

//     return readOnly ? (
//         <div
//             className="sun-editor-container"
//             dangerouslySetInnerHTML={{ __html: Description }}
//         />
//     ) : (
//         <SunEditor
//             onChange={(content) => {
//                 if (content !== "") {
//                     setDescriptionErr(false);
//                     setDescription(content);
//                 } else {
//                     setDescriptionErr(true);
//                 }
//             }}
//             defaultValue={Description}
//         />
//     );
// };

// export default RichTextEditor;

import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import "./RichTextEditor.css";

const RichTextEditor = ({
  readOnly,
  Errors,
  setErrors,
  Data,
  setData,
  KeyName,
  RichTextEditorStyle,
}) => {
  const [DescriptionErr, setDescriptionErr] = useState();
  const [Description, setDescription] = useState(
    Data?.Description || Data?.CourseCurriculum || ""
  );
  console.log("Data,", Data);
  const editor = useRef();

  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };
  useEffect(() => {
    if (DescriptionErr)
      if (Errors) setErrors({ ...Errors, [KeyName]: DescriptionErr });
      else if (!DescriptionErr) {
        delete Errors[KeyName];
        setErrors({ ...Errors });
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DescriptionErr]);

  useEffect(() => {
    if (KeyName) setData({ ...Data, [KeyName]: Description });
    else setData({ ...Data, Description });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Description, KeyName]);
  console.log("Description", Description);
  useEffect(() => {
    setDescription(Data?.Description || Data?.CourseCurriculum || "");
  }, [Data]);

  return (
    <>
      {/* {readOnly ? (
        <div
          className="sun-editor-container bg-white py-2 px-2"
          dangerouslySetInnerHTML={{ __html: Description }}
        /> */}
      {/* ) : ( */}
      <SunEditor
        getSunEditorInstance={getSunEditorInstance}
        // ref={editorRef}
        // onLoad={() => {
        //   if (editorRef.current) {
        //     editorRef.current.editor.setContents(Description);
        //   }
        // }}
        setContents={Description}
        onChange={(Content) => {
          // const aa = document.getElementsByClassName("se-wrapper-inner")
          if (Content !== "") {
            setDescriptionErr(false);
            setDescription(Content);
          } else setDescriptionErr(true);
        }}
        defaultValue={Description}
        placeholder="Enter your content here..."
        setDefaultStyle="font-size: 20px; font-family: 'Poppins', sans-serif; font-weight: 400; line-height: 1.5; color: #333; padding: 10px;"
        autoFocus={false}
        // width="600"
        // height="200"
        disable={readOnly}
        hideToolbar={readOnly}
        setOptions={
          {
            // backgroundColor: RichTextEditorStyle?.backgroundColor,
          }
        }
      />
      {/* )} */}
    </>
  );
};

export default RichTextEditor;
