import React, { useState } from "react";
import "./GetReadyTiles.css";
import FrontCarImage from "./Assets/Driving knowledge.png";
import StudentsImage from "./Assets/Driving quiz.png";
import SideCarImg from "./Assets/Road Test.png";
import BulbIcon from "./Assets/BulbIcon.png";
const GetReadyTiles = () => {
    const [TilesInfo] = useState([
        {
            ImageLink: SideCarImg,
            Heading: "Knowledge Test",
            Text: "Gain Knowledge for Your License Category.",
            href: "/get-ready/knowledge-test",
        },
        {
            ImageLink: FrontCarImage,
            Heading: "Knowledge Test Quiz",
            Text: "Test Your Knowledge with Quizzes.",
            href: "/get-ready/knowledge-test-selection",
        },
        {
            ImageLink: StudentsImage,
            Heading: "Road Test",
            Text: "Book Your Road Test.",
            href: "",
        },
    ]);
    // [rgba(238,238,238,1)]
    return (
        <div className="relative -top-10 flex w-full flex-col flex-wrap items-center justify-center gap-5 bg-[#4d4d4d00] xs:-top-20 sm:-top-20 md:-top-20 md:flex-row md:gap-10 lg:-top-20 xl:-top-20 2xl:gap-20">
            {TilesInfo.map((value, index) => (
                <a
                    href={value.href}
                    key={index}
                    className={`TilesShadow flex max-h-[355px] max-w-[350px] cursor-pointer  flex-col items-center justify-center
        gap-5 rounded-2xl  border-2 border-solid border-[#EEEEEE]
        bg-white text-black no-underline 
        `}
                >
                    <div className="w-20 md:w-28">
                        <img
                            loading="lazy"
                            src={value.ImageLink}
                            alt=""
                            className="w-full"
                        />
                    </div>
                    <div className="flex flex-col gap-[5px]">
                        <h3 className="Blackfont text-center">
                            {value.Heading}
                        </h3>
                        <p className="Regularfont w-56 text-center md:w-72 ">
                            {value.Text}
                        </p>
                    </div>
                </a>
            ))}
            {/* <picture>
                <source media="(max-width:640px )" srcset="" />
                <img
                    loading="lazy"
                    className="absolute -left-32 -top-48 -z-10 hidden h-[380px] xl:inline-block"
                    src={BulbIcon}
                    alt=""
                />
            </picture> */}
        </div>
    );
};

export default GetReadyTiles;
