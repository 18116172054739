import { createReducer } from "@reduxjs/toolkit";

const StaffInitialState = {
    loading: false,
    Staff: {},
    Staffs: [],
    SingleStaff: {},
    error: {},
};

export const StaffReducer = createReducer(StaffInitialState, (builder) => {
    //create staff member
    builder.addCase("CreateStaffsRequest", (state) => {
        state.loading = true;
    });

    builder.addCase("CreateStaffsSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.Staff = action.payload;
    });

    builder.addCase("CreateStaffsFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });

    // ------single staff member start
    builder.addCase("GetSingleStaffRequest", (state) => {
        state.loading = true;
    });

    builder.addCase("GetSingleStaffSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.SingleStaff = action.payload;
    });

    builder.addCase("GetSingleStaffFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });
    // --------single staff member end

    //----------update staff member--start
    builder.addCase("UpdateStaffsRequest", (state) => {
        state.loading = true;
    });

    builder.addCase("UpdateStaffsSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.Staff = action.payload;
    });

    builder.addCase("UpdateStaffsFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });
    //----------update staff member end

    // -------delete staff start
    builder.addCase("DeleteStaffRequest", (state) => {
        state.loading = true;
    });

    builder.addCase("DeleteStaffSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
    });

    builder.addCase("DeleteStaffFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });
    //-----------delete staff end

    builder.addCase("GetStaffsRequest", (state) => {
        state.loading = true;
    });

    builder.addCase("GetStaffsSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.Staffs = action.payload;
    });

    builder.addCase("GetStaffsFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });
});
