export const ScrollElement = (Errors, Extra) => {

    if (!Extra)
        Extra = 200
    for (const [key, value] of Object.entries(Errors)) {
        if (value && value !== "") {
            ScrollSingleElement(key, Extra)

            break
        }
    }

}
export function ScrollSingleElement(id, Extra) {
    const Element = document.getElementById(id);
    const Y = Element?.getBoundingClientRect().top + window.scrollY - Extra
    if (Y) {
        window.scroll({
            top: Y,
            behavior: 'smooth'
        });
    }

}