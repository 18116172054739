import { CheckString } from "../../Helpers/TypeChecker/CheckString";
import PhoneInput from "react-phone-number-input";
import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";

import "./InputComp.css";
const Container = "w-full  flex flex-col gap-3 ";
const TextStyle = "text-3xs md:text-2xs xl:text-xs";
const ResponsiveInpuyStyle = "text-5xs md:text-4xs lg:text-3xs";

export const Input = ({
    licenseInput,
    inputsDisabled,
    readOnly,
    Label,
    Placeholder,
    Id,
    onChange,
    Err,
    State,
    type,
    Number,
    autoComplete = "on", // Default value set to "on"
}) => {
    const Border = "border-[#E2E2E2] border-[1px] border-solid";
    return (
        <div className={licenseInput ? Container : "flex  w-full flex-col"}>
            <label htmlFor={Id} className={TextStyle}>
                {Label}
            </label>

            <h4 className="text-right text-4xs font-normal text-[red]">
                {Err
                    ? CheckString(Err)
                        ? Err
                        : `${Label} * is required`
                    : null}
            </h4>

            <input
                id={Id}
                type={Number ? Number : "text"}
                placeholder={Placeholder}
                disabled={readOnly}
                // disabled={type === "disabled" || readOnly ? true : false}
                className={`rounded-md py-2  px-4 ${Border} shadow-[4px_5px_6px_#00000029] ${ResponsiveInpuyStyle}`}
                value={State}
                onChange={onChange}
                autoComplete={autoComplete} // Add this line
            />
        </div>
    );
};

export const Phone = ({
    readOnly,
    Label,
    Placeholder,
    Id,
    onChange,
    Err,
    State,
    type,
    Number,
    country,
}) => {
    const Border = "border-[#E2E2E2] border-[1px] border-solid";

    return (
        <div className={Container}>
            <label htmlFor={Id} className={TextStyle}>
                {Label}
            </label>

            <h4 className="text-right text-4xs font-normal text-[red]">
                {Err ? (CheckString(Err) ? Err : `${Label} is required`) : null}
            </h4>

            <PhoneInput
                // country={country}
                id={Id}
                // readOnly={readOnly}
                type={Number ? Number : "text"}
                placeholder={Placeholder}
                disabled={readOnly}
                className={`rounded-md py-2  px-4 ${Border} text-[29px] shadow-[4px_5px_6px_#00000029]  ${ResponsiveInpuyStyle}`}
                value={State}
                // onChange={onChange}
                onChange={onChange}

                // country="CA"
                // defaultCountry={"CA"}
            />
        </div>
    );
};

export const InputWithImage = ({
    Label,
    Placeholder,
    Id,
    onChange,
    Err,
    Src,
    State,
    name,
    readOnly,
}) => {
    const AddParentStyle = (e) =>
        (e.target.parentNode.style = "border:2px solid black");
    const RemoveParentStyle = (e) => (e.target.parentNode.style = "");

    return (
        <div className={Container}>
            <label htmlFor={Id} className={TextStyle}>
                {Label}
            </label>

            <h4 className="text-right text-4xs font-normal text-[red]">
                {" "}
                {Err ? (CheckString(Err) ? Err : `${Label} is required`) : null}
            </h4>

            <span className="flex w-full gap-4 rounded-md border-[1px] border-solid border-[#E2E2E2] bg-white py-2 px-4 shadow-[4px_5px_6px_#00000029]">
                <img src={Src} className="h-8" alt="" />
                <input
                    id={Id}
                    type="text"
                    disabled={readOnly}
                    placeholder={Placeholder}
                    className={`none w-full border-none outline-none  ${ResponsiveInpuyStyle}`}
                    onFocus={AddParentStyle}
                    onBlur={RemoveParentStyle}
                    value={State}
                    onChange={onChange}
                />
            </span>
        </div>
    );
};
export const SelectList = ({
    Label,
    Id,
    onChange,
    OptionsArr,
    defaultValue,
    multiselect,
    selectedValue,
    Text,
    Err,
    State,
    type,
    readOnly,
    licenseInput,
}) => {
    const Border = "border-[#E2E2E2] border-[1px] border-solid";

    return (
        <div className={licenseInput ? Container : "flex  w-full flex-col"}>
            <label htmlFor={Id} className={TextStyle}>
                {Label}
            </label>

            <h4 className="text-right text-4xs font-normal text-[red]">
                {" "}
                {Err ? (CheckString(Err) ? Err : `${Label} is required`) : null}
            </h4>

            <select
                className={`flex w-full rounded-md bg-white py-2 px-4 ${Border} shadow-[4px_5px_6px_#00000029] ${ResponsiveInpuyStyle}`}
                disabled={readOnly}
                id={Id}
                value={State}
                onChange={onChange}

                // onChange={!readOnly ? onChange : undefined}
            >
                <option disabled value="">
                    {Text}
                </option>

                {OptionsArr ? <OptionsArr selectedValue={selectedValue}/> : null}
            </select>
        </div>
    );
};

export const HeaderSelectList = ({
    Label,
    Id,
    onChange,
    OptionsArr,
    defaultValue,
    multiselect,
    selectedValue,
    Text,
    Err,
    State,
    type,
    readOnly,
    licenseInput,
  }) => {
    const Border = "border-[#E2E2E2] border-[1px] border-solid";
  
    return (
      <div className={licenseInput ? Container : "flex  w-full flex-col"}>
        <label htmlFor={Id} className={TextStyle}>
          {Label}
        </label>
  
        <h4 className="text-right text-4xs font-normal text-[red]">
          {Err ? (CheckString(Err) ? Err : `${Label} is required`) : null}
        </h4>
  
        <select
          className={`flex w-full rounded-md bg-white py-2 px-4 ${Border} shadow-[4px_5px_6px_#00000029] ${ResponsiveInpuyStyle}`}
          disabled={readOnly}
          id={Id}
          value={State}
          onChange={onChange}
        >
          <option disabled value="">
            {Text}
          </option>
  
          {/* Render options directly if OptionsArr is provided */}
          {OptionsArr &&
            OptionsArr.map((option, index) => (
              <option key={index} value={option.value || option.name}>
                {option.label || option.name}
              </option>
            ))}
        </select>
      </div>
    );
  };
  
