import dayjs from "dayjs";
import "./TeacherDashboardTable.css";
export const AdminDashboardRowTemplate = ({
  index,
  FirstName,
  LastName,
  Status,
  createdAt,
  Email,
  // LastComp,
  LastCompp,
  StatusColor,
  PaymentOrUnderClients,
  PaymentOrClientsColor,
  OnClick,
  TrStyle,
  ImgUrl,
  Delete,
  type,
  StudentId,
  NextClassStartDate,
  NextClassStartTime,
  classStatus,
  TotalClasses,
  completedClasses,
  ClassType,
  ClassNo,
  Delivered,
  Edit,
  Update,
  InitiateClass,
  InstructorImgUrl,
  InstructorFirstName,
  InstructorLastName,
  ClassFeedback,
  EnrollmentId
}) => {
  return (
    <tr onClick={OnClick} className={TrStyle}>
    
      <td className="text-center">
        <div
          className={`addCourse4Status ${
            classStatus === "Delivered"
              ? "bg-[#B5AAFF] text-white"
              : classStatus === "Missed"
              ? "bg-[#B05AC4] text-white"
              : "bg-[#7F82F9] text-white"
          }`}
        >
          {Status || classStatus}
        </div>
      </td>

      <td >
      <div className="flex justify-center items-center gap-[15px]">
        <div className="ProfileImageContainer">
          <img
            className="absolute w-full"
            src={ImgUrl}
            alt={FirstName + "img"}
          />
        </div>
        <div> {FirstName} {LastName}</div>
      </div>
      </td>
      
      {/* <td className="text-center truncate hover:text-clip  ">
        {FirstName} {LastName}
      </td> */}

      <td className="">
      <div className="flex justify-center items-center gap-[15px]">
        <div className="ProfileImageContainer">
          <img
            className="absolute w-full"
            src={InstructorImgUrl}
            alt={InstructorFirstName + "img"}
          />
        </div>
        <div> {InstructorFirstName} {LastName}</div>
      </div>
      </td>

      {/* <td className="text-center truncate hover:text-clip  ">
        {InstructorFirstName} {InstructorLastName}
      </td> */}

      {ClassType ? (
        <td className={`text-center  `}>
          {/* <div className={`addCourse4Status `}> */}
          {ClassType === "Driving"
            ? "Driving"
            : ClassType === "Online"
            ? "Online"
            : ClassType === "InClass"
            ? "In-Class"
            : null}
          {/* </div> */}
        </td>
      ) : null}

      <td className="text-center">{ClassNo}</td>

      <td className="text-center">
        <div
          // className={`addCourse4Status ${
          //   classStatus === "Delivered"
          //     ? "bg-[#B5AAFF] text-white"
          //     : classStatus === "Missed"
          //     ? "bg-[#B05AC4] text-white"
          //     : "bg-[#7F82F9] text-white"
          // }`}
        >
          {`${NextClassStartDate} | ${NextClassStartTime}`}
        </div>
      </td>
     
        <td >
          <div className="text-left w-[90%] h-[80px] bg-[#FFFFFF] rounded-[10px] text-black px-[20px] py-[15px] m-[10px]">
              {ClassFeedback}
          </div>
        </td>

      <td className={`  text-center`}>
        {type === "students" ? (
          <img
            className="absolute w-full"
            src={require("../../Assets/Chat.svg").default}
            alt={"chat"}
            width={40}
            height={40}
          />
        ) : (
          <>
            {
            classStatus === "Completed" ? (
              <Delivered />
            ) : 
              classStatus === "Missed" ? (
              <Edit rowData={{ FirstName, LastName, Status, createdAt, Email, StudentId, NextClassStartDate, NextClassStartTime, classStatus, TotalClasses, completedClasses, ClassType, ClassNo, EnrollmentId }} />
            ) : 
              classStatus === "Upcoming Class" &&
              ClassType === "Driving" && InitiateClass ? (
                 <InitiateClass />
            ) : 
              (classStatus === "Upcoming Class" || ClassType === "Driving" || ClassType === "Online" || ClassType === "InClass") ? (
              <Update rowData={{ FirstName, LastName, Status, createdAt, Email, StudentId, NextClassStartDate, NextClassStartTime, classStatus, TotalClasses, completedClasses, ClassType, ClassNo, EnrollmentId }} />
            ) : null}
          </>
        )}
      </td>

    </tr>
  );
};
