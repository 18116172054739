export const VehicleAndLicenseTextdata = 
  {
    vehicleTypeL: {
        headingText:
        "Vehicle Category L – 2, 3 and 4 Wheel Bikes, Tricycles and Quadricycles Vehicles",
      subHeading:
        "Discover comprehensive list of courses for license category M1, M2, M, M2 with L Condition or M2 with 1 Condition classes at our centralized hub for mastering the fun and adventure of vehicle driving licenses of these categories.",
        headingForListing:
        "Vehicle Category L – 2, 3 and 4 Wheel Bikes, Tricycles and Quadricycles Vehicles",
        subHeadingForListing:
        "License Classes Covered under this Category are: M1, M2, M, M2 with L Condition or M2 with 1 Condition",
    },
    vehicleTypeM: {
        headingText:
        "Vehicle Learning Innovation facilitate course for Vehicle Category M – Personal Vehicle Carrying Passenger(s)",
      subHeading:
        "Discover comprehensive list of courses and knowledge tests for license classes G1, G2 and G at our centralized hub for mastering the driver's education and learning to obtain the full class G license.",
        headingForListing:
        "Vehicle Category M - Personal Vehicle Carrying Passenger(s)",
        subHeadingForListing:
        "License Classes Covered under this Category are: G1, G2 and G",
    },
    vehicleTyeN: {
        headingText:
        "Vehicle Learning Innovation facilitate course for Vehicle Category N – Commercial Vehicle for Material Transportation",
      subHeading:
        "Discover comprehensive list of courses for vehicle category N, which is covering the licenses of A, B, C, D, E and F classes at our centralized hub for mastering the commercial vehicle driving licenses. ",
        headingForListing:
        "Vehicle Category N - Commercial Vehicle for Material Transportation",
        subHeadingForListing:
        "License Classes Covered under this Category are: A, B, C, D, E and F",

    },
    vehicleTypeT: {
        headingText: "Vehicle Category T – Agriculture and construction machinery including tractor, forklift, crane and/or earth moving equipment.",
        subHeading:
        "Discover a comprehensive list of courses to obtain the licenses to operate heavy equipment and machineries.",
        headingForListing:
        "T – Agriculture and construction machinery including tractor, forklift, crane and/or earth moving equipment.",
        subHeadingForListing:
        "Including various categories of licenses",
    }

  }
;


export const DataForSubcategories = {
  licenseTypeG: {
    HeadingText : "Vehicle Learning Innovation facilitate course for to obtain full class G license.",
    SubHeading : "Discover comprehensive list of courses and compare the most suitable course for your need to obtain the full Class G License."  
  },
  licenseTypeM: {
    HeadingText : "Vehicle Learning Innovation facilitate course for to obtain full class M license.",
    SubHeading : "Discover comprehensive list of courses and compare the most suitable course for your need to obtain the full Class M License."  
  }
       
}

export const DataForSubcategoriess = {
  licenseTypeG: {
    HeadingText : "Vehicle Learning Innovation facilitate course for to obtain full class G license. ",
    SubHeading : "This is your last and final step toward obtaining your driving license!!! Well Done."  ,
    SecondSubHeading: "Discover comprehensive list of courses and compare the most suitable course for your need to obtain the full Class G License.",
    ListingSecPara: "A full licence to drive a car, van, or small truck. Additional conditions apply to those 21 years of age or under."
  },
  licenseTypeG1: {
    HeadingText : "Vehicle Learning Innovation facilitate course for to obtain class G1 license.",
    SubHeading : "This is your first step toward obtaining your driving license!!! So, congratulations.",
    SecondSubHeading: "Discover comprehensive list of courses and compare the most suitable course for your need to obtain the Class G1 License.",
    ListingSecPara: "G1 holders may drive Class G vehicles when accompanied by a fully licensed driver with at least four years of driving experience. Additional conditions apply."
  },
  licenseTypeG2: {
    HeadingText : "Vehicle Learning Innovation facilitate course for to obtain class G2 license.",
    SubHeading : "This is your second step toward obtaining your driving license!!! So, great progress.",
    SecondSubHeading: "Discover comprehensive list of courses and compare the most suitable course for your need to obtain the Class G2 License.",
    ListingSecPara : "Holders may drive Class G vehicles without an accompanying driver. Additional conditions apply, particularly to those 21 years of age or under."
  },
  licenseTypeM: {
    HeadingText : "Vehicle Learning Innovation facilitate course for to obtain full class M license. ",
    SubHeading : "This is your final step toward obtaining your motorcycle license!!! Awesome" ,
    SecondSubHeading : "Discover comprehensive list of courses and compare the most suitable course for your need to obtain the full Class M License.",
    ListingSecPara: "Holders may drive Class M vehicles without any additional conditions"
  },
  licenseTypeM1: {
    HeadingText : "Vehicle Learning Innovation facilitate course for to obtain class M1 license. ",
    SubHeading : "This is your first step toward obtaining your motorcycle License!!! So, great progress.",
    SecondSubHeading : "Discover comprehensive list of courses and compare the most suitable course for your need to obtain the Class M1 License.",
    ListingSecPara: "Level 1 of graduated licensing for motorcycles, including limited-speed motorcycles (motor scooters) and motor-assisted bicycles (mopeds)."
  },
  licenseTypeM2: {
    HeadingText : "Vehicle Learning Innovation facilitate course for to obtain class M2 license. ",
    SubHeading : "This is your second step toward obtaining your motorcycle License!!! So, well done.",
    SecondSubHeading: "Discover comprehensive list of courses and compare the most suitable course for your need to obtain the Class M2 License.",
    ListingSecPara: "Level 2 of graduated licensing for motorcycles, including limited-speed motorcycles (motor scooters) and motor-assisted bicycles (mopeds)."  
  },
  conditionL: {
    HeadingText : "Vehicle Learning Innovation facilitate course for to obtain class M, M1 & M2 license with Condition L. ",
    SubHeading : "Discover comprehensive list of courses and compare the most suitable course for your need to obtain the Class M, M1 & M2 License with Condition L.",
    SecondSubHeading: "",
    ListingSecPara: "Limited-speed motorcycles (LSM) or mopeds only. Limited-speed motorcycles are motorcycles with a maximum speed of 70 km/hr. If manufactured after 1988, they are identified by a label on the vehicle that indicates it to be a “LSM/MVL.” For a full description, visit MTO’s website" 
  },
  condition1: {
    HeadingText : "Vehicle Learning Innovation facilitate course for to obtain class M, M1 & M2 license with Condition 1.",
    SubHeading : "Discover comprehensive list of courses and compare the most suitable course for your need to obtain the Class M, M1 & M2 License with Condition 1.",
    SecondSubHeading: "",
    ListingSecPara: "Motor tricycles (three-wheeled motorcycles) only. These vehicles travel on three wheels, have straddled seating, use a handle bar for steering, carry no more than 4 passengers, and have a gross vehicle weight of 1,000 kg or less. Some vehicles with three wheels, because of the close spacing of its front wheels, are considered to be a two-wheeled open motorcycle by Transport Canada. If you take a road test with one of these vehicles, you can get a full, rather than limited, M class driver’s licence." 
  }

 
       
}

