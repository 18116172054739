import React from "react";
import SideFilters from "../../../../Components/SideFilter/SideFilters";
import TeacherFilterArr from "./FilterArr";
import StaffFilterArr from "./StaffFilterArr";
import VehicleFilterArr from "./VehicleFilterArr";

const TeacherFilter = ({
    MaxHeight,
    setSortBy,
    setSortByTime,
    setSearchQuery,
    setSuspend,
    handleApplyClick,
    suspend,
    sortByTime,
    sortBy,
    searchQuery,
    vehicleType,
    setVehicleType,
    vehicleLicenseType,
    setVehicleLicenseType,

    title,
    type,
    types,
    gearType,
    setTypes,
    setGearType,
}) => {
    // console.log("TEacher filter rendered");
    return (
        <SideFilters
            OnClick={() =>
                handleApplyClick({
                    sortBy: sortBy,
                    sortByTime: sortByTime,
                    searchQuery: searchQuery,
                    suspend: suspend,
                    vehicleType: vehicleType,
                    vehicleLicenseType: vehicleLicenseType,
                    types: types,
                    gearType: gearType,
                })
            }
            types={types}
            setTypes={setTypes}
            gearType={gearType}
            setGearType={setGearType}
            setSortBy={setSortBy}
            setSortByTime={setSortByTime}
            setSearchQuery={setSearchQuery}
            setSuspend={setSuspend}
            WidthStyle={`${MaxHeight} h-fit duration-100 w-full`}
            PositionAndIndex="absolute z-30 top-12"
            ShowOnSmall={true}
            sortBy={sortBy}
            sortByTime={sortByTime}
            searchQuery={searchQuery}
            suspend={suspend}
            vehicleLicenseType={vehicleLicenseType}
            setVehicleLicenseType={setVehicleLicenseType}
            vehicleType={vehicleType}
            setVehicleType={setVehicleType}
            Title={title}
            FiltersArr={
                type === "InstructorList"
                    ? TeacherFilterArr : type === "StaffList" ? StaffFilterArr
                    : type === "VehicleList"
                    ? VehicleFilterArr
                    : null
            }
            handleApplyClick={handleApplyClick}
        />
    );
};

export default TeacherFilter;
