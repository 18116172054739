import React, { useState } from "react";
import "./GetReadyTiles.css";
import FrontCarImage from "./Assets/Driving knowledge.png";
import StudentsImage from "./Assets/Driving quiz.png";
import SideCarImg from "./Assets/Road Test.png";
import Footer from "../../../../Components/Footer/Footer";
// import BulbIcon from "./Assets/BulbIcon.png";
const KnowledgeTestTile = () => {
    const [TilesInfo] = useState([
        {
            ImageLink: SideCarImg,
            Heading: "10 Random Questions",
            Text: "You will be asked 10 Random Question",
            href: "/get-ready/knowledge-test/attempt-quize",
        },
        {
            ImageLink: FrontCarImage,
            Heading: "20 Random Questions",
            Text: "You will be asked 20 Random Question",
            href: "",
        },
        {
            ImageLink: StudentsImage,
            Heading: "50 Random Questions",
            Text: "You will be asked 50 Random Question",
            href: "",
        },
        {
            ImageLink: SideCarImg,
            Heading: "80 Random Questions",
            Text: "You will be asked 80 Random Question",
            href: "",
        },
        {
            ImageLink: FrontCarImage,
            Heading: "100 Random Questions",
            Text: "You will be asked 100 Random Question",
            href: "",
        },
        {
            ImageLink: StudentsImage,
            Heading: "150 Random Questions",
            Text: "You will be asked 150 Random Question",
            href: "",
        },
    ]);
    // [rgba(238,238,238,1)]
    return (
        <div>
            <div className="flex items-center justify-center ">
                <div className="flex  w-[1483px] flex-col items-center gap-5 overflow-hidden xs:mt-10 sm:mt-10 md:mt-10 lg:mt-10  lg:gap-5">
                    <h2 className="SemiBoldItalic px-5 xs:text-center xs:text-xs sm:text-center lg:text-[38px]">
                        Preparing for Your Canadian Driving Test Experience
                    </h2>
                    <p className=" px-5 text-center text-xs  font-normal  ">
                        All practice questions are multiple choice and will
                        challenge your knowledge of the road rules. There are
                        150 questions in the actual database quiz, or choose 10,
                        20, 50, 80, 100 or All 150 from the menu.
                    </p>
                    {/* <div className=" mt-10 mb-20 flex flex-wrap items-center justify-center gap-10 px-5"> */}
                    <div className="mt-10 mb-10 flex  w-full flex-col flex-wrap items-center justify-center gap-5 bg-[#4d4d4d00]   md:flex-row md:gap-10   2xl:gap-20">
                        {TilesInfo.map((value, index) => (
                            <a
                                href={value.href}
                                key={index}
                                className={`TilesShadow flex max-h-[355px] max-w-[350px] cursor-pointer  flex-col items-center justify-center
        gap-5 rounded-2xl  border-2 border-solid border-[#EEEEEE]
        bg-white text-black no-underline 
        `}
                            >
                                <div className="w-20 md:w-28">
                                    <img
                                        loading="lazy"
                                        src={value.ImageLink}
                                        alt=""
                                        className="w-full"
                                    />
                                </div>
                                <div className="flex flex-col gap-[5px]">
                                    <h3 className="Blackfont text-center">
                                        {value.Heading}
                                    </h3>
                                    <p className="Regularfont w-56 text-center md:w-72 ">
                                        {value.Text}
                                    </p>
                                </div>
                            </a>
                        ))}
                        {/* <picture>
                <source media="(max-width:640px )" srcset="" />
                <img
                    loading="lazy"
                    className="absolute -left-32 -top-48 -z-10 hidden h-[380px] xl:inline-block"
                    src={BulbIcon}
                    alt=""
                />
            </picture> */}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default KnowledgeTestTile;
