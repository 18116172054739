import React from "react";
import { useJsApiLoader } from "@react-google-maps/api";

export const useMap = () => {
  const mapObj = useJsApiLoader({
    id: "vli-google-map",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  });
  return mapObj;
};

export const simulateMovement = () => {
  const startPoint = { lat: 43.65107, lng: -79.347015 }; // Starting point
  const endPoint = { lat: 43.6459, lng: -79.4111 }; // Ending point
  const numPoints = 20; // Number of points to simulate

  // Calculate increments for latitude and longitude for the outbound trip
  const latIncrementOutbound = (endPoint.lat - startPoint.lat) / numPoints;
  const lngIncrementOutbound = (endPoint.lng - startPoint.lng) / numPoints;

  // Generate points along the road for the outbound trip
  const pointsOutbound = [];
  for (let i = 0; i <= numPoints; i++) {
    const lat = startPoint.lat + i * latIncrementOutbound;
    const lng = startPoint.lng + i * lngIncrementOutbound;
    pointsOutbound.push({ lat, lng });
  }

  // Define intermediary points for the return trip
  const intermediaryPoints = [
    { lat: 43.655, lng: -79.36 },
    { lat: 43.655, lng: -79.37 },
    { lat: 43.655, lng: -79.38 },
    // Add more intermediary points as needed
  ];

  // Calculate increments for latitude and longitude for the return trip
  const latIncrementReturn = (startPoint.lat - endPoint.lat) / numPoints;
  const lngIncrementReturn = (startPoint.lng - endPoint.lng) / numPoints;

  // Generate points along the road for the return trip
  const pointsReturn = [];
  for (let i = 0; i <= numPoints; i++) {
    const lat = endPoint.lat + i * latIncrementReturn;
    const lng = endPoint.lng + i * lngIncrementReturn;
    pointsReturn.push({ lat, lng });
  }

  // Concatenate the points for both trips
  const points = pointsOutbound.concat(intermediaryPoints, pointsReturn);

  return points;
};

export const fixedSimulateMovement = () => {
  // Define waypoints along the route
  const waypoints = [
    { lat: 43.65107, lng: -79.347015 }, // Starting point
    { lat: 43.6515, lng: -79.3485 },
    { lat: 43.652, lng: -79.35 },
    { lat: 43.6525, lng: -79.3515 },
    { lat: 43.653, lng: -79.353 },
    { lat: 43.6535, lng: -79.3545 },
    { lat: 43.654, lng: -79.356 },
    { lat: 43.6545, lng: -79.3575 },
    { lat: 43.655, lng: -79.359 },
    { lat: 43.6555, lng: -79.3605 },
    { lat: 43.656, lng: -79.362 },
    { lat: 43.6565, lng: -79.3635 },
    { lat: 43.657, lng: -79.365 },
    { lat: 43.6575, lng: -79.3665 },
    { lat: 43.658, lng: -79.368 },
    { lat: 43.6585, lng: -79.3695 },
    { lat: 43.659, lng: -79.371 },
    { lat: 43.6595, lng: -79.3725 },
    { lat: 43.66, lng: -79.374 },
    { lat: 43.6605, lng: -79.3755 },
    { lat: 43.661, lng: -79.377 }, // Ending point
  ];

  return waypoints;
};
