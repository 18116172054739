import React from "react";
import { useSelector } from "react-redux";
import { BaseUrl } from "../../../../Actions/Base";

function ChatHeader({ active, currentSelected, online }) {
    const { UserInfo } = useSelector((state) => state.LoginSignupReducer);
    // const isOnline =
    //     offline &&
    //     online?.find((user) => user?.UserId === currentSelected?.UserId);

    const isOnline = online?.includes(currentSelected?.UserId);

    return (
        <div className="flex  gap-10 px-[2] lg:px-5">
            <div className="flex items-center gap-5">
                <div className="relative">
                    {currentSelected &&
                    currentSelected?.Instructor?.ProfileImage ? (
                        <img
                            className="rounded-full"
                            src={`${BaseUrl}/api/images/Instructors?url=${currentSelected?.Instructor?.ProfileImage}`}
                            alt=""
                            srcSet=""
                            width={80}
                            height={80}
                        />
                    ) : (
                        <img
                            src={require("../../assets/AvatarIcon.svg").default}
                            alt=""
                            srcSet=""
                            width={80}
                            height={80}
                        />
                    )}

                    {isOnline ? (
                        <div className="absolute bottom-3 right-0 z-10 h-4 w-4 rounded-full bg-[#22c55e]"></div>
                    ) : (
                        <div className="absolute bottom-3 right-0 z-10 h-4 w-4 rounded-full bg-[#c56922]"></div>
                    )}
                </div>
                <div className="flex flex-col gap-1">
                    <p className=" font-seoge-ui text-sm   text-[#4C34AD]">
                        {currentSelected?.FirstName} {currentSelected?.LastName}
                    </p>
                    <p className="font-seoge-ui text-sm font-black text-[#43425D]">
                        {active}
                    </p>
                </div>
            </div>
            {/* <div className="flex items-center gap-3">
                <div className="flex flex-col gap-1">
                    <p className="font-seoge-ui text-sm">Package Name </p>
                    <p className="font-seoge-ui text-sm font-black text-[#43425D]">
                        Automotive Course
                    </p>
                </div>
                <div className="border-r-1 h-[58px] border border-t-0 border-l-0 border-b-0 border-solid border-[#D6D6D6]" />

                <div className="flex flex-col gap-1">
                    <p className="font-seoge-ui text-sm">Driving </p>
                    <p className="font-seoge-ui text-sm font-black text-[#43425D]">
                        12 Hrs
                    </p>
                </div>

                <div className="border-r-1 h-[58px] border border-t-0 border-l-0 border-b-0 border-solid border-[#D6D6D6]" />

                <div className="flex flex-col gap-1">
                    <p className="font-seoge-ui text-sm">Online </p>
                    <p className="font-seoge-ui text-sm font-black text-[#43425D]">
                        12 Hrs
                    </p>
                </div>
                <div className="border-r-1 h-[58px] border border-t-0 border-l-0 border-b-0 border-solid border-[#D6D6D6]" />

                <div className="flex flex-col gap-1">
                    <p className="font-seoge-ui text-sm">Inclass </p>
                    <p className="font-seoge-ui text-sm font-black text-[#43425D]">
                        12 Hrs
                    </p>
                </div>
                <div className="border-r-1 h-[58px] border border-t-0 border-l-0 border-b-0 border-solid border-[#D6D6D6]" />
                <div className="flex flex-col gap-1">
                    <p className="font-seoge-ui text-sm">Status </p>
                    <p className="font-seoge-ui text-sm font-black text-[#43425D]">
                        IN Progress
                    </p>
                </div>
            </div>
            <div className=" mt-3 ">
                <img
                    className="cursor-pointer"
                    src={require("../../assets/close.svg").default}
                    alt=""
                    srcSet=""
                    width={19}
                    height={19}
                />
            </div> */}
        </div>
    );
}

export default ChatHeader;
