// import { useContext, useEffect } from 'react';
// import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

// const useBlocker = (blocker, when = true) => {
//   const { navigator } = useContext(NavigationContext);

//   useEffect(() => {
//     if (!when) return;

//     const push = navigator.push;
//     const replace = navigator.replace;

//     navigator.push = (...args) => {
//       const result = blocker();
//       if (result) push(...args);
//     };

//     navigator.replace = (...args) => {
//       const result = blocker();
//       if (result) replace(...args);
//     };

//     return () => {
//       navigator.push = push;
//       navigator.replace = replace;
//     };
//   }, [navigator, blocker, when]);
// }

// export default useBlocker;

import React, { useEffect } from "react";

const usePageUnloadWarning = (message) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = message; // Standard for most browsers
      return message; // Some browsers (older ones)
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [message]);
};

export default usePageUnloadWarning;
