import React from "react";
import { Link } from "react-router-dom";
import { GetLocalStorage } from "../../../../../../Helpers/LocalStorage/LocalStorage";
import "./Left.css";
import useAxios from "../../../../../../Helpers/CustomHooks/useAxios";
import { BaseUrl } from "../../../../../../Actions/Base";
import { useState } from "react";
import { useEffect } from "react";

const Left = ({ studentCount, data }) => {
  const [res, errors, loading] = useAxios("get", `/api/stats`);

  const [Stats, setStates] = useState();
  const InstructorId = GetLocalStorage("UserInfo")?.Instructor?.InstructorId;

  useEffect(() => {
    if (res?.data) setStates(res?.data);
  }, [res]);

  const cardClasses = `absolute bottom-0 left-0 h-[40%] xl:w-[170px] w-[230px]`
  const cardH4Classes = `Boldfont text-center text-6xs text-white`
  const cardP1Classes = `text-xs font-medium text-white sm:text-6xs font-segoe-ui`
  const cardP2Classes = `px-4 text-center text-8xs text-white`

  return (
    <>
      <div className="LeftCardsContainer ">
        <div className="">
          <Link
            to=""
            className="StaffCards background-image-11 relative    
                        
                        origin-top-left bg-white 
                        no-underline
                        transition duration-700
                       hover:scale-100  md:hover:scale-[1.09]

                        "
          >
            {/* <img className="w-[65px]"  alt="" /> absolute bottom-0 left-0 h-[40%] xl:w-[170px] w-[230px]*/}
            <div
              className={`${cardClasses}`}
              style={{ textAlign: "-webkit-center" }}
            >
              <div className="flex flex-col items-center">
                <h4 className={`${cardH4Classes}`}>
                  Students
                </h4>
                <p className={`${cardP1Classes}`}>
                  {studentCount}
                </p>
              </div>
              <p className={`${cardP2Classes}`}>
                10% More students have enrolled this month
              </p>
            </div>
            {/* <div className="absolute overlay-1 bg-gray-100 bottom-0 left-0 h-[50%] w-[230px]">
            </div> */}
          </Link>
          <Link
            to={`/teacher/UpcomingClasses/${InstructorId}`}
            className="StaffCards background-image-21 relative origin-top-left bg-white
                        no-underline
                        transition duration-700
                       hover:scale-100  md:hover:scale-[1.09]
                        "
          >
            <div
              className={`${cardClasses}`}
              style={{ textAlign: "-webkit-center" }}
            >
              <div className="flex flex-col items-center gap-8">
                <h4 className={`${cardH4Classes}`}>
                  Weekly Schedule
                </h4>

                <p className={`${cardP2Classes}`}>
                  10% More students have enrolled this month
                </p>
              </div>
            </div>
          </Link>

          <Link
            to=""
            className="StaffCards background-image-31 relative origin-top-left bg-white
                        no-underline
                        transition duration-700
                       hover:scale-100  md:hover:scale-[1.09]
                        "
          >
            <div
              className={`${cardClasses}`}
              style={{ textAlign: "-webkit-center" }}
            >
              <div className="flex flex-col items-center">
                <h4 className={`${cardH4Classes}`}>
                  Completed Courses
                </h4>
                <p className={cardP1Classes}>
                  0
                </p>
              </div>
              <p className={`${cardP2Classes}`}>
                You can view completed course and students reviews from here
              </p>
            </div>
          </Link>
          <Link
            to="/lessons"
            className="
                        StaffCards background-image-32
                       relative  no-underline
        transition duration-700 hover:origin-top-left 
                        hover:scale-100  md:hover:scale-[1.09]"
          >
            {/* <img className="w-[65px]"  alt="" /> */}
            <div
              className={`${cardClasses}`}
              style={{ textAlign: "-webkit-center" }}
            >
              <div className="flex flex-col items-center">
                <h4 className={`${cardH4Classes}`}>
                  Lessons
                </h4>
                <p className={`${cardP1Classes}`}>
                   {data?.length}
                </p>
              </div>
              <p className={`${cardP2Classes}`}>
                You can update the status of each class from here
              </p>
            </div>
            {/* <div className="absolute overlay-1 bg-gray-100 bottom-0 left-0 h-[50%] w-[230px]">
            </div> */}
          </Link>
        </div>
      </div>
    </>
  );
};

export default Left;
