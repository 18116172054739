import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LicenseWarn.css";
const LicenseWarn = ({
    setShowPopup,
    popupDetail,
    onCancel,
    onConfirm,
    type,
    PopupMessage,
}) => {
    const [profile, setProfile] = useState();
    // useEffect(() => {
    //     const ImageUrl = `${BaseUrl}/api/images/Instructors?url=${popupDetail?.Profile}`;
    //     setProfile(ImageUrl);
    // }, [popupDetail]);
    // console.log("profile", profile);
    const Navigate = useNavigate();
    const OnClick = () => Navigate("/instructors/list");
    return (
        <div className="fixed left-0 top-0 z-30 flex h-screen w-screen items-center justify-center bg-[#00000029]">
            <div className="relative flex aspect-[353_/_221] h-fit w-full max-w-[360px] flex-col  rounded-[20px] bg-white px-8 py-5">
                {/* <div className="mt-0 flex flex-col "> */}
                <h1 className="HeadingBorder mt-4 text-5xs font-normal ">
                    Warning
                </h1>
                <h2 className=" mb-10 text-center text-4xs font-normal ">
                    {PopupMessage}
                </h2>

                <div className="flex items-center justify-center ">
                    <button
                        type="button"
                        className="BrandingButton SemiBold w-fit cursor-pointer rounded-[14px] py-1 px-8 text-6xs"
                        onClick={() => setShowPopup(false)}
                    >
                        Close
                    </button>
                </div>
                {/* </div> */}
                <img
                    className="absolute right-3 top-[6px] cursor-pointer"
                    src={require("./Assets/Cross.svg").default}
                    alt=""
                    onClick={() => setShowPopup(false)}
                />
            </div>
        </div>
    );
};

export default LicenseWarn;
