import axios from "axios";
import { BaseUrl } from "../../Actions/Base";

const axiosInstance = axios.create({
  baseURL: BaseUrl,
  withCredentials: true,
});

export const verifingOtp = async (otp) => {
  try {
    const response = await axiosInstance.post("/api/verify-instructor-otp", {
      otp,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};
