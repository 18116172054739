import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BaseUrl } from "../../../Actions/Base";
import { DeleteStaffA } from "../../../Actions/StaffA";

import InstituteTemplate from "../../../Components/InstituteTemplate/InstituteTemplate";
import { InstructorRowTemplate } from "../../../Components/InstructorTemplate/InstructorTemplate";
import LoadingSpinner from "../../../Components/LoadingSpinner/LoadingSpinner";
import NoDataResponse from "../../../Components/NoDataResponse";
import useCheckLogin from "../../../Helpers/CustomHooks/CheckLogin";
import TeacherFilter from "../Instructors/Components/TeacherFilter";
import axios from "axios";
import "./StaffList.css";
import DeletePopup from "../../../Components/DeletePopup/DeletePopup";
import { GetInstituteStaffA } from "../../../Actions/InstructorA";

const StaffListing = () => {
    const { Staffs, loading } = useSelector(Store => Store.InstructorReducer);
    const [staffData, setStaffData] = useState([]);
    const [FilterOpen, setFilterOpen] = useState();
    const Navigate = useNavigate();
    const [MaxHeight, setMaxHeight] = useState("max-h-[0px]");
    const [sortByTime, setSortByTime] = useState("");
    const [sortBy, setSortBy] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [suspend, setSuspend] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [popupDetail, setPopupDetail] = useState({});
    const Dispatch = useDispatch();

    useCheckLogin(true, ["Institute"], ["Staff", "Admin"]);
    const OnClick = (e) => {
        e.stopPropagation();
        if (!FilterOpen) {
            setFilterOpen(true);
            setMaxHeight("max-h-[1000px]");
        } else if (FilterOpen) {
            setFilterOpen(false);
            setMaxHeight("max-h-[0px]");
        }
    };

    // const fetchData = async () => {
    //     const params = new URLSearchParams({
    //         sortByTime,
    //         sortBy,
    //         searchQuery,
    //         suspend,
    //     });

    //     const url = `${BaseUrl}/api/Institute/Staffs?${params}`;

    //     try {
    //         setLoading(true)
    //         const res = await axios.get(url);
    //         setStaffData(res.data);
    //         setLoading(false)
    //     } catch (err) {
    //         console.log(err);
    //         setLoading(false)

    //     }
    // };

    // useEffect(() => {
    //     const filters = ({
    //         sortByTime,
    //         sortBy,
    //         searchQuery,
    //         suspend,
    //     });
    //     fetchData();
    // }, [sortByTime, sortBy, searchQuery, suspend]);

    // useEffect(() => {
    //     let isMounted = true;
    //     fetchData();
    //     return () => {
    //         isMounted = false;
    //     };
    // }, [sortByTime, sortBy, searchQuery, suspend]);
    useEffect(() => {
        Dispatch(
            GetInstituteStaffA({
            sortByTime,
            sortBy,
            searchQuery,
            suspend,
          })
        );
      }, [Dispatch, sortByTime, sortBy, searchQuery, suspend]);

    const handleApplyClick = (filters) => {
        // Get filter values from state variables
        console.log("in handleSortBy:", filters);
        setSearchQuery(filters?.searchQuery);
        setSuspend(filters.suspend);
        setSortBy(filters.sortBy);
        setSortByTime(filters.sortByTime);

        // Fetch data based on the applied filters
        // Dispatch(
        //     GetInstituteInstructorsA({
        //         sortByTime: filters.sortByTime,
        //         sortBy: filters.sortBy,
        //         searchQuery: filters.searchQuery,
        //         suspend: filters.suspend,
        //     })
        // );
    };
    const handleDelete = (e) => {
        e.stopPropagation();
        setShowConfirmation(false); // Close the confirmation dialog

        // Dispatch the delete action with the InstructorId if needed
        Dispatch(DeleteStaffA(popupDetail?.StaffId))
            .then(() => {
                // Fetch the updated list of instructors after successful deletion
                // Dispatch(
                //     GetInstituteInstructorsA({
                //         sortByTime,
                //         sortBy,
                //         searchQuery,
                //         suspend,
                //     })
                // );
                GetInstituteStaffA();
            })
            .catch((error) => {
                console.error("Error deleting instructor:", error);
            });
    };

    return (
        <div className="Staff_table shadow instructorDiv my-16  w-11/12  rounded-[35px] py-10">
            <div className="flex w-full flex-col items-center px-6 sm:flex-row sm:px-8 md:px-10 lg:px-12 xl:px-14 2xl:px-16">
                <div className="Boldfont w-full text-center text-3xs  text-[#174A84] sm:w-1/5 sm:text-left md:w-1/4 lg:text-2xs xl:text-xs">
                    Staff
                </div>
                <div className="md::w-3/4 flex w-full justify-between gap-2 sm:w-4/5 sm:justify-end sm:gap-4 md:gap-6 lg:gap-8 xl:gap-10 2xl:gap-12">
                    <Link
                        to="/Admin/staff/add"
                        className="InstructorPageBtns px-4 "
                    >
                        Add a Member
                    </Link>
                    <span
                        className="InstructorPageBtns  relative"
                        onClick={OnClick}
                        onMouseEnter={() => setMaxHeight("max-h-[1000px]")}
                        onMouseLeave={() => setMaxHeight("max-h-[0px]")}
                    >
                        Filter Member
                        <TeacherFilter
                            handleApplyClick={handleApplyClick}
                            setSortBy={setSortBy}
                            setSortByTime={setSortByTime}
                            setSearchQuery={setSearchQuery}
                            setSuspend={setSuspend}
                            sortBy={sortBy}
                            sortByTime={sortByTime}
                            searchQuery={searchQuery}
                            suspend={suspend}
                            title={"Filter Staffs"}
                            MaxHeight={MaxHeight}
                            type="StaffList"
                        />
                        
                    </span>
                </div>
            </div>

            {loading ? (
                <LoadingSpinner />
            ) : Staffs?.length === 0 ? (
                <NoDataResponse
                    topText="Sorry, there are no Staff to show!"
                    bottomText="Admin/Institute can add Staff by click on 'Add a New Staff' button."
                />
            ) : (
                <div className=" px-5 py-10 overflow-x-auto ">
                    {showConfirmation && (
                        <DeletePopup
                            type={"Staff"}
                            onCancel={() => setShowConfirmation(false)}
                            onConfirm={handleDelete}
                            popupDetail={popupDetail}
                        />
                    )}
                    <table className="staff_table addCourse4Table instructorDiv ">
                        <thead>
                            <tr>
                                <th className="w-[10%]  ">Profile</th>
                                <th className="w-[10%] font-bold text-center">ID</th> 
                                <th className="w-[3%] font-bold ">Name</th>
                                <th className="w-[16.5%] font-bold text-center">Email</th>
                                <th className="w-[14%] font-bold text-center">Status</th>
                                <th className="w-[10%] font-bold text-center">Role</th>
                                <th className="w-[15%] font-bold text-center">Phone</th>
                                {!suspend && (
                                    <th colSpan={2} className="w-[3.5%] font-bold text-center">
                                        Actions
                                    </th>
                                )}
                                
                            </tr>
                        </thead>
                        <tbody>
                            {Staffs?.map((value, index) => {
                                console.log("Staffs", Staffs)
                                {
                                    /* console.log("value", value); */
                                }
                                const { Status } = value?.Staff;
                                // const PaymentOrClientsColor =
                                //   !ClientsTraining || ClientsTraining === 0
                                //     ? "text-[green] bg-[#aaffbe]"
                                //     : "text-white bg-[#b5aaff]";
                                const StatusColor = !Status
                                    ? "text-[green] bg-[#aaffbe]"
                                    : "text-white bg-[#b5aaff]";

                                const Edit = () => (
                                    <Link
                                        onClick={(e) => e.stopPropagation()}
                                        to={`/Admin/staff/update/${value?.Staff?.StaffId}`}
                                    >
                                        <button
                                            type="button"
                                            disabled={suspend && true}
                                            // class="focus:ring-blue-300 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-2 cursor-pointer rounded-lg border-0 bg-[#4c34ad] px-4 py-1 text-6xs font-medium text-white outline-none hover:bg-[#a1a3ef] focus:outline-none focus:ring-4 md:px-8 md:py-2"
                                            className="absolute  left-0 w-[80px] 2sm:w-auto cursor-pointer  rounded md:rounded-[10px] border-0 bg-[#4C34AD]  px-4 py-1 text-[13px] md:text-[15px] font-medium text-white md:mb-[2px] md:px-8 md:py-2 lg:px-9 lg:py-2 "
                                            >
                                            Edit
                                        </button>
                                    </Link>
                                );

                                const ImageUrl = `${BaseUrl}/api/images/Staff?url=${value?.Staff?.ProfileImage}`;
                                // const ImageUrl =null;

                                const OnRowClick = (e) => {
                                    Navigate(
                                        `/Admin/staff/detail/${value.Staff.StaffId}?suspend=${suspend}`
                                    );

                                    e.stopPropagation();
                                };

                                const Delete = () => {
                                    const handleDeleteClick = (e) => {
                                        e.stopPropagation();
                                        setPopupDetail({
                                            StaffId: value?.Staff?.StaffId,
                                            StaffName: `${value?.FirstName} ${value?.LastName}`,
                                            Profile: value?.Staff?.ProfileImage,
                                            Heading: "Delete Staff !!",
                                        });
                                        setShowConfirmation(true);
                                    };

                                    return (
                                        <>
                                            <button
                                                onClick={handleDeleteClick}
                                                type="button"
                                                disabled={suspend && true}
                                                // class="focus:ring-blue-300 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-2 cursor-pointer rounded-lg border-0 bg-[#4c34ad] px-4 py-1 text-6xs font-medium text-white outline-none hover:bg-[#a1a3ef] focus:outline-none focus:ring-4 md:px-8 md:py-2 lg:py-2"
                                                className="absolute  left-0 w-[80px] 2sm:w-auto  cursor-pointer rounded md:rounded-[10px] border-0 bg-[#4C34AD]  px-4 py-1 text-[13px] md:text-[15px] font-medium text-white md:mb-[2px] md:px-8 md:py-2 lg:px-6 lg:py-2 "
                                                >
                                                Delete
                                            </button>
                                        </>
                                    );
                                };

                                return (
                                    <InstructorRowTemplate
                                        key={value?.Staff?.StaffId}
                                        value={value}
                                        index={index}
                                        FirstName={value.FirstName}
                                        Email={value.Email}
                                        LastName={value.LastName}
                                        Status={!Status ? "free" : Status}
                                        createdAt={value?.Staff?.createdAt}
                                        // ClientsTraining={value.Instructor.ClientsTraining}
                                        // PaymentOrUnderClients={
                                        //   !StudentsTraining ? "free" : StudentsTraining
                                        // }
                                        suspend={suspend}
                                        ImgUrl={ImageUrl}
                                        // PaymentOrClientsColor={PaymentOrClientsColor}
                                        TrStyle={"cursor-pointer"}
                                        StatusColor={StatusColor}
                                        LastComp={Edit}
                                        OnClick={OnRowClick}
                                        Role={value?.Staff?.Role}
                                        type={"Staff"}
                                        phone={value?.Staff?.PhoneNumber}
                                        Delete={Delete}
                                        const
                                        StaffIdCardNo={
                                            value?.Staff?.StaffIdCardNo
                                        }
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

const StaffList = () => (
    <InstituteTemplate
        Element={StaffListing}
        bg="bg-[#F7F7F7] min-h-screen items-center"
    />
);

export default StaffList;
