import { Link } from 'react-router-dom'
import { BaseUrl } from '../../../../../Actions/Base'
import "./ForSubcategories.css"
import { useAuthContext } from '../../../../../context/authContext'
import { DeleteLocalStorage, SetLocalStorage } from '../../../../../Helpers/LocalStorage/LocalStorage'
import './Tiles.css'

export function Tiles({ Heading, Detail, IMG, LicenseTypeName, LicenseTypeImage, index, LicenseTypeId}) {
    console.log("locense type image in tiles is:",LicenseTypeImage)
    const handleSublicenseIndex =()=> {
        DeleteLocalStorage("SublicenseIndex")
        SetLocalStorage("SublicenseIndex", index)
    }
   

    let TilesStyle=`relative flex flex-col items-center bg-white   rounded-2xl duration-[400ms] cursor-pointer overflow-hidden
    w-fit          sm:w-1/2
    max-w-[350px]   sm:max-w-[250px]    md:max-w-[280px]    lg:max-w-[300px]    xl:max-w-[320px]
    min-w-[220px]       
    h-[400px]                                                                   xl:h-[460px] 2xl:h-[460px]
    px-0                                md:px-2                                 xl:px-3 
    pt-3                                                    lg:pt-5             xl:pt-6 
    pb-8                                                    lg:pb-14            xl:pb-20
    hover-on-child LicenseShadow`
    return <div onClick={handleSublicenseIndex} className={TilesStyle}><Link state={1} to={`/sublicense/allcourses/${LicenseTypeId}`} className={`no-underline	 text-black `}>

        {/* <img className='absolute left-0 top-4 ' src={IMG} alt="" /> */}
        <div className='flex'>
            <div className="indexDiv flex items-center">
                <p className="font-['segoe ui'] text-[20px] font-bold text-[#8C92C7] pl-[10px]">Level</p>
            </div>
            <div className='w-[48px] h-[48px] border-solid rounded-full border-3 border-[#A1A3EF] flex justify-center items-center ml-[-10px]'>
                <div className='w-[34px] h-[34px] rounded-full bg-[#A1A3EF] flex justify-center items-center'>
                    <p className="font-['segoe ui'] text-[20px] font-bold text-[#ffffff]">{index + 1}</p>
                </div>
            </div>
        </div>
        <span className='w-full'>
            <img className='w-[100%] ' src={`${BaseUrl}/api/SubLicenseTypeImage?url=${LicenseTypeImage}`} alt="img44" />
        </span>
        

       
        <span className='flex flex-col w-full items-center  '>
            <h3 className='text-3xs font-normal lg:text-xs text-center'>{Heading === "M, M1, or M2 with condition 1 (motor tricycle)" || Heading === "M, M1, or M2 with condition L (LSM/moped)" ? Heading : Heading + " License"}  </h3>
            <p className='text-6xs lg:text-4xs pt-2 text-center'>{Detail} </p>

        { console.log("Detail===>", Detail) }
           
            
        <span><p className='text-6xs lg:text-4xs text-center py-4'>Vehicles carrying passengers. </p></span>
        </span>

        {/* <div className="btnShadow relative z-10 h-9 w-9 md:h-12 md:w-12  cursor-pointer rounded-[24px] bg-[#A1A3EF]">
                  <img
                    className="hover: absolute top-[12px] left-[7px] md:left-[13px] md:top-[18px]   z-10" />
         </div> */}
       
        <div className='z-10 h-9 w-9 xl:h-12 xl:w-12 rounded-[24px] absolute right-3 bottom-3 bg-[#A1A3EF] cursor-pointer btnShadow'>
            <img
                className='z-10 absolute top-[12px] left-[7px] xl:left-[13px] xl:top-[18px]  hover:'
                src={require('../../../../LandingPage/LicenseTypes/Assets/Arrow Icon.svg').default}
                alt="" />
        </div>
    </Link>
    </div>
   

}