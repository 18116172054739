import React, { useEffect, useState } from "react";
import ImagePreviewer from "../../../../../Components/ImagePreviewer/ImagePreviewer";
import { DateInput } from "../../../../../Components/InputComps/DateComps";
import {
    Input,
    InputWithImage,
    SelectList,
    Phone,
    MultiSelectList,
} from "../../../../../Components/InputComps/InputComps";
import { CountryOptions, GenderOptions } from "../OptionsArr/OptionsArr";
import { City, Country, State } from "country-state-city";
import "./InstructorInfo.css";
import { LicenseDetails } from "./components/licenseDetails/LicenseDetails";
import { SpecialLicenseDetail } from "./components/specailLicenseDetails/SpecialLicenseDetail";
import DisplayLicenseDetails from "./components/displayLicenseDetails/DisplayLicenseDetails";
import useAxios from "../../../../../Helpers/CustomHooks/useAxios";
import { useParams } from "react-router-dom";
import DisplaySpecialLicenseDetails from "./components/displayLicenseDetails/DisplaySpecialLicenseDetails";
import LicenseImagePreviewer from "../../../../../Components/ImagePreviewer/LicenseImagePreviewer";
import LocationSuggestion from "./components/LocationSuggester/LocationSuggestion";
import SearchLocationByInput from "./components/GooglePlacesApi/SearchLocationByInput";
import { useRef } from "react";
import { set } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { GetSpecialities } from "../../../../../Actions/CategoryA";
import Multiselect from "multiselect-react-dropdown";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useTheme } from "@emotion/react";

const InstructorInfo = ({
    setLicenseImages,
    InstructorData,
    setInstructorData,
    Err,
    setErr,
    readOnly,
    updateOnly,
    LicenseImages,
    SLicenseImages,
    setSLicenseImages,
    setSpecialityName,
    SpecialityName,
    SInstructor,
}) => {
    // console.log("InstructorInfoData", InstructorData);
    const [InstructorLicenseDetails, setInstructorLicenseDetails] = useState(
        []
    );
    // console.log("InstructorLicenseDetails", InstructorLicenseDetails);

    const [SpecialLicenseDetails, setSpecialLicenseDetails] = useState([]);
    const [simpleLicense, setSimpleLicense] = useState([]);
    const [specialLicense, setSpecialLicense] = useState([]);
    const [add, setAdd] = useState("");
    // const InstructorId = InstructorData?.InstructorId;
    console.log("add", add);
    const { InstructorId } = useParams();
    const [selectedCountry, setSelectedCountry] = useState();
    const [cities, setCities] = useState([]);
    const [selectedProvince, setSelectedProvince] = useState();
    const [countries, setCountries] = useState([]);
    const [others, setOthers] = useState("");
    const [SelectedCoordinates, setSelectedCoordinates] = useState("");
    const [isHovered, setIsHovered] = useState(false);

    const [res, Errors] = useAxios(
        `get`,
        `/api/instructor/license/detail/${InstructorId}`
    );
    const Dispatch = useDispatch();
    useEffect(() => {
        let countries = Country.getAllCountries();
        setCountries(countries);
    }, []);
    // console.log(countries);
    const { Specialities } = useSelector((Store) => Store.SpecialitiesReducer);
    useEffect(() => {
        Dispatch(GetSpecialities());
    }, [Dispatch]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 4;

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    // const theme = useTheme();
    // function getStyles(name, SpecialityName, theme) {
    //     return {
    //         fontWeight:
    //             SpecialityName.indexOf(name) === -1
    //                 ? theme.typography.fontWeightRegular
    //                 : theme.typography.fontWeightMedium,
    //     };
    // }
    // console.log("specialitiesininstructor", Specialities);
    // const SpecialitiesOptions = () =>
    //     Specialities?.map((value) => value.SpecialityName);

    // const customStyles = {
    //     option: (provided, state) => ({
    //         ...provided,
    //         backgroundColor: state.isSelected ? "#f0f0f0" : "inherit", // Change the background color of selected options
    //     }),
    // };

    const ProvinceOptions = () => {
        let states = [];

        if (typeof InstructorData?.Country === "string") {
            let countries = Country?.getAllCountries();

            const country = countries?.find(
                (country) =>
                    country?.name.toLowerCase() ===
                    InstructorData?.Country?.toLowerCase()
            );

            if (country) {
                states = State?.getStatesOfCountry(country?.isoCode);
                // console.log("country", states);
            }
        }

        console.log("States", states);
        return states?.map((country) => (
            <option value={country.name} key={country.isoCode}>
                {country?.name}
            </option>
        ));
    };

    // const countryCodes = countries?.map((country) => (
    //     <option value={country.name} key={country.isoCode}>
    //         {country?.name}
    //     </option>
    // ));

    useEffect(() => {
        const selectedCountry = InstructorData?.Country;
        const selectedProvince = InstructorData?.Province;
        if (
            typeof selectedCountry === "string" &&
            typeof selectedProvince === "string"
        ) {
            const country = Country?.getAllCountries()?.find(
                (country) =>
                    country?.name.toLowerCase() ===
                    selectedCountry?.toLowerCase()
            );
            if (country) {
                const states = State?.getStatesOfCountry(country?.isoCode);
                const selectedProvinceData = states?.find(
                    (province) =>
                        province.name.toLowerCase() ===
                        selectedProvince?.toLowerCase()
                );
                if (selectedProvinceData) {
                    const citiesData = City.getCitiesOfState(
                        country?.isoCode,
                        selectedProvinceData?.isoCode
                    );
                    setCities(citiesData);
                }
            }
        }
    }, [InstructorData, selectedCountry, selectedProvince]);

    // console.log("cities", cities);
    const CityOptions = () => {
        return (
            cities &&
            cities?.map((city, index) => (
                <option value={city?.name} key={index}>
                    {city?.name}
                </option>
            ))
        );
    };

    const handleChange = (event) => {
        console.log("target", event);
        const {
            target: { value },
        } = event;
        setSpecialityName(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );
    };
    // useEffect(() => {
    //     navigator.geolocation.getCurrentPosition((pos) => {
    //         const { latitude, longitude } = pos.coords;
    //         console.log(latitude, longitude);
    //         const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
    //         fetch(url)
    //             .then((res) => res.json())
    //             .then((data) => setAdd(data.address));
    //     });
    // }, []);

    useEffect(() => {
        if (
            res &&
            res?.data?.InstructorLicenseDetail &&
            res?.data?.SpecialLicenseDetail
        ) {
            setSimpleLicense(res?.data.InstructorLicenseDetail);
            setInstructorLicenseDetails(res?.data.InstructorLicenseDetail);
            setSpecialLicenseDetails(res?.data.SpecialLicenseDetail);
            setSpecialLicense(res?.data.SpecialLicenseDetail);
        }
    }, [
        // readOnly,
        res,
        // setSimpleLicense,
        // setSpecialLicense,
        // InstructorLicenseDetails,
    ]);
    // console.log("SeelectedCountry", selectedCountry);

    const OnChange = (e, Name, file) => {
        // console.log("Name", Name);
        let Data =
            e?.target?.files?.length > 0
                ? e?.target?.files[0]
                : e?.target?.value;
        // console.log("Data", Data);
        let formattedData = Data;

        if (Name === "Gender") {
            if (Data === "Others" && others) {
                formattedData = others;
            }
        }
        // if (Name === "Country") {
        //     setSelectedCountry(e.target.value);
        // }
        // if (Name === "Province") {
        // setSelectedProvince(e.target.value);

        // setInstructorData((prevData) => ({
        //     ...prevData,
        //     Province: e.target.value,
        // }));
        // setSelectedProvince(e.target.value);
        // }
        if (Name === "PostalCode") {
            // Remove all non-alphanumeric characters from the input
            const cleaned = Data.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();

            // Ensure the length of the cleaned postal code is at most 6 alphanumeric characters
            let postalCode = cleaned.substring(0, 6);

            // Format the postal code as per the Canadian format: A1A-A1A
            postalCode = postalCode.replace(
                /^([A-Za-z]\d{3})([A-Za-z]\d{3})/,
                "$1-$2"
            );

            formattedData = postalCode;
        }

        if (
            Name === "PhoneNumber" ||
            (Name === "EmergencyPhoneNumber" && typeof Data === "string")
        ) {
            // Remove all non-numeric characters from the input
            const cleaned = Data.replace(/\D/g, "");

            // Check if the cleaned data consists only of the country code "1"
            if (cleaned === "1") {
                formattedData = ""; // If yes, set formattedData to an empty string
            } else {
                // Check if the phone number starts with +1-
                if (cleaned.startsWith("1")) {
                    // If it starts with +1-, remove the prefix and format the remaining digits as XXX-XXX-XXXX
                    const phoneNumber = cleaned
                        .substring(1)
                        .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
                    formattedData = "+1-" + phoneNumber;
                    formattedData = formattedData.substring(0, 15);
                } else {
                    // If it doesn't start with +1-, format the phone number as +1-XXX-XXX-XXXX
                    const formattedPhoneNumber = "+1-" + cleaned;
                    formattedData = formattedPhoneNumber.substring(0, 14);
                }
            }
        }

        // if (
        //     Name === "PhoneNumber" ||
        //     (Name === "EmergencyPhoneNumber" && typeof Data === "string")
        // ) {
        //     // Remove all non-numeric characters from the input
        //     const cleaned = Data.replace(/\D/g, "");

        //     // Format the phone number as per the Canadian format: XXX-XXX-XXXX
        //     const phoneNumber = cleaned.replace(
        //         /(\d{3})(\d{3})(\d{4})/,
        //         "$1-$2-$3"
        //     );

        //     formattedData = phoneNumber;
        //     formattedData = formattedData.substring(0, 11);
        // }

        setInstructorData({
            ...InstructorData,
            [Name]: formattedData,
        });
        if (Err?.[Name]) {
            delete Err[Name];
            setErr({ ...Err });
        }
    };
    // console.log("InstructorInfo", InstructorData);
    // console.log("SpecialityNameee", SpecialityName);

    useEffect(() => {
        setInstructorData({
            ...InstructorData,
            Speciality: SpecialityName,
            InstructorLicenseDetails: InstructorLicenseDetails,

            SpecialLicenseDetails: SpecialLicenseDetails,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [InstructorLicenseDetails, SpecialLicenseDetails, SpecialityName, res]);

    const handleSelect = (selectedList, selectedItem) => {
        // selectedItem.preventDefault()
        setInstructorData({ Speciality: selectedList });
    };

    const names = Specialities.map((value) => value.SpecialityName);
    // console.log("names", names);
    // console.log("names", Specialities);

    const handleRemove = (selectedList, removedItem) => {
        setInstructorData(selectedList);
    };

    return (
        <div className="w-full space-y-5 p-5">
            {/* personal details -- ONE
      <div className=" gradientt my-6 w-11/12 rounded-[35px] bg-white">
        <div className="mb-2 flex w-full flex-col flex-wrap justify-between gap-5 gap-y-6 p-16  sm:w-full md:w-[95%] md:flex-row lg:w-[100%]"> */}

            <h3
                style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                // className="text-xs font-bold text-[#4C34AD] sm:text-sm md:text-base lg:text-[29px] xl:text-lg 2xl:text-xl"
                className="2xl:text-md text-xs font-bold text-[#4C34AD] sm:text-sm md:text-base lg:text-[29px] xl:text-lg"
            >
                Personal Details{" "}
            </h3>
            <div
                className="  rounded-[23px] px-5 py-10 "
                style={{
                    boxShadow: "0 0 50px rgba(0, 0, 0, 0.3)",
                    background:
                        "transparent linear-gradient(90deg, #F9F9FF 0%, #DADCFF 100%) 0% 0% no-repeat padding-box",
                }}
            >
                <div className="flex flex-wrap gap-5 ">
                    <div className="flex w-full flex-col items-center justify-between  gap-10 lg:flex-row">
                        <div className="relative flex basis-full flex-col items-center gap-5 md:basis-1/2">
                            <ImagePreviewer
                                readOnly={readOnly}
                                Id="ProfileImage"
                                PreviewStyle="Instructor_ImagePreview"
                                BrowseBtnStyle="Instructor_BrowseBtn"
                                ContainerStyle="Teacher_PreviewContainer"
                                OnChange={(e) => OnChange(e, "ProfileImage")}
                                ImgUrl={InstructorData?.ProfileImage}
                                isInstructorProfile={true}
                            />
                        </div>
                        <div className="flex w-full basis-full flex-col gap-5  md:basis-1/2">
                            <Input
                                Label="First name *"
                                Placeholder="John"
                                Id="FirstName"
                                Err={Err?.FirstName}
                                State={InstructorData.FirstName}
                                readOnly={readOnly}
                                onChange={(e) => OnChange(e, "FirstName")}
                            />

                            <Input
                                readOnly={readOnly}
                                Label="Last name *"
                                Placeholder="Smith"
                                Id="LastName"
                                Err={Err?.LastName}
                                State={InstructorData.LastName}
                                onChange={(e) => OnChange(e, "LastName")}
                            />

                            <DateInput
                                instructor={true}
                                readOnly={readOnly}
                                style="w-full"
                                Id={"DOB"}
                                State={InstructorData.DOB}
                                onChange={(e) => OnChange(e, "DOB")}
                            />
                            {/* </div> */}

                            <SelectList
                                readOnly={readOnly}
                                Label="Gender *"
                                Id="Gender"
                                defaultValue=""
                                Text="Gender"
                                Err={Err?.Gender}
                                OptionsArr={GenderOptions}
                                State={InstructorData.Gender}
                                onChange={(e) => OnChange(e, "Gender")}
                            />
                            {InstructorData?.Gender === "Others" && (
                                <Input
                                    readOnly={readOnly}
                                    // Label="Gender *"
                                    Placeholder=""
                                    // Id="Gender"
                                    // Err={Err?.Gender}
                                    State={others}
                                    onChange={(e) => setOthers(e.target.value)}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* personal details -- TWO */}

            <h3
                style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                className="2xl:text-md text-xs font-bold text-[#4C34AD] sm:text-sm md:text-base lg:text-[29px] xl:text-lg"
            >
                Contact Details
            </h3>

            <div
                className=" flex flex-col gap-10 rounded-[23px] px-5 py-10"
                style={{
                    boxShadow: "0 0 50px rgba(0, 0, 0, 0.3)",

                    background:
                        "transparent linear-gradient(90deg, #F9F9FF 0%, #DADCFF 100%) 0% 0% no-repeat padding-box",
                }}
            >
                <div className="flex flex-col items-center justify-between gap-10 md:flex-row">
                    <div className="w-full md:basis-1/2 [&>*]:w-full">
                        <Input
                            readOnly={readOnly}
                            Label="Phone Number *"
                            Placeholder="123-456-7890"
                            Id="PhoneNumber"
                            Err={Err?.PhoneNumber}
                            State={InstructorData.PhoneNumber}
                            onChange={(e) => OnChange(e, "PhoneNumber")}
                        />
                    </div>
                    <div className="w-full md:basis-1/2 [&>*]:w-full">
                        <Input
                            readOnly={readOnly}
                            Label="Emergency Phone Number *"
                            Placeholder="123-456-7890"
                            Id="EmergencyPhoneNumber"
                            Err={Err?.EmergencyPhoneNumber}
                            State={InstructorData.EmergencyPhoneNumber}
                            onChange={(e) =>
                                OnChange(e, "EmergencyPhoneNumber")
                            }
                        />
                    </div>
                </div>
                <div className="w-full [&>*]:max-w-full">
                    <Input
                        readOnly={readOnly}
                        Label="Email Address *"
                        Placeholder="abc@gmail.com"
                        Id="Email"
                        Err={Err?.Email}
                        State={InstructorData.Email}
                        onChange={(e) => OnChange(e, "Email")}
                    />
                </div>
            </div>

            {/* ----  ADDRESS   ------- */}

            <h3
                style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                className="2xl:text-md text-xs font-bold text-[#4C34AD] sm:text-sm md:text-base lg:text-[29px] xl:text-lg"
            >
                Address
            </h3>

            <div
                className=" flex flex-col gap-10 rounded-[23px] px-5 py-10"
                style={{
                    boxShadow: "0 0 50px rgba(0, 0, 0, 0.3)",

                    background:
                        "transparent linear-gradient(90deg,  #F9F9FF 0%, #DADCFF 100%) 0% 0% no-repeat padding-box",
                }}
            >
                <div className="flex flex-col items-center justify-between gap-10 md:flex-row">
                    <div className="w-full md:basis-1/2 [&>*]:w-full">
                        {/* <Input
                            readOnly={readOnly}
                            Label="Country *"
                            Placeholder="Canada"
                            Id="Country"
                            Err={Err?.Province}
                            State={InstructorData.Country}
                            onChange={(e) => OnChange(e, "Country")}
                        /> */}
                        <SelectList
                            readOnly={readOnly}
                            Label="Country *"
                            Id="Country"
                            defaultValue=""
                            Text="Country"
                            Err={Err?.Province}
                            OptionsArr={CountryOptions}
                            State={InstructorData.Country}
                            onChange={(e) => OnChange(e, "Country")}
                        />
                    </div>
                    <div className="w-full md:basis-1/2 [&>*]:w-full">
                        <SelectList
                            readOnly={readOnly}
                            Label="Province / Territory *"
                            Id="Province"
                            defaultValue=""
                            Text="Province / Territory"
                            Err={Err?.Province}
                            OptionsArr={ProvinceOptions}
                            State={InstructorData?.Province}
                            onChange={(e) => OnChange(e, "Province")}
                        />
                    </div>
                </div>

                <div className="flex flex-col items-center justify-between gap-10 md:flex-row">
                    <div className="w-full md:basis-1/2 [&>*]:w-full [&>div>span>input]:shadow-none">
                        <SelectList
                            readOnly={readOnly}
                            Label="City *"
                            Id="City"
                            defaultValue=""
                            Text="City"
                            Err={Err?.City}
                            OptionsArr={CityOptions}
                            State={InstructorData?.City}
                            onChange={(e) => OnChange(e, "City")}
                        />
                    </div>
                    <div className="w-full md:basis-1/2 [&>*]:w-full">
                        <Input
                            readOnly={readOnly}
                            // Number="number"
                            Label="Postal Code *"
                            Placeholder="A1A 1A1"
                            Id="PostalCode"
                            Err={Err?.PostalCode}
                            State={InstructorData.PostalCode}
                            onChange={(e) => OnChange(e, "PostalCode")}
                        />
                    </div>
                </div>
                <div className="w-full [&>*]:max-w-full [&>div>span>input]:shadow-none">
                    <SearchLocationByInput
                        InstructorData={InstructorData}
                        setInstructorData={setInstructorData}
                        Err={Err?.Address}
                        setErr={setErr}
                        readOnly={readOnly}
                        updateOnly={updateOnly}
                        setSelectedCoordinates={setSelectedCoordinates}
                        Id="Address"
                    />
                    {/* <LocationSuggestion /> */}
                </div>
            </div>

            {/* ----  License Details   ------- */}

            {!readOnly && (
                <LicenseDetails
                    setInstructorLicenseDetails={setInstructorLicenseDetails}
                    InstructorLicenseDetails={InstructorLicenseDetails}
                    Err={Err}
                    setErr={setErr}
                    simpleLicense={simpleLicense}
                    updateOnly={updateOnly}
                    readOnly={readOnly}
                    InstructorData={InstructorData}
                    OnChange={OnChange}
                    setLicenseImages={setLicenseImages}
                    LicenseImages={LicenseImages}
                />
            )}
            {/* ----  License Attachables   ------- */}

            <h3
                style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                className="2xl:text-md text-xs font-bold text-[#4C34AD] sm:text-sm md:text-base lg:text-[29px] xl:text-lg"
            >
                {readOnly && "License Details"}
            </h3>

            <div
                className="rounded-[23px] "
                style={{
                    boxShadow: "0 0 50px rgba(0, 0, 0, 0.3)",

                    background:
                        "transparent linear-gradient(90deg,  #F9F9FF 0%, #DADCFF 100%) 0% 0% no-repeat padding-box",
                }}
            >
                {readOnly && (
                    <DisplayLicenseDetails simpleLicense={simpleLicense} />
                )}
                {/* <div className="flex flex-wrap items-center justify-between gap-16 px-5  py-8 lg:px-16">
                    <div className="Instructor-PreviewContainer-Container">
                        <h3 className="text-5xs font-normal md:text-4xs lg:text-3xs xl:text-2xs 2xl:w-[175px] 2xl:text-xs">
                            License 1 <br />
                            {simpleLicense && simpleLicense[0]?.LicenseNumber}
                        </h3>
                        <ImagePreviewer
                            LicenseDetail={true}
                            readOnly={readOnly}
                            Id="LicenseImage"
                            PreviewStyle="Instructor_ImagePreview"
                            BrowseBtnStyle="Instructor_BrowseBtn"
                            ContainerStyle="Instructor_PreviewContainer"
                            OnChange={(e) => OnChange(e, "LicenseImage")}
                            ImgUrl={InstructorData?.LicenseImage}
                        />
                    </div>
                    <div className="Instructor-PreviewContainer-Container">
                        <h3 className="text-5xs font-normal md:text-4xs lg:text-3xs xl:text-2xs 2xl:w-[175px] 2xl:text-xs">
                            License 2 <br />
                            {simpleLicense && simpleLicense[1]?.LicenseNumber}
                        </h3>
                        <ImagePreviewer
                            readOnly={readOnly}
                            Id="LicenseImageTwo"
                            PreviewStyle="Instructor_ImagePreview"
                            BrowseBtnStyle="Instructor_BrowseBtn"
                            ContainerStyle="Instructor_PreviewContainer"
                            OnChange={(e) => OnChange(e, "LicenseImageTwo")}
                            ImgUrl={InstructorData?.LicenseImageTwo}
                        />
                    </div>
                </div> */}
            </div>

            {/* ----  Special License Details   ------- */}

            {!readOnly && (
                <SpecialLicenseDetail
                    Err={Err}
                    setErr={setErr}
                    SpecialLicenseDetails={SpecialLicenseDetails}
                    setSpecialLicenseDetails={setSpecialLicenseDetails}
                    updateOnly={updateOnly}
                    OnChange={OnChange}
                    readOnly={readOnly}
                    InstructorData={InstructorData}
                    SLicenseImages={SLicenseImages}
                    setSLicenseImages={setSLicenseImages}
                />
            )}
            {/* ----  Special License Attachables   ------- */}

            <h3
                style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                className="2xl:text-md text-xs font-bold text-[#4C34AD] sm:text-sm md:text-base lg:text-[29px] xl:text-lg"
            >
                {readOnly && "Special License Attachables"}
            </h3>

            <div
                className="rounded-[23px] "
                style={{
                    boxShadow: "0 0 50px rgba(0, 0, 0, 0.3)",

                    background:
                        "transparent linear-gradient(90deg,  #F9F9FF 0%, #DADCFF 100%) 0% 0% no-repeat padding-box",
                }}
            >
                {readOnly && (
                    <DisplaySpecialLicenseDetails
                        specialLicense={specialLicense}
                    />
                )}

                {/* <div
                    className="flex flex-wrap items-center justify-between gap-16 rounded-[23px] px-5 py-10 lg:px-16"
                    // style={{
                    //     background:
                    //         "transparent linear-gradient(90deg, #FFFFFF 0%, #F6F6FF 24%, #D6D8FF 100%) 0% 0% no-repeat padding-box",
                    // }}
                >
                    <div className="Instructor-PreviewContainer-Container">
                        <h3 className="text-5xs font-normal md:text-4xs lg:text-3xs xl:text-2xs 2xl:w-[175px] 2xl:text-xs">
                            License 1 <br />
                            {specialLicense &&
                                specialLicense[1]?.SpecialLicenseNumber}
                        </h3>

                        <ImagePreviewer
                            readOnly={readOnly}
                            Id="SpecialLicenseImage"
                            PreviewStyle="Instructor_ImagePreview"
                            BrowseBtnStyle="Instructor_BrowseBtn"
                            ContainerStyle="Instructor_PreviewContainer"
                            OnChange={(e) => OnChange(e, "SpecialLicenseImage")}
                            ImgUrl={InstructorData?.SpecialLicenseImage}
                        />
                    </div>
                    <div className="Instructor-PreviewContainer-Container">
                        <h3 className="text-5xs font-normal md:text-4xs lg:text-3xs xl:text-2xs 2xl:w-[175px] 2xl:text-xs">
                            License 2 <br />
                            {specialLicense &&
                                specialLicense[1]?.SpecialLicenseNumber}
                        </h3>
                        <ImagePreviewer
                            readOnly={readOnly}
                            Id="SpecialLicenseImageTwo"
                            PreviewStyle="Instructor_ImagePreview"
                            BrowseBtnStyle="Instructor_BrowseBtn"
                            ContainerStyle="Instructor_PreviewContainer"
                            OnChange={(e) =>
                                OnChange(e, "SpecialLicenseImageTwo")
                            }
                            ImgUrl={InstructorData?.SpecialLicenseImageTwo}
                        />
                    </div>
                </div> */}
            </div>

            {/* ----  Other Attachments   ------- */}

            <h3
                style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                className="2xl:text-md text-xs font-bold text-[#4C34AD] sm:text-sm md:text-base lg:text-[29px] xl:text-lg"
            >
                Other Attachments
            </h3>

            <div
                className="flex items-center justify-start gap-16 rounded-[23px] px-5 py-10 lg:px-16"
                style={{
                    boxShadow: "0 0 50px rgba(0, 0, 0, 0.3)",

                    background:
                        "transparent linear-gradient(90deg, #F9F9FF 0%, #DADCFF 100%) 0% 0% no-repeat padding-box",
                }}
            >
                <div className="flex   w-full  justify-between gap-20 sm:flex-col md:flex-col lg:flex-row">
                    <div className="flex gap-5">
                        <h3 className="text-5xs font-normal md:text-4xs lg:text-3xs xl:text-2xs 2xl:w-[175px] 2xl:text-xs">
                            Trainer Permit
                        </h3>
                        <div
                        // onMouseEnter={() => setIsHovered(true)}
                        // onMouseLeave={() => setIsHovered(false)}
                        >
                            <ImagePreviewer
                                readOnly={readOnly}
                                Id="TrainerPermitImage"
                                PreviewStyle="Instructor_ImagePreview"
                                BrowseBtnStyle="Instructor_BrowseBtn"
                                ContainerStyle="Instructor_PreviewContainer"
                                OnChange={(e) =>
                                    OnChange(e, "TrainerPermitImage")
                                }
                                ImgUrl={InstructorData?.TrainerPermitImage}
                            />
                            {/* {isHovered && (
                                <ImagePreviewer
                                    // isHovered={isHovered}
                                    readOnly={readOnly}
                                    Id="TrainerPermitImage"
                                    PreviewStyle=""
                                    BrowseBtnStyle=""
                                    ContainerStyle=""
                                    OnChange={(e) =>
                                        OnChange(e, "TrainerPermitImage")
                                    }
                                    ImgUrl={InstructorData?.TrainerPermitImage}
                                />
                            )} */}
                        </div>
                    </div>
                    <div className="w-full md:basis-1/2 [&>*]:w-full">
                        {/* <MultiSelectList
                            readOnly={readOnly}
                            Label="Speciality *"
                            Id="LicenseType"
                            defaultValue=""
                            Text="License Type A,B,C,G"
                            Err={Err?.Speciality}
                            OptionsArr={SpecialitiesOptions}
                            State={InstructorData.Speciality}
                            onChange={(e) => OnChange(e, "Speciality")}
                        /> */}
                        <div className="flex flex-col ">
                            <label
                                htmlFor=""
                                className="text-3xs md:text-2xs xl:text-xs"
                            >
                                Speciality *
                            </label>
                            {Err && Err?.Speciality}
                            <FormControl
                                sx={{
                                    // m: 1,
                                    fontStyle: "normal",
                                    width: "100%",
                                    backgroundColor: "white",
                                    boxShadow:
                                        "4px 5px 6px rgba(0, 0, 0, 0.29)",
                                }}
                            >
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    disabled={readOnly}
                                    displayEmpty
                                    // value={SpecialityName}
                                    // value={
                                    //     typeof SpecialityName === "string"
                                    //         ? [SpecialityName] // Convert to array
                                    //         : SpecialityName
                                    //     // !Array.isArray(SpecialityName)
                                    //     //     ? alert("it is not an array")
                                    //     //     : [SpecialityName]
                                    // }
                                    value={
                                        Array.isArray(SpecialityName)
                                            ? SpecialityName
                                            : [SpecialityName]
                                    }
                                    onChange={handleChange}
                                    // renderValue={(selected) => {
                                    //     const selectedArray = Array.isArray(
                                    //         selected
                                    //     )
                                    //         ? selected
                                    //         : [selected];
                                    //     // if (selected?.length === 0) {
                                    //     //     return <em>License Types A B C</em>;
                                    //     // }
                                    //     if (selectedArray.length === 0) {
                                    //         return <em>License Types A B C</em>;
                                    //     }
                                    //     // console.log(
                                    //     //     "Selected insideMULTISELECT",
                                    //     //     selectedArray
                                    //     // );

                                    //     // console.log(
                                    //     //     "Selected insideMULTISELECT",
                                    //     //     typeof selectedArray
                                    //     // );

                                    //     return selectedArray.join(", ");
                                    //     // return selected?.join(", ");
                                    // }}
                                    renderValue={(selected) => {
                                        if (
                                            !Array.isArray(selected) ||
                                            selected?.length === 0
                                        ) {
                                            return <em>License Types A B C</em>;
                                        }

                                        return selected.join(", ");
                                    }}
                                    MenuProps={MenuProps}
                                    inputProps={{
                                        "aria-label": "Without label",
                                    }}
                                >
                                    <MenuItem disabled value="">
                                        <em sx={{ fontStyle: "normal" }}>
                                            License Types A B C
                                        </em>
                                    </MenuItem>
                                    {names &&
                                        names?.map((name) => (
                                            <MenuItem key={name} value={name}>
                                                <Checkbox
                                                    checked={
                                                        SpecialityName?.indexOf(
                                                            name
                                                        ) > -1
                                                    }
                                                />
                                                <ListItemText primary={name} />
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InstructorInfo;
