import React from "react";
import { CheckData } from "./Helper/CheckData";
import {
  ScrollElement,
  ScrollSingleElement,
} from "../../../../Helpers/ScrolltoElement";

const PaymentTop = ({
  setInstallment,
  Installment,
  Err,
  setErr,
  EnrollmentData,
  StudentImage,
  StudentImageCheck,
  setPaymentDetailPopup,
  PricesPlusTax,
  LatestLicense
}) => {
  console.log(Installment);
  const OnClick = (PaymentType) => {
    const Checked = CheckData(
      EnrollmentData,
      setErr,
      ScrollElement,
      StudentImage,
      LatestLicense,
      ScrollSingleElement,
      StudentImageCheck
    );
    console.log("check before");

    if (!Checked) {
      setInstallment(undefined);
      return;
    }
    console.log("check");
    setErr({ ...Err, PaymentTypeErr: undefined });
    setInstallment(PaymentType);
    setPaymentDetailPopup(true);
  };

  console.log("Error", Err);

  return (
    <div className=" flex w-full flex-col items-center gap-4 bg-[#F6F5F5] pt-4 pb-4">
      <span className=" flex flex-col gap-5">
        <h1
          className={`SemiBoldItalic m-0 text-center text-xs
                                text-black sm:text-base    md:text-lg              xl:text-[38px]`}
        >
          Choose how to pay
        </h1>
        <h4
          className={`text-center text-3xs font-normal
                                text-[#404040]                md:text-2xs lg:text-xs  xl:text-base`}
        >
          Your payment is encrypted
        </h4>
      </span>

      <span className="flex w-auto flex-col items-end lg:w-[488px]">
        <h5 className="m-0 w-full text-left font-normal text-[red]">
          {Err?.PaymentTypeErr ? Err?.PaymentTypeErr : ""}
        </h5>

        <span className="flex">
          <p className="text-5xs md:text-4xs xl:text-3xs">
            End-to-end encrypted
          </p>
          <img src={require("../Assets/LockIcon.svg").default} alt="" />
        </span>
        <div className="flex w-full flex-col items-center gap-5">
          <PayIn
            Data={`Pay Full Payment`}
            BorderColor={
              Installment === "Full"
                ? "border-[#A1A3EF] bg-[#cfd0f0]"
                : "border-[#707070]"
            }
            OnClick={() => OnClick("Full")}
          />
          {EnrollmentData?.Package &&
          EnrollmentData?.Package?.Installments > 1 ? (
            <PayIn
              Data={"Pay in Installments"}
              BorderColor={
                Installment === "Installments"
                  ? "border-[#A1A3EF] bg-[#cfd0f0]"
                  : "border-[#707070]"
              }
              OnClick={() => OnClick("Installments")}
            />
          ) : null}
        </div>
      </span>
    </div>
  );
};

export default PaymentTop;

function PayIn({ Data, OnClick, BorderColor }) {
  return (
    <div
      className={`flex justify-between gap-3 border-[2px]  py-5 pl-5 pr-3 sm:gap-5 md:gap-7 lg:gap-9 xl:gap-12 2xl:gap-14 ${BorderColor} w-full cursor-pointer  border-solid`}
      onClick={OnClick}
    >
      <h3 className="w-2/3 whitespace-nowrap text-4xs  font-normal text-[#404040] md:text-3xs lg:text-2xs xl:text-xs ">
        {Data}
      </h3>
      <span className="flex items-center gap-2 ">
        <span className="flex items-center gap-2 [&>*]:h-3 md:[&>*]:h-4 ">
          <img src={require("../Assets/JCBIconS.png")} alt="" />
          <img src={require("../Assets/VisaIconS.png")} alt="" />
          <img src={require("../Assets/CreditCardIconS.png")} alt="" />
          <img src={require("../Assets/DebitCardIconS.png")} alt="" />
          <img src={require("../Assets/PaypalIconS.png")} alt="" />
        </span>
        <img
          className="h-5 md:h-auto"
          src={require("../Assets/ArrowIcon.svg").default}
          alt=""
        />
      </span>
    </div>
  );
}
