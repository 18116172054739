import React, { Fragment } from "react";
import { quizData } from "./quizData";

function QuizeResults({ results, handleRetakeQuiz }) {
    const Iterations = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
    const totalQuestions = results.length;
    const attemptedQuestions = results.filter((item) => !item.notAttempted);
    // Calculate the number of correct answers
    const correctAnswersCount = attemptedQuestions.filter(
        (item) => item.isCorrect
    ).length;

    // Calculate the percentage of correct answers
    const totalAttemptedQuestions = attemptedQuestions.length;
    const percentageCorrect = (correctAnswersCount / totalQuestions) * 100;
    return (
        <div className="mx-auto mt-40 flex w-[80%] flex-col gap-10  ">
            <div className=" flex flex-col items-center  justify-center gap-20 lg:flex-row lg:gap-40">
                <div
                    className="   flex min-h-[431px] w-[300px] flex-col items-center justify-center gap-5 rounded-[17px] p-5 xs:w-[400px] sm:w-[450px] md:w-[600px]  lg:w-[500px] "
                    style={{
                        boxShadow: "0px 0px 22px #00000042",
                    }}
                >
                    <div className="flex flex-wrap items-center  justify-center gap-2">
                        {results?.map((item, index) => (
                            <div
                                className={`${
                                    item?.isCorrect === false
                                        ? "flex h-[52px] w-[53px] items-center justify-center rounded-[10px]  border border-solid border-[#FF7C7C] bg-[#FF7C7C]"
                                        : item?.isCorrect === true
                                        ? "flex h-[52px] w-[53px] items-center justify-center rounded-[10px]  border border-solid border-[#8DF4A9]  bg-[#8DF4A9]"
                                        : "flex h-[52px] w-[53px] items-center justify-center rounded-[10px] border border-solid border-[#A1A3EF] bg-[#A1A3EF]"
                                }`}
                            >
                                <h2 className="text-[25px] font-bold">
                                    {index + 1}
                                </h2>
                            </div>
                        ))}
                    </div>
                    <div className="mt-5">
                        <h2>
                            {`You scored ${percentageCorrect}% (${totalAttemptedQuestions} of ${totalQuestions} )`}
                        </h2>
                    </div>
                    <div className="  mt-10 flex flex-col gap-5 lg:flex-row ">
                        <div className="flex items-center justify-center gap-3">
                            <div className="h-[10px] w-[10px] rounded-full bg-[#8DF4A9]" />
                            <h2 className="text-[23px]">Correct</h2>
                        </div>

                        <div className="flex items-center justify-center gap-3">
                            <div className="h-[10px] w-[10px] rounded-full bg-[#FF7C7C]" />
                            <h2 className="text-[23px]">In-Correct</h2>
                        </div>

                        <div className="flex items-center justify-center gap-3">
                            <div className="h-[10px] w-[10px] rounded-full bg-[#D5D6FF]" />
                            <h2 className="text-[23px]">Not Answered</h2>
                        </div>
                    </div>

                    <div className="mt-10 flex items-center justify-center gap-2">
                        <div
                            // onClick={()=>}
                            className="bg-transparent  cursor-pointer rounded-[11px] border border-solid border-[#A1A3EF] py-2.5 px-10 text-center text-[22px] font-normal text-[#A1A3EF] shadow-lg transition duration-300 hover:bg-[#A1A3EF] hover:text-white hover:shadow-xl"
                        >
                            Back
                        </div>
                        <div
                            onClick={handleRetakeQuiz}
                            className=" cursor-pointer rounded-[11px] bg-[#A1A3EF] px-8 py-3 text-center text-[22px] font-normal text-white shadow-lg transition duration-300 hover:shadow-xl"
                        >
                            Retake
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-10  px-5 sm:w-[450px] md:w-[600px] lg:w-[500px]">
                    <h2 className="text-[38px]">
                        Hire an instructor for Class B licensing
                    </h2>
                    <p className="text-justify text-[24px]">
                        For expert guidance and a safe testing experience,
                        consider hiring a qualified instructor to conduct your
                        road test. Their expertise ensures you're well-prepared
                        and confident, maximizing your chances of success while
                        prioritizing safety.
                    </p>
                    <div
                        // onClick={() => Navigate("/get-ready/knowledge-test")}
                        class="inline-block w-fit  cursor-pointer rounded-[11px] bg-[#A1A3EF] py-3 px-5 text-right text-[22px] font-normal text-white shadow-lg transition duration-300 hover:shadow-xl"
                    >
                        Hire Instructor
                    </div>
                </div>
            </div>

            <div
                className="  mx-auto flex h-fit w-[80%] flex-col items-center justify-center gap-10 rounded-[17px] py-10 px-2 xs:px-5 sm:px-5 md:px-10  lg:px-20"
                style={{
                    boxShadow: "0px 0px 22px #00000042",
                }}
            >
                {results?.map((item) => {
                    if (item?.notAttempted && item?.notAttempted === true)
                        return null;
                    if (item?.isCorrect) return null;
                    return (
                        <Fragment>
                            <h2 className="w-full  text-[28px] text-[#161616]">
                                {item?.question}
                            </h2>
                            {/* <div className="mx-auto text-center">
                                <img
                                    loading="lazy"
                                    src={require("./Assets/Mask Group 232.png")}
                                    alt=""
                                    className="h-[273px] w-[200px]  xs:h-[273px] xs:w-[200px]   sm:h-[273px] sm:w-[372px] md:h-[273px] md:w-[372px]  lg:h-[273px] lg:w-[372px]   "
                                />
                            </div> */}
                            <div className="flex w-full flex-col gap-10">
                                <div
                                    className={
                                        "rounded-[17px] border border-solid border-[#8DF4A9] bg-[#D6FFE1] p-5"
                                    }
                                >
                                    <h2 className="text-[23px] text-[#161616]">
                                        {item?.correctOption}
                                    </h2>
                                </div>
                                <div
                                    className={
                                        "rounded-[17px] border border-solid border-[#FF7C7C]  bg-[#FFCBCB] p-5 "
                                    }
                                >
                                    <h2 className="text-[23px] text-[#161616]">
                                        {item?.selectedOption}
                                    </h2>
                                </div>
                            </div>
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
}

export default QuizeResults;
