import "./Conversation.css";
import { useSelector } from "react-redux";
import InstituteTemplate from "../../Components/InstituteTemplate/InstituteTemplate";
import ChatBody from "./components/ChatBody/ChatBody";
import ChatFilters from "./components/ChatFilters";
import ContactsList from "./components/ContactsList/ContactsList";
import { useEffect, useState } from "react";
import useCheckInboxLogin from "../../Helpers/CustomHooks/CheckInboxLogin";
import WelcomeChat from "./components/WelcomeChat/WelcomeChat";
import { SocketContext } from "../../App";
import { useContext } from "react";
import { SocketReducer } from "../../Reducers/SocketR";
import { io } from "socket.io-client";
import { BaseUrl } from "../../Actions/Base";
import axios from "axios";

const ConversationPage = () => {
    const storedActive = localStorage.getItem("activeButton") || "Students";
    const [active, setActive] = useState(storedActive);
    useCheckInboxLogin(true, ["Institute"], ["Staff", "Admin", "Instructor"]); 

    const { UserInfo } = useSelector((state) => state.LoginSignupReducer);
    const [currentSelected, setCurrentSelected] = useState(undefined); //if it's selected the  chatbody will be displayed otherwise it shows welcome screen
    const { SocketUserId } = useSelector((Store) => Store.SocketReducer);
    const [online, setOnline] = useState();
    const [offline, setOffline] = useState([]);
    const [lastseen, setLastseen] = useState();
    const [unreadMessages, setUnreadMessages] = useState({});

    const socket = useContext(SocketContext);
    useEffect(() => {
        localStorage.setItem("activeButton", active);
    }, [active]);
    const [search, setSearch] = useState("");
    const handleFilterChange = (filter) => {
        setCurrentSelected(undefined); //when user selecte this filter it will show the welcome screen untill user select the contact
        setActive(filter);
    };

    useEffect(() => {
        if (socket) {
            if (!socket.connected) {
                socket.connect();
            }

            // socket?.on("yes", (yes) => console.log("yesssssssss", yes));
            socket?.on("lastSeen", (lastseen) => {
                console.log("socket last seen", lastseen);
                setLastseen(lastseen);
            });

            socket?.on("online", (getOnline) => {
                setOnline(getOnline);
                console.log("socket online users", getOnline);
            });
            return () => {
                socket?.off("online");
                socket?.off("lastSeen");
            };
        }
    }, [socket, online, lastseen]);

    console.log("online users", online);

    useEffect(() => {
        return () => {
            if (socket) {
                socket.disconnect();
            }
        };
    }, []);

    const updateUnreadCount = (userId, count) => {
        setUnreadMessages((prevCounts) => ({
            ...prevCounts,
            [userId]: count,
        }));
    };

    const handleReadMessage = async (userId) => {
        try {
            // Send a request to mark messages as read for the specific user
            await axios.put(`${BaseUrl}/api/messages/mark-as-read`, {
                userId: userId,
            });

            // Update the unread message count for the specific user
            updateUnreadCount(userId, 0);
        } catch (error) {
            console.error("Error marking messages as read:", error);
        }
        // Reset  count for the user to 0
    };
    console.log("unreadMessages", unreadMessages);

    const backtoChatList = () => {
        setCurrentSelected(undefined);
    };

    return (
        <>
            <div className="hidden lg:flex flex-col lg:flex-row h-full lg:max-h-[91vh]  ">
                <div className="h-full lg:max-h-[91vh] w-full lg:w-[49%] 2xl:w-[45%] lg:border lg:border-l-0 lg:border-b-0 lg:border-t-0 lg:border-solid  lg:border-[#F1F1F3] ">
                    <div className="hidden lg:flex h-[106px]   w-full items-center    border lg:border-l-0  lg:border-t-0 lg:border-solid lg:border-[#F1F1F3] ">
                        <div className="ml-10 flex items-center gap-3">
                            <img
                                src={require("./assets/Search Icon.svg").default}
                                width={20}
                                height={20}
                                alt="avatar"
                            />
                            <form onSubmit={(e) => e.preventDefault()}>
                                <input
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    type="text"
                                    placeholder="Search Name…"
                                    className="bg-transparent w-full  border-none py-4 text-2xs shadow-none focus:outline-none"
                                />
                            </form>
                        </div>
                    </div>

                    <div className="lg:hidden h-[80px] flex w-full items-center justify-center mt-[15px]">
                        <div className="w-[300px] h-[40px] p-[20px] flex items-center justify-between border-[1px] border-solid border-[#F1F1F3]">
                        
                            <form onSubmit={(e) => e.preventDefault()}>
                                <input
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    type="text"
                                    placeholder="Search…"
                                    className="bg-transparent w-full  border-none h-[30px] text-2xs shadow-none focus:outline-none"
                                />
                            </form>
                            <img
                                src={require("./assets/Search Icon.svg").default}
                                width={20}
                                height={20}
                                alt="avatar"
                            />
                        </div>
                    </div>

                    <p className="lg:hidden pl-[30px] font-bold text-[24px] font-sans">Messages</p>
                    {/* <div className=" m-auto h-[106px]      border border-l-0  border-t-0 border-solid border-[#F1F1F3] "> */}
                    <ChatFilters
                        active={active}
                        handleFilterChange={handleFilterChange}
                    />
                    {/* </div> */}
                    <div className="max-h-[68vh] overflow-auto">
                        <ContactsList
                            online={online}
                            setCurrentSelected={setCurrentSelected}
                            active={active}
                            search={search}
                            unreadMessages={unreadMessages}
                            handleReadMessage={handleReadMessage}
                            lastseen={lastseen}
                        />
                    </div>
                </div>

                <div className="hidden lg:flex max-h-[91vh] w-full flex-col  ">
                    {UserInfo && currentSelected === undefined ? (
                        <div className="flex  items-center justify-center">
                            <WelcomeChat />
                        </div>
                    ) : (
                        <>
                            <ChatBody
                                currentSelected={currentSelected}
                                online={online}
                                active={active}
                                socket={socket}
                                unreadMessages={unreadMessages}
                                updateUnreadCount={updateUnreadCount}
                            />
                        </>
                    )}
                </div>
            </div>

            <div className="lg:hidden flex flex-col lg:flex-row h-full lg:max-h-[91vh]  ">
                { currentSelected === undefined && (
                    <div className="h-full lg:max-h-[91vh] w-full lg:w-[45%] lg:border lg:border-l-0 lg:border-b-0 lg:border-t-0 lg:border-solid  lg:border-[#F1F1F3] ">
                        <div className="hidden lg:flex h-[106px]   w-full items-center    border lg:border-l-0  lg:border-t-0 lg:border-solid lg:border-[#F1F1F3] ">
                            <div className="ml-10 flex items-center gap-3">
                                <img
                                    src={require("./assets/Search Icon.svg").default}
                                    width={20}
                                    height={20}
                                    alt="avatar"
                                />
                                <form onSubmit={(e) => e.preventDefault()}>
                                    <input
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        type="text"
                                        placeholder="Search Name…"
                                        className="bg-transparent w-full  border-none py-4 text-2xs shadow-none focus:outline-none"
                                    />
                                </form>
                            </div>
                        </div>

                        <div className="lg:hidden h-[80px] flex w-full items-center justify-center mt-[15px]">
                            <div className="w-[300px] h-[40px] p-[20px] flex items-center justify-between border-[1px] border-solid border-[#F1F1F3]">
                            
                                <form onSubmit={(e) => e.preventDefault()}>
                                    <input
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        type="text"
                                        placeholder="Search…"
                                        className="bg-transparent w-full  border-none h-[30px] text-2xs shadow-none focus:outline-none"
                                    />
                                </form>
                                <img
                                    src={require("./assets/Search Icon.svg").default}
                                    width={20}
                                    height={20}
                                    alt="avatar"
                                />
                            </div>
                        </div>

                        <p className="lg:hidden pl-[30px] font-bold text-[24px] font-sans">Messages</p>
                        {/* <div className=" m-auto h-[106px]      border border-l-0  border-t-0 border-solid border-[#F1F1F3] "> */}
                        <ChatFilters
                            active={active}
                            handleFilterChange={handleFilterChange}
                        />
                        {/* </div> */}
                        <div className="max-h-[68vh] overflow-auto">
                            <ContactsList
                                online={online}
                                setCurrentSelected={setCurrentSelected}
                                active={active}
                                search={search}
                                unreadMessages={unreadMessages}
                                handleReadMessage={handleReadMessage}
                                lastseen={lastseen}
                            />
                        </div>
                    </div>
                )}

                { currentSelected !== undefined && (
                    <ChatBody
                        currentSelected={currentSelected}
                        online={online}
                        active={active}
                        socket={socket}
                        unreadMessages={unreadMessages}
                        updateUnreadCount={updateUnreadCount}
                        backtoChatList={backtoChatList}
                    />
                )}
            </div>
        </>
    );
};

const Conversation = () => {
    return <InstituteTemplate Element={ConversationPage} />;
};

export default Conversation;
