import React from "react";
import { useParams } from "react-router-dom";
import TopSection from "../../../sublicenseAllCourses/components/top-section/TopSection";
import { VehicleAndLicenseTextdata } from "../../../../../dataString/dataForLicenseAndVehicles";

const ForNoSubcategory = ({ Type }) => {
  const { LicenseTypeId } = useParams();

  const TypeName = Type?.LicenseTypeName
    ? Type?.LicenseTypeName
    : Type?.VehicleTypeName;
  let headingText;
  let subHeading;
  let headingForListing;
  let subHeadingForListing;
  console.log("TypeName===>", TypeName);
  switch (TypeName) {
    case "L":
      headingText = VehicleAndLicenseTextdata?.vehicleTypeL?.headingText;
      subHeading = VehicleAndLicenseTextdata?.vehicleTypeL?.subHeading;
      headingForListing =
        VehicleAndLicenseTextdata?.vehicleTypeL?.headingForListing;
      subHeadingForListing =
        VehicleAndLicenseTextdata?.vehicleTypeL?.subHeadingForListing;
      break;
    case "M":
        headingText = VehicleAndLicenseTextdata?.vehicleTypeM?.headingText;
        subHeading = VehicleAndLicenseTextdata?.vehicleTypeM?.subHeading;
        headingForListing =
          VehicleAndLicenseTextdata?.vehicleTypeM?.headingForListing;
        subHeadingForListing =
          VehicleAndLicenseTextdata?.vehicleTypeM?.subHeadingForListing;
      break;
    case "N":
        headingText = VehicleAndLicenseTextdata?.vehicleTyeN?.headingText;
        subHeading = VehicleAndLicenseTextdata?.vehicleTyeN?.subHeading;
        headingForListing =
          VehicleAndLicenseTextdata?.vehicleTyeN?.headingForListing;
        subHeadingForListing =
          VehicleAndLicenseTextdata?.vehicleTyeN?.subHeadingForListing;
      break;
    case "B":
      headingText =
        "B Heading Vehicle Learning Innovation Offers A-Z Driving Courses for all Vehicles";
      subHeading =
        "B Para Discover comprehensive A-Z driving courses for all vehicle types at Vehicle Learning Innovation, your centralized hub for mastering the road.";
      break;
    case "C":
      headingText =
        "C Heading Vehicle Learning Innovation Offers A-Z Driving Courses for all Vehicles";
      subHeading =
        "C Para Discover comprehensive A-Z driving courses for all vehicle types at Vehicle Learning Innovation, your centralized hub for mastering the road.";
      break;
    case "T":
        headingText = VehicleAndLicenseTextdata?.vehicleTypeT?.headingText;
        subHeading = VehicleAndLicenseTextdata?.vehicleTypeT?.subHeading;
        headingForListing =
          VehicleAndLicenseTextdata?.vehicleTypeT?.headingForListing;
        subHeadingForListing =
          VehicleAndLicenseTextdata?.vehicleTypeT?.subHeadingForListing;
      break;
    case "Industrial Machinery":
      headingText =
        "Industrial Machinery Heading Vehicle Learning Innovation Offers A-Z Driving Courses for all Vehicles";
      subHeading =
        "Industrial Machinery Para Discover comprehensive A-Z driving courses for all vehicle types at Vehicle Learning Innovation, your centralized hub for mastering the road.";
      break;
    default:
      headingText =
        "Heading Vehicle Learning Innovation Offers A-Z Driving Courses for all Vehicles";
      subHeading =
        "Para Discover comprehensive A-Z driving courses for all vehicle types at Vehicle Learning Innovation, your centralized hub for mastering the road.";
      break;
  }

  const HeadingText =
    " Vehicle Learning Innovation Offers A-Z Driving Courses for all Vehicles";
  const paraText =
    " Discover comprehensive A-Z driving courses for all vehicle types at Vehicle Learning Innovation, your centralized hub for mastering the road.";
  return (
    <>
      <TopSection headingText={headingText} paraText={subHeading} />

      <div className="flex flex-col items-center bg-backgroundWhiteColor">
        <div className="flex w-full flex-col items-center  gap-2 bg-backgroundWhiteColor py-5">
          <div className="flex w-full justify-center">
            <div className="w-full">
            {headingForListing && (
              <div className="w-full content-center">
                <h2 className="Regularfont mx-auto w-[90%] font-black font-segoe-ui text-center text-4xs text-black sm:w-[90%] md:w-[80%] md:text-3xs lg:text-2xs xl:text-base pb-5">
                  {headingForListing && headingForListing}
                </h2>

                <p className="Regularfont mx-auto w-[90%] font-medium font-segoe-ui text-center text-4xs text-black sm:w-[90%] md:w-[80%]  md:text-3xs lg:text-2xs xl:text-base">
                   {subHeadingForListing && subHeadingForListing}
                </p>
              </div>
            )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForNoSubcategory;
