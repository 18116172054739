import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  GetStatusHoldStudentsA,
  GetStatusInProgressStudentsA,
  GetStudentsA,
} from "../../../Actions/StudentA";
import InstituteTemplate from "../../../Components/InstituteTemplate/InstituteTemplate";
import { InstructorRowTemplate } from "../../../Components/InstructorTemplate/InstructorTemplate";
import LoadingSpinner from "../../../Components/LoadingSpinner/LoadingSpinner";
import NoDataResponse from "../../../Components/NoDataResponse";
import useCheckLogin from "../../../Helpers/CustomHooks/CheckLogin";
import StudentFilter from "./Components/StudentFilter";
import StudentTable from "./Components/StudentTable";
import { StudentClassesContext } from "../../../context/StudentClassesContext";

const StudentsListChild = () => {
  const { Students, loading, StatusInProgressStudents, StatusHoldStudents } =
    useSelector((Store) => Store?.StudentReducer);
  const [MaxHeight, setMaxHeight] = useState("max-h-[0px]");
  const Dispatch = useDispatch();

  useEffect(() => {
    Dispatch(GetStudentsA());
    Dispatch(GetStatusInProgressStudentsA());
    Dispatch(GetStatusHoldStudentsA());
  }, [Dispatch]);
  useCheckLogin(true, ["Institute"], ["Staff", "Admin"]);

  console.log("StudentsStudentsStudents", Students);
  const { studentClassesScheduleAppointments, setStudentClassesScheduleAppointments } = useContext(StudentClassesContext);

  useEffect(() => {
        setStudentClassesScheduleAppointments([]); // Clear the state when leaving the route
  }, []);
  // console.log("Students", Students);
  // console.log("StatusInProgressStudents", StatusInProgressStudents);
  // console.log("StatusInProgressStudents", StatusHoldStudents);

  return (
    <div className=" mt-4 w-11/12">
      {/* heading */}
      {/* <div className="text-lg font-black text-[#4C34AD]">Students</div> */}
      <h3
        style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
        className="text-xs font-bold text-[#4C34AD] sm:text-sm md:text-base lg:text-[29px] xl:text-lg 2xl:text-xl"
      >
        Students
      </h3>
      <div className="mt-6  text-2xs font-medium  text-[#4C34AD]  md:text-sm">
        New Enrollments
      </div>
      {/* student listing table start */}
      <div
        className="mt-6 overflow-x-scroll w-full rounded-[35px] bg-white/0 px-6 pt-12 pb-8 shadow"
        style={{
          background:
            "transparent linear-gradient(100deg, #FFFFFF 40%, #F6F6FF 54%, #D6D8FF 100%) 0% 0% no-repeat padding-box",
          borderRadius: "23px;",
        }}
      >
        {loading ? (
          <LoadingSpinner />
        ) : Students.length === 0 ? (
          <NoDataResponse
            topText="Sorry, there are not students to show!"
            bottomText="No students enrolled yet"
          />
        ) : (
          <StudentTable Students={Students} />
        )}
      </div>
      {/* student listing table end */}

      {StatusInProgressStudents.length === 0 ? null : (
        <>
          <div className="">
            <div className="mt-10">
              <div className="mt-6  text-2xs font-medium  text-[#4C34AD]  md:text-sm">
                Enrollment Completed Students
              </div>
            </div>

            {/*Insructor assigned student listing table start */}
            <div
              className="mt-6 w-full overflow-x-scroll rounded-[35px] bg-white/0 px-6 pt-12 pb-8 shadow"
              style={{
                background:
                  "transparent linear-gradient(100deg, #FFFFFF 40%, #F6F6FF 54%, #D6D8FF 100%) 0% 0% no-repeat padding-box",
                borderRadius: "23px;",
              }}
            >
              {loading ? (
                <LoadingSpinner />
              ) : StatusInProgressStudents.length === 0 ? (
                <NoDataResponse
                  topText="Sorry, there are not students to show!"
                  bottomText="No students enrolled yet"
                />
              ) : (
                <StudentTable
                  filtered={true}
                  Students={StatusInProgressStudents}
                />
              )}
            </div>
          </div>
        </>
      )}

      {/*Insructor assigned student listing table start */}

      {StatusHoldStudents.length === 0 ? null : (
        <>
          <div>
            <div className="mt-10">
              <div className="mt-6  text-2xs font-medium  text-[#4C34AD]  md:text-sm">
                Course Enrollment Status On Hold Students
              </div>
            </div>

            {/*Insructor assigned student listing table start */}
            <div
              className="mt-6 w-full overflow-x-scroll rounded-[35px] bg-white/0 px-6 pt-12 pb-8 shadow"
              style={{
                background:
                  "transparent linear-gradient(100deg, #FFFFFF 40%, #F6F6FF 54%, #D6D8FF 100%) 0% 0% no-repeat padding-box",
                borderRadius: "23px;",
              }}
            >
              {loading ? (
                <LoadingSpinner />
              ) : StatusHoldStudents.length === 0 ? (
                <NoDataResponse
                  topText="Sorry, there are not students to show!"
                  bottomText="No students enrolled yet"
                />
              ) : (
                <StudentTable filtered={true} Students={StatusHoldStudents} />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const StudentsList = () => (
  <InstituteTemplate
    Element={StudentsListChild}
    bg="bg-[#F7F7F7] min-h-screen items-center"
  />
);

export { StudentsList, StudentsListChild };
