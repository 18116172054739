import React, { useState, useEffect } from "react";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

function BottomCarIcon({setClassTimingDialog, setBottomCarMinimizingIcon}) {

    const handleIconClick = () =>{
        setClassTimingDialog(true)
        setBottomCarMinimizingIcon(false)
      }

  return (
    <div className="w-full fixed lg:hidden  bottom-0 left-0 ">
        <div className="w-[56px] h-[56px] m-[15px] rounded-full bg-[#4C34AD] flex justify-center items-center"
        onClick={handleIconClick}>
            <DirectionsCarIcon sx={{ color: '#FFFFFF', fontSize: 35 }} />            
        </div>
    </div>
  );
}

export default BottomCarIcon;
