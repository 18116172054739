import React, { useState } from 'react';
import './Stars.css';

const Stars = ({rating, onRatingChange}) => {
  const [hover, setHover] = useState(0);

  console.log("rat", rating)

  return (
    <div className="rating-container">
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <button
            type="button"
            key={index}
            className={index <= (hover || rating) ? "on" : "off"}
            onClick={() => onRatingChange(index)}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(rating)}
          >
            <span className="star">&#9733;</span>
          </button>
        );
      })}
    </div>
  );
};

export default Stars;
