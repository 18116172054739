import dayjs from 'dayjs';
import { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { GetEnrollCourseInfo } from '../../../Actions/CourseA';
import { useAuthContext } from '../../../context/authContext';

export const usePageLoadCheckers = (location, EnrollmentData, setEnrollmentData) => {
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const [Loaded, setLoaded] = useState();
    const { UserInfo } = useSelector((Store) => Store.LoginSignupReducer);
    // const { EnrollCourseInfo } = useSelector((Store) => Store.CourseReducer)

    // This would navigate user to home if user tries to enter previous url.
    useEffect(() => {
        if (!location?.state?.InstituteCourseId && Loaded === false) {
            Navigate('/')
        } else {
            setLoaded(true)
        }
    }, [location, Navigate, Loaded])


    
    

   
    //Course Information Api call!
    //This API should be called when a user enrolls in a course.
    useEffect(() => {
        if (location?.state?.InstituteCourseId && Loaded)
            Dispatch(GetEnrollCourseInfo(location?.state?.InstituteCourseId))
    }, [location, Loaded])




    // This would fill all the fields with information previously enterd by user, automatically, 
    // when page is loaded
//     useEffect(() => {
//         if (UserInfo) {
//             const WriteableUserInfo = JSON.parse(JSON.stringify(UserInfo))
//             if (EnrollmentData?.StudentData?.FirstName === "")
//                 setEnrollmentData({
//                     ...EnrollmentData, StudentData: {
//                         ...EnrollmentData.StudentData, ...WriteableUserInfo, ...WriteableUserInfo?.StudentInfos,
//                         DOB: dayjs(new Date(WriteableUserInfo?.StudentInfos?.DOB)).format('MM-DD-YYYY')
//                     }
//                 })


//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [EnrollmentData, UserInfo])

useEffect(() => {
    if (UserInfo) {
        const WriteableUserInfo = JSON.parse(JSON.stringify(UserInfo))
        console.log("WriteableUserInfo", WriteableUserInfo);
        if (EnrollmentData?.StudentData?.FirstName === "")
        setEnrollmentData(prevEnrollmentData => ({
            ...prevEnrollmentData,
            StudentData: {
                ...prevEnrollmentData.StudentData,
                FirstName: WriteableUserInfo.FirstName,
                LastName: WriteableUserInfo.LastName,
                Email: WriteableUserInfo.Email,
                PhoneNumber: WriteableUserInfo.PhoneNumber,
                Country: WriteableUserInfo.StudentInfo?.Country,
                PostalCode: WriteableUserInfo.StudentInfo?.PostalCode,
                Province: WriteableUserInfo.StudentInfo?.Province,
                City: WriteableUserInfo.StudentInfo?.City,
                Gender: WriteableUserInfo.StudentInfo?.Gender,
                // ...WriteableUserInfo.StudentInfo, // Merge StudentInfo fields
                DOB: dayjs(new Date(WriteableUserInfo.StudentInfo?.DOB)).format('YYYY-MM-DD') // Format DOB
            }
        }));


        
        
       // Log which fields are disabled or enabled
    // const disabledFields = Object.entries(EnrollmentData.StudentData).filter(([key, value]) => value === "" && inputsDisabled);
    // const enabledFields = Object.entries(EnrollmentData.StudentData).filter(([key, value]) => value !== "" && !inputsDisabled);
    // console.log("Disabled fields:", disabledFields.map(([key, value]) => ({ state: "inputsDisabled", fieldName: key, fieldValue: value })));
    // console.log("Enabled fields:", enabledFields.map(([key, value]) => ({ state: "inputsEnabled", fieldName: key, fieldValue: value })));
       


    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [EnrollmentData, UserInfo])



}