import React, {useEffect } from "react";
import { Link } from "react-router-dom";
import { GetLocalStorage } from "../../../../../../Helpers/LocalStorage/LocalStorage";
import "./RightCards.css";
import PieDonutChart from "../../../../../../Components/Charts/PieDonutChart";
import { useDispatch, useSelector } from "react-redux";
import { InstituteCoursesCountA } from "../../../../../../Actions/CourseA";

const RightCards = () => {
  const { CoursesCount } = useSelector((Store) => Store.CourseReducer);

  const Dispatch = useDispatch();
  useEffect(() => {
    Dispatch(InstituteCoursesCountA());
  }, [Dispatch]);

  console.log()

  let AddCourse = `/addcourse`;
  let AdminCourse = `/admin/course/add`;
  let CourseRoute =
    GetLocalStorage("UserInfo").User === "Admin"
      ? AdminCourse
      : GetLocalStorage("UserInfo").User === "Institute"
      ? AddCourse
      : null;

  
  const approvedCourses = CoursesCount?.ApprovedCourses;
  const underRevisionCourses = CoursesCount?.InProgress;
  const rejectedCourses = CoursesCount?.RejectedCourses;
  const totalCourses = approvedCourses + underRevisionCourses + rejectedCourses;

  const getPercentage = (value) => {
    return Math.round((value / totalCourses ) * 100);
  };

  const approvedPercentage = getPercentage(approvedCourses);
  const underRevisionPercentage = getPercentage(underRevisionCourses);
  const rejectedPercentage = getPercentage(rejectedCourses);

  const series = [
    approvedPercentage,
    underRevisionPercentage,
    rejectedPercentage,
  ];
  const labels = ["Approved Courses ", "Under Revision", "Rejected"];
  const colors = ["#AEB0FF", "#7F82F9", "#B05AC4"];

  return (
    <>
      {GetLocalStorage("UserInfo").User === "Admin" ? (
        <div className="RightCardsContainer">
          <div>
            <div className="RightCardHeading">Add a New Course</div>
            <div>
              <p>
              Want to publish a Vehicle Learning course? click on Create Course
              to publishss
              </p>
              <Link className="RightCardLink" to={CourseRoute}>
                <span className="text-6xs">Create Course</span>
                <span className="Img">
                  <img src={require("../Assets/Add.svg").default} alt="" />
                </span>
              </Link>
            </div>
          </div>

          <div>
            <div className="RightCardHeading">Add a New E-Book</div>
            <div>
              Want to publish an E-Book to Vehicle Learning innovation click on
              Create Course to publish
              <Link className="RightCardLink" to={"/admin/Book/add"}>
                <span className="text-6xs">Publish e-book</span>
                <span className="Img">
                  <img src={require("../Assets/Add.svg").default} alt="" />
                </span>
              </Link>
            </div>
          </div>
        </div>
      ) : null}

      {GetLocalStorage("UserInfo").InstituteUserType === "Admin" || GetLocalStorage("UserInfo").InstituteUserType === "Staff" ? (
        <>
          <div className="md:min-h-[342.4px] md:h-[342.4px] h-auto self-center shadow w-[332px] md:w-[380px]   rounded-[15px] border-[1px] border-solid border-[#D4D4D4] bg-white px-[15px] pt-[20px] pb-[20px] drop-shadow-[0px_2px_3px_rgba(0,0,0,0.04)] xs:self-center sm:self-center min-w-[320px] md:min-w-[380px] md:max-w-[400px]">
            <h3
              style={{
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
              className="heading_2 font-bold text-[#4C34AD] "
            >
              Course Status
            </h3>

            <div className="flex w-full justify-center py-2">
              <div className="  flex items-center justify-center  ">
                {totalCourses === 0 ? (
                  <div className="circle-outer flex h-[150px] w-[150px] items-center justify-center rounded-[50%] bg-[#26248D]">
                    <div className="circle-inner h-[105px] w-[105px] rounded-[50%] bg-white"></div>
                  </div>
                ) : (
                  <PieDonutChart
                    labels={labels}
                    series={series}
                    colors={colors}
                  />
                )}
              </div>
            </div>

            <div className="flex w-full justify-center gap-5">
              <table className="border-spacing-2">
                <tr className="">
                  <td className="pr-10 text-end text-6xs 3xl:4xs">Approved Courses</td>
                  <td className="rounded-[10px] bg-[#AEB0FF] px-12 py-1 text-6xs 3xl:4xs">
                    {approvedCourses}
                  </td>
                </tr>
                <tr className="py-5" style={{ margin: "2rem" }}>
                  <td className="pr-10 text-end text-6xs 3xl:4xs">Under Revision</td>
                  <td className="rounded-[10px] bg-[#7F82F9] text-6xs 3xl:4xs  px-12 py-1">
                    {underRevisionCourses}
                  </td>
                </tr>
                <tr className="">
                  <td className="pr-10 text-end text-6xs 3xl:4xs">Rejected</td>
                  <td className="rounded-[10px] bg-[#B05AC4]  px-12 py-1 text-6xs 3xl:4xs">
                    {rejectedCourses}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default RightCards;
