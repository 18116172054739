import React, { useEffect, useState } from "react";
import InstituteTemplate from "../../Components/InstituteTemplate/InstituteTemplate";
import useCheckLogin from "../../Helpers/CustomHooks/CheckLogin";
import CoursePackaging from "./Components/Course Packaging/coursePackaging";
import StepsIndicator from "./Components/Steps Indicator/Steps Indicator";
import CourseSelection from "./Components/course Selection/CourseSelection";
import CourseDetails from "./Components/CourseDetails/CourseDetails";
import SelectVehicle from "./Components/vehicle Selection/SelectVehicle";
import InstructorsSelection from "./Components/Instructors Selection/Instructors Selection";
// import AdminApprovedCourses from './Components/adminApprovedCourses/AdminApprovedCourses'

import "./ownBootstrap.css";
import { useDispatch, useSelector } from "react-redux";
import { CreateCoursesA } from "../../Actions/CourseA";
import { useLocation } from "react-router-dom";
import { handleCourseLicense } from "./Components/course Selection/components/CoursesInventory";
import { GetLicenseTypes } from "../../Actions/CategoryA";

const AddCourseChild = () => {
    const [Success, setSuccess] = useState();
    const [CourseData, setCourseData] = useState({
        CourseSyllabus: [{ CourseDescription: "" }],
        ClassSchedule: [
            { ClassDescription: "", ClassNumber: "", WeekNumber: "" },
        ],
        Instructors: [],
        Possible_FAQs: [""],
    });

    const [Err, setErr] = useState();
    const [Steps, setSteps] = useState(1);
    const [CourseLicense, setCourseLicense] = useState({});
    const [showPopup, setShowPopup] = useState(false);

    const Dispatch = useDispatch();
    useCheckLogin(true, ["Institute"], ["Admin", "Staff"]);
    // const { LicenseTypes } = useSelector((Store) => Store.LicenseTypeReducer);

    // console.log("LicenseType", LicenseTypes);
    // console.log("CourseLicense", CourseLicense);
    const SubmitForm = (e) =>
        SubmitCourseData(e, Dispatch, CourseData, setSuccess);

    const location = useLocation();
    // useEffect(() => {
    //     Dispatch(GetLicenseTypes());
    // }, [Dispatch]);
    useEffect(() => {
        if (
            typeof location?.state?.CoursePK === "string" &&
            location?.state?.CoursePK !== undefined
        ) {
            setCourseData({
                ...CourseData,
                CourseFK: location?.state?.CoursePK,
            });
            setSteps(2);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    useEffect(() => {
        window.scroll(0, 0);
    }, [Steps]);
    console.log("CourseData", CourseData);
    const OnClickBack = () => {
        if (Steps !== 1) {
            setSteps(Steps - 1);
        }
        if (Steps === 1) return;
    };

    const OnClickSteps = (step) => {
        if (step === 2 && CourseData && !CourseData?.CourseFK) {
            // alert("Please complete the previous steps");
            setShowPopup(true);
        } else if (
            step === 3 &&
            CourseData &&
            !CourseData?.Packages !== "" &&
            !CourseData.CourseCurriculum
        ) {
            setShowPopup(true);

            // alert("Please complete the previous steps");
        } else if (
            step === 4 &&
            CourseData &&
            (CourseData?.ShortDescription === "" ||
                CourseData?.LongDescription === "")
        ) {
            setShowPopup(true);

            // alert("Please complete the previous steps");
        } else if (
            step === 5 &&
            CourseData &&
            (!CourseData?.Vehicles || CourseData.Vehicles.length === 0)
        ) {
            // alert("Please complete the previous steps");
            setShowPopup(true);
        } else {
            setSteps(step);
        }
    };
    return (
        <form onSubmit={SubmitForm} onClick={(e) => e.stopPropagation()}>
            {showPopup && (
                <CustomPopup
                    message={"Please complete previous step"}
                    onClose={() => setShowPopup(false)}
                />
            )}
            <CourseSelection
                setCourseLicense={setCourseLicense}
                OnClickSteps={OnClickSteps}
                OnClickBack={OnClickBack}
                Steps={Steps}
                setSteps={setSteps}
                CourseData={CourseData}
                setCourseData={setCourseData}
                StepsIndicator={StepsIndicator}
                PageNo={1}
            />

            <CoursePackaging
                OnClickSteps={OnClickSteps}
                OnClickBack={OnClickBack}
                Steps={Steps}
                setSteps={setSteps}
                CourseData={CourseData}
                setCourseData={setCourseData}
                Err={Err}
                setErr={setErr}
                StepsIndicator={StepsIndicator}
                PageNo={2}
            />

            <CourseDetails
                OnClickSteps={OnClickSteps}
                OnClickBack={OnClickBack}
                Steps={Steps}
                setSteps={setSteps}
                CourseData={CourseData}
                setCourseData={setCourseData}
                Err={Err}
                setErr={setErr}
                StepsIndicator={StepsIndicator}
                PageNo={3}
            />

            <SelectVehicle
                CourseLicense={CourseLicense}
                OnClickSteps={OnClickSteps}
                OnClickBack={OnClickBack}
                Steps={Steps}
                setSteps={setSteps}
                CourseData={CourseData}
                setCourseData={setCourseData}
                StepsIndicator={StepsIndicator}
                PageNo={4}
            />

            <InstructorsSelection
                CourseLicense={CourseLicense}
                OnClickSteps={OnClickSteps}
                OnClickBack={OnClickBack}
                Steps={Steps}
                CourseData={CourseData}
                setCourseData={setCourseData}
                setSteps={setSteps}
                StepsIndicator={StepsIndicator}
                PageNo={5}
                Success={Success}
            />
        </form>
    );
};

const SubmitCourseData = (e, Dispatch, CourseData, setSuccess) => {
    e.preventDefault();
    console.log("courseData is:",CourseData)
    const CourseFormData = new FormData();
    CourseFormData.append("CourseData", JSON.stringify(CourseData));
    Dispatch(CreateCoursesA(CourseFormData, setSuccess));
};

const AddCourse = () => <InstituteTemplate Element={AddCourseChild} />;

export default AddCourse;
const CustomPopup = ({ message, onClose }) => {
    return (
        <div className="bg-gray-800 fixed top-0 left-0 z-10 flex h-full w-full items-center justify-center bg-opacity-50">
            <div className="flex flex-col items-center justify-center rounded-lg bg-white p-8">
                <p className="text-lg">{message}</p>
                <button
                    onClick={onClose}
                    className="hover:bg-blue-600 text-md mt-4 cursor-pointer rounded-md bg-[#4c34ad] px-8 py-2 font-semibold  text-white"
                >
                    Close
                </button>
            </div>
        </div>
    );
};
