import React from 'react'
import { BaseUrl } from '../../../../../../Actions/Base'
import { useAuthContext } from '../../../../../../context/authContext'

const LicenseFullView = ({imageUrl, setLicenseFullView}) => {

  const {LicenseImage, setLicenseImage} = useAuthContext()
  console.log("LicenseImage", LicenseImage)

    // const imageUrl = `${BaseUrl}/api/Images/LatestLicense?url=${Student?.StudentInfo?.LatestLicense}`
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 "
    style={{zIndex: '200000'}}
    >
      <div
        
        className="relative w-[80%] h-auto md:max-h-[700px] overflow-y-scroll  rounded-[24px]   text-white sm:w-[380px] md:w-[700px]  "
      >
        <span
          onClick={()=> setLicenseFullView(false)}
          class="material-symbols-outlined absolute right-[1rem] top-[1rem] cursor-pointer font-semibold text-primary"
          style={{
            fontSize: "30px",
          }}
        >
          close
        </span>
        <img className='w-full rounded-lg' src={LicenseImage ? LicenseImage : imageUrl} alt="" />
        </div>

        </div>
  )
}

export default LicenseFullView
