import React from "react";
import useCheckLogin from "../../../Helpers/CustomHooks/CheckLogin";
import Steps from "./Components/Steps";
import "./AddVehicle.css";
import VehicleInfo from "./Components/VehicleInfo";
import VehicleImages from "./Components/VehicleImages";
import VehicleCredentials from "./Components/VehicleCredentials";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    GetManufacturesA,
    GetSingleVehicleA,
    GetVehicleColorsA,
    GetVehicleEngineA,
    GetVehicleGearTypesA,
    GetVehicleModelsA,
    GetVehicleSeatsA,
    GetVehicleTypeA,
    GetVehicleYearsA,
} from "../../../Actions/VehicleA";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { SubmitUpdateVehicleForm } from "./Helpers/OnSubmit";
import InstituteTemplate from "../../../Components/InstituteTemplate/InstituteTemplate";
import LoadingSpinner from "../../../Components/LoadingSpinner/LoadingSpinner";
import VehiclePopup from "./VehiclePopup/VehiclePopup";

const UpdateVehicleChild = () => {
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const { VehicleId } = useParams();
    const {
        loading,
        Manufacturers,
        Colors,
        EngineCapacity,
        GearType,
        Seats,
        Types,
        Cars,
        years,

        VehicleData: VehicleDataA,
        error: serverError,
    } = useSelector((Store) => Store.VehicleReducer);
    const [VehicleErrors, setVehicleErrors] = useState({ Description: false });
    const [VehicleImagesState, setVehicleImagesState] = useState([{}]);
    const [ShowSuccessPopup, setShowSuccessPopup] = useState(false);
    const [VehicleData, setVehicleData] = useState({
        Manufacturer: undefined,
        Model: undefined,
        Year: undefined,
        Color: undefined,
        Type: undefined,
        Seats: undefined,
        EngineCapacity: undefined,
        GearType: undefined,
        Description: undefined,
        IdentityNumber: "",
        PlateNumber: "",
        InsuranceNumber: "",
        TrainerNumberPlate: "",
        VehicleId,
    });

    useEffect(() => {
        Dispatch(GetManufacturesA());
        Dispatch(GetVehicleColorsA());
        Dispatch(GetVehicleEngineA());
        Dispatch(GetVehicleGearTypesA());
        Dispatch(GetVehicleTypeA());
        Dispatch(GetVehicleSeatsA());
        Dispatch(GetVehicleModelsA());
        Dispatch(GetVehicleYearsA());
    }, [Dispatch]);
    useEffect(() => {
        if (VehicleDataA) {
            setVehicleData({ ...VehicleDataA });
            setVehicleImagesState(VehicleDataA?.VehicleImages);
        }
    }, [VehicleDataA]);
    useEffect(() => {
        if (VehicleId) Dispatch(GetSingleVehicleA(VehicleId));
    }, [Dispatch, VehicleId]);
    useEffect(() => {
        if (serverError?.response?.data) {
            const err = JSON.parse(JSON.stringify(serverError?.response?.data));
            setVehicleErrors({ ...VehicleErrors, ...err });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serverError]);

    useCheckLogin(true, ["Institute"], ["Admin", "Staff"]);

    const SubmitForm = (e) =>
        SubmitUpdateVehicleForm(
            e,
            VehicleData,
            VehicleImagesState,
            VehicleErrors,
            setVehicleErrors,
            Dispatch,
            Navigate,
            setShowSuccessPopup
        );
    return !loading ? (
        <div className="flex w-full flex-col items-center bg-[#F7F7F7] py-10">
            {ShowSuccessPopup && (
                <VehiclePopup message={"Vehicle Detail Updated Successfully"} />
            )}
            <Steps updateOnly={true} />
            <form
                className="flex w-full flex-col items-center"
                onSubmit={SubmitForm}
            >
                <VehicleInfo
                    Colors={Colors}
                    EngineCapacity={EngineCapacity}
                    GearType={GearType}
                    Seats={Seats}
                    Types={Types}
                    cars={Cars}
                    years={years}
                    Manufacturers={Manufacturers}
                    setVehicleData={setVehicleData}
                    VehicleData={VehicleData}
                    VehicleErrors={VehicleErrors}
                    setVehicleErrors={setVehicleErrors}
                />

                <VehicleImages
                    VehicleImagesState={VehicleImagesState}
                    setVehicleImagesState={setVehicleImagesState}
                    VehicleErrors={VehicleErrors}
                    setVehicleErrors={setVehicleErrors}
                />

                <VehicleCredentials
                    setVehicleData={setVehicleData}
                    VehicleData={VehicleData}
                    VehicleErrors={VehicleErrors}
                    setVehicleErrors={setVehicleErrors}
                />

                <button
                    type="submit"
                    className="mt-10 cursor-pointer rounded-xl border-none bg-[#A1A3EF] py-[10px] px-5 text-base text-white outline-none"
                >
                    Save & Continue
                </button>
            </form>
        </div>
    ) : (
        <LoadingSpinner />
    );
};

const UpdatedVehicle = () => {
    return <InstituteTemplate Element={UpdateVehicleChild} />;
};

export default UpdatedVehicle;
