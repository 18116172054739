import React from "react";

const AddAward = ({setAwardForm}) => {
  return (
    <>
      <div className="h-auto w-full py-10 px-20">
        <div className="add-certificate relative flex flex-col h-[250px] w-[300px] items-center justify-center rounded-lg border border-dashed border-gray-100 shadow-lg">
          <div className="h-20 w-20 rounded-full bg-purpal  opacity-30 shadow-lg"></div>
          <span className="material-symbols-outlined absolute top-[76px] left-[138px] rounded-full bg-purpal text-white opacity-100">
            add
          </span>

          <button
            type="button"
            onClick={()=> setAwardForm(true)}
            class="focus:ring-blue-300 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mt-5 mb-2 cursor-pointer rounded-lg border-0 bg-[#4c34ad] px-8 py-2 text-4xs font-medium text-white outline-none hover:bg-[#a1a3ef] focus:outline-none focus:ring-4 md:text-2xs lg:text-[22px]"
          >
            Add Award
          </button>
        </div>

       
          

      </div>
    </>
  );
};

export default AddAward;
