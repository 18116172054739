import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import EnrolledCourseDetails from "./Components/EnrolledCourseDetails";
import EnrolledCourseHrs from "./Components/EnrolledCourseHrs";

const EnrolledCourseInfo = ({ Course, setShowSubscription }) => {
  const { UserInfo } = useSelector((Store) => Store.LoginSignupReducer);
  const [ShowMessage, setShowMessage] = useState(false);
  const { InstructorId } = useParams();
  // console.log(Course?.Student)
  console.log("Course", Course);
  console.log("UserInfo", UserInfo);
  console.log("InstructorId innenrolled course info", InstructorId);

  const showMessage = () => {};

  return (
    <div
      className={`mt-28  flex min-h-[205px] flex-col items-center justify-between gap-8 border-[1px]
        border-solid
                        border-[#D6D6D6]            bg-white  py-7
                        px-5 lg:px-3       md:px-10 lg:flex-row
                        `}
    >
      <div className="flex w-[72%] flex-col items-center justify-between gap-5  xl:flex-row">
        <EnrolledCourseDetails
          InstituteCourse={Course?.CoursePackage?.InstituteCourse}
        />
        <EnrolledCourseHrs CoursePackage={Course?.CoursePackage} />
      </div>

      <hr className="h-0 w-3/5 lg:ml-3   lg:min-h-[150px] lg:w-0" />

      <span className="flex w-full flex-col items-end justify-center gap-3 sm:w-[50%] md:w-[25%] xl:w-[21%]">
        {ShowMessage ? (
          <div
            class="bg-red-50 bg-gray-800 mb-4 rounded-lg p-4 text-sm text-[#d24444] dark:text-[#d24444]"
            role="alert"
          >
            <span class="text-[14px">please contact to administration office to resume your course status. </span> 
          </div>
        ) : null}
        {UserInfo?.User === "Student" ? (
          Course?.Status === "Hold" ? (
            <Link
              onClick={() => setShowMessage(true)}
              className={`cursor-pointer whitespace-nowrap rounded-xl border-none bg-[#A1A3EF] py-3 px-5 text-3xs
                        text-white        no-underline     md:px-6
                        md:text-xs    xl:px-8 
                    `}
            >
              Resume
            </Link>
          ) : (
            <Link
              to={`/enrolledcourse/coursemodule/${Course?.EnrollmentId}`}
              className={`cursor-pointer whitespace-nowrap rounded-xl border-none bg-[#A1A3EF] py-3 px-5 text-3xs
                            text-white        no-underline     md:px-6
                            md:text-xs    xl:px-8 
                        `}
            >
              Go To Course
            </Link>
          )
        ) : null}
        {UserInfo?.User === "Institute" ? (
          <>
            <Link
              to={
                UserInfo.InstituteUserType === "Instructor"
                  ? `/About/Instructor/${InstructorId}`
                  : `/enrolledcourse/coursemodule/${Course?.EnrollmentId}`
              }
              className={`w-full max-w-[185px] cursor-pointer whitespace-nowrap rounded-xl border-none bg-[#A1A3EF] py-3 px-2
                                text-center   sm:text-4xs     md:text-3xs     xl:text-2xs      2xl:text-3xs text-white no-underline
                                sm:max-w-[215px]   
                        `}
            >
              View Calendar
            </Link>
            {UserInfo?.InstituteUserType === "Instructor" ? (
              <Link
                className={`w-full max-w-[185px] cursor-pointer whitespace-nowrap rounded-xl border-none bg-[#EE1B1B] py-3 px-1
                                text-center text-4xs text-white
                                no-underline 
                                sm:max-w-[215px]    sm:text-4xs     md:text-3xs     xl:text-2xs      2xl:text-xs`}
                onClick={() => setShowSubscription(true)}
              >
                On Leave
              </Link>
            ) : (
              <button
                className={`w-full max-w-[185px] cursor-pointer whitespace-nowrap rounded-xl border-none bg-[#EE1B1B] py-3 px-1
                                text-center text-4xs text-white
                                no-underline 
                                sm:max-w-[215px]    sm:text-5xs     md:text-5xs     xl:text-4xs      2xl:text-3xs`}
                onClick={() => setShowSubscription(true)}
              >
                Subscription Status
              </button>
            )}
          </>
        ) : null}
      </span>
    </div>
  );
};

export default EnrolledCourseInfo;
