import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { BaseUrl } from "../../../Actions/Base";
import VehicleDelete from "./VehicleDelete";

const CarTiles = ({ Vehicle, onDelete }) => {
    const PRef = useRef();
    const [DescText, setDescText] = useState();

    useEffect(() => {
        if (Vehicle?.Description) PRef.current.innerHTML = Vehicle?.Description;
    }, [Vehicle?.Description]);

    useEffect(() => {
        if (PRef.current?.innerText) setDescText(PRef.current?.innerText);
    }, []);

    useEffect(() => {
        if (DescText?.length > 100) {
            setDescText(DescText.split("").slice(0, 97).join("") + "...");
        }
    }, [DescText]);

    const Image = Vehicle?.VehicleImages[0]?.VehicleImageLink;

    // const onConfirm = handleDelete;
    // const onCancel = s;
    return (
        <div
            className={`flex max-h-[460px] w-full max-w-[275px] flex-col items-center justify-center gap-2 rounded-xl bg-white px-2 py-5
        shadow-[6px_12px_24px_#00000014] md:w-2/5 lg:w-1/2 xl:w-1/3
        `}
        >
            <Link
                className="cursor-pointer no-underline"
                to={`/vehicle/detail/${Vehicle.VehicleId}`}
            >
                <div ref={PRef} className="hidden"></div>
                <span className="flex h-[200px] max-h-[200px] w-full items-center justify-center overflow-hidden">
                    <img
                        className="w-full"
                        src={`${BaseUrl}/api/Vehicleimage?url=${Vehicle?.VehicleImages[0]?.VehicleImageLink}`}
                        alt=""
                    />
                </span>
                <div className="CarTilesBorder flex w-full items-center justify-between py-3 text-[#45454D]  ">
                    <h3 className="text-3xs font-bold ">{Vehicle?.Model}</h3>
                    <h4 className="text-6xs font-normal">{Vehicle?.Year}</h4>
                </div>
                <div className="CarTilesBorder flex h-8 w-full items-center justify-center gap-2 py-2">
                    <img
                        className=" w-5"
                        src={require("../Assets/Address Card.svg").default}
                        alt=""
                    />
                    <h4 className="text-6xs font-normal text-[#45454D]">
                        {Vehicle?.PlateNumber}
                    </h4>
                </div>

                <div className="CarTilesBorder h-16 w-full overflow-hidden py-1 no-underline">
                    <p className=" break-words text-center text-6xs text-[#45454D] no-underline">
                        {DescText}
                    </p>
                </div>
            </Link>

            <div className="flex gap-3">
                <Link
                    type="button"
                    to={`/vehicle/update/${Vehicle.VehicleId}`}
                    className="w-fit cursor-pointer rounded-xl border-none bg-[#A1A3EF] px-10 py-2 text-6xs text-white no-underline outline-none"
                >
                    Edit
                </Link>
                <Link
                    type="button"
                    onClick={onDelete}
                    className="w-fit cursor-pointer rounded-xl border-none bg-[#A1A3EF] px-7 py-2 text-6xs text-white no-underline outline-none"
                >
                    Delete
                </Link>
            </div>
        </div>
    );
};

export default CarTiles;
