import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { GetSubscriptionStatus } from "../../../../Actions/StudentA";
import crossIcon from "./cross_icon.svg";
import axios from "axios";
import { BaseUrl } from "../../../../Actions/Base";
import { GetLocalStorage } from "../../../../Helpers/LocalStorage/LocalStorage";

function CourseSubscription({ ShowSubscription, setShowSubscription }) {
  const [Error, setError] = useState({});
  const [showEnterReason, setShowEnterReason] = useState(false);
  const { EnrollmentId } = useParams();


  const { Subscription, Student } = useSelector(
    (Store) => Store.StudentReducer
  );
  const [ButtonText, setButtonText] = useState('')

  const Dispatch = useDispatch();

  //popup visibility
  const showReason = () => {
    if (Subscription?.Status === "New Enrollment") {
      setError({ Status: "New Enrollment" });
      return;
    }
    setShowEnterReason(!showEnterReason);
  };

  useEffect(() => {
    if (EnrollmentId) Dispatch(GetSubscriptionStatus(EnrollmentId));
  }, [Dispatch, EnrollmentId, ButtonText]);

  const handleParentRerender = (data) => {
    console.log("myData",data)
    setButtonText(data === "Hold"
  ? "Resume"
  : "Put Course Status on Hold")
  };

console.log("Subscription", Subscription)
  return (
    <>
      {showEnterReason ? (
        <EnterReason
        onSubmission={(data) => handleParentRerender(data)}
        setButtonText={setButtonText}
          setShowEnterReason={setShowEnterReason}
          Error={Error}
          setError={setError}
          Subscription={Subscription}
          EnrollmentId={EnrollmentId}
          showReason={showReason}
        />
      ) : null}
      <div className="fixed left-0 top-0 z-30 flex h-screen w-screen items-center justify-center bg-[#00000045]">
        <div className=" bg-slate-300 flex h-[100vh] w-[100vw] items-center justify-center backdrop-blur-sm">
          <div className="h-full w-11/12 rounded-b-[24px] border-2 bg-white md:w-[50%]">
            <div className="w-[100%]">
              {/*  ---- heading ------ */}
              <div className="relative flex h-[86px] w-[100%] items-center justify-start bg-[#F1F1F1] pl-6 lg:justify-center">
                <p className="text-[26px] leading-[26px] text-[#4D4F5C]">
                  Course Subscription
                </p>
                <img
                  className="absolute top-6 right-5 h-[38px] w-[38px] cursor-pointer"
                  src={crossIcon}
                  alt="Cancel Icon"
                  onClick={() => setShowSubscription(false)}
                />
              </div>
              {/*  ---- body ------ */}
              <div className="my-[50px] text-center ">
                <p className="text-[#4D4F5C]">
                  You can Update the status of course subscription from here
                </p>
              </div>
              <hr className="mx-4 mb-[50px] border-b-[1px] border-b-[#707070]" />
              {/* Course Subscription Action Section */}
              <div className="flex w-[100%] justify-center ">
                <div className="flex w-[85%] flex-col gap-8">
                  <div className="textCol-action flex justify-between">
                    <p className="textSmall text-[22px] font-normal text-[#4D4F5C]">
                      Visa: **** **** **** (092)
                    </p>
                    <p className="textSmall text-[22px] font-normal text-[#4D4F5C]">
                      Payment Method: One Time pay
                    </p>
                  </div>
                  <div className="textCol-action flex justify-between">
                    <p className="textSmall text-[22px] font-normal text-[#4D4F5C]">
                      Next Installment Date: {Subscription?.NextInstallmentDate}
                    </p>
                    <Link
                      href="#"
                      className="textSmall text-[22px] font-normal text-[#157DBC] underline"
                    >
                      View Billing History
                    </Link>
                  </div>
                </div>
              </div>
              {/* buttons */}
              <div className="relative">
                {Error ? (
                  <p className="absolute top-[-30px] left-[19%] text-[16px] leading-[22px] text-[#FF5163] lg:text-[18px] ">
                    {Error.Status}
                  </p>
                ) : null}

                <div className="w-full text-center flex justify-center">
                  <div className="customActionBtn my-[64px] flex w-[90%] justify-center gap-6">
                    <button
                      onClick={showReason}
                      className="customActionBtnStyle w-[45%] cursor-pointer rounded-[7px] border-none bg-[#A1A3EF] py-[17px] px-[29px] text-[22px] text-white"
                    >
                      {ButtonText === "" ? Subscription?.Status === "Hold" ? "Resume" : "Put Course Status on Hold" : ButtonText}
                    </button>
                    <button className="customActionBtnStyle w-[45%] cursor-pointer rounded-[7px] border-none bg-[#FF5163] py-[17px] px-[59px] text-[22px]  text-white">
                      Cancel Subscription
                    </button>
                  </div>
                </div>
              </div>
              {/* Payment Details Section */}
              <div className="w-[100%]">
                <div className="pl-12 pr-5">
                  <h3 className="text-[24px] font-bold ">Payment Details</h3>
                  <div className="paymentSection my-[26px] flex justify-between">
                    <div className="flex w-[339px] flex-col gap-[21px]">
                      <div className="flex justify-between">
                        <p className="whitespace-nowrap text-[22px] leading-[22px]">
                          Total Paids:
                        </p>
                        <span className="whitespace-nowrap text-[22px] leading-[22px]">
                          {Subscription?.TotalPaid} $
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <p className="whitespace-nowrap text-[22px] leading-[22px]">
                          Remaining Amount:
                        </p>
                        <span className="whitespace-nowrap text-[22px] leading-[22px]">
                          {Subscription?.RemainingAmount}
                        </span>
                      </div>
                    </div>
                    <div className="paymentSecondSection ml-[25px] flex w-[339px] flex-col gap-[21px]">
                      <div className="flex justify-between">
                        <p className="whitespace-nowrap text-[22px] leading-[22px]">
                          Course Fee:
                        </p>
                        <span className="whitespace-nowrap text-[22px] leading-[22px]">
                          {Subscription?.TotalFee} $
                        </span>
                        <span></span>
                      </div>
                      <div className="flex justify-between">
                        <p className="whitespace-nowrap text-[22px] leading-[22px]">
                          Installments Paid:
                        </p>
                        <span className="whitespace-nowrap text-[22px] leading-[22px]">
                          {Subscription?.InstallmentsPaid}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CourseSubscription;

const EnterReason = ({
  showReason,
  setShowEnterReason,
  Error,
  setError,
  EnrollmentId,
  Subscription,
  setButtonText,
  onSubmission
  
}) => {
  const [Reason, setReason] = useState("");
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    const axiosInstance = axios.create({
      baseURL: BaseUrl,
      withCredentials: true,
    });

    try {
      const UserFirstName = GetLocalStorage("UserInfo").FirstName
      const UserLastName = GetLocalStorage("UserInfo").LastName
      if (Reason === "") {
        setError({ reason: "please enter reason" });
        return;
      }

      const payload = {
        status: Subscription?.Status === "Hold" ? "InProgress" : "Hold",
        message: Reason,
        FirstName: UserFirstName,
        LastName: UserLastName

      };

      // Add try-catch block for error handling
      try {
        const res = await axiosInstance.put(`/api/EnrollmentStatus/update${EnrollmentId}`, payload);
        onSubmission(res?.data?.Status);
        console.log("res,", res)
        setShowEnterReason(false);
      } catch (error) {
        // Handle specific error cases if needed
        console.error("Error during axios request:", error);
      }
    } catch (err) {
      console.error("Error in handleSubmit:", err);
    }
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/50"
      style={{ zIndex: "200000" }}
    >
      <form
        onSubmit={handleSubmit}
        className="BG_IMAGEE w-[80%] rounded-[24px]  bg-[#e1e7f0] py-10 px-10 text-white sm:w-[380px] sm:p-10 md:w-[686px] md:px-[4rem] 2xl:pb-14 3xl:pb-14"
      >
        <span
          onClick={showReason}
          class="material-symbols-outlined absolute right-[1rem] top-[1rem] cursor-pointer font-semibold text-primary"
          style={{
            fontSize: "30px",
          }}
        >
          close
        </span>
        <div className="flex  w-full items-center justify-center">
          <img
            className="h-[40px] w-[220px]  md:h-[60px] md:w-[328px]"
            src={require("./Assets/forgotLog.png")}
            alt=""
          />
        </div>

        <div className="mt-[30px]  mb-[1rem] flex w-full flex-col items-center justify-center text-[#1D1D1D]">
          <h2 className="text-3xs font-bold md:text-xs 2xl:text-[24px]">
            Enter Your Reason here
          </h2>
        </div>
        <div>
          {Error ? (
            <p className="text-[16px] leading-[22px] text-[#FF5163] lg:text-[18px] ">
              {Error.reason}
            </p>
          ) : null}
          <textarea
            value={Reason}
            onChange={(e) => setReason(e.target.value)}
            className="w-full border-0 p-4 text-[14px] tracking-wide outline-0 md:text-[16px]"
            name=""
            id=""
            cols="10"
            rows="5"
          ></textarea>
        </div>

        <div className="">
          <span
            className={`submit-btn-box-shadow relative mt-8 flex w-full justify-center`}
          >
            <button
              className={`h-[45px] w-full cursor-pointer rounded-[8px] border-none bg-primary  py-2
                           text-[16px] text-white md:text-[18px]  xl:py-3 
                            xl:text-[18px]     `}
              type="submit"
            >
              Submit Reason
            </button>
          </span>
        </div>
      </form>
    </div>
  );
};
