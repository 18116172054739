import { createReducer } from "@reduxjs/toolkit";

export const VehicleReducer = createReducer({}, (builder) => {
    builder.addCase("AddVehicleRequest", (state, action) => {
        state.loading = true;
    });
    builder.addCase("AddVehicleSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.VehicleData = action.payload;
    });
    builder.addCase("AddVehicleFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });
    ///////////////////////
    builder.addCase("UpdateVehicleRequest", (state, action) => {
        state.loading = true;
    });
    builder.addCase("UpdateVehicleSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.VehicleData = action.payload;
    });
    builder.addCase("UpdateVehicleFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });

    ///////////////////
    builder.addCase("DeleteVehicleRequest", (state) => {
        state.loading = true;
    });

    builder.addCase("DeleteVehicleSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
    });

    builder.addCase("DeleteVehicleFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });

    ///////////////////
    builder.addCase("GetAllVehiclesRequest", (state, action) => {
        state.loading = true;
    });
    builder.addCase("GetAllVehiclesSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.Vehicles = action.payload;
    });
    builder.addCase("GetAllVehiclesFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });
    /////////////////
    builder.addCase("GetSingleVehicleRequest", (state, action) => {
        state.loading = true;
    });
    builder.addCase("GetSingleVehicleSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.VehicleData = action.payload;
    });
    builder.addCase("GetSingleVehicleFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });

    //vehicle registrations options lists start
    builder.addCase("GetManufacturesRequest", (state, action) => {
        state.loading = true;
    });
    builder.addCase("GetManufacturesSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.Manufacturers = action.payload;
    });
    builder.addCase("GetManufacturesFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });
    //////////////////
    builder.addCase("GetVehicleColorsRequest", (state, action) => {
        state.loading = true;
    });
    builder.addCase("GetVehicleColorsSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.Colors = action.payload;
    });
    builder.addCase("GetVehicleColorsFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });

    /////////////

    builder.addCase("GetVehicleEngineRequest", (state, action) => {
        state.loading = true;
    });
    builder.addCase("GetVehicleEngineSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.EngineCapacity = action.payload;
    });
    builder.addCase("GetVehicleEngineFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });

    //////////
    builder.addCase("GetVehicleGearTypeRequest", (state, action) => {
        state.loading = true;
    });
    builder.addCase("GetVehicleGearTypeSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.GearType = action.payload;
    });
    builder.addCase("GetVehicleGearTypeFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });

    /////////////
    builder.addCase("GetVehicleSeatsRequest", (state, action) => {
        state.loading = true;
    });
    builder.addCase("GetVehicleSeatsSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.Seats = action.payload;
    });
    builder.addCase("GetVehicleSeatsFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });

    ////////////

    builder.addCase("GetVehicleTypeRequest", (state, action) => {
        state.loading = true;
    });
    builder.addCase("GetVehicleTypeSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.Types = action.payload;
    });
    builder.addCase("GetVehicleTypeFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });

    ////////////

    builder.addCase("GetVehicleModelsRequest", (state, action) => {
        state.loading = true;
    });
    builder.addCase("GetVehicleModelsSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.Cars = action.payload;
    });
    builder.addCase("GetVehicleModelsFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });

    ////////////

    builder.addCase("GetVehicleYearsRequest", (state, action) => {
        state.loading = true;
    });
    builder.addCase("GetVehicleYearsSuccess", (state, action) => {
        state.loading = false;
        state.error = {};
        state.years = action.payload;
    });
    builder.addCase("GetVehicleYearsFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });
});
