import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./VehicleDelete.css";
import { BaseUrl } from "../../../Actions/Base";
const VehicleDelete = ({ PopupDetail, onCancel, onConfirm, type }) => {
    const [profile, setProfile] = useState();
    // console.log("PopupDEtail", PopupDetail);
    useEffect(() => {
        const ImageUrl = `${BaseUrl}/api/Vehicleimage?url=${PopupDetail?.VehicleImages[0]?.VehicleImageLink}`;

        setProfile(ImageUrl);
    }, [PopupDetail]);
    // console.log("profile", profile);
    const Navigate = useNavigate();
    const OnClick = () => Navigate("/vehicle/inventory");

    return (
        <div className="fixed left-0 top-0 z-30 flex h-screen w-screen items-center justify-center bg-[#00000029]">
            <div className="relative flex aspect-[353_/_221] h-fit w-full max-w-[360px] flex-col gap-[10px] rounded-[20px] bg-white px-8 py-5">
                <div className="flex flex-col items-center gap-3">
                    <h3 className="HeadingBorder text-5xs font-normal">
                        {"Delete Vehicle !!"}
                    </h3>
                    <h3 className=" text-4xs font-normal ">
                        Are you sure you want to delete
                    </h3>
                </div>
                <div className="flex flex-col items-center gap-[6px]">
                    <div className="h-20 w-20 rounded">
                        {profile && (
                            <img
                                className=" h-[100%] w-[100%]"
                                src={profile}
                                alt="profile-img"
                            />
                        )}
                    </div>
                    <h3 className=" Semibold text-4xs ">
                        {/* {popupDetail?.InstructorName || popupDetail?.StaffName} */}
                        {PopupDetail?.Model}
                    </h3>
                    {/* )} */}
                    <div className="flex items-center justify-center gap-3">
                        <button
                            type="button"
                            className="BrandingButton SemiBold w-fit cursor-pointer rounded-[14px] py-1 px-8 text-6xs"
                            onClick={onCancel}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="SemiBold w-fit cursor-pointer cursor-pointer  rounded-[14px] bg-[#4C34AD] py-1 px-8 text-6xs text-white"
                            onClick={onConfirm}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
                <img
                    className="absolute right-3 top-[6px] cursor-pointer"
                    src={require("./Assets/Cross.svg").default}
                    alt=""
                    onClick={onCancel}
                />
            </div>
        </div>
    );
};

export default VehicleDelete;
