import {
  calculateDiscount,
  calculateTax,
} from "../../../Helpers/calculateDiscount";
import "./paymentDetails.css";
function PaymentsDetailsSection({
  Installment,
  setPaymentDetailPopup,
  EnrollmentData,
  PricesPlusTax
}) {
  const { StudentData, Package } = EnrollmentData;

  let discountedTotalPrice;
  const discount = Package?.DiscountType === "add_discount";
  if (discount) {
    const discountPercentage = parseFloat(Package?.AddDiscount) || 0;
    discountedTotalPrice = calculateDiscount(
      Package?.TotalFee,
      discountPercentage
    );
  } else {
    discountedTotalPrice = Package?.TotalFee;
  }
  return (
    <>
      <div className=" paymentBoxShadow relative w-[488px] rounded-[19px] border bg-white px-4 py-4">
        <span
          onClick={() => setPaymentDetailPopup(false)}
          className="material-symbols-outlined absolute top-1 right-1 cursor-pointer text-[29px] font-semibold text-violet-100"
        >
          cancel
        </span>

        {Installment === "Full" ? (
          <Installments type={"Full"}
          PricesPlusTax={PricesPlusTax}
          EnrollmentData={EnrollmentData}
          discountedTotalPrice={
            discount
              ? discountedTotalPrice.discountedPrice
              : discountedTotalPrice
          } />
        ) : Installment === "Installments" ? (
          <Installments
            type={"Installments"}
            PricesPlusTax={PricesPlusTax}
            EnrollmentData={EnrollmentData}
            discountedTotalPrice={
              discount
                ? discountedTotalPrice.discountedPrice
                : discountedTotalPrice
            }
          />
        ) : null}
      </div>
    </>
  );
}

function Installments({ EnrollmentData, discountedTotalPrice, type, PricesPlusTax }) {
  const { StudentData, Package } = EnrollmentData;

  let discountAvailable = Package?.DiscountType === "add_discount";
  let TaxValue;
  let discountValue;
  let discountInfo;
  // let PricesPlusTax;
  let SingleInstallment;
  let RemainigAmount;
  let RemainingPriceWithoutTax;

  if (Package?.DiscountType !== "no_tax") {
    if (discountAvailable) {
      const discountPercentage = parseFloat(Package?.AddDiscount) || 0;
      discountInfo = calculateDiscount(Package?.TotalFee, discountPercentage);
      discountValue = discountInfo.discountedPrice;
      TaxValue = calculateTax(discountInfo.discountedPrice, Package?.Tax);
      PricesPlusTax = discountValue + TaxValue;
      SingleInstallment = PricesPlusTax / Package?.Installments;
      RemainigAmount = PricesPlusTax - SingleInstallment;
      RemainingPriceWithoutTax = discountedTotalPrice - SingleInstallment;
    } else if (!discountAvailable) {
      TaxValue = calculateTax(Package?.TotalFee, Package?.Tax);
      PricesPlusTax = Package?.TotalFee + TaxValue;
      SingleInstallment = PricesPlusTax / Package?.Installments;
      RemainigAmount = PricesPlusTax - SingleInstallment;
      RemainingPriceWithoutTax = discountedTotalPrice - SingleInstallment;
    }
  } else if (Package?.DiscountType === "no_tax") {
    if (discountAvailable) {
      const discountPercentage = parseFloat(Package?.AddDiscount) || 0;
      discountInfo = calculateDiscount(Package?.TotalFee, discountPercentage);
      discountValue = discountInfo.discountedPrice;
      PricesPlusTax = discountInfo.discountedPrice;
      SingleInstallment = Package?.TotalFee / Package?.Installments;
      RemainigAmount = Package?.TotalFee - SingleInstallment;
      RemainingPriceWithoutTax = discountedTotalPrice - SingleInstallment;
    } else if (!discountAvailable) {
      PricesPlusTax = Package?.TotalFee;
      SingleInstallment = PricesPlusTax / Package?.Installments;
      RemainigAmount = PricesPlusTax - SingleInstallment;
      RemainingPriceWithoutTax = PricesPlusTax - SingleInstallment;
    }
  }

  console.log("TaxValue", TaxValue);
  console.log("discountValue", discountValue);
  console.log("PricesPlusTax", PricesPlusTax);
  console.log("SingleInstallment", SingleInstallment);
  console.log("RemainigAmount", RemainigAmount);

  return (
    <>
      {type === "Installments" && (
        <>
          <div className="font-segoe-ui ">
            <div className="flex w-full flex-col justify-center">
              <div className="text-center">
                <img
                  className="w-[101px] text-gray-600"
                  src="/assets/OtherSectionImages/paymentDetailsImage_2.svg"
                  alt=""
                />
              </div>
              <h2 className=" text-center text-4xs text-[#000000] md:text-xs">
                Payment Details
              </h2>
              <p className="text-center text-[#404040]">
                Package Selected :{" "}
                <span className="text-5xs text-black md:text-[19px]">
                  {Package?.PackageName}
                </span>
              </p>
            </div>
            <Hrline />

            <div className="flex flex-col gap-3">
              <div>
                <h2 className="text-4xs font-semibold md:text-[19px]">
                  Installments
                </h2>
              </div>
              <div className="total-payable flex justify-between">
                <p>Total Payable</p>
                <p>
                  $ {discountedTotalPrice.toFixed(2)}
                  <sup className="text-8xs">+Tax</sup>{" "}
                </p>
              </div>
              <div className="flex justify-between">
                <p>Total Installments</p>
                <p>0{Package?.Installments}</p>
              </div>
              <div className="flex justify-between">
                <p>Installment Frequency</p>
                <p>{Package?.InstallmentSchedule}</p>
              </div>
              <div className="flex justify-between">
                <p>Single Installment Amount</p>
                <p>
                  $ {(discountedTotalPrice / Package?.Installments).toFixed(2)}
                  <sup className="text-8xs">+Tax</sup>{" "}
                </p>
              </div>
            </div>

            <Hrline />

            <div className="flex flex-col gap-3">
              <div>
                <h2 className="text-4xs font-semibold md:text-[19px]">
                  Payment Details
                </h2>
              </div>

              <div className="flex justify-between">
                <p>Payable</p>
                <p>$ {(discountedTotalPrice / Package?.Installments).toFixed(2)}</p>
              </div>

              <div className="flex justify-between">
                <p>Tax</p>
                <p>$ {(TaxValue / Package.Installments).toFixed(2)}</p>
              </div>

              <div className="flex justify-between font-semibold">
                <p className="">Total Payable For This Transaction</p>
                <p className="">$ {SingleInstallment.toFixed(2)}</p>
              </div>
            </div>
            <Hrline />

            <div className="flex flex-col gap-3">
              <div>
                <h2 className="text-4xs font-semibold md:text-[19px]">
                  Remaining Installments
                </h2>
              </div>

              <div className="flex justify-between">
                <p>Total Remaining Amount</p>
                <p>
                  $ {RemainingPriceWithoutTax.toFixed(2)}
                  <sup className="text-8xs">+Tax</sup>
                </p>
              </div>

              <div className="flex justify-between">
                <p>Remaining Installments</p>
                <p>
                  $ {Package?.Installments > 1 ? Package?.Installments - 1 : 0}
                </p>
              </div>
            </div>
          </div>
        </>
      )}

      {type === "Full" && (
        <>
          <div className="font-segoe-ui gap-4">
            <div className="flex w-full flex-col justify-center">
              <div className="text-center">
                <img
                  className="w-[101px] text-gray-600"
                  src="/assets/OtherSectionImages/paymentDetailsImage_2.svg"
                  alt=""
                />
              </div>
              <h2 className=" text-center text-4xs text-[#000000] md:text-xs">
                Payment Details
              </h2>
              <p className="text-center text-[#404040]">
                Package Selected :{" "}
                <span className="text-5xs text-black md:text-[19px]">
                  {Package?.PackageName}
                </span>
              </p>
            </div>

            <Hrline />
            <div className="flex flex-col gap-4">

            <div className="flex justify-between">
                <p>Payable</p>
                <p>$ {discountedTotalPrice.toFixed(2)}</p>
              </div>

               {TaxValue && TaxValue !== 0 && <div className="flex justify-between">
                <p>Tax</p>
                <p>$ {TaxValue.toFixed(2)}</p>
              </div>}
            </div>

              <Hrline />

              <div className="flex justify-between font-semibold">
                <p>Total Payable</p>
                <p>$ {PricesPlusTax.toFixed(2)}</p>
              </div>

          </div>
        </>
      )}
    </>
  );
}

function Hrline() {
  return (
    <>
      <hr className="color-[#707070] my-3" />
    </>
  );
}
export default PaymentsDetailsSection;
