import axios from "axios";
import { BaseUrl } from "./Base";

const axiosInstance = axios.create({
  baseURL: BaseUrl,
  withCredentials: true,
});


export const GetFeedbackPageData =(EnrollmentId)=> async(dispatch)=> {
    try {
          dispatch({
            type: "feedbackRequest"
          })
          const {data} = await axiosInstance.get(`/api/getdata-for-feedback/${EnrollmentId}`)

          dispatch({
            type: "feedbackSuccess", 
            payload: data
          })

    }catch(error) {
        dispatch({
            type: "feedbackFailure", 
            payload: error
        })
        console.log(error)
    }
}