import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BaseUrl } from "../../../../Actions/Base";
import InstuctorContacts from "./InstuctorContacts";
import InstructorContacts from "./InstuctorContacts";
import StudentsContacts from "./StudentsContacts";
import AdminContacts from "./AdminContacts";
import StaffContacts from "./StaffContacts";
import useCheckLogin from "../../../../Helpers/CustomHooks/CheckLogin";
import { SocketContext } from "../../../../App";

const ContactsList = ({
    setCurrentSelected,
    active,
    online,
    search,
    unreadMessages,
    handleReadMessage,
    lastseen,
}) => {
    // const { UserInfo } = useSelector((state) => state.LoginSignupReducer);

    let displayContacts = null;

    if (active === "Instructor") {
        displayContacts = (
            <InstructorContacts
                search={search}
                online={online}
                setCurrentSelected={setCurrentSelected}
                unreadMessages={unreadMessages}
                handleReadMessage={handleReadMessage}
                lastseen={lastseen}
            />
        );
    } else if (active === "Students") {
        displayContacts = (
            <StudentsContacts
                search={search}
                online={online}
                setCurrentSelected={setCurrentSelected}
                unreadMessages={unreadMessages}
                handleReadMessage={handleReadMessage}
                lastseen={lastseen}
            />
        );
    } else if (active === "Staffs") {
        displayContacts = (
            <StaffContacts
                search={search}
                unreadMessages={unreadMessages}
                online={online}
                lastseen={lastseen}
                setCurrentSelected={setCurrentSelected}
                handleReadMessage={handleReadMessage}
            />
        );
    } else if (active === "Admin") {
        //     displayContacts = (
        //         <AdminContacts setCurrentSelected={setCurrentSelected} />
        //     );
    }

    return <div className="">{displayContacts}</div>;
};

export default ContactsList;
