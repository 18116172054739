import React from "react";
import useCheckLogin from "../../../Helpers/CustomHooks/CheckLogin";
import Steps from "./Components/Steps";
import "./AddVehicle.css";
import VehicleInfo from "./Components/VehicleInfo";
import VehicleImages from "./Components/VehicleImages";
import VehicleCredentials from "./Components/VehicleCredentials";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InstituteTemplate from "../../../Components/InstituteTemplate/InstituteTemplate";
import { SubmitAddVehicleForm } from "./Helpers/OnSubmit";
import { useEffect } from "react";
import VehiclePopup from "./VehiclePopup/VehiclePopup";
import {
    GetManufacturesA,
    GetVehicleColorsA,
    GetVehicleEngineA,
    GetVehicleGearTypesA,
    GetVehicleModelsA,
    GetVehicleSeatsA,
    GetVehicleTypeA,
    GetVehicleYearsA,
} from "../../../Actions/VehicleA";
const AddVehicleChild = () => {
    const [VehicleData, setVehicleData] = useState({
        Manufacturer: undefined,
        Model: undefined,
        Year: undefined,
        Color: undefined,
        Type: undefined,
        Seats: undefined,
        EngineCapacity: undefined,
        GearType: undefined,
        Description: undefined,
        IdentityNumber: undefined,
        PlateNumber: undefined,
        InsuranceNumber: undefined,
        TrainerNumberPlate: undefined,
    });

    const { error: serverError } = useSelector((Store) => Store.VehicleReducer);
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const {
        loading,
        Manufacturers,
        Colors,
        EngineCapacity,
        GearType,
        Seats,
        Types,
        Cars,
        years,
        
    } = useSelector((Store) => Store.VehicleReducer);
    const [VehicleErrors, setVehicleErrors] = useState({ Description: false });
    const [VehicleImagesState, setVehicleImagesState] = useState([]);
    const [ShowSuccessPopup, setShowSuccessPopup] = useState(false);

    useEffect(() => {
        Dispatch(GetManufacturesA());
        Dispatch(GetVehicleColorsA());
        Dispatch(GetVehicleEngineA());
        Dispatch(GetVehicleGearTypesA());
        Dispatch(GetVehicleTypeA());
        Dispatch(GetVehicleSeatsA());
        Dispatch(GetVehicleModelsA());
        Dispatch(GetVehicleYearsA());
    }, [Dispatch]);
    const SubmitForm = (e) =>
        SubmitAddVehicleForm(
            e,
            VehicleData,
            VehicleImagesState,
            VehicleErrors,
            setVehicleErrors,
            Dispatch,
            Navigate,
            setShowSuccessPopup
        );
    useEffect(() => {
        if (serverError?.response?.data) {
            const err = JSON.parse(JSON.stringify(serverError?.response?.data));
            console.log(err);
            setVehicleErrors({ ...VehicleErrors, ...err });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serverError]);
    useCheckLogin(true, ["Institute"], ["Admin", "Staff"]);

    return !loading ? (
        <div className="flex w-full flex-col items-center bg-[#F7F7F7] py-10">
            {ShowSuccessPopup && <VehiclePopup />}
            <Steps />
            <form
                className="flex w-full flex-col items-center"
                onSubmit={SubmitForm}
            >
                <VehicleInfo
                    Colors={Colors}
                    EngineCapacity={EngineCapacity}
                    GearType={GearType}
                    Seats={Seats}
                    Types={Types}
                    cars={Cars}
                    years={years}
                    Manufacturers={Manufacturers}
                    setVehicleData={setVehicleData}
                    VehicleData={VehicleData}
                    VehicleErrors={VehicleErrors}
                    setVehicleErrors={setVehicleErrors}
                />

                <VehicleImages
                    VehicleImagesState={VehicleImagesState}
                    setVehicleImagesState={setVehicleImagesState}
                    VehicleErrors={VehicleErrors}
                    setVehicleErrors={setVehicleErrors}
                />

                <VehicleCredentials
                    setVehicleData={setVehicleData}
                    VehicleData={VehicleData}
                    VehicleErrors={VehicleErrors}
                    setVehicleErrors={setVehicleErrors}
                />

                {/* <button
          type="submit"
          className="mt-10 cursor-pointer rounded-xl border-none bg-[#A1A3EF] py-[10px] px-5 text-base text-white outline-none"
        >
          Save & Continue
        </button> */}
                <button
                    type="submit"
                    class="focus:ring-blue-300 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mt-10 mb-2 cursor-pointer rounded-lg border-0 bg-[#4c34ad] px-10 py-3  text-4xs font-medium text-white outline-none hover:bg-[#a1a3ef] focus:outline-none focus:ring-4 md:text-2xs lg:text-[22px]"
                >
                    Save & Continue
                </button>
            </form>
        </div>
    ) : (
        <h1 className="mt-20">Loading</h1>
    );
};

const AddVehicle = () => {
    return <InstituteTemplate Element={AddVehicleChild} />;
};

export default AddVehicle;
