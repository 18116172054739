import React, { useState } from "react";

import { DateInput } from "../../../../../../../Components/InputComps/DateComps";
import {
    Input,
    SelectList,
} from "../../../../../../../Components/InputComps/InputComps";
import { LicenseTypesOption } from "../../../OptionsArr/OptionsArr";
import LicenseImagePreviewer from "../../../../../../../Components/ImagePreviewer/LicenseImagePreviewer";
import { BaseUrl } from "../../../../../../../Actions/Base";

export const SpecialLicenseDetailsComp = ({
    value,
    index,
    onChange,
    RemoveInstructor,
    InstructorData,
    SpecialLicenseDetails,
    setSpecialLicenseDetails,
    // OnChange,
    updateOnly,
    readOnly,
    SLicenseImages,
}) => {
    // console.log("valuesof special license after map", value);
    // console.log("special license after map PICTURES", SLicenseImages);

    // Check if updateOnly is true and there are existing details
    // const [isHovered, setIsHovered] = useState(false);
    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

    const SpecialLicenseImage = `${BaseUrl}/api/images/Instructors?url=${value?.SpecialLicenseImage}`;

    const handleMouseEnter = (index) => {
        setHoveredRowIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredRowIndex(null);
    };
    const isHovered = index === hoveredRowIndex;
    return (
        <>
            <tr className=" border-0 border-b border-solid border-[#A5C9FF]">
                <td class="h-full px-6 text-3xs font-medium text-black">
                    <div className=" mb-5 flex  w-full items-center justify-center [&>*]:max-w-full">
                        <Input
                            licenseInput={true}
                            Placeholder="0546"
                            readOnly={true}
                            State={
                                value?.LicenseNumber ||
                                value?.SpecialLicenseNumber ||
                                ""
                            }
                            onChange={(e) =>
                                onChange(
                                    e,
                                    index,
                                    "LicenseNumber",
                                    "License Number"
                                )
                            }
                        />
                    </div>
                </td>
                <td class="  px-6 pb-0 text-3xs text-black">
                    <div className=" mb-3 [&>*]:w-full [&>div>span>input]:shadow-none">
                        <DateInput
                            licenseInput={true}
                            readOnly={true}
                            Id={"LicenceExpiry"}
                            type={"licence-datee"}
                            State={
                                value?.LicenceExpiry ||
                                value?.SpecialLicenseExpiry ||
                                ""
                            }
                            onChange={(e) =>
                                onChange(
                                    e,
                                    index,
                                    "LicenceExpiry",
                                    "License Date"
                                )
                            }
                        />
                    </div>
                </td>
                <td class=" px-6  text-3xs text-black">
                    <div className=" mb-5 w-full [&>*]:max-w-full">
                        <SelectList
                            licenseInput={true}
                            readOnly={true}
                            // Label="Speciality"
                            Id="LicenseTypeFK"
                            defaultValue=""
                            Text="License Type"
                            OptionsArr={LicenseTypesOption}
                            State={
                                value?.LicenseTypeFK ||
                                value?.SpecialLicenseTypeFK ||
                                ""
                            }
                            onChange={(e) =>
                                onChange(
                                    e,
                                    index,
                                    "LicenseTypeFK",
                                    "LicenseDetails Speciality"
                                )
                            }
                        />
                    </div>
                </td>

                <td
                    class=" px-6  text-3xs text-black"
                    // onMouseEnter={() => setIsHovered(true)}
                    // onMouseLeave={() => setIsHovered(false)}

                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={() => handleMouseLeave(index)}
                >
                    {/* <div className="w-full [&>*]:max-w-full"> */}
                    {/* {!isHovered && ( */}
                    <LicenseImagePreviewer
                        LicenseDetail={false}
                        readOnly={readOnly}
                        Id={"SLicenseImage"}
                        // isHovered={isHovered}
                        PreviewStyle=""
                        BrowseBtnStyle=""
                        ContainerStyle=""
                        onChange={onChange}
                        // onChange={(e) =>
                        //     onChange(e, "SpecialLicenseImage")
                        // }
                        ImgUrl={
                            updateOnly
                                ? SpecialLicenseImage || SLicenseImages[index]
                                : SLicenseImages[index]
                        }
                    />
                    {/* )} */}
                    {isHovered && (
                        <LicenseImagePreviewer
                            LicenseDetail={true}
                            isHovered={isHovered}
                            readOnly={readOnly}
                            Id="SLicenseImage"
                            PreviewStyle=""
                            BrowseBtnStyle=""
                            ContainerStyle=""
                            onChange={(e) =>
                                // setLicenseImg(
                                //     e.target
                                //         .value
                                // )
                                onChange(e, "SLicenseImage", "SLicenseImage")
                            }
                            ImgUrl={
                                updateOnly
                                    ? SpecialLicenseImage ||
                                      SLicenseImages[index]
                                    : SLicenseImages[index]
                            }
                        />
                    )}
                    {/* </div> */}
                </td>

                <td class="relative whitespace-nowrap px-6 text-center text-3xs font-medium">
                    <div className="flex items-center justify-center ">
                        <button
                            onClick={() => RemoveInstructor(index)}
                            type="button"
                            className=" bottom-[14px] left-0 cursor-pointer  rounded-[10px] border-0 bg-[#4C34AD]  px-4 py-1 text-4xs font-medium text-white md:mb-[2px] md:px-8 md:py-2 lg:py-2"
                        >
                            Delete
                        </button>
                    </div>
                </td>
            </tr>
        </>
    );
};
