import React from "react";

import CoursesProgresReport from "../../../../Components/CourseProgressReport/CoursesProgresReport";

const CourseProgressIns = ({ CoursePackage, CourseProgress }) => {
    const { OnlineHours, InClassHours, DrivingHours } = CoursePackage;
    console.log("CourseProgress", CourseProgress);
    return (
        <div className="w-full sm:w-11/12 xl:w-4/5">
            <h3 className="Boldfont px-6 pb-6 text-[30px] sm:px-8 md:px-10 lg:px-12 xl:px-14 2xl:px-16">
                Client Progress
            </h3>
            <div className="flex w-full flex-col gap-14">
                <CoursesProgresReport
                    ClassType={"Overall Course Completion"}
                    // TotalClasses={DrivingHours + InClassHours + OnlineHours}
                    // TotalClasses={CourseProgress.TotalClasses}
                    // ClassesTaken={CourseProgress?.length}
                    TotalClasses={CourseProgress?.overallTotal}
                    ClassesTaken={CourseProgress?.overAllTakenClasses}
                    Index={1}
                />
                <CoursesProgresReport
                    ClassType={"Online Course Completion"}
                    // TotalClasses={OnlineHours}
                    TotalClasses={CourseProgress.classCounts.Online.total}
                    ClassesTaken={CourseProgress.classCounts.Online.completed}
                    // ClassesTaken={CourseProgress?.OnlineHours?.length || 0}
                    Index={2}
                    Color={"#5456C7"}
                />

                <CoursesProgresReport
                    ClassType={"In Class Course Completion"}
                    // TotalClasses={InClassHours}
                    // ClassesTaken={CourseProgress?.InClassHours?.length || 0}
                    TotalClasses={CourseProgress.classCounts.InClass.total}
                    ClassesTaken={CourseProgress.classCounts.InClass.completed}
                    Index={3}
                    Color={"#7F82F9"}
                />

                <CoursesProgresReport
                    ClassType={"Driving Course Completion"}
                    // TotalClasses={DrivingHours}
                    // ClassesTaken={CourseProgress?.DrivingHours?.length || 0}
                    TotalClasses={CourseProgress.classCounts.Driving.total}
                    ClassesTaken={CourseProgress.classCounts.Driving.completed}
                    Index={4}
                    Color={"#A1A3EF"}
                />
            </div>
        </div>
    );
};

export default CourseProgressIns;
