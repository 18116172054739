import React, { createContext, useContext, useState } from 'react';

// Create a context
const TimetableContext = createContext();

// Create a provider component
export const TimetableProvider = ({ children }) => {
  const [userSelectedDbTimetable, setUserSelectedDbTimetable] = useState([]);

  return (
    <TimetableContext.Provider value={{ userSelectedDbTimetable, setUserSelectedDbTimetable }}>
      {children}
    </TimetableContext.Provider>
  );
};

// Create a custom hook to use the context
export const useTimetable = () => {
  return useContext(TimetableContext);
};
