import axios from "axios"
import { CheckLoginServer } from "../Helpers/CheckLogin";
import { BaseUrl } from "./Base";

const axiosInstance = axios.create({
    baseURL: BaseUrl,
    withCredentials: true,
})
export const CreateAdminCourseA = (CourseData, setSuccess) => async (dispatch) => {
    try {
        dispatch({
            type: "Admin_AddCourseRequest"
        })

        console.log("CourseData", CourseData)
        const { data } = await axiosInstance.post(`/api/course/create`, CourseData);

        dispatch({
            type: "Admin_AddCourseSuccess",
            payload: data
        })
        setSuccess(true)
    }
    catch (err) {
        CheckLoginServer(err?.response?.data, dispatch)
        dispatch({
            type: "Admin_AddCourseFailure",
            payload: err
        })
    }
}