import React from "react";

const ClassHoursSchedule = ({ setShowTimeSlots, EnrollmentData, classHoursData, isClassHours }) => {
  // Function to display more than one slot with some gap
  const renderTimeSlots = (day) => {
    let timeSlots;
    if (isClassHours) {
      timeSlots = classHoursData?.DayandHoursData?.Schedule?.[day];
    } else {
      timeSlots = EnrollmentData?.StudentData?.Schedule?.[day];
    }
    
    // Check if timeSlots is an array (if you are storing multiple slots as an array)
    if (Array.isArray(timeSlots)) {
      // Map over the array and render each time slot with a space or line break
      return timeSlots.map((slot, index) => (
        <React.Fragment key={index}>
          <p className="text-3xs">{slot}</p>
        </React.Fragment>
      ));
    }

    // Render as a single slot if it's not an array
    return <p className="text-3xs">{timeSlots}</p>;
  };

  // Days of the week
  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

  // Filter days that have no time slots
  const daysWithSlots = days.filter(day => {
    const timeSlots = isClassHours
      ? classHoursData?.DayandHoursData?.Schedule?.[day]
      : EnrollmentData?.StudentData?.Schedule?.[day];
    return timeSlots && (Array.isArray(timeSlots) ? timeSlots.length > 0 : timeSlots);
  });

  return (
    <div className="flex w-full flex-col gap-2">
      <h2 className="pb-6 text-xs sm:text-base md:text-lg xl:text-[38px] font-semibold">Your Class Schedule</h2>
      {daysWithSlots.map((day) => (
        <div className="flex gap-8" key={day}>
          <div className="w-[80px]">
            <p className="text-3xs">{day}</p>
          </div>
          <div className="flex gap-4">
            <span className="material-symbols-outlined text-[#A1A3EF]">
              radio_button_checked
            </span>
            <div className="flex flex-col gap-2">
              {renderTimeSlots(day)}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ClassHoursSchedule;
