import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../forget-password/forgotPassword.css";
import { useAuthContext } from "../../context/authContext";
import { OpenLoginSignUp } from "../../Actions/ToggleSignupA";
import { BaseUrl } from "../../Actions/Base";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha-v2";

const NewPassword = () => {
  const Dispatch = useDispatch();

  const [Errors, setErrors] = useState();
  const { error } = useSelector((Store) => Store.LoginSignupReducer);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(true);
  const [cview, setCivew] = useState(true);
  const [captchaError, setCaptchaError] = useState('');
  const [ captcha, setCaptcha] = useState()

  useEffect(() => {
    setErrors(JSON.parse(JSON.stringify(error)));
  }, [error]);

  console.log(Errors);

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPass, setConfirmNewPass] = useState("");
  const { setRresetPass, setChangeToNewPassWord, resetPass } = useAuthContext();
  const [confirmNewPassError, setConfirmNewPassError] = useState("");

  const OnClickFun = () => {};

  const axiosInstance = axios.create({
    baseURL: BaseUrl,
    withCredentials: true,
  });

  let InputBoxStyle = `w-full rounded-[6px] outline-none bg-[#F3F6F7] text-gray-400 h-[45px]
    text-4xs md:text-[16px]   lg:text-[16px]     xl:[16px]   
    py-[6px]                    xl:py-3
    px-[8px]                    xl:px-[22px]    2xl:px-7`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("submit", newPassword);

    if (confirmNewPass !== newPassword) {
      setConfirmNewPassError("new Confirm password do not match with password");
      return
    }
    if(!captcha) {
      setCaptchaError('Please click the captcha to proceed!')
      return;
     }

    const payload = {
      Password: newPassword,
      ConfirmPassword: confirmNewPass
    };

    try {
      setLoading(true);
      const token = JSON.parse(localStorage.getItem("token"));
      const res = await axiosInstance.post(
        `/api/update-password/${token}`,
        payload
      );
      console.log(res);
      if (res?.status === 200) {
        if(resetPass) {
          setRresetPass(false);
          Dispatch(OpenLoginSignUp("Sign In", true));
        }else {
          setChangeToNewPassWord(false)
        }
        localStorage.removeItem("token")
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      localStorage.removeItem("token");
    }
  };

  function onChangee(value) {
    setCaptcha(value);
  }

  useEffect(() => {
    setErrors(JSON.parse(JSON.stringify(error)));
  }, [error]);

  const handleClose = () => {
    if(resetPass) {
    setRresetPass(false);
    }else {
      setChangeToNewPassWord(false)
    }
  };

  const handleChange = (e) => {
    setConfirmNewPass(e.target.value);
 
  };

  const OnPasswordChange = (e) => {
    delete Errors?.Invalid;
    let Check = true;

    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/;
    Check = regex.test(e.target.value);

    setNewPassword(e.target.value);

    if (e.target.value === "") setErrors({ ...Errors });
    else if (!Check)
      setErrors({
        ...Errors,
        Password:
          "Password is must contain a capital letter, a small letter,a number and a special character with minimum length 8",
      });
    else {
      delete Errors?.Password;
      setErrors(Errors);
    }
  };
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 "
    style={{zIndex: '200000'}}>
      <form
        onSubmit={handleSubmit}
        className="BG_IMAGEE w-[90%] rounded-[24px] bg-[#e1e7f0]  py-6 px-6 text-white sm:w-[80%] sm:p-10 md:w-[686px] md:py-10 md:px-16  2xl:pb-14 3xl:pb-14"
      >
        <span
          onClick={handleClose}
          class="material-symbols-outlined absolute right-[1rem] top-[1rem] cursor-pointer font-semibold text-primary"
          style={{
            fontSize: "30px",
          }}
        >
          close
        </span>
        <div className="flex  w-full items-center justify-center">
          <img
            className="h-[40px] w-[220px]  md:h-[60px] md:w-[328px]"
            src={require("../Assets/forgotLog.png")}
            alt=""
          />
        </div>

        <div className="mt-[30px]  mb-[1rem] flex w-full flex-col items-center justify-center text-[#1D1D1D]">
          <h2 className="text-3xs font-bold md:text-xs ">
            Enter Your New Password
          </h2>
          {/* <p className=" my-3 mt-3 text-center text-4xs font-semibold md:px-5 md:text-2xs  2xl:text-xs">
           
            Please check your email, we have sent verification code to reset your password
           
          </p> */}
        </div>

        {/* inputs */}
        <h4 className="font-normal text-[red]"> {Errors?.Password} </h4>
        <div>
          <span className="flex flex-col">
            <input
              id="newPass"
              className={`${" border-[1px]  border-solid border-[#BCBCBC]"} ${InputBoxStyle} max-w-[650px ] `}
              type={view ? `password` : "text"}
              placeholder={!view ? `Enter New Pasword` : "************"}
              required
              value={newPassword}
              onChange={OnPasswordChange}
            />

            <div
              className="relative w-full "
              style={{ top: "-38px", position: "relative" }}
            >
              {view ? (
                <span
                  onClick={() => setView((prev) => !prev)}
                  className="material-symbols-outlined absolute top-[5px] right-[13px] cursor-pointer text-[16px] text-gray-400 md:top-[5px] xl:top-0"
                  style={{ fontSize: "30px" }}
                >
                  visibility
                </span>
              ) : (
                <span
                  onClick={() => setView((prev) => !prev)}
                  className="material-symbols-outlined absolute top-[5px] right-[13px] cursor-pointer text-[16px] text-gray-400 md:top-[5px] xl:top-0"
                  style={{ fontSize: "30px" }}
                >
                  visibility_off
                </span>
              )}
            </div>
          </span>
          
        </div>
        <div className="mt-3">
          {confirmNewPassError ? (
            <h4 className="font-normal text-[red]"> {confirmNewPassError} </h4>
          ) : null}

          <span className="flex flex-col">
            <input
              id="cNewPass"
              className={`${" border-[1px]  border-solid border-[#BCBCBC]"} ${InputBoxStyle} max-w-[650px ] `}
              type={cview ? `password` : "text"}
              placeholder={!cview ? `Confirm New Password` : "************"}
              required
              value={confirmNewPass}
              onChange={handleChange}
            />

            <div
              className="relative w-full "
              style={{ top: "-38px", position: "relative" }}
            >
              {cview ? (
                <span
                  onClick={() => setCivew((prev) => !prev)}
                  className="material-symbols-outlined absolute top-[5px] right-[13px] cursor-pointer text-[16px] text-gray-400 md:top-[5px] xl:top-0"
                  style={{ fontSize: "30px" }}
                >
                  visibility
                </span>
              ) : (
                <span
                  onClick={() => setCivew((prev) => !prev)}
                  className="material-symbols-outlined absolute top-[5px] right-[13px] cursor-pointer text-[16px] text-gray-400 md:top-[5px] xl:top-0"
                  style={{ fontSize: "30px" }}
                >
                  visibility_off
                </span>
              )}
            </div>
          </span>
        </div>

        <div className="mt-4">
        {
          captchaError ? <h4 className="font-normal text-[red]"> {captchaError} </h4> : null
        }
                  <ReCAPTCHA
                    sitekey="6Lecb_IoAAAAANjflXMmXPZiBMaU0clxSmxPG9f9"
                    onChange={onChangee}
                  />
                  </div>

        <div className="mt-8">
          <span
            className={`submit-btn-box-shadow relative mt-4 flex w-full justify-center`}
          >
            <button
              className={`h-[45px] w-full cursor-pointer rounded-[8px] border-none bg-primary  py-2
                           text-[16px] text-white md:text-[18px]  xl:py-3 
                            xl:text-[18px] `}
              type="submit"
              onClick={OnClickFun}
            >
              Save New Password
              {loading ? (
                <span className="ml-4">
                  <svg
                    aria-hidden="true"
                    role="status"
                    class="me-3 inline h-5 w-5 animate-spin text-white"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              ) : null}
            </button>
          </span>
        </div>
      </form>
    </div>
  );
};

export default NewPassword;
