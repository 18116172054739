import React, { useContext, useState } from "react";
import { SocketContext } from "../../../../App";
import { useSelector } from "react-redux";

function ChatInput({ handleSendMessage }) {
    const [msg, setMsg] = useState("");
    const { UserInfo } = useSelector((state) => state.LoginSignupReducer);

    const sendChat = (e) => {
        e.preventDefault();
        if (msg.trim() || msg.length > 0) {
            handleSendMessage(msg);
            setMsg("");
        }
    };

    const socket = useContext(SocketContext);
    const handleTyping = () => {
        socket?.emit("typing", UserInfo?.UserId);
    };
    return (
        <form onSubmit={(e) => sendChat(e)} className=" flex flex-col  gap-2 ">
            {/* <div className="">
                <p>Someone is typing...</p>
            </div> */}
            <input
                onKeyDown={handleTyping}
                type="text"
                value={msg}
                onChange={(e) => setMsg(e.target.value)}
                placeholder="Type your message here..."
                className=" w-full break-words border-none bg-[#F5F6FA] py-4 text-sm text-2xs placeholder-[#3B86FF] shadow-none focus:outline-none"
            />
            <div className="flex gap-5">
                <img
                    src={require("../../assets/Path 304.svg").default}
                    alt=""
                    srcSet=""
                    width={24}
                    height={24}
                />

                <img
                    src={require("../../assets/Path 305.svg").default}
                    alt=""
                    srcSet=""
                    width={24}
                    height={24}
                />
                <img
                    src={require("../../assets/Path 306.svg").default}
                    alt=""
                    srcSet=""
                    width={24}
                    height={24}
                />
            </div>
        </form>
    );
}

export default ChatInput;
