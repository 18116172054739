import React, { useState } from "react";
import "./LoginAndRegister.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { OpenLoginSignUp, ResetLoginSignUp } from "../../Actions/ToggleSignupA";
import UserSigning from "./Components/UserSigning/UserSigning";
import InstituteSide from "./Components/InstituteSide/InstituteSide";

let TopLoginRegisterBtns = `absolute border-none   rounded-t-[28px]  cursor-pointer Boldfont hidden md:inline-block 
            -top-9   lg:-top-11 xl:-top-14 2xl:-top-16
            py-[6px] lg:py-2 xl:py-3 2xl:py-4
            px-20    lg:px-24 xl:px-28 2xl:px-32 
            text-2xs lg:text-xs xl:text-base 2xl:text-[30px]`;

const SignUp = () => {
    const { error, loading } = useSelector((Store) => Store.LoginSignupReducer);
    const { AuthPageName, Navigation, Done } = useSelector(
        (Store) => Store.ToggleSignupReducer
    );

    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const [ScreenSize, setScreenSize] = useState();

    useEffect(() => {
        window.addEventListener("resize", () => {
            setScreenSize(Number(window.innerWidth));
        });
    }, []);

    useEffect(() => {
        if (Object.keys(error).length === 0 && Done && Navigation) {
            Dispatch(ResetLoginSignUp(false));
            Navigate("/");
        } else if (Object.keys(error).length === 0 && Done && !Navigation)
            Dispatch(ResetLoginSignUp(false));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Navigate, error, Done, Navigation]);

    return AuthPageName ? (
        <div
            className="LightBg"
            onClick={(e) => {
                Dispatch(OpenLoginSignUp(false, false));
            }}
        >
            <div
                className="MainParent bgGradient"
                onClick={(e) => e.stopPropagation()}
            >
                {/* {!loading
                        ? <div className="ImageContainer">
                            <div className='w-full h-80 bg-[#000000cc] ' > </div>
                        </div>
                        : null
                    } */}
                <div
                    className="FormParent bg-transparent md:bg-transparent"
                    style={{ backgroundColor: "transparent" }}
                >
                    {AuthPageName === "Register" ? (
                        <InstituteSide />
                    ) : (
                        <UserSigning
                            ScreenSize={ScreenSize}
                            TopLoginRegisterBtns={TopLoginRegisterBtns}
                        />
                    )}
                </div>
            </div>
        </div>
    ) : null;
};

export function SubmitButton({ AuthPageName, type, OnClickFun }) {
    return (
        <span
            className={`submit-btn-box-shadow relative  flex w-full max-w-[575px]  justify-center ${
                AuthPageName === "Sign In" ? "mt-[7rem]" : "mt-16"
            }`}
        >
            <img
                className="xl-top-[-62px] pointer-events-none absolute   top-[-61px] left-[60%]  w-[103px]  -translate-x-1/2 md:top-[-62px]  lg:top-[-62px] lg:w-[103px] xl:w-[103px]"
                src={require("./Assets/Sign up Avater.png")}
                alt=""
            />
            <button
                className={`w-full cursor-pointer rounded-[8px] border-none bg-primary  py-2
                           text-[16px] text-white  xl:py-3 
                            xl:text-[18px]     2xl:text-base`}
                type={type}
                onClick={OnClickFun}
            >
                {AuthPageName}
            </button>
        </span>
    );
}

export default SignUp;
