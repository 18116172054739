import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TopSection from "./components/top-section/TopSection";
import Footer from "../../../Components/Footer/Footer";
import CourseTiles from "../Components/CourseTiles/CourseTiles";
import { useDispatch, useSelector } from "react-redux";
import { GetLocalStorage } from "../../../Helpers/LocalStorage/LocalStorage";
import { GetLicenseTypeCourse } from "../../../Actions/CourseA";
import LoadingSpinner from "../../../Components/LoadingSpinner/LoadingSpinner";
import { DataForSubcategoriess } from "../../../dataString/dataForLicenseAndVehicles";

const SublicenseAllCourses = () => {
  const [FilteredCount, setFilteredCount] = useState(0);
  const { LicenseTypeId } = useParams();
  const Dispatch = useDispatch();
  const sublicenseIndex = GetLocalStorage("SublicenseIndex");
  const { Courses, loading } = useSelector((Store) => Store.CourseReducer);
  console.log("LicenseTypeId", LicenseTypeId);

  let sublicenseCourses = Courses?.SubLicenseTypes[sublicenseIndex];
  console.log("sublicenseCourses===>", sublicenseCourses);

  useEffect(() => {
    if (LicenseTypeId) Dispatch(GetLicenseTypeCourse(LicenseTypeId));
  }, [Dispatch, LicenseTypeId]);

  console.log("Courses>", Courses);
  // console.log("SublicenseAllCourseslocation", location)
  const [ref, setref] = useState({});

  const SublicenseClass = sublicenseCourses?.SubLicenseTypeName.toLowerCase();
  const mapping = {
    "class g2" : DataForSubcategoriess?.licenseTypeG2,
    "class g1" : DataForSubcategoriess?.licenseTypeG1,
    "class g" : DataForSubcategoriess?.licenseTypeG,
    "class m" : DataForSubcategoriess?.licenseTypeM,
    "class m1" : DataForSubcategoriess?.licenseTypeM1,
    "class m2" : DataForSubcategoriess?.licenseTypeM2,
    "m, m1, or m2 with condition 1 (motor tricycle)" : DataForSubcategoriess?.condition1,
    "m, m1, or m2 with condition l (lsm/moped)" : DataForSubcategoriess?.conditionL,
  };

  const defaultData = {
    Heading: "Heading",
    SubHeading: "SubHeading",
    SecondSubHeading: "SecondSubHeading",
    ListingSecPara: "",

  }



  const data = mapping[SublicenseClass] || defaultData;

  const Heading = data?.HeadingText;
  const SubHeading = data?.SubHeading;
  const SecondSubHeading = data?.SecondSubHeading;
  const ListingSecPara = data?.ListingSecPara;

  return (
    <>
      {!loading ? (
        <div>
          <TopSection
            headingText={Heading && Heading}
            paraText={SubHeading && SubHeading}
            SecondSubHeading={SecondSubHeading && SecondSubHeading}
            sublicense={true}
          />
          <div className="flex w-full items-center justify-center bg-backgroundWhiteColor">
            <div className="w-full px-3 md:px-0  md:w-[820px] text-center">
              <h2 className="py-5 text-black">
                {sublicenseCourses?.SubLicenseTypeName} License
              </h2>

              <p className="bg-backgroundWhiteColor  text-center text-4xs font-thin text-black md:text-3xs lg:text-2xs xl:text-base">
                {ListingSecPara === "" ? "Paragraph text needed" :  ListingSecPara}
              </p>
            </div>
          </div>

          <div className="top top relative flex h-fit  w-full flex-row flex-wrap items-center justify-center gap-5 bg-backgroundWhiteColor px-10 pt-10">
            <CourseTiles
              CoursesInfo={sublicenseCourses?.Courses}
              setref={setref}
              setFilteredCount={setFilteredCount}
            />
          </div>
          <Footer />
        </div>
      ) : (
        <LoadingSpinner
          Height="[500px]"
          Width="[99%]"
          Top="[80px]"
          Left="0"
          Position="relative"
        />
      )}
    </>
  );
};

export default SublicenseAllCourses;
