import axios from 'axios';
import { CheckLoginServer } from '../Helpers/CheckLogin';
import { BaseUrl } from './Base';

const axiosInstance = axios.create({
    baseURL: BaseUrl,
    withCredentials: true,
})
export const AddBookA = (BookData, setSuccess) => async (dispatch) => {
    try {

        dispatch({
            type: "AddBookRequest",
        })

        const { data } = await axiosInstance.post(`/api/Book/Create`, BookData);

        dispatch({
            type: "AddBookSuccess",
            payload: data,
        })
        setSuccess(true)
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch)
        dispatch({
            type: "AddBookError",
            payload: error
        })
    }
}
export const GetBooksA = () => async (dispatch) => {
    try {

        dispatch({
            type: "GetAllBooksRequest",
        })

        const { data } = await axiosInstance.get(`/api/Books`);

        dispatch({
            type: "GetAllBooksSuccess",
            payload: data,
        })

    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch)
        dispatch({
            type: "GetAllBooksError",
            payload: error
        })
    }
}
export const GetStudentBooksA = () => async (dispatch) => {
    try {

        dispatch({
            type: "GetAllBooksRequest",
        })

        const { data } = await axiosInstance.get(`/api/Students/Books`);

        dispatch({
            type: "GetAllBooksSuccess",
            payload: data,
        })

    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch)
        dispatch({
            type: "GetAllBooksError",
            payload: error
        })
    }
}