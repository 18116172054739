import React, { useRef, useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Button,
  IconButton,
  useMediaQuery 
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { verifingOtp } from "./api";
import { GetStudentNumberAndSendOTP } from "../../Actions/InstructorA";
import { useDispatch } from "react-redux"; // For dispatching actions


const OTPModal = ({ open, handleClose, onStdudentVerifing, EventId, setOpenCamera, setOtpAPIResponse, otpAPIResponse, setOpenBypassOTPModal, setOpenOTPModal }) => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width:635px)");

  const inputRefs = useRef([]);

  const dispatch = useDispatch(); // Redux dispatch
 
  useEffect(() => {
    if (EventId) {
      console.log("event id is:",EventId)
      // Dispatch the action to get student number and send OTP
      // dispatch(GetStudentNumberAndSendOTP(EventId));
    }
  }, [EventId, dispatch]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (!isNaN(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setError("");

      if (value && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = otp.every((digit) => digit !== "" && !isNaN(digit));
    if (isValid) {
      // Handle OTP submission logic here
      setIsLoading(true);
      setOpenCamera(true)
       const response = await verifingOtp(otp.join(""));
       console.log("response in otp verigying function is:",response)
       const otpResponse = response.message;
       console.log("otpResponse in otp verigying function is:",otpResponse)
       setOtpAPIResponse(otpResponse)
       setIsLoading(false);
      // if (response.message === "verified") {
      //   onStdudentVerifing(Boolean(response.message));
       
      // } else if (response.error) setError(response.error);
    } else {
      setIsLoading(false);
      setError("Please enter the OTP shared by student");
    }
  };

  const handleBypassOtpClick = () =>{
    setOpenOTPModal(false)
    setOpenBypassOTPModal(true)
  }

  return (
    <Dialog open={open} aria-labelledby="dialog-title"
    PaperProps={{
        style: {
          // width: "35%", // Set dialog width to 30% of the screen
          width: isMobile ? "100%" : "35%", // Change width to 80% for mobile screens, 35% otherwise
          borderRadius: "19px", // Border radius of 20px
          paddingInline: isMobile ? "15px" : "",
        },
      }}>
      <DialogTitle
        id="dialog-title"
        className="w-full flex justify-center relative"
        style={{ textAlign: "center", marginBottom: "0px" }} // Center the title text
      >
        <span className="text-center mt-[10px]">Please Insert OTP To Start The Class</span>
        <IconButton
          onClick={handleClose}
          size="small"
          // sx={{ backgroundColor: "transparent" }}
          sx={{
            position: "absolute",
            top: isMobile ? 5 : 8,
            right: isMobile ? 0 : 8,
            backgroundColor: "transparent",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent style={{ marginTop: "-20px" }}>
          <Typography className="text-center mt-[-20px]">
            A verification code has been sent to{" "}
            <span className="font-bold">Student Name</span> phone number. Please
            enter the code to start the class.
          </Typography>
          <div className="mt-4 flex justify-center space-x-2">
            {otp.map((digit, index) => (
              <TextField
                key={index}
                value={digit}
                onChange={(e) => handleChange(e, index)}
                variant="outlined"
                inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                className="h-12 w-12"
                inputRef={(el) => (inputRefs.current[index] = el)}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            ))}
          </div>
          {error && (
            <Typography color="error" className="!my-4 text-center">
            {error}
            </Typography>
          )}
          {otpAPIResponse === undefined && (
            <Typography color="error" className="text-center !my-4">
              Wrong OTP entered
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <div className="flex gap-[10px] lg:gap-[15px] w-full justify-center py-5">
          <button
              className="w-[130px] lg:w-[167px] h-[40px] lg:h-[50px] rounded-[12px] text-[16px] lg:text-[20px]"
              style={{ border: "1px solid #B5AAFF" }}
              onClick={handleBypassOtpClick}
            >
              Bypass OTP
            </button>

            <Button
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: "#B5AAFF", // Apply your color
                color:"#FFFFFF",
                width: isMobile ? "130px" : "166px",
                height: isMobile ? "37px" :"50px",
                fontSize: isMobile ? "16px" : "20px"
              }}
              disabled={isLoading}
            >
              {!isLoading ? "Verify" : "Verifing..."}
            </Button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default OTPModal;
