import React from "react";

const InVehicleRecordingMenu = ({ PageName, IconName }) => {
  const Color = PageName === IconName ? "#4C34AD" : "#fff";
  return (
    <div className="ml-0 flex items-center gap-4">
      <svg
        version="1.1"
        x="0px"
        y="0px"
        width="24px"
        height="24"
        viewBox="0 0 362.731 362.731"
        fill={Color}
      >
        <g>
          <path
            d="M242.848,91.82c-37.852-5.338-76.197-8.044-113.969-8.044S52.764,86.482,14.912,91.82c-2.02,0.284-3.664,1.767-4.154,3.745
		c-14.344,57.737-14.344,113.867,0,171.597c0.49,1.979,2.135,3.461,4.154,3.746c37.859,5.339,76.209,8.046,113.982,8.046
		c0,0-0.002,0,0,0c37.77,0,76.113-2.707,113.953-8.046c2.02-0.285,3.662-1.768,4.154-3.746c14.338-57.717,14.338-113.847,0-171.596
		C246.51,93.587,244.867,92.105,242.848,91.82z"
          />
          <path
            d="M360.01,91.916c-1.67-0.855-3.68-0.703-5.201,0.393l-80,57.599c-1.445,1.04-2.227,2.77-2.055,4.541
		c1.734,17.857,1.736,35.971,0.004,53.836c-0.172,1.77,0.609,3.5,2.055,4.54l79.996,57.599c0.867,0.624,1.893,0.942,2.922,0.942
		c0.779,0,1.56-0.182,2.279-0.55c1.67-0.855,2.721-2.574,2.721-4.45V96.366C362.731,94.489,361.68,92.771,360.01,91.916z"
          />
        </g>
      </svg>
      <p className="font-bold text-white">In Vehicle Records</p>
    </div>
  );
};

export default InVehicleRecordingMenu;
