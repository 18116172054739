import { Link } from "react-router-dom";

export function RatingBtns({ value }) {
    console.log("Value in ratingBtns is:",value)
    return (
        <div
            className={`mt-6 flex w-full items-end justify-between gap-3
    md:w-[100%] md:gap-4 lg:gap-4 xl:gap-6  `}
        >
            <div className="flex flex-col">
                <span className="flex gap-1">
                    {[...new Array(5)].map((value, index) => {
                        return (
                            <img
                                className="w-4"
                                key={index + 0.6}
                                src={
                                    require("../../../CourseOverview/Assets/StarIcon.svg")
                                        .default
                                }
                                alt=""
                            />
                        );
                    })}
                </span>
                <span className="SemiBold text-3xs">
                    <img
                        className="w-4"
                        src={
                            require("../../../LandingPage/PopularCourses/Assets/Thumb Icon.svg")
                                .default
                        }
                        alt=""
                    />{" "}
                    100%
                </span>
            </div>
            <span className="text-end">
                <h5 className="Regularfont whitespace-nowrap text-center text-[11px] font-thin md:text-[12px]">
                    {value?.Institute?.InstituteName}
                </h5>
                <h5 className="Regularfont whitespace-nowrap  text-[11px] font-thin md:text-[12px]">
                    {value?.Institute?.Country} | {value?.Institute?.State}
                </h5>
            </span>
        </div>
    );
}

export function CourseInfo({ value, Path }) {
    let Description = value?.Description;

    if (value?.Description.length > 125)
        Description =
            value?.Description.split("").slice(0, 60).join("") + " ...";

    let CourseName = value?.CourseName;

    if (value?.Description.length > 125)
        CourseName = value?.CourseName.split("").slice(0, 30).join("") + " ...";

    return (
        <Link
            to={Path}
            className="flex flex-col items-center gap-2 text-black no-underline w-full"
        >
             
                <div className="w-full">
            <h2 className="Blackfont px-2 text-center  h-6 w-full truncate  text-[12px] md:text-3xs transition-all delay-300 hover:text-clip  hover:whitespace-normal	hover:h-auto  ">
                {CourseName}
            </h2>
           

            </div>
            
           

            <p className="Regularfont h-16 bg-blend-overlay max-w-[250px] overflow-y-auto text-center text-[12px] md:text-4xs ">
                {Description}
            </p>
          
            
        </Link>
    );
}
