import React from "react";
import { NavLink } from "react-router-dom";
import Dashboard from "./Assets/Dashboard";
import Invoices from "./Assets/Invoices";
import Customers from "./Assets/Staff";
import Car from "./Assets/Car";
import Inbox from "./Assets/Inbox";
import Home from "./Assets/Home";
import Settings from "./Assets/Settings";
import "./SideBar.css";
import { useState } from "react";
import { CheckHomePage, FindValue } from "./Helpers/CheckPage";
import Clients from "./Assets/Clients";
import Staff from "./Assets/Staff";
import Instructor from "./Assets/Instructor";
import Certificates from "./Assets/Certificates";
import InVehicleRecordingMenu from "./Assets/InVehicleRecordingMenu.jsx";
import { useAuthContext } from "../../context/authContext.js";
import { Hidden } from "@mui/material";
import AllCourses from "./Assets/AllCourses.jsx";
let BtnsNames = ["/", "inbox", "vehicle", "invoice", "customer", "settings"];

const SideBar = () => {
  const [PageName, setPageName] = useState(FindValue(BtnsNames));
  const {showSidebar, setShowSidebar, hideSidebar, setHideSidebar} = useAuthContext()

  let LinkStyle =
    "flex justify-center items-center text-[12px] sm:text-[16px] py-6 w-full duration-300 SideBarBtns";

  const Classes = (isActive, PageName) => {
    if (
      window.location.href?.toString().split("/")?.includes(PageName) ||
      isActive
    ) {
      return LinkStyle + " SideBarSvgSelected";
    } else if (
      (PageName === "/" || PageName === "AddCourse") &&
      CheckHomePage(BtnsNames)
    ) {
      return LinkStyle + " SideBarSvgSelected";
    } else return LinkStyle + "SideBarSvgUnSelected";
  };

  const OnClick = (Name) => setPageName(Name);


  const sidebarClick =()=> {
    setHideSidebar(true)
    setShowSidebar(false)
  }
  console.log("showww", showSidebar)
//  
  return (
    <div
      className={`${!showSidebar && "hidden"} fixed  z-[1499]  h-screen w-[180px] sm:w-[250px] overflow-y-scroll  flex-col pt-8 sm:${hideSidebar ? "hidden" : "flex"} `}
      style={{
        background:
          "transparent linear-gradient(162deg, #AEB0FF 0%, #B05AC4 100%) 0% 0% no-repeat padding-box",
      }}
    >
      {/* arrow back  start*/}
      <div 
      onClick={sidebarClick}
      className="z-[34234234234] fixed left-[149px] sm:left-[218px] top-14 flex lg:hidden h-[55px] w-[55px] cursor-pointer items-center justify-center rounded-full border-4 border-solid border-white bg-[#4C34AD]">
        <span class="relative z-[34234234234] material-symbols-outlined cursor-pointer pl-2 text-white">
          arrow_back_ios
        </span>
      </div>
      {/* arrow back end */}
      <div className="w-full flex justify-center">
        <img
          className=" mb-[2rem] mt-[1rem] h-[42px] w-[90%]"
          src="/assets/institute-dashboard-logo.png"
          alt="logoo"
        />
      </div>
      <NavLink
        className={({ isActive }) => Classes(isActive, "/")}
        to="/"
        onClick={() => OnClick("/")}
        style={{
          justifyContent: "start",
          paddingLeft: "1rem",
          textDecoration: "none",
          borderBlock: "1px solid rgba(255, 255, 255, 0.3)",
        }}
      >
        <Home IconName="/" PageName={PageName} />
      </NavLink>

      <NavLink
        className={({ isActive }) => Classes(isActive, "revenue")}
        to="/revenue"
        onClick={() => OnClick("revenue")}
        style={{
          justifyContent: "start",
          paddingLeft: "1rem",
          textDecoration: "none",
          borderBlock: "1px solid rgba(255, 255, 255, 0.3)",
        }}
      >
        <Dashboard IconName="revenue" PageName={PageName} />
      </NavLink>

      <NavLink
        className={({ isActive }) => Classes(isActive, "inbox")}
        to="/inbox"
        onClick={() => OnClick("inbox")}
        style={{
          justifyContent: "start",
          paddingLeft: "1rem",
          textDecoration: "none",
          borderBlock: "1px solid rgba(255, 255, 255, 0.3)",
        }}
      >
        <Inbox IconName="inbox" PageName={PageName} />
      </NavLink>

      <NavLink
        className={({ isActive }) => Classes(isActive, "vehicle")}
        to="/vehicle/inventory"
        onClick={() => OnClick("vehicle")}
        style={{
          justifyContent: "start",
          paddingLeft: "1rem",
          textDecoration: "none",
          borderBlock: "1px solid rgba(255, 255, 255, 0.3)",
        }}
      >
        <Car IconName="vehicle" PageName={PageName} />
      </NavLink>

      {/* <NavLink
        className={({ isActive }) => Classes(isActive, "books")}
        to="/admin/books/list"
        onClick={() => OnClick("books")}
      >
        <Invoices IconName="books" PageName={PageName} />
      </NavLink> */}

      <NavLink
        className={({ isActive }) => Classes(isActive, "staff")}
        to="/staff/List"
        onClick={() => OnClick("staff")}
        style={{
          justifyContent: "start",
          paddingLeft: "1rem",
          textDecoration: "none",
          borderBlock: "1px solid rgba(255, 255, 255, 0.3)",
        }}
      >
        <Staff IconName="staff" PageName={PageName} />
      </NavLink>

      <NavLink
        className={({ isActive }) => Classes(isActive, "Instructors")}
        to="/Instructors/list"
        onClick={() => OnClick("Instructors")}
        style={{
          justifyContent: "start",
          paddingLeft: "1rem",
          textDecoration: "none",
          borderBlock: "1px solid rgba(255, 255, 255, 0.3)",
        }}
      >
        <Instructor IconName="Instructors" PageName={PageName} />
      </NavLink>

      <NavLink
        className={({ isActive }) => Classes(isActive, "students")}
        to="/students/list"
        onClick={() => OnClick("students")}
        style={{
          justifyContent: "start",
          paddingLeft: "1rem",
          textDecoration: "none",
          borderBlock: "1px solid rgba(255, 255, 255, 0.3)",
        }}
      >
        <Clients IconName="students" PageName={PageName} />
      </NavLink>

      <NavLink
        className={({ isActive }) => Classes(isActive, "In-Vehicle-Recording")}
        to="/in-vehicle-recording"
        onClick={() => OnClick("In-Vehicle-Recording")}
        style={{
          justifyContent: "start",
          paddingLeft: "1rem",
          textDecoration: "none",
          // borderBlock: "1px solid rgba(255, 255, 255, 0.3)",
        }}
      >
        <InVehicleRecordingMenu
          IconName="In-Vehicle-Recording"
          PageName={PageName}
        />
      </NavLink>
      <NavLink
        className={({ isActive }) => Classes(isActive, "courses")}
        to="/all/courses"
        onClick={() => OnClick("courses")}
        style={{
          justifyContent: "start",
          paddingLeft: "1rem",
          textDecoration: "none",
          borderBlock: "1px solid rgba(255, 255, 255, 0.3)",
        }}
      >
        <AllCourses
          IconName="courses"
          PageName={PageName}
        />
      </NavLink>

      <NavLink
        className={({ isActive }) => Classes(isActive, "certificates")}
        to="/certificates"
        onClick={() => OnClick("certificates")}
        style={{
          justifyContent: "start",
          paddingLeft: "1rem",
          textDecoration: "none",
          borderBlock: "1px solid rgba(255, 255, 255, 0.3)",
        }}
      >
        <Certificates IconName="certificates" PageName={PageName} />
      </NavLink>

      <NavLink
        className={({ isActive }) => Classes(isActive, "settings")}
        to="/settings"
        onClick={() => OnClick("settings")}
        style={{
          justifyContent: "start",
          paddingLeft: "1rem",
          textDecoration: "none",
          borderTop: "1px solid rgba(255, 255, 255, 0.3)",
          marginTop: "auto",
        }}
      >
        <Settings IconName="settings" PageName={PageName} />
      </NavLink>
    </div>
  );
};

export default SideBar;
