import React, { useState, useEffect } from "react";
import axios from "axios";
import { feedbackText } from "../../../../dataString/feedbackText";
import Stars from "../../../../Components/starsAndReviews/Stars";
import "./PublicFeedback.css";
import {GetLocalStorage} from "../../../../Helpers/LocalStorage/LocalStorage.js"
import { BaseUrl } from "../../../../Actions/Base.js";

const axiosInstance = axios.create({
  baseURL: BaseUrl,
  withCredentials: true,
});

const defaultFeedbackPageData = {
  Instructors: []
};

const PublicFeedback = ({ FeedbackPageData }) => {

  const HrStyle = `h-[0.8px]  bg-gray-100 opacity-50`;
  console.log("FeedbackPageData", FeedbackPageData)

  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    instructorFeedback: [],
    instituteRating: 0,
    courseRating:0,
    overallCourseReview: "",
  });
  useEffect(() => {
    // Initialize instructorFeedback based on FeedbackPageData
    if (FeedbackPageData?.Instructors) {
      setFormData({
        ...formData,
        instructorFeedback: FeedbackPageData.Instructors.map((ins) => ({
          id: ins?.InstructorId,
          style: 0,
          preparation: 0,
          punctuality: 0,
        }))
      });
    }
  }, [FeedbackPageData]);

  console.log("formData", formData)

  const handleSubmitt = async(e) => {
 
    e.preventDefault();
    if(formData.overallCourseReview.trim() === "") {
      setError("Please provide a course review.")
      return
    }


    const studentId = GetLocalStorage("UserInfo").UserId;
    const instituteCourseFK = FeedbackPageData.CoursePackage.InstituteCourseFK;
    const instituteFK = FeedbackPageData.CoursePackage.InstituteCourse.InstituteFK;

    const paylaod = {
      ...formData,
      studentId,
      instituteCourseFK,
      instituteFK
    }

    console.log("paylaod==>", paylaod)

    try {
      const response = await axiosInstance.post("/api/student/feedback", paylaod)
      if (response.status === 201) {
        console.log('Feedback submitted successfully:', response.data);
      } else {
        console.error('Error submitting feedback:', response.data);
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
    
  };





  const handleInputChange =(index, field, value)=> {
    const updatedFeedback = formData?.instructorFeedback?.map((feedback, idx)=> {
      if(index === idx){
        return {...feedback, [field]: value}
      }
      return feedback
    })

    setFormData({...formData, instructorFeedback: updatedFeedback})
  };
  const handleInstituteRatingChange =(rating)=> {
    setFormData({ ...formData, instituteRating: rating });
  }
  const handleCourseRatingChange =(rating)=> {
    setFormData({ ...formData, courseRating: rating });
  }
  const handleReviewChange =(e)=> {
    setFormData({ ...formData, overallCourseReview: e.target.value });
  };

  return (
    <div className=" w-full">
      <div className="top-text-sec w-full lg:w-[80%]">
        <h2 className="headingTwo Boldfont head pb-2 text-darkPurpal">
          Public Feedback
        </h2>
        <p className="font-segoe-ui text-4xs font-normal text-darkPurpal xl:text-2xs 2xl:text-xs ">
          Share your experience with this course, to help us make better
          decisions in future.Rate your experience between 1 to 5 Where 1 is the
          least and 5 is the most
        </p>
      </div>

      <div className="InstructorText-sec my-10 text-darkPurpal">
        <form onSubmit={handleSubmitt} className="flex flex-col gap-5">
          {FeedbackPageData?.Instructors.map((ins, ind) => {
            return (
              <>
              
                <InstructorTextSec
                  text={ins?.User?.FirstName + " " + ins?.User?.LastName}
                  index={ind}
                  handleInputChange={handleInputChange}
                  feedback={formData?.instructorFeedback[ind]}
                />
                <hr className={HrStyle} />
              </>
            );
          })}

          <InstituteRating
          rating={formData.instituteRating}
          handleRatingChange={handleInstituteRatingChange}
          />
          <hr className={HrStyle} />

          <CourseRating
          rating={formData.courseRating}
          handleCourseRatingChange={handleCourseRatingChange}
          />
          <hr className={HrStyle} />

          <OverAllCourseReview
          review={formData.overallCourseReview}
          handleReviewChange={handleReviewChange}
          />

         {error && (
            <div className="text-red-500 text-4xs xl:text-3xs 2xl:text-2xs">
              {error}
            </div>
          )}

          <div className="w-full text-center sm:text-end">
            <button
              type="submit"
              className="rounded-lg bg-violet-100 px-4 py-2 font-segoe-ui text-4xs font-medium text-white transition-all hover:bg-purpal xl:text-2xs 2xl:text-xs"
            >
              Submit Feedback
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

function InstructorTextSec({ text, handleInputChange, index, feedback }) {
  return (
    <>
      <h4 className="text-center text-4xs font-semibold sm:text-left xl:text-3xs 2xl:text-2xs">
        {text}
      </h4>
      <ol className="flex flex-col content-center gap-4">
        <li className="flex flex-col items-center justify-center sm:flex-row sm:justify-between">
          <p className="text-4xs xl:text-3xs 2xl:text-2xs">
            <span className="text-4xs font-bold xl:text-3xs 2xl:text-2xs">
              1.{" "}
            </span>
            {feedbackText.style}
          </p>
          <div>
            <Stars
            rating={feedback?.style}
            onRatingChange={(value)=> handleInputChange(index, "style", value)}
            />
          </div>
        </li>

        <li className="flex flex-col items-center justify-center sm:flex-row sm:justify-between">
          <p className="text-4xs xl:text-3xs 2xl:text-2xs">
            <span className="text-4xs font-bold xl:text-3xs 2xl:text-2xs">
              2.{" "}
            </span>{" "}
            {feedbackText.preparation}
          </p>
          <div>
            <Stars
            rating={feedback?.preparation}
            onRatingChange={(value)=> handleInputChange(index, "preparation", value)}
            />
          </div>
        </li>

        <li className="flex flex-col items-center justify-center sm:flex-row sm:justify-between">
          <p className="text-4xs xl:text-3xs 2xl:text-2xs">
            <span className="text-4xs font-bold xl:text-3xs 2xl:text-2xs">
              3.{" "}
            </span>{" "}
            {feedbackText.punctuality}
          </p>
          <div>
            <Stars
            rating={feedback?.punctuality}
            onRatingChange={(value)=> handleInputChange(index, "punctuality",value)}
            />
          </div>
        </li>
      </ol>
    </>
  );
}

function InstituteRating({rating, handleRatingChange}) {
  return (
    <>
      <div>
        <h4 className="pb-2 text-center text-4xs font-semibold sm:text-left xl:text-3xs 2xl:text-2xs">
          Institute Rating
        </h4>
        <div className="flex flex-col items-center justify-center sm:flex-row sm:justify-between">
          <p className="text-4xs xl:text-3xs 2xl:text-2xs">
            How satisfied are you with the institute.
          </p>
          <div>
            <Stars
            rating={rating}
            onRatingChange={handleRatingChange}
            />
          </div>
        </div>
      </div>
    </>
  );
}
function CourseRating({rating, handleCourseRatingChange}) {
  return (
    <>
      <div>
        <h4 className="pb-2 text-center text-4xs font-semibold sm:text-left xl:text-3xs 2xl:text-2xs">
          Course Rating
        </h4>
        <div className="flex flex-col items-center justify-center sm:flex-row sm:justify-between">
          <p className="text-4xs xl:text-3xs 2xl:text-2xs">
            How satisfied are you with the Course.
          </p>
          <div>
            <Stars
            rating={rating}
            onRatingChange={handleCourseRatingChange}
            />
          </div>
        </div>
      </div>
    </>
  );
}



function OverAllCourseReview({review, handleReviewChange}) {
  return (
    <div className="w-full">
      <h4 className="pb-2 text-center text-4xs font-semibold sm:text-left xl:text-3xs 2xl:text-2xs">
        Overall Course Review
      </h4>
      <textarea
        className="w-full border-2 border-solid border-gray-100  p-4 text-4xs text-darkPurpal outline-none md:p-6 xl:text-3xs"
        name=""
        id=""
        rows={6}
        value={review}
        onChange={handleReviewChange}
      ></textarea>
    </div>
  );
}

export default PublicFeedback;
