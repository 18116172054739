import React, { useState } from 'react';
import { Alert, IconButton  } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from '@mui/icons-material/Info';


export function CheckboxBtns({ Days, BorderStyle, TimeIndex, Change, EnrollmentData, DaysIndex, TimeArr, selectedSlotsCount, isTimeSlotAvailable, timeSlots }) {
    const [showAlert, setShowAlert] = useState(false); // State to manage showing the alert
    const [alertMessage, setAlertMessage] = useState(''); // State to manage custom alert messages
    console.log("timeslots in the CheckboxBtns components are:", timeSlots)

    const handleClose = () => {
        setShowAlert(false); // Close the alert when the button is clicked
      };
    // Check if the current time slot is selected (array of selected time slots)
    const isChecked = EnrollmentData.StudentData.Schedule[Days[TimeIndex]]?.includes(TimeArr[DaysIndex]);

   // Helper function to convert 12-hour format (from TimeArr) to 24-hour format for comparison
   const convertTo24HourFormat = (time) => {
    const [hours, minutes] = time.split(':');
    let hour = parseInt(hours, 10);
    const isPM = time.includes('PM');
    if (isPM && hour !== 12) hour += 12;
    if (!isPM && hour === 12) hour = 0; // Special case for 12 AM
    return `${hour.toString().padStart(2, '0')}:${minutes.slice(0, 2)}`;
};

// Split time range in TimeArr and convert it to 24-hour format
const extractTimes = (timeRange) => {
    const [start, end] = timeRange.split(' - ').map(time => time.trim());
    return { start: convertTo24HourFormat(start), end: convertTo24HourFormat(end) };
};

// Split the time range into start and end times (if valid)
const timeRange = TimeArr[DaysIndex] ? extractTimes(TimeArr[DaysIndex]) : null;

// Filter valid time slots for the current day
const validTimeSlots = timeSlots.filter(slot => slot.DayOfWeek === Days[TimeIndex]);

// Check if current time slot exists in validTimeSlots by matching the converted 24-hour times
const currentTimeSlot = validTimeSlots.find(slot => {
    return (
        timeRange && 
        slot.StartTime === timeRange.start &&
        slot.EndTime === timeRange.end
    );
});

    const handleCheckboxChange = (e) => {
        const checked = e.target.checked;
        const currentSchedule = EnrollmentData.StudentData.Schedule[Days[TimeIndex]] || [];
        
        if (!currentTimeSlot) {
            setAlertMessage(`This institute does not operate at this time on ${Days[TimeIndex]}.`);
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 5000); // Hide alert after 5 seconds
            return;
        }

        // Check if the user has already selected two slots for the current day
        if (checked && selectedSlotsCount >= 2) {
            // Show the alert when more than 2 slots are selected
            setAlertMessage(`You can select only two time slots per day.`);
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 5000); // Automatically hide the alert after 3 seconds
            return;
        }

        if (checked) {
            // Add the time slot if checked
            Change(Days[TimeIndex], [...currentSchedule, TimeArr[DaysIndex]]);
        } else {
            // Remove the time slot if unchecked
            Change(Days[TimeIndex], currentSchedule.filter((time) => time !== TimeArr[DaysIndex]));
        }
    };

   
    return (
        <>
             {/* Conditionally render the MUI Alert */}
             {showAlert && (
                <Alert severity="info"
                // icon={false}  // Remove the severity icon
                  style={{
                     backgroundColor: '#4C34AD',  // Change the background color
                     color: '#fff',
                    position: 'absolute',
                    top: '20px',
                    left: '50%',
                    transform: 'translateX(-50%)', // This centers the alert horizontally
                    width: '700px', // Set the width to 700px
                }}
                iconMapping={{
            info: <InfoIcon style={{ color: '#fff' }} />  // Customize the icon color to white
        }}

                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                    }>
                    {alertMessage}
                </Alert>
            )}
       
            <span className={`w-52 flex justify-center py-5 ${BorderStyle}`}>
                <label className='Checkbox-Btn'>
                    <input
                        className='relative left-1/2 -translate-x-1/2'
                        value={TimeArr[DaysIndex]}
                        type="checkbox"
                        name={`${Days[TimeIndex]}`}
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        disabled={!currentTimeSlot}
                    />
                    <span className='CheckMark'></span>
                </label>
            </span>
        </>
    );
}
