// react States
import React, { useEffect } from "react";

// import '../../CourseSelection/CourseSelection.css'
import "../courseSelection.css";
import { useDispatch, useSelector } from "react-redux";
import { GetAdminCoursesA } from "../../../../../Actions/CourseA";
import LoadingSpinner from "../../../../../Components/LoadingSpinner/LoadingSpinner";
import NoDataResponse from "../../../../../Components/NoDataResponse";
import { BaseUrl } from "../../../../../Actions/Base";
import RecommendCoursePopup from "./RecommendCoursePopup";
import { useState } from "react";
import { GetLocalStorage } from "../../../../../Helpers/LocalStorage/LocalStorage";

const CoursesInventory = ({
  setSteps,
  CourseData,
  setCourseData,
  setCourseLicense,
  update,
}) => {
  const Dispatch = useDispatch();
  const { AdminCourses, loading } = useSelector((Store) => Store.CourseReducer);

  useEffect(() => {
    Dispatch(GetAdminCoursesA());
  }, [Dispatch]);

  console.log("AdminCourses", AdminCourses);

  const [Popup, setPopup] = useState();
  const OnClick = (e, CoursePK, Added, LicenseTypeName, VehicleTypeName) => {
    // console.log(
    //     "LIcenseTYPe",
    //     LicenseTypeName,
    //     "vehilcetype",
    //     VehicleTypeName
    // );
    if (e.target === e.currentTarget && !Added) {
      if (GetLocalStorage("UserInfo")?.InstituteUserType === "Admin")
        setPopup(true);
      else setSteps(2);
      setCourseLicense({ LicenseTypeName, VehicleTypeName });
      setCourseData({ ...CourseData, CourseFK: CoursePK });
    }
    e.stopPropagation();
  };

  const addedCourse =(value)=> {
    const state = false;
    const findIns = value?.InstituteCourses.find((val)=> val.InstituteFK === GetLocalStorage("UserInfo")?.Institute?.InstituteId )
    console.log("findIns", findIns)
    return findIns
  }

  return !loading ? (
    <div className="mainContainer1White addCourseFirstPage vehicleInventrory">
      {GetLocalStorage("UserInfo")?.InstituteUserType === "Admin" && Popup ? (
        <RecommendCoursePopup
          CoursePK={CourseData?.CourseFK}
          setPopup={setPopup}
          setSteps={setSteps}
        />
      ) : null}
      <div className="addCourse3Main" style={{ marginTop: "-100px" }}>
        <div className="row" onClick={(e) => e.stopPropagation()}>
          {console.log("AdminCourses=>", AdminCourses)}
          {AdminCourses?.length > 0 ? (
            AdminCourses?.map((value, index, arr) => {
              const Added = addedCourse(value)

              return (
                <CourseComp
                  update={update}
                  key={value.CoursePK}
                  VehicleType={value?.VehicleType?.VehicleTypeName}
                  LicenseType={value?.LicenseType?.LicenseTypeName}
                  CourseName={value.CourseName}
                  Description={value.Description}
                  Image={value.CourseThumbnail}
                  Added={Added}
                  OnClick={(e) =>
                    OnClick(
                      e,
                      arr[index].CoursePK,
                      Added,
                      // arr[index].LicenseTypeName,
                      // arr[index].VehicleTypeName
                      value?.LicenseType?.LicenseTypeName,
                      value?.VehicleType?.VehicleTypeName
                    )
                  }
                />
              );
            })
          ) : (
            <div className="flex min-h-[80vh] w-[calc(100%_-_80px)] items-center justify-center">
              <NoDataResponse
                topText="No Course found"
                bottomText="VlI admin will add course tamplates for you"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <LoadingSpinner />
  );
};

export const CourseComp = ({
  VehicleType,
  LicenseType,
  CourseName,
  Description,
  OnClick,
  Image,
  Added,
  update,
}) => {
  // const [Select, setSelect] = useState(false);
  let ModifiedDescription = Description;

  let ModifiedCourseName = CourseName;

  if (ModifiedDescription && ModifiedDescription.split("").length > 150)
    ModifiedDescription =
      ModifiedDescription?.split("")?.slice(0, 150).join("") + "...";

  if (ModifiedCourseName && ModifiedCourseName?.split("").length > 27)
    ModifiedCourseName =
      ModifiedCourseName?.split("")?.slice(0, 27).join("") + "...";

  return (
    <div className="col-3 aspect-[159_/_217] max-h-[440px]">
      <div
        className={`vehicalBox addCourseFIrstVehicalBox pt-1 ${
          Added ? "bg-[#9c9c9c]" : ""
        }`}
      >
        <div className="vehicalImgConainer">
          <div className="vehicleInventoryImgTopRate">
            {/* {Select
                        ? <div> 1 </div>
                        : null} */}
          </div>
          <div className="flex h-[160px] items-center justify-center overflow-hidden">
            <img
              src={`${BaseUrl}/api/Thumbnail/course?url=${Image}`}
              alt="addCourseVehical1"
            />
          </div>
          <div className="vehicleInventoryPictureWaterPrints">
            <div className="waterPrintsGreen">LType {LicenseType}</div>
            <div className="waterPrintsGreen">VType {VehicleType}</div>
          </div>
        </div>
        <div>
          <h3 className="m-0 px-4 pt-4 text-3xs font-normal">
            {ModifiedCourseName}
          </h3>
          <p className="mx-2 mb-9 aspect-[257_/_95] h-[100px] w-full break-all px-4 pt-1 text-4xs text-[#333333]">
            {ModifiedDescription}
          </p>
        </div>
        <center>
          <button
            disabled={update}
            className={`vehicalSelectBtn cursor-pointer`}
            type="button"
            onClick={OnClick}
          >
            {Added ? "Selected" : "Select Course"}
          </button>
        </center>
      </div>
    </div>
  );
};
export default CoursesInventory;
