import axios from "axios";
import { CheckLoginServer } from "../Helpers/CheckLogin";
import { BaseUrl } from "./Base";

const axiosInstance = axios.create({
    baseURL: BaseUrl,
    withCredentials: true,
});

export const AddVehicleA = (VehicleData, Navigate) => async (dispatch) => {
    try {
        dispatch({ type: "AddVehicleRequest" });

        const { data } = await axiosInstance.post(
            `/api/Vehicle/add`,
            VehicleData
        );

        dispatch({
            type: "AddVehicleSuccess",
            payload: data,
        });
        // Navigate('/vehicle/inventory')
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "AddVehicleFailure",
            payload: error,
        });
    }
};

export const UpdateVehicleA = (VehicleData, Navigate) => async (dispatch) => {
    try {
        dispatch({ type: "UpdateVehicleRequest" });
        console.log(VehicleData);
        const { data } = await axiosInstance.put(
            `/api/Vehicle/update`,
            VehicleData
        );

        dispatch({
            type: "UpdateVehicleSuccess",
            payload: data,
        });
        // Navigate("/vehicle/inventory");
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "UpdateVehicleFailure",
            payload: error,
        });
    }
};

export const DeleteVehicleA = (VehicleId) => async (dispatch) => {
    try {
        dispatch({
            type: "DeleteVehicleRequest",
        });

        const { data } = await axiosInstance.delete(
            `/api/Vehicle/remove/${VehicleId}`
        );

        dispatch({
            type: "DeleteVehicleSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "DeleteVehicleFailure",
            payload: error,
        });
    }
};

export const GetVehicleA = (filters) => async (dispatch) => {
    try {
        dispatch({ type: "GetAllVehiclesRequest" });

        const { sortByTime, sortBy, searchQuery, types, gearType } = filters;
        const queryParams = new URLSearchParams({
            sortByTime: sortByTime,
            sortBy: sortBy,
            searchQuery: searchQuery,
            types: types,
            gearType: gearType,
        }).toString();
        const { data } = await axiosInstance.get(
            `/api/Vehicles?${queryParams}`
        );

        dispatch({
            type: "GetAllVehiclesSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetAllVehiclesFailure",
            payload: error,
        });
    }
};

export const GetSingleVehicleA = (VehicleId) => async (dispatch) => {
    try {
        dispatch({ type: "GetSingleVehicleRequest" });

        const { data } = await axiosInstance.get(`/api/Vehicle/${VehicleId}`);

        dispatch({
            type: "GetSingleVehicleSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetSingleVehicleFailure",
            payload: error,
        });
    }
};

// vehicle registration select options below actions are for that
export const GetManufacturesA = () => async (dispatch) => {
    try {
        dispatch({ type: "GetManufacturesRequest" });

        const { data } = await axiosInstance.get(`/api/manufactures`);
        dispatch({
            type: "GetManufacturesSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetManufacturesFailure",
            payload: error,
        });
    }
};

export const GetVehicleColorsA = () => async (dispatch) => {
    try {
        dispatch({ type: "GetVehicleColorsRequest" });

        const { data } = await axiosInstance.get(`/api/vehicle-color`);
        dispatch({
            type: "GetVehicleColorsSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetVehicleColorsFailure",
            payload: error,
        });
    }
};
export const GetVehicleEngineA = () => async (dispatch) => {
    try {
        dispatch({ type: "GetVehicleEngineRequest" });

        const { data } = await axiosInstance.get(
            `/api/vehicle-engine-capacity`
        );
        dispatch({
            type: "GetVehicleEngineSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetVehicleEngineFailure",
            payload: error,
        });
    }
};

export const GetVehicleGearTypesA = () => async (dispatch) => {
    try {
        dispatch({ type: "GetVehicleGearTypeRequest" });

        const { data } = await axiosInstance.get(`/api/vehicle-gear-type`);
        dispatch({
            type: "GetVehicleGearTypeSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetVehicleGearTypeFailure",
            payload: error,
        });
    }
};

export const GetVehicleSeatsA = () => async (dispatch) => {
    try {
        dispatch({ type: "GetVehicleSeatsRequest" });

        const { data } = await axiosInstance.get(`/api/vehicle-seats-capacity`);
        dispatch({
            type: "GetVehicleSeatsSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetVehicleSeatsFailure",
            payload: error,
        });
    }
};

export const GetVehicleTypeA = () => async (dispatch) => {
    try {
        dispatch({ type: "GetVehicleTypeRequest" });

        const { data } = await axiosInstance.get(`/api/type-of-vehicle`);
        dispatch({
            type: "GetVehicleTypeSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetVehicleTypeFailure",
            payload: error,
        });
    }
};

export const GetVehicleModelsA = () => async (dispatch) => {
    try {
        dispatch({ type: "GetVehicleModelsRequest" });

        const { data } = await axiosInstance.get(`/api/vehicle-model-name`);
        dispatch({
            type: "GetVehicleModelsSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetVehicleModelsFailure",
            payload: error,
        });
    }
};

export const GetVehicleYearsA = () => async (dispatch) => {
    try {
        dispatch({ type: "GetVehicleYearsRequest" });

        const { data } = await axiosInstance.get(`/api/vehicle-year`);
        dispatch({
            type: "GetVehicleYearsSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetVehicleYearsFailure",
            payload: error,
        });
    }
};
