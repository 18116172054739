import React from "react";
import img from "../../Assets/Overlay.png";
import { BaseUrl } from "../../../../Actions/Base";

const CourseSection = ({FeedbackPageData}) => {
  console.log("FeedbackPageData?.InstituteCourse?.Course?.Coursename", FeedbackPageData?.CoursePackage?.InstituteCourse?.Course?.CourseName)
  console.log("FeedbackPageData?.InstituteCourse?.Course?", FeedbackPageData?.CoursePackage?.InstituteCourse?.Course?.CourseThumbnail)
  console.log("FeedbackPageData?.InstituteCourse?.Course?", FeedbackPageData?.CoursePackage?.InstituteCourse?.Course)
  return (
    <div className="flex w-full justify-center py-5">
      <div className="card-div  block max-w-[350px] rounded-lg border border-gray-200 bg-white p-6 shadow hover:bg-violet-100 ">
        <div className="flex w-full justify-center">
          <img className="self-center w-[277px] h-[180px]" src={`${BaseUrl}/api/LicenseType/Image?url=${FeedbackPageData?.CoursePackage?.InstituteCourse?.Course?.CourseThumbnail}`} alt="Course-Image" />
        </div>

        <p className="text-center text-[20px] text-black font-black">{FeedbackPageData?.CoursePackage?.InstituteCourse?.Course?.CourseName}</p>

        <div className="flex mt-5 flex-col w-full gap-3">
          <div className="flex justify-between">
            <p className="font-bold">Course Start Date:</p>
            <p>15 / 03 / 2024</p>
          </div>
          <div className="flex justify-between">
            <p className="font-bold">Course Start Date:</p>
            <p>15 / 03 / 2024</p>
          </div>
          <div className="flex justify-between">
            <p className="font-bold">Fee:</p>
            <p>$ 600</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseSection;
