import React, { useState } from 'react'
import InstituteTemplate from '../../../Components/InstituteTemplate/InstituteTemplate';
import "./ManageStaff.css"
import TeacherFilter from '../Instructors/Components/TeacherFilter';
import SignUpPopup from './Popups/SignUpPopup';

function ManageStaffChild() {
    const [FilterOpen, setFilterOpen] = useState();
    const [MaxHeight, setMaxHeight] = useState("max-h-[0px]");
    const [showSignUpPopup, setShowSignUpPopup] = useState(false)
    const OnClick = (e) => {
      e.stopPropagation()
      if (!FilterOpen) {
        setFilterOpen(true);
        setMaxHeight("max-h-[1000px]");
      }
      else if (FilterOpen) {
        setFilterOpen(false);
        setMaxHeight("max-h-[0px]");
      }
    }
    return (
        <>
       {
        showSignUpPopup &&  <SignUpPopup setShowSignUpPopup={setShowSignUpPopup}/>
       }
        <div className='bg-[#F7F7F7] w-full  pb-10 '>
            <h1 className='text-[#4C34AD] text-left w-11/12 mt-10'>Manage Login</h1>
            {/* awaiting login */}

            <div className='rounded-[13px]  px-5 pb-10 w-11/12 mx-auto border border-solid border-[#D4D4D4]' >
            <div className='flex items-center justify-between'>
            <h1 className='text-[#4C34AD] text-left w-11/12 mt-10'>Awaiting for Login</h1>
            <span
            className="InstructorPageBtns  relative"
            onClick={OnClick}
            onMouseEnter={() => setMaxHeight("max-h-[1000px]")}
            onMouseLeave={() => setMaxHeight("max-h-[0px]")}
          >
            Filter Instructor
            <TeacherFilter MaxHeight={MaxHeight} />
          </span>
            </div>
                <div class="relative overflow-x-auto manage-staff">
                    <table class="w-full text-sm  rtl:text-right border-collapse border manage-staff-table-border text-center">
                        <thead class=" text-[18px] font-normal manage-staff-table-border">
                            <tr className='bg-[#9C43F5]  text-white manage-staff-table-border'>
                                <th scope="col" class="px-4 py-4 font-normal manage-staff-table-border">
                                    S.No
                                </th>
                                <th scope="col" class="px-4 py-4 font-normal manage-staff-table-border">
                                    Staff Name
                                </th>
                                <th scope="col" class="px-4 py-4 font-normal manage-staff-table-border">
                                    Email
                                </th>
                                <th scope="col" class="px-4 py-4 font-normal manage-staff-table-border">
                                    Role
                                </th>
                                <th scope="col" class="px-4 py-4 font-normal manage-staff-table-border">
                                    Request Date
                                </th>
                                <th scope="col" class="px-4 py-4 font-normal manage-staff-table-border">
                                    Ph Number
                                </th>
                                <th scope="col" class="px-4 py-4 font-normal manage-staff-table-border">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody className='manage-staff-table-border text-[16px]' style={{
                            background:
                                "transparent linear-gradient(90deg, #FFFFFF 0%, #F6F6FF 24%, #D6D8FF 100%) 0% 0% no-repeat padding-box",

                        }}>
                            <tr class=" manage-staff-table-border">
                                <th scope="row" class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap  manage-staff-table-border">
                                    01
                                </th>
                                <td class="px-4 py-2  flex items-center gap-2">
                                    <div className="ProfileImageContainer">
                                        <img
                                            className="absolute w-full"
                                            src={"https://newprofilepic.photo-cdn.net//assets/images/article/profile.jpg?90af0c8"}
                                            alt={"img"}
                                        />
                                    </div>
                                    <p>Michael Scott</p>
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border">
                                    michael@gmail.com
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border">
                                    <p className='bg-[#32363C] rounded-[9px] py-2 px-3 text-white w-fit mx-auto'>Supervisor</p>
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border">
                                07/09/2022
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border">
                                01 1345 789
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border ">
                                   <div className='flex items-center gap-2'>
                                   <button className='rounded-[11px] text-white bg-[#9C43F5] py-2 w-[100px] border-0 cursor-pointer'>View</button>
                                    <button onClick={()=> setShowSignUpPopup(true)} className='rounded-[11px] text-white bg-[#FBBF11] py-2 w-[100px] border-0 cursor-pointer'>Sign Up</button>
                                    <button className='rounded-[11px] text-white bg-[#DC3744] py-2 w-[100px] border-0 cursor-pointer'>Delete</button>
                                   </div>
                                </td>
                            </tr>
                            <tr class=" manage-staff-table-border">
                                <th scope="row" class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap  manage-staff-table-border">
                                    02
                                </th>
                                <td class="px-4 py-2  flex items-center gap-2">
                                    <div className="ProfileImageContainer border-0">
                                        <img
                                            className="absolute w-full"
                                            src={"https://newprofilepic.photo-cdn.net//assets/images/article/profile.jpg?90af0c8"}
                                            alt={"img"}
                                        />
                                    </div>
                                    <p>Michael Scott</p>
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border">
                                michael@gmail.com
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border">
                                <p className='bg-[#32363C] rounded-[9px] py-2 px-3 text-white w-fit mx-auto'>Accountant</p>
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border">
                                07/09/2022
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border">
                                01 1345 789
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border ">
                                   <div className='flex items-center gap-2'>
                                   <button className='rounded-[11px] text-white bg-[#9C43F5] py-2 w-[100px] border-0 cursor-pointer'>View</button>
                                    <button onClick={()=> setShowSignUpPopup(true)} className='rounded-[11px] text-white bg-[#FBBF11] py-2 w-[100px] border-0 cursor-pointer'>Sign Up</button>
                                    <button className='rounded-[11px] text-white bg-[#DC3744] py-2 w-[100px] border-0 cursor-pointer'>Delete</button>
                                   </div>
                                </td>
                            </tr>
                            <tr class="  ">
                                <th scope="row" class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap  manage-staff-table-border">
                                    03
                                </th>
                                <td class="px-4 py-2 flex items-center gap-2">
                                    <div className="ProfileImageContainer">
                                        <img
                                            className="absolute w-full"
                                            src={"https://newprofilepic.photo-cdn.net//assets/images/article/profile.jpg?90af0c8"}
                                            alt={"img"}
                                        />
                                    </div>
                                    <p>Michael Scott</p>
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border">
                                michael@gmail.com
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border">
                                <p className='bg-[#32363C] rounded-[9px] py-2 px-3 text-white w-fit mx-auto'>Quality incharge</p>
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border">
                                07/09/2022
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border">
                                01 1345 789
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border ">
                                   <div className='flex items-center gap-2'>
                                   <button className='rounded-[11px] text-white bg-[#9C43F5] py-2 w-[100px] border-0 cursor-pointer'>View</button>
                                    <button onClick={()=> setShowSignUpPopup(true)} className='rounded-[11px] text-white bg-[#FBBF11] py-2 w-[100px] border-0 cursor-pointer'>Sign Up</button>
                                    <button className='rounded-[11px] text-white bg-[#DC3744] py-2 w-[100px] border-0 cursor-pointer'>Delete</button>
                                   </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

            {/* Published Staff */}
            <h1 className='text-[#4C34AD] text-left w-11/12 mt-10'>Published Staff</h1>
            <div className='rounded-[13px]  px-5 py-10 w-11/12 mx-auto border border-solid border-[#D4D4D4]' >
            
                <div class="relative overflow-x-auto manage-staff ">
                    <table class="w-full text-sm  rtl:text-right border-collapse border manage-staff-table-border text-center">
                        <thead class=" text-[18px] font-normal manage-staff-table-border">
                            <tr className='bg-[#9C43F5]  text-white manage-staff-table-border'>
                                <th scope="col" class="px-4 py-4 font-normal manage-staff-table-border">
                                    S.No
                                </th>
                                <th scope="col" class="px-4 py-4 font-normal manage-staff-table-border">
                                    Staff Name
                                </th>
                                <th scope="col" class="px-4 py-4 font-normal manage-staff-table-border">
                                    Email
                                </th>
                                <th scope="col" class="px-4 py-4 font-normal manage-staff-table-border">
                                    Role
                                </th>
                                <th scope="col" class="px-4 py-4 font-normal manage-staff-table-border">
                                    Request Date
                                </th>
                                <th scope="col" class="px-4 py-4 font-normal manage-staff-table-border">
                                    Ph Number
                                </th>
                                <th scope="col" class="px-4 py-4 font-normal manage-staff-table-border">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody className='manage-staff-table-border text-[16px]' style={{
                            background:
                                "transparent linear-gradient(90deg, #FFFFFF 0%, #F6F6FF 24%, #D6D8FF 100%) 0% 0% no-repeat padding-box",

                        }}>
                            <tr class=" manage-staff-table-border">
                                <th scope="row" class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap  manage-staff-table-border">
                                    01
                                </th>
                                <td class="px-4 py-2  flex items-center gap-2">
                                    <div className="ProfileImageContainer">
                                        <img
                                            className="absolute w-full"
                                            src={"https://newprofilepic.photo-cdn.net//assets/images/article/profile.jpg?90af0c8"}
                                            alt={"img"}
                                        />
                                    </div>
                                    <p>Michael Scott</p>
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border">
                                    michael@gmail.com
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border">
                                    <p className='bg-[#32363C] rounded-[9px] py-2 px-3 text-white w-fit mx-auto'>Supervisor</p>
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border">
                                07/09/2022
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border">
                                01 1345 789
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border ">
                                   <div className='flex items-center gap-2'>
                                   <button className='rounded-[11px] text-white bg-[#9C43F5] py-2 w-[100px] border-0 cursor-pointer'>View</button>
                                   
                                    <button className='rounded-[11px] text-white bg-[#DC3744] py-2 w-[100px] border-0 cursor-pointer'>Delete</button>
                                   </div>
                                </td>
                            </tr>
                            <tr class=" manage-staff-table-border">
                                <th scope="row" class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap  manage-staff-table-border">
                                    02
                                </th>
                                <td class="px-4 py-2  flex items-center gap-2">
                                    <div className="ProfileImageContainer border-0">
                                        <img
                                            className="absolute w-full"
                                            src={"https://newprofilepic.photo-cdn.net//assets/images/article/profile.jpg?90af0c8"}
                                            alt={"img"}
                                        />
                                    </div>
                                    <p>Michael Scott</p>
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border">
                                michael@gmail.com
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border">
                                <p className='bg-[#32363C] rounded-[9px] py-2 px-3 text-white w-fit mx-auto'>Accountant</p>
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border">
                                07/09/2022
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border">
                                01 1345 789
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border ">
                                   <div className='flex items-center gap-2'>
                                   <button className='rounded-[11px] text-white bg-[#9C43F5] py-2 w-[100px] border-0 cursor-pointer'>View</button>
                                   
                                    <button className='rounded-[11px] text-white bg-[#DC3744] py-2 w-[100px] border-0 cursor-pointer'>Delete</button>
                                   </div>
                                </td>
                            </tr>
                            <tr class="  ">
                                <th scope="row" class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap  manage-staff-table-border">
                                    03
                                </th>
                                <td class="px-4 py-2 flex items-center gap-2">
                                    <div className="ProfileImageContainer">
                                        <img
                                            className="absolute w-full"
                                            src={"https://newprofilepic.photo-cdn.net//assets/images/article/profile.jpg?90af0c8"}
                                            alt={"img"}
                                        />
                                    </div>
                                    <p>Michael Scott</p>
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border">
                                michael@gmail.com
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border">
                                <p className='bg-[#32363C] rounded-[9px] py-2 px-3 text-white w-fit mx-auto'>Quality incharge</p>
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border">
                                07/09/2022
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border">
                                01 1345 789
                                </td>
                                <td class="px-4 py-2 manage-staff-table-border ">
                                   <div className='flex items-center gap-2'>
                                   <button className='rounded-[11px] text-white bg-[#9C43F5] py-2 w-[100px] border-0 cursor-pointer'>View</button>
                                   
                                    <button className='rounded-[11px] text-white bg-[#DC3744] py-2 w-[100px] border-0 cursor-pointer'>Delete</button>
                                   </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
        </>
    )
}



const ManageStaffLogin = () => (
    <InstituteTemplate
        Element={ManageStaffChild}
        bg="bg-[#F7F7F7] min-h-screen items-center"
    />
);

export default ManageStaffLogin;