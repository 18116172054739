import React, { createContext } from "react";
import "./UserSigning.css";
import { useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ResetLoginSignUp } from "../../../../Actions/ToggleSignupA";
import {
  GoogleReCapthcaLoading,
  LoginUser,
  SignUpUser,
} from "../../../../Actions/UserA";
import LoadingSpinner from "../../../../Components/LoadingSpinner/LoadingSpinner";
import { SubmitButton } from "../../LoginAndRegister";
import SignInLeftSide from "./Components/LeftSide/LeftSide";
import SigninRightSide from "./Components/RightSide/RightSide";
import { useAuthContext } from "../../../../context/authContext";
import VerifyUser from "../../../../popups/verifyUser/VerifyUser";
import { BaseUrl } from "../../../../Actions/Base";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const TermsContext = createContext();
const UserSigning = ({ ScreenSize, TopLoginRegisterBtns }) => {
  //axios instance
  const axiosInstance = axios.create({
    baseURL: BaseUrl,
    withCredentials: true,
  });
  const Navigate = useNavigate()

  const [Credentials, setCredentials] = useState({
    Email: "",
    Password: "",
    ConfirmPassword: "",
    FirstName: "",
    LastName: "",
  });
  const [TermsAgreed, setTermsAgreed] = useState(false);
  const CheckBoxRef = useRef();
  const Dispatch = useDispatch();
  const {
    captcha,
    setCaptcha,
    setResync,
    setverifyOtp,
    verifyOtp,
    verified,
    setVerified,
    setOtpPopup
  } = useAuthContext();
  const [PasswordError, setPasswordError] = useState("");
  const [captchaError, setCaptchaError] = useState("");

  const { loading } = useSelector((Store) => Store.LoginSignupReducer);
  const { AuthPageName } = useSelector((Store) => Store.ToggleSignupReducer);

  const VerifyPopup =()=> {
    !loading && setverifyOtp(true);
  }

  const SubmitForm = (e) => {
    e.preventDefault();

    const CheckErrorLabel =
      CheckBoxRef?.current?.classList?.contains("outline-[red]");

    if (AuthPageName === "Sign Up" && TermsAgreed && CheckErrorLabel) {
      CheckBoxRef?.current?.classList?.remove("outline-[red]");
      CheckBoxRef?.current?.classList?.add("outline-[#707070]");
    } else if (AuthPageName === "Sign Up" && !TermsAgreed && !CheckErrorLabel) {
      CheckBoxRef?.current?.classList?.add("outline-[red]");
      CheckBoxRef?.current?.classList?.remove("outline-[#707070]");
      return;
    }
    if (
      AuthPageName === "Sign Up" &&
      Credentials.Password !== Credentials.ConfirmPassword
    ) {
      setPasswordError("Password do not match");
      return;
    }
    if (AuthPageName === "Sign Up" && !captcha) {
      setCaptchaError(
        "Please complete the security check by selecting the CAPTCHA."
      );
      return;
    }

    if (AuthPageName !== "Sign Up" && !captcha) {
      setCaptchaError(
        "Please complete the security check by selecting the CAPTCHA."
      );
      return;
    }

    // window.grecaptcha?.ready(function () {
    //   if (
    //     (AuthPageName === "Sign Up" && TermsAgreed) ||
    //     AuthPageName === "Sign In"
    //   )
    //     Dispatch(GoogleReCapthcaLoading(AuthPageName));

      // window.grecaptcha
      //   .execute(process.env.REACT_APP_GOOGLE_CAPTCHA_KEY_2)
      //   .then(function (token) {
      //     Credentials.Token = token;
      //     if (AuthPageName === "Sign Up" && TermsAgreed) {
      //       Dispatch(
      //         SignUpUser(
      //           Credentials,
      //           Dispatch,
      //           AuthPageName,
      //           setCaptcha,
      //           setResync
      //         )
      //       );
      //       !loading && setverifyOtp(true);
      //     } else if (AuthPageName === "Sign In") Dispatch(LoginUser(Credentials, Dispatch, AuthPageName, setCaptcha, Navigate));
      //   });

          if (AuthPageName === "Sign Up" && TermsAgreed) {
            Dispatch(
              SignUpUser(
                Credentials,
                Dispatch,
                AuthPageName,
                setCaptcha,
                setResync,
                setOtpPopup,
                VerifyPopup
              )
            );
            
          } else if (AuthPageName === "Sign In") Dispatch(LoginUser(Credentials, Dispatch, AuthPageName, setCaptcha, Navigate));
    // });

    return false;
  };

  

  return (
    <>
      {verifyOtp ? <VerifyUser /> : null}

      <TermsContext.Provider value={[TermsAgreed, setTermsAgreed, CheckBoxRef]}>
        {!loading ? (
          <form
            className="relative flex h-fit w-full flex-col  items-center"
            onSubmit={SubmitForm}
          >
            <button
              type="button"
              className="absolute right-6 top-2 z-10 flex cursor-pointer items-center justify-center rounded-xl border-none bg-[#A1A3EF] p-3 md:hidden"
              onClick={() => {
                Dispatch(ResetLoginSignUp(false));
                setCredentials({
                  Email: "",
                  Password: "",
                  ConfirmPassword: "",
                  FirstName: "",
                  LastName: "",
                });
              }}
            >
              <img
                className=" cursor-pointer"
                src={require("../../Assets/CrossIcon.svg").default}
                alt=""
              />
            </button>
            <div
              className={`flex
            h-fit             max-h-[1050px] 
            min-h-fit           w-[99%]
            max-w-[1240px]               flex-col-reverse
            justify-around      md:h-auto
            md:min-h-[auto]    md:w-[80%]  
            md:flex-row
            md:justify-start
            md:bg-white
                                 `}
            >
              <SignInLeftSide
                TopLoginRegisterBtns={TopLoginRegisterBtns}
                ScreenSize={ScreenSize}
                setCredentials={setCredentials}
              />

              {/* <CenterORline /> */}
              <SigninRightSide
                TopLoginRegisterBtns={TopLoginRegisterBtns}
                setCredentials={setCredentials}
                Credentials={Credentials}
                PasswordError={PasswordError}
                captchaError={captchaError}
              />

              {/* For Mobile View only */}
              <div className="Boldfont mt-16 flex  h-[12%]  flex-col items-center justify-between text-white md:hidden ">
                <h2>Sign Up For Free</h2>
                <p className="text-center">
                  "Lorem Ipsum is simply dummy <br />
                  text of the printing"{" "}
                </p>
              </div>
            </div>
          </form>
        ) : (
          <LoadingSpinner
            Position="static"
            Height="[80vh]"
            Width="[99%]"
            Top="20"
            Left="0"
            Bg="white"
          />
        )}
      </TermsContext.Provider>
    </>
  );
};
export function CenterORline({ Visible }) {
  if (Visible === undefined) Visible = true;
  const { AuthPageName } = useSelector((Store) => Store.ToggleSignupReducer);

  let Order = AuthPageName === "Register" ? "order-2" : "";
  return (
    <span
      className={`relative hidden items-center  justify-center self-stretch md:flex ${Order}`}
    >
      <hr className="relative -top-[2%] h-[70%]" />
      {Visible ? (
        <span
          className={`Regularfont absolute top-1/2 h-11 w-11 -translate-y-1/2 rounded-[32px] border-[1px]  
           border-solid  border-[#707070] bg-[#A1A3EF] 
             p-2       text-center        text-2xs
             text-white           xl:h-14             xl:w-14 
             xl:text-base           2xl:h-16             2xl:w-16  
             2xl:text-[30px]`}
        >
          OR
        </span>
      ) : null}
    </span>
  );
}

export default UserSigning;
