import React from "react";

const Settings = (PageName, IconName) => {
  const Color = PageName === IconName ? "#4C34AD" : "#fff";

  return (
    <div className="ml-0 flex items-center gap-4">
      <svg
        id="Settings"
        xmlns="http://www.w3.org/2000/svg"
        width="25.276"
        height="25.276"
        viewBox="0 0 25.276 25.276"
      >
        <g id="icon_setting">
          <path
            id="Path_108"
            data-name="Path 108"
            d="M21.011,8.215,22.748,4.9,20.537,2.686,17.219,4.423a5.659,5.659,0,0,0-1.738-.632L14.218,0H11.058L9.794,3.633a6.6,6.6,0,0,0-1.58.632L4.9,2.528,2.528,4.9,4.265,8.215a6.6,6.6,0,0,0-.632,1.58L0,11.058v3.159l3.633,1.264c.158.632.474,1.106.632,1.738L2.528,20.537l2.212,2.212,3.317-1.738a5.659,5.659,0,0,0,1.738.632l1.264,3.633h3.159l1.264-3.633c.632-.158,1.106-.474,1.738-.632l3.317,1.738,2.212-2.212-1.738-3.317a5.659,5.659,0,0,0,.632-1.738l3.633-1.264V11.058L21.643,9.794A6.6,6.6,0,0,0,21.011,8.215Zm-8.373,9.163A4.655,4.655,0,0,1,7.9,12.638,4.655,4.655,0,0,1,12.638,7.9a4.655,4.655,0,0,1,4.739,4.739A4.655,4.655,0,0,1,12.638,17.377Z"
            fill={Color}
          />
        </g>
      </svg>
      <p className="font-bold text-white">Settings</p>
    </div>
  );
};

export default Settings;
