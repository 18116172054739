import React, { useState,useEffect } from "react";
import  './time-rang.css'

const TimeRangePicker = ({value,onChange}) => {
  const [startTime, setStartTime] = useState(value.start);
  const [endTime, setEndTime] = useState(value.end);

  useEffect(() => {
    setStartTime(value.start);
    setEndTime(value.end);
  }, [value]);

  const handleStartTimeChange = (e) => {
    const newStart = e.target.value;
    setStartTime(newStart);
    onChange({ start: newStart, end: endTime });
  };

  const handleEndTimeChange = (e) => {
    const newEnd = e.target.value;
    setEndTime(newEnd);
    onChange({ start: startTime, end: newEnd });
  };

  return (
    <div className="time-rang-cnt flex w-fit items-center rounded px-2 py-1 gap-1 shadow-md ">
      <input
        type="time"
        value={startTime}
        onChange={handleStartTimeChange}
        className="time-input bg-mediumWhite"
      />
<span className="w-3 h-[1px] bg-black"></span>
      <input
        type="time"
        value={endTime}
        onChange={handleEndTimeChange}
        className="time-input bg-mediumWhite"
      />
    </div>
  );
};

export default TimeRangePicker;
