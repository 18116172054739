import axios from "axios";
import { CheckLoginServer } from "../Helpers/CheckLogin";
import { BaseUrl } from "./Base";

const axiosInstance = axios.create({
    baseURL: BaseUrl,
    withCredentials: true,
});

export const AddInstructorA =
    (InstructorData, setSuccess) => async (dispatch) => {
        try {
            dispatch({
                type: "CreateInstructorRequest",
            });
            const { data } = await axiosInstance.post(
                `/api/Instructor/add`,
                InstructorData
            );

            dispatch({
                type: "CreateInstructorSuccess",
                payload: data,
            });
            // if (data) {
            setSuccess(true);
            // }
        } catch (error) {
            CheckLoginServer(error?.response?.data, dispatch);
            dispatch({
                type: "CreateInstructorFailure",
                payload: error,
            });
        }
    };

export const UpdateInstructorA =
    (InstructorData, setSuccess) => async (dispatch) => {
        try {
            dispatch({
                type: "CreateInstructorRequest",
            });

            const { data } = await axiosInstance.put(
                `/api/Instructor/update`,
                InstructorData
            );
            dispatch({
                type: "CreateInstructorSuccess",
                payload: data,
            });
            setSuccess(true);
        } catch (error) {
            CheckLoginServer(error?.response?.data, dispatch);
            dispatch({
                type: "CreateInstructorFailure",
                payload: error,
            });
        }
    };

export const GetInstructorsA = (EnrollmentId) => async (dispatch) => {
    try {
        dispatch({
            type: "GetInstructorsRequest",
        });

        const { data } = await axiosInstance.get(
            `/api/Instructors/${EnrollmentId}`
        );

        dispatch({
            type: "GetInstructorsSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetInstructorsFailure",
            payload: error,
        });
    }
};

export const DeleteInstructorA = (InstructorId) => async (dispatch) => {
    try {
        dispatch({
            type: "DeleteInstructorRequest",
        });

        const { data } = await axiosInstance.delete(
            `/api/Instructor/delete/${InstructorId}`
        );

        dispatch({
            type: "DeleteInstructorSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "DeleteInstructorFailure",
            payload: error,
        });
    }
};
export const GetInstituteInstructorsA = (filters) => async (dispatch) => {
    try {
        dispatch({
            type: "GetInstructorsRequest",
        });
        // console.log("filters", filters);
        const { sortByTime, sortBy, searchQuery, suspend } = filters;
        const queryParams = new URLSearchParams({
            sortByTime: sortByTime,
            sortBy: sortBy,
            searchQuery: searchQuery,
            suspend: suspend,
        }).toString();
        // console.log("queryparams", queryParams);
        const { data } = await axiosInstance.get(
            `/api/Institute/Instructors?${queryParams}`
        );
        // console.log("instructorFilteredData", data);
        // const { data } = await axiosInstance.get(`/api/Institute/Instructors`);

        dispatch({
            type: "GetInstructorsSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetInstructorsFailure",
            payload: error,
        });
    }
};

export const GetInstituteStaffA = (filters) => async (dispatch) => {
    try {
        dispatch({
            type: "GetStaffRequest",
        });
        // console.log("filters", filters);
        const { sortByTime, sortBy, searchQuery, suspend } = filters;
        const queryParams = new URLSearchParams({
            sortByTime: sortByTime,
            sortBy: sortBy,
            searchQuery: searchQuery,
            suspend: suspend,
        }).toString();
        // console.log("queryparams", queryParams);
        const { data } = await axiosInstance.get(
            `/api/Institute/Staffs?${queryParams}`
        );
        // console.log("instructorFilteredData", data);
        // const { data } = await axiosInstance.get(`/api/Institute/Instructors`);

        dispatch({
            type: "GetStaffSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetStaffFailure",
            payload: error,
        });
    }
};

export const GetInstructorStudents = (InstructorId) => async (dispatch) => {
    try {
        dispatch({
            type: "GetInstructorStudentsRequest",
        });

        const { data } = await axiosInstance.get(
            `/api/teacher/${InstructorId}`
        );
        console.log("data", data);

        dispatch({
            type: "GetInstructorStudentsSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetInstructorStudentsFailure",
            payload: error,
        });
    }
};

export const GetSInstructorA = (InstructorId) => async (dispatch) => {
    try {
        dispatch({
            type: "GetSInstructorRequest",
        });

        const { data } = await axiosInstance.get(
            `/api/SInstructor/${InstructorId}`
        );

        dispatch({
            type: "GetSInstructorSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetSInstructorFailure",
            payload: error,
        });
    }
};

//Get Related to course Instructors
export const GetCourseInstructorA = (EnrollmentId) => async (dispatch) => {
    try {
        dispatch({
            type: "GetCourseInstructorRequest",
        });

        const { data } = await axiosInstance.get(
            `/api/Course/Instructors/${EnrollmentId}`
        );

        dispatch({
            type: "GetCourseInstructorSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetCourseInstructorFailure",
            payload: error,
        });
    }
};

// courses of instructors
export const GetCoursesOfSInstructorA = (InstructorId) => async (dispatch) => {
    try {
        dispatch({
            type: "GetCoursesOfSInstructorRequest",
        });

        console.log("instructor id in action is:",InstructorId)

        const { data } = await axiosInstance.get(
            `/api/instructor/courses/${InstructorId}`
        );

        console.log("coursesdata", data);
        dispatch({
            type: "GetCoursesOfSInstructorSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetCoursesOfSInstructorFailure",
            payload: error,
        });
    }
};

// courses instructor and student address
export const GetInstructorAndStudentAddressA = (EventId) => async (dispatch) => {
    try {
        dispatch({
            type: "GetInstructorAndStudentAddressRequest",
        });

        console.log("EventId in action is:",EventId)

        const { data } = await axiosInstance.get(
            `/api/instructor/address/${EventId}`
        );

        console.log("address student and instructor", data);
        dispatch({
            type: "GetInstructorAndStudentAddressSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetInstructorAndStudentAddressFailure",
            payload: error,
        });
    }
};

// otp to user mobile number
export const GetStudentNumberAndSendOTP = (EventId) => async (dispatch) => {
    try {
        dispatch({
            type: "GetStudentNumberAndSendOTPRequest",
        });

        console.log("EventId in GetStudentNumberAndSendOTP action is:",EventId)

        const { data } = await axiosInstance.post(
            `/api/smsotp/${EventId}`
        );

        dispatch({
            type: "GetStudentNumberAndSendOTPSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "GetStudentNumberAndSendOTPFailure",
            payload: error,
        });
    }
};

// bypass otp feedback update
export const GetBypassOtpFeedBackUpdate = (EventId, feedbackData) => async (dispatch) => {
    try {
        dispatch({
            type: "GetBypassOtpFeedBackUpdateRequest",
        });

        console.log("EventId in GetBypassOtpFeedBackUpdate action is:",EventId)
        console.log("Feedback Data:", feedbackData);

        const { data } = await axiosInstance.post(
            `/api/bypassotpfeedback/${EventId}`,
            feedbackData
        );

        dispatch({
            type: "GetBypassOtpFeedBackUpdateSuccess",
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: "GetBypassOtpFeedBackUpdateFailure",
            payload: error,
        });
    }
};

// update student that instructor has left the location
export const UpdateStudentAboutInstructorLocation = (StudentEmail, StudentFirstName, StudentLastName) => async (dispatch) => {
    try {
        dispatch({
            type: "GetInstructorLeftLocationUpdateRequest",
        });

        console.log("StudentEmail in UpdateStudentAboutInstructorLocation action is:",StudentEmail)
        console.log("StudentFirstName in UpdateStudentAboutInstructorLocation action is:",StudentFirstName)
        console.log("StudentLastName in UpdateStudentAboutInstructorLocation action is:",StudentLastName)

        const { data } = await axiosInstance.get(
            `/api/updateinstructorleftlocation/${StudentEmail}`,
            {
                params: {
                    StudentFirstName: StudentFirstName,
                    StudentLastName: StudentLastName,
                },
            }
        );

        dispatch({
            type: "GetInstructorLeftLocationUpdateSuccess",
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: "GetInstructorLeftLocationUpdateFailure",
            payload: error,
        });
    }
};
