import axios from "axios";
import { CheckLoginServer } from "../Helpers/CheckLogin";
import { BaseUrl } from "./Base";

const axiosInstance = axios.create({
    baseURL: BaseUrl,
    withCredentials: true,
});

export const GetVehicleTypes = () => async (dispatch) => {
    try {
        dispatch({
            type: "VehicleTypeRequest",
        });

        const { data } = await axiosInstance.get(`/api/AllVehicleTypes`);

        dispatch({
            type: "VehicleTypeSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "VehicleTypeError",
            payload: error,
        });
    }
};

export const GetLicenseTypes = () => async (dispatch) => {
    try {
        dispatch({
            type: "LicenseTypeRequest",
        });

        const { data } = await axiosInstance.get(`/api/licensetypes`);

        dispatch({
            type: "LicenseTypeSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "LicenseTypeError",
            payload: error,
        });
    }
};

export const GetSpecialities = () => async (dispatch) => {
    try {
        dispatch({
            type: "SpecialitiesRequest",
        });

        const { data } = await axiosInstance.get(`/api/specialities`);
        console.log("specialdjfdata", data);
        dispatch({
            type: "SpecialitiesSuccess",
            payload: data,
        });
    } catch (error) {
        CheckLoginServer(error?.response?.data, dispatch);
        dispatch({
            type: "SpecialitiesError",
            payload: error,
        });
    }
};
