import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { TeacherDashboardRowTemplate } from "./TeacherDashboardRowTemplate";
import dayjs from "dayjs";
import "./TeacherDashboardTable.css";
function StudentTable({
  Students,
  enrollmentCountMap,
  completedClassCountsMap,
  filtered,
  filteredStudents,
}) {
  const Navigate = useNavigate();
  return (
    <div className="">
      <table
        className="instructor_students_table addCourse4Table "
        style={
          {
            // background: "rgba(0,0,0,0)";
          }
        }
      >
        <thead className="bg-color">
          <tr>
            <th className="w-[10%] text-center">Profile</th>
            <th className="w-[9%] text-center">ID</th>
            <th className="w-[3%] text-center">Name</th>
            <th className="w-[16.5%] text-center">Email</th>
            <th className="w-[14%] text-center">Status</th>
            <th className="w-[9%] text-center">Joining</th>
            <th className="w-[10%] text-center truncate hover:text-clip">Total Classes</th>
            <th className="w-[16%] text-center truncate hover:text-clip">Next Class</th>

            <th className="w-[10%] text-center"></th>
          </tr>
        </thead>
        <tbody className="bg-color">
          {filteredStudents &&
            filteredStudents?.map((value, index) => {
              const { EnrollmentId, createdAt, Status, IdCardNo } = value;

              const NextClassStartTime = dayjs(
                new Date(value?.User?.CourseEnrollment?.TimeTable?.startDate)
              ).format("hh:mm A");

              const NextClassStartDate = dayjs(
                new Date(value?.User?.CourseEnrollment?.TimeTable?.endDate)
              ).format("DD/MM/YYYY");
              const TotalClasses = enrollmentCountMap[EnrollmentId] || 0;
              const completedClasses =
                completedClassCountsMap[EnrollmentId] || 0;
              {
                /* const { PaymentStatus, Status } = value; */
              }

              // console.log('valueeeeeeeeee', value?.CourseEnrollment?.IdCardNo )

              {
                /* const PaymentOrClientsColor =
                                !PaymentStatus === 0
                                    ? "text-[green] bg-[#aaffbe]"
                                    : "text-white bg-[#b5aaff]"; */
              }

              const StatusColor = "bg-[#B5AAFF] text-white";
              {
                /* const StatusColor = !Status
                                ? "text-[#0D472D] bg-[#F7E5D1]"
                                : "text-white bg-[#b5aaff]";
                            { */
              }
              /* const PaymentMethod =
                                "Installments | " +
                                CoursePackage.InstallmentSchedule +
                                " | " +
                                CoursePackage.Installments; */

              const OnClick = () => {
                {
                  Navigate(`/teacher/AboutStudent/${EnrollmentId}`);
                }
              };
              {
                /* const Delete = () => (
                                <Link
                                    onClick={(e) => e.stopPropagation()}
                                    to={`/enrolledcourse/${EnrollmentId}`}
                                >
                                    <button
                                        type="button"
                                        class="focus:ring-blue-300 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-2 cursor-pointer rounded-lg border-0 bg-[#4c34ad] px-5 py-2 text-4xs font-medium text-white outline-none hover:bg-[#a1a3ef] focus:outline-none focus:ring-4"
                                    >
                                        Delete
                                    </button>
                                </Link>
                            ); */
              }

              {
                /* const Manage = () => (
                                <Link
                                    onClick={(e) => e.stopPropagation()}
                                    to={
                                        filtered
                                            ? `/enrolledcourse/${EnrollmentId}`
                                            : `/enrolledcourse/manage/${EnrollmentId}`
                                    }
                                >
                                    <button
                                        type="button"
                                        class="focus:ring-blue-300 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-2 cursor-pointer rounded-lg border-0 bg-[#4c34ad] px-5 py-2 text-4xs font-medium text-white outline-none hover:bg-[#a1a3ef] focus:outline-none focus:ring-4"
                                    >
                                        Manage
                                    </button>
                                </Link>
                            ); */
              }
              return (
                <TeacherDashboardRowTemplate
                  type={"students"}
                  OnClick={OnClick}
                  TrStyle="cursor-pointer"
                  key={value.User.UserId}
                  // index={index}
                  // LastComp={Delete}
                  // LastCompp={Manage}
                  FirstName={value?.User?.FirstName}
                  LastName={value?.User?.LastName}
                  Email={value?.User?.Email}
                  Status={Status}
                  StudentId={IdCardNo}
                  createdAt={createdAt}
                  // PaymentOrUnderClients={
                  //     !PaymentStatus ? PaymentMethod : "free"
                  // }
                  StatusColor={StatusColor}
                  // PaymentOrClientsColor={
                  //     PaymentOrClientsColor
                  // }
                  NextClassStartTime={NextClassStartTime}
                  NextClassStartDate={NextClassStartDate}
                  TotalClasses={TotalClasses}
                  completedClasses={completedClasses}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default StudentTable;
