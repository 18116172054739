import React from 'react';
import "./Message.css"

const SuccessMessage = ({text}) => {
  return (
    <div className="relative w-full">
                <div
                  className="rounded-lg font-segoe-ui bg-[#d3ebe0] p-4 text-4xs text-[#03543f] "
                  role="alert"
                >
                  <span className="font-medium">Success alert!</span> {text}
                </div>
                <div className="success-message-underline"></div>
              </div>
  )
}

export default SuccessMessage
