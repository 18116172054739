import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CategoryOne from "./Components/CategoryOne/CategoryOne";


const CoursesDisplayed = () => {
  const { LicenseTypeId, VehicleTypeId } = useParams();

  const { Courses } = useSelector((Store) => Store.CourseReducer);
  console.log("Courses=>", Courses)

  // LicenseTypeId ? (
  //   !Courses?.SubLicenseTypes?.length ||
  //   Courses?.SubLicenseTypes?.length === 0 ? (
  //     <CategoryOne Types={Courses} />
  //   )  : VehicleTypeId ? (
  //   <CategoryOne Types={Courses} />
  // ) : null : null)
  if(LicenseTypeId && Courses?.SubLicenseTypes?.length && Courses?.SubLicenseTypes?.length > 0) {
     return null
  }else {
     return <CategoryOne Types={Courses} />  
  }

};


export default CoursesDisplayed;
