import React, { useEffect, useState } from "react";
import { GetLocalStorage } from "../../../../../../../Helpers/LocalStorage/LocalStorage";
import "./ButtonsSide.css";
import { useAuthContext } from "../../../../../../../context/authContext";
const BottonSideForAllCourses = ({ value, OnClick, Color }) => {


  return (
    <div className="InsAdminCourseTilesBorder w-full px-2 py-2 lg:w-[27%] lg:max-w-[230px] ">
      <div className="cardFoter flex  flex-col items-center">
        <p className="text-7xs 2xs:text-6xs xs:text-5xs font-medium sm:text-4xs font-segoe-ui 3xl:text-3xs ">
         Current Status
        </p>
        <button
          className={`mt-[10px] mb-[10px] rounded border-none  py-[3px] 
                    px-1 text-center text-6xs 2xs:px-[6px] 2xs:text-6xs xs:px-2 
                     md:py-[4px] md:px-[10px]
                    lg:px-3 xl:py-[4px] xl:px-[14px] xl:text-4xs 3xl:text-3xs 2xl:py-[6px] 2xl:px-4 
                    ${Color}`}
        >
          {value && value?.Publish === "false"
            ? "Under Review"
            : value?.Publish}
        </button>

      </div>
    </div>
  );
};

export default BottonSideForAllCourses;