import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SetImagePreview } from "../../Helpers/ImagePreview/ImagePreview";
import { useAuthContext } from "../../context/authContext";
import { ImagePreviewModal } from "./LicenseImagePreviewer";
import { BaseUrl } from "../../Actions/Base";

function ImagePreviewer({
    Id,
    BrowseBtnStyle,
    ContainerStyle,
    OnChange,
    ImgUrl,
    type,
    isInstructorProfile,
    readOnly,
  
    editType,
    // isHovered,
    isStaffProfile,
}) {
    // const {Image, setImage} = useAuthContext()
    const { UserInfo } = useSelector((store) => store.LoginSignupReducer);
    const [isHovered, setIsHovered] = useState(false);

  
    const [Image, setImage] = useState();
    const studentImage = (UserInfo?.User &&
        `${BaseUrl}/api/images/Student?url=${UserInfo?.StudentImage}`);
    const Simg = (UserInfo?.User && studentImage.includes("StudentImage"))

    const PreviewImage = (e) => {
        const file = e.target.files[0];

        if (OnChange) OnChange(e);

        if (e.target.files[0].type === "application/pdf")
            setImage(require("./Assets/PDF.svg").default);
        else SetImagePreview(setImage, file);
    };

    if (!BrowseBtnStyle) BrowseBtnStyle = "text-3xs bg-[#F3F6F7]";
    if (!ContainerStyle) ContainerStyle = "flex flex-col gap-5 ";

    useEffect(() => {
        if (ImgUrl) {
            if (typeof ImgUrl === "string" && ImgUrl?.length > 1)
                setImage(ImgUrl);
            else SetImagePreview(setImage, ImgUrl);
        }
    }, [ImgUrl, Image]);
    

    // if (isHovered) {
    //     return <ImagePreviewModal Image={Image} />;
    // }


    return (
        <div className={ContainerStyle}>
            <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                // className={`flex items-center  justify-center overflow-hidden rounded-xl border-[3px] border-dashed border-[#C8C8C8] bg-[#F3F6F7] ${PreviewStyle} h-[164px2 min-w-[120px] md:h-[164px] md:min-w-[160px]`}
                // className={`flex items-center  justify-center overflow-hidden rounded-xl border-[3px] border-dashed border-[#C8C8C8] bg-[#F3F6F7] ${PreviewStyle} h-[164px2 min-w-[120px] md:h-[260px] md:min-w-[260px]`}

                className={`bilal flex items-center justify-center overflow-hidden rounded-xl border-[3px] border-dashed border-[#C8C8C8] bg-[#F3F6F7] h-[197px] w-[212px] min-w-[212px]`}
            >
                { Image  && (
                    <img
                        className={"h-[197px] min-w-[212px]"}
                        src={Image}
                        alt=""
                    />)
                }
               
                {isHovered && Image && !isInstructorProfile && (
                    <ImagePreviewModal Image={Image} />
                )}
                {!Image && isInstructorProfile && isStaffProfile && (
                    <img
                        className="h-full w-full"
                        src={require("./Assets/profile-preview.svg").default}
                        alt="user"
                    />
                )}
                <input
                    type="file"
                    accept="image/*"
                    capture
                    className="hidden"
                    onChange={PreviewImage}
                    id={Id}
                />
            </div>
            {!readOnly && (
                <div
                    className={
                        isInstructorProfile
                            ? " relative top-20 flex flex-col items-center gap-2"
                            : "flex flex-col items-center gap-2"
                    }
                >
                    <label
                        className={`h-fit  w-fit cursor-pointer rounded-lg bg-[#4C34AD]  px-4 py-1 text-[14px]  text-white shadow-[5px_6px_6px_#00000016] md:px-8 md:py-2 lg:text-[16px] `}
                        htmlFor={Id}
                    >
                        {isInstructorProfile ? "Browse" : " Browse"}
                    </label>

                    <p className="text-[12px] md:text-[13px]">
                        Jpeg, PNG or Giff
                    </p>
                </div>
            )}
        </div>
    );
}
export default ImagePreviewer;
