import React, { useState, useEffect } from "react";
import { InstructorLicenseDetail } from "../InstructorLicenseDetails/InstructorLicenseDetail";
import {
    Input,
    SelectList,
} from "../../../../../../../Components/InputComps/InputComps";
import { DateInput } from "../../../../../../../Components/InputComps/DateComps";
import { InstructorOnChange } from "../../../../../../AddCourse/Components/Course Packaging/Helpers/Events";
import LicenseImagePreviewer from "../../../../../../../Components/ImagePreviewer/LicenseImagePreviewer";
import { BaseUrl } from "../../../../../../../Actions/Base";

const DisplayLicenseDetails = ({ simpleLicense }) => {
    // const [isHovered, setIsHovered] = useState(false);
    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredRowIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredRowIndex(null);
    };
    return (
        <>
            {/* <h3
                style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                className="text-xs font-bold text-[#9E57F3] sm:text-sm md:text-base lg:text-[29px] xl:text-lg 2xl:text-xl"
            >
                License Details
            </h3> */}

            <div
                className=" rounded-[23px] px-5 py-10 "
                // style={{
                //     background:
                //         "transparent linear-gradient(90deg, #FFFFFF 0%, #F6F6FF 24%, #D6D8FF 100%) 0% 0% no-repeat padding-box",
                // }}
            >
                <div class="space-y-4">
                    <div class="-m-1.5 overflow-x-auto">
                        <div class="inline-block min-w-full p-1.5 align-middle">
                            <div class="overflow-hidden">
                                <table class="min-w-full border-collapse overflow-x-auto">
                                    <thead>
                                        <tr className="border-0 border-b border-solid border-[#A5C9FF]">
                                            <th
                                                scope="col"
                                                class="px-6 py-3 text-start text-xs font-medium text-[#404040] "
                                            >
                                                License Number
                                            </th>
                                            <th
                                                scope="col"
                                                class="px-6 py-3 text-start text-xs font-medium text-[#404040] "
                                            >
                                                License Expiry
                                            </th>
                                            <th
                                                scope="col"
                                                class="px-6 py-3 text-start text-xs font-medium text-[#404040] "
                                            >
                                                License Class
                                            </th>

                                            <th
                                                scope="col"
                                                class="px-6 py-3 text-start text-xs font-medium text-[#404040] "
                                            >
                                                Attachable
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class=" ">
                                        {simpleLicense &&
                                            simpleLicense.map(
                                                (license, index) => {
                                                    const {
                                                        LicenseNumber,
                                                        LicenseExpiry,
                                                        LicenseTypeName,
                                                        LicenseImage,
                                                    } = license;
                                                    const LicenseImages = `${BaseUrl}/api/images/Instructors?url=${LicenseImage}`;
                                                    const isHovered =
                                                        index ===
                                                        hoveredRowIndex;

                                                    return (
                                                        <tr className=" border-0 border-b border-solid border-[#A5C9FF]">
                                                            <td class=" h-full px-6 py-4 text-3xs font-medium text-black">
                                                                {LicenseNumber}
                                                            </td>
                                                            <td class=" mb-0  px-6 pb-0 text-3xs text-black">
                                                                {LicenseExpiry ||
                                                                    "11/06/2028"}{" "}
                                                            </td>
                                                            <td class=" px-6 py-4 text-3xs text-black">
                                                                {
                                                                    LicenseTypeName
                                                                }
                                                            </td>
                                                            <td
                                                                class=" px-6 py-1 text-3xs text-black"
                                                                // onMouseEnter={() =>
                                                                //     setIsHovered(
                                                                //         true
                                                                //     )
                                                                // }
                                                                // onMouseLeave={() =>
                                                                //     setIsHovered(
                                                                //         false
                                                                //     )
                                                                // }

                                                                onMouseEnter={() =>
                                                                    handleMouseEnter(
                                                                        index
                                                                    )
                                                                }
                                                                onMouseLeave={() =>
                                                                    handleMouseLeave(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                <div className="w-full [&>*]:max-w-full">
                                                                    {/* {!isHovered && ( */}
                                                                    <LicenseImagePreviewer
                                                                        LicenseDetail={
                                                                            false
                                                                        }
                                                                        // readOnly={
                                                                        //     readOnly
                                                                        // }
                                                                        Id={
                                                                            "SLicenseImage"
                                                                        }
                                                                        // isHovered={isHovered}
                                                                        PreviewStyle=""
                                                                        BrowseBtnStyle=""
                                                                        ContainerStyle=""
                                                                        // onChange={
                                                                        //     onChange
                                                                        // }
                                                                        // onChange={(e) =>
                                                                        //     onChange(e, "SpecialLicenseImage")
                                                                        // }
                                                                        ImgUrl={
                                                                            LicenseImages
                                                                        }
                                                                    />
                                                                    {/* )} */}
                                                                    {isHovered && (
                                                                        <LicenseImagePreviewer
                                                                            LicenseDetail={
                                                                                true
                                                                            }
                                                                            isHovered={
                                                                                isHovered
                                                                            }
                                                                            // readOnly={
                                                                            //     readOnly
                                                                            // }
                                                                            Id="SLicenseImage"
                                                                            PreviewStyle=""
                                                                            BrowseBtnStyle=""
                                                                            ContainerStyle=""
                                                                            // onChange={(
                                                                            //     e
                                                                            // ) =>
                                                                            //     // setLicenseImg(
                                                                            //     //     e.target
                                                                            //     //         .value
                                                                            //     // )
                                                                            //     onChange(
                                                                            //         e,
                                                                            //         "SLicenseImage",
                                                                            //         "SLicenseImage"
                                                                            //     )
                                                                            // }
                                                                            ImgUrl={
                                                                                LicenseImages
                                                                            }
                                                                        />
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {/* end */}
                </div>
            </div>
        </>
    );
};

export default DisplayLicenseDetails;
