import { io } from "socket.io-client";

const isProduction = window.location.hostname !== "localhost";
const URL = isProduction
  ? "https://vehiclelearning.com"
  : "http://localhost:9100";

export const socket = io(URL);

export const getSocket = () => {
  console.log("socket url", URL);
  const socket = io(URL);
  return socket;
};
