import React, { useState } from "react";
import "./DropDown.css";

function DropDown({
    Label,
    DropDownOptions,
    Name,
    onChange,
    StateValue,
    styles,
    TextStyle,
    SelectValueStyle,
    IconSrc,
}) {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleDropDown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (value) => {
        setIsOpen(false);
        onChange(value);
    };

    return (
        <div className={`select-box w-full ${styles}`}>
            <div
                className={`selected-value-container ${SelectValueStyle} relative cursor-pointer`}
                onClick={handleToggleDropDown}
            >
                <h3
                    className={`${TextStyle} pointer-events-none whitespace-nowrap font-normal`}
                >
                    {StateValue
                        ? DropDownOptions.find(
                              (option) => option.value === StateValue
                          )?.label
                        : Label}
                </h3>
                <img
                    className="pointer-events-none absolute right-0 top-1/2 -translate-y-1/2"
                    src={
                        !IconSrc
                            ? require("./Assets/Arrow.svg").default
                            : IconSrc
                    }
                    alt=""
                />
            </div>
            {isOpen && (
                <div className="Options-Container">
                    {DropDownOptions.map((option) => (
                        <div
                            key={option.value}
                            className={`dropdown-option ${
                                StateValue === option.value ? "selected" : ""
                            }`}
                            onClick={() => handleOptionClick(option.value)}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default DropDown;

// import React from 'react'

// import './DropDown.css'
// function DropDown({ Label, DropDownOptions, Name, onChange, StateValue, styles, TextStyle, SelectValueStyle, IconSrc }) {

//     const OpenDropDown = (e) => {
//         const OptionsContainer = e.target.parentElement.getElementsByClassName("Options-Container")[0];
//         OptionsContainer.classList.toggle("UnActive");

//     }
//     if (!TextStyle)
//         TextStyle = "text-3xs"

//     return <div className={`select-box w-full ${styles}`}  >
//         <div className={`selected-value-container Selecting ${SelectValueStyle} cursor-pointer relative`} onClick={OpenDropDown}

//         >
//             <h3 className={`${TextStyle} pointer-events-none font-normal whitespace-nowrap`}>{!StateValue ? Label : StateValue}</h3>
//             <img className='absolute right-0 top-1/2 -translate-y-1/2 pointer-events-none'
//                 src={!IconSrc ? require('./Assets/Arrow.svg').default : IconSrc}
//                 alt="" />
//         </div>
//         <div className='Options-Container UnActive' >
//             {DropDownOptions && <DropDownOptions Name={Name} onChange={onChange} StateValue={StateValue} />}
//         </div>
//     </div>
// }

// export default DropDown
