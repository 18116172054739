import React from "react";
import { useState } from "react";
// import useCheckLogin from "../../../../Helpers/CustomHooks/CheckLogin";

function GetReadyRoadTest() {
    // useCheckLogin(true, ["Student"]);

    return (
        <div className="mx-auto w-[80%]    ">
            <h2 className=" SemiBoldItalic mt-10 text-center md:text-[38px] lg:text-[38px]">
                Road Test
            </h2>
            <div className="mt-10 flex  flex-col xs:flex-col   lg:flex-row lg:items-center lg:justify-center lg:gap-10  ">
                <div className="flex w-full flex-col  gap-10">
                    <h2 className="SemiBoldItalic text-[55px] md:text-[38px]">
                        Assessing Driving Skills
                        <br />
                        And Readiness
                    </h2>
                    <p className="text-[24px]">
                        Ready to put your skills to the test? Schedule your road
                        test with one of our experienced instructors. Practice
                        road hours and refine your driving abilities under
                        professional guidance. Prepare thoroughly and increase
                        your chances of passing your road test on the first try.
                    </p>
                    <div className="flex items-center justify-center gap-2">
                        <div className="h-fit w-[10px] bg-[#A1A3EF]" />
                        <p className="text-[22px]">
                            In VLI, schedule your road test online. Meet with a
                            certified instructor for a practical exam covering
                            different driving scenarios. Receive real-time
                            feedback and documentation upon passing.
                        </p>
                    </div>
                    <div class="inline-block w-fit cursor-pointer rounded-[11px] bg-[#A1A3EF] py-3 px-5 text-[22px] font-normal text-white shadow-lg transition duration-300 hover:shadow-xl">
                        Hire Instructor
                    </div>
                </div>
                <div className=" mt-10 mb-20 flex w-full flex-wrap items-center justify-center gap-10 px-5">
                    <Card
                        title="Skill Evaluation"
                        Description="Road tests provide a practical assessment of an individual's driving skills, ensuring they meet the necessary standards for safe and competent driving."
                        src={require("./Assets/Preparation.png")}
                        Style="text-white"
                        DivStyle="bg-[#A1A3EF] "
                    />
                    <Card
                        title="Real-World Experience"
                        Description="Road tests provide supervised real-world driving practice, readying individuals for diverse road conditions."
                        src={require("./Assets/Real-World Experience.png")}
                        DivStyle="bg-white"
                    />
                    <Card
                        title="Feedback"
                        Description="Instructors give tailored feedback during road tests, pinpointing improvement areas for enhanced driving skills."
                        src={require("./Assets/Feedback.png")}
                        DivStyle="bg-white "
                    />
                    <Card
                        title="Licensing Requirement"
                        Description="Licensing Requirement"
                        src={require("./Assets/Licensing Requirement.png")}
                        DivStyle="bg-white "
                    />
                </div>
            </div>
        </div>
    );
}

export default GetReadyRoadTest;
function Card({ title, Description, src, Style, DivStyle }) {
    return (
        <div
            className={`rounded p-5 ${DivStyle} 
                    } h-[295px]
            w-[270px]`}
            style={{
                boxShadow: "5px 5px 19px #00000038",
            }}
        >
            <img src={src} className="" alt="" />
            <h2 className={`${Style}`}>{title}</h2>
            <p className={`text-wrap mt-2 text-justify ${Style}`}>
                {Description}
            </p>
        </div>
    );
}
