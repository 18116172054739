import React, { useState, useEffect } from "react";
import { DateInput } from "../../../../../../../Components/InputComps/DateComps";
import { SpecialLicenseDetailsComp } from "../sepcialLicenseDetailsComp/SpecialLicenseDetailsComp";
import {
    Input,
    SelectList,
} from "../../../../../../../Components/InputComps/InputComps";
import { LicenseTypesOption } from "../../../OptionsArr/OptionsArr";
import LicenseImagePreviewer from "../../../../../../../Components/ImagePreviewer/LicenseImagePreviewer";
import LicenseWarn from "../LicenseWarnPopup/LicenseWarn";
import dayjs from "dayjs";
import { BaseUrl } from "../../../../../../../Actions/Base";

export const SpecialLicenseDetail = ({
    SLicenseImages,
    setSLicenseImages,
    setSpecialLicenseDetails,
    SpecialLicenseDetails,
    Err,
    setErr,
    updateOnly,
    InstructorData,
    readOnly,
    OnChange,
}) => {
    const [SpecailLicenseErrors, setSpecailLicenseErrors] = useState([]);
    const [licenseNum, setLicenseNum] = useState("");
    const [licenseExp, setLicenseExp] = useState("");
    const [licenseTyp, setLicenseTyp] = useState("");
    const [SpeciallicenseImg, SetSpeciallicenseImg] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [NewError, setNewError] = useState("");
    const [PopupMessage, setPopupMessage] = useState("");

    const handleSpecialLicenseType = (e) => {
        if (
            InstructorData?.SpecialLicenseDetails?.some(
                (license) =>
                    license.LicenseTypeFK === e.target.value ||
                    license.SpecialLicenseTypeFK === e.target.value
            )
        ) {
            setPopupMessage("Selected license type is already used");

            setShowPopup(true);
            return;
        }
        setLicenseTyp(e.target.value);
    };

    const AddInstructor = (e) => {
        if (!licenseNum || !licenseExp || !licenseTyp || !SpeciallicenseImg) {
            setNewError("Please fill all fields marked with *");
            return;
        }

        const licenseExpiryDate = dayjs(licenseExp, "YYYY-MM-DD");
        const currentDate = dayjs(); // Current date

        if (licenseExpiryDate.isBefore(currentDate, "day")) {
            setPopupMessage(
                "License has expired or enter the correct license expiry date."
            );
            setShowPopup(true);
            return;
        }

        const NewInstructor = {
            InstructorFK: "",
            LicenseNumber: licenseNum,
            LicenceExpiry: licenseExp,
            LicenseTypeFK: licenseTyp,
            // SLicenseImage: SpeciallicenseImg,
        };

        console.log("specialLicenseImg", SpeciallicenseImg);
        setSLicenseImages([...SLicenseImages, SpeciallicenseImg]);

        setSpecialLicenseDetails([...SpecialLicenseDetails, NewInstructor]);
        setLicenseNum("");
        setLicenseExp("");
        setLicenseTyp("");
        SetSpeciallicenseImg(null);
        setNewError("");

        setErr({ ...Err, SpecailLicense: [...SpecailLicenseErrors] });
    };

    const RemoveInstructor = (Index) => {
        let RemovedInstructorLicenseDetails = SpecialLicenseDetails.filter(
            (value, index) => Index !== index
        );

        let removedLicenseImages = SLicenseImages?.filter(
            (value, index) => index !== Index
        );
        setSLicenseImages(removedLicenseImages);

        setSpecialLicenseDetails(RemovedInstructorLicenseDetails);
        if (RemovedInstructorLicenseDetails.length <= 0) {
            setErr({
                ...Err,
                SpecailLicense: "At least one License Detail is required",
            });
            setNewError("");
        }
    };

    const onChange = (e, INDEX, ClassType, ClassName) => {
        // Get a copy of the current state
        const updatedLicenseDetails = [...SpecialLicenseDetails];

        // Update the specific property for the item at the given index
        let Data =
            e?.target?.files?.length > 0
                ? e?.target?.files[0]
                : e?.target?.value;
        // if (Data === e.target.files.length > 0) {
        //     SetSpeciallicenseImg(Data);
        // }
        updatedLicenseDetails[INDEX] = {
            ...updatedLicenseDetails[INDEX],
            // [ClassType]: e.target.value,
            [ClassType]: Data,
        };

        console.log("INDEX", INDEX);
        console.log("ClassType", ClassType);
        console.log("updatedLicenseDetails", updatedLicenseDetails);

        setSpecialLicenseDetails(updatedLicenseDetails);
    };

    // InstructorOnChange(e, INDEX, ClassType, ClassName, SpecialLicenseDetails, setSpecialLicenseDetails, SpecailLicenseErrors, setInstructorErrors)

    useEffect(() => {
        setErr({ ...Err, SpecailLicense: [...SpecailLicenseErrors] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SpecailLicenseErrors, NewError]);

    return (
        <>
            {showPopup && (
                <LicenseWarn
                    setShowPopup={setShowPopup}
                    PopupMessage={PopupMessage}
                />
            )}
            <h3
                style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                className="2xl:text-md text-xs font-bold text-[#4C34AD] sm:text-sm md:text-base lg:text-[29px] xl:text-lg"
            >
                Special License Details
            </h3>

            <div
                className=" rounded-[23px] px-5 py-10 "
                style={{
                    boxShadow: "0 0 50px rgba(0, 0, 0, 0.3)",

                    background:
                        "transparent linear-gradient(90deg, #F9F9FF 0%, #DADCFF 100%) 0% 0% no-repeat padding-box",
                }}
            >
                <div class="">
                    <div class="-m-1.5 overflow-x-auto">
                        <div class="inline-block min-w-full p-1.5 align-middle">
                            <div class="overflow-hidden">
                                <table class="min-w-full border-collapse overflow-x-auto">
                                    <thead>
                                        <tr className="border-0 border-b border-solid border-[#A5C9FF]">
                                            <th
                                                scope="col"
                                                class="px-6 py-3 text-start text-xs font-medium text-[#404040] "
                                            >
                                                License Number *
                                            </th>
                                            <th
                                                scope="col"
                                                class="px-6 py-3 text-start text-xs font-medium text-[#404040] "
                                            >
                                                License Expiry *
                                            </th>
                                            <th
                                                scope="col"
                                                class="px-6 py-3 text-start text-xs font-medium text-[#404040] "
                                            >
                                                License Class *
                                            </th>
                                            <th
                                                scope="col"
                                                class="px-6 py-3 text-start text-xs font-medium text-[#404040] "
                                            >
                                                Attachable *
                                            </th>

                                            <th
                                                scope="col"
                                                class="px-1 py-3  text-xs font-medium text-[#404040] "
                                            >
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class=" ">
                                        {SpecialLicenseDetails.length > 0
                                            ? SpecialLicenseDetails.map(
                                                  (value, index) => (
                                                      <SpecialLicenseDetailsComp
                                                          SpecialLicenseDetails={
                                                              SpecialLicenseDetails
                                                          }
                                                          SLicenseImages={
                                                              SLicenseImages
                                                          }
                                                          key={index}
                                                          value={value}
                                                          index={index}
                                                          Err={Err}
                                                          setErr={setErr}
                                                          onChange={onChange}
                                                          RemoveInstructor={
                                                              RemoveInstructor
                                                          }
                                                          updateOnly={
                                                              updateOnly
                                                          }
                                                          InstructorData={
                                                              InstructorData
                                                          }
                                                          //   OnChange={OnChange}
                                                      />
                                                  )
                                              )
                                            : null}

                                        <tr className=" border-0 border-b border-solid border-[#A5C9FF]">
                                            <td class=" px-6 text-3xs font-medium text-black">
                                                <div className="mb-5 flex w-full items-center justify-center [&>*]:max-w-full">
                                                    <Input
                                                        licenseInput={true}
                                                        Placeholder="0546"
                                                        State={licenseNum}
                                                        onChange={(e) =>
                                                            setLicenseNum(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </td>
                                            <td class="   px-6  text-3xs text-black">
                                                <div className=" mb-3 flex items-center  justify-center [&>*]:w-full [&>div>span>input]:shadow-none">
                                                    <DateInput
                                                        licenseInput={true}
                                                        type={"licence-date"}
                                                        Id={"LicenceExpiry"}
                                                        State={licenseExp}
                                                        onChange={(e) =>
                                                            setLicenseExp(
                                                                e.target.value
                                                            )
                                                        }
                                                        CurrentYear={dayjs().format(
                                                            "YYYY-MM-DD"
                                                        )}
                                                    />
                                                </div>
                                            </td>
                                            <td class=" px-6  text-3xs text-black">
                                                <div className=" mb-5 flex w-full items-center  justify-center [&>*]:max-w-full">
                                                    {/* <SelectList
                                                        // Label="Speciality"
                                                        Id="LicenseType"
                                                        defaultValue=""
                                                        Text="License Type"
                                                        readOnly={true}

                                                        // onChange={(e) => OnChange(e, "Speciality")}
                                                    /> */}

                                                    <SelectList
                                                        licenseInput={true}
                                                        Id="LicenseTypeFK"
                                                        defaultValue=""
                                                        Text="License Type"
                                                        OptionsArr={
                                                            LicenseTypesOption
                                                            //
                                                        }
                                                        State={licenseTyp}
                                                        onChange={
                                                            handleSpecialLicenseType
                                                            // (e) =>
                                                            //     setLicenseTyp(
                                                            //         e.target.value
                                                            //     )
                                                        }
                                                    />
                                                </div>
                                            </td>
                                            <td class=" px-6 text-3xs text-black">
                                                {/* <div className="w-full [&>*]:max-w-full "> */}
                                                <LicenseImagePreviewer
                                                    LicenseDetail={true}
                                                    readOnly={readOnly}
                                                    Id="SpeciallicenseImg"
                                                    PreviewStyle=""
                                                    BrowseBtnStyle=""
                                                    ContainerStyle=""
                                                    onChange={
                                                        (e) =>
                                                            SetSpeciallicenseImg(
                                                                e.target
                                                                    .files[0]
                                                            )
                                                        // OnChange(
                                                        //     e,
                                                        //     "specialLicenseImg"
                                                        // )
                                                    }
                                                    ImgUrl={SpeciallicenseImg}
                                                />
                                                {/* </div> */}
                                            </td>

                                            <td class="relative whitespace-nowrap px-6 text-center text-3xs font-medium">
                                                <div className=" flex items-center justify-center ">
                                                    <button
                                                        onClick={AddInstructor}
                                                        type="button"
                                                        className=" bottom-[14px] left-0  cursor-pointer  rounded-[10px] border-0 bg-[#4C34AD]  px-4 py-1 text-4xs font-medium text-white md:mb-[2px] md:px-8 md:py-2  lg:py-2 lg:px-10"
                                                    >
                                                        Add
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {SpecailLicenseErrors.map((value, index) => (
                                    <h3
                                        key={index + Object.keys(value)?.[0]}
                                        className="font-normal text-[red]"
                                    >
                                        {value?.[Object.keys(value)?.[0]]}{" "}
                                    </h3>
                                ))}
                                <h3 className="text-3xs font-normal text-[red]">
                                    {typeof Err?.SpecailLicense === "string"
                                        ? Err?.SpecailLicense
                                        : null}
                                    {NewError && NewError}
                                </h3>
                            </div>
                        </div>
                    </div>

                    {/* end */}
                </div>
            </div>
        </>
    );
};
