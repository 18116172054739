import "./App.css";
import Header from "./Components/Header/Headers";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./Pages/LandingPage/LandingPage";
import LoginAndRegister from "./Pages/LoginAndRegister/LoginAndRegister";
import { useEffect, useState } from "react";
import CourseOverview from "./Pages/CourseOverview/CourseOverview";
import CourseCategory from "./Pages/CourseCategory/CourseCategory";
import CourseEnrollment from "./Pages/CourseEnrollment/CourseEnrollment";
import WishList from "./Pages/WishList/WishList";
import EnrolledCourses from "./Pages/EnrolledCourses/EnrolledCourses";
import SEnrolledCourse from "./Pages/SingleEnrolledCourse/SingleEnrolledCourse";
import CourseProgress from "./Pages/CourseProgress/CourseProgress";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import useSetSocket, {
  useSaveSocketUser,
} from "./Helpers/CustomHooks/useSetSocket";
import { createContext } from "react";
import AddVehicle from "./Pages/Institute/AddVehicle/AddVehicle";
import VehicleInventory from "./Pages/VehicleInventory/VehicleInventory";
import InstituteDashboard from "./Pages/InstituteDashboard/InstituteDashboard";
import { GetLocalStorage } from "./Helpers/LocalStorage/LocalStorage";
import SideBar from "./Components/SideBar/SideBar";
import UpdateVehicle from "./Pages/Institute/AddVehicle/UpdatedVehicle";
import AddCourse from "./Pages/AddCourse/AddCourse";
import useCheckLogin, {
  useSetLoginInfo,
} from "./Helpers/CustomHooks/CheckLogin";
import EnrolledCourseIns from "./Pages/Institute/EnrolledCourse By Student (For Institute)/EnrolledCourseIns";
import AddInstructor from "./Pages/Institute/AddInstructor/AddInstructor";
import AboutClient from "./Pages/Institute/CourseProgress (Institute)/AboutClient";

import PaymentSettings from "./Pages/Payment Settings/PaymentSettings";
import ManageNewStudent from "./Pages/Institute/ManageNewStudent/ManageNewStudent";
import AddCourseAdmin from "./Pages/Admin/Add Course (VLI Admin)/AddCourseAdmin";
import AddBookAdmin from "./Pages/Admin/Add Book (VLI Admin)/AddBookAdmin";
import EbookList from "./Pages/Admin/E-Book list/EbookList";
import InstructorsList from "./Pages/Institute/Instructors/Instructors";
import { StudentsList } from "./Pages/Institute/StudentsList/StudentsList";
import ManageStatusChild from "./Pages/table/ManageStatus";
import TeacherTimetable from "./Pages/Institute/TeacherTimeTable/TeacherTimeTable";
import CourseProgressInstructor from "./Pages/Institute/CourseProgress for(Instructor)/CourseProgress_Instructor";
import TeacherAboutStudent from "./Pages/Institute/AboutStudent (For Teacher)/TeacgerAboutStudent";
import CourseModule from "./Pages/Institute/CourseModule (For Teacher)/CourseModule";
import ClassesSchedule from "./Pages/ClassesSchedule (For Student)/ClassesSchedule";
import Sales from "./Pages/Institute/Sales/Sales";
import UpdateInstructor from "./Pages/Institute/AddInstructor/UpdateInstructor";
import RecommendedCoursesByAdmin from "./Pages/Institute/RecommendedCoursesByAdmin/RecommendedCoursesByAdmin";
import { BaseUrl } from "./Actions/Base";
import EBooks from "./Pages/EBooks/EBooks";
import Page404 from "./Pages/Page404/Page404";
import UpdateCourseAdmin from "./Pages/Admin/Add Course (VLI Admin)/UpdateCourseAdmn";
import ForgotPassword from "./popups/forget-password/ForgotPassword";
import ManageStaffLogin from "./Pages/Institute/ManageStaff/ManageStaff";
import StaffList from "./Pages/Institute/staff-listing/StaffListing";
import InstructorSchedule from "./Pages/InstituteDashboard/Teacher Dashboard/TeacherSchedule/InstSchedule";
import InstructorStudentList from "./Pages/InstituteDashboard/Teacher Dashboard/instructorStudents/InstructorStudentsList";
import Conversation from "./Pages/Conversation/Conversation";
import CoursesTeaching from "./Pages/Institute/AboutInstructor (for Staff)";
import AboutInstructor from "./Pages/Institute/AboutInstructor (for Staff)";
import InstructorTrainingCourses from "./Pages/Institute/InstructorTrainingCourses/Index";
import UpdateStaff from "./Pages/Institute/AddStaff/UpdateStaff";
import AddStaff from "./Pages/Institute/AddStaff/AddStaff";
import AboutStaff from "./Pages/Institute/AddStaff/AboutStaff";
import ViewVehicleDetail from "./Pages/Institute/AddVehicle/ViewVehicleDetail";
import Getready from "./Pages/GetReady/Getready";
import KnowledgeTest from "./Pages/GetReady/KnowledgeTest";
import GetReadyLicenseDetail from "./Pages/GetReady/Components/GetReadyLicenseDetail/GetReadyLicenseDetail";
import KnowledgeTestSelect from "./Pages/GetReady/Components/GetReadyKnowledgeTest/KnowledgeTestSelect";
import AttemptQuize from "./Pages/GetReady/Components/GetReadyKnowledgeTest/AttemptQuize";
import UpdateCourse from "./Pages/AddCourse/UpdateCourse";
import ViewCourse from "./Pages/AddCourse/ViewCourse";
import SublicenseAllCourses from "./Pages/CourseCategory/sublicenseAllCourses/SublicenseAllCourses";
import ContactUs from "./Pages/eventContact/ContactUs";

import LessonAttendance from "./Components/LessonAttendance/LessonAttendance";
import InstituteAwards from "./Pages/InstituteAwards.jsx/InstituteAwardsPage";
import InVehicleRecording from "./Pages/InVehicleRecording";
import Ratings from "./Pages/Ratings/Ratings";
import InVehicleClass from "./Pages/InVehicleClass";
import { AllCoursesPage } from "./Pages/InstituteDashboard/allCoursesPage/AllCourses";
const tokenId = GetLocalStorage("UserInfo")?.UserId;

export const SocketContext = createContext();

function App() {
  const [setAuthPageName] = useState("");
  const { AuthPageName } = useSelector((Store) => Store.ToggleSignupReducer);
  const [Socket, setSocket] = useState(undefined);
  const { UserInfo } = useSelector((store) => store.LoginSignupReducer);

  const UserType = GetLocalStorage("UserInfo")?.User;
  const InstituteUserType = GetLocalStorage("UserInfo")?.InstituteUserType;

  console.log("GetLocalStorage", GetLocalStorage("UserInfo"));

  useSetSocket(Socket);
  useSaveSocketUser(Socket);

  useEffect(() => {
    console.log("auth in side useffect", tokenId);

    if (!UserType || UserType === "Student")
      setSocket(
        io(`${BaseUrl}/`, {
          auth: { token: tokenId },
        })
      );
    else if (UserType === "Institute")
      setSocket(
        io(
          `${BaseUrl}/${UserType?.toLowerCase()}/${InstituteUserType?.toLowerCase()}`,
          {
            auth: { token: tokenId },
          }
        )
      );
    else if (UserType !== "Student")
      setSocket(
        io(`${BaseUrl}/${UserType?.toLowerCase()}`, {
          auth: { token: tokenId },
        })
      );
    // else if (UserType === "Institute") setSocket(io(`${BaseUrl}/`));
  }, [UserType, InstituteUserType]);

  useSetLoginInfo();

  const Home =
    !GetLocalStorage("UserInfo")?.User || UserInfo?.User === "Student" ? (
      <LandingPage />
    ) : GetLocalStorage("UserInfo")?.User === "Institute" ||
      GetLocalStorage("UserInfo")?.User === "Admin" ? (
      <InstituteDashboard />
    ) : (
      <LandingPage />
    );

  return (
    <SocketContext.Provider value={Socket}>
      <BrowserRouter>
        <Header AuthPageName={AuthPageName} setAuthPageName={setAuthPageName} />
        {/* {(GetLocalStorage("UserInfo")?.User === "Institute" &&
          GetLocalStorage("UserInfo")?.InstituteUserType !== "Instructor") ||
          GetLocalStorage("UserInfo")?.User === "Admin"
          ? <SideBar />
        : null} */}

        {AuthPageName !== "" ? <LoginAndRegister /> : null}

        <Routes>
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/" element={Home} />
          <Route path="/inbox" element={<Conversation />} />
          <Route path="/get-ready" element={<Getready />} />
          <Route path="/get-ready/knowledge-test" element={<KnowledgeTest />} />
          <Route
            path="/get-ready/knowledge-test/attempt-quize"
            element={<AttemptQuize />}
          />
          <Route
            path="/get-ready/knowledge-test-selection"
            element={<KnowledgeTestSelect />}
          />
          <Route
            path="/get-ready/knowledge-test/license-type/:LicenseTypeId"
            element={<GetReadyLicenseDetail />}
          />

          <Route path="/Ebooks" element={<EBooks />} />
          <Route
            path="/course/:InstituteCourseId"
            element={<CourseOverview />}
          />

          <Route
            path="/license/category/:LicenseTypeId"
            element={<CourseCategory />}
          />
          <Route
            path="/vehicle/category/:VehicleTypeId"
            element={<CourseCategory />}
          />

          <Route path="/Enrollment/Course" element={<CourseEnrollment />} />

          <Route path="/Wishlist" element={<WishList />} />

          <Route
            path="/mycourses/enrolledcourses"
            element={<EnrolledCourses />}
          />
          <Route path="/mycourses/Wishlist" element={<WishList />} />

          <Route
            path="/enrolledcourse/progress/:EnrollmentId"
            element={<CourseProgress />}
          />

          <Route
            path="/enrolledcourse/coursemodule/:EnrollmentId"
            element={<SEnrolledCourse />}
          />

          <Route path="/AddCourse" element={<AddCourse />} />
          <Route path="/UpdateCourse/:CourseId" element={<UpdateCourse />} />
          <Route path="/ViewCourse/:CourseId" element={<ViewCourse />} />

          {/* Instructor details start */}
          <Route path="/Instructors/list" element={<InstructorsList />} />
          <Route path="/Instructor/add" element={<AddInstructor />} />
          <Route
            path="/Instructor/update/:InstructorId"
            element={<UpdateInstructor />}
          />
          <Route
            path="/about/instructor/:InstructorId"
            element={<AboutInstructor />}
          />
          <Route
            path="/about/instructor/training/courses/:InstructorId"
            element={<InstructorTrainingCourses />}
          />

          <Route
            path="/about/instructor/schedule/:InstructorId"
            element={<TeacherTimetable />}
          />
          {/* Instructor details end */}
          <Route path="/staff/manage-login" element={<ManageStaffLogin />} />

          <Route path="/vehicle/add" element={<AddVehicle />} />
          <Route
            path="/vehicle/detail/:VehicleId"
            element={<ViewVehicleDetail />}
          />

          {/* add staff */}
          <Route path="/staff/List" element={<StaffList />} />
          <Route path="/Admin/staff/add" element={<AddStaff />} />
          <Route
            path="/Admin/staff/update/:StaffId"
            element={<UpdateStaff />}
          />
          <Route path="/Admin/staff/detail/:StaffId" element={<AboutStaff />} />
          <Route
            path="/vehicle/update/:VehicleId"
            element={<UpdateVehicle />}
          />

          <Route path="/vehicle/inventory" element={<VehicleInventory />} />
          <Route path="/students/list" element={<StudentsList />} />

          <Route
            path="/enrolledcourse/:EnrollmentId"
            element={<EnrolledCourseIns />}
          />
          <Route path="/Admin/sales" element={<Sales />} />
          <Route
            path="/enrolledcourse/AboutClient/:EnrollmentId"
            element={<AboutClient />}
          />

          <Route path="/instructor/schedule" element={<InstructorSchedule />} />

          <Route
            path="/courses/recommended"
            element={<RecommendedCoursesByAdmin />}
          />
          <Route
            path="/enrolledcourse/PaymentSetting/:EnrollmentId"
            element={<PaymentSettings />}
          />
          <Route
            path="/teacher/UpcomingClasses/:InstructorId"
            element={<TeacherTimetable />}
          />
          <Route
            path="/enrolledcourse/upcomingclasses/:EnrollmentId"
            element={<ClassesSchedule />}
          />

          <Route
            path="/enrolledcourse/manage/:EnrollmentId"
            element={<ManageNewStudent />}
          />

          <Route
            path="/enrolledcourse/PaymentSetting/:EnrollmentId"
            element={<PaymentSettings />}
          />

          <Route
            path="/instructorr/studentsListing"
            element={<InstructorStudentList />}
          />
          <Route
            path="/teacher/CourseModule/:EnrollmentId"
            element={<CourseModule />}
          />
          <Route
            path="/sublicense/allcourses/:LicenseTypeId"
            element={<SublicenseAllCourses />}
          />
          <Route path="/feedback" element={<Ratings />} />
          <Route
            path="/teacher/AboutStudent/:EnrollmentId"
            element={<TeacherAboutStudent />}
          />
          {/* Lessons routes start*/}
          <Route path="/lessons" element={<LessonAttendance />} />
          <Route
            path="/in-vehicle-class/:eventId"
            element={<InVehicleClass />}
          />
          <Route
            path="/in-vehicle-recording"
            element={<InVehicleRecording />}
          />
          {/* Lessons routes end */}
          {/* <Route path='/teacher/UpcomingClasses' element={<TeacherTimetable />} /> */}
          <Route
            path="/teacher/CourseProgress/:EnrollmentId"
            element={<CourseProgressInstructor />}
          />

          <Route path="/certificates" element={<InstituteAwards />} />

          <Route path="/admin/adadcourse" element={<ManageStatusChild />} />
          <Route
            path="/admin/course/update/:CoursePK"
            element={<UpdateCourseAdmin />}
          />
          <Route path="/all/courses" element={<AllCoursesPage />} />
          <Route path="/admin/course/add" element={<AddCourseAdmin />} />
          <Route path="/admin/Book/add" element={<AddBookAdmin />} />
          <Route path="/admin/books/list" element={<EbookList />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </SocketContext.Provider>
  );
}

export default App;
