import dayjs from "dayjs";
import "./TeacherDashboardTable.css";
export const TeacherDashboardRowTemplate = ({
  index,
  FirstName,
  LastName,
  Status,
  createdAt,
  Email,
  // LastComp,
  LastCompp,
  StatusColor,
  PaymentOrUnderClients,
  PaymentOrClientsColor,
  OnClick,
  TrStyle,
  ImgUrl,
  Delete,
  type,
  StudentId,
  NextClassStartDate,
  NextClassStartTime,
  classStatus,
  TotalClasses,
  completedClasses,
  ClassType,
  ClassNo,
  Delivered,
  Edit,
  Update,
  InitiateClass,
}) => {
  return (
    <tr onClick={OnClick} className={TrStyle}>
      
      <td className="">
        <div className="ProfileImageContainer">
          <img
            className="absolute w-full"
            src={ImgUrl}
            alt={FirstName + "img"}
          />
        </div>
      </td>
      {/* <td className="px-3 text-start">{++index}</td> */}
      {type === "students" || "instructor-lesson-attendance" ? (
        <td className="relative text-center truncate hover:text-clip"> {StudentId} </td>
      ) : null}
      <td className="text-center truncate hover:text-clip">
        {FirstName} {LastName}
      </td>
      <td className="text-center">{Email}</td>
      <td className="text-center">
        <div
          className={`addCourse4Status ${
            classStatus === "Delivered"
              ? "bg-[#B5AAFF] text-white"
              : classStatus === "Missed"
              ? "bg-[#B05AC4] text-white"
              : "bg-[#7F82F9] text-white"
          }`}
        >
          {Status || classStatus}
        </div>
      </td>
      {ClassType ? (
        <td className={`text-center `}>
          {/* <div className={`addCourse4Status `}> */}
          {ClassType === "Driving"
            ? "In-Vehicle"
            : ClassType === "Online"
            ? "Online/others"
            : ClassType === "InClass"
            ? "In-Class"
            : null}
          {/* </div> */}
        </td>
      ) : null}

      {type === "instructor-lesson-attendance" && (
        <td className="text-center">{ClassNo}</td>
      )}
      {type === "students" && (
        <td className="text-center">
          {dayjs(new Date(createdAt)).format("DD/MM/YYYY")}
        </td>
      )}

      {/* {type === "students" ? (
        ""
      ) : (
        <td className="relative text-center">Super Visor</td>
      )} */}
      <td className={`  text-center`}>
        {completedClasses} /{TotalClasses || 0}
      </td>
      <td className="text-center">
        <div
          className={`addCourse4Status ${
            classStatus === "Delivered"
              ? "bg-[#B5AAFF] text-white"
              : classStatus === "Missed"
              ? "bg-[#B05AC4] text-white"
              : "bg-[#7F82F9] text-white"
          }`}
        >
         {NextClassStartDate} | {NextClassStartTime}
         {/*  {classStatus === "Upcoming Class"
            ? `${NextClassStartDate} | ${NextClassStartTime}`
            : classStatus} */}
        </div>
      </td>

      <td className={`  text-center`}>
        {type === "students" ? (
          <img
            className="absolute w-full"
            src={require("../../Assets/Chat.svg").default}
            alt={"chat"}
            width={40}
            height={40}
          />
        ) : (
          <>
            {type === "instructor-lesson-attendance" &&
            classStatus === "Delivered" ? (
              <Delivered />
            ) : type === "instructor-lesson-attendance" &&
              classStatus === "Missed" ? (
              <Edit />
            ) : type === "instructor-lesson-attendance" &&
               InitiateClass &&
               (classStatus === "Upcoming Class" || ClassType === "Driving" || ClassType === "Online" || ClassType === "InClass") ? (
                 <InitiateClass />
            ) : type === "instructor-lesson-attendance" &&
              (classStatus === "Upcoming Class" || ClassType === "Driving" || ClassType === "Online" || ClassType === "InClass") ? (
              <Update rowData={{ FirstName, LastName, Status, createdAt, Email, StudentId, NextClassStartDate, NextClassStartTime, classStatus, TotalClasses, completedClasses, ClassType, ClassNo }} />
            ) : null}
          </>
        )}
      </td>

      {/* { LastCompp ? (
                <td className="text-center">
                    <LastCompp />
                </td>
            ) : null}
            <td className="text-center">{LastComp ? <LastComp /> : null}</td>
            {Delete ? (
                <td className="text-center">
                    <Delete />
                </td>
            ) : null} */}
    </tr>
  );
};
