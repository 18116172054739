import React from "react";
import InstituteTemplate from "../../Components/InstituteTemplate/InstituteTemplate";
import StartClass from "../../Components/InVehicleClass/StartClass";

const InVehicleClass = () => {
  return (
    <InstituteTemplate
      Element={StartClass}
      bg="bg-[#F7F7F7] min-h-screen items-center"
    />
  );
};

export default InVehicleClass;
