// react States
import React from "react";

// components
import CoursesInventory from "./components/CoursesInventory.jsx";
import CourseInventoryAdminNote from "./components/courseInventoryAdminNote.jsx";

import "./courseSelection.css";

import CoursesFilters from "./components/course FIlters/index.jsx";

const CourseSelection = ({
  OnClickBack,
  Steps,
  setSteps,
  CourseData,
  setCourseData,
  StepsIndicator,
  PageNo,
  OnClickSteps,
  setCourseLicense,
  update,
}) => {
  // console.log("setcoursedataincorseselection", CourseData);
  return (
    <div
      className={`addCoursesMainContainer ${
        Steps !== PageNo ? "pointer-events-none  hidden" : ""
      }`}
    >
      <div className="row addCourse1">
        <div className="col-12" style={{ margin: "0 auto" }}>
          <div className="mainContainerAddCourse addCourseFirstPageCont">
            <StepsIndicator
              Steps={Steps}
              PageNo={PageNo}
              OnClickSteps={OnClickSteps}
            />
            <div className="row mt-4">
              <div className="col-9 vehicleInventoryInventory">
                <CoursesInventory
                  update={update}
                  setCourseLicense={setCourseLicense}
                  Steps={Steps}
                  setSteps={setSteps}
                  CourseData={CourseData}
                  setCourseData={setCourseData}
                />
              </div>

              <div
                className="col-3 vehicleInventorySearch mt-5 flex flex-col items-center"
                id="vehicleInventorySearch"
              >
                <CoursesFilters />
                <CourseInventoryAdminNote />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseSelection;
