import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import "./index.css";
export const Select = ({
    onChange,
    value: State,
    children,
    readOnly,
    placeholder,
    SelectedContainerStyles,
    ListContainerStyles,
}) => {
    const [selectValue, setSelectValue] = useState({ value: "", text: "" });
    const [FirstRender, setFirstRender] = useState(true);
    const [height, setHeight] = useState("max-h-[0px]");
    const valueRef = useRef();
    // console.log("SelectValue", selectValue);
    // console.log("Statevalue", State, typeof State);

    const Onclick = (e) => {
        if (!readOnly) {
            // Only open dropdown if readOnly is false
            e.stopPropagation();
            if (height === "max-h-[0px]") setHeight("max-h-[150px]");
            else if (height === "max-h-[150px]") setHeight("max-h-[0px]");
        }
    };

    useEffect(() => {
        if (!FirstRender) {
            if (onChange) onChange(selectValue);
        } else setFirstRender(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectValue]);

    if (!SelectedContainerStyles) SelectedContainerStyles = "py-2 bg-white";
    if (!ListContainerStyles)
        ListContainerStyles = " shadow-[5px_4px_6px_#00000029] text-3xs";

    return (
        <div className="relative flex w-full flex-col" onClick={Onclick}>
            <div
                className={`flex w-full items-center justify-between px-2 ${SelectedContainerStyles}`}
                ref={valueRef}
            >
                <input type="hidden" value={selectValue?.value} />

                <div
                    style={{
                        color:
                            !selectValue?.text || selectValue?.text === ""
                                ? "gray"
                                : "black",
                    }}
                >
                    {!selectValue?.text || selectValue?.text === ""
                        ? placeholder
                        : selectValue?.text}
                </div>

                <img src={require("./Assets/Arrow.svg").default} alt="" />
            </div>

            <div
                style={{ top: valueRef?.current?.offsetHeight + 2 + "px" }}
                id="Options-Container"
                className={`flex flex-col ${height} absolute z-10 w-full gap-[6px] overflow-auto bg-white shadow-[5px_4px_6px_#00000029] duration-150`}
            >
                {React.Children.map(children, (child) =>
                    React.cloneElement(child, {
                        value: child?.props?.value,
                        setSelectValue,
                        State: String(State),
                    })
                )}
            </div>
        </div>
    );
};
export const Option = ({ value, children, setSelectValue, State }) => {
    const BtnRef = useRef();
    const OnClickBtn = (e) => {
        // e.stopPropagation()
        setSelectValue({
            value: e.target.getAttribute("data-value"),
            text: e.target.innerText?.trim(),
        });
    };
    useEffect(() => {
        if (State === value && BtnRef?.current && State !== "")
            setSelectValue({ value, text: BtnRef?.current?.innerText?.trim() });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [State, value, BtnRef]);

    return (
        <div
            className="cursor-pointer bg-[#00000000] px-1  py-1 duration-100 hover:bg-[#989898]"
            ref={BtnRef}
            data-value={value}
            onClick={OnClickBtn}
        >
            {children}
        </div>
    );
};
