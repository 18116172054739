import React, {useState, useEffect} from "react";
import ClassesIndicator from "../../../../../Components/ClassesIndicator/ClassesIndicator";
import RadioBtnClasses from "../../../../../Components/ClassesIndicator/RadioBtnClasses";
import InstructorCardTemplate from "./Components/InstructorCardTemplate";
import './courseI.css'
import TimeSlots from "../../../../CourseEnrollment/TimeSlots/TimeSlots";
import Schedule from "../../../../CourseEnrollment/UserInfo/Components/schedule/Schedule";
import ClassHoursSchedule from "../../../../CourseEnrollment/UserInfo/Components/schedule/ClassHoursSchedule";
import { useTimetable } from "../../../../../context/timetableContext";

const CourseInstructors = ({
  ShowSlider,
  setShowSlider,
  Instructors,
  OneInstructor,
  setOneInstructor,
  setStudentPrefrredTimeTable,
  timeSlots,
  DrivingHours,
  OnlineHours,
  InClassHours
}) => {
  const [selectedClassType, setSelectedClassType] = useState("Driving");
  const { userSelectedDbTimetable } = useTimetable();
  const [ShowTimeSlots, setShowTimeSlots] = useState(false);
  const [ShowSchedule, setShowSchedule] = useState(false)
  const [Err, setErr] = useState({});
  const [classHoursData, setClassHoursData] = useState({
    DayandHoursData: {
        Schedule: {
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
        },
    },
});

// Helper function to get the correct date for each day of the week (e.g., Monday, Tuesday, etc.)
const getDateForDay = (day) => {
  const daysOfWeek = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  };

  const today = new Date();  // Get current local date
  const currentDay = today.getDay();  // Get current day of the week (0 for Sunday, 1 for Monday, etc.)

  // Calculate the difference in days between today and the target day
  let diff = daysOfWeek[day] - currentDay;

  // If the target day is earlier in the week (negative diff), go to the next occurrence (next week)
  if (diff < 0) {
    diff += 7;
  }

  // Create a new Date object by adding the difference in days
  const correctDay = new Date(today);
  correctDay.setDate(today.getDate() + diff);
  
  // Handle the time zone offset to avoid day shift issues (ensure it starts from midnight local time)
  correctDay.setHours(0, 0, 0, 0);

  // Adjust for time zone differences, ensuring the correct local time
  const timezoneOffset = correctDay.getTimezoneOffset() * 60000;  // Offset in milliseconds
  const localDate = new Date(correctDay.getTime() - timezoneOffset);

  return localDate;
};


const convertTo24Hour = (time) => {
  const [timePart, modifier] = time.split(' ');
  let [hours, minutes] = timePart.split(':').map(Number);

  if (modifier === 'PM' && hours < 12) hours += 12;
  if (modifier === 'AM' && hours === 12) hours = 0;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
};

// Helper function to parse time range and assign it to the correct date
const parseTimeRange = (day, timeRange) => {
  const [startTime, endTime] = timeRange.split(' - ');

  // Get the correct date for the given day
  const dayDate = getDateForDay(day);

  // Format the date as 'YYYY-MM-DD'
  const formattedDate = dayDate.toISOString().split('T')[0];

  // Create start and end Date objects by combining the correct date with the time (use local time)
  const startDate = new Date(`${formattedDate}T${convertTo24Hour(startTime)}:00`);
  const endDate = new Date(`${formattedDate}T${convertTo24Hour(endTime)}:00`);

  // Return start and end as local date objects, not in UTC
  return { startDate, endDate };
};

// Transform class hours data to associate the correct date with each event
const transformClassHoursData = (classHoursData) => {
  console.log("classHoursData in course instructor is:",classHoursData)
  const dayMappings = {
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
  };

  const transformedData = [];

  // Loop over each day
  for (const day in dayMappings) {
    const slots = classHoursData.DayandHoursData.Schedule[day];

    // Only process days that have time slots (i.e., non-empty arrays)
    if (slots && slots.length > 0) {
      slots.forEach((slot, index) => {
        // Parse the time range and associate with the correct date for the given day
        const { startDate, endDate } = parseTimeRange(day, slot);

        if (startDate && endDate) {
          const eventData = {
            allDay: false,
            description: `${day} Class`, // You can modify this description as needed
            end: endDate.toLocaleString(),  // Use local string instead of ISO (to keep local time)
            endDate: endDate,  // Date object for end time
            event_id: `${day}-${index}`, // Unique identifier (based on day and index)
            start: startDate.toLocaleString(), // Use local string instead of ISO (to keep local time)
            startDate: startDate, // Date object for start time
            text: selectedClassType, // Optional text description
          };

          transformedData.push(eventData);
        }
      });
    }
  }

  return transformedData;
};

// UseEffect or function to handle transformation and setting the new state
useEffect(() => {
  const transformedData = transformClassHoursData(classHoursData);
  console.log("transformed data is:", transformedData);
  setStudentPrefrredTimeTable(transformedData);
}, [classHoursData]);

  const OnClick = (InstructorType) =>
    setShowSlider({ ...ShowSlider, InstructorType, Show: !ShowSlider.Show });

    const handleClassTypeChange = (event) => {
      setSelectedClassType(event.target.value);
    };

    const handleRadioChange = (event) => {
      if (event.target.value === 'useClientSchedule') {
        // Set the preferred timetable to the context value
        setStudentPrefrredTimeTable(userSelectedDbTimetable);
      } else {
        // Show time slots for custom schedule
        setShowTimeSlots(true);
      }
    };

  return (
    <div className="mt-5 flex w-full flex-col items-center gap-7">
     

      
        <span className="flex flex-col gap-3">
          {/* <ClassesIndicator /> */}
              <RadioBtnClasses 
              handleClassTypeChange={handleClassTypeChange} 
              selectedClassType={selectedClassType}
              InClassHours={InClassHours}
              DrivingHours={DrivingHours}
              OnlineHours={OnlineHours} />
          {/* <div className="flex w-full items-center justify-center gap-1">
            <input
              type="checkbox"
              className="h-5 w-5"
              id="OneInstructor"
              value={OneInstructor}
              onChange={() => setOneInstructor(!OneInstructor)}
            ></input>
            <label htmlFor="OneInstructor" className="text-6xs">
              Use same Instructor for all Category classes
            </label>
          </div> */}
        </span>

        <div className="mt-[20px] flex w-full flex-wrap justify-center gap-20 ">
          {/* <InstructorCardTemplate
            InstructorType="Driving"
            Instructor={Instructors[0]}
            OnClick={() => OnClick("Driving")}
          />
          {!OneInstructor ? (
            <>
              <InstructorCardTemplate
                InstructorType="Online"
                Instructor={Instructors[1]}
                OnClick={() => OnClick("Online")}
              />
              <InstructorCardTemplate
                InstructorType="InClass"
                Instructor={Instructors[2]}
                OnClick={() => OnClick("InClass")}
              />
            </>
          ) : null} */}

          <TimeSlots
                ShowTimeSlots={ShowTimeSlots}
                setShowTimeSlots={setShowTimeSlots}
                setClassHoursData={setClassHoursData}
                classHoursData={classHoursData}
                Err={Err}
                setErr={setErr}
                setShowSchedule={setShowSchedule}
                isClassHours={"true"}
                timeSlots={timeSlots}
            />

          {selectedClassType === "Driving" && (
            <>
            {
            ShowSchedule ? 
            <ClassHoursSchedule classHoursData={classHoursData} isClassHours={"true"} setShowTimeSlots={setShowTimeSlots} /> : null
            } 

            <div className="flex flex-col gap-[20px]">
            {/* <label className="text-xs">
              <input
                type="radio"
                name="scheduleOption"
                value="useClientSchedule"
                onChange={handleRadioChange}
                className="w-[15px] h-[15px] bg-transparent mr-[10px]"
              />
              Use Client Selected Schedule
            </label>

            <label className="text-xs">
              <input
                type="radio"
                name="scheduleOption"
                value="setYourOwnSchedule"
                onChange={handleRadioChange}
                className="w-[15px] h-[15px] bg-transparent mr-[10px]"
              />
              Set Your Own Schedule
            </label> */}

              <InstructorCardTemplate
              InstructorType="Driving"
              Instructor={Instructors[0]}
              OnClick={() => OnClick("Driving")}
            />
            </div>
          </>
          )}

          {selectedClassType === "Online" && (
            <>
             {
            ShowSchedule ? 
            <ClassHoursSchedule classHoursData={classHoursData} isClassHours={"true"} setShowTimeSlots={setShowTimeSlots} /> : null
            } 

            <div className="flex flex-col gap-[15px]">
            {/* <button
              type="button"
              id="Schedule"
              className="cursor-pointer rounded-xl border-none bg-[#A1A3EF] px-5 py-2 text-xs text-white"
              onClick={() => setShowTimeSlots(true)}
            >
             {ShowSchedule ? 'Update Schedule for Online Classes' : 'Select Schedule for Online Classes'}
            </button> */}

            <InstructorCardTemplate
              InstructorType="Online"
              Instructor={Instructors[1]}
              OnClick={() => OnClick("Online")}
            />
            </div>
          </>
        )}
        {selectedClassType === "InClass" && (
          <>
             {
            ShowSchedule ? 
            <ClassHoursSchedule classHoursData={classHoursData} isClassHours={"true"} setShowTimeSlots={setShowTimeSlots} /> : null
            } 

            <div className="flex flex-col gap-[15px]">
            {/* <button
              type="button"
              id="Schedule"
              className="cursor-pointer rounded-xl border-none bg-[#A1A3EF] px-5 py-2 text-xs text-white"
              onClick={() => setShowTimeSlots(true)}
            >
             {ShowSchedule ? 'Update Schedule for InClass Classes' : 'Select Schedule for InClass Classes'}
            </button> */}
          <InstructorCardTemplate
            InstructorType="InClass"
            Instructor={Instructors[2]}
            OnClick={() => OnClick("InClass")}
          />
         
          </div>
          </>
        )}
     
      </div>
    </div>
  );
};

export default CourseInstructors;
