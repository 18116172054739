
import "devextreme/dist/css/dx.light.css";
import { Editing, Resource, Scheduler, View } from "devextreme-react/scheduler";
import { useAuthContext } from "../../../context/authContext";
import { useState, useEffect, useContext } from "react";
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import './AssignSchedule.css'
import { useTimetable } from "../../../context/timetableContext";
import NewTimeSlotsComponent from "../../../Pages/CourseEnrollment/TimeSlots/NewTimeSlotComponent";
import { RRule } from 'rrule';
import { StudentClassesContext } from "../../../context/StudentClassesContext";
import ModalTimeSlots from "../../../Pages/CourseEnrollment/TimeSlots/ModalTimeSlot";
import AddIcon from '@mui/icons-material/Add';


const getNextMonday=(date) =>{
  const resultDate = moment(date).clone().startOf('isoWeek').add(1, 'weeks'); // Get next Monday (ISO Week starts on Monday)
  return resultDate;
}

const firstInstructorSchedule = (events, drivingHours, firstInstructor, instructorTypeId, selectedDate) => {
  console.log("First instructor schedule called for:", selectedDate);
  console.log("First instructor schedule called for events:", events);

  const scheduledEvents = [];
  const startDay = selectedDate ? moment(selectedDate) : getNextMonday(new Date()); // Use selectedDate if provided, otherwise next Monday
  let drivingHourCount = 0;  // Total accumulated driving hours
  let currentDay = moment(startDay); // Start from the selected date or next Monday
  let lastDrivingEventEndDate = null;
  let classNumber = 1;

  console.log("Initial Start Day:", startDay.format("YYYY-MM-DD"));

  // Group events by day and extract valid hours
  const eventsByDay = {};
  events.forEach(event => {
    const day = moment(event.startDate).day();
    if (!eventsByDay[day]) eventsByDay[day] = [];
    eventsByDay[day].push(event);
  });

  // Get all valid days from events (ensure it's sorted in ascending order)
  const validDays = Object.keys(eventsByDay)
    .map(day => parseInt(day))
    .sort((a, b) => a - b);

  // Loop to schedule driving hours first
  while (drivingHourCount < drivingHours) {
    for (const day of validDays) {
      // If the current day is before the start day, skip it
      if (currentDay.day() > day) {
        continue;
      }

      // Set currentDay to the selected event day
      currentDay.day(day);

      for (const event of eventsByDay[day]) {
        if (drivingHourCount >= drivingHours) break;

        const eventStart = moment(event.startDate);
        const eventEnd = moment(event.endDate);

        // Calculate the event's duration in hours
        const eventDuration = eventEnd.diff(eventStart, 'hours', true);

        // Only schedule as much time as is still needed to reach drivingHours
        const remainingHours = drivingHours - drivingHourCount;
        const scheduledDuration = Math.min(eventDuration, remainingHours);

        // Create the new event using the event duration
        const newStartDate = moment(currentDay).hour(eventStart.hour()).minute(eventStart.minute());
        const newEndDate = moment(newStartDate).add(scheduledDuration, 'hours');

        const newEvent = {
          ...event,
          start: newStartDate.toISOString(),
          end: newEndDate.toISOString(),
          startDate: newStartDate.toDate(),
          endDate: newEndDate.toDate(),
          ClassType: firstInstructor,
          event_id: `${Date.now()}-${Math.floor(Math.random() * 10000)}`,
          Instructors: instructorTypeId,
          ClassNo: classNumber,
        };

        scheduledEvents.push(newEvent);

        // Increment the classNumber for the next event
        classNumber++;

        // Add the scheduled time to the total driving hours
        drivingHourCount += scheduledDuration;
        lastDrivingEventEndDate = newEndDate;
      }

      if (drivingHourCount >= drivingHours) break;
    }

    if (drivingHourCount < drivingHours) {
      // Not enough hours in the current week, go to the next week's Monday
      currentDay = currentDay.startOf('week').add(1, 'week').day(1); // Move to next Monday
    }
  }

  return scheduledEvents;
};

const generateRepeats = (events, weeksAhead) => {
  const generatedEvents = [];

  events.forEach(event => {
    // Use the original start and end times for each event
    const originalStartDate = new Date(event.startDate);
    const originalEndDate = new Date(event.endDate);

    // Generate events for each week (up to the given weeksAhead)
    for (let i = 0; i <= weeksAhead; i++) {
      // Create new instances of the start and end date for each repetition
      let newStartDate = new Date(originalStartDate);
      let newEndDate = new Date(originalEndDate);

      // Add weeks to the start and end dates, but start from the original week for the first event
      if (i > 0) {
        newStartDate.setDate(newStartDate.getDate() + i * 7);
        newEndDate.setDate(newEndDate.getDate() + i * 7);
      }

      // Add the event if it's within the desired date range (optional: you can adjust this condition)
      if (newStartDate >= originalStartDate) {
        generatedEvents.push({
          ...event,
          startDate: newStartDate,
          endDate: newEndDate,
        });
      }
    }
  });

  return generatedEvents;
};

function AssignScheduleTimetable({ Events, setEvents, Edit, Resources, studentSchedule, CoursePackage, StudentEnrollmentSelectedTimeTable, selectedDate,
   setSelectedDate, InsEvent, setPrefrredTimeTable, setShowOwnTimeFields, showOwnTimeFields,
   setClassHoursData, classHoursData, saveStudentTimeTableContext, setSaveStudentTimeTableContext, setNewRefreshedEvents }) {
  console.log("Course Package in the assign schedule timetable is:", CoursePackage)
  console.log("studentSchedule in the assign schedule timetable is:", Events)
  console.log("Resources in the assign schedule timetable is:", Resources)
  console.log("StudentEnrollmentSelectedTimeTable in the assign schedule timetable is:", StudentEnrollmentSelectedTimeTable)
  console.log("InsEvent in the assign schedule timetable is:", InsEvent)
  const { studentClassesScheduleAppointments, setStudentClassesScheduleAppointments } = useContext(StudentClassesContext);


  

  const [uniqueKey, setUniqueKey] = useState(0); // Start with an initial value
  const [StudentTimeTable, setStudentTimeTable] = useState([]);

  // const [selectedDate, setSelectedDate] = useState(null); // Initialize with current date
  const [instructorTypeExtracted, setInstructorTypeExtracted] = useState(null);
  const [instructorTypeId, setInstructorTypeId] = useState(null);
  const [secondInstructor, setSecondInstructor] = useState(null);
  const [thirdInstructor, setThirdInstructor] = useState(null);
  const [firstClassHourType, setFirstClassHourType] = useState(null);
  const [firstClassTotalHours, setFirstClassTotalHours] = useState(null);
  const [selectedInterval, setSelectedInterval] = useState('');
  

  // Effect to extract instructor types and set state
useEffect(() => {
  if (Resources && Resources.length > 0) {
    const dataSource = Resources[0].dataSource; // Assuming you're checking the first resource's dataSource

    if (dataSource && dataSource.length > 0) {
      if (dataSource[0]) {
        setInstructorTypeExtracted(dataSource[0].InstructorType);
        setInstructorTypeId(dataSource[0].InstructorFK)
      }
      if (dataSource[1]) {
        setInstructorTypeExtracted(dataSource[1].InstructorType);
        setInstructorTypeId(dataSource[1].InstructorFK)
      }
      if (dataSource[2]) {
        setInstructorTypeExtracted(dataSource[2].InstructorType);
        setInstructorTypeId(dataSource[2].InstructorFK)
      }
    }
  }
}, [Resources]);

// Separate Effect to handle scheduling when instructor and class hours are ready
useEffect(() => {
  const timeoutId = setTimeout(() => {
  if (instructorTypeExtracted && CoursePackage && Events.length > 0) {
    let totalHours;

    // Set total hours based on the first instructor's type
    if (instructorTypeExtracted === 'Driving') {
      totalHours = CoursePackage.DrivingHours;
      setFirstClassHourType('Driving');
    } else if (instructorTypeExtracted === 'Online') {
      totalHours = CoursePackage.OnlineHours;
      setFirstClassHourType('Online');
    } else if (instructorTypeExtracted === 'InClass') {
      totalHours = CoursePackage.InClassHours;
      setFirstClassHourType('InClass');
    }

    // Now that we have the hours and instructor, schedule the events
    if (totalHours) {
      const scheduledEvents = firstInstructorSchedule(Events, totalHours, instructorTypeExtracted, instructorTypeId, selectedDate);
      console.log("Generated schedule for first instructor:", scheduledEvents);
      setSaveStudentTimeTableContext([...scheduledEvents])
      // Find the last date of the newly scheduled events
      const lastScheduledEvent = scheduledEvents[scheduledEvents.length - 1];
      const lastScheduledEndDate = moment(lastScheduledEvent.endDate);

      // Find the first event's start date in the scheduled events
      const firstScheduledEvent = scheduledEvents[0];
      const firstScheduledStartDate = moment(firstScheduledEvent.startDate);

      // Find the last event's end date in student enrollment
      const lastStudentEvent = StudentEnrollmentSelectedTimeTable[StudentEnrollmentSelectedTimeTable.length - 1];
      const lastStudentEventEndDate = moment(lastStudentEvent.endDate);

      let nextWeekEvents;
      // Check if new events go beyond the last student enrollment event date
      if (lastScheduledEndDate.isAfter(lastStudentEventEndDate)) {
        // Calculate the difference in weeks between first and last scheduled events
        const weeksDifference = Math.ceil(moment.duration(lastScheduledEndDate.diff(firstScheduledStartDate)).asWeeks());
        console.log("Weeks difference between first and last scheduled events:", weeksDifference);

        // Repeat student enrollment events for the calculated number of weeks
        nextWeekEvents = generateRepeats(StudentEnrollmentSelectedTimeTable, weeksDifference);
        console.log("nextWeekEvents", nextWeekEvents);
      } else {
        nextWeekEvents = StudentEnrollmentSelectedTimeTable;
      }

        
      setEvents([...scheduledEvents]);
      setStudentTimeTable((prevEvents) => {
        // Merge existing events with new scheduled events
        const updatedEvents = [...nextWeekEvents, ...InsEvent, ...studentClassesScheduleAppointments, ...scheduledEvents];
        console.log("Updated Events State:", updatedEvents);
        return updatedEvents;
      });
      console.log("Updated setStudentTimeTable State after schedule:", StudentTimeTable);
      console.log("Updated Events State after schedule:", Events);
      setUniqueKey((prevKey) => prevKey + 1);
    }
  } 
}, 5000); // 5 seconds delay

// Cleanup function to clear the timeout if the component is unmounted before 5 seconds
return () => clearTimeout(timeoutId);

}, [instructorTypeExtracted, CoursePackage, Events, selectedDate, StudentEnrollmentSelectedTimeTable, InsEvent]); // Add all dependencies


  useEffect(() => {
    setStudentTimeTable([...Events]);
  }, [Events]);
  

  async function getInstructorType(id) {
    console.log("Resources:", Resources);
    console.log("Resources[0].dataSource:", Resources?.[0]?.dataSource);
    console.log("id", id)

    //check whether the resources and id are present or not
    if (!id) {
      console.warn("Invalid ID passed to getInstructorType:", id);
      return null;
    }
  
    // Verify Resources structure
    if (!Resources || !Array.isArray(Resources)) {
      console.warn("Invalid Resources structure:", Resources);
      return null;
    }

    // Iterate through all dataSource arrays in Resources instead of just checking the first record
  for (let resource of Resources) {
    if (resource.dataSource && Array.isArray(resource.dataSource)) {
      const instructor = resource.dataSource.find((val) => val.InstructorFK === id);

      if (instructor) {
        console.log("Instructor found:", instructor);
        return instructor.InstructorType; // Or return the instructor object if needed
      }
    }
  }

  console.warn("No instructor found for ID:", id);
  return null;

   /*  const instructor = Resources?.[0]?.dataSource.find((val) => val.InstructorFK === id);
    console.log("instructor", instructor)
    console.log("instructor.InstructorType", instructor.InstructorType)
    return instructor ? instructor.InstructorType : null; */
  }
  

  const {setLastFiveValues, lastFiveValues} = useAuthContext()
  console.log("resources", Resources)
  const neww = Resources?.[0].dataSource.map((val)=> console.log("val", val))
  console.log("neww",neww)


  // const renderTimeCell = (data) => (
  //   <i style={{ color: "green" }}>{data?.text}</i>
  // );

  const renderDateCell = (data) => {
    const { date } = data; // Extract date from the data
    const dayOfWeek = date.getDay(); // Get the day of the week (0 = Sunday, 6 = Saturday)
  
    // Apply grey background for Saturday (6) and Sunday (0)
    const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;
  
    return (
      <div
        style={{
          backgroundColor: isWeekend ? '#e0e0e0' : 'transparent', // Grey for weekends, transparent for others
          height: '100%', // Ensure the cell takes full height
          width: '100%' // Ensure the cell takes full width
        }}
      >
        {data.text} {/* Keep the date text */}
      </div>
    );
  };
  
  const renderTimeCell = (data) => {
    const { startDate } = data; // Each time cell represents a specific start time
  
    if (!startDate) {
      return <div>{data.text}</div>; // Render default cell if startDate is undefined
    }
  
    const dayOfWeek = startDate.getDay(); // Get the day of the week (0 = Sunday, 6 = Saturday)
  
    // Apply grey background for Saturday (6) and Sunday (0)
    const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;
  
    return (
      <div
        style={{
          backgroundColor: isWeekend ? '#e0e0e0' : 'transparent', // Grey for weekends, transparent for others
          height: '100%', // Ensure the cell takes full height
          width: '100%' // Ensure the cell takes full width
        }}
      >
        {data.text} {/* Keep the time text */}
      </div>
    );
  };
  

  const OnUpdate = (e) => {
    if(studentSchedule === 'lastFiveValues') {
      const lastFive = Events.slice(-5);
      setLastFiveValues(lastFive);
    }

  };
  // console.log("lastFiveValues",lastFiveValues)
  

  const onAppointmentDeleting = (e) => {
    // Prevent automatic deletion until you confirm it
    e.cancel = true;
  
    const appointmentId = e.appointmentData.event_id || e.appointmentData.EventId;
    
      deleteAppointment(appointmentId);
  };

  const deleteAppointment = (appointmentId) => {
    console.log("appointmentId in deleteing is:",appointmentId)
    const updatedAppointments = StudentTimeTable.filter(
      (appointment) => appointment.event_id !== appointmentId && appointment.EventId !== appointmentId
    ); 

    const updatedSaveStudentAppointments = saveStudentTimeTableContext.filter(
      (appointment) => appointment.event_id !== appointmentId && appointment.EventId !== appointmentId
    );

    const updatedStudentClassesScheduleAppointments = studentClassesScheduleAppointments.filter(
      (appointment) => appointment.event_id !== appointmentId && appointment.EventId !== appointmentId
    );
  
    setStudentTimeTable(updatedAppointments);
    setEvents(updatedAppointments);
    setSaveStudentTimeTableContext(updatedSaveStudentAppointments)
    setStudentClassesScheduleAppointments(updatedStudentClassesScheduleAppointments)
  };
  
  
  const onAppointmentDeleted = (e) => {
    const appointmentId = e.appointmentData.event_id || e.appointmentData.EventId;
  
    // Remove the deleted appointment from the StudentTimeTable array
    const updatedAppointments = StudentTimeTable.filter(
      (appointment) => appointment.event_id !== appointmentId && appointment.EventId !== appointmentId
    );
  
    const updatedSaveStudentAppointments = saveStudentTimeTableContext.filter(
      (appointment) => appointment.event_id !== appointmentId && appointment.EventId !== appointmentId
    );

    const updatedStudentClassesScheduleAppointments = studentClassesScheduleAppointments.filter(
      (appointment) => appointment.event_id !== appointmentId && appointment.EventId !== appointmentId
    );
    // Update the state with the filtered appointments
    setStudentTimeTable(updatedAppointments);
    setEvents(updatedAppointments);
    setSaveStudentTimeTableContext(updatedSaveStudentAppointments)
    setStudentClassesScheduleAppointments(updatedStudentClassesScheduleAppointments)
  };
  
  const onAppointmentAdding = (e) => {
  };
  // const onAppointmentAdded = async (e) => {
  //   const { appointmentData } = e;
  //   console.log("appointment data", appointmentData)
  //   console.log("appoin", appointmentData.Instructors)

  //   const type = await getInstructorType(appointmentData.Instructors)
  //   console.log("type", type)
  //   // console.log("appointmentData", appointmentData)
  //   const NewEvents = Events.map((value) => {
  //     if (
  //       value.endDate === appointmentData.endDate &&
  //       value.startDate === appointmentData.startDate &&
  //       value.startDate === appointmentData.startDate &&
  //       value.description === appointmentData.description &&
  //       value.text === appointmentData.text
  //     ) {
  //       value.InstructorFK = appointmentData.Instructors;
  //       // value.ClassType = appointmentData.ClassType;
  //       value.ClassType =  type
  //     }
  //     return value;
  //   });
  //   setStudentTimeTable([...NewEvents]);
  //   setEvents([...NewEvents])
  // };


 /*  const onAppointmentAdded = async (e) => {
    const { appointmentData } = e;
  
    console.log("appointment data", appointmentData);
    console.log("instructors", appointmentData.Instructors);
  
    // Fetch the class type based on the instructor
    const type = await getInstructorType(appointmentData.Instructors);
  
    // Format the new appointment in the desired structure
    const newAppointment = {
      ClassNo: Events.length + 1, // Assign a unique ClassNo (incrementing)
      ClassType: type || "Unknown", // Assign the class type based on the instructor
      Instructors: appointmentData.Instructors, // Instructor ID
      allDay: appointmentData.allDay || false, // Default to false if not provided
      description: appointmentData.description || "", // Description of the class
      end: appointmentData.endDate.toISOString(), // ISO string for end time
      endDate: appointmentData.endDate, // JavaScript Date object for end time
      event_id: `event_${Date.now()}`, // Assign a unique ID for the event
      start: appointmentData.startDate.toISOString(), // ISO string for start time
      startDate: appointmentData.startDate, // JavaScript Date object for start time
      text: appointmentData.text || "", // Text description (if any)
    };

    console.log("newAppointment scheduled is:",newAppointment)
  
    // Append the new appointment to the existing events
    // const updatedEvents = [...Events, newAppointment];
  
    // Update the relevant state variables, keeping previous data intact
    // setStudentTimeTable((prevData) => [...prevData, newAppointment]); // Preserve existing data
  
    // Update the context and other related state variables while keeping the previous data intact
     setStudentClassesScheduleAppointments((prevAppointments) => [...prevAppointments, newAppointment]); // Preserve data
    // Optionally, log or perform other actions after the update
    // console.log("Updated Events", updatedEvents);
  }; */
  
  const onAppointmentAdded = async (e) => {
    const { appointmentData } = e;
  
    console.log("appointment data", appointmentData);
    console.log("instructors", appointmentData.Instructors);
  
    // Fetch the class type based on the instructor
    const type = await getInstructorType(appointmentData.Instructors);
  
    // Format the new appointment in the desired structure
    const newAppointment = {
      ClassNo: Events.length + 1, // Assign a unique ClassNo (incrementing)
      ClassType: type || "Unknown", // Assign the class type based on the instructor
      Instructors: appointmentData.Instructors, // Instructor ID
      allDay: appointmentData.allDay || false, // Default to false if not provided
      description: appointmentData.description || "", // Description of the class
      end: appointmentData.endDate.toISOString(), // ISO string for end time
      endDate: appointmentData.endDate, // JavaScript Date object for end time
      event_id: `event_${Date.now()}`, // Assign a unique ID for the event
      start: appointmentData.startDate.toISOString(), // ISO string for start time
      startDate: appointmentData.startDate, // JavaScript Date object for start time
      text: appointmentData.text || "", // Text description (if any)
    };
  
    console.log("newAppointment scheduled is:", newAppointment);
  
    // Update StudentTimeTable with the new appointment
    const updatedStudentTimeTableAppointments = StudentTimeTable.map((value) => {
      if (
        value.endDate.getTime() === appointmentData.endDate.getTime() &&
        value.startDate.getTime() === appointmentData.startDate.getTime() &&
        value.description === appointmentData.description &&
        value.text === appointmentData.text
      ) {
        // Update existing appointment with the new data
        return {
          ...value,
          InstructorFK: appointmentData.Instructors,
          ClassType: type || value.ClassType,
          ClassNo: Events.length + 1,
          event_id: `event_${Date.now()}`,
        };
      }
      return value;
    });
  
    // Append the new appointment to the updated list
    setStudentTimeTable([...updatedStudentTimeTableAppointments]);
    console.log("setStudentTimeTable in appointment added is:", StudentTimeTable);
  
    // Call function to update studentClassesScheduleAppointments
    updateStudentClassesSchedule(newAppointment);
  };
  
  // Function to update studentClassesScheduleAppointments
  const updateStudentClassesSchedule = (newAppointment) => {
  
    // Append the new appointment to the updated list
    setStudentClassesScheduleAppointments((prevAppointments) => [...prevAppointments, newAppointment]);
  };
  
  
  const handleIntervalChange = (event) => {
    setSelectedInterval(event.target.value);
  };
  
  console.log("Events", Events)

  const customizeAppointment = (e) => {
    // Customize the color based on conditions
    let appointmentColor = "#1EC1FE";
    let appointmentText =  "";
    // console.log('eeee', e.appointmentData)
   
    if (e?.appointmentData?.EventId) {
      appointmentColor = "#34ADAD";
    } else if (e?.appointmentData?.CourseEnrollment?.Status === "Hold") {
      appointmentColor = "#d9534f";
    } else if (e?.appointmentData?.ClassType === "Online") {
      appointmentColor = "#7f82f9";
      appointmentText = "Online";
    } else if (e?.appointmentData?.ClassType === "InClass") {
      appointmentColor = "#5456c7";
      appointmentText = "InClass";
    }else if (e?.appointmentData?.ClassType === "Driving") {
      appointmentColor = "#a1a3ef";
      appointmentText = "Driving";
    } else if (e?.appointmentData?.ClassType === "User") {
      appointmentColor = "#ffb84c"; // Example color for "User" class type
      appointmentText = "User Schedule"; // Text to display for "User" class type
    }
    
    return (
      <div
        className=""
        id="newDiv"
        style={{
          backgroundColor: appointmentColor,
          border: "0",
          margin: "0",
          width: "calc(100% + 10px)", // Adjust for left and right padding (e.g., 5px on each side)
          height: "calc(100% + 10px)",
        }}
      >
        <div className="flex h-full w-full items-center justify-center">
          <p className="mb-2 mr-2 text-[12px]">
            {" "}
            {appointmentText || e?.appointmentData?.CourseEnrollment?.User?.FirstName}
          </p>
        </div>
      </div>
    );
  };

  const { userSelectedDbTimetable } = useTimetable();



  return (
    <>
    {/* <div style={{ marginBottom: '50px', marginTop:'10px', height: '30px', width: '800px', display:'flex', gap:'30px' }} >
      

        <label className="text-xs">
              <input
                type="radio"
                name="scheduleOption"
                value="useClientSchedule"
                onChange={handleRadioChange}
                className="w-[15px] h-[15px] bg-transparent mr-[10px]"
              />
              Use Student Schedule
            </label>

            <label className="text-xs">
              <input
                type="radio"
                name="scheduleOption"
                value="setYourOwnSchedule"
                onChange={handleRadioChange}
                className="w-[15px] h-[15px] bg-transparent mr-[10px]"
              />
              Set Own Schedule
            </label>

             

    </div> */}

    { showOwnTimeFields && <ModalTimeSlots 
                timetable="assignschedule"
                setClassHoursData={setClassHoursData}
                classHoursData={classHoursData}
                showOwnTimeFields={showOwnTimeFields}
                setShowOwnTimeFields={setShowOwnTimeFields}
                instructorTypeExtracted={instructorTypeExtracted}
            /> }


      <div style={{ overflowX: 'auto' }}>
        <Scheduler
          key={uniqueKey}  // Use uniqueKey here to force re-render
          id="scheduler"
          dataSource={StudentTimeTable}
          currentView="week"
          views={["week", "month"]}
          startDayHour={9}
          endDayHour={19}
          crossScrollingEnabled={true} 
          onAppointmentUpdated={OnUpdate}
          onAppointmentDeleted={onAppointmentDeleted}
          onAppointmentDeleting={onAppointmentDeleting}
          onAppointmentAdding={onAppointmentAdding}
          onAppointmentAdded={onAppointmentAdded}
          showCurrentTimeIndicator={true}
          shadeUntilCurrentTime={true}
          appointmentRender={customizeAppointment}
          className=""
          // dateCellRender={renderDateCell}  // Date cell render
          // timeCellRender={renderTimeCell}  // Time cell render (for time slots)
        >
          <Editing allowDeleting={Edit} allowAdding={Edit} allowUpdating={Edit} />
          <View
            type="week"
            cellDuration={45}
            dateCellRender={renderTimeCell}
          />
          {/* {Resources?.length > 0
            ? Resources.map((value) => (
                <Resource
                  key={value.fieldExpr}
                  fieldExpr={value.fieldExpr}
                  dataSource={value.dataSource}
                />
              ))
            : null} */}
            {Resources?.length > 0 ? <Resource
                  key={Resources[0].fieldExpr}
                  fieldExpr={Resources[0].fieldExpr}
                  dataSource={Resources[0].dataSource}
                  style={{ width: '100%' }} 
                />
            
            : null}
        </Scheduler>
      </div>

    </>
  );
}

export default AssignScheduleTimetable;

// [
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T04:00:00.000Z",
//     "endDate": "2024-06-24T04:30:00.000Z",
//     "Instructors": "66a862b2-86a2-4d5c-9353-f5709962e364",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   },
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T05:30:00.000Z",
//     "endDate": "2024-06-24T06:00:00.000Z",
//     "Instructors": "d0b3c6c1-2114-4c4a-a36c-37207abf5ae8",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   },
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T06:00:00.000Z",
//     "endDate": "2024-06-24T06:30:00.000Z",
//     "Instructors": "d0b3c6c1-2114-4c4a-a36c-37207abf5ae8",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   },
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T06:30:00.000Z",
//     "endDate": "2024-06-24T07:00:00.000Z",
//     "Instructors": "4b716892-63d8-47cf-815c-19d6f3bca83f",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   }
// ]



// [
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T04:00:00.000Z",
//     "endDate": "2024-06-24T04:30:00.000Z",
//     "Instructors": "4b716892-63d8-47cf-815c-19d6f3bca83f",
//     "ClassType": "Driving",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   },
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T05:00:00.000Z",
//     "endDate": "2024-06-24T05:30:00.000Z",
//     "Instructors": "d0b3c6c1-2114-4c4a-a36c-37207abf5ae8",
//     "ClassType": "Online",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   },
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T06:00:00.000Z",
//     "endDate": "2024-06-24T06:30:00.000Z",
//     "Instructors": "e57d1ea5-8bef-4fb1-a484-39beac9ceec5",
//     "ClassType": "InClass",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   }
// ]