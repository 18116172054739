import React from "react";
import "./clientInfo.css";
import { BaseUrl } from "../../../../Actions/Base";

const ArrangeHrs = (Hrs) => {
    if (Hrs) {
        let SHH = Hrs.Start.hh;
        let SMM = Hrs.Start.mm;
        let SAM = Hrs.Start.AM ? "AM" : "PM";

        let EHH = Hrs.End.hh;
        let EMM = Hrs.End.mm;
        let EAM = Hrs.End.AM ? "AM" : "PM";

        if (Hrs.Start.hh < 10) SHH = "0" + Hrs.Start.hh;
        if (Hrs.Start.mm < 10) SMM = "0" + Hrs.Start.mm;
        if (Hrs.End.hh < 10) EHH = "0" + Hrs.End.hh;
        if (Hrs.End.mm < 10) EMM = "0" + Hrs.End.mm;

        return (
            SHH + ":" + SMM + " " + SAM + "  to  " + EHH + ":" + EMM + " " + EAM
        );
    }
};
const ClientInfo = ({ Student, StudentInfo }) => {
    const studentImage = `${BaseUrl}/api/images/Student?url=${Student?.StudentImage}`;
   
    return (
        <div className="flex w-full flex-col justify-between lg:flex-row">
            <div className="flex w-full flex-col items-center justify-center gap-4 lg:w-[45%] lg:items-start ">
                <img
                    className="aspect-[59_/_74] w-40 sm:w-44 md:w-48 lg:w-52 xl:w-56 2xl:w-60 rounded-md "
                    src={studentImage}
                    alt=""
                />
                <InfoComp Heading="Email Address" Value={Student?.Email} />
                <InfoComp
                    Heading="Date of Birth"
                    // Value={StudentInfo?.DOB}
                    Value={new Date(StudentInfo?.DOB).toLocaleDateString()}
                    Src={
                        require("../../../../Components/InputComps/Assets/BirtPlaceIcon.svg")
                            .default
                    }
                />
            </div>
            <div className="flex w-full flex-col  justify-end gap-[17px] lg:w-[45%] mt-3 lg:mt-0">
                <InfoComp
                    Heading="Name"
                    Value={Student?.FirstName + " " + Student?.LastName}
                />
                <InfoComp
                    Heading="Address"
                    Value={StudentInfo?.Address}
                />
                <InfoComp
                    Heading="Phone Number"
                    Value={
                        Student?.PhoneNumber
                            ? Student?.PhoneNumber
                            : "Not mentioned"
                    }
                />
                <InfoComp
                    Heading="Gender"
                    Value={StudentInfo?.Gender}
                />
                {/* <InfoComp
                    Heading="Free Hours"
                    Value={ArrangeHrs(Student?.StudentInfo?.FreeHours?.[0])}
                /> */}
               
            </div>
        </div>
    );
};
const InfoComp = ({ Heading, Value, Src }) => {
    return (
        <div className="relative flex w-full max-w-[600px] flex-col gap-1">
            <h3 className="text-3xs font-normal text-[#404040] 2xl:text-2xs ">
                {Heading}{" "}
            </h3>
            <span className="flex items-center justify-between rounded-xl bg-white px-5 py-3 shadow-[0px_3px_6px_#00000029]">
                <h4 className="text-4xs font-normal 2xl:text-3xs">
                    {Value}
                </h4>
                {Src ? (
                    <img
                        src={Src}
                        className="absolute right-5 h-6 sm:h-[26px] md:h-7 lg:h-8"
                        alt=""
                    />
                ) : null}
            </span>
        </div>
    );
};
export default ClientInfo;
