import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/Footer";
import ReCAPTCHA from "react-google-recaptcha-v2";
import "./ContactUs.css";
import axios from "axios";
import { BaseUrl } from "../../Actions/Base";
import SuccessMessage from "../../Components/SuccessAndErrorMessage/SuccessMessage";

const axiosInstance = axios.create({
  baseURL: BaseUrl,
  withCredentials: true,
});
const ContactUs = () => {
  let InputBoxStyle = `w-full  shadow rounded-[2px] h-[49px] outline-none bg-[#F3F6F7] text-gray-400
    text-4xs md:text-[16px]   lg:text-[16px]     xl:[16px]      2xl:text-[21px]
    py-[6px]                    xl:py-3
    px-[8px]                    xl:px-[22px]    2xl:px-7`;

  const [formData, setFormData] = useState({
    fName: "",
    lName: "",
    emailAddress: "",
    phoneNumber: "",
    address: "",
    message: "",
  });
  const [captcha, setCaptcha] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [errors, setErrors] = useState({});
  const [isChecked, setIsChecked] = useState(false);

  // Handle checkbox state change
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    console.log("Checkbox is", event.target.checked ? "checked" : "unchecked");
    console.log("Checkbox value:", event.target.value);
  };

  console.log("isChecked", isChecked)

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => setSuccessMessage(false), 4000);

      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.fName) newErrors.fName = "First Name is required";
    if (!formData.emailAddress)
      newErrors.emailAddress = "Email Address is required";
    if (!formData.message) newErrors.message = "Message is required";
    if (!captcha || captcha === "")
      newErrors.captcha =
        "Please complete the security check by selecting the CAPTCHA.";

    if(!isChecked) newErrors.checked = "Please select the consent check box."

    return newErrors;
  };
  function captchaChange(value) {
    setErrors({});
    setCaptcha(value);
  }
  const handleChange = (e) => {
    setErrors({});
    const { id, value } = e.target;

    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleSubmitt = async (e) => {
    e.preventDefault();
    let newErrors = validateForm();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const payload = {
      Data: formData,
    };
    const formDataa = new FormData();
    formDataa.append("paylaod", payload);
    console.log("formData payload", formDataa);
    try {
      const response = await axiosInstance.post("/api/contact-us", formData);
      setSuccessMessage(true);
      // Clear form and errors
      setFormData({
        fName: "",
        lName: "",
        emailAddress: "",
        phoneNumber: "",
        address: "",
        message: "",
      });
      setErrors({});
    } catch (error) {
      if (error.response && error.response.data.errors) {
        const backendErrors = error.response.data.errors.reduce(
          (acc, error) => {
            acc[error.param] = error.msg;
            return acc;
          },
          {}
        );
        setErrors(backendErrors);
      } else {
        console.error("Error submitting form:", error);
        alert("Error submitting form");
      }
    }
  };

  console.log("errors out", errors);
  console.log("captcha out", captcha);

  return (
    <div className="">
      <div className="bgGradient absolute -z-10 h-[745px] w-full rounded-b-[300px]"></div>
      <div className="relative mb-40 flex   w-full flex-col items-center md:mb-10 md:block">
        <picture className="-z-10 hidden overflow-hidden md:block">
          <img
            className="LandingContainerLite relative -top-5 -left-28"
            onLoad={() => console.log("load")}
            onError={(e) => console.log("error", e)}
            src={require("./Assets/LiteLandingContainer.svg").default}
            alt=""
          />
        </picture>

        <div className="h- -top-0 w-full rounded-b-[300px] md:absolute md:h-auto md:min-h-fit md:rounded-[0px] md:bg-[#291c5f00] xl:w-full ">
          <picture className=" ">
            <source
              media="(max-width:635px)"
              className=""
              srcSet={require("./Assets/Rectangle.svg").default}
            />

            <source
              media="(min-width:768px)"
              srcSet={require("./Assets/LandingContainer.svg").default}
            />
            <img
              className="hidden w-full md:inline-block"
              loading="lazy"
              src={require("./Assets/LandingContainer.svg").default}
              alt=""
            />
          </picture>
        </div>

        <div className="top-32  left-0 mt-[150px] flex w-full flex-col items-center justify-around md:absolute md:top-[5rem] md:mt-0 md:flex-row">
          <div className=" mx-1 flex w-[80%]  flex-col gap-10 sm:-top-0 md:relative md:left-10 md:z-10 md:m-0  md:w-[65%] md:gap-1 md:px-10   md:pb-4 lg:w-[55%] lg:gap-10 lg:pb-4 xl:w-[50%]  xl:pb-1 ">
            <h2 className="LandingPagetext text-pretty text-center   text-2xs text-white  md:w-auto md:text-left xl:text-sm  2xl:text-lg ">
              Contact Us
            </h2>

            <p className="Regularfont text-pretty text-center   text-4xs font-thin text-white md:text-left xl:text-2xs   2xl:text-sm ">
              You are at the right place to explore various options at your
              fingertips. Navigate below to discover the new world of vehicle
              learning experience.
            </p>
          </div>

          <div className="flex w-[70%] items-center justify-center md:w-[35%] lg:w-[45%] xl:w-[50%]">
            <img
              className=" relative mt-4 w-full md:mt-0 lg:top-[3.5rem] lg:right-[2rem]"
              src={require("./Assets/LandingPageCar.png")}
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="form-section mt-[14rem] md:mt-[3rem]">
        <form
          onSubmit={handleSubmitt}
          className="flex flex-col items-center justify-center gap-8"
          action=""
        >
          <div className="flex w-[80%] flex-col gap-8 sm:w-[70%] xl:max-w-[800px] 3xl:max-w-[1100px]">
            <div className="flex w-full flex-col gap-8 md:flex-row">
              <div className="w-full md:basis-[50%]">
                <Input
                  Id="fName"
                  InputBoxStyle={InputBoxStyle}
                  Type="text"
                  Placeholder="First Name*"
                  Required={true}
                  Value={formData.fName}
                  OnChange={handleChange}
                  Errors={errors.fName}
                />
              </div>
              <div className="w-full md:basis-[50%]">
                <Input
                  Id="lName"
                  InputBoxStyle={InputBoxStyle}
                  Type="text"
                  Placeholder="Last Name"
                  Required={false}
                  Value={formData.lName}
                  OnChange={handleChange}
                />
              </div>
            </div>

            <div className="flex w-full flex-col gap-8 md:flex-row">
              <div className="w-full md:basis-[50%]">
                <Input
                  Id="emailAddress"
                  InputBoxStyle={InputBoxStyle}
                  Type="email"
                  Placeholder="Email Address*"
                  Required={true}
                  Value={formData.emailAddress}
                  OnChange={handleChange}
                  Errors={errors.emailAddress}
                />
              </div>
              <div className="w-full md:basis-[50%]">
                <Input
                  Id="phoneNumber"
                  InputBoxStyle={InputBoxStyle}
                  Type="number"
                  Placeholder="Phone Number"
                  Required={false}
                  Value={formData.phoneNumber}
                  OnChange={handleChange}
                />
              </div>
            </div>

            <div className=" w-full ">
              <Input
                Id="address"
                InputBoxStyle={InputBoxStyle}
                Type="text"
                Placeholder="Address"
                Required={false}
                Value={formData.address}
                OnChange={handleChange}
              />
            </div>

            <div className=" w-full ">
              <textarea
                className="w-full rounded-[4px] bg-[#F3F6F7] p-4  text-6xs   shadow outline-none md:text-[16px]"
                name="message"
                id="message"
                placeholder="Enter message*"
                rows={6}
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
              {errors.message && (
                <p className="text-[#bc3131]">{errors.message}</p>
              )}
            </div>

            <div>
              <div class=" flex items-center">
              
                <input
                  id="default-checkbox" 
                  type="checkbox" 
                  value="agree" 
                  checked={isChecked} 
                  onChange={handleCheckboxChange} 
                  className="text-blue-600 focus:ring-blue-500 h-6 w-6 rounded border-gray-300 bg-gray-100   focus:ring-2"
                />
                <label
                  for="default-checkbox"
                  className="ml-2  text-4xs "
                >
                  I consent that VLI can communicate with me/organization via email and phone
                </label>
               
                
              </div>
              {errors.checked ? (
                  <h4 className="mt-1 font-normal text-[#bc3131]">
              
                    {errors.checked}
                  </h4>
                ) : null}
            </div>

            <div className="w-full">
              <div className="w-full">
                {errors.captcha ? (
                  <h4 className="font-normal text-[#bc3131]">
                    {" "}
                    {errors.captcha}{" "}
                  </h4>
                ) : null}
                <ReCAPTCHA
                  sitekey="6Lecb_IoAAAAANjflXMmXPZiBMaU0clxSmxPG9f9"
                  onChange={captchaChange}
                />
              </div>
            </div>
            {successMessage && (
              <div className="flex w-full justify-center">
                <div className="w-[80%]">
                  <SuccessMessage text={"Message submitted successfully"} />
                </div>
              </div>
            )}
            <div className="w-full">
              <button
                type="submit"
                class="focus:ring-blue-300 me-2 mb-2 rounded bg-violet-100 px-8 py-3 text-2xs font-medium text-white hover:bg-[#9093ea] focus:outline-none   focus:ring-4 "
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>

      <Footer contactUs={true} FooterBgColor="#F1FAFF" />
    </div>
  );
};

const Input = ({
  Id,
  InputBoxStyle,
  Type,
  Placeholder,
  Required,
  Value,
  Errors,
  OnChange,
}) => {
  return (
    <>
      <input
        id={Id}
        className={`${InputBoxStyle} border-0`}
        type={Type}
        placeholder={Placeholder}
        required={Required}
        value={Value}
        onChange={OnChange}
      />
      {Errors && <p className="text-[#bc3131]">{Errors}</p>}
    </>
  );
};

export default ContactUs;
