import axios from "axios";
import UserInfo from "../../../../UserInfo/UserInfo";

export const CreateOrder = async (Package, Installment) => {
    let PayPrice;
    if (Installment === "Installments")
        PayPrice = (Package?.TotalFee / Package?.Installments).toFixed(2);
    else if (Installment === "Full") PayPrice = Package?.TotalFee;

    const { data } = await axios.post("/api/orders", {
        CoursePackageId: Package?.CoursePackageId,
        TotalPrice: PayPrice,
        Currency: "USD",
    });
    console.log("createorder", data);
    return data.id;
};

export const ApproveOrder = async (
    OrderId,
    Package,
    Installment,
    EnrollmentData,
    handleEnrollmentSuccess,
    StudentImage,
    UserInformation,
    SetLocalStorage,
    dispatch,
    LatestLicense
) => {
    let PayPrice, InstallmentNumber;
    if (Installment) {
        PayPrice = (Package?.TotalFee / Package?.Installments).toFixed(2);
        InstallmentNumber = 1;
    } else {
        PayPrice = Package?.TotalFee;
        InstallmentNumber = Package.Installments;
    }
    console.log("Studnetimageeee", StudentImage);
    const formData = new FormData();
    // Append the image data to the FormData object
    formData.append("StudentImage", StudentImage);
    formData.append("LatestLicense", LatestLicense);
    formData.append("CoursePackageId", Package.CoursePackageId);
    formData.append("TotalPrice", PayPrice);
    formData.append("Installments", InstallmentNumber);
    formData.append("EnrollmentData", JSON.stringify(EnrollmentData));
    console.log("formData", formData);

    try {
       const res = await axios.post(
            `/api/orders/${OrderId}/capture`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        if(res?.status === 200) {
            handleEnrollmentSuccess()
            // DeleteLocalStorage("UserInfo")
            dispatch({
                type: "EnrollmentSuccess",
                payload: res?.data?.NewUserData,
                Auth: false,
            });
            SetLocalStorage("UserInfo", {
                ...UserInformation,
                ...res?.data?.NewUserData
            });
            
        }
        
    } catch (error) {
        console.error("Error:", error);
    }
};
