import React from "react";
import TimeRangePicker from "./TimeRangPicker";
// import TimeRangePicker from './TimeRangePicker';

const WeekTimeTableNew = ({ schedule, onChange, handleAddHour }) => {
  // Dynamically calculate the number of periods (hours)
  const numberOfPeriods = schedule[0].timetable.length; // Assuming all days have the same timetable length

  // Dynamically generate the grid column class based on the number of periods
  const gridColsClass = `grid-cols-${numberOfPeriods + 1}`; // Adding 1 for the day name column

  return (
    <div className="bg-mediumWhite p-4">
      <div  className="grid gap-2"
      style={{
          gridTemplateColumns: `repeat(${numberOfPeriods + 1}, minmax(0, 1fr))`, // +1 for the day column
        }}>
        <div></div>
        {schedule[0].timetable.map((_, index) => (
          <div
            key={index}
            className="flex w-40 items-center py-2 px-4 text-center font-bold shadow-md"
          >
            <p> Class {index + 1}</p>
          </div>
        ))}
        {schedule.map((day, dayIndex) => (
          <React.Fragment key={day.day}>
            <div className="row-span-1 flex w-28 items-center justify-center py-2 px-4 font-bold shadow-md">
              <p>{day.day}</p>
            </div>
            {day.timetable.map((period, periodIndex) => {
           
              return (
                <div key={period.period}>
                    {period.start ?  <TimeRangePicker
                    value={{ start: period.start, end: period.end }}
                    onChange={(newTimeRange) =>
                      onChange(dayIndex, periodIndex, newTimeRange)
                    }
                  />:<p>No Class</p>}
                
                </div>
              );
            })}
          </React.Fragment>
        ))}
        {/* <button
              sx={{ textTransform: "capitalize" }}
              className="bg-primary px-10"
              variant="contained"
              onClick={handleAddHour}
            >
              Add Hour
        </button> */}
      </div>
    </div>
  );
};

export default WeekTimeTableNew;
