// react States
import React, { useEffect, useState } from "react";

import RichTextEditor from "../../../Institute/AddVehicle/Components/VehicleInfoComps/RichTextEditor";

// components
import CourseSelctionNoteArea from "./components/CourseSelctionNoteArea.jsx";
import CreateCoursePackage from "./components/CreateCoursePackage.jsx";
import PackageInstallments from "./components/PackageInstallments.jsx";

// css
import "./coursePackaging.css";
import { PackagesDone } from "./Helpers/PackagesDone";

const CoursePackaging = ({
  Steps,
  setSteps,
  CourseData,
  setCourseData,
  StepsIndicator,
  PageNo,
  Err,
  setErr,
  OnClickBack,
  OnClickSteps,
  CourseInfo,
  update,
}) => {
  const [Packages, setPackages] = useState([]);

  useEffect(() => {
    setPackages(CourseInfo?.CoursePackages || []);
  }, [CourseInfo?.CoursePackages]);

  console.log("Packages", Packages);
  useEffect(() => {
    setCourseData({
      ...CourseData,
      Packages: Packages,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Packages]);

  const OnClick = (e) => PackagesDone(e, CourseData, setErr, Err, setSteps);

  const RichTextEditorStyle = {
    background:
      "transparent linear-gradient(107deg, #ffffff 0%, #f6f6ff 14%, #d6d8ff 100%) 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 30px 30px rgba(161, 146, 221, 0.4)",
  };
  // console.log(document.getElementsByClassName("grecaptcha-badge"))
  return (
    <>
      <div
        className={`addCoursesMainContainer ${
          Steps !== PageNo ? "hidden" : ""
        }`}
      >
        <div className="row addCourse1">
          <div className="col-12">
            <div className="mainContainerAddCourse">
              {/* <div className="flex w-[60%] items-center justify-center"> */}
              <StepsIndicator
                Steps={Steps}
                PageNo={PageNo}
                OnClickSteps={OnClickSteps}
              />
              {/* </div> */}

              <div className="mainContainer1White">
                {/* <CourseSelctionNoteArea /> */}
                <h2 className="text-[32px] text-[#4C34AD]">
                  Pricing & Packages
                </h2>

                <div className=" mt-10 flex flex-col   rounded-[10px] border border-solid border-[#D4D4D4] p-5">
                  <div className=" installmentsTxt">
                    <h2
                      className=" font-semibold text-[#4C34AD]
                                        "
                    >
                      Add Packages
                    </h2>
                    <p className="text-[20px]  text-[#4C34AD]">
                      You can create your own packages according to the training
                      hours
                    </p>
                  </div>
                  <div className="flex items-center justify-center">
                    <CreateCoursePackage
                      update={update}
                      CourseData={CourseData}
                      Err={Err}
                      setErr={setErr}
                      setPackages={setPackages}
                      Packages={Packages}
                    />
                  </div>
                </div>

                {/* <div className="installmantTable"> */}
                <div className=" mt-10 flex flex-col   rounded-[10px] border border-solid border-[#D4D4D4] p-5">
                  <div className=" installmentsTxt">
                    <h2 className=" font-semibold text-[#4C34AD]">
                      Installments
                    </h2>
                    <p className="text-[20px]  text-[#4C34AD]">
                      Create Frequency for each Installments
                    </p>
                  </div>
                  <div className="flex items-center justify-center">
                    <PackageInstallments
                      update={update}
                      setPackages={setPackages}
                      Packages={Packages}
                    />
                  </div>
                </div>

                {/* <div className="courseCVDiv gap-[15%]"> */}
                <div className=" mt-10 flex flex-col   rounded-[10px] border border-solid border-[#D4D4D4] p-5">
                  {/* <div className="courseCVDivHeading"> */}
                  <div className=" installmentsTxt">
                    <h2 className=" font-semibold text-[#4C34AD]">
                      Course Curriculum
                    </h2>
                    <p className="text-[20px] text-[#4C34AD]">
                      You can attach or write Curriculum for your Course here
                    </p>
                  </div>

                  <div
                    className="w- flex  items-center justify-center  "
                    // max-w-[675px]
                    id="CourseCurriculum"
                  >
                    <div
                      className="packageTable"
                      // style={RichTextEditorStyle}
                    >
                      <RichTextEditor
                        KeyName="CourseCurriculum"
                        setData={setCourseData}
                        readOnly={update}
                        Data={CourseData}
                        Errors={Err}
                        setErrors={setErr}
                        bg={RichTextEditorStyle}
                      />
                      <h3 className="text-3xs font-normal text-[red]">
                        {Err?.CourseCurriculum}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!update && (
              <div className="flex items-center justify-center gap-5">
                <button
                  className="SaveAndContinueBtn w-[12%]  cursor-pointer"
                  type="button"
                  onClick={() => OnClickBack(Steps)}
                >
                  Back
                </button>
                <button
                  className="SaveAndContinueBtn  cursor-pointer"
                  type="button"
                  onClick={OnClick}
                >
                  Save & Continue
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CoursePackaging;
