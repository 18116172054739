import React, { useState, useEffect } from 'react';
import TimeSlotSelector from './TimeSlotSelector';
import ModalWrapper from './ModalWrapper'; // Import ModalWrapper
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TimePicker from 'react-time-picker';

const ModalTimeSlots = ({ classHoursData, showOwnTimeFields, timetable, setClassHoursData, setShowOwnTimeFields, instructorTypeExtracted }) => {
    const Days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
    const [selectedDay, setSelectedDay] = useState('');
    const [showModalbtn, setShowModalbtn] = useState(false);


    // Function to add a new time slot for the selected day
    const handleAddTimeSlot = (day, timeSlot) => {
        if (timetable === 'assignschedule') {
            setClassHoursData(prevData => {
                const updatedData = {
                    ...prevData,
                    DayandHoursData: {
                        ...prevData.DayandHoursData,
                        Schedule: {
                            ...prevData.DayandHoursData.Schedule,
                            [day]: [...(prevData.DayandHoursData.Schedule[day] || []), timeSlot]
                        }
                    }
                };
                console.log('Updated Class Hours Data:', updatedData); // Log the updated data here
                return updatedData;
            });
        }
    };
    
    const handleSelectDay = (e) => {
        setSelectedDay(e.target.value);
    };

     // Log classHoursData whenever it changes
     useEffect(() => {
        console.log('Updated classHoursData:', classHoursData);
    }, [classHoursData]);

    // Initialize time states with defaults
    const [startTime, setStartTime] = useState('09:00'); // Default start time
    const [endTime, setEndTime] = useState('10:00');     // Default end time

    // Function to format time to AM/PM format
    const formatTimeAMPM = (time) => {
        const [hours, minutes] = time.split(':');
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    };

    // Handle adding a time slot
    const handleAddSlot = () => {
        setShowModalbtn(true)
        if (selectedDay && startTime && endTime) {
            const formattedStartTime = formatTimeAMPM(startTime);
            const formattedEndTime = formatTimeAMPM(endTime);
            // Pass the formatted time to the parent component
            handleAddTimeSlot(selectedDay, `${formattedStartTime} - ${formattedEndTime}`);
        }
    };

    // const renderTimeSlots = (day) => {
    //     if (classHoursData?.DayandHoursData?.Schedule?.[day]?.length > 0) {
    //         return classHoursData.DayandHoursData.Schedule[day].map((slot, index) => (
    //             <span key={index} className="text-[16px]">{slot}</span>
    //         ));
    //     }
    //     return null; // No slots to render
    // };
    

    const hasScheduleData = Object.values(classHoursData.DayandHoursData.Schedule).some(daySlots => daySlots.length > 0);

        // Function to clear schedule data
    const discardChanges = () => {
        setClassHoursData({
        DayandHoursData: {
            Schedule: {
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            },
        },
        });
    };


    // Function to remove a time slot when a checkbox is unchecked
    const handleRemoveTimeSlot = (day, slot) => {
        setClassHoursData(prevData => {
            const updatedData = {
                ...prevData,
                DayandHoursData: {
                    ...prevData.DayandHoursData,
                    Schedule: {
                        ...prevData.DayandHoursData.Schedule,
                        [day]: prevData.DayandHoursData.Schedule[day].filter(timeSlot => timeSlot !== slot)
                    }
                }
            };
            console.log('Updated Class Hours Data after removal:', updatedData);
            return updatedData;
        });
    };

    // Function to render time slots with checkboxes
    const renderTimeSlots = (day) => {
        if (classHoursData?.DayandHoursData?.Schedule?.[day]?.length > 0) {
            return classHoursData.DayandHoursData.Schedule[day].map((slot, index) => (
                <div key={index} className="flex items-center gap-[10px]">
                    <input
                        type="checkbox"
                        checked={true}
                        onChange={(e) => {
                            if (!e.target.checked) {
                                handleRemoveTimeSlot(day, slot); // Remove slot if unchecked
                            }
                        }}
                        className="w-[20px] h-[20px]"
                    />
                    <span className="text-[18px] text-[#404040]">{slot}</span>
                </div>
            ));
        }
        return null;
    };




    return (
        <ModalWrapper isOpen={showOwnTimeFields} onClose={() => {
            setShowOwnTimeFields(false); 
          }} showModalbtn={showModalbtn} hasScheduleData={hasScheduleData}>
          <p className='text-[26px] text-[#4C34AD] font-semibold'>Custom Time Schedule ({instructorTypeExtracted})</p>
          <div className='mt-[10px] w-full rounded-[12px]'
          style={{ border: '1px solid #B7B7B7' }}>
            <div 
             style={{ borderBottom: '1px solid #B7B7B7' }}>
                <div className='flex gap-[5px] px-[15px] pt-[15px] pb-[10px]'>
                <AccessTimeIcon />
                <p className='text-[19px] text-[#333333] font-semibold'>Date And Time</p>
                </div>
            </div>
            <div className='flex flex-col py-[15px] px-[20px] gap-[15px]'>
                <div className='flex items-center gap-[43px]'>
                    <p className='text-[19px] text-[#333333] font-semibold'>On</p>
                    <select
                    value={selectedDay}
                    onChange={handleSelectDay}
                    className="rounded-[13px] border-0" // Removes default border
                    style={{
                        width: '89%',
                        height: '50px', // Sets the height to 50px
                        border: '2px solid #D7D9FF', // Sets the border color and width
                        outline: 'none', // Optional: removes default outline on focus
                        padding: '0 10px', // Optional: adds horizontal padding for better appearance
                    }}
                >
                    <option value="">Select a day</option>
                    {Days.map((day) => (
                        <option key={day} value={day}>{day}</option>
                    ))}
                    </select>
                </div>

                <div className='flex items-center gap-[20px]'>
                    <p className='text-[19px] text-[#333333] font-semibold'>From</p>
                    <TimePicker
                        onChange={setStartTime}
                        value={startTime}
                        disableClock
                        clearIcon={null}
                        format="h:mm a"
                        className="custom-time-picker"
                    />
                </div>

                <div className='flex items-center gap-[45px]'>
                    <p className='text-[19px] text-[#333333] font-semibold'>To</p>
                    <TimePicker
                        onChange={setEndTime}
                        value={endTime}
                        disableClock
                        clearIcon={null}
                        format="h:mm a"
                        className="custom-time-picker"
                    />
                </div>

                <div className='mt-[10px] w-full flex justify-center items-center gap-[20px]'>
                <button
                type="button"
                className="rounded-[10px]  w-[121px]  h-[42px] text-[#4C34AD] text-[19px] "
                style={{ border: '2px solid #4C34AD' }}
                onClick={() => {
            setShowOwnTimeFields(false); 
          }}
                >
                    Cancel
                </button>

                <button type="button"
                className=" rounded-[10px] bg-[#4C34AD] w-[121px]  h-[42px] text-[19px] text-[#FFFFFF] "
                onClick={handleAddSlot}
                >
                    Add Class
                </button>
            </div>
            </div>
          </div>

          {hasScheduleData &&
          <div className='mt-[10px]'>
          <div className='w-full flex justify-end'>
                <button
                        type="button"
                        className="rounded-[10px]  w-[181px]  h-[42px] text-[#4C34AD] text-[19px] "
                        style={{ border: '2px solid #4C34AD' }}
                        onClick={discardChanges}  // Clear data on click
                        >
                    Discard Changes
                </button>
          </div>
                <h2 className="text-[22px] font-semibold mb-[10px]">Class Schedule</h2>
                 {/* Iterate through Days array and display only those with slots */}
                    {Days.map((day) => (
                        classHoursData?.DayandHoursData?.Schedule?.[day]?.length > 0 && (
                            <div className="flex gap-[10px] mb-[5px]" key={day}>
                                <div className="w-[100px]">
                                    <p className="text-[18px]">{day}</p>
                                </div>
                                <div className="flex flex-wrap gap-2">
                                    {/* Display slots with radio button icons */}
                                    {renderTimeSlots(day).map((slot, index) => (
                                    <div key={index} className="flex items-center gap-[5px]">
                                        {/* <span className="material-symbols-outlined text-[#A1A3EF]">
                                        radio_button_checked
                                        </span> */}
                                        <p className="text-[18px] text-[#404040]">{slot}</p>
                                    </div>
                                    ))}
                                </div>
                            </div>
                        )
                    ))}
          </div>
          }
            {/* <div className='timeslots'>
                <h2 style={{ textAlign: 'center' }}>Select a Day and Add Time Slots</h2>
                
                <select
                    value={selectedDay}
                    onChange={handleSelectDay}
                    style={{ marginBottom: '20px', padding: '10px', width: '100%' }}
                >
                    <option value="">Select a day</option>
                    {Days.map((day) => (
                        <option key={day} value={day}>{day}</option>
                    ))}
                </select>

                {selectedDay && (
                    <TimeSlotSelector day={selectedDay} onAddTimeSlot={handleAddTimeSlot} />
                )}

                <div style={{ marginTop: '20px' }}>
    <h3>Selected Schedule:</h3>
    {Object.entries(classHoursData.DayandHoursData.Schedule).map(([day, slots]) => (
        <div key={day} style={{ marginBottom: '10px' }}>
            <h4>{day}:</h4>
            {slots.length > 0 ? (
                <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
                    {slots.map((slot, index) => (
                        <li key={index} style={{ margin: '5px 0' }}>
                            {typeof slot === 'object' ? `${slot.start} - ${slot.end}` : slot}
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No slots selected</p>
            )}
        </div>
    ))}
</div>

            </div> */}
        </ModalWrapper>
    );
};

export default ModalTimeSlots;
