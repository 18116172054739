import { useState } from "react";
import InstituteTemplate from "../../Components/InstituteTemplate/InstituteTemplate";
import AddAward from "./Components/AddAward";
import AwardForm from "./Components/AwardForm";
import Awards from "./Components/Awards";
import { validateData } from "./Helpers/AwardsHelpers";
import { useDispatch } from "react-redux";
import { CreateAward } from "../../Actions/AwardA";
import { GetLocalStorage } from "../../Helpers/LocalStorage/LocalStorage";
import { json } from "react-router-dom";

const InstituteAwardsPage =()=> {
    const Dispatch = useDispatch()
    const [awardForm, setAwardForm] = useState(false)
    const [Err, setErr] = useState({})
    const [awardData, setAwardData] = useState({
        AwardThumbnail: "",
        Title: "",
        Description: "",
    });

    const submitData =()=> {
       const errors = validateData(awardData)
        console.log(awardData)
        if (Object.keys(errors).length > 0) {
            setErr(errors);
        } else {
            const formData = new FormData()
            const newAwardData = {...awardData, InstituteFK:GetLocalStorage("UserInfo").Institute.InstituteId}
            formData.append("AwardThumbnail", awardData.AwardThumbnail)
            delete newAwardData.AwardThumbnail
            formData.append("awardData", JSON.stringify(newAwardData))
            Dispatch(CreateAward(formData))
            console.log("Submitting data:", awardData);
            console.log("Submitting newAwardData:", newAwardData);
            // For example, you might want to clear the errors
            setErr({});
        }
    }
    console.log("Err", Err)
    return(
        <>
        {
           awardForm ? <AwardForm  submitData={submitData} setAwardForm={setAwardForm} Err={Err} setErr={setErr} awardData={awardData} setAwardData={setAwardData} /> : null
        }
         <Awards />
        <AddAward setAwardForm={setAwardForm}  />
        </>
    )
}

const InstituteAwards = () => (
    <InstituteTemplate Element={InstituteAwardsPage} />
);


export default InstituteAwards;