
import "devextreme/dist/css/dx.light.css";
import { Editing, Resource, Scheduler, View } from "devextreme-react/scheduler";
import { useAuthContext } from "../../../context/authContext";
import { useState, useEffect } from "react";
import { ChangeScheduleStatus } from "../../../Actions/StudentA";
import { useDispatch, useSelector } from "react-redux";


function DevExtremeScheduler({ Events, setEvents, Edit, Resources, studentSchedule, setPrefrredTimeTable, 
  PrefrredTimeTable, updatePrefrredTimeTable, ClassType, setNewUpdatedEvents }) {
  console.log("student schedule in timetable is:",Events)
  console.log("setPrefrredTimeTable schedule in timetable is:",PrefrredTimeTable)
  console.log("Resources in the assign schedule timetable is:", Resources)
  console.log("ClassType in the assign schedule timetable is:", ClassType)
  const [showPopup, setShowPopup] = useState(false);
  const [appointmentId, setAppointmentId] = useState();


  const dispatch = useDispatch();
  const { statusChangeSuccess } = useSelector(
    (Store) => Store.StudentReducer
  );// Adjust path as necessary

   // New useEffect to update setPrefrredTimeTable whenever Events changes
   /* useEffect(() => {
    if (JSON.stringify(PrefrredTimeTable) !== JSON.stringify(Events)) {
      setPrefrredTimeTable([...Events]);
    }
  }, [Events]); */

  useEffect(() => {
    const filteredEvents = Events.filter(event => !event.EventId); // Only keep events without event_id
    if (JSON.stringify(PrefrredTimeTable) !== JSON.stringify(filteredEvents)) {
      setPrefrredTimeTable([...filteredEvents]);
    }
  }, [Events]);

  // Extract the instructor data and format it for the Resource
  const formattedInstructors = Resources?.[0]?.dataSource?.map((val) => {
    return {
      id: val.user?.Instructor?.InstructorId, // or use another unique identifier
      text: `${val.user?.FirstName} ${val.user?.LastName}` // Combine FirstName and LastName
    };
  });

  console.log("Formatted Instructors:", formattedInstructors);
  

  async function getInstructorType(id) {
    if (!id) {
      console.warn("Invalid ID passed to getInstructorType:", id);
      return null;
    }
    
    // Iterate through all dataSource arrays in Resources instead of just checking the first record
    for (let resource of Resources) {
      if (resource.fieldExpr === "Instructors" && Array.isArray(resource.dataSource)) {
        const instructor = resource.dataSource.find((val) => val.InstructorFK === id);
  
        if (instructor) {
          console.log("Instructor found:", instructor);
          return instructor.InstructorType; // Or return the instructor object if needed
        }
      }
    }
  
    console.warn("No instructor found for ID:", id);
    return null;
  }
  
  const {setLastFiveValues, lastFiveValues} = useAuthContext()
  console.log("resources", Resources)
  const neww = Resources?.[0].dataSource.map((val)=> console.log("val", val))
  console.log("neww",neww)


  const renderTimeCell = (data) => (
    <i style={{ color: "green" }}>{data?.text}</i>
  );

  const OnUpdate = (e) => {
    if(studentSchedule === 'lastFiveValues') {
      const lastFive = Events.slice(-5);
      setLastFiveValues(lastFive);
    }

  };
  // console.log("lastFiveValues",lastFiveValues)
  

  const onAppointmentDeleting = async (e) => {
    const appointmentData = e.appointmentData;
    console.log("Canceling appointment:", appointmentData);
    console.log("Event Id is:", appointmentData.EventId);
    setAppointmentId(appointmentData.EventId)
    // Prevent deletion
    e.cancel = true;

    setShowPopup(true);

    // await dispatch(ChangeScheduleStatus(appointmentData.EventId, "Cancelled"));
    // window.location.reload();
   
};


  const onAppointmentDeleted = (e) => {
    // let Index = Events.indexOf(e?.appointmentData);
    // let NewEvents = Events.splice(Index, 1)
    console.log("delete before setting")
    setEvents([...Events]);
    setPrefrredTimeTable([...Events])
    console.log("delete after setting")
  };
  const onAppointmentAdding = (e) => {
  };

  const onAppointmentAdded = (e) => {
    const { appointmentData } = e;
    console.log("appoin", appointmentData)

    const type = getInstructorType(appointmentData.Instructors)

    // Extract EnrollmentFK from an existing event (assuming Events contains the list of events)
    const existingEnrollmentFK = Events[0]?.EnrollmentFK;
    console.log("existingEnrollmentFK are:",existingEnrollmentFK)

    // Ensure new appointment has the same EnrollmentFK
    const updatedAppointment = {
      ...appointmentData,
      EnrollmentFK: existingEnrollmentFK,
    };

    // console.log("appointmentData", appointmentData)
    const NewEvents = Events.map((value) => {
      if (
        value.endDate === appointmentData.endDate &&
        value.startDate === appointmentData.startDate &&
        value.startDate === appointmentData.startDate &&
        value.description === appointmentData.description &&
        value.text === appointmentData.text
      ) {
        value.InstructorFK = appointmentData.Instructors;
        value.EnrollmentFK = existingEnrollmentFK;
        // value.ClassType = appointmentData.ClassType;
        value.ClassType =  appointmentData.ClassType
      }
      return value;
    });

    const updatedEvents = [...NewEvents, updatedAppointment];
    console.log("updatedEvents are:",updatedEvents)
    console.log("updatedAppointment are:",updatedAppointment)


    console.log("before setting")
    setEvents([...NewEvents]);
    setPrefrredTimeTable([...NewEvents]);
    // If `NewUpdatedEvents` already contains appointments, add the new one
    setNewUpdatedEvents((prevEvents) => {
      return prevEvents.length > 0 ? [...prevEvents, updatedAppointment] : [updatedAppointment];
    });
    console.log("After setting")
    
  };

  console.log("Events", Events)
  console.log("PrefrredTimeTable", PrefrredTimeTable)



  const customizeAppointment = (e) => {
    // Customize the color based on conditions
    let appointmentColor = "#3377b2";
    let appointmentText = "";
    // console.log('eeee', e.appointmentData)
   
    if (e?.appointmentData?.classStatus === "Missed") {
      appointmentColor = "#FF4500";
      appointmentText = "Missed";
    } else if (e?.appointmentData?.classStatus === "Cancelled") {
      appointmentColor = "#d9534f";
      appointmentText = "Cancelled";
    } else if (e?.appointmentData?.classStatus === "Rescheduled") {
      appointmentText = "Rescheduled";
      // Check ClassType when classStatus is Rescheduled
        if (e?.appointmentData?.ClassType === "Driving") {
          appointmentColor = "#a1a3ef";  // Driving color
        } else if (e?.appointmentData?.ClassType === "Online") {
          appointmentColor = "#7f82f9";  // Online color
        } else if (e?.appointmentData?.ClassType === "InClass") {
          appointmentColor = "#5456c7";  // InClass color
        }
    } else if (e?.appointmentData?.CourseEnrollment?.Status === "Hold") {
      appointmentColor = "#d9534f";
    } else if (e?.appointmentData?.ClassType === "Online") {
      appointmentColor = "#7f82f9";
    } else if (e?.appointmentData?.ClassType === "InClass") {
      appointmentColor = "#5456c7";
    }else if (e?.appointmentData?.ClassType === "Driving") {
      appointmentColor = "#a1a3ef";
    } 
    
    return (
      <div
        className=""
        id="newDiv"
        style={{
          backgroundColor: appointmentColor,
          border: "0",
          margin: "0",
          width: "calc(100% + 10px)", // Adjust for left and right padding (e.g., 5px on each side)
          height: "calc(100% + 10px)",
        }}
      >
        <div className="flex flex-col h-full w-full justify-center">
          <p className="mb-2 mr-2 text-[16px] text-center">
            {appointmentText || e?.appointmentData?.CourseEnrollment?.User?.FirstName}
          </p>
          {/* <p className="text-[12px] mr-2 "
          >
           {e?.appointmentData?.ClassFeedback}
          </p> */}
        </div>
      </div>
    );
  };

  return (
    <>

      {showPopup && (
        <UpdateClassStatus
          setShowPopup={setShowPopup}
          title={"Class Status Update"}
          description={"Please update your feedback for this class"}
          MissedButton={"Missed"}
          CancelledButton={"Cancelled"}
          EventId={appointmentId}
        />
      )}
      <Scheduler
        id="scheduler"
        dataSource={Events}
        currentView="week"
        views={["week", "month"]}
        startDayHour={9}
        endDayHour={19}
        onAppointmentUpdated={OnUpdate}
        // onAppointmentDeleted={onAppointmentDeleted}
        onAppointmentDeleting={onAppointmentDeleting}
        onAppointmentAdding={onAppointmentAdding}
        onAppointmentAdded={onAppointmentAdded}
        showCurrentTimeIndicator={true}
        shadeUntilCurrentTime={true}
        appointmentRender={customizeAppointment}
      >
        <Editing allowDeleting={Edit} allowAdding={Edit} allowUpdating={Edit} />
        <View
          type="week"
          cellDuration={45}
          dateCellRender={renderTimeCell}
        />
        {/* {Resources?.length > 0
          ? Resources.map((value) => (
              <Resource
                key={value.fieldExpr}
                fieldExpr={value.fieldExpr}
                dataSource={value.dataSource}
              />
            ))
          : null} */}
          {formattedInstructors?.length > 0 && (
          <Resource
            fieldExpr="Instructors"
            dataSource={formattedInstructors}
            label="Instructor"
          />
        )}

        {/* Render ClassType resource */}
   {/* Render ClassType Resource */}
   {ClassType?.length > 0 && (
    <Resource
      key={ClassType[0].fieldExpr}  // Ensuring a unique key
      fieldExpr={ClassType[0].fieldExpr}  // The "ClassType" field in Events
      dataSource={ClassType[0].dataSource}  // The array of class types
      label="Class Type"  // Label for the resource
    />
  )}

      </Scheduler>
    </>
  );
}

export default DevExtremeScheduler;

const UpdateClassStatus = ({
  setShowPopup,
  title,
  MissedButton,
  CancelledButton,
  description,
  EventId
}) => {
  const [ClassFeedback, setClassFeedback] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);

  const handleButtonClick = async (Status) =>{
    await dispatch(ChangeScheduleStatus(EventId, Status, ClassFeedback));
    window.location.reload();
  }

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-md transition duration-500">
      <div className="relative rounded-[23px] bg-white p-8 shadow-lg">
        <div className="absolute top-2 right-2">
          <img
            className="absolute right-3 top-[6px] cursor-pointer"
            src={require("./Assets/Cross.svg").default}
            alt=""
            onClick={() => setShowPopup(false)}
          />
        </div>
        <h2
          // style={{ WebkitTextStroke: "1px #404040" }}
          className=" mb-4 text-center text-[16px]"
        >
          {title}
        </h2>
        <p className="text-center text-[14px]">{description}</p>
        <div className="text-center">
          <img
            src={require("./Assets/update-class.png")}
            alt="feedback"
            className="mb-4 "
          />
        </div>

        <textarea
          className=" mb-4 h-24 w-full resize-none rounded-md border border-gray-300 p-2 focus:outline-none "
          placeholder="Add your feedback here..."
          value={ClassFeedback}
          onChange={(e) => setClassFeedback(e.target.value)}
          // onChange={(e) => setData({ ...data, Feedback: e.target.value })}
        />

        <div className="flex items-center justify-center gap-3">
          <button
            onClick={() => handleButtonClick("Missed")}
            className="border-1 cursor-pointer rounded-[7px] border-solid border-[#4C34AD] bg-white px-8 py-2 font-sans text-xs text-[#4C34AD] transition duration-500 hover:bg-[#4C34AD] hover:text-white  "
          >
            {MissedButton}
          </button>
          <button
            onClick={() => handleButtonClick("Cancelled")}
            className="border-1 cursor-pointer rounded-[7px] border-solid border-[#4C34AD] bg-white px-5 py-2 font-sans text-xs text-[#4C34AD] transition duration-500 hover:bg-[#4C34AD] hover:text-white  "
          >
            {CancelledButton}
          </button>
        </div>
        
      </div>
    </div>
  );
};

// [
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T04:00:00.000Z",
//     "endDate": "2024-06-24T04:30:00.000Z",
//     "Instructors": "66a862b2-86a2-4d5c-9353-f5709962e364",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   },
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T05:30:00.000Z",
//     "endDate": "2024-06-24T06:00:00.000Z",
//     "Instructors": "d0b3c6c1-2114-4c4a-a36c-37207abf5ae8",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   },
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T06:00:00.000Z",
//     "endDate": "2024-06-24T06:30:00.000Z",
//     "Instructors": "d0b3c6c1-2114-4c4a-a36c-37207abf5ae8",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   },
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T06:30:00.000Z",
//     "endDate": "2024-06-24T07:00:00.000Z",
//     "Instructors": "4b716892-63d8-47cf-815c-19d6f3bca83f",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   }
// ]



// [
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T04:00:00.000Z",
//     "endDate": "2024-06-24T04:30:00.000Z",
//     "Instructors": "4b716892-63d8-47cf-815c-19d6f3bca83f",
//     "ClassType": "Driving",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   },
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T05:00:00.000Z",
//     "endDate": "2024-06-24T05:30:00.000Z",
//     "Instructors": "d0b3c6c1-2114-4c4a-a36c-37207abf5ae8",
//     "ClassType": "Online",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   },
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T06:00:00.000Z",
//     "endDate": "2024-06-24T06:30:00.000Z",
//     "Instructors": "e57d1ea5-8bef-4fb1-a484-39beac9ceec5",
//     "ClassType": "InClass",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   }
// ]