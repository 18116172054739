import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetLicenseTypes } from "../../../Actions/CategoryA";
import { BaseUrl } from "../../../Actions/Base";
import { useState } from "react";
import { Link } from "react-router-dom";
import a from "../Assets/Arrow Icon.svg";
import CoursesSlider from "../../../Components/CourseSlider/CourseSlider";

let ClassNames = `relative   duration-300 flex items-start my-2
w-[95%]                             lg:w-11/12                  xl:w-[97%]       
flex-nowrap     md:flex-wrap  
justify-start   md:justify-around   lg:justify-center
gap-7                               lg:gap-x-8 lg:gap-y-16
`;
function LicenseTypesGetReady() {
    const Dispatch = useDispatch();
    const [LicenseTypesState, setLicenseTypesState] = useState([]);
    const { LicenseTypes } = useSelector((state) => state.LicenseTypeReducer);

    useEffect(() => {
        Dispatch(GetLicenseTypes());
    }, [Dispatch]);
    useEffect(() => {
        setLicenseTypesState(LicenseTypes);
    }, [LicenseTypes]);
    return (
        <div
            id="LicenseTypes"
            className="relative flex w-full flex-col items-center gap-9 overflow-hidden  lg:gap-3 "
        >
            <img
                loading="lazy"
                className=" absolute h-full   w-full lg:inline"
                src={require("../Assets/tilebg.png")}
                alt=""
            />
            <img
                loading="lazy"
                className=" absolute h-full   w-full lg:inline lg:hidden"
                src={require("../Assets/Rectangle 23781.png")}
                alt=""
            />
            <h2 className="SemiBoldItalic z-10 mt-5 px-5 text-white lg:text-[38px]">
                {" "}
                Select License Type You Want To Study For
            </h2>
            <p className="z-10 px-5  text-center text-xs font-normal  text-white">
                Choose the License Class You Wish to Prepare For and Access
                Materials Specifically Tailored to That Category.
            </p>
            <div className={`relative w-full`}>
                {/* <img
                    className="absolute inset-0   w-full lg:inline"
                    src={require("../Assets/tilebg.png")}
                    alt=""
                /> */}

                <CoursesSlider
                    CoursesInfo={LicenseTypesState}
                    ClassNames={ClassNames}
                    CourseTiles={LicenseTypeTiles}
                    ShowMore={false}
                    NavigateBtnClass="G1Licensing"
                    SwitchSize={"md"}
                    ExtraMove={28}
                />
            </div>
        </div>
    );
}

export default LicenseTypesGetReady;
function LicenseTypeTiles({ CoursesInfo, setref }) {
    return CoursesInfo?.map((value) => {
        let LicenseTypeDescription = value.LicenseTypeDescription + ".";
        let words = value.LicenseTypeDescription.split(/[-\s(]/);

        if (words.length > 15)
            LicenseTypeDescription =
                value.LicenseTypeDescription.split(" ").slice(0, 15).join(" ") +
                " ...";

        let LicenseTypeName = value.LicenseTypeName;
        if (value?.LicenseTypeName !== "Industrial Machinery")
            LicenseTypeName = value.LicenseTypeName + " Licensing";

        let HoverStyle =
            "hover:origin-top-left hover:gap-10 hover:md:gap-6 hover:scale-100 md:hover:scale-[1.04] hover:bg-[#ABACEF]";
        let TransitionDuration = "duration-[400ms]";

        if (LicenseTypeName !== "Industrial Machinery")
            LicenseTypeName = `Class ${LicenseTypeName}`;
        return (
            <Link
                key={value.LicenseTypeId}
                // to={`/license/category/${value.LicenseTypeId}`}
                to={`/get-ready/knowledge-test/license-type/${value?.LicenseTypeId}`}
                ref={(e) => {
                    setref(e);
                }}
                className={`relative flex w-[60%] min-w-[220px] max-w-[320px] cursor-pointer flex-col
                items-center gap-7 rounded-2xl bg-white text-black no-underline duration-[400ms] ${HoverStyle} ${TransitionDuration}

                hover-on-child                       LicenseShadow 
                h-[324px] lg:h-[480px]`}
            >
                <div className="flex h-36 w-full items-center justify-center lg:h-56">
                    <img
                        id="LicenseTypeImage"
                        loading="lazy"
                        src={`${BaseUrl}/api/LicenseType/image/?url=${value.LicenseTypeImage}`}
                        className={`w-[90%] ${TransitionDuration}`}
                        alt=""
                    />
                </div>
                <div
                    id="LicenseTypeInfo"
                    className={`mx-8 flex flex-col items-center gap-3 ${TransitionDuration}`}
                >
                    <h3 className="Regularfont  text-center text-3xs font-thin lg:text-xs">
                        {" "}
                        {LicenseTypeName}{" "}
                    </h3>

                    <p className="Regularfont  text-center text-[12px] font-thin lg:text-4xs">
                        {LicenseTypeDescription}
                    </p>
                </div>

                <div className="btnShadow absolute right-3 bottom-3 z-10 h-12 w-12 cursor-pointer rounded-[24px] bg-[#A1A3EF]">
                    <img
                        className="hover: absolute left-[13px] top-[18px]  z-10"
                        src={a}
                        alt=""
                    />
                </div>
            </Link>
        );
    });
}
