import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./CoursesTiles.css";
import { useAuthContext } from "../../context/authContext";
import { DeleteLocalStorage, SetLocalStorage } from "../../Helpers/LocalStorage/LocalStorage";
const CoursesSlider = ({
  CoursesInfo,
  CourseTiles,
  NavigateBtnClass,
  ClassNames,
  ShowMoreUrl,
  SwitchSize = "",
  ExtraMove = 0,
  ShowMore = true,
  Name,
  Name2,
  Name3,
  Name4,
  Name5,
  LicenseTypeId
 
}) => {
  const [ref, setref] = useState({});
  const [Size, setSize] = useState(0);
  const [FilteredCount, setFilteredCount] = useState(0);
  const [Btns, setBtns] = useState(
    document?.querySelectorAll(
      `.${NavigateBtnClass}Remove,.${NavigateBtnClass}`
    )
  );

  useEffect(() => {
    if (Btns?.length === 0 && NavigateBtnClass)
      setBtns(
        document?.querySelectorAll(
          `.${NavigateBtnClass}Remove,.${NavigateBtnClass}`
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [NavigateBtnClass]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setSize(0);

      for (let index = 0; index < Btns?.length; index++) {
        if (index === 0) {
          Btns[index].classList.remove(`${NavigateBtnClass}Remove`);
        } else {
          Btns[index].classList.add(`${NavigateBtnClass}Remove`);
        }
      }
    });
  }, [ref, Btns, NavigateBtnClass]);
  if (SwitchSize !== "") SwitchSize = SwitchSize + ":";

  if (window.innerWidth > 641) ExtraMove = 0;

  return (
    <div
      className={`w-full h-fit relative flex justify-center items-center flex-col ${SwitchSize} ${!LicenseTypeId && "gap-8" }`}
    >
     
      {Name &&  <LicenceNameAndTextComp Name={Name} CoursesInfo={CoursesInfo} index={0} LicenseTypeId={LicenseTypeId} />}
      {Name2 && <LicenceNameAndTextComp Name={Name2} CoursesInfo={CoursesInfo} index={1} LicenseTypeId={LicenseTypeId} />}
      {Name3 && <LicenceNameAndTextComp Name={Name3} CoursesInfo={CoursesInfo} index={2} LicenseTypeId={LicenseTypeId} />}
      {Name4 && <LicenceNameAndTextComp Name={Name4} CoursesInfo={CoursesInfo} index={3} LicenseTypeId={LicenseTypeId} />}
      {Name5 && <LicenceNameAndTextComp Name={Name5} CoursesInfo={CoursesInfo} index={4} LicenseTypeId={LicenseTypeId} />}

      <div
        className={`w-[95%] ${SwitchSize}w-full mx-2  ${SwitchSize}flex items-center justify-center py-5 ${!LicenseTypeId && 'lg:mt-5 pt-0' } overflow-hidden`}
      >
        <div
          style={{
            left: -(Size * ref?.offsetWidth + (Size * ExtraMove) / 2) + "px",
          }}
          className={`"bilal", ${ClassNames}`}
        >
          <CourseTiles
            CoursesInfo={CoursesInfo}
            VehicleTypesSate={CoursesInfo}
            setref={setref}
            setFilteredCount={setFilteredCount} // Pass the setFilteredCount function
          />
        </div>
      </div>
      <div
        className={`${
          SwitchSize !== "" ? SwitchSize + "hidden" : ""
        } flex h-10 w-full justify-center gap-4`}
      >
        {CoursesInfo?.map((value, index) => {
          let Class = NavigateBtnClass;
          if (index === 0) {
            Class = NavigateBtnClass;
          } else {
            Class = NavigateBtnClass + "Remove";
          }

          return (
            <span
              key={index}
              className={`NavigateButton_DropShadow h-5 w-5 cursor-pointer rounded-[28px] bg-[#A1A3EF] ${Class}`}
              onClick={(e) => {
                Btn(e, Btns, NavigateBtnClass, setBtns);
                setSize(index);
              }}
            ></span>
          );
        })}
      </div>
      
      {ShowMore && FilteredCount > 4 ? ( //displaying show more if the filtered courses are more than 4
        <Link
          to={ShowMoreUrl}
          className="Regularfont ShowMoreBtn_BlackShadow cursor-pointer rounded-xl border-none bg-[#A1A3EF] px-4 py-3 text-4xs text-white no-underline md:text-base"
        >
          Show More
        </Link>
      ) : null}
      {/* {ShowMore && CoursesInfo?.length > 4 ? (
        <Link
          to={ShowMoreUrl}
          className="Regularfont ShowMoreBtn_BlackShadow cursor-pointer rounded-xl border-none bg-[#A1A3EF] px-4 py-3 text-4xs text-white no-underline md:text-base"
        >
          Show More
        </Link>
      ) : null} */}
    </div>
  );
};

function Btn(e, BtnsArr, BtnClass, setBtns) {
  let ChildIndex = Array.from(BtnsArr).indexOf(e.target);

  for (let i = 0; i < BtnsArr?.length; i++) {
    if (e.target === BtnsArr[i] && ChildIndex === i) {
      BtnsArr[i].classList.add(BtnClass);
      BtnsArr[i].classList.remove(BtnClass + "Remove");
    } else {
      BtnsArr[i].classList.add(BtnClass + "Remove");
      BtnsArr[i].classList.remove(BtnClass);
    }
  }
  setBtns(e.target.parentNode.childNodes);
}

const LicenceNameAndTextComp =({Name, CoursesInfo, index, LicenseTypeId})=> {

   const SublicenseNavigationState =()=> {
    DeleteLocalStorage('SublicenseIndex')
    SetLocalStorage('SublicenseIndex', index)

    
  }
  


  
 
  return <section className="w-full bg-backgroundWhiteColor px-8 sm:px-16 pt-5">
  <div className="w-full">
    <div className="flex w-full justify-between  ">
      <div className="text-start w-[90%] sm:w-[80%] md:w-[50%] gap-2">
        <h1 className="m-0 p-0 text-black font-bold font-segoe-ui text-start text-3xs md:text-xs 2xl:text-sm">{Name} Licensing</h1>

          <p className=" text-start pt-5 text-6xs md:text-2xs font-medium">
            {Name} of graduate learning licensing. Holder may drive
            Class M vehicles with an accompanying fully licensed driver.
          </p>
      
      </div>

<div onClick={SublicenseNavigationState}>
      <Link
      to={`/sublicense/allcourses/${LicenseTypeId}`}
      className="m-0 p-0"
      >
        <div className="btnShadow relative z-10 h-9 w-9 md:h-12 md:w-12  cursor-pointer rounded-[24px] bg-[#A1A3EF]">
          <img
            className="hover: absolute top-[12px] left-[7px] md:left-[13px] md:top-[18px]   z-10"
            src={
              require("../../Pages/LandingPage/LicenseTypes/Assets/Arrow Icon.svg")
                .default
            }
            // src={require('../../../../LandingPage/LicenseTypes/Assets/Arrow Icon.svg').default}
            alt=""
          />
        </div>
      </Link>
      </div>
    </div>
  </div>
</section>
}

export default CoursesSlider;
