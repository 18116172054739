import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Option, Select } from '../../../../../Components/SelectList';
const CourseCategory = ({ setCourseData, CourseData, SubLicenseType, setSubLicenseType, Err, setErr }) => {

    const OnChange = (target, Key, Name) => {
        if (target.value !== "") {
            delete Err?.[Key]
            setErr(Err)
            setCourseData({ ...CourseData, [Key]: target.value })
        }
        else if (target.value === "")
            setErr({ ...Err, [Key]: `${Name} is required` })
    }
    const { VehicleTypes } = useSelector((Store) => Store.VehicleTypeReducer)
    const { LicenseTypes } = useSelector(Store => Store.LicenseTypeReducer)
    console.log("SubLicnese type", SubLicenseType);
    useEffect(() => {
        LicenseTypes.forEach(value => {
            if (value.LicenseTypeId === CourseData.LicenseTypeFK && value.SubLicenseTypes.length > 0)
                setSubLicenseType(value.SubLicenseTypes)
            else if (value.LicenseTypeId === CourseData.LicenseTypeFK && value.SubLicenseTypes.length <= 0)
                setSubLicenseType([])
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [LicenseTypes, CourseData, setSubLicenseType])




    const onChange = (value, key) => {
        OnChange(value.value, "VehicleTypeFK", "Vehicle type")
        setCourseData({ ...CourseData, [key]: value.value })
        // console.log("CourseDataCourseData",CourseData)
        // console.log('value.value', value.value)
    }

   

    return (
        <div className='DataInputContainer mb-20'>
            <span className='Admin_HeadingContainer'>
                <h3 className='InputHeadings'>
                    Course Category
                </h3>
                <p className='InputDetails'>
                    Choose the category and sub-category most suitable for your Vehicle type
                </p>
            </span>

            <div className='Add_C_B_InputSideContainer'>
                <div className='flex w-full justify-center md:justify-start flex-wrap md:flex-nowrap gap-4 md:gap-6 lg:gap-8 xl:gap-10 2xl:gap-12 pt-3 sm:pt-0'>


                    <span className='flex flex-col max-w-[360px] gap-[6px] sm:gap-3 w-full'>
                        <h3 className='font-normal whitespace-nowrap text-7xs sm:text-6xs md:text-5xs lg:text-3xs xl:2xs 2xl:text-xs'>
                            Course Category for Vehicle Type
                        </h3>

                        <Select value={CourseData?.VehicleTypeFK} placeholder="Select Vehicle Type" onChange={value => onChange(value, "VehicleTypeFK")}>
                            {VehicleTypes?.map(value => {
                                console.log(value?.VehicleTypeName)
                                return <Option key={value?.VehicleTypeId} value={value?.VehicleTypeId}>
                                    V Type {value?.VehicleTypeName}
                                </Option>
                            })}
                        </Select>
                        <p className='text-[14px] text-[red] font-normal h-[14px]'>{Err?.VehicleTypeFK} </p>

                    </span>


                    <div className='flex flex-col gap-5  max-w-[360px]  sm:gap-3 w-full'>


                        <span className='flex flex-col max-w-[360px] gap-[6px] sm:gap-3 w-full'>
                            <h3 className='font-normal whitespace-nowrap text-7xs sm:text-6xs md:text-5xs lg:text-3xs xl:2xs 2xl:text-xs'>
                                Course Category for License Type
                            </h3>
                            <Select
                             State={CourseData?.LicenseTypeFK}
                                placeholder="Select License Type"
                                onChange={value => onChange(value, "LicenseTypeFK")}>
                                {LicenseTypes?.map(value => {
                                     
                                    {/* if (CourseData?.LicenseTypeFK === value?.LicenseTypeId)
                                        console.log(CourseData?.LicenseTypeFK) */}
                                    return <Option key={value?.LicenseTypeId} value={value?.LicenseTypeId}>
                                        L Type {value?.LicenseTypeName}
                                    </Option>
                                })}
                            </Select>
                            <p className='text-[14px] text-[red] font-normal h-[14px]'>{Err?.LicenseTypeFK} </p>
                        </span>


                        {SubLicenseType?.length > 0
                            ? <span className='flex flex-col max-w-[360px] gap-[6px] sm:gap-3 w-full'>
                                <h3 className='font-normal text-[12px] whitespace-nowrap sm:text-7xs md:text-6xs lg:text-4xs xl:3xs 2xl:text-2xs'>
                                    Course Category for sub License Type
                                </h3>

                                <Select State={CourseData?.SubLicenseTypeFK}
                                    placeholder="Select Sub License Type"
                                    onChange={value => onChange(value, "SubLicenseTypeFK")}>
                                    {SubLicenseType?.map(value => {
                                        return <Option key={value?.SubLicenseTypeId} value={value?.SubLicenseTypeId}>
                                            Sub L Type {value?.SubLicenseTypeName}
                                        </Option>
                                    })}
                                </Select>
                                <p className='text-[14px] text-[red] font-normal h-[14px]'>{Err?.SubLicenseTypeFK} </p>
                            </span>
                            : null}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseCategory