import React from "react";
import EnrolledCourseInfo from "../../EnrolledCourses/Component/EnrolledCourseInfo/EnrolledCourseInfo";
import { useParams } from "react-router-dom";
import MyCourses from "../../../Components/MyCourses/MyCourses";
import InstituteTemplate from "../../../Components/InstituteTemplate/InstituteTemplate";
import {
    TeacherButtons,
    TeacherInfoButtonsForStaffAdmin,
} from "../../../PageNames";
import InstructorAssignedCourses from "../Instructors/InstructorsAssignedCourses/InstructorAssignedCourses";
import { useDispatch, useSelector } from "react-redux";
import {
    GetCoursesOfSInstructorA,
    GetSInstructorA,
} from "../../../Actions/InstructorA";
import { useEffect } from "react";

const InstructorTrainingCoursesPage = () => {
    const Dispatch = useDispatch();
    const { InstructorId } = useParams();
    const { SInstructor } = useSelector((Store) => Store.InstructorReducer);
    const { error, loading, InstructorCourses } = useSelector(
        (Store) => Store.InstructorReducer
    );
    // console.log(Course?.Student)

    const showMessage = () => {};
    useEffect(() => {
        if (InstructorId) {
            Dispatch(GetSInstructorA(InstructorId));
            Dispatch(GetCoursesOfSInstructorA(InstructorId));
        }
    }, [Dispatch, InstructorId]);
    console.log("instructor courses", InstructorCourses);
    return (
        <MyCourses
            ButtonsInfo={TeacherInfoButtonsForStaffAdmin}
            PageName="TrainingCourses"
            Param={InstructorId}
            HideFooter={true}
            DisplayName={SInstructor?.FirstName + " " + SInstructor?.LastName}
        >
            <div className="">
                <InstructorAssignedCourses
                    error={error}
                    loading={loading}
                    InstructorCourses={InstructorCourses}
                />
            </div>
        </MyCourses>
    );
};
const InstructorTrainingCourses = () => {
    return (
        <InstituteTemplate
            Element={InstructorTrainingCoursesPage}
            BG="white"
            mt="0"
        />
    );
};
export default InstructorTrainingCourses;
