import CryptoJS from 'crypto-js';

export const SetLocalStorage = (name, key) => {
    key = CryptoJS.AES.encrypt(`${JSON.stringify(key)}`, process.env.REACT_APP_EncryptionKey).toString();
    localStorage.setItem(name, JSON.stringify(key));
}

export const UpdateLocalStorage = (name, updatedData) => {
    let existingData = GetLocalStorage(name);
    if (existingData) {
        // Update the existing data with the new data
        Object.assign(existingData, updatedData);
        // Encrypt and save the updated data back to local storage
        SetLocalStorage(name, existingData);
    } else {
        console.error(`No data found in local storage with name ${name}`);
    }
}


export const GetLocalStorage = (name) => {
    let data = JSON.parse(localStorage.getItem(name))
    if (data) {
        let decrypted = CryptoJS.AES.decrypt(data, process.env.REACT_APP_EncryptionKey);
        decrypted = decrypted.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decrypted)
    }
    else 
        return null
}



export const DeleteLocalStorage = (name) => {
    localStorage.removeItem(name)
}
// `${process.env.REACT_APP_EncryptionKey}`