import React, { useEffect, useState } from "react";
import { BaseUrl } from "../../../../Actions/Base";
import LastLicenseImagePreview from "../../../../Components/ImagePreviewer/LastLicenseImagePreview";
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: BaseUrl,
  withCredentials: true,
});
const LastLicense = ({
  imageUrl: image,
  setLicenseFullView,
  setLicenseUpdate,
  StudentInfoId,
}) => {
  const [LastLicenseImage, setLastLicenseImage] = useState();

  async function updateLicenseImage() {
    const formdata = new FormData();
    formdata.append("LatestLicense", LastLicenseImage);
    console.log("LastLicenseImagemm", LastLicenseImage);

    try {
      const res = await axiosInstance.put(
        `/api/update/latestLicenseImage/${StudentInfoId}`,
        formdata
      );
      console.log();
    } catch (error) {
      console.log(error);
    }
  }
  const onChange = (e) => {
    if (e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setLastLicenseImage(selectedImage);
    }
  };
  useEffect(() => {
    if (LastLicenseImage) {
      updateLicenseImage();
    }
  }, [LastLicenseImage]);

  console.log("111111111111111", LastLicenseImage);

  return (
    <div className="mt-5 w-full lg:w-[45%]">
      <div className="flex flex-col gap-3 md:flex-row">
        <p className="text-3xs font-normal text-[#404040] 2xl:text-2xs">
          Student License/Learner
        </p>
        <LastLicenseImagePreview
          PreviewStyle="Instructor_ImagePreview"
          Id="LastLicenseImage"
          ContainerStyle="Instructor_PreviewContainer"
          OnChange={onChange}
          ImgUrl={LastLicenseImage}
          image={image}
          setLicenseFullView={setLicenseFullView}
        />
        <div></div>
      </div>
    </div>
  );
};

export default LastLicense;
