import React from 'react'
import { Option, Select } from '../../../../../Components/SelectList'

const EBookCategory = ({ BookData, setBookData, Err, setErr }) => {
    const OnChange = value => {
        if (value.value !== "") {
            delete Err.E_BookCategory
            setErr(Err)
            setBookData({ ...BookData, E_BookCategory: value.value })
        }
        else if (value.value === "")
            setErr({ ...Err, E_BookCategory: "category is required" })
    }
    const Options = ["Type 1", "Type 2"]

    return (
        <div className='DataInputContainer mb-20 items-center'>
            <span className='Admin_HeadingContainer'>
                <h3 className='InputHeadings'>
                    Course Category
                </h3>
                <p className='InputDetails'>
                    Choose the category and sub-category most suitable for your Vehicle type
                </p>
            </span>

            <div className='max-w-[360px] Add_C_B_InputSideContainer'>
                <span className='flex flex-col gap-3 w-full'>
                    
                    <Select  value={BookData?.E_BookCategory} onChange={OnChange} placeholder="Select E-book category">
                        {
                            Options.map(value =>
                                <Option key={value} value={value}>
                                    {value}
                                </Option>
                            )
                        }

                    </Select>
                    <p className='text-[12px] h-6 text-[red]'>{Err.E_BookCategory ? Err.E_BookCategory : ""} </p>
                </span>
            </div>
        </div>
    )
}

export default EBookCategory