import React from "react";
import sublicenseCategoryImage from "../../Assets/sublicense-top-section.svg";

const TopSection = ({ paraText, headingText, sublicense, SecondSubHeading  }) => {
  return (
    <div>
      <div className={`main-subcategory flex w-full flex-col justify-center  pt-[2rem]  text-white md:flex-row md:justify-around  ${sublicense && 'pt-[5rem] sm:pt-[6rem] md:pt-[4.5rem]'} `}>
        <div className="text-div py-4 sm-p-[1%] flex basis-[55%] flex-col items-center justify-center px-[4%] md:px-[4%]">
          <h2 className="LandingPagetext 2xl:text-md xl:text-md pb-5 text-center text-[20px] text-white md:w-auto  md:text-left md:text-base md:text-[text-sm] lg:text-lg">
            {headingText}
          </h2>
          <p className="Regularfont text-center text-4xs font-thin text-white md:text-left md:text-3xs lg:text-2xs xl:text-base ">
            {paraText}
          </p>
          {SecondSubHeading &&
            <p className="Regularfont mt-3 text-center text-4xs font-thin text-white md:text-left md:text-3xs lg:text-2xs xl:text-base ">
            {SecondSubHeading}
          </p>
          }
        </div>
        <div className="image-div flex basis-[45%] items-center  justify-center p-[2%]">
          <img
            className="w-[400px] md:w-[300px] lg:w-[500px]"
            src={sublicenseCategoryImage}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default TopSection;
