import React from "react";
import "./steps.css";

const DetailsArr = [
    "Add Vehicle Information",
    " Add images of the vehicle",
    "Add vehicle credentials",
];

const Steps = ({ readOnly, updateOnly }) => {
    return (
        <>
            {" "}
            <h2 className="Boldfont my-5 self-start px-5  text-3xs text-[#4C34AD] sm:text-2xs md:text-base  lg:text-lg">
                {readOnly
                    ? "Vehicle's Detail"
                    : updateOnly
                    ? "Update Vehicle Detail"
                    : "Add a New Vehicle In Inventory"}
            </h2>
            {!readOnly && (
                <div
                    className={`back mt-7 flex h-fit w-11/12 flex-col 
                        gap-3    py-4     pb-4     sm:gap-4    sm:bg-white    sm:py-6
                        md:gap-6   md:py-8    lg:gap-8    lg:py-10    xl:gap-12   xl:py-12
                        2xl:gap-14 2xl:py-14`}
                >
                    <div className="flex justify-around gap-7 px-5">
                        {DetailsArr.map((value, index) => (
                            <Step
                                key={index + "z"}
                                index={++index}
                                Detail={value}
                            />
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};
function Step({ index, Detail }) {
    return (
        <div className="flex flex-col items-center gap-4 sm:flex-row ">
            <span
                className={`CarIconShadow flex items-center justify-center
                        rounded-full p-3
                        lg:p-5`}
            >
                <img
                    className="w-6 sm:w-8 md:w-8 lg:w-10 xl:w-12 2xl:w-14"
                    src={require("../Assets/Car.svg").default}
                    alt=""
                />
            </span>
            <p
                className={`text-center 
                        text-7xs text-[#707070] sm:text-left sm:text-6xs
                        md:text-5xs xl:text-4xs`}
            >
                <b>Step {index}.</b> {Detail}{" "}
            </p>
        </div>
    );
}
export default Steps;
