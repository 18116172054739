import React, { useState } from "react";
import ErrorIndicator from "./Components/ErrorIndicator";
import RichTextEditor from "./VehicleInfoComps/RichTextEditor";
import { Option, Select } from "../../../../Components/SelectList";

import "./steps.css";
import { keyframes } from "@emotion/react";

const VehicleInfo = ({
    VehicleData,
    setVehicleData,
    VehicleErrors,
    setVehicleErrors,
    Manufacturers,
    readOnly,
    Colors,
    EngineCapacity,
    GearType,
    Seats,
    Types,
    cars,
    years,
}) => {
    // console.log("vehicledata", VehicleData.Seats);
    // console.log("Seats", Seats);
    let TextStyle = `font-normal whitespace-nowrap w-[30%] max-w-[200px]
                    sm:text-5xs     lg:text-4xs      xl:text-3xs
        hidden      sm:inline-block`;

    let DivStyle = `flex w-full flex-col justify-center sm:justify-start`;

    const OnChange = (Content, KeyName) => {
        setVehicleData({ ...VehicleData, [KeyName]: Content });
        if (Content.toString() !== "" && KeyName !== "Description")
            setVehicleErrors({ ...VehicleErrors, [KeyName]: false });

       
    };

    const [filterCars, setFilterCars] = useState([])

   

    const NewOnChange = (KeyName) => {
        return (value) =>
            setVehicleData({ ...VehicleData, [KeyName]: value.value });
    };

    const NewOnChangeForFilterCars = (KeyName) => {
        return (value) => {
            if (KeyName === "Manufacturer" && Array.isArray(cars)) {
                const filterValues = cars.filter((val) => val.ModelName.toLowerCase().includes(value.value.toLowerCase()));
                console.log("filterValues", filterValues);
                setFilterCars(filterValues);
            }
            setVehicleData((prevData) => ({ ...prevData, [KeyName]: value.value }));
        };
    };

    console.log("vehicle data",VehicleData)

    console.log("cars", cars)

    return (
        <div className="back mt-8 flex w-11/12 flex-col items-center gap-10 py-6 sm:items-start sm:bg-white sm:px-14">
            <h2 className="Boldfont text-3xs font-normal text-[#4C34AD] md:text-2xs xl:text-sm">
                Vehicle Information
            </h2>
            <div
                className={`ml-4 flex w-full
                            flex-wrap          items-center
                            gap-7    sm:ml-5 
                            sm:w-11/12            sm:items-start         md:ml-6         lg:ml-8     xl:ml-9     2xl:ml-10`}
            >
                <div className="flex w-full flex-col gap-5 sm:flex-col sm:gap-5  lg:flex-row  lg:gap-20">
                    <div className={"w-full"}>
                        <h4 className={TextStyle}>Manufacturer</h4>
                        <span className="relative w-11/12 max-w-[450px] sm:w-2/3">
                            <ErrorIndicator
                                Error={VehicleErrors?.Manufacturer}
                            />
                            <Select
                                readOnly={readOnly}
                                value={VehicleData.Manufacturer}
                                placeholder="Select a company"
                                onChange={NewOnChangeForFilterCars("Manufacturer")}
                            >
                                {Manufacturers?.map((value) => {
                                    return (
                                        <Option
                                            value={value.CompanyName}
                                            key={value.id}
                                        >
                                            {value.CompanyName}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </span>
                    </div>

                    <div className={"w-full"}>
                        <h4 className={TextStyle}>Model</h4>
                        <span className="relative w-11/12 max-w-[450px] sm:w-2/3">
                            <ErrorIndicator Error={VehicleErrors?.Model} />

                            <Select
                                readOnly={readOnly}
                                value={VehicleData.Model}
                                placeholder="Select a Model"
                                onChange={NewOnChange("Model")}
                            >
                                {!filterCars ? cars?.map((value) => {
                                    return (
                                        <Option
                                            key={value.id}
                                            value={value.ModelName}
                                        >
                                            {value.ModelName}
                                        </Option>
                                    );
                                })
                                : filterCars?.map((value) => {
                                    return (
                                        <Option
                                            key={value.id}
                                            value={value.ModelName}
                                        >
                                            {value.ModelName}
                                        </Option>
                                    );
                                })

                            
                            }
                            </Select>
                        </span>
                    </div>
                </div>
                <div className="flex w-full flex-col gap-5 sm:flex-col sm:gap-5  lg:flex-row  lg:gap-20">
                    <div className={"w-full"}>
                        <h4 className={TextStyle}>Year</h4>
                        <span className="relative w-11/12 max-w-[450px] sm:w-2/3">
                            <ErrorIndicator Error={VehicleErrors?.Year} />
                            <Select
                                readOnly={readOnly}
                                value={VehicleData?.Year?.toString()}
                                placeholder="Select Year"
                                onChange={NewOnChange("Year")}
                            >
                                {years?.map((value) => {
                                    return (
                                        <Option
                                            key={value.id}
                                            value={value.Year}
                                        >
                                            {value.Year}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </span>
                    </div>

                    <div className={"w-full"}>
                        <h4 className={TextStyle}>Color</h4>
                        <span className="relative w-11/12 max-w-[450px] sm:w-2/3">
                            <ErrorIndicator Error={VehicleErrors?.Color} />
                            <Select
                                readOnly={readOnly}
                                value={VehicleData.Color}
                                placeholder="Select Color"
                                onChange={NewOnChange("Color")}
                            >
                                {Colors?.map((value) => {
                                    return (
                                        <Option
                                            key={value.id}
                                            value={value.Color}
                                        >
                                            {value.Color}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </span>
                    </div>
                </div>
                <div className="flex w-full flex-col gap-5 sm:flex-col sm:gap-5  lg:flex-row  lg:gap-20">
                    <div className={"w-full"}>
                        <h4 className={TextStyle}>Type</h4>
                        <span className="relative w-11/12 max-w-[450px] sm:w-2/3">
                            <ErrorIndicator Error={VehicleErrors?.Type} />

                            <Select
                                readOnly={readOnly}
                                value={VehicleData.Type}
                                placeholder="Select Type"
                                onChange={NewOnChange("Type")}
                            >
                                {Types?.map((value) => {
                                    return (
                                        <Option
                                            key={value.id}
                                            value={value.Type}
                                        >
                                            {value.Type}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </span>
                    </div>
                    <div className={"w-full"}>
                        <h4 className={TextStyle}>Seats</h4>
                        <span className="relative w-11/12 max-w-[450px] sm:w-2/3">
                            <ErrorIndicator Error={VehicleErrors?.Seats} />
                            <Select
                                readOnly={readOnly}
                                value={VehicleData?.Seats}
                                placeholder="Select no. of Seats"
                                onChange={NewOnChange("Seats")}
                            >
                                {Seats?.map((value) => {
                                    return (
                                        <Option
                                            key={value?.id}
                                            value={value?.SeatsCapacity}
                                        >
                                            {value.SeatsCapacity}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </span>
                    </div>
                </div>
                <div className="flex w-full flex-col gap-5 sm:flex-col sm:gap-5  lg:flex-row  lg:gap-20">
                    {/* <div className="flex w-full flex-col gap-10"> */}
                    <div className={"w-full"}>
                        <h4 className={TextStyle}>Engine Capacity</h4>
                        <span className="relative w-11/12 max-w-[450px] sm:w-2/3">
                            <ErrorIndicator
                                Error={VehicleErrors?.EngineCapacity}
                            />
                            <Select
                                readOnly={readOnly}
                                value={VehicleData.EngineCapacity}
                                placeholder="Select Engine capacity"
                                onChange={NewOnChange("EngineCapacity")}
                            >
                                {EngineCapacity?.map((value) => {
                                    return (
                                        <Option
                                            value={value.EngineCapacity}
                                            key={value.id}
                                        >
                                            {value.EngineCapacity}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </span>
                    </div>
                    <div className={"w-full"}>
                        <h4 className={TextStyle}>Transmission</h4>
                        <span className="relative w-11/12 max-w-[450px] sm:w-2/3">
                            <ErrorIndicator Error={VehicleErrors?.GearType} />
                            <Select
                                readOnly={readOnly}
                                value={VehicleData.GearType}
                                placeholder="Select Gear Type"
                                onChange={NewOnChange("GearType")}
                            >
                                {GearType?.map((value) => {
                                    return (
                                        <Option
                                            value={value.GearType}
                                            key={value.id}
                                        >
                                            {value.GearType}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </span>
                    </div>
                    {/* </div> */}
                </div>

                <div className={"w-full"}>
                    <p className={TextStyle}>Description</p>
                    <span className="relative h-[50px] w-11/12  max-w-[800px] sm:w-2/3">
                        <ErrorIndicator Error={VehicleErrors?.Description} />

                        <RichTextEditor
                            readOnly={readOnly}
                            OnChange={OnChange}
                            Errors={VehicleErrors}
                            setErrors={setVehicleErrors}
                            Data={VehicleData}
                            setData={setVehicleData}
                        />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default VehicleInfo;

// import React from 'react'
// import ErrorIndicator from './Components/ErrorIndicator'
// import RichTextEditor from './VehicleInfoComps/RichTextEditor'
// import { Option, Select } from '../../../../Components/SelectList'
// import { Colors, EngineCapacity, GearType, Manufacturers, Seats, Types, cars, years } from "./constants.js";
// import './steps.css'
// const VehicleInfo = ({ VehicleData, setVehicleData, VehicleErrors, setVehicleErrors }) => {
//     let TextStyle = `font-normal whitespace-nowrap w-[30%] max-w-[200px]
//                     sm:text-5xs     lg:text-4xs      xl:text-3xs
//         hidden      sm:inline-block`

//     let DivStyle = `flex w-full justify-center sm:justify-start
//                     sm:gap-14   md:gap-16   lg:gap-20   xl:gap-24   2xl:gap-28`;

//     const OnChange = (Content, KeyName) => {
//         setVehicleData({ ...VehicleData, [KeyName]: Content })
//         if (Content.toString() !== "" && KeyName !== "Description")
//             setVehicleErrors({ ...VehicleErrors, [KeyName]: false })

//     }
//     const NewOnChange = (KeyName) => {

//         return (value) => setVehicleData({ ...VehicleData, [KeyName]: value.value })
//     }
//     // console.log(VehicleData.Manufacturer)
//     return (
//         <div className='sm:bg-white w-11/12 mt-8 flex flex-col items-center sm:items-start sm:px-14 gap-10 py-6 back'>
//             <h2 className='font-normal text-[#4C34AD] text-3xs md:text-2xs xl:text-sm Boldfont'>Car Information</h2>
//             <div className={`flex flex-col gap-7
//                             w-full          sm:w-11/12
//                             items-center    sm:items-start
//                             ml-4            sm:ml-5         md:ml-6         lg:ml-8     xl:ml-9     2xl:ml-10`}>

//                 <div className={DivStyle}>
//                     <h4 className={TextStyle}>Manufacturer</h4>
//                     <span className='w-11/12 sm:w-2/3 relative max-w-[450px]'>
//                         <ErrorIndicator Error={VehicleErrors?.Manufacturer} />
//                         <Select value={VehicleData.Manufacturer} placeholder="Select a company"
//                             onChange={NewOnChange("Manufacturer")}
//                         >
//                             {
//                                 Manufacturers.map(value => {
//                                     return <Option value={value} key={value}>
//                                         {value}
//                                     </Option>
//                                 })
//                             }
//                         </Select>
//                     </span>
//                 </div>

//                 <div className={DivStyle}>
//                     <h4 className={TextStyle}>Model</h4>
//                     <span className='w-11/12 sm:w-2/3 relative max-w-[450px]'>
//                         <ErrorIndicator Error={VehicleErrors?.Model} />

//                         <Select value={VehicleData.Model} placeholder="Select a Model"
//                             onChange={NewOnChange("Model")}
//                         >
//                             {
//                                 cars.map(value => {
//                                     return <Option value={value} key={value}>
//                                         {value}
//                                     </Option>
//                                 })
//                             }
//                         </Select>
//                     </span>

//                 </div>

//                 <div className={DivStyle}>
//                     <h4 className={TextStyle} >Year</h4>
//                     <span className='w-11/12 sm:w-2/3 relative max-w-[450px]'>
//                         <ErrorIndicator Error={VehicleErrors?.Year} />
//                         <Select value={VehicleData?.Year?.toString()} placeholder="Select Year"
//                             onChange={NewOnChange("Year")}
//                         >
//                             {
//                                 years.map(value => {
//                                     return <Option value={value} key={value}>
//                                         {value}
//                                     </Option>
//                                 })
//                             }
//                         </Select>
//                     </span>

//                 </div>

//                 <div className={DivStyle}>
//                     <h4 className={TextStyle}>Color</h4>
//                     <span className='w-11/12 sm:w-2/3 relative max-w-[450px]'>
//                         <ErrorIndicator Error={VehicleErrors?.Color} />
//                         <Select value={VehicleData.Color} placeholder="Select Color"
//                             onChange={NewOnChange("Color")}
//                         >
//                             {
//                                 Colors.map(value => {
//                                     return <Option value={value} key={value}>
//                                         {value}
//                                     </Option>
//                                 })
//                             }
//                         </Select>
//                     </span>

//                 </div>
//                 <div className={DivStyle}>
//                     <h4 className={TextStyle}>Type</h4>
//                     <span className='w-11/12 sm:w-2/3 relative max-w-[450px]'>
//                         <ErrorIndicator Error={VehicleErrors?.Type} />

//                         <Select value={VehicleData.Type} placeholder="Select Type"
//                             onChange={NewOnChange("Type")}
//                         >
//                             {
//                                 Types.map(value => {
//                                     return <Option value={value} key={value}>
//                                         {value}
//                                     </Option>
//                                 })
//                             }
//                         </Select>
//                     </span>

//                 </div>
//                 <div className={DivStyle}>
//                     <h4 className={TextStyle} >Seats</h4>
//                     <span className='w-11/12 sm:w-2/3 relative max-w-[450px]'>
//                         <ErrorIndicator Error={VehicleErrors?.Seats} />
//                         <Select value={VehicleData.Seats} placeholder="Select no. of Seats"
//                             onChange={NewOnChange("Seats")}
//                         >
//                             {
//                                 Seats.map(value => {
//                                     return <Option value={value} key={value}>
//                                         {value}
//                                     </Option>
//                                 })
//                             }
//                         </Select>

//                     </span>

//                 </div>
//                 <div className={DivStyle}>
//                     <h4 className={TextStyle} >Engine Capacity</h4>
//                     <span className='w-11/12 sm:w-2/3 relative max-w-[450px]'>
//                         <ErrorIndicator Error={VehicleErrors?.EngineCapacity} />
//                         <Select value={VehicleData.EngineCapacity} placeholder="Select Engine capacity"
//                             onChange={NewOnChange("EngineCapacity")}
//                         >
//                             {
//                                 EngineCapacity.map(value => {
//                                     return <Option value={`${value}`} key={value}>
//                                         {value}
//                                     </Option>
//                                 })
//                             }
//                         </Select>

//                     </span>

//                 </div>
//                 <div className={DivStyle}>
//                     <h4 className={TextStyle} >Gear Type</h4>
//                     <span className='w-11/12 sm:w-2/3 relative max-w-[450px]'>
//                         <ErrorIndicator Error={VehicleErrors?.GearType} />
//                         <Select value={VehicleData.GearType} placeholder="Select Gear Type"
//                             onChange={NewOnChange("GearType")}
//                         >
//                             {
//                                 GearType.map(value => {
//                                     return <Option value={value} key={value}>
//                                         {value}
//                                     </Option>
//                                 })
//                             }
//                         </Select>
//                     </span>

//                 </div>
//                 <div className={`${DivStyle}`}>
//                     <p className={TextStyle}>Description</p>
//                     <span className='w-11/12 sm:w-2/3  max-w-[800px] relative'>
//                         <ErrorIndicator Error={VehicleErrors?.Description} />

//                         <RichTextEditor OnChange={OnChange} Errors={VehicleErrors} setErrors={setVehicleErrors}
//                             Data={VehicleData} setData={setVehicleData}
//                         />
//                     </span>
//                 </div>
//             </div>

//         </div>
//     )
// }

// export default VehicleInfo
