import { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [forgotPass, setForgotPass] = useState(false);
  const [resetPass, setRresetPass] = useState(false);
  const [terms, setTerms] = useState(false);
  const [captcha, setCaptcha] = useState();
  const [otpPopup, setOtpPopup] = useState(false);
  const [otpEmail, setOtpEmail] = useState();
  const [resync, setResync] = useState(false);
  const [Image, setImage] = useState();
  const [popupType, setPopupType] = useState("");
  const [changePassPopup, setChangePassPopup] = useState(false);
  const [changeOtpPopup, setChangeOtpPopup] = useState(false);
  const [ChangeToNewPassWord, setChangeToNewPassWord] = useState(false);
  const [lastFiveValues, setLastFiveValues] = useState([]);
  const [verifyOtp, setverifyOtp] = useState(false);
  const [verified, setVerified] = useState(false);
  const [deletePopupVisible, setdeletePopupVisible] = useState(false);
  const [SingleDeletedAppointmentData, setSingleDeletedAppointmentData] =
    useState({});
  const [SublicenseIndex, setSublicenseIndex] = useState(null);
  const [LicenseImage, setLicenseImage] = useState();
  const [showSidebar, setShowSidebar] = useState(false);
  const [hideSidebar, setHideSidebar] = useState(false);

  return (
    <AuthContext.Provider
      value={{
        forgotPass,
        setForgotPass,
        resetPass,
        setRresetPass,
        terms,
        setTerms,
        captcha,
        setCaptcha,
        otpPopup,
        setOtpPopup,
        otpEmail,
        setOtpEmail,
        resync,
        setResync,
        setImage,
        Image,
        popupType,
        setPopupType,
        changePassPopup,
        setChangePassPopup,
        changeOtpPopup,
        setChangeOtpPopup,
        ChangeToNewPassWord,
        setChangeToNewPassWord,
        lastFiveValues,
        setLastFiveValues,
        verifyOtp,
        setverifyOtp,
        setVerified,
        verified,
        deletePopupVisible,
        setdeletePopupVisible,
        SingleDeletedAppointmentData,
        setSingleDeletedAppointmentData,
        SublicenseIndex,
        setSublicenseIndex,
        LicenseImage,
        setLicenseImage,
        showSidebar,
        setShowSidebar,
        hideSidebar,
        setHideSidebar
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  return useContext(AuthContext);
};

export { AuthContext, AuthProvider, useAuthContext };
