import React from "react";
import { Link } from "react-router-dom";
import { GetLocalStorage } from "../../../../../../Helpers/LocalStorage/LocalStorage";
import "./LeftCards.css";
import useAxios from "../../../../../../Helpers/CustomHooks/useAxios";
import { useState } from "react";
import { useEffect } from "react";

const LeftCards = () => {
  const [res, errors, loading] = useAxios("get", `/api/stats`);
  const [Stats, setStates] = useState();

  useEffect(() => {
    if (res?.data) setStates(res?.data);
  }, [res]);
  console.log("stats", Stats);
  return (
    <>
      <div className="LeftCardsContainer">
        <div className="">
          <Link
            to="/students/list"
            className="
                        StaffCards background-image-1
                       relative  no-underline
        transition duration-700 hover:origin-top-left 
                        hover:scale-100  md:hover:scale-[1.09]"
          >
            {/* <img className="w-[65px]"  alt="" /> */}
            <div
              className="absolute bottom-0 left-0 h-[40%] xl:w-[170px] w-[230px]"
              style={{ textAlign: "-webkit-center" }}
            >
              <div className="flex flex-col items-center">
                <h4 className="Boldfont text-center text-6xs text-white ">
                  Students
                </h4>
                <p className="text-xs font-medium text-white sm:text-6xs font-segoe-ui ">
                  {Stats?.Students?.total}
                </p>
              </div>
              <p className="px-4 text-center text-8xs text-white ">
                10% More students have enrolled this month
              </p>
            </div>
            {/* <div className="absolute overlay-1 bg-gray-100 bottom-0 left-0 h-[50%] w-[230px]">
            </div> */}
          </Link>
          {GetLocalStorage("UserInfo").InstituteUserType === "Staff" ||
          GetLocalStorage("UserInfo").InstituteUserType === "Supervisor" ? (<></>
            // <div className="StaffCards ClickNoCard">
            //   <img
            //     className="w-[30px]"
            //     src={require("../Assets/Cursor.svg").default}
            //     alt=""
            //   />
            //   <div className="flex flex-col items-center gap-5">
            //     <h4 className="Boldfont text-center text-5xs text-white lg:text-4xs">
            //       No of Clicks
            //     </h4>
            //     <p className="text-xs font-normal text-white sm:text-sm md:text-base lg:text-[29px] xl:text-lg 2xl:text-xl">
            //       1250
            //     </p>
            //   </div>

            //   <p className="max-w-[200px] text-center text-3xs text-white lg:text-6xs">
            //     25% increase in number of clicks
            //   </p>
            // </div>
          ) : GetLocalStorage("UserInfo").InstituteUserType === "Admin" ? (
            <Link
              to="/admin/sales"
              className="StaffCards background-image-4 relative  no-underline
                            transition duration-700 hover:origin-top-left 
                        hover:scale-100  md:hover:scale-[1.09]
                            "
            >
              <div
                className="absolute bottom-0 left-0 h-[40%] w-[230px] xl:w-[170px]"
                style={{ textAlign: "-webkit-center" }}
              >
                <div className="flex flex-col items-center">
                  <h4 className="Boldfont text-center text-6xs text-white ">
                    Sales
                  </h4>
                  <p className="text-xs  text-white font-medium font-segoe-ui sm:text-6xs ">
                    {/* {Stats?.Students?.total}
                     */}
                    $30400
                  </p>
                </div>
                <p className="px-4 text-center text-8xs text-white ">
                  10% More students have enrolled this month
                </p>
              </div>
            </Link>
          ) : GetLocalStorage("UserInfo").User === "Admin" ? (
            <Link
              className="StaffCards ClickNoCard no-underline
                            transition duration-700 hover:origin-top-left 
                        hover:scale-100  md:hover:scale-[1.09] 
                            "
              to="/Admin/sales"
            >
              <img
                className="w-[64px]"
                src={require("../Assets/School.svg").default}
                alt=""
              />
              <div className="flex flex-col items-center gap-5">
                <h4 className="Boldfont text-center text-6xs font-medium font-segoe-ui text-white">
                  Driving Schools List
                </h4>
                <p className=" text-white text-6xs font-medium font-segoe-ui">
                  {Stats?.Institutes?.total}
                </p>
              </div>

              <p className="max-w-[200px] text-center text-6xs text-white ">
                {"   "}
              </p>
            </Link>
          ) : null}

          <Link
            to={"/vehicle/inventory"}
            className="StaffCards background-image-2  CarCard relative text-white no-underline
                        transition duration-700 hover:origin-top-left 
                        hover:scale-100  md:hover:scale-[1.09]
                        "
          >
            {/* <img
              className="w-[60px]"
              src={require("../Assets/Car.svg").default}
              alt=""
            /> */}
            <div
              className="absolute bottom-0 left-0 h-[45%] w-[230px] xl:w-[170px]"
              style={{ textAlign: "-webkit-center" }}
            >
              <div className="flex flex-col items-center">
                <h4 className="Boldfont text-center text-white text-6xs font-medium font-segoe-ui">
                  Vehicle Inventory
                </h4>
                <p className=" text-white text-6xs font-medium font-segoe-ui">
                  {Stats?.Vehicles?.total}
                </p>
              </div>
              {GetLocalStorage("UserInfo").User !== "Admin" ? (
                <p className="max-w-full text-center text-white text-8xs font-segoe-ui">
                  In Use = {Stats?.Vehicles?.inUse} <br />
                  Broke Down = {Stats?.Vehicles?.brokenDown} <br />
                  Available = {Stats?.Vehicles?.available}
                </p>
              ) : null}
            </div>
            {/* <div className="absolute overlay-2 bg-gray-100 bottom-[16px] right-[24px] h-[50%] w-[230px]">
            </div> */}
          </Link>

          <Link
            to={"/Instructors/list"}
            className="StaffCards background-image-3 InstructorCard relative no-underline
                        transition duration-700 hover:origin-top-left 
                        hover:scale-100  md:hover:scale-[1.09]
                        "
          >
            <div
              className="absolute bottom-0 left-0 h-[45%] w-[230px] xl:w-[170px]"
              style={{ textAlign: "-webkit-center" }}
            >
              {/* <img
              className="w-[60px]"
              src={require("../Assets/Instructor.svg").default}
              alt=""
            /> */}
              <div className="flex flex-col items-center">
                <h4 className="Boldfont text-center text-6xs text-white  font-medium font-segoe-ui">
                  Instructors
                </h4>
                <p className=" text-white text-6xs font-medium font-segoe-ui ">
                  {Stats?.Instructors?.total}
                </p>
              </div>

              {GetLocalStorage("UserInfo").User !== "Admin" ? (
                <p className="max-w-[200px] text-center text-white text-8xs  font-segoe-ui">
                  In Class = {Stats?.Instructors?.busy} <br />
                  Un available = {Stats?.Instructors?.unAvailable} <br />
                  Available = {Stats?.Instructors?.free}
                </p>
              ) : null}
            </div>
            {/* <div className="absolute overlay-2 bg-gray-100 bottom-[16px] right-[24px] h-[50%] w-[230px]">
            </div> */}
          </Link>
          <Link
            to="/lessons"
            className="
                        StaffCards background-image-5
                       relative  no-underline
        transition duration-700 hover:origin-top-left 
                        hover:scale-100  md:hover:scale-[1.09] "
          >
            {/* <img className="w-[65px]"  alt="" /> */}
            <div
              className="absolute bottom-0 left-0 h-[40%] w-[230px] xl:w-[170px]"
              style={{ textAlign: "-webkit-center" }}
            >
              <div className="flex flex-col items-center">
                <h4 className="Boldfont text-center text-6xs font-medium font-segoe-ui text-white ">
                  Lessons
                </h4>
                <p className=" text-white  text-6xs font-medium font-segoe-ui">
                  {/* {Stats?.Students?.total} */} 40
                </p>
              </div>
              <p className="px-4 max-w-[195px] text-center  text-white text-8xs font-segoe-ui">
                You can update the status of each class from here
              </p>
            </div>
            {/* <div className="absolute overlay-1 bg-gray-100 bottom-0 left-0 h-[50%] w-[230px]">
            </div> */}
          </Link>
        </div>
      </div>
    </>
  );
};

export default LeftCards;
