import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Player } from "video-react";

const CustomeMediaPlayer = (props) => {
  const {
    title = "Recorded in Vehicle",
    open,
    onClose,
    media,
    controls,
  } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <video
          // ref={(videoEle) => {
          //   console.log(media);
          //   videoEle && (videoEle.src = media);
          // }}
          controls={controls}
          src={media}
          className="w-full"
          autoPlay
        ></video>
      </DialogContent>
    </Dialog>
  );
};

export default CustomeMediaPlayer;
