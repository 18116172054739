import React from "react";
import { Button, Switch } from "@mui/material";


const TimeItem = ({ day, disabled,onChangeTime,onDayEnable }) => {
  return (
    <div className="time-component flex items-center gap-8">
      <h2 className="w-24 text-[16px]">{day.name}</h2>
      <Switch size="medium" checked={day.isOpen} onChange={(e)=>onDayEnable(e,day.name)} />
      <div className="flex gap-2 items-center">
        <input
          className="px-5 py-1"
          style={{borderColor:"unset",borderWidth:1}}
          type="time"
          value={day.time.start}
          onChange={(e) => onChangeTime(e,day.name,"start")}
          disabled={disabled}
        />
        <span className="w-3 h-[1px] bg-black"></span>
        <input
          className="px-5 py-1"
          style={{borderColor:"unset",borderWidth:1}}
          type="time"
          value={day.time.end}
          onChange={(e) => onChangeTime(e,day.name,"end")}
          disabled={disabled}
        />

      </div>
      <Button variant="text" disabled={disabled}>Add hours</Button>
    </div>
  );
};

export default TimeItem;
