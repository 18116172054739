export const SetImagePreview = (setImage, image) => {
    console.log("imageinsetpreviw", image);
    if (image?.type !== "application/pdf") {
        const Reader = new FileReader();

        Reader.readAsDataURL(image);
        Reader.onload = () => {
            setImage(Reader.result);
        };
    }
};
