import React from "react";
import img from "./Assets/right-section-image.png";
import "./CourseSyllabus.css"

const CourseSyllabus = ({ CourseSyllabusArr, CourseSyllabusRef }) => {
  console.log("CourseSyllabusArr", CourseSyllabusArr);
  return (
    <div
      className="flex backround_color px-6 md:px-[3%] flex-col flex-wrap items-center gap-10 py-10 mb-5"
      ref={CourseSyllabusRef}
    >
      <h2 className=" text-[38px] font-bold">Syllabus</h2>

      <div className="w-full  gap-5 items-center flex flex-col lg:flex-row">
        <div className="flex flex-col gap-6 w-[80%] lg:w-[55%] items-center">
            <div className="top-text ml-6">
                  <h2 className="pb-4 text-xs lg:text-sm 2xl:text-base font-bold font-segoe-ui">Master The Road</h2>
                  <p className=" text-4xs lg:text-3xs 2xl:text-xs font-segoe-ui">This syllabus outlines the key areas you'll explore throughout our driver's education course. By following this structured learning path, you'll gain the knowledge and practical skills necessary to confidently navigate the road and become a safe, responsible driver.</p>
            </div>
            <div className="flex flex-col md:flex-row  w-full flex-wrap  ">

        
            {CourseSyllabusArr?.map((value) => {
                
              return value.CourseDescription !== "" ? <div
                className="flex w-full md:w-[50%]  justify-start items-center "
                key={value.CourseSyllabusId}
              >
                <img src={require("./Assets/syllabous-arrow.svg").default} alt="" />
                <p className="SemiBold text-start text-4xs text-[#2D2366] md:text-2xs">
                  {value.CourseDescription} 
                </p>
              </div> : null
            })}
           </div>
        </div>
        <div className="image-container flex w-[80%] lg:w-[45%] items-center justify-center">
          <img className="w-full" src={img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default CourseSyllabus;
