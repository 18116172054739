import React, { createContext, useState } from 'react';

// Create the context
export const StudentClassesContext = createContext();

// Create the provider component
export const StudentClassesProvider = ({ children }) => {
  // Initialize the state
  const [studentClassesScheduleAppointments, setStudentClassesScheduleAppointments] = useState([]);

  return (
    <StudentClassesContext.Provider value={{ studentClassesScheduleAppointments, setStudentClassesScheduleAppointments }}>
      {children}
    </StudentClassesContext.Provider>
  );
};
