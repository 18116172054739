import React, { useEffect } from 'react'
import "./Footer.css";
import { Link } from "react-router-dom";
import footerLogo from './Assets/vli_logo_bw.png'
const Footer = ({ FooterBgColor, Z_Index, contactUs }) => {

    if (!Z_Index)
        Z_Index = "-z-10"
    return (
        <footer className={` ${contactUs ? 'md:pt-[12rem] lg:pt-[13rem] xl:pt-[20rem] 2xl:pt-[20rem] 3xl:pt-[25rem] mt-6' : 'pt-[130px] md:pt-[100px] mt-32'} footer-new-class-for-bg bg-backgroundWhiteColor bg-transparent   md:mt-0 w-full gap-4 md:gap-12 lg:gap-24 xl:gap-40 2xl:gap-10 3xl:gap-36 md:max-h-[auto] relative md:h-fit flex flex-col items-center  rounded-t-[50px] md:rounded-t-[0px]  FooterLinearGradient md:overflow-hidden ${Z_Index}`}>


            {!contactUs && <ContactPortionFooter />}
            <MobileScreenFooter />
            <div className='flex flex-col w-full lg:gap-7 sm:gap-2'>
                <MidFooter />
                <LowerFooter />
            </div>
            <img src={require('./Assets/Footer BG.svg').default}
                className={`2xl:w-full absolute bottom-0 ${contactUs ? 'w-[130%] lg:w-[120%] 2xl:w-full ' : 'w-[150%] lg:w-[145%]'} -z-[10]  hidden md:inline-block`}

                alt="" />

        </footer>
    )
}
function MobileScreenFooter() {
    return <div className='flex w-full md:hidden flex-col items-center gap-4'>
        <div className='w-[90%] pt-4 flex flex-wrap list-none gap-3 justify-center text-6xs Regularfont'>

            <Link className='text-white'>Help  </Link> <hr />


            <Link className='text-white'>Search Courses By License Types</Link> <hr />


            <Link className='text-white'> View Special Courses</Link>

        </div>
        {/* <p className='text-6xs text-white Regularfont w-4/5 text-center'>© 2022The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, flick quartz, vex nymphs. Waltz, bad nymp</p> */}
        <p className='text-6xs text-white Regularfont w-4/5 text-center'>Vehicle Learning Innovation | 1225 Kennedy Rd, Suite 2000, Toronto, M1P 4Y1, ON, Canada </p>
    </div>
}
function ContactPortionFooter() {
    return <div className=' absolute -top-24 md:static max-w-[525px] w-[80%] xs:w-[60%] sm:w-4/5 px-7 pt-4 pb-7 sm:pb-14 flex flex-col items-center bg-[#A1A3EF] rounded-[20px] text-white gap-4  min-h-[180px] '>
        <h3 className='text-3xs md:text-lg font-normal Regularfont'>
            Contact Us!
        </h3>

        <p className='text-[12px] md:text-xs font-segoe-ui text-center text-pretty Regularfont'>
        We'd love to hear from you. Want to see how we can transform the future together? Contact us today. </p>
        <Link to='/contact-us' className='z-50 text-6xs px-8 py-2 m-2 bg-[#333697] Regularfont text-white md:text-base rounded-[50px] border-none cursor-pointer ContactBtn_DropShadow'>
            Contact
        </Link>
       
    </div>
}
function MidFooter() {
    
    let LinkStyle = "text-white Regularfont no-underline text-6xs cursor-pointer sm:text-7xs  lg:text-3xs 3xl:text-sm`";
    let ulStyle = "list-none md:flex flex-col justify-start gap-5"
    let iconStyle = "w-full sm:w-[27px] xl:w-[35px]"
    return <div className='flex pl-6 items-center justify-around w-full '>
        <ul className={`hidden sm:w-[25%] ${ulStyle}`}>
            <li><Link className={`${LinkStyle}`}>E-Books</Link></li>
            <li><Link className={`${LinkStyle}`}>Latest News</Link></li>
            <li><Link className={`${LinkStyle}`}>Knowledge Test</Link></li>
            <li><Link className={`${LinkStyle}`}>Vehicle Type</Link></li>
            <li><Link className={`${LinkStyle}`}>License Classes</Link></li>

        </ul>

        <ul className='list-none flex gap-5 sm:w-[25%]  flex-col-reverse items-center w-1/2 md:w-auto my-4'>
            <li>
                <img
                    className='w-full md:w-[250px] xl:w-[300px]'
                    src={footerLogo} alt="" />
            </li>
            <li className='flex 2xs:gap-3 gap-6 items-center justify-center w-full'>
                <a target='_blank' href={'https://www.instagram.com/vliheadoffice/'} className='md:w-auto' >
                    <img className={iconStyle} src={require("./Assets/Instagram Icon.svg").default} alt="" />
                </a>
                <a target='_blank' href='https://www.facebook.com/profile.php?id=61558157441917' className='md:w-auto' >
                    <img className={iconStyle} src={require("./Assets/Facebook Icon.svg").default} alt="" />
                </a>
                <Link className='md:w-auto' >
                    <img className={iconStyle} src={require("./Assets/Twitter Icon.svg").default} alt="" />
                </Link>
                <Link className='md:w-auto' >
                    <img className={iconStyle} src={require("./Assets/Linkedin Icon.svg").default} alt="" />
                </Link>
                <Link className='md:w-auto' >
                    <img className={iconStyle} src={require("./Assets/Pinterest Icon.svg").default} alt="" />
                </Link>
            </li>
        </ul>

        <ul className={`sm:w-[25%] ${ulStyle} hidden px-1`}>
            <li><Link className={`${LinkStyle} relative`}>
                <span className='absolute -left-8'><LocationIcon /></span> 1225 Kennedy Rd, Suite 2000, Toronto, M1P 4Y1, ON, Canada</Link>
            </li>
            <li><Link className={`${LinkStyle} relative `}><span className='absolute -left-8'><img src={require('./Assets/phone.svg').default} alt="" /></span> +1 647 948 9811</Link></li>
            <li><Link className={`${LinkStyle} relative `}><span className='absolute -left-8'><img src={require('./Assets/mail.svg').default} alt="" /></span> headoffice@vehiclelearning.com</Link></li>
        </ul>
    </div>
}
function LowerFooter() {
    let text = 'text-[9px]   lg:text-[11px] xl:text-6xs 3xl:text-6xs text-white  Regularfont font-thin list-none no-underline'
    return <div className='md:flex w-full flex-col gap-4 mb-5 hidden'>
        <hr />
        <div className='flex justify-around w-full'>
            <Link className={`${text}`}>Copyright & Terms And Conditions</Link>
            <p className={`${text}`}>© 2024 Vehicle learning Innovation All rights reserved. Vehicle learning Innovation is a Canadian trademark.</p>
            <span className='flex gap-4 lg:gap-16'>
                <Link className={`${text}`}>
                    Terms of use
                </Link>
                <hr />
                <Link className={`${text}`}>
                    Privacy  Policy
                </Link>
            </span>
        </div>
    </div>
}

function LocationIcon({ Classes }) {
    return <svg xmlns="http://www.w3.org/2000/svg" width="21" height="30" viewBox="0 0 21 30">
        <path id="Icon_material-location-on" data-name="Icon material-location-on" className={Classes} d="M18,3A10.492,10.492,0,0,0,7.5,13.5C7.5,21.375,18,33,18,33S28.5,21.375,28.5,13.5A10.492,10.492,0,0,0,18,3Zm0,14.25a3.75,3.75,0,1,1,3.75-3.75A3.751,3.751,0,0,1,18,17.25Z" transform="translate(-7.5 -3)" fill="#fff" />
    </svg>

}

function PhoneIcon({ Classes }) {
    return <svg xmlns="http://www.w3.org/2000/svg" width="21.725" height="21.775" className={Classes} viewBox="0 0 21.725 21.775">
    <path id="Path_94214" data-name="Path 94214" d="M18.435,15.676,16.618,16.7a1.146,1.146,0,0,1-1.373-.189L9.181,10.447a1.147,1.147,0,0,1-.189-1.373l1.024-1.817A1.719,1.719,0,0,0,9.733,5.2L7.01,2.474a1.719,1.719,0,0,0-2.431,0L3.342,3.71a4.585,4.585,0,0,0-.689,5.6l.62,1.034A35.939,35.939,0,0,0,16.261,23.058l.123.07a4.593,4.593,0,0,0,5.546-.722l1.291-1.291a1.719,1.719,0,0,0,0-2.431L20.5,15.959a1.719,1.719,0,0,0-2.06-.283Z" transform="translate(-2 -1.971)" fill="#fff"/>
  </svg>
}


export default Footer