



import React from "react";

const Certificates = ({ PageName, IconName }) => {
  const Color = PageName === IconName ? "#4C34AD" : "#fff";

  return (
    <div className="ml-0 flex items-center gap-4">
     <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m363-310 117-71 117 71-31-133 104-90-137-11-53-126-53 126-137 11 104 90-31 133ZM480-28 346-160H160v-186L28-480l132-134v-186h186l134-132 134 132h186v186l132 134-132 134v186H614L480-28Zm0-112 100-100h140v-140l100-100-100-100v-140H580L480-820 380-720H240v140L140-480l100 100v140h140l100 100Zm0-340Z"/>
     <g id="Customers" transform="translate(-0.494 0.105)">
          <g id="icon_customers" transform="translate(0.494 -0.105)">
            <path
              id="Union_10"
              data-name="Union 10"
              d="M0,25a9.43,9.43,0,0,1,9.428-9.375h3.143A9.43,9.43,0,0,1,22,25Zm4.085-3.126H18.071a6.344,6.344,0,0,0-5.5-3.125H9.585A6.348,6.348,0,0,0,4.085,21.874ZM4.714,7.812V6.249a6.286,6.286,0,0,1,12.572,0V7.812a6.286,6.286,0,0,1-12.572,0ZM7.857,6.249V7.812a3.143,3.143,0,0,0,6.287,0V6.249a3.143,3.143,0,0,0-6.287,0Z"
              fill={Color}
            />
          </g>
        </g>
     </svg>
      <p className="font-bold text-white">Certificates</p>
    </div>
  );
};

export default Certificates;
