import React from "react";
import { CreateOrder, ApproveOrder } from "./Helpers/CreateOrder";
import { SetLocalStorage, UpdateLocalStorage, DeleteLocalStorage } from "../../../../../Helpers/LocalStorage/LocalStorage";


export const PaypalBtn = ({
    Package,
    Installment,
    setSuccess,
    EnrollmentData,
    PayPalButton,
    PaypalBtnRef,
    StudentImage,
    handleEnrollmentSuccess,
    UserInformation,
    dispatch,
    LatestLicense
}) => {
    // const handleEnrollmentSuccess = () => {
    //     // Call the onSuccess callback with course details
    //     onSuccess({
    //         Package,
    //     });
    // };
    return (
        <div
            style={{ maxWidth: "750px", minHeight: "200px" }}
            ref={PaypalBtnRef}
        >
             
            <PayPalButton
                createOrder={(data, actions) =>
                    CreateOrder(Package, Installment)
                }
                
                onApprove={async (data, actions) => {
                    await ApproveOrder(
                        data.orderID,
                        Package,
                        Installment,
                        EnrollmentData,
                        handleEnrollmentSuccess,
                        StudentImage,
                        UserInformation,
                        SetLocalStorage,
                        dispatch,
                        LatestLicense
                    )
                        .then((data) => {
                            // console.log("handleEnrollmentSuccessdata",data)
                           
                            // console.log(
                            //     "line~39 i am triggered below enrollment success"
                            // );
                        })
                        .catch((error) => {
                            console.error("Error capturing payment:", error);
                            // Handle error if necessary
                        });
                }}
            />
            {/* <PayPalButton
                createOrder={(data, actions) => CreateOrder(Package, Installment,)}
                onApprove={async (data, actions) => {
                    await ApproveOrder(data.orderID, Package, Installment, EnrollmentData )
                    let Check = await actions.order.capture();
                    // if (Check.status === 'COMPLETED')
                    //     setSuccess(true)
                    return actions.order.capture()
                }}
            /> */}
        </div>
    );
};
