import React from "react";
import RecordingListCnt from "./RecordingListCnt";
import InstituteTemplate from "../../Components/InstituteTemplate/InstituteTemplate";

const InVehicleRecording = () => {
  return (
    <InstituteTemplate
      Element={RecordingListCnt}
      bg="bg-[#F7F7F7] min-h-screen items-center"
    />
  );
};

export default InVehicleRecording;
