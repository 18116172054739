import { fromAddress, fromLatLng, setKey } from "react-geocode";

export const fetchLocationName = async (latLng) => {
  setKey(process.env.REACT_APP_GOOGLE_MAPS_KEY);
  //   43.7241141, -79.5006267
  try {
    const response = await fromLatLng(latLng.lat, latLng.lng);

    if (response.results[0]) {
      console.log("instructor location", response.results[0].formatted_address);
      return response.results[0].formatted_address;
    } else {
      return "No location information found!";
    }
  } catch (error) {
    alert(error.message);
  }
};
export const fetchLatLng = async (address) => {
  setKey(process.env.REACT_APP_GOOGLE_MAPS_KEY);
  //   43.7241141, -79.5006267
  try {
    const response = await fromAddress(address);
    if (response.results[0]) {
      return response.results[0].geometry.location;
    } else {
      return "No location information found!";
    }
  } catch (error) {
    alert(error.message);
  }
};

export const getCurrentLocation = async (addressType, studentAddressfromDB) => {
  let address1 = `222 Spadina Ave. Unit 233, Toronto, ON M5T 3B3, Canada`;
  let address2 = `572 Rogers Rd, York, ON M6M 1B6`;

  if (addressType === "instructor") {
    let latlng = await fetchLatLng(studentAddressfromDB);
    return { address: studentAddressfromDB, latlng };
  }
  if (addressType === "student") {
    let latlng = await fetchLatLng(studentAddressfromDB);
    return { address: studentAddressfromDB, latlng };
  }
  if (
    addressType &&
    (addressType != "student" || addressType != "instructor")
  ) {
    let latlng = await fetchLatLng(addressType);
    return { address: addressType, latlng };
  } else alert("Please provide type");
};

export const convertDistanceToMeters = (distanceStr) => {
  const distanceRegex = /^(\d+(?:\.\d+)?)\s*(km|m)$/;
  const match = distanceStr.match(distanceRegex);

  if (!match) {
    throw new Error("Invalid distance format");
  }

  const [_, value, unit] = match;
  const numericValue = parseFloat(value);

  if (unit === "km") {
    return numericValue * 1000; // Convert kilometers to meters
  }

  return numericValue; // If the unit is meters, return the value as is
};

export const liveData = [
  {
    lat: 43.65107,
    lng: -79.347015,
  },
  {
    lat: 43.650811499999996,
    lng: -79.35021925,
  },
  {
    lat: 43.650552999999995,
    lng: -79.3534235,
  },
  {
    lat: 43.650294499999994,
    lng: -79.35662775,
  },
  {
    lat: 43.650036,
    lng: -79.359832,
  },
  {
    lat: 43.6497775,
    lng: -79.36303625,
  },
  {
    lat: 43.649519,
    lng: -79.3662405,
  },
  {
    lat: 43.6492605,
    lng: -79.36944475,
  },
  {
    lat: 43.649001999999996,
    lng: -79.372649,
  },
  {
    lat: 43.648743499999995,
    lng: -79.37585325,
  },
  {
    lat: 43.648484999999994,
    lng: -79.3790575,
  },
  {
    lat: 43.6482265,
    lng: -79.38226175,
  },
  {
    lat: 43.647968,
    lng: -79.38546600000001,
  },
  {
    lat: 43.6477095,
    lng: -79.38867025,
  },
  {
    lat: 43.647451,
    lng: -79.3918745,
  },
  {
    lat: 43.647192499999996,
    lng: -79.39507875000001,
  },
  {
    lat: 43.646933999999995,
    lng: -79.398283,
  },
  {
    lat: 43.6466755,
    lng: -79.40148725,
  },
  {
    lat: 43.646417,
    lng: -79.4046915,
  },
  {
    lat: 43.6461585,
    lng: -79.40789575000001,
  },
  {
    lat: 43.6459,
    lng: -79.4111,
  },
  {
    lat: 43.655,
    lng: -79.36,
  },
  {
    lat: 43.655,
    lng: -79.37,
  },
  {
    lat: 43.655,
    lng: -79.38,
  },
  {
    lat: 43.6459,
    lng: -79.4111,
  },
  {
    lat: 43.6461585,
    lng: -79.40789575000001,
  },
  {
    lat: 43.646417,
    lng: -79.4046915,
  },
  {
    lat: 43.6466755,
    lng: -79.40148725,
  },
  {
    lat: 43.646933999999995,
    lng: -79.398283,
  },
  {
    lat: 43.647192499999996,
    lng: -79.39507875000001,
  },
  {
    lat: 43.647451,
    lng: -79.3918745,
  },
  {
    lat: 43.6477095,
    lng: -79.38867025,
  },
];
