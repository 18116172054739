import { EnrollIntoCourse } from "../../Actions/CourseA";
import { ScrollSingleElement } from "../../Helpers/ScrolltoElement";
import { validatePhoneNumber } from "../Institute/AddInstructor/AddInstructor";
import { CheckSchedule } from "./Payment/Components/Helper/CheckSchedule";

export const SubmitForm = (
    e,
    EnrollmentData,
    Err,
    setErr,
    Dispatch
    // Navigate
) => {
    e.preventDefault();
    // console.log(
    //     "line~15 Inside the Submitfor",
    //     Err,
    //     EnrollmentData.StudentData
    // );
    let Errors = {};
    for (const [key, value] of Object.entries(EnrollmentData.StudentData)) {
        // console.log("Errorvalues", value);
        if (value === "") Errors = { ...Errors, [key]: `${key} * is required` };
        else Errors = { ...Errors, [key]: undefined };
    }

    // if (EnrollmentData?.StudentData?.AgreeTerms !== true) {
    //     alert("Please check on the terms and conditions");
    //     return;
    // }
    EnrollmentData?.StudentData?.FreeHours?.forEach((Value, index) => {
        if (Value.Start)
            for (const [, value] of Object.entries(Value.Start))
                if (value === "")
                    Errors = {
                        ...Errors,
                        FreeHours: `Please enter a valid value`,
                    };
                else Errors = { ...Errors, FreeHours: undefined };
    });
    let Serr = CheckSchedule(EnrollmentData);
    Errors = { ...Errors, ...Serr };

    setErr({ ...Err, ...Errors });

    let CheckErr = false;

    for (const [, value] of Object.entries(Errors)) {
        if (value === undefined) CheckErr = true;
    }

    if (!CheckErr) return;
    Dispatch(EnrollIntoCourse(EnrollmentData));
};
