import { useDispatch, useSelector } from "react-redux";
import InstituteTemplate from "../../../Components/InstituteTemplate/InstituteTemplate";
import { useEffect, useState } from "react";
import {
  DeleteInstituteCoursesA,
  InstituteCoursesA,
} from "../../../Actions/CourseA";
import LoadingSpinner from "../../../Components/LoadingSpinner/LoadingSpinner";
import NoDataResponse from "../../../Components/NoDataResponse";
import "./AllCourses.css";
import CoursesTilesForAllCourses from "../Institute Admin/Components/RecentCourses/Components/CoursesTilesForAllCourses";

function AllCourses({ setInstituteCourseId, setEdit }) {
  const { Courses, loading } = useSelector((store) => store.CourseReducer);
  const dispatch = useDispatch();
  const [deleteCourseId, setDeleteCourseId] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All Courses");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    dispatch(InstituteCoursesA(currentPage, selectedFilter));
  }, [dispatch, currentPage, selectedFilter]);

  useEffect(() => {
    if (Courses) {
      setTotalPages(Courses.totalPages);
      setTotalItems(Courses.totalItems);
    }
  }, [Courses]);

  const handleDeleteCourse = () => {
    dispatch(DeleteInstituteCoursesA(deleteCourseId)).then(() =>
      dispatch(InstituteCoursesA(currentPage, selectedFilter))
    );
    setShowPopup(false);
  };

  const UpdateStatus = (instituteCourseId) => {
    console.log('update status')
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const CourseEditClick = (id) => {
    setEdit(true);
    setInstituteCourseId(id);
  };

  //   const { Courses, loading } = useSelector((Store) => Store.CourseReducer);
  //   const Dispatch = useDispatch();
  //   const [DeleteCourseId, setDeleteCourseId] = useState();
  //   const [showPopup, setShowPopup] = useState(false);
  //   const [selectedFilter, setSelectedFilter] = useState('All Courses');
  //   const [filteredCourses, setFilteredCourses] = useState([]);

  //   useEffect(() => {
  //     if (selectedFilter === 'All Courses') {
  //       setFilteredCourses(Courses?.courses || []);
  //     } else {
  //       setFilteredCourses(
  //         Courses?.courses.filter((course) => course.Publish === selectedFilter) || []
  //       );
  //     }
  //   }, [selectedFilter, Courses]);
  //   const handleDeleteCourse = () => {
  //     Dispatch(DeleteInstituteCoursesA(DeleteCourseId)).then(() =>
  //       Dispatch(InstituteCoursesA())
  //     );
  //     setShowPopup(false);
  //   };
  //   const DeleteCourse = (InstituteCourseId) => {
  //     setDeleteCourseId(InstituteCourseId);
  //     setShowPopup(true);
  //     // Dispatch(DeleteInstituteCoursesA(InstituteCourseId))
  //     //   .then(() => Dispatch(InstituteCoursesA()))
  //     //   .catch((error) => console.error("Error deleting course", error));
  //   };

  //   useEffect(() => {
  //     Dispatch(InstituteCoursesA());
  //   }, [Dispatch]);

  //   const CourseEditClick = (Id) => {
  //     setEdit(true);
  //     setInstituteCourseId(Id);
  //   };

  console.log("CoursesCoursesCourses", Courses?.courses);
  console.log("CoursesCoursesCourses", Courses);
  const filterTextStyle = `cursor-pointer hover:scale-105 `
  const activeFilterTextStyle = `underline decoration-violet-100 decoration-4 underline-offset-[6px]`

  function filterChange(filter) {
    setSelectedFilter(filter)
    setCurrentPage(1)
  }

  return (
    <>
      {" "}
      <div className="flex flex-col gap-6 bg-purpal pt-[4%] pb-2">
        <div className=" px-[13%] md:px-[4%] self-center lg:self-start ">
          <h3
            style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
            className="heading_1   text-white w-fit"
          >
            Courses
          </h3>
        </div>


        <div className="flex justify-around xl:justify-start xl:gap-12 2xl:gap-24 px-[4%] text-8xs md:text-6xs xl:text-4xs 2xl:text-3xs 3xl:text-xs font-semibold text-white">
          <p
            className={`${filterTextStyle} ${
              selectedFilter === "All Courses"
                ? activeFilterTextStyle
                : ""
            }`}
            onClick={() => filterChange("All Courses")}
          >
            All Courses
          </p>
          <p
            className={`${filterTextStyle}  ${
              selectedFilter === "Approved"
                ? activeFilterTextStyle
                : ""
            }`}
            onClick={() => filterChange("Approved")}
          >
            Approved
          </p>
          <p
            className={`${filterTextStyle} ${
              selectedFilter === "false"
                ? activeFilterTextStyle
                : ""
            }`}
            onClick={() => filterChange("false")}
          >
            Under Revision
          </p>
          <p
            className={`${filterTextStyle}  ${
              selectedFilter === "Rejected"
                ? activeFilterTextStyle
                : ""
            }`}
            onClick={() => filterChange("Rejected")}
          >
            Rejected
          </p>
        </div>
      </div>

      
      
      <div className="max-w-[1150px] px-3 pt-5 2xs:px-4 xs:px-5 sm:px-6 md:px-7 lg:px-8 xl:px-9 2xl:px-10 3xl:px-11 ">
    
        {/* top */}
        {showPopup && (
          <CourseDeletePopup
            type={"course"}
            onConfirm={handleDeleteCourse}
            onCancel={() => setShowPopup(false)}
          />
        )}

        {/* recent courses list */}

        <div className="flex flex-col items-center bg-white py-4">
        <h3
            style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
            className="heading_1 text-purpal w-fit lg:self-start pb-4"
          >
            All Courses
          </h3>
          {loading ? (
            <LoadingSpinner
              Position="fixed"
              Left="0"
              Bg="white"
              Height="screen"
              Width="full"
            />
          ) : Courses?.courseslength === 0 ? (
            <NoDataResponse
              topText="Sorry, courses aren't published yet!"
              bottomText="Admin/Institute can add courses by click on the 'Create Course' button from Dashboard."
            />
          ) : (
            <CoursesTilesForAllCourses
              Courses={Courses?.courses}
              OnClick={CourseEditClick}
              UpdateStatus={UpdateStatus}
              allCourses={true}
            />
          )}
          {Courses?.totalPages > 1 && (
            <div className="flex h-[40px] w-full justify-center lg:justify-end">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const CustomButton = ({ text, CourseType, OnClick }) => {
  const BgColor = CourseType !== text ? "bg-white" : "bg-[#C7DDFC]";
  return (
    <button
      className={`${BgColor} w-[19%] cursor-pointer rounded-t-[12px] border-none px-[25px] py-[31px] text-center text-3xs text-[#576271]`}
      onClick={OnClick}
    >
      {text}
    </button>
  );
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pages = [];

  // Calculate the pages to display
  if (totalPages <= 3) {
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
  } else {
    if (currentPage > 1) {
      pages.push(currentPage - 1);
    }
    pages.push(currentPage);
    if (currentPage < totalPages) {
      pages.push(currentPage + 1);
    }
  }

  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };
  const pageNumberStyle = `pagination-button w-[15px] flex justify-center relative items-center px-4 h-full text-purpal `;

  return (
    <div className="pagination flex h-full w-fit border-2 border-solid  border-[#5456C7]">
      <button
        onClick={handlePrevious}
        disabled={currentPage === 1}
        className={`pagination-button flex items-center bg-white px-4 text-6xs font-semibold  text-purpal lg:text-5xs w-[80px] ${currentPage === 1 ? ' cursor-not-allowed': ''}`}
      >
        Previous
      </button>

      {currentPage > 2 && totalPages > 3 && (
        <button
          onClick={() => onPageChange(1)}
          className={` ${pageNumberStyle} `}
        >
          1
        </button>
      )}

      {currentPage > 3 && totalPages > 3 && (
        <span className={`${pageNumberStyle}`}>...</span>
      )}

      {pages.map((page) => (
        <button
          key={page}
          onClick={() => onPageChange(page)}
          disabled={page === currentPage}
          className={`pagination-button relative flex h-full w-[15px] items-center justify-center px-4 ${
            page === currentPage
              ? "bg-purpal text-white"
              : "bg-white text-purpal"
          }`}
        >
          {page}
        </button>
      ))}

      {currentPage < totalPages - 2 && totalPages > 3 && (
        <span className={`${pageNumberStyle}`}>...</span>
      )}

      {currentPage < totalPages - 1 && totalPages > 3 && (
        <button
          onClick={() => onPageChange(totalPages)}
          className={`${pageNumberStyle}`}
        >
          {totalPages}
        </button>
      )}

      <button
        onClick={handleNext}
        disabled={currentPage === totalPages}
        className={`pagination-button relative bg-white px-4 py-2 text-6xs font-semibold text-purpal  lg:text-5xs w-[80px] ${currentPage === totalPages ? 'cursor-not-allowed': ''}`}
      >
        Next
      </button>
    </div>
  );
};

const CourseDeletePopup = ({ popupDetail, onCancel, onConfirm, type }) => {
  return (
    <div className="fixed left-0 top-0 z-30 flex h-screen w-screen items-center justify-center bg-[#00000029]">
      <div className="relative flex aspect-[353_/_221] h-fit w-full max-w-[360px] flex-col gap-[10px] rounded-[20px] bg-white px-8 py-5">
        <div className="flex flex-col items-center gap-3">
          <h3 className="HeadingBorder text-5xs font-normal">
            Delete Course !!
          </h3>
          <h3 className="mt-10 text-4xs font-normal ">
            Are you sure you want to delete
          </h3>
        </div>
        <div className="mt-5 flex flex-col items-center gap-[6px]">
          {/* )} */}
          <div className="flex items-center justify-center gap-3">
            <button
              type="button"
              className="BrandingButton SemiBold w-fit cursor-pointer rounded-[14px] py-1 px-8 text-6xs"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="SemiBold w-fit cursor-pointer  rounded-[14px] bg-[#4C34AD] py-1 px-8 text-6xs text-white"
              onClick={onConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export function AllCoursesPage() {
  return <InstituteTemplate Element={AllCourses} />;
}
