import React from 'react'

const CustomButtonPopup = ({setShowButtonPopup, handleUseCustomSchedule}) => {
  return (
    <div className="fixed inset-0 z-[99] flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-md transition duration-500">
      <div className="relative bg-white p-8 shadow-lg w-[490px]"
      style={{
          background:
            "transparent linear-gradient(90deg, #FFFFFF 0%, #D6D8FF 100%) 0% 0% no-repeat padding-box",
          borderRadius: "29px",
          opacity: 1,
        }}>
        <div className="absolute top-2 right-2">
          <img
            className="absolute right-3 top-[15px] cursor-pointer w-[30px] h-[30px]"
            src={require("./TimeTable/Assets/Cross.svg").default}
            alt=""
            onClick={() => setShowButtonPopup(false)}
          />
        </div>
        
        <div className='flex justify-center w-full items-center pb-[5px] '>
            <div className='flex flex-col justify-center items-center gap-[15px]'>
                <p className='text-[22px] font-bold text-center'>Discard Student Schedule Changes</p>
                <img
                        className="mb-4 w-[90px] h-[90px]"
                        src={require("./TimeTable/Assets/Iconmetrocancel.png")}
                        alt=""
                    />
                <p className='text-[21px] text-center '>Are you sure! You want to shift to custom schedule. Changes made on this page will be removed.</p>
                <div className='mt-[20px] w-full flex justify-center items-center gap-[20px]'>
                    <button
                    type="button"
                    className="rounded-[10px]  w-[170px]  h-[42px] text-[#4C34AD] text-[18px] "
                    style={{ border: '2px solid #4C34AD' }}
                    onClick={() => setShowButtonPopup(false)}
                    >
                        Stay on this page
                    </button>

                    <button type="button"
                    className=" rounded-[10px] bg-[#4C34AD] w-[170px]  h-[42px] text-[18px] text-[#FFFFFF] "
                    onClick={handleUseCustomSchedule}    >
                        Leave this page
                    </button>
            </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default CustomButtonPopup