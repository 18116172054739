import ClassesIndicator from "../../Components/ClassesIndicator/ClassesIndicator";
import Timetable from "./TimeTable/Timetable";
import "./Scheduler.css";
import { useSelector } from "react-redux";
import AssignScheduleTimetable from "./TimeTable/AssignScheduleTimetable";
import {useEffect, useState, useContext} from "react";
import { StudentClassesContext } from "../../context/StudentClassesContext";
import { useTimetable } from "../../context/timetableContext";
import CustomButtonPopup from "./CustomButtonPopup";
import CustomStudentButtonPopup from "./CustomStudentButton";
import { useCourse } from "../../context/courseHoursContext";
import StudentTimeTableClassesindicator from "../ClassesIndicator/StudentTimetableClassesIndicator";

function ClassScheduler({
  Name,
  TopBtn,
  BottomComp,
  setEvents,
  setPrefrredTimeTable,
  PrefrredTimeTable,
  Events,
  Edit,
  Resources,
  studentSchedule,
  timeTable,
  StudentEnrollmentSelectedTimeTable,
  selectedDate,
  setSelectedDate,
  InsEvent,
  ClassType,
  setNewUpdatedEvents,
  StudentName,
}) {
  const { drivingHours, setDrivingHours, inClassHours, setInClassHours, onlineHours, setOnlineHours } = useCourse();
  const { studentClassesScheduleAppointments, setStudentClassesScheduleAppointments } = useContext(StudentClassesContext);
  const [saveStudentTimeTableContext, setSaveStudentTimeTableContext] = useState([]);
  const { userSelectedDbTimetable } = useTimetable();
  const [newRefreshedEvents, setNewRefreshedEvents] = useState([]);
  const [showButtonPopup, setShowButtonPopup] = useState(false);
  const [showStudentButton, setShowStudentButton] = useState(false);
  const [showStudentButtonPopup, setStudentShowButtonPopup] = useState(false);
  const [showUpdateScheduleButton, setShowUpdateScheduleButton] = useState(false);


  const [classHoursData, setClassHoursData] = useState({
    DayandHoursData: {
        Schedule: {
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
        },
    },
});

const [showOwnTimeFields, setShowOwnTimeFields] = useState(false);


    const {CoursePackage } = useSelector(
        (Store) => Store.StudentReducer
      );

      const updatePrefrredTimeTable = () => {
        setPrefrredTimeTable([...Events]);
    };

    const convertTo24Hour = (time) => {
      const [timePart, modifier] = time.split(' ');
      let [hours, minutes] = timePart.split(':').map(Number);
    
      if (modifier === 'PM' && hours < 12) hours += 12;
      if (modifier === 'AM' && hours === 12) hours = 0;
    
      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');
    
      return `${formattedHours}:${formattedMinutes}`;
    };
    
      const getNextMonday = (startFromDate) => {
        const today = startFromDate || new Date(); // Use the passed date or default to today
        const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
    
        // Calculate how many days to add to get to next Monday (if it's Sunday, it will be 1, etc.)
        const daysUntilNextMonday = (9 - dayOfWeek) % 7 || 7; // If it's Monday, keep it as Monday
    
        // Create a new Date object set to the next Monday
        const nextMonday = new Date(today);
        nextMonday.setDate(today.getDate() + daysUntilNextMonday);
        nextMonday.setHours(0, 0, 0, 0); // Set time to start of the day
    
        return nextMonday;
      };
    
      const getMondayOfWeek = (date) => {
        const selectedDate = new Date(date);
        const dayOfWeek = selectedDate.getDay(); // 0 (Sunday) to 6 (Saturday)
      
        // Calculate the Monday of the current week, adjusting for Sunday (0)
        const daysToSubtract = (dayOfWeek === 0 ? 6 : dayOfWeek - 2); // If it's Sunday (0), subtract 6, otherwise subtract (dayOfWeek - 1)
        
        const monday = new Date(selectedDate);
        monday.setDate(selectedDate.getDate() - daysToSubtract);
        monday.setHours(0, 0, 0, 0); // Set time to start of the day
      
        return monday;
      };
    
    // Helper function to parse time range and assign it to the correct date
    const parseTimeRange = (day, timeRange, selectedDate) => {
      const [startTime, endTime] = timeRange.split(' - ');
    
      let startOfWeek;
      
      // If a date is selected, calculate the Monday of that week's date
      if (selectedDate) {
        startOfWeek = getMondayOfWeek(selectedDate); // Get the Monday of the selected date's week
      } else {
        startOfWeek = getNextMonday(); // Fallback to next Monday
      }
    
      // Get day offset from Monday to the correct day of the week
      const daysOfWeek = { Monday: 0, Tuesday: 1, Wednesday: 2, Thursday: 3, Friday: 4, Saturday: 5, Sunday: 6 };
      const dayOffset = daysOfWeek[day];
    
      // Create a new Date object for the specific day (starting from that week's Monday)
      const eventDate = new Date(startOfWeek);
      eventDate.setDate(startOfWeek.getDate() + dayOffset); // Adjust to the correct weekday
    
      // Format the date as 'YYYY-MM-DD'
      const formattedDate = eventDate.toISOString().split('T')[0];
    
      // Create start and end Date objects by combining the correct date with the time
      const startDate = new Date(`${formattedDate}T${convertTo24Hour(startTime)}:00`);
      const endDate = new Date(`${formattedDate}T${convertTo24Hour(endTime)}:00`);
    
      return { startDate, endDate };
    };
    
    // Transform class hours data to associate the correct date with each event
    const transformClassHoursData = async (ScheduleTimeTable, selectedDate, idSuffix) => {
      console.log("ScheduleTimeTable in course instructor is:",ScheduleTimeTable)
      const dayMappings = {
        Monday: 'Monday',
        Tuesday: 'Tuesday',
        Wednesday: 'Wednesday',
        Thursday: 'Thursday',
        Friday: 'Friday',
      };
    
      const transformedData = [];
    
      // Loop over each day
      for (const day in dayMappings) {
        const slots = ScheduleTimeTable[day];
    
        // Only process days that have time slots (i.e., non-empty arrays)
        if (slots && slots.length > 0) {
          slots.forEach((slot, index) => {
            // Parse the time range and associate with the correct date for the given day
            const { startDate, endDate } = parseTimeRange(day, slot, selectedDate);
    
            if (startDate && endDate) {
              const eventData = {
                allDay: false,
                description: `${day} Class`, // You can modify this description as needed
                end: endDate.toLocaleString(),  // Use local string instead of ISO (to keep local time)
                endDate: endDate,  // Date object for end time
                event_id: `${Date.now()}-${Math.floor(Math.random() * 10000)}-${idSuffix}`, // Unique identifier with suffix            start: startDate.toLocaleString(), // Use local string instead of ISO (to keep local time)
                startDate: startDate, // Date object for start time
                text: '', // Optional text description
              };
    
              transformedData.push(eventData);
            }
          });
        }
      }
    
      return transformedData;
    };
  
    useEffect(() => {
      const transformAndSetData = async () => {
          try {
              // Check if there is data in the schedule (any day has non-empty data)
              const scheduleData = classHoursData.DayandHoursData.Schedule;
              const hasData = Object.values(scheduleData).some(day => day.length > 0);
  
              if (hasData && showOwnTimeFields === false && timeTable === "assignSchedule") {
                  console.log("classHoursData.DayandHoursData.Schedule data is:", scheduleData);
  
                  const transformedPreferredData = await transformClassHoursData(scheduleData, selectedDate, 'preferred');
                  console.log("Transformed preferred data in scheduler:", transformedPreferredData);
  
                 
                      setEvents(transformedPreferredData);  // Update events state
                      setPrefrredTimeTable(transformedPreferredData)
                      // setNewRefreshedEvents(transformedPreferredData);  // Update refreshed events
              }
          } catch (error) {
              console.error("Error transforming or setting data:", error);
          }
      };
      transformAndSetData();
  }, [classHoursData.DayandHoursData.Schedule, showOwnTimeFields]);

  const handleSaveSchedule = () => {
    // Add the current Events to the context state
    setStudentClassesScheduleAppointments(prevData => {
      const updatedData = [...prevData, ...saveStudentTimeTableContext]; // Combine previous data with new Events
      console.log("Updated Appointments in handle save:", updatedData); // Log the updated state
      return updatedData; // Update the state with combined data
    });

  };

  const handleUseStudentSchedule = () => {
    // Set the preferred timetable to the context value
    console.log("user selected db timetable is:",userSelectedDbTimetable)
    setEvents(userSelectedDbTimetable)
    setNewRefreshedEvents(userSelectedDbTimetable)
    setPrefrredTimeTable(userSelectedDbTimetable)
    setShowStudentButton(false)
    setStudentShowButtonPopup(false)
  };

  const handleUseCustomSchedule = () => {
    setShowButtonPopup(false)
    setShowOwnTimeFields(true);
    setShowStudentButton(true)
  };

  useEffect(() => {
    
    // Function to check if required classes are scheduled
      const checkScheduledClasses = () => {
        if(CoursePackage){
        const { DrivingHours, InClassHours, OnlineHours } = CoursePackage;

        // Update the context state
        setDrivingHours(DrivingHours || 0);
        setInClassHours(InClassHours || 0);
        setOnlineHours(OnlineHours || 0);

      // Define the class types required based on the course package
      const classTypesToCheck = [];
      if (DrivingHours && DrivingHours > 0) {
      classTypesToCheck.push('Driving');
      }
      if (InClassHours && InClassHours > 0) {
      classTypesToCheck.push('InClass');
      }
      if (OnlineHours && OnlineHours > 0) {
      classTypesToCheck.push('Online');
      }

      // Check if the student's schedule contains all the required class types
      const allClassesScheduled = classTypesToCheck.every(classType =>
      studentClassesScheduleAppointments.some(appointment => appointment.ClassType === classType)
      );

      // If all required classes are scheduled, show the update button
      if (allClassesScheduled) {
      setShowUpdateScheduleButton(true);
      } else {
      setShowUpdateScheduleButton(false);
      }
      };
      }

      checkScheduledClasses(); // Run the check whenever the data changes
  }, [studentClassesScheduleAppointments]); // Dependency array

  


  return (
    <>
    <div className="TimeTableContainer relative top-[-113px] lg:top-[-69px] ">
      <div className="flex h-fit  flex-col items-center justify-start gap-7 bg-white px-0 sm:w-[87%] w-full sm:px-1 md:px-1 lg:px-6 xl:px-7 2xl:px-8">
        <div className="flex w-full justify-between">
          {/* <h3 className="text-xs font-normal text-[#4D4F5C]">{Name}</h3> */}
        </div>

        <div className="flex w-full flex-col items-center justify-between gap-5 lg:flex-row">
          <div className="w-full lg:w-[100%]">
          {timeTable === "assignSchedule" ?
            <StudentTimeTableClassesindicator
            text="sm:text-5xs md:text-4xs xl:text-3xs"
            CoursePackage={CoursePackage}
            timeTable={timeTable}
            Indicator="assignSchedule"
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setShowOwnTimeFields={setShowOwnTimeFields}
            showStudentButton={showStudentButton}
            StudentName={StudentName}
             /> : 
             <ClassesIndicator 
            text="sm:text-5xs md:text-4xs xl:text-3xs"
            CoursePackage={CoursePackage}
            timeTable={timeTable}
            Indicator="assignSchedule"
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setShowOwnTimeFields={setShowOwnTimeFields}
            showStudentButton={showStudentButton}
             />
          }
          </div>

          <div className="flex w-[55%]  flex-col items-center justify-between gap-3 sm:flex-row">
            {/* <p className="top-5 whitespace-nowrap text-center text-3xs text-[#4D4F5C] md:text-2xs lg:relative lg:text-xs xl:text-base">
                        </p> */}
          
            <div>{TopBtn && <TopBtn />} </div>
          </div>
        </div>

        <div className="w-full overflow-x-scroll">
          <div className="w-full min-w-[350px] ">
            {timeTable === "assignSchedule" ? <AssignScheduleTimetable  Events={Events}
                setEvents={setEvents}
                Edit={Edit}
                Resources={Resources}
                studentSchedule={studentSchedule}
                CoursePackage={CoursePackage}
                StudentEnrollmentSelectedTimeTable={StudentEnrollmentSelectedTimeTable}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                InsEvent={InsEvent}
                setPrefrredTimeTable={setPrefrredTimeTable}
                setShowOwnTimeFields={setShowOwnTimeFields}
                showOwnTimeFields={showOwnTimeFields}
                setClassHoursData={setClassHoursData}
                classHoursData={classHoursData}
                saveStudentTimeTableContext={saveStudentTimeTableContext}
                setSaveStudentTimeTableContext={setSaveStudentTimeTableContext}
                setNewRefreshedEvents={setNewRefreshedEvents}
                /> :  <Timetable
                Events={Events}
                setEvents={setEvents}
                Edit={Edit}
                Resources={Resources}
                studentSchedule={studentSchedule}
                setPrefrredTimeTable={setPrefrredTimeTable}
                PrefrredTimeTable={PrefrredTimeTable}
                updatePrefrredTimeTable={updatePrefrredTimeTable}
                ClassType={ClassType}
                setNewUpdatedEvents={setNewUpdatedEvents}
              />}
             
    
          </div>
        </div>
       

        
      </div>

     
    </div>
    
    {BottomComp && !showUpdateScheduleButton &&
    <div className="flex w-full justify-center items-center gap-[15px]">
      {showStudentButton &&
        <button
              type="button"
              className="rounded-[10px] font-poppinssans w-[232px]  h-[48px] 2.5xl:h-[50px] text-[#4C34AD] text-[20px] 2.5xl:text-[24px]"
              style={{ border: '2px solid #4C34AD' }}
              onClick={() => setStudentShowButtonPopup(true)}
              // onClick={handleUseStudentSchedule}
            >
              Student Schedule
        </button>
      }

      {!showStudentButton &&
        <button
              type="button"
              className="rounded-[10px] font-poppinssans w-[232px]  h-[48px] 2.5xl:h-[50px] text-[#4C34AD] text-[20px] 2.5xl:text-[24px]"
              onClick={() => setShowButtonPopup(true)}
              style={{ border: '2px solid #4C34AD' }}
               // onClick={handleUseCustomSchedule}
            >
             Custom Schedule
        </button>
      }

        <button type="button"
              className="rounded-[10px] bg-[#4C34AD] font-poppinssans w-[282px]  h-[48px] 2.5xl:h-[50px] text-[20px] 2.5xl:text-[24px] text-[#FFFFFF] px-[10px]"
                onClick={handleSaveSchedule} // Save schedule on click
                >
            Save Schedule
        </button>
    </div>
    }

    {showButtonPopup &&
    <CustomButtonPopup 
    setShowButtonPopup={setShowButtonPopup}
    handleUseCustomSchedule={handleUseCustomSchedule} />
    }

    {showStudentButtonPopup &&
    <CustomStudentButtonPopup
    setStudentShowButtonPopup={setStudentShowButtonPopup}
    handleUseStudentSchedule={handleUseStudentSchedule}
    /> }

    <div className="flex w-full justify-center ">
        {BottomComp && showUpdateScheduleButton && <BottomComp />}
    </div>
    </>
  
  );
}


export default ClassScheduler;


