import React from "react";
import { Link } from "react-router-dom";
import "./Headers.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OpenLoginSignUp } from "../../Actions/ToggleSignupA";
import OtherHeader from "./Components/Others Header/Others Header";
import StudentHeader from "./Components/StudentHeader/StudentHeader";
import { GetLocalStorage } from "../../Helpers/LocalStorage/LocalStorage";

const Headers = ({ setAuthPageName }) => {
    const [ShowSidebar, setShowSidebar] = useState(false);
    const { UserInfo } = useSelector((store) => store.LoginSignupReducer);
    const admin = UserInfo?.User === "Admin";
    const instituteUser = UserInfo?.User === "Institute";
    return (
        <header className="Header" onClick={(e) => e.stopPropagation()}>
            <div className={`HeaderDataContainer ${admin || instituteUser ? "HeaderDataContainer_2" : null}`}>
                {!GetLocalStorage("UserInfo")?.User ||
                UserInfo?.User === "Student" ? (
                    <>
                        <StudentHeader
                            ShowSidebar={ShowSidebar}
                            setShowSidebar={setShowSidebar}
                        />
                    </>
                ) : null}
                { instituteUser || admin ? (
                    <OtherHeader
                        setShowSidebar={setShowSidebar}
                        ShowSidebar={ShowSidebar}
                    />
                ) : null}
            </div>
        </header>
    );
};

export function LoginRegister() {
    const Dispatch = useDispatch();

    return (
        <nav
            id="RegisterPortion"
            className="absolute right-7 top-24 z-20 hidden w-auto items-center justify-center gap-9 lg:flex"
        >
            <Link
                className="Regularfont  text-xs text-white no-underline xl:text-2xs"
                onClick={() => Dispatch(OpenLoginSignUp("Sign In", true))}
            >
                Login
            </Link>
            <Link
                className={` Regularfont rounded-[25px] bg-[#EA52C6] text-center text-xs text-white
                                    no-underline     lg:py-3         lg:px-7
                                    xl:py-4     xl:px-10        xl:text-2xs 
                        2xl:py-5                 2xl:px-12       `}
                onClick={() => Dispatch(OpenLoginSignUp("Sign Up", true))}
            >
                Register
            </Link>
        </nav>
    );
}

export default Headers;
