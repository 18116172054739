import React, { useState } from "react";
import { useSelector } from "react-redux";

import { useHideOnClickOutside } from "../../../Helpers/CustomHooks/Hide Elements/useOutsideChecker";
import { BaseUrl } from "../../../Actions/Base";

function ProfileDropDown({ DropDownOptions, setShowSidebar }) {
  const [DropDownRef, setDropDownRef] = useState(null);
  const [RefBtn, setRefBtn] = useState(null);
  const { UserInfo } = useSelector((store) => store.LoginSignupReducer);
  const ToggleClass = (e, ClassName) => e.classList.toggle(ClassName);
  const HideOnClick = (e, cb) => {
    setShowSidebar(false);
    ToggleClass(e.target.parentNode.parentNode.parentNode, "active");
    if (cb !== null) {
      cb();
    }
  };
  // const admin = UserInfo?.User === "Admin"; const instituteUser = UserInfo?.User === "Institute";
  console.log("UserInfossss", UserInfo);
  const ImageUrl =
    (UserInfo?.Instructor &&
      `${BaseUrl}/api/images/Instructors?url=${UserInfo?.Instructor?.ProfileImage}`) ||
    (UserInfo?.Staff &&
      `${BaseUrl}/api/images/Staff?url=${UserInfo?.Staff?.ProfileImage}`) ||
      (UserInfo?.InstituteUserType === "Admin" &&
        `${BaseUrl}/api/Images/InstituteLogo?url=${UserInfo?.Institute?.InstituteLogo}`) ||
    (UserInfo?.User &&
      `${BaseUrl}/api/images/Student?url=${UserInfo?.StudentImage}`)
      
  // console.log("ImageUrl", ImageUrl)
  // let avatarImage = ImageUrl;
  // .includes("undefined")
  // avatarImage = ImageUrl.includes(null)
  // console.log("avatarImage", avatarImage)

  useHideOnClickOutside(DropDownRef, RefBtn, "active");
  return (
    <span
      className={`HeaderRightSide-LinkStyle SemiBold DropDown relative -order-1 flex cursor-pointer gap-5 lg:order-[0]`}
      onClick={(e) => ToggleClass(e.target, "active")}
      ref={(e) => {
        setRefBtn(e);
      }}
    >
      <hr className="pointer-events-none absolute -left-3 hidden h-full lg:block" />
      <span className="pointer-events-none flex items-center justify-start gap-2 xl:gap-4">
        {!ImageUrl ? (
          <img
            src={require("./Assets/AvatarIcon.svg").default}
            alt="img"
            srcSet=""
          />
        ) : (
          <img
            className="rounded-full"
            src={ImageUrl}
            width={40}
            height={40}
            // alt={UserInfo?.FirstName}
            alt="img"
          />
        )}

        <h4 className="SemiBold text-3xs">{UserInfo?.FirstName}</h4>
      </span>

      <div
        className="DropDown-Menu absolute right-10 top-14 shadow-[0px_2px_10px_#00000030] lg:right-10  lg:top-12"
        ref={(e) => setDropDownRef(e)}
      >
        <img
          className=" absolute -top-2 left-5 z-10 drop-shadow lg:right-14 lg:left-auto"
          src={require("./Assets/PointIcon.svg").default}
          alt=""
        />

        <DropDownOptions HideOnClick={HideOnClick} />
      </div>
    </span>
  );
}

export default ProfileDropDown;
