import React from "react";
import { Link } from "react-router-dom";
import { GetLocalStorage } from "../../../../../../Helpers/LocalStorage/LocalStorage";
import "./RightCards.css";
import TeacherCalender from "../../Calender/DashboardCalender";
const RightCards = ({ classTypeCounts, data }) => {
  // let AddCourse = `/addcourse`;
  // let AdminCourse = `/admin/course/add`;
  // let CourseRoute =
  //     GetLocalStorage("UserInfo").User === "Admin"
  //         ? AdminCourse
  //         : GetLocalStorage("UserInfo").User === "Institute"
  //         ? AddCourse
  //         : null;
  return (
    // <div className="min-h-[572px] w-full   rounded-[15px] border-[1px] border-solid border-[#D4D4D4] bg-white p-[27px] drop-shadow-[0px_2px_3px_rgba(0,0,0,0.04)] xs:self-center sm:self-center md:min-w-[480px] md:max-w-[480px]">
    //   <TeacherCalender data={data} />
    //   <div className="flex w-full justify-center ">
    //     <table className="border-spacing-2">
    //       <tr className="">
    //         <td className="pr-10 text-end">Practical Class</td>
    //         <td className=" bg-[#A1A3EF] px-12 py-1">
    //           {classTypeCounts?.physical}
    //         </td>
    //       </tr>
    //       <tr className="">
    //         <td className="pr-10 text-end">Online Class</td>
    //         <td className=" bg-[#7F82F9]  px-12 py-1">
    //           {classTypeCounts?.online}
    //         </td>
    //       </tr>
    //       <tr className="" style={{ margin: "2rem" }}>
    //         <td className="pr-10 text-end">In-Class</td>
    //         <td className=" bg-[#5456C7]  px-12 py-1">
    //           {classTypeCounts?.inclass}
    //         </td>
    //       </tr>
    //     </table>
    //   </div>
    // </div>
    <>
     

      
          <div className="md:min-h-[342.4px] md:h-[342.4px] h-auto self-center shadow w-[332px] md:w-[380px]   rounded-[15px] border-[1px] border-solid border-[#D4D4D4] bg-white px-[15px] pt-[20px] pb-[20px] drop-shadow-[0px_2px_3px_rgba(0,0,0,0.04)] xs:self-center sm:self-center min-w-[320px] md:min-w-[380px] md:max-w-[400px]">
            <h3
              style={{
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
              className="heading_2 font-bold text-[#4C34AD] "
            >
              Home Pickup
            </h3>

            <p className="py-4 font-segoe-ui text-7xs 2xs:text-6xs xs:text-5xs sm:text-4xs  2xl:ManageLogin">
                {/* Enable home pickup service for the student. */}
                Your upcoming class pickup is scheduled in 5 hours Click 'Start Pickup' before leaving to inform your student.
            </p>
            <div className=" flex w-full justify-center ">
                <div className="img w-[35%] pt-2">
                    <img className="w-full rotate-90" src={require("../Assets/homepickup.png")} alt="" />
                </div>
            </div>

            <div className="text-center">
                <Link to={``}>
                    <button
                        type="button"
                        class="focus:ring-blue-300 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mt-2 mb-2 cursor-pointer rounded-lg border-0 bg-[#4c34ad] px-10 py-2  text-white outline-none  hover:bg-[#6266e8] transition-all hover:scale-95 focus:outline-none focus:ring-4 text-4xs font-medium md:text-3xs"
                    >
                        {/* Pickup */}
                         Start Pickup
                    </button>
                </Link>
            </div>

          </div>
       
   
    </>
  );
};

export default RightCards;
