import React, { useEffect } from 'react'
import useAxios from '../../../Helpers/CustomHooks/useAxios';
import { BaseUrl } from '../../../Actions/Base';
import { useState } from 'react';
import { Link } from 'react-router-dom';



const DashBoardAdminCourses = () => {
    const [res, err, Loading] = useAxios("get", `/api/courses`);
    const [AdminCourses, setAdminCourses] = useState([]);

    useEffect(() => {
        if (res?.data)
            setAdminCourses(res?.data)
    }, [res])

    return (
        <div className="w-full py-5 md:py-9 xs:px-1 sm:px-2 md:px-5 lg:px-8 xl:px-11 2xl:px-14 bg-white rounded-[40px]">
            <h2 className="text-xs font-normal">Courses List</h2>

            <div className='flex px-1 xs:px-2 sm:px-4 md:px-6 lg:px-8 xl:px-10 2xl:px-12 py-6 flex-wrap gap-8 flex-col md:flex-row items-center'>
                {AdminCourses.map(value =>
                    <AdminCourseTile key={value.CoursePK} value={value} />
                )}

            </div>
        </div>
    )
}

function AdminCourseTile({ value }) {

    return <div className='w-[22%] max-w-[360px] min-w-[325px] md:min-w-[280px] 2xl:min-w-[325px] h-[466px] flex flex-col items-center px-4 py-6 shadow-[25px_21px_30px_#0000000d] rounded-2xl'>
        <div className='w-full flex flex-col items-center justify-center relative mb-10'>
            <span className='flex justify-center items-center w-11/12 h-48 overflow-hidden rounded-[20px] shadow-[15px_28px_30px_#0000000d]'>
                <img className='w-full' src={`${BaseUrl}/api/Thumbnail/course?url=${value?.CourseThumbnail}`} alt="" />
            </span>
            <Link  to={`/admin/course/update/${value?.CoursePK}`} className='text-4xs rounded-[14px] w-1/2 py-4 shadow-[15px_28px_30px_#0000000d] text-center absolute -bottom-5 z-0 no-underline duration-200 text-black hover:bg-[#e9e9e9] bg-white cursor-pointer'>
                Edit
            </Link>
        </div>


        <h3 className='text-3xs Blackfont text-[#3B3735]'>
            {value?.CourseName}
        </h3>


        <p className='text-center w-full h-[100px] break-all'>
            {value?.Description}
        </p>
        <div className='flex justify-between w-full'>
            <div>
                <div className='text-5xs text-[#A1A3EF]'>
                    License Type  : {value?.LicenseType?.LicenseTypeName}
                </div>
                <div className='text-5xs text-[#A1A3EF]'>
                    Vehicle  Type : {value?.VehicleType?.VehicleTypeName}
                </div>
            </div>
            <div className='text-[10px]'>
                {value?.SchoolsOffering}
            </div>
        </div>
    </div>
}
export default DashBoardAdminCourses