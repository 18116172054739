import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetLicenseTypes, GetVehicleTypes } from "../../Actions/CategoryA";
import {
  DeleteVehicleA,
  GetManufacturesA,
  GetVehicleA,
} from "../../Actions/VehicleA";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import NoDataResponse from "../../Components/NoDataResponse";
import useCheckLogin from "../../Helpers/CustomHooks/CheckLogin";
import AddVehicle from "./Components/AddVehicle";
import CarTiles from "./Components/CarTiles";
import Filters from "./Components/Filters";
import "./VehicleInventory.css";
import InstituteTemplate from "../../Components/InstituteTemplate/InstituteTemplate";
import { Link } from "react-router-dom";
import TeacherFilter from "../Institute/Instructors/Components/TeacherFilter";
import VehicleDelete from "./Components/VehicleDelete";
const VehicleInventoryPage = () => {
  const [FilterOpen, setFilterOpen] = useState();
  const [MaxHeight, setMaxHeight] = useState("max-h-[0px]");
  const [sortByTime, setSortByTime] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [suspend, setSuspend] = useState(false);
  const [vehicleType, setVehicleType] = useState("");
  const [vehicleLicenseType, setVehicleLicenseType] = useState("");
  const [gearType, setGearType] = useState("");
  const [types, setTypes] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [PopupDetail, setPopupDetail] = useState({});
  const Dispatch = useDispatch();
  const { Vehicles, loading } = useSelector((Store) => Store.VehicleReducer);

  const memoizedFetchData = useCallback(() => {
    Dispatch(GetVehicleA({ searchQuery, sortBy, sortByTime, types, gearType }));
  }, [Dispatch, searchQuery, sortBy, sortByTime, types, gearType]);

  useEffect(() => {
    // Dispatch(GetVehicleA({ searchQuery, sortBy, sortByTime }));
    memoizedFetchData();
    Dispatch(GetVehicleTypes());
    Dispatch(GetLicenseTypes());
  }, [Dispatch, memoizedFetchData]);
  useCheckLogin(true, ["Institute"], ["Staff", "Admin"]);

  const OnClick = useCallback(() => {
    setFilterOpen(!FilterOpen);
    setMaxHeight(FilterOpen ? "max-h-[0px]" : "max-h-[1000px]");
  }, [FilterOpen]);

  const handleApplyClick = (filters) => {
    // Get filter values from state variables
    // console.log("in handleSortBy:", filters);
    setSearchQuery(filters?.searchQuery);
    setSuspend(filters.suspend);
    setSortBy(filters.sortBy);
    setSortByTime(filters.sortByTime);
    setVehicleType(filters.vehicleType);
    setVehicleLicenseType(filters.vehicleLicenseType);
    setTypes(filters.types);
    setGearType(filters.gearType);
  };
  const handleDeleteClick = (vehicle) => {
    setPopupDetail(vehicle);
    setShowConfirmation(true);
  };

  const handleDeleteCancel = () => {
    setShowConfirmation(false);
    setPopupDetail(null);
  };

  const handleDeleteConfirm = () => {
    if (PopupDetail) {
      Dispatch(DeleteVehicleA(PopupDetail.VehicleId))
        .then(() => {
          Dispatch(
            GetVehicleA({
              searchQuery,
              sortBy,
              sortByTime,
              types,
              gearType,
            })
          );
        })
        .catch((error) => {
          console.error("Error deleting vehicle:", error);
        });
    }
    setShowConfirmation(false);
    setPopupDetail(null);
  };

  const handleViewDetail = (VehicleId) => {
    if (VehicleId) {
      Dispatch(DeleteVehicleA(VehicleId));
      // Dispatch(
      //     GetVehicleA({
      //         searchQuery,
      //         sortBy,
      //         sortByTime,
      //         types,
      //         gearType,
      //     })
      // );
    }
  };
  return (
    <div className="px-5 py-10">
      {showConfirmation && (
        <VehicleDelete
          PopupDetail={PopupDetail}
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
        />
      )}
      <h2
        style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
        className="text-xs font-bold text-purpal sm:text-sm md:text-base lg:text-[29px] xl:text-lg 2xl:text-[40px]"
      >
        Vehicle inventory
      </h2>
      <div className="px-10">
        <div
          className={`order-1 flex w-full flex-col gap-12    
      lg:pr-5        
      `}
        >
          {/* <AddVehicle /> */}
          <div className="gradientAddvehicleColor mt-10 max-h-[472px] w-[380px]  rounded-[15px] border-[1px] border-solid border-[#D4D4D4] bg-white px-5 pt-[37px] drop-shadow-[0px_2px_3px_rgba(0,0,0,0.04)] xs:self-center sm:self-center md:min-w-[480px] md:max-w-[480px] md:self-start">
            <h3
              style={{
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
              className="xl:text-md text-xs font-bold text-[#4C34AD] sm:text-sm md:text-base lg:text-[29px] 2xl:text-lg"
            >
              Add a New Vehicle
            </h3>

            {/* <p className="pt-4 text-7xs 2xs:text-6xs xs:text-5xs sm:text-4xs md:text-3xs lg:text-2xs xl:text-sm 2xl:text-base">
                            Want to add a new vehicle to the inventory? Click
                            Add a new Vehicle.
                        </p> */}
            <p className="text-wrap inline-block w-full pt-2 text-xs font-normal tracking-wide  text-black ">
              Want to add a new vehicle to the inventory? Click Add a new
              Vehicle.
            </p>

            <div className="flex justify-center">
              <div className=" flex w-[80%] justify-center ">
                <div className="img w-full">
                  <img
                    height={"200px"}
                    width={"100%"}
                    src={require("./Assets/addNewVehicle (1).png")}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="text-center">
              <Link to="/vehicle/add">
                <button
                  type="button"
                  class="focus:ring-blue-300 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800  mb-2 cursor-pointer rounded-lg border-0 bg-[#4c34ad] px-10 py-2 text-4xs font-medium text-white outline-none hover:bg-[#a1a3ef] focus:outline-none focus:ring-4 md:text-2xs lg:text-[22px]"
                >
                  Add Vehicle
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex justify-end  ">
          <div
            className="gradientAddvehicleColor InstructorPageBtns relative  inline-block shadow-md  "
            onClick={OnClick}
            onMouseEnter={() => setMaxHeight("max-h-[1000px]")}
            onMouseLeave={() => setMaxHeight("max-h-[0px]")}
          >
            Filter Vehicle
            <TeacherFilter
              handleApplyClick={handleApplyClick}
              setSortBy={setSortBy}
              setSortByTime={setSortByTime}
              setSearchQuery={setSearchQuery}
              setSuspend={setSuspend}
              sortBy={sortBy}
              sortByTime={sortByTime}
              searchQuery={searchQuery}
              suspend={suspend}
              vehicleLicenseType={vehicleLicenseType}
              setVehicleLicenseType={setVehicleLicenseType}
              vehicleType={vehicleType}
              setVehicleType={setVehicleType}
              title={"Filter vehicles"}
              MaxHeight={MaxHeight}
              type="VehicleList"
              types={types}
              setTypes={setTypes}
              gearType={gearType}
              setGearType={setGearType}
            />
          </div>
        </div>
        <div
          className={`relative flex w-full flex-col gap-10 rounded-lg border-[1px] border-solid border-[#D4D4D4]  bg-[#F7F7F7] px-5 py-10 sm:flex-row sm:gap-0`}
        >
          <div
            className={`gradientAddvehicleColor borderRad element-with-drop-shadow order-2 flex
        w-full   
        flex-col              flex-wrap
        items-center  justify-center  gap-10
        px-1      py-5     sm:order-1   sm:px-2   md:flex-row md:items-start    md:px-3 
        lg:px-4 xl:px-5  2xl:px-6
      `}
          >
            {loading ? (
              <div className="flex w-full items-center justify-center md:h-[50vh]">
                <LoadingSpinner />
              </div>
            ) : Vehicles?.length === 0 ? (
              <div className="mx-auto">
                <NoDataResponse
                  topText="Sorry, there are no vehicles to show!"
                  bottomText="Admin/Institute can add vehciles by click on 'Add a New Vehicle' button."
                />
              </div>
            ) : (
              <>
                {/* <Filters /> */}
                {Vehicles?.length &&
                  Vehicles?.map((Vehicle) => (
                    <CarTiles
                      onDelete={() => handleDeleteClick(Vehicle)}
                      key={Vehicle.VehicleId}
                      Vehicle={Vehicle}
                      handleViewDetail={handleViewDetail}
                      showConfirmation={showConfirmation}
                      setShowConfirmation={setShowConfirmation}
                    />
                  ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const VehicleInventory = () => (
  <InstituteTemplate Element={VehicleInventoryPage} />
);
export default VehicleInventory;
