// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider} from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyC1dXoLs-mskeNhgafPB8WPhxkP6PF6mVo",
  authDomain: "dummy-429c7.firebaseapp.com",
  projectId: "dummy-429c7",
  storageBucket: "dummy-429c7.appspot.com",
  messagingSenderId: "372667694397",
  appId: "1:372667694397:web:285b1aeaaf10f650191954"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const provider = new GoogleAuthProvider()

export {auth, provider}


// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyC1dXoLs-mskeNhgafPB8WPhxkP6PF6mVo",
//   authDomain: "dummy-429c7.firebaseapp.com",
//   projectId: "dummy-429c7",
//   storageBucket: "dummy-429c7.appspot.com",
//   messagingSenderId: "372667694397",
//   appId: "1:372667694397:web:285b1aeaaf10f650191954"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);