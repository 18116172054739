
 const defaultWeekTiming = [
    {
      name: "Monday",
      isOpen: true,
      time: {
        start: "09:00",
        end: "17:00",
      },
    },
    {
      name: "Tuesday",
      isOpen: true,
      time: {
        start: "09:00",
        end: "17:00",
      },
    },
    {
      name: "Wednesday",
      isOpen: true,
      time: {
        start: "09:00",
        end: "17:00",
      },
    },
    {
      name: "Thursday",
      isOpen: true,
      time: {
        start: "09:00",
        end: "17:00",
      },
    },
    {
      name: "Friday",
      isOpen: true,
      time: {
        start: "09:00",
        end: "17:00",
      },
    },
    {
      name: "Saturday",
      isOpen: false,
      time: {
        start: "09:00",
        end: "17:00",
      },
    },
    {
      name: "Sunday",
      isOpen: false,
      time: {
        start: "09:00",
        end: "17:00",
      },
    },
  ];
  
  const defaultWeekClasses = [
    {
      day: "Monday",
      timetable: [
        { period: 1, start: "09:00", end: "09:59" },
        { period: 2, start: "10:00", end: "10:59" },
        { period: 3, start: "11:00", end: "11:59" },
        { period: 4, start: "12:00", end: "12:59" },
        { period: 5, start: "13:00", end: "13:59" },
      ],
    },
    {
      day: "Tuesday",
      timetable: [
        { period: 1, start: "09:00", end: "09:59" },
        { period: 2, start: "10:00", end: "10:59" },
        { period: 3, start: "11:00", end: "11:59" },
        { period: 4, start: "12:00", end: "12:59" },
        { period: 5, start: "13:00", end: "13:59" },
      ],
    },
    {
      day: "Wednesday",
      timetable: [
        { period: 1, start: "09:00", end: "09:59" },
        { period: 2, start: "10:00", end: "10:59" },
        { period: 3, start: "11:00", end: "11:59" },
        { period: 4, start: "12:00", end: "12:59" },
        { period: 5, start: "13:00", end: "13:59" },
      ],
    },
    {
      day: "Thursday",
      timetable: [
        { period: 1, start: "09:00", end: "09:59" },
        { period: 2, start: "10:00", end: "10:59" },
        { period: 3, start: "11:00", end: "11:59" },
        { period: 4, start: "12:00", end: "12:59" },
        { period: 5, start: "13:00", end: "13:59" },
      ],
    },
    {
      day: "Friday",
      timetable: [
        { period: 1, start: "09:00", end: "09:59" },
        { period: 2, start: "10:00", end: "10:59" },
        { period: 3, start: "11:00", end: "11:59" },
        { period: 4, start: "12:00", end: "12:59" },
        { period: 5, start: "13:00", end: "13:59" },
      ],
    },
    {
      day: "Saturday",
      timetable: [
        { period: 1, start: "09:00", end: "09:59" },
        { period: 2, start: "10:00", end: "10:59" },
        { period: 3, start: "11:00", end: "11:59" },
        { period: 4, start: "12:00", end: "12:59" },
        { period: 5, start: "13:00", end: "13:59" },
      ],
    },
    {
      day: "Sunday",
      timetable: [
        { period: 1, start: "09:00", end: "09:59" },
        { period: 2, start: "10:00", end: "10:59" },
        { period: 3, start: "11:00", end: "11:59" },
        { period: 4, start: "12:00", end: "12:59" },
        { period: 5, start: "13:00", end: "13:59" },
      ],
    },
  ];

export const getWeekTiming=()=>defaultWeekTiming;
export const getWeekClasses=()=>defaultWeekClasses;