import React from "react";
import { BuyCourse } from "./Components/BuyCourse";
import { TitleAndDetail } from "./Components/TitleAndDetail";
import "./CourseIntro.css";

const CourseIntro = ({ CourseInfo, downloadCurriculum }) => {
  return (
    <div className=" flex flex-col items-center bg-bgPurpal ">
      <div className="h-[2px] w-full bg-white"></div>
      <h2 className="h-[72px] content-center text-center font-segoe-ui text-3xs text-white md:text-base xl:text-lg">
        Course Details
      </h2>
      <div className="h-[2px] w-full bg-white"></div>

      <div
        className={`flex h-auto lg:h-[416px] w-full
             flex-col 
             md:flex-row 
             overflow-hidden	
            `}
      >
        <div className=" pt-[35px] pb-[18px] px-0 sm:px-[40px] flex w-full md:w-[80%] lg:w-[60%] flex-col  ">
      
            <TitleAndDetail CourseInfo={CourseInfo} />
            <div className="flex lg:hidden w-full justify-center mt-4">
               <img className="w-[70%] sm:w-[60%]" src={require("./Assets/rectangle.png")} alt="img" />
            </div>
            <BuyCourse CourseInfo={CourseInfo} downloadCurriculum={downloadCurriculum} />
      
        </div>

        <div className=" relative hidden lg:flex bg-bgPurpal h-full w-[40%]" >
          <img
            className="absolute right-0 left-[-21px] h-full w-[120%]"
            src={require("./Assets/diagonalImage.png")}
            alt="img"
           
          />
        </div>
      </div>
    </div>
  );
};

function ExpandOnClickPart() {
  return (
    <div
      className={`flex w-full flex-row items-center justify-between gap-2
                            px-4    py-6 
                            sm:block  sm:w-fit 
    `}
    >
      <p className="SemiBold w-fit  text-4xs text-white sm:hidden sm:text-2xs">
        22,000 Students already enrolled
      </p>

      <span className="relative hidden w-fit flex-col items-center text-[#BBAEFF] sm:flex">
        <img
          className="absolute -left-10 top-2"
          src={require("./Assets/TimeIcon.svg").default}
          alt=""
        />
        <h3 className="whitespace-nowrap text-xs font-normal">Opening Hours</h3>
        <h5 className="text-5xs font-normal text-white">
          (Click here to expand)
        </h5>
      </span>

      <span className="flex w-fit flex-col items-center gap-2">
        <h2 className="SemiBold text-4xs text-white sm:text-[33px]">
          Offered By
        </h2>
        <img
          className="w-[70px] sm:w-auto"
          src={require("./Assets/Institute Logo.png")}
          alt=""
        />
      </span>
    </div>
  );
}

export default CourseIntro;
