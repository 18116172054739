import React, { useContext } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetInstituteInstructorsA } from "../../../../Actions/InstructorA";
import { BaseUrl } from "../../../../Actions/Base";
import { useState } from "react";
import { SocketContext } from "../../../../App";
import { calculateLastActiveTime } from "../../Helpers/utils";

function InstructorContacts({
    setCurrentSelected,
    online,
    search,
    unreadMessages,
    handleReadMessage,
    lastseen,
}) {
    const [selectedContact, setSelectedContact] = useState(undefined);
    const Dispatch = useDispatch();
    const socket = useContext(SocketContext);

    const { Instructors, loading } = useSelector(
        (store) => store.InstructorReducer
    );

    useEffect(() => {
        // Define dummy filters with default values
        const dummyFilters = {
            sortByTime: "asc", // or "desc" depending on your needs
            sortBy: "name", // or any other field you want to sort by
            searchQuery: search || "", // Use search prop or an empty string
            suspend: "false", // or any other value required
        };

        // Call the action with the dummy filters
        Dispatch(GetInstituteInstructorsA(dummyFilters));
       /*  Dispatch(GetInstituteInstructorsA()); */
    }, [Dispatch]);

    console.log("Instructors from Redux:", Instructors);
    console.log("Instructors from Redux users are:", Instructors.user);


    const changeCurrentChat = (index, contact) => {
        setSelectedContact(index);
        setCurrentSelected(contact);

        if (unreadMessages[contact?.UserId] > 0) {
            // Call the ReadMessage function to handle read event
            handleReadMessage(contact?.UserId);
        }
    };

 /*    const filteredInstructors = Instructors?.filter((instructor) =>
        instructor?.FirstName?.toLowerCase().includes(search.toLowerCase())
    ); */

    // Correct filtering using the nested user object
    const filteredInstructors = Instructors?.filter((instructor) =>
        instructor?.user?.FirstName?.toLowerCase().includes(search.toLowerCase())
    );


    return (
        <div>
            {filteredInstructors &&
                filteredInstructors?.map((item, index) => {
                    {/* const ImageUrl = `${BaseUrl}/api/images/Instructors?url=${item?.Instructor?.ProfileImage}`; */}
                    const ImageUrl = item?.user?.Instructor?.ProfileImage
                        ? `${BaseUrl}/api/images/Instructors?url=${item?.user?.Instructor?.ProfileImage}`
                        : require("../../assets/AvatarIcon.svg").default;

                    const unreadCount = unreadMessages[item?.user?.UserId] || 0;

                    const lastSeen =
                        lastseen &&
                        lastseen?.find((user) => user?.id === item?.user?.UserId);

                    const lastActive = lastSeen ? lastSeen.time : null;
                    const lastActiveTimestamp = new Date(lastActive).getTime(); // Convert ISO timestamp to Unix timestamp (milliseconds)

                    const isOnline = online?.includes(item?.user?.UserId);

                    return (
                        <div
                            key={index}
                            onClick={() => changeCurrentChat(index, item)}
                            className={
                                selectedContact === index
                                    ? "  flex h-[106px]  cursor-pointer items-center justify-between  border   border-l-0 border-t-0 border-solid border-[#F1F1F3] bg-[#F5F6FA] p-2  "
                                    : "  flex h-[106px] cursor-pointer items-center justify-between   border   border-l-0 border-t-0 border-solid border-[#F1F1F3] p-2 hover:bg-[#F5F6FA]  "
                            }
                        >
                            <div className="ml-[15px] lg:ml-[40px] flex items-center  ">
                                <div className="relative  ">
                                    {ImageUrl ? (
                                        <img
                                            className="rounded-full"
                                            src={ImageUrl}
                                            alt="img"
                                            width={60}
                                            height={60}
                                        />
                                    ) : (
                                        <img
                                            className="rounded-full"
                                            src={
                                                require("../../assets/AvatarIcon.svg")
                                                    .default
                                            }
                                            alt=""
                                            srcSet=""
                                            width={60}
                                            height={60}
                                        />
                                    )}
                                    {isOnline ? (
                                        <div className="absolute bottom-3 right-0 z-10 h-4 w-4 rounded-full bg-[#22c55e]"></div>
                                    ) : (
                                        <div className="absolute bottom-3 right-0 z-10 h-4 w-4 rounded-full bg-[#c56922]"></div>
                                    )}
                                </div>

                                <div className="ml-5 flex flex-col gap-3">
                                    <h4 className="  text-sm font-semibold  text-[#4D4F5C]">
                                        {item?.user?.FirstName} {item?.user?.LastName}
                                    </h4>
                                    <p className="  ">
                                        {!isOnline && lastActiveTimestamp
                                            ? calculateLastActiveTime(
                                                  lastActiveTimestamp
                                              )
                                            : ""}
                                    </p>
                                </div>
                            </div>
                            <div>
                                {unreadCount > 0 && (
                                    <div className=" flex h-12 w-12 items-center justify-center rounded-full bg-[#22c55e] text-white">
                                        <p className="text-[25px] font-semibold">
                                            {unreadCount}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
        </div>
    );
}

export default InstructorContacts;
