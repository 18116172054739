import React, { useState } from "react";
import DropDown from "../../../../Components/custom-drop-down/DropDown";

const VehicleFilterArr = ({
    setSortBy,
    setSortByTime,
    setSearchQuery,
    setSuspend,
    suspend,
    sortByTime,
    sortBy,
    searchQuery,
    handleApplyClick,
    vehicleType,
    setVehicleType,
    vehicleLicenseType,
    setVehicleLicenseType,
    types,
    gearType,
    setTypes,
    setGearType,
}) => {
    const [filtersApplied, setFiltersApplied] = useState(false); // New state t
    // console.log("Data", data);
    const IconSrc = require("../Assets/DropDown.svg").default;
    // const [status, setStatus] = useState(null);
    // const [sortByDateModified, setSortByDateModified] = useState(null);
    // const [filterByAlphabeticOrder, setFilterByAlphabeticOrder] =

    //     useState(null);

    // const [status, setStatus] = useState(false);
    // const [sortByDateModified, setSortByDateModified] = useState("newest");
    // const [filterByAlphabeticOrder, setFilterByAlphabeticOrder] =
    //     useState("a_to_z");

    const vehicleLicenseTypeOptions = [
        { value: "License Type G", label: "License Type G" },
        { value: "License Type L", label: "License Type L" },
        { value: "License Type M", label: "License Type M" },
    ];
    const typesOptions = [
        { value: "Petrol", label: "Petrol" },
        { value: "Hybrid", label: "Hybrid" },
        { value: "Electric", label: "Electric" },
    ];

    const GearOptions = [
        { value: "Manual", label: "Manual" },
        { value: "Auto", label: "Auto" },
        { value: "Semi-Auto", label: "Semi-Auto" },
    ];

    const sortByDateModifiedOptions = [
        { value: "newest", label: "New to Old" },
        { value: "oldest", label: "Old to New" },
    ];

    const filterByAlphabeticOrderOptions = [
        { value: "atoz", label: "A to Z" },
        { value: "ztoa", label: "Z to A" },
    ];

    const handleTypeChange = (value) => {
        // console.log("setsuspendtriggered", value);
        setTypes(value);
    };
    const handleGearTypeChange = (value) => {
        setGearType(value);
    };

    const handleSortByDateModifiedChange = (value) => {
        // console.log("setModifiedDaa", value);

        // setSortByDateModified(value);
        setSortByTime(value);
    };

    const handleFilterByAlphabeticOrderChange = (value) => {
        // setFilterByAlphabeticOrder(value);
        setSortBy(value);
    };

    const handleSearch = (e) => {
        // e.preventDefault();
        setSearchQuery(e.target.value);
    };

    return (
        <>
            <div className="">
                {/* <h4 className="text-left text-4xs font-normal text-[#22232B]">
                    Status
                </h4> */}
                <input
                    type="text"
                    placeholder="Search by Model Name"
                    className="TeacherFilterFieldsBorder w-full p-2 text-5xs"
                    onChange={handleSearch}
                    value={searchQuery}
                />
            </div>
            <span className="flex w-full flex-col">
                {/* <h4 className=" text-left text-4xs font-normal text-[#22232B]">
                    Status
                </h4> */}
                <DropDown
                    Label="Type"
                    DropDownOptions={typesOptions}
                    // Name="Status"
                    TextStyle="text-left text-[#22232B] text-5xs TeacherFilterFieldsBorder"
                    styles="w-full min-w-[275px]"
                    IconSrc={IconSrc}
                    onChange={handleTypeChange}
                    // StateValue={suspend}
                    StateValue={types}
                />
            </span>
            <span className="flex w-full flex-col">
                {/* <h4 className=" text-left text-4xs font-normal text-[#22232B]">
                    Status
                </h4> */}
                <DropDown
                    Label="Transmission"
                    DropDownOptions={GearOptions}
                    // Name="Status"
                    TextStyle="text-left text-[#22232B] text-5xs TeacherFilterFieldsBorder"
                    styles="w-full min-w-[275px]"
                    IconSrc={IconSrc}
                    onChange={handleGearTypeChange}
                    // StateValue={suspend}
                    StateValue={gearType}
                />
            </span>
            <span className="flex w-full flex-col">
                {/* <h4 className=" text-left text-4xs font-normal text-[#22232B]">
                    Sort by Date Modified
                </h4> */}
                <DropDown
                    Label="New or Old"
                    DropDownOptions={sortByDateModifiedOptions}
                    // Name="DateModified"
                    TextStyle="text-left text-[#22232B] text-5xs TeacherFilterFieldsBorder"
                    styles="w-full min-w-[275px]"
                    IconSrc={IconSrc}
                    onChange={handleSortByDateModifiedChange}
                    StateValue={sortByTime}
                />
            </span>
            <span className="flex w-full flex-col">
                <DropDown
                    Label="Filter by Alphabetic Order"
                    DropDownOptions={filterByAlphabeticOrderOptions}
                    // Name="AlphabeticalOrder"
                    // Name="Filter by Alphabetic Order"
                    TextStyle="text-left text-[#22232B] text-5xs TeacherFilterFieldsBorder"
                    styles="w-full min-w-[275px]"
                    IconSrc={IconSrc}
                    onChange={handleFilterByAlphabeticOrderChange}
                    StateValue={sortBy}
                />
            </span>
        </>
    );
};

export default VehicleFilterArr;
