import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SetImagePreview } from "../../Helpers/ImagePreview/ImagePreview";
import { useAuthContext } from "../../context/authContext";
import { ImagePreviewModal } from "./LicenseImagePreviewer";
import { BaseUrl } from "../../Actions/Base";

function ImagePreviewerForEnrollmentLicense({
  Id,
  PreviewStyle,
  BrowseBtnStyle,
  ContainerStyle,
  OnChange,
  ImgUrl,
  readOnly,
}) {
  // const {Image, setImage} = useAuthContext()
  const { UserInfo } = useSelector((store) => store.LoginSignupReducer);
  const [isHovered, setIsHovered] = useState(false);

  const [Image, setImage] = useState();
//   const studentImage =
//     UserInfo?.User &&
//     `${BaseUrl}/api/images/Student?url=${UserInfo?.StudentImage}`;

  const PreviewImage = (e) => {
    const file = e.target.files[0];

    if (OnChange) OnChange(e);

    if (e.target.files[0].type === "application/pdf")
      setImage(require("./Assets/PDF.svg").default);
    else SetImagePreview(setImage, file);
  };

  if (!PreviewStyle) PreviewStyle = "w-52 h-36 ";
  if (!BrowseBtnStyle) BrowseBtnStyle = "text-3xs bg-[#F3F6F7]";
  if (!ContainerStyle) ContainerStyle = "flex gap-5 ";

  useEffect(() => {
    if (ImgUrl) {
      if (typeof ImgUrl === "string" && ImgUrl?.length > 1) setImage(ImgUrl);
      else SetImagePreview(setImage, ImgUrl);
    }
  }, [ImgUrl, Image]);
 

  // if (isHovered) {
  //     return <ImagePreviewModal Image={Image} />;
  // }
  

  return (
    <div className={ContainerStyle}>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        // className={`flex items-center  justify-center overflow-hidden rounded-xl border-[3px] border-dashed border-[#C8C8C8] bg-[#F3F6F7] ${PreviewStyle} h-[164px2 min-w-[120px] md:h-[164px] md:min-w-[160px]`}
        // className={`flex items-center  justify-center overflow-hidden rounded-xl border-[3px] border-dashed border-[#C8C8C8] bg-[#F3F6F7] ${PreviewStyle} h-[164px2 min-w-[120px] md:h-[260px] md:min-w-[260px]`}

        className={`flex items-center justify-center overflow-hidden bg-[#A1A3EF]   ${PreviewStyle} ${"h-[64px] w-[64px] min-w-[64px] md:h-[64px] rounded"}`}
        style={{width: "64px"}}
      >
        {!Image ? (
          <>
            <div>
              <span class="material-symbols-outlined text-[50px] text-white">
                cloud_upload
              </span>
            </div>
          </>
        ) : (
            Image && (
              <img
                className="h-[100%] w-[100%] border-[0px] border-none outline-none"
                src={Image}
                alt=""
              />
            )
          )}
        {/* {UserInfo?.StudentImage ? (
          <img
            className="h-[100%] w-[100%] border-[0px] border-none outline-none"
            src={studentImage}
            alt=""
          />
        ) : (
          Image && (
            <img
              className="h-[100%] w-[100%] border-[0px] border-none outline-none"
              src={Image}
              alt=""
            />
          )
        )} */}

        {isHovered && Image && <ImagePreviewModal Image={Image} />}

        <input
          type="file"
          accept="image/*"
          capture
          className="hidden"
          onChange={PreviewImage}
          id={Id}
        />
      </div>
      {!readOnly && (
        <div className={"flex flex-col items-center gap-2"}>
          <label
            className={`h-fit  w-fit cursor-pointer rounded-lg bg-[#A1A3EF]  px-2 py-1 text-[14px]  text-white shadow-[5px_6px_6px_#00000016]  lg:text-[15px] `}
            htmlFor={Id}
          >
            Upload
          </label>

          <p className="text-[12px] md:text-[13px]">Jpeg, PNG or Giff</p>
        </div>
      )}
    </div>
  );
}
export default ImagePreviewerForEnrollmentLicense;
