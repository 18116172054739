import axios from "axios";
import { CheckLoginServer } from "../Helpers/CheckLogin";
import { BaseUrl } from "./Base";

const axiosInstance = axios.create({
  baseURL: BaseUrl,
  withCredentials: true,
});

export const GetCourse = (InstituteCourseId) => async (dispatch) => {
  try {
    dispatch({
      type: "CourseRequest",
    });

    const { data } = await axiosInstance.get(
      `/api/institute/course/${InstituteCourseId}`
    );

    dispatch({
      type: "CourseSuccess",
      payload: data,
    });
  } catch (error) {
    CheckLoginServer(error?.response?.data, dispatch);
    dispatch({
      type: "CourseFailure",
      payload: error,
    });
  }
};

export const GetInstituteCoursesTimeSlots = (InstituteCourseId) => async (dispatch) => {
  dispatch({ type: "GetInstituteCoursesTimeSlotsRequest" });

  try {
      const response = await axiosInstance.get(
        `/api/institute/timeslots/${InstituteCourseId}`
      ); // Replace with actual API call
      dispatch({
          type: "GetInstituteCoursesTimeSlotsSuccess",
          payload: response.data, // The time slots data
      });
  } catch (error) {
      dispatch({
          type: "GetInstituteCoursesTimeSlotsFailure",
          payload: error.response?.data || error.message,
      });
  }
};

export const GetInstituteSideCoursesTimeSlots = (InsId) => async (dispatch) => {
  dispatch({ type: "GetInstituteSideCoursesTimeSlotsRequest" });

  try {
      const response = await axiosInstance.get(
        `/api/institute/InstituteSidetimeslots/${InsId}`
      ); // Replace with actual API call
      dispatch({
          type: "GetInstituteSideCoursesTimeSlotsSuccess",
          payload: response.data, // The time slots data
      });
  } catch (error) {
      dispatch({
          type: "GetInstituteSideCoursesTimeSlotsFailure",
          payload: error.response?.data || error.message,
      });
  }
};


export const GetLicenseTypeCourse = (LicenseTypeId) => async (dispatch) => {
  try {
    dispatch({
      type: "LicenceTypeCoursesRequest",
    });

    const { data } = await axiosInstance.get(
      `/api/LicenseType/courses/${LicenseTypeId}`
    );

    dispatch({
      type: "LicenceTypeCoursesSuccess",
      payload: data,
    });
  } catch (error) {
    CheckLoginServer(error?.response?.data, dispatch);
    dispatch({
      type: "LicenceTypeCoursesFailure",
      payload: error,
    });
  }
};
export const GetVehicleTypeCourse = (VehicleypeId) => async (dispatch) => {
  try {
    dispatch({
      type: "LicenceTypeCoursesRequest",
    });

    const { data } = await axiosInstance.get(
      `/api/vehicletype/courses/${VehicleypeId}`
    );

    dispatch({
      type: "LicenceTypeCoursesSuccess",
      payload: data,
    });
  } catch (error) {
    CheckLoginServer(error?.response?.data, dispatch);
    dispatch({
      type: "LicenceTypeCoursesFailure",
      payload: error,
    });
  }
};

export const GetEnrollCourseInfo = (EnrollCourseId) => async (dispatch) => {
  try {
    dispatch({
      type: "EnrollCourseRequest",
    });
    const { data } = await axiosInstance.get(
      `/api/institute/course/${EnrollCourseId}`
    );

    dispatch({
      type: "EnrollCourseSuccess",
      payload: data,
    });
  } catch (error) {
    CheckLoginServer(error?.response?.data, dispatch);
    dispatch({
      type: "EnrollCourseFailure",
      payload: error,
    });
  }
};

export const EnrollIntoCourse = (EnrollmentData) => async (dispatch) => {
  try {
    dispatch({
      type: "BuyCourseRequest",
    });
    const { data } = await axiosInstance.post(
      `/api/buy/course`,
      EnrollmentData
    );

    dispatch({
      type: "BuyCourseSuccess",
      payload: data,
    });
    // Navigate("/mycourses/enrolledcourses");
  } catch (error) {
    CheckLoginServer(error?.response?.data, dispatch);
    dispatch({
      type: "BuyCoursefailure",
      payload: error,
    });
  }
};

export const GetEnrolledCourses = () => async (dispatch) => {
  try {
    dispatch({
      type: "GetEnrolledCoursesRequest",
    });
    const { data } = await axiosInstance.get(`/api/enrollCourse`);

    dispatch({
      type: "GetEnrolledCoursesSuccess",
      payload: data,
    });
  } catch (error) {
    CheckLoginServer(error?.response?.data, dispatch);
    dispatch({
      type: "GetEnrolledCoursesFailure",
      payload: error,
    });
  }
};

export const GetSingleEnrolledCourse = (EnrollmentId) => async (dispatch) => {
  try {
    dispatch({
      type: "GetSEnrolledCoursesRequest",
    });
    const { data } = await axiosInstance.get(
      `/api/enrollCourse/${EnrollmentId}`
    );
    console.log("senrol", data);
    dispatch({
      type: "GetSEnrolledCoursesSuccess",
      payload: data,
    });
  } catch (error) {
    CheckLoginServer(error?.response?.data, dispatch);
    dispatch({
      type: "GetSEnrolledCoursesFailure",
      payload: error,
    });
  }
};

export const GetSEnrolledCourse_ForInsA =
  (EnrollmentId) => async (dispatch) => {
    try {
      dispatch({
        type: "GetSEnrolledCoursesRequest",
      });
      const { data } = await axiosInstance.get(`/api/Enrolled/${EnrollmentId}`);

      dispatch({
        type: "GetSEnrolledCoursesSuccess",
        payload: data,
      });
    } catch (error) {
      CheckLoginServer(error?.response?.data, dispatch);
      dispatch({
        type: "GetSEnrolledCoursesFailure",
        payload: error,
      });
    }
  };

export const GetCourseProgress = (EnrollmentId) => async (dispatch) => {
  try {
    dispatch({
      type: "GetSEnrolledCoursesRequest",
    });
    const { data } = await axiosInstance.get(
      `/api/courseprogress/${EnrollmentId}`
    );

    dispatch({
      type: "GetSEnrolledCoursesSuccess",
      payload: data?.EnrolledCourse,
    });
    dispatch({
      type: "EnrolledCourseProgressSuccess",
      payload: data?.CourseProgress,
    });
  } catch (error) {
    CheckLoginServer(error?.response?.data, dispatch);
    dispatch({
      type: "GetSEnrolledCoursesFailure",
      payload: error,
    });
  }
};

export const GetAdminCoursesA = () => async (dispatch) => {
  try {
    dispatch({
      type: "GetAdminCoursesRequest",
    });
    const { data } = await axiosInstance.get(`/api/courses`);

    dispatch({
      type: "GetAdminCoursesSuccess",
      payload: data,
    });
  } catch (error) {
    CheckLoginServer(error?.response?.data, dispatch);
    dispatch({
      type: "GetAdminCoursesFailure",
      payload: error,
    });
  }
};

export const GetRecommendedAdminCoursesA = () => async (dispatch) => {
  try {
    dispatch({
      type: "GetAdminCoursesRequest",
    });

    const { data } = await axiosInstance.get(`/api/course/forwards/`);
    let CoursesArr = data.map((value) => value.Course);
    CoursesArr = CoursesArr?.filter((value, Index, arr) => {
      let CheckVal = true;
      arr.forEach((Val, index) => {
        if (value?.CoursePK === Val?.CoursePK && Index > index)
          CheckVal = false;
      });

      return CheckVal === true;
    });

    dispatch({
      type: "GetAdminCoursesSuccess",
      payload: CoursesArr,
    });
  } catch (error) {
    CheckLoginServer(error?.response?.data, dispatch);
    dispatch({
      type: "GetAdminCoursesFailure",
      payload: error,
    });
  }
};

export const GetAboutInfoA = (EnrollmentId) => async (dispatch) => {
  try {
    dispatch({
      type: "GetAboutClientRequest",
    });
    const { data } = await axiosInstance.get(`/api/student/${EnrollmentId}`);
    console.log("data recieved is:",data)
    console.log("CoursePackage:", data?.EnrolledCourse?.CoursePackage);
    console.log("Student:", data?.EnrolledCourse?.User);
    console.log("CourseProgress:", data?.Progress);
    console.log("ScheduleTimeTable:", data?.EnrolledCourse?.ParsedSchedule);
    console.log("StudentInfo:", data?.StudentInfoData);

    // let StudentInfo = { ...data.EnrolledCourse.User };

    // StudentInfo.StudentInfo.DOB = new Date(
    //   StudentInfo?.StudentInfo?.DOB
    // ).toLocaleDateString();

    dispatch({
      type: "GetAboutClientSuccess",
      CoursePackage: data?.EnrolledCourse?.CoursePackage,
      Student: data?.EnrolledCourse?.User,
      // CourseProgress: data.EnrolledCourse.CourseProgress,
      CourseProgress: data?.Progress,

      ScheduleTimeTable: data?.EnrolledCourse?.ParsedSchedule,
      StudentInfo: data?.StudentInfoData,
    });
  } catch (error) {
    CheckLoginServer(error?.response?.data, dispatch);
    dispatch({
      type: "GetAboutClientFailure",
      payload: error,
    });
  }
};

export const CreateCoursesA =
  (CourseFormData, setSuccess) => async (dispatch) => {
    try {
      dispatch({
        type: "CreateCourseRequest",
      });

      const { data } = await axiosInstance.post(
        `/api/institute/course/add`,
        CourseFormData
      );

      setSuccess(true);
      dispatch({
        type: "CreateCourseSuccess",
        payload: data,
      });
    } catch (error) {
      CheckLoginServer(error?.response?.data, dispatch);
      dispatch({
        type: "CreateCourseFailure",
        payload: error,
      });
    }
  };

// export const InstituteCoursesA = () => async (dispatch) => {
//   try {
//     dispatch({
//       type: "GetInstituteCoursesRequest",
//     });

//     const { data } = await axiosInstance.get(`/api/institute/courses`);

//     dispatch({
//       type: "GetInstituteCoursesSuccess",
//       payload: data,
//     });
//   } catch (error) {
//     CheckLoginServer(error?.response?.data, dispatch);
//     dispatch({
//       type: "GetInstituteCoursesFailure",
//       payload: error,
//     });
//   }
// };
export const InstituteCoursesA = (page = 1, filter = 'All Courses') => async (dispatch) => {
  try {
    dispatch({
      type: "GetInstituteCoursesRequest",
    });

    const params = {
      page,
      limit: 10, // You can change the limit as needed
    };

    if (filter !== 'All Courses') {
      params.filter = filter;
    }

    const { data } = await axiosInstance.get(`/api/institute/courses`, { params });

    dispatch({
      type: "GetInstituteCoursesSuccess",
      payload: data,
    });
  } catch (error) {
    CheckLoginServer(error?.response?.data, dispatch);
    dispatch({
      type: "GetInstituteCoursesFailure",
      payload: error,
    });
  }
};

export const InstitutePendingCoursesA = () => async (dispatch) => {
  try {
    dispatch({
      type: "GetInstitutePendingCoursesRequest",
    });

    const { data } = await axiosInstance.get(`/api/institute/courses/pending`);

    dispatch({
      type: "GetInstitutePendingCoursesSuccess",
      payload: data,
    });
  } catch (error) {
    CheckLoginServer(error?.response?.data, dispatch);
    dispatch({
      type: "GetInstitutePendingCoursesFailure",
      payload: error,
    });
  }
};


export const InstituteCoursesCountA = () => async (dispatch) => {
  try {
    dispatch({
      type: "GetInstituteCoursesCountRequest",
    });

    const { data } = await axiosInstance.get(`/api/institute/courses/count`);

    dispatch({
      type: "GetInstituteCoursesCountSuccess",
      payload: data,
    });
  } catch (error) {
    CheckLoginServer(error?.response?.data, dispatch);
    dispatch({
      type: "GetInstituteCoursesCountFailure",
      payload: error,
    });
  }
};

export const UpdateCoursesA =
  (CourseFormData, setSuccess) => async (dispatch) => {
    try {
      dispatch({
        type: "CreateCourseRequest",
      });

      const { data } = await axiosInstance.put(
        `/api/institute/course/update`,
        CourseFormData
      );

      setSuccess(true);
      dispatch({
        type: "CreateCourseSuccess",
        payload: data,
      });
    } catch (error) {
      CheckLoginServer(error?.response?.data, dispatch);
      dispatch({
        type: "CreateCourseFailure",
        payload: error,
      });
    }
  };

export const DeleteInstituteCoursesA =
  (InstituteCourseId) => async (dispatch) => {
    try {
      dispatch({
        type: "GetInstituteCoursesRequest",
      });

      const { data } = await axiosInstance.delete(
        `/api/institute/course/remove`,
        { data: { InstituteCourseId } }
      );

      dispatch({
        type: "GetInstituteCoursesSuccess",
        payload: data,
      });
    } catch (error) {
      CheckLoginServer(error?.response?.data, dispatch);
      dispatch({
        type: "GetInstituteCoursesFailure",
        payload: error,
      });
    }
  };
