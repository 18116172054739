import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TeacherDashboardRowTemplate } from "./TeacherDashboardRowTemplate";
import dayjs from "dayjs";
import "./TeacherDashboardTable.css";
import { BaseUrl } from "../../../../../Actions/Base";
import { Feedback } from "@mui/icons-material";
import LoadingSpinner from "../../../../../Components/LoadingSpinner/LoadingSpinner";
function LessonAttendanceTable({
  Students,
  UpdateClassStatusFunc,
  ClassFeedback,
  setClassFeedback,
  setEventId,
  loading,
  EventId,
  error,
  //   enrollmentCountMap,
  //   completedClassCountsMap,
  //   filtered,
  filteredStudents,
}) {
  const Navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [rowData, setRowData] = useState(null); // Add state for row data

  const handleInitClass = () => {
    console.log("clcik");
  };

  const handleUpdateClick = (data) => {
    setRowData(data); // Set row data
    setShowPopup(true);
  };

  const toURL = `/in-vehicle-class/${EventId}?startDate=${encodeURIComponent(filteredStudents.startDate)}&endDate=${encodeURIComponent(filteredStudents.endDate)}`;

  return (
    <div className="">
      {/* {error && <div>{error?.response?.data?.message}</div>} */}
      {/* {loading && <LoadingSpinner />} */}
      {showPopup && (
        <UpdateClassStatus
          ClassFeedback={ClassFeedback}
          setClassFeedback={setClassFeedback}
          UpdateClassStatusFunc={UpdateClassStatusFunc}
          setShowPopup={setShowPopup}
          title={"Class Status Update"}
          description={"Please update your feedback for this class"}
          MissedButton={"Missed"}
          DeliveredButton={"Delivered"}
          rowData={rowData} // Pass row data to popup
        />
      )}
      <table
        className="addCourse4Table "
        style={
          {
            // background: "rgba(0,0,0,0)";
          }
        }
      >
        <thead className="bg-color">
          <tr>
            <th className="w-[9%] text-center">Student ID</th>
            {/* <th className="w-[5%] px-3 text-start">S.No</th> */}
            <th className="w-[3%] text-center">Name</th>
            <th className="w-[10%] text-center"></th>
            <th className="w-[16.5%] text-center">Email</th>
            <th className="w-[14%] text-center">Class Status</th>
            <th className="w-[9%] text-center">Type</th>
            <th className="w-[4%] text-center">ClassNo</th>
            <th className="w-[7%] text-center">Total Classes</th>
            <th className="w-[16%] text-center">Next Class & Date</th>

            <th className="w-[16%] text-center">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-color">
          {filteredStudents && Array.isArray(filteredStudents) &&
            filteredStudents?.map((value, index) => {
              const {
                classStatus,
                startDate,
                endDate,
                createdAt,
                EnrollmentFK,
                ClassNo,
                ClassType,
                EventId,
                InstructorFK,
              } = value;
              const { IdCardNo, EnrollmentId } = value?.CourseEnrollment;
              const { FirstName, LastName, PhoneNumber, Email, StudentImage } =
                value?.CourseEnrollment?.User;
              const ImgUrl = `${BaseUrl}/api/images/Student?url=${StudentImage}`;

              const NextClassStartTime = dayjs(new Date(startDate)).format(
                "hh:mm A"
              );

              const NextClassStartDate = dayjs(new Date(endDate)).format(
                "DD/MM/YYYY"
              );

              {
                /* const currentDate = dayjs().format("DD/MM/YYYY HH:mm");
              const endDateFormatted = dayjs(new Date(endDate)).format(
                "DD/MM/YYYY HH:mm"
              ); */
              }

              const currentDateTime = dayjs();
              const endDateTime = dayjs(new Date(endDate));

              {
                /* const TotalClasses = enrollmentCountMap[EnrollmentId] || 0; */
              }
              {
                /* const completedClasses =
                completedClassCountsMap[EnrollmentId] || 0; */
              }
              {
                /* const { PaymentStatus, Status } = value; */
              }

              // console.log('valueeeeeeeeee', value?.CourseEnrollment?.IdCardNo )

              {
                /* const PaymentOrClientsColor =
                                !PaymentStatus === 0
                                    ? "text-[green] bg-[#aaffbe]"
                                    : "text-white bg-[#b5aaff]"; */
              }

              const StatusColor = "bg-[#B5AAFF] text-white";

              const OnClick = () => {
                {
                  /* {
                  Navigate(`/teacher/AboutStudent/${EnrollmentId}`);
                } */
                }
              };

              const Edit = () => (
                <Link
                  onClick={(e) => {
                    e.stopPropagation();
                    setEventId(EventId);
                    setShowPopup(true);
                    // alert("Update");
                  }}
                  to={
                    "#"
                    // filtered
                    //   ? `/enrolledcourse/${EnrollmentId}`
                    //   : `/enrolledcourse/manage/${EnrollmentId}`
                  }
                >
                  <button
                    type="button"
                    class="focus:ring-blue-300  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800  cursor-pointer rounded-lg border-0 bg-[#4c34ad] px-10 py-2 text-4xs font-medium text-white outline-none hover:bg-[#a1a3ef] focus:outline-none focus:ring-4"
                  >
                    Edit
                  </button>
                </Link>
              );
              const Update = () => (
                <Link
                  onClick={(e) => {
                    e.stopPropagation();
                    setEventId(EventId);
                    handleUpdateClick({
                      FirstName,
                      LastName,
                      Status: classStatus,
                      createdAt,
                      Email,
                      StudentId: IdCardNo,
                      NextClassStartDate,
                      NextClassStartTime,
                      classStatus,
                      TotalClasses: "", // Placeholder
                      completedClasses: "", // Placeholder
                      ClassType,
                      ClassNo,
                    });
                    // alert("Update");
                  }}
                  to={
                    "#"
                    // filtered
                    //   ? `/enrolledcourse/${EnrollmentId}`
                    //   : `/enrolledcourse/manage/${EnrollmentId}`
                  }
                >
                  <button
                    type="button"
                    // disabled={currentDate !== endDateFormatted}
                    disabled={endDateTime.isAfter(currentDateTime)}
                    // class="focus:ring-blue-300 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-2 cursor-pointer rounded-lg border-0 bg-[#4c34ad] px-5 py-2 text-4xs font-medium text-white outline-none hover:bg-[#a1a3ef] focus:outline-none focus:ring-4"
                    class={`  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800  cursor-pointer rounded-lg border-0 bg-[#4c34ad] px-7 py-2 text-center text-4xs font-medium text-white outline-none hover:bg-[#a1a3ef] focus:outline-none focus:ring-4 ${
                      endDateTime.isAfter(currentDateTime) ? " opacity-50" : ""
                    }`}
                  >
                    Update
                  </button>
                </Link>
              );
              const Delivered = () => (
                <Link
                  onClick={(e) => e.stopPropagation()}
                  to={
                    "#"
                    // filtered
                    //   ? `/enrolledcourse/${EnrollmentId}`
                    //   : `/enrolledcourse/manage/${EnrollmentId}`
                  }
                >
                  <button
                    type="button"
                    class="focus:ring-blue-300  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800  cursor-pointer rounded-lg border-0 bg-[#4c34ad] px-5 py-2 text-4xs font-medium text-white outline-none hover:bg-[#a1a3ef] focus:outline-none focus:ring-4"
                  >
                    Delivered
                  </button>
                </Link>
              );

              const InitiateClass = () => (
                <Link
                  // onClick={(e) => e.stopPropagation()}
                  to={`/in-vehicle-class/${EventId}`}
                  // to={toURL}
                >
                  <button
                    type="button"
                    class="focus:ring-blue-300  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800  cursor-pointer rounded-lg border-0 bg-[#4c34ad] px-5 py-2 text-4xs font-medium text-white outline-none hover:bg-[#a1a3ef] focus:outline-none focus:ring-4"
                  >
                    Initiate Class
                  </button>
                </Link>
              );

              return (
                <TeacherDashboardRowTemplate
                  type={"instructor-lesson-attendance"}
                  OnClick={OnClick}
                  TrStyle=""
                  key={index}
                  ImgUrl={ImgUrl}
                  ClassType={ClassType}
                  ClassNo={ClassNo}
                  // index={index}
                  // LastComp={Delete}
                  //   LastCompp={Manage}
                  FirstName={FirstName}
                  LastName={LastName}
                  Email={Email}
                  classStatus={
                    classStatus === "Scheduled" ? "Upcoming Class" : classStatus
                  }
                  StudentId={IdCardNo}
                  //   createdAt={createdAt}
                  // PaymentOrUnderClients={
                  //     !PaymentStatus ? PaymentMethod : "free"
                  // }
                  StatusColor={StatusColor}
                  // PaymentOrClientsColor={
                  //     PaymentOrClientsColor
                  // }
                  NextClassStartTime={NextClassStartTime}
                  NextClassStartDate={NextClassStartDate}
                  TotalClasses={""}
                  completedClasses={""}
                  InitiateClass={endDateTime.isAfter(currentDateTime) ? InitiateClass  : null}
                  Update={Update}
                  Edit={Edit}
                  Delivered={Delivered}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default LessonAttendanceTable;

const UpdateClassStatus = ({
  UpdateClassStatusFunc,
  ClassFeedback,
  setClassFeedback,
  setShowPopup,
  title,
  MissedButton,
  DeliveredButton,
  description,
  rowData
}) => {
  console.log("row data in UpdateClassStatus is:",rowData)
  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-md transition duration-500">
      <div className="relative rounded-[23px] bg-white p-8 shadow-lg">
        <div className="absolute top-2 right-2">
          <img
            className="absolute right-3 top-[6px] cursor-pointer"
            src={require("../../Assets/Cross.svg").default}
            alt=""
            onClick={() => setShowPopup(false)}
          />
        </div>
        <h2
          // style={{ WebkitTextStroke: "1px #404040" }}
          className=" mb-4 text-center text-[16px]"
        >
          {title}
        </h2>
        <p className="text-center text-[14px]">{description}</p>
        <div className="text-center">
          <img
            src={require("../../Assets/update-class.png")}
            alt="feedback"
            className="mb-4 "
          />
        </div>

        <textarea
          className=" mb-4 h-24 w-full resize-none rounded-md border border-gray-300 p-2 focus:outline-none "
          placeholder="Add your feedback here..."
          value={ClassFeedback}
          onChange={(e) => setClassFeedback(e.target.value)}
          // onChange={(e) => setData({ ...data, Feedback: e.target.value })}
        />
        <div className="flex items-center justify-center gap-3">
          <button
            onClick={() => {
              UpdateClassStatusFunc("Missed", rowData, setShowPopup);

              // setData({ ...data, newStatus: "Missed" });
              
            }}
            className="border-1 cursor-pointer rounded-[7px] border-solid border-[#4C34AD] bg-white px-8 py-2 font-sans text-xs text-[#4C34AD] transition duration-500 hover:bg-[#4C34AD] hover:text-white  "
          >
            {MissedButton}
          </button>
          <button
            onClick={() => {
              UpdateClassStatusFunc("Delivered", rowData, setShowPopup);
              // setData({ ...data, newStatus: "Delivered" });
  
            }}
            className="border-1 cursor-pointer rounded-[7px] border-solid border-[#4C34AD] bg-white px-5 py-2 font-sans text-xs text-[#4C34AD] transition duration-500 hover:bg-[#4C34AD] hover:text-white  "
          >
            {DeliveredButton}
          </button>
        </div>
      </div>
    </div>
  );
};

