import React from "react";
import ImagePreviewer from "../../../../../Components/ImagePreviewer/ImagePreviewer";

const InstituteDocuments = ({
    InstituteData,
    setInstituteData,
    Err,
    setErr,
}) => {
    let DocumentData = [
        {
            Title: "Ministry of Transportation Registration Slip",
            Id: "TransportationSlip",
            ImageName: "MOTR_Slip",
        },
        {
            Title: "Partner Logo",
            Id: "InstituteLogo",
            ImageName: "InstituteLogo",
        },
        {
            Title: "Local Registration Slip",
            Id: "LocalRegistrationSlip",
            ImageName: "LR_Slip",
        },
        {
            Title: "Partner Banner",
            Id: "Banner",
            ImageName: "Institute_Banner",
        },
    ];
    const OnChange = (e, ImageName, Title) => {
        if (e.target.files.length > 0) {
            const acceptedTypes = ["image/png", "image/jpeg", "image/svg+xml", "application/pdf"];
        console.log("e.target.file.type", e.target.files[0].type)
        if (acceptedTypes.includes(e.target.files[0].type)) {
            delete Err?.[ImageName];
            setErr(Err);
            setInstituteData({
                ...InstituteData,
                [ImageName]: e.target.files[0],
            });
        }else setErr({ ...Err, [ImageName]: `"${Title}" Unsupported file type.` })
        
           
        } else setErr({ ...Err, [ImageName]: `"${Title}" is required` });
    };
    const ContainerStyle = "";
console.log("Errorr", Err)
    return (
        <div className="InstituteDocument_MainContainer">
            
            {DocumentData.map((value) => (
                <div className="flex w-full flex-col" key={value.Title}>
                    <div className="flex flex-col gap-5 sm:flex-row">
                        <h3 className="InstituteDocument_Title">
                            {value?.Title}
                        </h3>
                        <div>
                        <h5 className="mb-1 h-[14px] text-5xs font-normal text-[red]">
                        {Err?.[value?.ImageName]}
                    </h5>
                        <ImagePreviewer
                            Id={value.Id}
                            OnChange={(e) => OnChange(e, value.ImageName)}
                            ContainerStyle={ContainerStyle}
                            ImgUrl={!Err?.[value?.ImageName] && InstituteData?.[value.ImageName]}
                        />
                        </div>
                    </div>

                    
                </div>
            ))}
        </div>
    );
};

export default InstituteDocuments;
