import { createReducer } from "@reduxjs/toolkit";

export const FeedbackReducer = createReducer({}, (builder)=> {
    builder.addCase("feedbackRequest", (state, action)=> {
        state.loading = true
    })
    builder.addCase("feedbackSuccess", (state, action)=> {
        state.loading = false;
        state.error = {};
        state.FeedbackPageData = action.payload;
    })
    builder.addCase("feedbackFailure", (state, action)=> {
        state.loading = false;
        state.error = action.payload
    })
})