import React from "react";
import loader from "../../assets/loader.gif";
import { useSelector } from "react-redux";

function WelcomeChat() {
    const { UserInfo } = useSelector((state) => state.LoginSignupReducer);
    console.log(UserInfo, "userinfo");
    return (
        <div className="flex w-[100%]   items-center justify-center">
            <div className="flex flex-col items-center justify-center">
                <div>
                    <h1
                        style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                        className="text-[18px] font-bold text-[#9E57F3] sm:text-xs  lg:text-[29px] xl:text-lg "
                    >
                        Hello!{" "}
                        <span>
                            {UserInfo.FirstName} {UserInfo.LastName}{" "}
                        </span>
                    </h1>

                    <img src={loader} alt="img" height={500} />
                    <h5
                        style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                        className="text-[12px] font-bold text-[#9E57F3] sm:text-xs  lg:text-[15px] xl:text-lg "
                    >
                        Please select a chat to start messaging
                    </h5>
                </div>
            </div>
        </div>
    );
}

export default WelcomeChat;
