import React, { useEffect, useState } from "react";
import { Country, State, City } from "country-state-city";
import { useLocationContext } from "../../../../../../context/locationContext";
import { CountryOptions } from "../../../../../../Pages/Institute/AddInstructor/Components/OptionsArr/OptionsArr";
import { SelectList } from "../../../../../InputComps/InputComps";

const LocationSelector = () => {
  const { location, setLocation } = useLocationContext();
  const [selectedCountry, setSelectedCountry] = useState();
  const [cities, setCities] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState();
  console.log("countryOption in header rightside:",CountryOptions)

  useEffect(() => {
    console.log("Effect Triggered: ", location.country, location.state);
    const selectedCountry = location.country;
    const selectedProvince = location.state;
  
    if (typeof selectedCountry === "string" && typeof selectedProvince === "string") {
      const country = Country?.getAllCountries()?.find(
        (country) => country?.name.toLowerCase() === selectedCountry.toLowerCase()
      );
      console.log("Country in header rightside: ", country);
      if (country) {
        const states = State?.getStatesOfCountry(country?.isoCode);
        const selectedProvinceData = states?.find(
          (province) => province.name.toLowerCase() === selectedProvince.toLowerCase()
        );
        console.log("Selected Province Data in header rightside:: ", selectedProvinceData);
        if (selectedProvinceData) {
          const citiesData = City.getCitiesOfState(
            country?.isoCode,
            selectedProvinceData?.isoCode
          );
          console.log("Cities Data in header rightside:: ", citiesData);
          setCities(citiesData);
        }
      }
    }
  }, [location.country, location.state, location.city]);
  

/*   useEffect(() => {
    const selectedCountry = location.country;
    console.log("selected country set in new location is:",selectedCountry)
    const selectedProvince = location.state;
    console.log("selected selectedProvince set in new location is:",selectedProvince)
    if (
      typeof selectedCountry === "string" &&
      typeof selectedProvince === "string"
    ) {
      const country = Country?.getAllCountries()?.find(
        (country) =>
          country?.name.toLowerCase() === selectedCountry?.toLowerCase()
      );
      if (country) {
        const states = State?.getStatesOfCountry(country?.isoCode);
        const selectedProvinceData = states?.find(
          (province) =>
            province.name.toLowerCase() === selectedProvince?.toLowerCase()
        );
        if (selectedProvinceData ) {
          console.log("Found Province: ", selectedProvinceData);
          const citiesData = City.getCitiesOfState(
            country?.isoCode,
            selectedProvinceData?.isoCode
          );
          setCities(citiesData);
        }
      }
    }
  }, [location.country, location.city, location.state  ]); */

  const ProvinceOptions = () => {
    let states = [];

    // Determine the country to use
  const countryName = location.country;

    if (typeof countryName  === "string") {
      let countries = Country?.getAllCountries();

      const country = countries?.find(
        (country) =>
          country?.name.toLowerCase() ===
          countryName.toLowerCase()
      );

      if (country) {
        states = State?.getStatesOfCountry(country?.isoCode);
        // console.log("country", states);
      }
    }

    console.log("States", states);
    return states?.map((country) => (
      <option value={country.name} key={country.isoCode}>
        {country?.name}
      </option>
    ));
  };
  console.log("Province options are:",ProvinceOptions)
  const CityOptions = () => {
    return (
      cities &&
      cities?.map((city, index) => (
        <option value={city?.name} key={index}>
          {city?.name}
        </option>
      ))
    );
  };
  console.log("Province options are:",CityOptions)

  // Modified handleSelectChange function to handle country, state, and city updates
  const handleSelectChange = (e, type) => {
    const { value } = e.target;

    // Update location based on the type of selection (country, state, city)
    setLocation((prev) => ({
      ...prev,
      [type.toLowerCase()]: value, // Update the specific type (country, state, or city)
      ...(type === "Country" ? { state: "", city: "", postalCode: "" } : {}), // Clear state and city if country changes
      ...(type === "State" ? { city: "", postalCode: "" } : {}), // Clear city if state changes
      ...(type === "City" ? { postalCode: "" } : {}), // Clear postal code if city changes
    }));
  };

  // Handle changes for postal code
  const handlePostalCodeChange = (e) => {
    const { value } = e.target;
    setLocation((prev) => ({
      ...prev,
      postalCode: value, // Update only the postal code
    }));
    console.log("postal code is:",location.postalCode)
  };


  let DivStyle =
    "w-[200px] flex flex-col justify-center items-start gap-4  text-black";

  return (
    <>
    <div className="w-[200px] flex flex-col justify-center items-start gap-4">
      <SelectList
        Id="Country"
        defaultValue=""
        Text="Country"
         OptionsArr={CountryOptions}
        State={location.country}
        onChange={(e) => handleSelectChange(e, "Country")}
      />
      </div>

      <div className="w-[200px] flex flex-col justify-center items-start gap-4">
        <SelectList
        Id="Province"
        defaultValue=""
        Text="Province / Territory"
        OptionsArr={ProvinceOptions}
        State={location.state}
        onChange={(e) => handleSelectChange(e, "State")}
        />
      </div>

      <div className="w-[200px] flex flex-col justify-center items-start gap-4">
                <SelectList
                Id="City"
                defaultValue=""
                Text="City"
                OptionsArr={CityOptions}
                State={location.city}
                onChange={(e) => handleSelectChange(e, "City")}
                />
        </div>

      <input
        type="text"
        name="postalCode"
        value={location.postalCode}
        onChange={handlePostalCodeChange} // Use the new function for postal code
        placeholder="Postal Code"
        className="outline-none border-[#E2E2E2] border-[1px] border-solid text-[18px] h-[40px] w-[200px] pl-[15px]"
      />
    </>
  );
};

export default LocationSelector;
