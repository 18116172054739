import dayjs from "dayjs";

export const DateInput = ({
    readOnly,
    Err,
    onChange,
    State,
    type,
    typee,
    Id,
    style,
    CurrentYear,
    licenseInput,
    instructor,
    staff,
}) => {
    let DivStyle =
        "w-full md:w-[45%] flex flex-col justify-center items-start gap-3 max-w-[600px]";
    let mergeDivStyle = `${DivStyle} ${style}`;
    let HeadingStyle =
        "text-4xs sm:text-3xs md:text-2xs lg:text-xs text-[#404040] font-normal  whitespace-nowrap";
    let ExceptionSpanStyle =
        "flex items-center w-full rounded-[8px] bg-white px-5 border-[#E8E8E8] border-solid border-[2px]";
    let ExceptionInputStyle = ` w-full px-2 mr-2 text-6xs md:text-5xs lg:text-2xs xl:text-3xs rounded-[8px] py-2 border-none focus:border-none focus:outline-none`;
    const AddParentStyle = (e) =>
        (e.target.parentNode.style = "border:2px solid black");
    const RemoveParentStyle = (e) => (e.target.parentNode.style = "");

    const ShowDateee = (e) => {
        e.preventDefault();
        e.target.parentElement.previousElementSibling.showPicker();
    };

    const ShowDatee = (e) => {
        e.preventDefault();
        e.target.parentElement.previousElementSibling.showPicker();
    };
    const ShowDate = (e) => {
        e.preventDefault();
        e.target.parentElement.previousElementSibling.showPicker();
    };

    return (
        <div
            className={
                licenseInput
                    ? "flex w-full flex-col items-start justify-center gap-3 "
                    : "flex w-full flex-col items-start justify-center"
            }
        >
            <div className="flex">
                <label htmlFor={Id} className={HeadingStyle}>
                    {type === "licence-date" || type === "licence-datee"
                        ? ""
                        : "Date of Birth (MM/DD/YYYY) *"}
                </label>
                <h4 className="font-normal text-[red]"> {Err ? Err : null}</h4>
            </div>

            <span
                className={`${ExceptionSpanStyle}  ${
                    type === "licence-date"
                        ? "shadow-[4px_5px_6px_#00000029]"
                        : ""
                }`}
            >
                <input
                    className={`${ExceptionInputStyle} RemoveDateIcon   `}
                    type="date"
                    placeholder="DD/MM/YYYY"
                    id={Id}
                    disabled={readOnly}
                    onFocus={AddParentStyle}
                    onBlur={RemoveParentStyle}
                    value={State}
                    onChange={onChange}
                    min={CurrentYear}
                />

                <label
                    htmlFor={type === "licence-date" ? "licenceExpDate" : "DOB"}
                >
                    <img
                        src={require("./Assets/BirtPlaceIcon.svg").default}
                        alt=""
                        onClick={(e) => {
                            type === "licence-date"
                                ? ShowDatee(e)
                                : type === "licence-datee"
                                ? ShowDateee(e)
                                : ShowDate(e);
                        }}
                    />
                </label>
            </span>
        </div>
    );
};
