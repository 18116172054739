import React, { useState } from "react";
import img from "../Assets/Certified Stores and Stock Controller Badge.png";

const Awards = () => {

  const [Awards, setAward] = useState([1, 2, 3, 4, 5, 6]);
  return (
    <div className="h-auto w-full py-10 px-20">
      <h2 className="pb-5 font-segoe-ui text-base font-bold text-purpal md:text-lg">
        Certificates Management
      </h2>
      <div className="flex gap-10 flex-wrap">
      {Awards.map((cert) => {
        return (
          <div
            key={cert}
            className="certificate relative group h-[250px] w-[300px] shadow-lg"
          >
            <p className="hoverText hidden group-hover:flex group-hover:absolute ease-in-out duration-500 absolute bg-white/50 opacity-80 left-0 bottom-0 w-full  h-full"><span className="w-full flex justify-center items-end h-full"> <span className="pb-5 text-black opacity-100 font-bold text-sm">Award win 2020</span></span></p>
            <img className="h-full w-full" src={img} alt="img" />
          </div>
        );
      })}
       </div>
    </div>
  );
};

export default Awards;
