import React, { useState } from "react";
import "../forget-password/forgotPassword.css";
import { useAuthContext } from "../../context/authContext";
import { BaseUrl } from "../../Actions/Base";
import axios from "axios";
import { DoneLoginSignUp } from "../../Actions/ToggleSignupA";
import { useDispatch } from "react-redux";
import {
  DeleteLocalStorage,
  GetLocalStorage,
  SetLocalStorage,
  UpdateLocalStorage,
} from "../../Helpers/LocalStorage/LocalStorage";
import { LogOut, verifyOtp } from "../../Actions/UserA";
import { useNavigate } from "react-router-dom";

const VerifyUser = () => {
  const Navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const Dispatch = useDispatch();
  const { setverifyOtp } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  let InputBoxStyle = `w-full rounded-[6px] outline-0 bg-[#F3F6F7] text-gray-400 h-[45px]  border-[1px] border-solid
    text-4xs md:text-[16px]   lg:text-[16px]     xl:[16px]      
    py-[6px]                    xl:py-3
    px-[8px]                    xl:px-[22px]    2xl:px-7`;

  const axiosInstance = axios.create({
    baseURL: BaseUrl,
    withCredentials: true,
  });

  const handleVerifyOtp = async (payload) => {
    setLoading(true);
    try {
      const response = await Dispatch(verifyOtp(payload));
      if (response?.User?.UserId) {
        setverifyOtp(false);
        setLoading(false);
        DeleteLocalStorage("Usertoken");
        if (GetLocalStorage("navigateToEnroll")) {
          Dispatch(DoneLoginSignUp(false));
          const timeout = setTimeout(() => {
            Navigate(`/Enrollment/Course`, {
              state: { InstituteCourseId: GetLocalStorage("navigateToEnroll") },
            });
            DeleteLocalStorage("navigateToEnroll");
          }, 500);
          return () => clearTimeout(timeout);
        }
      }

      // Handle the response as needed
    } catch (error) {
      console.error("Verification failed:", error);
      setError(error?.response?.data?.message);
      setLoading(false);
      // Handle the error as needed;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("submit", otp);

    if (otp.length < 5) {
      setError("invalid otp");
      return;
    }

    const token = GetLocalStorage("Usertoken").token;
    console.log("invalid token ", token);

    const payload = {
      token: token,
      OTP: otp,
    };

    await handleVerifyOtp(payload);
    console.log("Usertoken handleVerifyOtp", GetLocalStorage("Usertoken"))

    //   try
    //   {
    //     setLoading(true);
    //     const res = await axiosInstance.post("/api/verify-registeredEmail", payload);
    //     console.log("ser",res);
    //     if(res?.status === 200){
    //       setverifyOtp(false)
    //       if(GetLocalStorage('navigateToEnroll')){
    //         Dispatch(DoneLoginSignUp(false));
    //         setTimeout(() => {
    //           // Navigate(`/course/${GetLocalStorage('navigateToEnroll')}`)
    //           Navigate(`/Enrollment/Course`, { state: { InstituteCourseId: GetLocalStorage('navigateToEnroll') } })
    //           DeleteLocalStorage('navigateToEnroll')
    //         }, 500);

    //     }else {
    //         Dispatch(DoneLoginSignUp(false));
    //     }

    //     }else if(res?.data?.success === false){
    //       setError(res?.data?.message)
    //     }
    //     setLoading(false);
    //   } catch (err) {
    //     setLoading(false);
    //     setError(err?.response?.data?.message)
    //   }
  };

  const otpResend = async()=> {
    const email = GetLocalStorage("Usertoken").email;
  const paylaod = { email };
    try{
      const res = await axiosInstance.post("/api/resend-otp", paylaod)
      if(res.status === 200) {
        const {ResetPasswordToken, ResetPasswordExpire} = res?.data?.UserEmailValidation;
        UpdateLocalStorage("Usertoken", {
          token : ResetPasswordToken,
          expire: ResetPasswordExpire
       });
      }
    }catch(error){
      console.log(error)
    }
  }

  const onChangeHandler = (e) => {
    setOtp(e.target.value);
    if (error !== "") {
      setError("");
    }
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 "
      style={{ zIndex: "200000" }}
    >
      <form
        onSubmit={handleSubmit}
        className="BG_IMAGEE w-[80%] rounded-[24px]  bg-[#e1e7f0] py-10 px-10 text-white sm:w-[380px] sm:p-10 md:w-[686px] md:px-[4rem] 2xl:pb-14 3xl:pb-14"
      >
        <span
          onClick={()=> setverifyOtp(false)}
          class="material-symbols-outlined absolute right-[1rem] top-[1rem] cursor-pointer font-semibold text-primary"
          style={{
            fontSize: "30px",
          }}
        >
          close
        </span>
        <div className="flex  w-full items-center justify-center">
          <img
            className="h-[40px] w-[220px]  md:h-[60px] md:w-[328px]"
            src={require("../Assets/forgotLog.png")}
            alt=""
          />
        </div>

        <div className="mt-[30px]  mb-[1rem] flex w-full flex-col items-center justify-center text-[#1D1D1D]">
          <h2 className="text-3xs font-bold md:text-xs 2xl:text-[24px]">
            Verify OTP
          </h2>
          <p className=" my-3 mt-3 text-center text-4xs font-semibold md:px-5 md:text-2xs  2xl:text-xs">
            Please check your email, we have sent Verification Code
            {/* (OTP) to
            reset your password */}
          </p>
        </div>

        {error ? <h4 className="font-normal text-[red]"> {error} </h4> : null}

        {/* inputs */}
        <div>
          <span className="flex flex-col">
            <input
              id="otp"
              className={`${
                error ? "border-[red]" : "border-[#BCBCBC]"
              } ${InputBoxStyle} max-w-[650px ] `}
              type={`number`}
              placeholder="Enter Otp"
              required
              value={otp}
              onChange={onChangeHandler}
            />
          </span>
        </div>

        <div className="">
          <span
            className={`submit-btn-box-shadow relative mt-4 flex w-full justify-center`}
          >
            <button
              className={`h-[45px] w-full cursor-pointer rounded-[8px] border-none bg-primary  py-2
                           text-[16px] text-white md:text-[18px]  xl:py-3 
                            xl:text-[18px]    `}
              type="submit"
            >
              Verify OTP
              {loading ? (
                <span className="ml-4">
                  <svg
                    aria-hidden="true"
                    role="status"
                    class="me-3 inline h-5 w-5 animate-spin text-white"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              ) : null}
            </button>
          </span>
        </div>

        <div className="mt-2 flex items-center justify-center text-[#1D1D1D]">
          <p>
            Didn't Receive Any Code?{" "}
            <span
              onClick={otpResend}
              className="cursor-pointer md:text-[#3B5998]"
            >
              {" "}
              Resend Code
            </span>
          </p>
        </div>
      </form>
    </div>
  );
};

export default VerifyUser;
