
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "./carouselSec.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// Import required modules
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { BaseUrl } from "../../../Actions/Base";

const CarouselSec = ({ vehicles, setSelectedVehicle  }) => {
  const updatedVehicle = vehicles?.map((vehicle)=> {
    return {...vehicle,VehicleImages: [vehicle.VehicleImages[0]]}
  })
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const handleSlideChange = (swiper) => {
    setSelectedVehicle(vehicles[swiper.activeIndex]);
  };
console.log("vehicles==>>", vehicles)
console.log("updatedVehicle==>>", updatedVehicle)
  return (
    <div className="max-h-[500px] w-[7300px] min-w-[400px] max-w-[800px]">
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        loop={false}
        spaceBetween={5}
        navigation={true}
        thumbs={{
          swiper: thumbsSwiper && thumbsSwiper.params ? thumbsSwiper : null,
        }}
        onSlideChange={handleSlideChange}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
      >
        {vehicles.map((vehicle) => (
          <SwiperSlide key={vehicle.VehicleImages?.[0]?.Vehicle_ImageId}>
            {console.log("vehicle=>>", vehicle)}
            <img
              src={`${BaseUrl}/api/vehicleimage?url=${vehicle.VehicleImages?.[0]?.VehicleImageLink}`}
              alt={`vehicle image`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        loop={false}
        spaceBetween={10}
        slidesPerView={vehicles?.length}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >
        {vehicles.map((vehicle) => (
          <SwiperSlide key={vehicle.VehicleImages?.[0]?.Vehicle_ImageId}>
            <img
              src={`${BaseUrl}/api/vehicleimage?url=${vehicle.VehicleImages?.[0]?.VehicleImageLink}`}
              alt={`vehicle image`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CarouselSec;