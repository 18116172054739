import React from "react";
import LeftCards from "./LeftCards/LeftCards";
import RightCards from "./RightCards/RightCards";
import { GetLocalStorage } from "../../../../../Helpers/LocalStorage/LocalStorage";
import { BaseUrl } from "../../../../../Actions/Base";

const TopCards = () => {

    //local storage values
    const localStorage = GetLocalStorage('UserInfo');
    const InstituteAdmin = localStorage?.InstituteUserType === "Admin";
    const Instructor = localStorage?.InstituteUserType === "Instructor";
    const InstiteStaffAnSuperVisor = localStorage?.InstituteUserType === "Staff" || localStorage?.InstituteUserType === "Supervisor";
    const InstituteStafAndSupAndAdmin = InstituteAdmin || InstiteStaffAnSuperVisor;
    const InstituteName = localStorage?.Institute?.InstituteName;
    
    return (
        <>
            {
                InstituteStafAndSupAndAdmin ? <>
                <div className="intitute_logo_section flex gap-4 py-4 justify-center md:justify-start">
                    <div className="h-20 w-20 object-cover Logo_image border-2 border-solid border-violet-100 rounded-full">
                       <img className="w-full rounded-full h-full" src={`${BaseUrl}/api/Images/InstituteLogo?url=${localStorage?.Institute?.InstituteLogo}`} alt="InstituteLogo" />
                    </div>
                 <h2
                        style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                        className=" heading_1 text-center content-center md:text-start font-bold text-[#4C34AD] pb-4"
                    >
                        {InstituteName} ({localStorage?.InstituteUserType})
                    </h2>
                    </div>
                    </> : null
            }
            {/* {InstituteAdmin ? (
                <>
                    <h2
                        style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                        className="text-xs text-center md:text-start font-bold text-[#4C34AD] sm:text-sm md:text-base lg:text-[29px] xl:text-lg 2xl:text-[42px]"
                    >
                        Hello! Admin
                    </h2>
                </>
            ) : null} */}

            {/* {InstiteStaffAnSuperVisor ? (
                <>
                    <h2
                        style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                        className=" text-center md:text-start text-xs font-bold text-[#9E57F3] sm:text-sm md:text-base lg:text-[29px] xl:text-lg 2xl:text-[42px]"
                    >
                        Hello!{" "}
                        {localStorage
                            .FirstName.charAt(0)
                            .toUpperCase() +
                            localStorage.FirstName.slice(1)}
                    </h2>
                </>
            ) : null} */}
            <div className="flex w-full flex-col gap-5 xl:flex-row  rounded-[15px]lg:px-[2rem]">
                <div className="h-fit min-w-[300px] self-center  shadow w-[332px] md:w-full lg:max-w-[100%] gap-5 rounded-[15px] border-[1px] bg-white border-solid border-[#D4D4D4] px-[26px] py-[20px] ">
                   
                        <>
                            <h2
                                style={{
                                    fontFamily: "'Source Sans Pro', sans-serif",
                                }}
                                className="heading_2 text-center md:text-start md:truncate md:hover:text-clip font-semibold text-[#4C34AD] "
                            >
                                {/* Hello!{" "}
                {localStorage.FirstName.charAt(0).toUpperCase() +
                  localStorage.FirstName.slice(1)} */}
                                Select an attribute for more information
                            </h2>
                        </>
                    
                    <LeftCards />
                </div>
                <RightCards />
            </div>
        </>
    );
};

export default TopCards;
