// react States
import React, { Component } from "react";

// images
import addCourse4FilterDropdown from "../../CourseDetails/Assets/addCourse4FilterDropdown.png";

// css
// import '../../CourseSelection/CourseSelection.css';

class AddCoursedashboardTabs extends Component {
    render() {
        return (
            <div className="addCourse4Heading bg-green ">
                {/* <h4 className="text-[#4C34AD]">Instuctors</h4> */}
                <div className="FilterInstructorDiv bg-white">
                    <p className="text-[#174A84]">
                        Filter Instructor{" "}
                        <span>
                            <img
                                src={addCourse4FilterDropdown}
                                alt="addCourse4FilterDropdown"
                            />
                        </span>
                    </p>
                </div>
            </div>
        );
    }
}
export default AddCoursedashboardTabs;
