import React from "react";

function InfoSummer({ distance, duration }) {
  return (
    <div className="hidden lg:absolute top-0 right-0 rounded-md bg-[#e5e5e7]">
      <h2 className="w-full  py-4 text-center text-4xs text-primary">
        Travel Summer
      </h2>
      <div className="flex items-center justify-between gap-10  p-10">
        <div className="felx flex-col items-center justify-center gap-2">
          <p>Distance</p>
          <p className="text-[#664ef0]">{distance}</p>
        </div>
        <div className="felx flex-col items-center justify-center gap-2">
          <p>Duration</p>
          <p className="text-[#664ef0]">{duration}</p>
        </div>
      </div>
    </div>
  );
}

export default InfoSummer;
