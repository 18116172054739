import { useContext } from "react";
import { useDispatch } from "react-redux";
import { OpenLoginSignUp } from "../../../../../Actions/ToggleSignupA";
import { TermsContext } from "../UserSigning";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../../../context/authContext";

export function OtherOption({
  MobileDisplay,
  AuthPageName,
  DesktopDisplay,
  setCredentials,
}) {
  const Dispatch = useDispatch();

  const OnClick = () => {
    if (AuthPageName === "Sign Up") Dispatch(OpenLoginSignUp("Sign In", true));
    else Dispatch(OpenLoginSignUp("Sign Up", true));
    console.log("Hello");

    setCredentials({
      Email: "",
      Password: "",
      ConfirmPassword: "",
      FirstName: "",
      LastName: "",
    });
  };
  return (
    <p
      className={`${MobileDisplay} md:${DesktopDisplay} text-3xs text-white md:mt-4 md:text-[16px]  md:text-black 2xl:text-2xs`}
    >
        {AuthPageName === "Sign Up" ? "Already have an account? " : "Don't have an account yet? "}
      
      <button
        type="button"
        onClick={OnClick}
        className="cursor-pointer border-none bg-[#ffffff00] text-3xs  text-white   md:text-[16px] font-semibold md:text-[#0800E2] 2xl:text-2xs"
      >
        {AuthPageName === "Sign Up" ? " Sign In " : " Sign Up"}
       
      </button>
    </p>
  );
}

export function OnRegister({
  MobileDisplay,
  AuthPageName,
  DesktopDisplay,
  Credentials,
}) {
  const [TermsAgreed, setTermsAgreed, CheckBoxRef] = useContext(TermsContext);
  const OnClick = (e) => {
    setTermsAgreed(e.target.checked);
    const CheckErrorLabel =
      CheckBoxRef.current.classList.contains("outline-[red]");

    if (e.target.checked && CheckErrorLabel) {
      CheckBoxRef.current.classList.remove("outline-[red]");
      CheckBoxRef.current.classList.add("outline-[#707070]");
    } else if (!e.target.checked && !CheckErrorLabel) {
      CheckBoxRef.current.classList.add("outline-[red]");
      CheckBoxRef.current.classList.remove("outline-[#707070]");
    }
  };

  const {setTerms} = useAuthContext()
  const Dispatch = useDispatch();

  const handleClickk =()=> {
    // Dispatch(OpenLoginSignUp("Sign Up", false));
    setTerms(true)
  }
  return (
    <>
      {/* <OtherOption MobileDisplay={MobileDisplay} Credentials={Credentials} DesktopDisplay={DesktopDisplay} AuthPageName={AuthPageName} /> */}
      <span className={`mb-2 flex items-center gap-[6px] xl:gap-4`}>
        <input
          className="z-[1] h-4 w-4 rounded-[4px] border-0 outline-0 md:h-6 md:w-6"
          type="checkbox"
          name=""
          id=""
          checked={TermsAgreed}
          onChange={OnClick}
          ref={CheckBoxRef}
        />
        <p className="text-6xs text-white  md:text-black lg:text-[15px] 2xl:text-[21px]">
          I agree to the{" "}
          <span
            onClick={handleClickk}
            className="text-6xs text-[#A1A3EF]  lg:text-[15px] 2xl:text-[21px] cursor-pointer"
            style={{ textDecoration: "none" }}
          >
            Terms and Conditions
          </span>
        </p>
      </span>
    </>
  );
}

export function OnLogin(setCredentials) {
    const {setForgotPass} = useAuthContext()
  const Dispatch = useDispatch();
  const OnClick = () => {
    // Dispatch(OpenLoginSignUp("Sign Up", true));
    Dispatch(OpenLoginSignUp("", false));
    setForgotPass(true)

    console.log('hiii')
    // setCredentials({
    //   Email: "",
    //   Password: "",
    //   ConfirmPassword: "",
    //   FirstName: "",
    //   LastName: "",
    // });
  };
  return (
    <>
      <span
      onClick={OnClick}
       className="text-4xs text-white no-underline md:text-[18px] md:text-[#3B5998] 2xl:text-[26px] cursor-pointer">
        Forgot Password?
      </span>
      {/* <p className=" text-white md:text-black">
        Don't have an account yet?
        <span
          onClick={OnClick}
          className="cursor-pointer text-white no-underline md:text-[#0800E2]"
        >
          Sign Up
        </span>
      </p> */}
    </>
  );
}
