import React, { useEffect, useState } from "react";
import InstituteTemplate from "../../../Components/InstituteTemplate/InstituteTemplate";
import { BaseUrl } from "../../../Actions/Base";
import "./AddStaff.css";
import { useDispatch, useSelector } from "react-redux";
import useCheckLogin from "../../../Helpers/CustomHooks/CheckLogin";
import {
    CreateInstituteStaffsA,
    GetSingleInstituteStaffMemberA,
    GetUpdateInstituteStaffsA,
} from "../../../Actions/StaffA";
import {
    ScrollElement,
    ScrollSingleElement,
} from "../../../Helpers/ScrolltoElement";
import {
    validateEmail,
    validatePhoneNumber,
} from "../AddInstructor/AddInstructor";
import { DateInput } from "../../../Components/InputComps/DateComps";
import SearchLocationByInput from "../AddInstructor/Components/InstructorInfo/components/GooglePlacesApi/SearchLocationByInput";
import { CountryOptions } from "../AddInstructor/Components/OptionsArr/OptionsArr";
import { Country, State, City } from "country-state-city";
import LoadingSpinner from "../../../Components/LoadingSpinner/LoadingSpinner";
import InstructorPopup from "../AddInstructor/Components/Popup/InstructorPopup";
import StaffInfo from "./StaffInfo";
import { useParams } from "react-router-dom";

const Staff = () => {
    const { SingleStaff, error, loading } = useSelector(
        (Store) => Store.StaffReducer
    );
    useCheckLogin(true, ["Institute"], ["Staff", "Admin"]);

    console.log("Staff", SingleStaff);
    const [selectedCountry, setSelectedCountry] = useState();
    const [cities, setCities] = useState([]);
    const [selectedProvince, setSelectedProvince] = useState();
    const [countries, setCountries] = useState([]);
    const [SelectedCoordinates, setSelectedCoordinates] = useState("");
    const [allFieldsFilled, setAllFieldsFilled] = useState(false);
    const [isProfileImageChanged, setIsProfileImageChanged] = useState(false);
    const [inputValues, setInputvalues] = React.useState({
        FirstName: "",
        LastName: "",
        Address: "",
        PostalCode: "",
        Province: "",
        Country: "",
        City: "",
        DOB: "",
        Email: "",
        PhoneNumber: "",
        EmergencyPhoneNumber: "",
        Role: "",
        Gender: "",
        ProfileImage: "",
    });
    const [Err, setErr] = React.useState({});
    const [success, setSuccess] = useState(false);
    const Dispatch = useDispatch();
    //for validation
    const { StaffId } = useParams();
    // console.log("setINputvalues", inputValues);
    useEffect(() => {
        if (StaffId) Dispatch(GetSingleInstituteStaffMemberA(StaffId));
    }, [Dispatch, StaffId]);

    useEffect(() => {
        //creating a deepcopy
        // const sStaff = JSON.parse(JSON.stringify(SingleStaff));
        if (Object.entries(SingleStaff).length > 0) {
            setInputvalues({
                ...inputValues,
                // ...sStaff,
                UserId: SingleStaff.UserId || "",
                StaffId: SingleStaff.Staff.StaffId || "",
                Email: SingleStaff.Email || "",
                PhoneNumber: SingleStaff.PhoneNumber || "",
                FirstName: SingleStaff.FirstName || "",
                LastName: SingleStaff.LastName || "",
                Address: SingleStaff.Staff.Address || "",
                Country: SingleStaff.Staff.Country || "",

                PostalCode: SingleStaff.Staff.PostalCode || "",
                Province: SingleStaff.Staff.Province || "",
                City: SingleStaff.Staff.City || "",
                DOB: SingleStaff.Staff.DOB || "",
                EmergencyPhoneNumber:
                    SingleStaff.Staff.EmergencyPhoneNumber || "",
                Role: SingleStaff.Staff.Role || "",
                Gender: SingleStaff.Staff.Gender || "",

                ProfileImage: `${BaseUrl}/api/images/Staff?url=${SingleStaff?.Staff?.ProfileImage}`,
            });
        }
    }, [SingleStaff]);
    useEffect(() => {
        let countries = Country.getAllCountries();
        setCountries(countries);
    }, []);
    const ProvinceOptions = () => {
        let states = [];

        if (typeof inputValues?.Country === "string") {
            let countries = Country?.getAllCountries();

            const country = countries?.find(
                (country) =>
                    country?.name.toLowerCase() ===
                    inputValues?.Country?.toLowerCase()
            );

            if (country) {
                states = State?.getStatesOfCountry(country?.isoCode);
                // console.log("country", states);
            }
        }

        console.log("States", states);
        return states?.map((country) => (
            <option value={country.name} key={country.isoCode}>
                {country?.name}
            </option>
        ));
    };
    // useEffect(() => {
    //     const filled = Object.values(inputValues).every((value) => {
    //         const stringValue = value.toString();
    //         return stringValue?.trim() !== "";
    //     });
    //     setAllFieldsFilled(filled);
    // }, [inputValues]);

    useEffect(() => {
        const selectedCountry = inputValues?.Country;
        const selectedProvince = inputValues?.Province;
        if (
            typeof selectedCountry === "string" &&
            typeof selectedProvince === "string"
        ) {
            const country = Country?.getAllCountries()?.find(
                (country) =>
                    country?.name.toLowerCase() ===
                    selectedCountry?.toLowerCase()
            );
            if (country) {
                const states = State?.getStatesOfCountry(country?.isoCode);
                const selectedProvinceData = states?.find(
                    (province) =>
                        province.name.toLowerCase() ===
                        selectedProvince?.toLowerCase()
                );
                if (selectedProvinceData) {
                    const citiesData = City.getCitiesOfState(
                        country?.isoCode,
                        selectedProvinceData?.isoCode
                    );
                    setCities(citiesData);
                }
            }
        }
    }, [inputValues, selectedCountry, selectedProvince]); // Re-run the effect whenever selectedCountry or selectedProvince changes

    const CityOptions = () => {
        return (
            cities &&
            cities?.map((city, index) => (
                <option value={city?.name} key={index}>
                    {city?.name}
                </option>
            ))
        );
    };

    useEffect(() => {
        if (error?.response?.data) {
            setErr(error?.response?.data);
            if (error?.response?.data)
                for (const [key] of Object.entries(error?.response?.data)) {
                    if (key) {
                        ScrollSingleElement(key, 200);
                        break;
                    }
                }
        }
    }, [error]);
    // console.log("values of forms", inputValues);

    const handleCancel = () => {
        setInputvalues({
            FirstName: "",
            LastName: "",
            Address: "",
            PostalCode: "",
            Province: "",
            City: "",
            DOB: "",
            Email: "",
            PhoneNumber: "",
            EmergencyPhoneNumber: "",
            Role: "",
            Gender: "",
            // ProfileImage: "",
        });

        setErr({});
    };

    const OnChange = (e, Name, file) => {
        // console.log("Name", Name);
        let Data =
            e?.target?.files?.length > 0
                ? e?.target?.files[0]
                : e?.target?.value;
        // console.log("Data", Data);
        let formattedData = Data;
        if (Name === "ProfileImage") {
            setIsProfileImageChanged(true);
        }
        // if (Name === "Gender") {
        //     if (Data === "Others" && others) {
        //         formattedData = others;
        //     }
        // }
        // if (Name === "Country") {
        //     setSelectedCountry(e.target.value);
        // }
        // if (Name === "Province") {
        // setSelectedProvince(e.target.value);

        // setinputValues((prevData) => ({
        //     ...prevData,
        //     Province: e.target.value,
        // }));
        // setSelectedProvince(e.target.value);
        // }
        if (Name === "PostalCode") {
            // Remove all non-alphanumeric characters from the input
            const cleaned = Data.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();

            // Ensure the length of the cleaned postal code is at most 6 alphanumeric characters
            let postalCode = cleaned.substring(0, 6);

            // Format the postal code as per the Canadian format: A1A-A1A
            postalCode = postalCode.replace(
                /^([A-Za-z]\d{3})([A-Za-z]\d{3})/,
                "$1-$2"
            );

            formattedData = postalCode;
        }

        if (
            Name === "PhoneNumber" ||
            (Name === "EmergencyPhoneNumber" && typeof Data === "string")
        ) {
            // Remove all non-numeric characters from the input
            const cleaned = Data.replace(/\D/g, "");

            // Check if the cleaned data consists only of the country code "1"
            if (cleaned === "1") {
                formattedData = ""; // If yes, set formattedData to an empty string
            } else {
                // Check if the phone number starts with +1-
                if (cleaned.startsWith("1")) {
                    // If it starts with +1-, remove the prefix and format the remaining digits as XXX-XXX-XXXX
                    const PhoneNumber = cleaned
                        .substring(1)
                        .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
                    formattedData = "+1-" + PhoneNumber;
                    formattedData = formattedData.substring(0, 15);
                } else {
                    // If it doesn't start with +1-, format the phone number as +1-XXX-XXX-XXXX
                    const formattedPhoneNumber = "+1-" + cleaned;
                    formattedData = formattedPhoneNumber.substring(0, 14);
                }
            }
        }

        // if (
        //     Name === "PhoneNumber" ||
        //     (Name === "EmergencyPhoneNumber" && typeof Data === "string")
        // ) {
        //     // Remove all non-numeric characters from the input
        //     const cleaned = Data.replace(/\D/g, "");

        //     // Format the phone number as per the Canadian format: XXX-XXX-XXXX
        //     const PhoneNumber = cleaned.replace(
        //         /(\d{3})(\d{3})(\d{4})/,
        //         "$1-$2-$3"
        //     );

        //     formattedData = PhoneNumber;
        //     formattedData = formattedData.substring(0, 11);
        // }

        setInputvalues({
            ...inputValues,
            [Name]: formattedData,
        });
        if (Err?.[Name]) {
            delete Err[Name];
            setErr({ ...Err });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (
            inputValues.PhoneNumber &&
            !validatePhoneNumber(inputValues.PhoneNumber)
        ) {
            setErr({ ...Err, PhoneNumber: "Invalid Phone Number" });
            ScrollSingleElement("PhoneNumber", 200);
            return;
        }

        if (
            inputValues.EmergencyPhoneNumber &&
            !validatePhoneNumber(inputValues.EmergencyPhoneNumber)
        ) {
            setErr({ ...Err, EmergencyPhoneNumber: "Invalid Phone Number" });
            ScrollSingleElement("EmergencyPhoneNumber", 200);
            return;
        }

        if (inputValues.Email && !validateEmail(inputValues.Email)) {
            setErr({ ...Err, Email: "Invalid email Address" });
            ScrollSingleElement("Email", 200);
            return;
        }

        if (inputValues.Address && inputValues?.Address === "") {
            setErr({ ...Err, Address: "Address* is requiredddd" });
            ScrollSingleElement("Address", 200);
            return;
        }

        let Errors = { ...Err };

        console.log("inputvalues", inputValues);
        const formData = new FormData();
        let StaffDataNoImage = {};
        for (let [key, value] of Object.entries(inputValues)) {
            if (key === "ProfileImage" && !isProfileImageChanged) {
                continue; // Skip adding profile image URL to formData if it's not changed
            }
            formData.append(key, value);
        }

        // formData.append("UserId", inputValues.UserId);
        // formData.append("StaffId", inputValues.StaffId);
        // formData.append("FirstName", inputValues.FirstName);
        // formData.append("LastName", inputValues.LastName);
        // formData.append("Email", inputValues.Email);
        // formData.append("Address", inputValues.Address);
        // formData.append("Country", inputValues.Country);
        // formData.append("PostalCode", inputValues.PostalCode);
        // formData.append("Province", inputValues.Province);
        // formData.append("City", inputValues.City);
        // formData.append("DOB", inputValues.DOB);
        // formData.append(
        //     "EmergencyPhoneNumber",
        //     inputValues.EmergencyPhoneNumber
        // );
        // formData.append("PhoneNumber", inputValues.PhoneNumber);
        // formData.append("ProfileImage", inputValues.ProfileImage);
        // formData.append("Gender", inputValues.Gender);
        // formData.append("Role", inputValues.Role);
        for (let [key, value] of Object.entries(inputValues)) {
            if (
                !value ||
                (typeof value === "string" && value?.trim() === "") ||
                (Array.isArray(value) && value.length === -1)
            ) {
                Errors[key] = true;
            } else if (typeof Errors[key] !== "string") {
                Errors[key] = undefined;
            }
        }

        setErr({ ...Errors });

        ScrollElement(Errors);

        // for (let [key, value] of Object.entries(inputValues)) {
        //     if (key.indexOf("Image") > -1) formData.append(key, value);
        //     else StaffDataNoImage = { ...StaffDataNoImage, [key]: value };
        // }
        // formData.append("StaffInfo", JSON.stringify(StaffDataNoImage));
        // if (allFieldsFilled) {
        Dispatch(GetUpdateInstituteStaffsA(formData));

        // Dispatch(CreateInstituteStaffsA(formData));
        setSuccess(true); // }
    };

    // console.log("errors", Err);
    return !loading ? (
        <>
            <div className="flex w-full pt-6">
                {/* <img src={require("./Assets/Male.svg").default} alt="sales" /> */}
                <h2 className="font-['segoe ui'] ml-[8%] mt-2 justify-start text-[22px] font-black text-purpal md:text-base">
                    Update Staff Detail
                </h2>
            </div>
            <div className="gradientt my-6 w-full rounded-[35px] bg-white  md:w-11/12">
                <StaffInfo
                    inputValues={inputValues}
                    setInputvalues={setInputvalues}
                    OnChange={OnChange}
                    Err={Err}
                    setErr={setErr}
                    setSelectedCoordinates={setSelectedCoordinates}
                    readOnly={false}
                    updateOnly={true}
                    CityOptions={CityOptions}
                    ProvinceOptions={ProvinceOptions}
                />
                <div className=" text-center">
                    <button
                        onClick={handleCancel}
                        type="button"
                        class="focus:ring-purple-300 me-2  dark:border-purple-400 dark:text-purple-400 dark:hover:bg-purple-500 dark:focus:ring-purple-900 mb-2 mr-2 rounded-lg border border-[#4c34ad] px-5 py-2 text-center text-4xs font-medium text-[#4c34ad] hover:bg-[#4c34ad] hover:text-white focus:outline-none focus:ring-4 dark:hover:text-white md:text-2xs lg:text-[22px]"
                    >
                        Cancel
                    </button>

                    <button
                        onClick={handleSubmit}
                        type="button"
                        class="focus:ring-blue-300 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mt-5 mb-2 cursor-pointer rounded-lg border-0 bg-[#4c34ad] px-10 py-2  text-4xs font-medium text-white outline-none hover:bg-[#a1a3ef] focus:outline-none focus:ring-4 md:text-2xs lg:text-[22px]"
                    >
                        Save
                    </button>
                </div>
            </div>
            {success ? (
                <InstructorPopup
                    message={"Updated Successfully"}
                    type="StaffListing"
                />
            ) : null}
        </>
    ) : (
        <LoadingSpinner />
    );
};

const UpdateStaff = () => (
    <InstituteTemplate
        Element={Staff}
        bg="bg-[#F7F7F7] min-h-screen items-center"
    />
);

export default UpdateStaff;
