import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import {
  VehicleTypeReducer,
  LicenseTypeReducer,
  SpecialitiesReducer,
} from "./Reducers/CategoryR.js";
import { LoginSignupReducer, ToggleSignupReducer } from "./Reducers/UserR.js";
import { CourseReducer } from "./Reducers/CourseR.js";
import { WishListReducer } from "./Reducers/WishListR.js";
import { SocketReducer } from "./Reducers/SocketR.js";
import { VehicleReducer } from "./Reducers/VehicleR.js";
import { InstructorReducer } from "./Reducers/InstructorR.js";
import { AdminCourseReducer } from "./Reducers/AdminCourseR.js";
import { BookReducer } from "./Reducers/BookR.js";
import { StudentReducer } from "./Reducers/StudentR.js";
import { NotificationReducer } from "./Reducers/NotificationsReducer.js";
import { StaffReducer } from "./Reducers/StaffR.js";
import { ScheduleReducer } from "./Reducers/ScheduleR.js";
import { FeedbackReducer } from "./Reducers/FeedbackR.js"

//Create logger instance
const middleware = [];
if (process.env.NODE_ENV === "development") {
  const logger = createLogger();
  middleware.push(logger);
}
const Store = configureStore({
  reducer: {
    VehicleTypeReducer,
    LicenseTypeReducer,
    SpecialitiesReducer,
    VehicleReducer,
    ToggleSignupReducer,
    LoginSignupReducer,
    CourseReducer,
    SocketReducer,
    WishListReducer,
    InstructorReducer,
    AdminCourseReducer,
    BookReducer,
    StudentReducer,
    StaffReducer,
    NotificationReducer,
    ScheduleReducer,
    FeedbackReducer
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middleware), // Apply logger middleware
});

export default Store;
