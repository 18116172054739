import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import CourseAbout from "./CourseAbout/CourseAbout";
import "./CourseOverview.css";
import CourseInstructors from "./CourseInstructors/CourseInstructors";
import CourseIntro from "./CourseIntro/CourseIntro";
import CourseSyllabus from "./CourseSyllabus/CourseSyllabus";
import VehicleDetails from "./VehicleDetails/VehicleDetails";
import { useDispatch, useSelector } from "react-redux";
import { GetCourse } from "../../Actions/CourseA";
import useCheckLogin from "../../Helpers/CustomHooks/CheckLogin";
import useGetWishList from "../../Helpers/CustomHooks/useGetWishList";
import { useRef } from "react";
import NavigationButtons from "./NavigationButtons";
import { SelectInView } from "./Helpers/SelectInView";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import AwardsSection from "./CourseAwardSection/AwardsSection";
import CarouselSec from "./carouselSec/CarouselSec";
import StudentReviews from "./StudentReviews/StudentReviews";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const CourseOverview = () => {
  const { InstituteCourseId } = useParams();
  const { loading: LoginLoading } = useSelector(
    (Store) => Store.LoginSignupReducer
  );
  const { loading, CourseInfo } = useSelector((Store) => Store.CourseReducer);
  const { UserInfo } = useSelector((Store) => Store.LoginSignupReducer);

  const [DivElement, setDivElement] = useState(
    document.getElementById("PolygonDivHeight")
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetCourse(InstituteCourseId));
  }, [InstituteCourseId, dispatch, UserInfo]);

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      setDivElement(document.getElementById("PolygonDivHeight"));
    });
    if (!DivElement) setDivElement(document.getElementById("PolygonDivHeight"));
  }, [DivElement]);
  useGetWishList();
  useCheckLogin(false, ["Student"]);
  const InstructorRef = useRef();
  const CourseSyllabusRef = useRef();
  const VehicleDetailRef = useRef();
  const StudentReviewRef = useRef();
  const CourseDetailRef = useRef();
  const AwardSectionRef = useRef();

  const GetInView = () =>
    SelectInView([
      CourseDetailRef,
      CourseSyllabusRef,
      AwardSectionRef,
      InstructorRef,
      VehicleDetailRef,
      StudentReviewRef,
    ]);

  window.addEventListener("scroll", GetInView, false);
  window.addEventListener("resize", GetInView, false);

  console.log("CourseInfo<=>", CourseInfo);

 /*  const downloadCurriculum = async () => {
    const content = document.getElementById("downloadContent"); // The main content div to capture
    if (content) {
      const canvas = await html2canvas(content); // Convert content to canvas
      const imageData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imageData, "PNG", 10, 10, 190, 0); // Add image to PDF
      pdf.save("curriculum.pdf"); // Download the PDF
    }
  }; */

  const downloadCurriculum = async () => {
    const input = document.getElementById('downloadContent'); // Ensure this ID is unique to the content to capture

    // Convert the content to a canvas using html2canvas
    const canvas = await html2canvas(input, { scale: 2 }); // Increase scale for better quality

    // Get the content in JPEG format
    const imgData = canvas.toDataURL('image/jpeg', 1.0);

    // Generate the JPEG download
    const link = document.createElement('a');
    link.href = imgData;
    link.download = 'course-curriculum.jpeg';
    link.click();

    // Create a new PDF document
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    
    // Add a heading "Course Details" centered
    pdf.setFontSize(16);
    pdf.text('Course Details', pdfWidth / 2, 20, { align: 'center' });

    // Add the image to the PDF
    const imgProps = pdf.getImageProperties(imgData);
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, 'JPEG', 10, 30, pdfWidth - 20, pdfHeight - 30); // Adjust positioning as needed

    // Save the PDF
    pdf.save('course-curriculum.pdf');
  };


  return !loading ? (
    <div className="relative mt-[80px]">
      <CourseIntro CourseInfo={CourseInfo} downloadCurriculum={downloadCurriculum} />

      <div id="PolygonDivHeight" className="sm:pt-5">
        <NavigationButtons
          InstructorRef={InstructorRef}
          CourseSyllabusRef={CourseSyllabusRef}
          CourseDetailRef={CourseDetailRef}
          VehicleDetailRef={VehicleDetailRef}
          StudentReviewRef={StudentReviewRef}
          AwardSectionRef={AwardSectionRef}
          ShowAwardsSec={CourseInfo?.Institute?.Awards.length > 0}
          ShowVehicleSec={CourseInfo?.Vehicles.length > 0}
          ShowReviews={true}
        />

<div id="downloadContent">
        <CourseAbout
          CourseInfo={CourseInfo}
          CourseDetailRef={CourseDetailRef}
        />
        <CourseSyllabus
          CourseSyllabusRef={CourseSyllabusRef}
          CourseSyllabusArr={CourseInfo?.CourseSyllabuses}
        />
        {
          CourseInfo?.Institute?.Awards.length > 0 && <AwardsSection
          AwardSectionRef={AwardSectionRef}
          CourseInfo={CourseInfo}
        />
        }
      
        <CourseInstructors
          InstructorRef={InstructorRef}
          InstructorDetails={CourseInfo?.Instructors}
        />
        

        {CourseInfo?.Vehicles.length > 0 && (
          <VehicleDetails 
          vehicles={CourseInfo?.Vehicles}
          VehicleDetailRef={VehicleDetailRef} 

          />
        )}

        <StudentReviews StudentReviewRef={StudentReviewRef} />
</div>
        <Footer />
      </div>
    </div>
  ) : (
    <div className="mt-20">
      <LoadingSpinner />
    </div>
  );
};

export default CourseOverview;
