import React from "react";
import top from "./Assets/Path 293.svg";
import img1 from "./Assets/image.svg";
import "./Notification.css";
import { Link } from "react-router-dom";
import { GetLocalStorage } from "../../Helpers/LocalStorage/LocalStorage";
import { BaseUrl } from "../../Actions/Base";
function Notification({ Notifications, setShowNotifications }) {
  console.log("Notifications", Notifications);

  return (
    <div className="h-[480px] w-full max-w-[460px] bg-white shadow-[0px_2px_5px_rgba(0,0,0,0.15)]">
      <div>
        <img
          src={top}
          alt="img"
          className="float-right mt-[-9px] mr-[67px] h-[18px] w-[18]"
        ></img>
      </div>
      <div className="mt- w-full bg-white pt-4 pb-[22px] text-center ">
        <p className="flex w-full flex-col items-center justify-center text-center text-[17px] font-normal text-[#4D4F5C]">
          Notification
        </p>
      </div>
      {Notifications?.map((value) => {
        const StudentImage = `${BaseUrl}/api/images/Student?url=${value?.StudentImage}`;

        if (value !== null) {
          let to = "";
          if (value?.NotificationType === "New Enrollment")
            to = `/enrolledcourse/manage/${value?.RelatedFK}`;
          if (value?.NotificationType === "Add new course to Inventory")
            to = `/courses/recommended`;

          if (value?.RelatedFK) {
            return (
              <div
                className="px-10"
                key={value?.NotificationId}
                onClick={() => setShowNotifications(false)}
              >
                <Link to={to} className="SNotification no-underline">
                  <span className="flex w-[15%] max-w-[45px] items-center justify-center">
                    {StudentImage ? (
                      <img
                        src={StudentImage}
                        alt="img"
                        className=" h-[44px] w-[44]"
                      ></img>
                    ) : (
                      <img
                        src={``}
                        alt="img"
                        className=" h-[44px] w-[44]"
                      ></img>
                    )}
                  </span>
                  <div className="w-4/5">
                    <p className="font-[source sans pro]  mt-[9px] text-[13px] font-bold leading-[13px] text-[#4D4F5C]">
                      {value?.NotificationType}
                    </p>
                    <p className="font-[source sans pro] mt-[9px] text-[11px] font-normal leading-[13px] text-[#43425D]  opacity-50">
                      {value.Message}
                    </p>
                  </div>
                </Link>
              </div>
            );
          } else if (
            value?.NotificationType === "Instructor Assigned" &&
            GetLocalStorage("UserInfo")?.InstituteUserType === "Instructor"
          ) {
            return (
              <div
                className="px-10"
                key={value?.NotificationId}
                onClick={() => setShowNotifications(false)}
              >
                <Link to={to} className="SNotification no-underline">
                  <span className="flex w-[15%] max-w-[45px] items-center justify-center">
                    {StudentImage ? (
                      <img
                        src={StudentImage}
                        alt="img"
                        className=" h-[44px] w-[44]"
                      ></img>
                    ) : (
                      <img
                        src={``}
                        alt="img"
                        className=" h-[44px] w-[44]"
                      ></img>
                    )}
                  </span>
                  <div className="w-4/5">
                    <p className="font-[source sans pro]  mt-[9px] text-[13px] font-bold leading-[13px] text-[#4D4F5C]">
                      {value?.NotificationType}
                    </p>
                    <p className="font-[source sans pro] mt-[9px] text-[11px] font-normal leading-[13px] text-[#43425D]  opacity-50">
                      {value.Message}
                    </p>
                  </div>
                </Link>
              </div>
            );
          }
        }
        return null;
      })}
      <div className="mt-[8px] h-[0px] w-[460px] border-[1px] border-solid border-[#F1F1F3] text-center"></div>
      <p className=" font-[source sans pro] absolute inset-x-0 bottom-[23px] text-center text-[12px] font-normal leading-[18px] text-[#3B86FF]">
        See all
      </p>
    </div>
  );
}

export default Notification;
