import React from "react";
import { useState } from "react";
import useAxios from "../../../Helpers/CustomHooks/useAxios";
import { useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

function ChatFilters({ active, handleFilterChange }) {
    return (
        <div className="ml-[15px] lg: ml-[10px] flex h-[106px] items-center gap-[15px] lg:gap-[10px]  border border-l-0  border-t-0 border-solid border-[#F1F1F3]  ">
            <button
                onClick={() => handleFilterChange("Students")}
                className={
                    active === "Students"
                        ? " h-[50px] cursor-pointer rounded-[7px] border-none bg-[#4C34AD] px-2 2xl:px-5 py-2 font-sans text-xs text-white "
                        : " border-1 h-[50px] cursor-pointer rounded-[7px] border-solid border-[#4C34AD] bg-white px-2 2xl:px-5 py-2 font-sans text-xs  "
                }
            >
                Students
            </button>

            <button
                onClick={() => handleFilterChange("Staffs")}
                className={
                    active === "Staffs"
                        ? " h-[50px] cursor-pointer rounded-[7px] border-none bg-[#4C34AD] px-5 2xl:px-5 py-2 font-sans text-xs text-white "
                        : " border-1 h-[50px] cursor-pointer rounded-[7px] border-solid border-[#4C34AD] bg-white px-5 2xl:px-5 py-2 font-sans text-xs  "
                }
            >
                Staff
            </button>
            {/* <button
                onClick={() => handleFilterChange("Admin")}
                className={
                    active === "Admin"
                        ? "h-[50px] cursor-pointer rounded-[7px] border-none bg-[#4C34AD] px-5 py-2 font-sans text-xs text-white "
                        : "border-1 h-[50px] cursor-pointer rounded-[7px] border-solid border-[#4C34AD] bg-white px-5 py-2 font-sans text-xs  "
                }
            >
                Admin
            </button> */}
            <button
                onClick={() => handleFilterChange("Instructor")}
                className={
                    active === "Instructor"
                        ? " h-[50px] cursor-pointer rounded-[7px] border-none bg-[#4C34AD] px-2 2xl:px-5 py-2 font-sans text-xs text-white "
                        : "border-1 h-[50px] cursor-pointer rounded-[7px] border-solid border-[#4C34AD] bg-white px-2 2xl:px-5 py-2 font-sans text-xs  "
                }
            >
                Instructor
            </button>
        </div>
    );
}

export default ChatFilters;
