import React, { useState } from "react";
import "./VehicleDetails.css";
import CarouselSec from "../carouselSec/CarouselSec";
const VehicleDetails = ({ VehicleDetailRef, vehicles }) => {
  const [selectedVehicle, setSelectedVehicle] = useState(vehicles[0]);
  const h4Style = `text-white 
                     text-4xs                   lg:text-3xs     xl:text-2xs  SemiBold`;
  const pStyle = `text-white 
                     text-3xs       md:xs  lg:text-base   SemiBold`;
  return (
    <div className="flex flex-col items-center gap-20" ref={VehicleDetailRef}>
      <h2 className=" PrintBoldfont text-[38px]">Vehicle Details</h2>
      <div className="flex w-full flex-col items-center justify-around gap-10 px-5 md:flex-row">
        <div className="flex w-11/12 max-w-[850px] flex-col items-center justify-center md:w-2/3">
          <div className="flex w-full justify-center">
            <CarouselSec
              vehicles={vehicles}
              setSelectedVehicle={setSelectedVehicle}
            />
          </div>
          {/* <img className='w-full' src={require('./Assets/Car Image.png')} alt="" /> */}
          <div>
            <span></span>
          </div>
        </div>

        <div
          className={`Shadowa relative w-11/12
                                max-w-[800px]      bg-[#2D2366]              px-5     py-5
                                md:w-2/6                    lg:px-9     lg:py-7
                                xl:px-11     xl:py-9 `}
        >
          <div className="details Shadowa absolute  top-[3px] -left-0 -z-10 hidden h-[calc(100%_-_6px)] w-full bg-[#2D2366] md:block"></div>

          <h2
            className={`Boldfont whitespace-nowrap 
                                    text-base       text-white      md:text-[28px]      lg:text-lg      xl:text-xl`}
          >
            About Vehicle
          </h2>
          <div className="flex flex-col gap-12 pt-5">
            <span className="VehicleBorder block w-11/12 pb-2 md:pb-3 lg:pb-4 xl:pb-5">
              <h4 className={h4Style}>Company</h4>
              <p className={pStyle}>{selectedVehicle?.Manufacturer}</p>
            </span>
            <span className="VehicleBorder block w-11/12 pb-2 md:pb-3 lg:pb-4 xl:pb-5">
              <h4 className={h4Style}>Model</h4>
              <p className={pStyle}>{selectedVehicle?.Model}</p>
            </span>
            <span className="VehicleBorder block w-11/12 pb-2 md:pb-3 lg:pb-4 xl:pb-5">
              <h4 className={h4Style}>Year</h4>
              <p className={pStyle}>{selectedVehicle?.Year}</p>
            </span>
            <span className="block w-11/12 pb-2 md:pb-3 lg:pb-4 xl:pb-5">
              <h4 className={h4Style}>Description</h4>
              {/* <p className='text-white'>asdasdasdadsasdasd</p> */}
              <p
                style={{ color: "white !important" }}
                className="description text-white"
                dangerouslySetInnerHTML={{
                  __html: selectedVehicle.Description,
                }}
              ></p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleDetails;
