import React from "react";

const Schedule = ({ setShowTimeSlots, EnrollmentData, classHoursData, isClassHours }) => {
  // function to display more than one slot with some gap
  const renderTimeSlots = (day) => {
    let timeSlots;
    if(isClassHours){
      timeSlots = classHoursData?.DayandHoursData?.Schedule?.[day];
    }else {
      timeSlots = EnrollmentData?.StudentData?.Schedule?.[day];
    }
    
    // Check if timeSlots is an array (if you are storing multiple slots as an array)
    if (Array.isArray(timeSlots)) {
      // Map over the array and render each time slot with a space or line break
      return timeSlots.map((slot, index) => (
        <React.Fragment key={index}>
          <p className="text-3xs">{slot}</p>
        </React.Fragment>
      ));
    }

    // Render as a single slot if it's not an array
    return <p className="text-3xs">{timeSlots}</p>;
  };
  return (
    <div className="flex w-full flex-col gap-2">
      <h2 className="pb-6 text-xs sm:text-base md:text-lg xl:text-[38px] font-semibold">Your Class Schedule</h2>
      <div className="flex gap-8">
        <div className="w-[80px]">
          <p className="text-3xs">Monday</p>
        </div>
        <div className="flex gap-4">
          <span className="material-symbols-outlined text-[#A1A3EF]">
            radio_button_checked
          </span>
          <div className="flex flex-col gap-2">{renderTimeSlots("Monday")}</div> 
          {/* <p className="text-3xs">{EnrollmentData?.StudentData?.Schedule?.Monday}</p> */}
        </div>
      </div>
      <div className="flex gap-8">
        <div className="w-[80px]">
          <p className="text-3xs">Tuesday</p>
        </div>
        <div className="flex gap-4">
          <span className="material-symbols-outlined text-[#A1A3EF]">
            radio_button_checked
          </span>
          <div className="flex flex-col gap-2">{renderTimeSlots("Tuesday")}</div> 
          {/* <p className="text-3xs">{EnrollmentData?.StudentData?.Schedule?.Tuesday}</p> */}
        </div>
      </div>
      <div className="flex gap-8">
        <div className="w-[80px]">
          <p className="text-3xs">Wednesday</p>
        </div>
        <div className="flex gap-4">
          <span className="material-symbols-outlined text-[#A1A3EF]">
            radio_button_checked
          </span>
          <div className="flex flex-col gap-2">{renderTimeSlots("Wednesday")}</div> 
          {/* <p className="text-3xs">{EnrollmentData?.StudentData?.Schedule?.Wednesday}</p> */}
        </div>
      </div>
      <div className="flex gap-8">
        <div className="w-[80px]">
          <p className="text-3xs">Thursday</p>
        </div>
        <div className="flex gap-4">
          <span className="material-symbols-outlined text-[#A1A3EF]">
            radio_button_checked
          </span>
          <div className="flex flex-col gap-2">{renderTimeSlots("Thursday")}</div> 
          {/* <p className="text-3xs">{EnrollmentData?.StudentData?.Schedule?.Thursday}</p> */}
        </div>
      </div>
      <div className="flex gap-8">
        <div className="w-[80px]">
          <p className="text-3xs">Friday</p>
        </div>
        <div className="flex gap-4">
          <span className="material-symbols-outlined text-[#A1A3EF]">
            radio_button_checked
          </span>
          <div className="flex flex-col gap-2">{renderTimeSlots("Friday")}</div> 
          {/* <p className="text-3xs">{EnrollmentData?.StudentData?.Schedule?.Friday}</p> */}
        </div>
      </div>
    </div>
  );
};

export default Schedule;
