import { createReducer } from "@reduxjs/toolkit";

const InstructorInitialState = {
    loading: false,
    Instructor: {},
    Instructors: [],
    Staffs:[],
    error: {},
    SInstructor: {},
    CourseInstructors: [],
    InstructorTimeTable: {},
    InstructorCourses: [],
    InstructorAddress:"",
};

export const InstructorReducer = createReducer(
    InstructorInitialState,
    (builder) => {
        builder.addCase("CreateInstructorRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("CreateInstructorSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            state.Instructor = action.payload;
        });

        builder.addCase("CreateInstructorFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        //Instructors listing 
        builder.addCase("GetInstructorsRequest", (state) => {
            state.loading = true;
        });

       

       

        // //staff listings
        builder.addCase("GetStaffRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("GetStaffSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            state.Staffs = action.payload;
        });

        builder.addCase("GetStaffFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });



        builder.addCase("GetInstructorsSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            state.Instructors = action.payload;
        });

        //Related to course Instructors list,
        builder.addCase("GetCourseInstructorRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("GetCourseInstructorSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            state.CourseInstructors = action.payload;
        });

        builder.addCase("GetCourseInstructorFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        

        builder.addCase("GetInstructorsFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase("GetInstructorStudentsRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("GetInstructorStudentsSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            state.Students = action.payload;
        });

        builder.addCase("GetInstructorStudentsFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase("GetSInstructorRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("GetSInstructorSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            state.SInstructor = action.payload;
        });

        builder.addCase("GetSInstructorFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase("DeleteInstructorRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("DeleteInstructorSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            // Handle delete success if needed
        });

        builder.addCase("DeleteInstructorFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase("GetCoursesOfSInstructorRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("GetCoursesOfSInstructorSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            state.InstructorCourses = action.payload;
        });

        builder.addCase("GetCoursesOfSInstructorFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase("GetInstructorAndStudentAddressRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("GetInstructorAndStudentAddressSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
            state.InstructorAddress = action.payload;
        });

        builder.addCase("GetInstructorAndStudentAddressFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        //student otp send api
        builder.addCase("GetStudentNumberAndSendOTPRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("GetStudentNumberAndSendOTPSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
        });

        builder.addCase("GetStudentNumberAndSendOTPFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });


        //bypass otp feedback
        builder.addCase("GetBypassOtpFeedBackUpdateRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("GetBypassOtpFeedBackUpdateSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
        });

        builder.addCase("GetBypassOtpFeedBackUpdateFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        //instructor left location update
        builder.addCase("GetInstructorLeftLocationUpdateRequest", (state) => {
            state.loading = true;
        });

        builder.addCase("GetInstructorLeftLocationUpdateSuccess", (state, action) => {
            state.loading = false;
            state.error = {};
        });

        builder.addCase("GetInstructorLeftLocationUpdateFailure", (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        // getting the all classes of particular Teacher with their students
    }
);
