import React from "react";
import InstituteTemplate from "../../../../Components/InstituteTemplate/InstituteTemplate";
import Timetable from "../../../../Components/Scheduler/TimeTable/Timetable";
import ClassesIndicator from "../../../../Components/ClassesIndicator/ClassesIndicator";
import TeacherTimetablePage from "../../../Institute/TeacherTimeTable/TeacherTimeTable";

const InstSchedule = () => {
  return (
    <>
    <TeacherTimetablePage />
    </>
    // <div className="px-8 py-10">
    //   <div className="mb-10">
    //     <h2
    //       className="text-xs font-bold text-[#9E57F3] sm:text-sm md:text-base lg:text-[29px] xl:text-lg 2xl:text-[42px]"
       
    //     >
    //       Instructor Schedule
    //     </h2>
    //   </div>
    //   <div className="flex justify-between items-baseline">
    //     <div>
    //       <div className="flex w-full items-center justify-center lg:w-1/3 lg:justify-start">
    //         <ClassesIndicator text="sm:text-5xs md:text-4xs xl:text-3xs" />
    //       </div>
    //     </div>
    //     <div>
    //       <button
    //         type="button"
    //         class="focus:ring-blue-300 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mt-5 mb-2 cursor-pointer rounded-lg border-0 bg-[#4c34ad] px-10 py-2 xl:py-4 text-4xs font-medium text-white outline-none hover:bg-[#a1a3ef] focus:outline-none focus:ring-4 md:text-2xs lg:text-[22px]"
    //       >
    //         Add a Leave/Break
    //       </button>
    //     </div>
    //   </div>

    //   <div className="w-full overflow-x-scroll">
    //     <div className="w-full min-w-[700px]">
    //       <Timetable />
    //       {/* {console.log("Events", Events) }
    //         {console.log("Edit", Edit) }
    //         {console.log("Resources", Resources) } */}
    //     </div>
    //   </div>
    // </div>
  );
};

const InstructorSchedule = () => (
  <InstituteTemplate Element={InstSchedule} bg={"bg-[#F7F7F7]"} />
);

export default InstructorSchedule;
