import React from "react";
import CustomeTable from "./CustomeTable";
import CloudOffIcon from "@mui/icons-material/CloudOff";

const RecordingsList = ({ title, columns, dataSource }) => {
  return (
    <div
      className="flex w-full flex-col gap-2 rounded-lg p-5"
      style={{ border: "1px solid gray" }}
    >
      <h2 className="text-purpal">{title}</h2>
      <CustomeTable
        dataSource={dataSource ? dataSource : []}
        columns={columns}
      />
      {!dataSource && (
        <div className="flex flex-col items-center justify-center gap-2 py-11">
          <CloudOffIcon className=" text-gray-100" fontSize="large" />
          <p className=" text-center text-xs text-gray-100 ">
            Data is not available in the list!
          </p>
        </div>
      )}
    </div>
  );
};

export default RecordingsList;
