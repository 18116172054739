import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SetImagePreview } from "../../Helpers/ImagePreview/ImagePreview";
import { useAuthContext } from "../../context/authContext";
import { ImagePreviewModal } from "./LicenseImagePreviewer";
import { BaseUrl } from "../../Actions/Base";

function LastLicenseImagePreview({
    Id,
    PreviewStyle,
    BrowseBtnStyle,
    ContainerStyle,
    OnChange,
    ImgUrl,
    readOnly,
    image,
    setLicenseFullView
}) {

  
    const [isHovered, setIsHovered] = useState(false);

  
    const [Image, setImage] = useState();
    const {LicenseImage, setLicenseImage} = useAuthContext()
   
  

    const PreviewImage = (e) => {
        const file = e.target.files[0];

        if (OnChange) OnChange(e);

        if (e.target.files[0].type === "application/pdf")
            setLicenseImage(require("./Assets/PDF.svg").default);
        else SetImagePreview(setLicenseImage, file);
    };

    if (!PreviewStyle) PreviewStyle = "w-52 h-36 ";
    if (!BrowseBtnStyle) BrowseBtnStyle = "text-3xs bg-[#F3F6F7]";
    if (!ContainerStyle) ContainerStyle = "flex gap-5 ";

    useEffect(() => {
        if (ImgUrl) {
            if (typeof ImgUrl === "string" && ImgUrl?.length > 1)
                setLicenseImage(ImgUrl);
            else SetImagePreview(setLicenseImage, ImgUrl);
        }
    }, [ImgUrl, LicenseImage]);
    

    // if (isHovered) {
    //     return <ImagePreviewModal Image={Image} />;
    // }
   

    return (
        <div className={ContainerStyle}>
            <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className={`flex items-center justify-center overflow-hidden bg-[#d7d0f3]  ${PreviewStyle} ${
                        "h-[85px] min-w-[130px] md:h-[85px] md:min-w-[130px]"
                }`}
            >
               
                {
                     LicenseImage ? <img
                    className="h-[100%] w-[100%] object-contain border-[0px] border-none outline-none"
                    src={LicenseImage}
                    alt=""
                /> : image  && (
                    <img
                        className="h-[100%] object-contain w-[100%] border-[0px] border-none outline-none"
                        src={image}
                        alt=""
                    />)
                }
             
                {isHovered && LicenseImage  && (
                    <ImagePreviewModal Image={LicenseImage} />
                )} 
                
                <input
                    type="file"
                    accept="image/*"
                    capture
                    className="hidden"
                    onChange={PreviewImage}
                    id={Id}
                />
            </div>
            {!readOnly && (
                <div
                    className={"flex flex-col items-center gap-2"}
                >
                    <label
                        className={`bg-purpal h-10 w-[85px] content-center  hover:bg-[#7862d1] focus:ring-blue-300    rounded-lg px-5 py-[2px] text-6xs 2xl:text-5xs font-medium text-white focus:outline-none focus:ring-4 `}
                        htmlFor={Id}
                    >
                        Update
                    </label>
                    <button
          onClick={()=> setLicenseFullView(true)}
            type="button"
            className="bg-purpal h-10 w-[85px]  hover:bg-[#7862d1] focus:ring-blue-300    rounded-lg px-5 py-[2px] text-6xs 2xl:text-5xs font-medium text-white focus:outline-none focus:ring-4"
          >
            View
          </button>
                </div>
            )}
        </div>
    );
}
export default LastLicenseImagePreview;
