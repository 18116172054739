import { useEffect, useState } from "react";
import { useAuthContext } from "../../context/authContext";
import { SetImagePreview } from "../../Helpers/ImagePreview/ImagePreview";

function LicenseImagePreviewer({
    Id,
    PreviewStyle,
    BrowseBtnStyle,
    ContainerStyle,
    // OnChange,
    onChange,
    ImgUrl,
    type,
    readOnly,
    LicenseDetail,
    isHovered,
    licenseImg,
    setIsHovered,
    editType,
}) {
    // const {Image, setImage} = useAuthContext()
    const [Image, setImage] = useState();
    // const [isHovered, setIsHovered] = useState(false);
    // console.log("isHovered", isHovered);
    const [FileName, setFileName] = useState(""); // State to store the filename
    const [showModal, setShowModal] = useState(false);
    const PreviewImage = (e) => {
        const file = e.target.files[0];
        // console.log("file", file);
        if (onChange) onChange(e);

        if (e.target.files[0].type === "application/pdf")
            setImage(require("./Assets/PDF.svg").default);
        else SetImagePreview(setImage, file);

        // Set the filename
        setFileName(file.name);
    };

    if (!PreviewStyle) PreviewStyle = "w-20 h-20 ";
    if (!BrowseBtnStyle) BrowseBtnStyle = "text-3xs bg-[#F3F6F7]";
    if (!ContainerStyle) ContainerStyle = "flex  ";

    useEffect(() => {
        if (ImgUrl) {
            if (typeof ImgUrl === "string" && ImgUrl?.length > 1)
                setImage(ImgUrl);
            else SetImagePreview(setImage, ImgUrl);
        }
    }, [ImgUrl, Image]);
    // console.log("ImageUrl", Image);
    // const handleImageClick = (e) => {
    //     e.stopPropagation();
    //     // Logic for handling click on image based on conditions
    //     if (!LicenseDetail) {
    //         // License is false
    //         // Handle click action here (e.g., show modal)
    //         console.log("View action triggered");
    //     } else {
    //         // License is true
    //         // Handle click action here (e.g., navigate to update page)
    //         console.log("Update action triggered");
    //     }
    // };

    if (isHovered) {
        return <ImagePreviewModal Image={Image} />;
    }

    return (
        <>
            {LicenseDetail ? (
                <div className={ContainerStyle}>
                    <div
                        className={`flex hidden  items-center justify-center  overflow-hidden rounded-full rounded-xl    bg-[#F3F6F7] ${PreviewStyle} h-[10px] min-w-[70px] md:h-[70px] md:min-w-[70px]`}
                    >
                        {Image && (
                            <img
                                className="h-[100%] w-[100%] border-[0px] border-none outline-none"
                                src={Image}
                                alt=""
                            />
                        )}

                        <input
                            type="file"
                            accept="image/*"
                            capture
                            className="hidden"
                            onChange={PreviewImage}
                            id={Id}
                        />
                    </div>

                    <div className="flex flex-col items-center">
                        {LicenseDetail && isHovered && (
                            <>
                                <div className="flex items-center justify-center  ">
                                    <label
                                        className={`h-fit  w-fit cursor-pointer rounded-lg border border-[#4C34AD] bg-[#4C34AD] px-2 py-1 text-[14px] text-white shadow-[5px_6px_6px_#00000016] md:px-4 lg:text-[10px]`}
                                        onClick={() => setShowModal(true)}
                                        // htmlFor={Id}
                                    >
                                        View
                                    </label>

                                    <label
                                        className={`h-fit w-fit cursor-pointer rounded-lg border border-[#4C34AD] bg-[#4C34AD] px-2 py-1 text-[14px] text-white shadow-[5px_6px_6px_#00000016] md:px-4  lg:text-[10px]`}
                                        htmlFor={Id}
                                    >
                                        Update
                                    </label>
                                </div>
                            </>
                        )}
                        <label className="w-fit cursor-pointer" htmlFor={Id}>
                            {!isHovered && (
                                <img
                                    className="h-full w-full"
                                    src={require("./Assets/upload.svg").default}
                                    alt="user"
                                />
                            )}
                        </label>
                        {FileName && (
                            <p className="text-[12px] text-[#4C34AD] md:text-[13px] ">
                                Upload Success
                            </p>
                        )}

                        {!FileName && !licenseImg && !isHovered && (
                            <p className="text-[12px] md:text-[13px]">
                                Jpeg, PNG or Giff
                            </p>
                        )}
                    </div>
                </div>
            ) : (
                <div
                    className={ContainerStyle}
                    // onMouseEnter={() => setIsHovered(true)}
                    // onMouseLeave={() => setIsHovered(false)}
                    // onClick={handleImageClick}
                >
                    <div
                        className={`flex items-center justify-center  overflow-hidden rounded-full rounded-xl  bg-[#F3F6F7] ${PreviewStyle} h-[10px] min-w-[70px] md:h-[70px] md:min-w-[70px]`}
                    >
                        {Image && (
                            <img
                                className="h-[100%] w-[100%] border-[0px] border-none outline-none"
                                src={Image}
                                alt=""
                            />
                        )}
                        <input
                            type="file"
                            accept="image/*"
                            capture
                            className="hidden"
                            onChange={PreviewImage}
                            id={Id}
                        />
                    </div>

                    <div className="flex hidden flex-col items-center gap-2">
                        <label className="w-fit cursor-pointer" htmlFor={Id}>
                            <img
                                className="h-full w-full"
                                src={require("./Assets/upload.svg").default}
                                alt="user"
                            />
                        </label>
                        {FileName && (
                            <p className="text-[12px] text-[#4C34AD] md:text-[13px] ">
                                Upload Success
                            </p>
                        )}
                        {!FileName && (
                            <p className="text-[12px] md:text-[13px]">
                                Jpeg, PNG or Giff
                            </p>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
export default LicenseImagePreviewer;

export const ImagePreviewModal = ({ Image }) => {
    return (
        <div className="fixed top-1/2 left-1/2 z-50 flex -translate-x-1/2 -translate-y-1/2 transform flex-col items-center justify-center rounded-md bg-black bg-opacity-75 p-4">
            <img className="max-h-80vh max-w-full" src={Image} alt="" />
        </div>
    );
};

// const ImagePreviewModal = ({ Image, setShowModal }) => {
//     return (
//         <div className="fixed top-1/2 left-1/2 z-50 flex -translate-x-1/2 -translate-y-1/2 transform flex-col items-center justify-center rounded-md bg-black bg-opacity-75 p-4">
//             <img className="max-h-80vh max-w-full" src={Image} alt="" />
//             <button
//                 className="mt-4 rounded-md bg-[#4C34AD] px-4 py-2 text-xl text-white"
//                 onClick={() => setShowModal(false)}
//             >
//                 Close
//             </button>
//         </div>
//     );
// };
