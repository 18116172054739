import React from "react";
import EnrolledCourseInfo from "../../EnrolledCourses/Component/EnrolledCourseInfo/EnrolledCourseInfo";
import { useParams } from "react-router-dom";
import MyCourses from "../../../Components/MyCourses/MyCourses";
import InstituteTemplate from "../../../Components/InstituteTemplate/InstituteTemplate";
import {
    TeacherButtons,
    TeacherInfoButtonsForStaffAdmin,
} from "../../../PageNames";
import InstructorInfo from "../AddInstructor/Components/InstructorInfo/InstructorInfo";
import { useState } from "react";
import useCheckLogin from "../../../Helpers/CustomHooks/CheckLogin";
import { useEffect } from "react";
import { GetSInstructorA } from "../../../Actions/InstructorA";
import { useDispatch, useSelector } from "react-redux";
import { BaseUrl } from "../../../Actions/Base";
import { GetLicenseTypes } from "../../../Actions/CategoryA";
import "./aboutInstructorindex.css";
import LoadingSpinner from "../../../Components/LoadingSpinner/LoadingSpinner";
// const CoursesTeachingChild = () => {
//     return (
//         <div className="relative mb-20 flex flex-col items-center gap-24">
//             <div className="flex w-11/12 flex-col 2xl:w-4/5">
//                 <EnrolledCourseInfo />
//             </div>
//         </div>
//     );
// };
const AboutInstructorPage = () => {
    const { error, loading, SInstructor } = useSelector(
        (Store) => Store.InstructorReducer
    );

    const [InstructorData, setInstructorData] = useState({
        FirstName: "",
        LastName: "",
        Address: "",
        PostalCode: "",
        Province: "",
        City: "",
        Email: "",
        PhoneNumber: "",
        GuardianPhoneNumber: "",
        Gender: "",
        DOB: "",
        Speciality: "",
        LicenseNumber: "",
        LicenseExpiry: "",
        SpecialLicenseNumber: "",
        SpecialLicenseExpiry: "",
        ProfileImage: "",
        LicenseImage: "",
        TrainerPermitImage: "",
        SpecialLicenseImage: "",
        Speciality: [],
    });
    const [Success, setSuccess] = useState();
    const [Err, setErr] = useState();
    const [SpecialityName, setSpecialityName] = useState([]);

    const Dispatch = useDispatch();

    const { InstructorId } = useParams();
    useCheckLogin(true, ["Institute"], ["Staff", "Admin", "Instructor"]);

    useEffect(() => {
        if (InstructorId) Dispatch(GetSInstructorA(InstructorId));
    }, [Dispatch, InstructorId]);

    useEffect(() => {
        const sinstructor = JSON.parse(JSON.stringify(SInstructor));
        if (Object.entries(SInstructor).length > 0) {
            delete sinstructor.FromInstitute;
            setInstructorData({
                ...InstructorData,
                ...sinstructor,
                ProfileImage: `${BaseUrl}/api/images/Instructors?url=${SInstructor?.ProfileImage}`,
                // LicenseImage: `${BaseUrl}/api/images/Instructors?url=${SInstructor?.LicenseImage}`,
                // LicenseImageTwo: `${BaseUrl}/api/images/Instructors?url=${SInstructor?.LicenseImageTwo}`,
                TrainerPermitImage: `${BaseUrl}/api/images/Instructors?url=${SInstructor?.TrainerPermitImage}`,
                // SpecialLicenseImage: `${BaseUrl}/api/images/Instructors?url=${SInstructor?.SpecialLicenseImage}`,
                // SpecialLicenseImageTwo: `${BaseUrl}/api/images/Instructors?url=${SInstructor?.SpecialLicenseImageTwo}`,
            });
            setSpecialityName(...SpecialityName, SInstructor?.Speciality);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SInstructor]);

    useEffect(() => {
        Dispatch(GetLicenseTypes());
    }, [Dispatch]);
    console.log("INstuctorDAATA", SInstructor);
    return (
        <MyCourses
            ButtonsInfo={TeacherInfoButtonsForStaffAdmin}
            PageName="AboutInstructor"
            Param={InstructorId}
            HideFooter={true}
            DisplayName={
                InstructorData &&
                `
                ${InstructorData?.FirstName} 
                ${InstructorData?.LastName}
                `
            }
        >
            <div className="">
                <InstructorInfo
                    InstructorData={InstructorData}
                    setInstructorData={setInstructorData}
                    Err={Err}
                    setErr={setErr}
                    readOnly={true}
                    SpecialityName={SpecialityName}
                    setSpecialityName={setSpecialityName}
                />
            </div>
        </MyCourses>
    );
};
const AboutInstructor = () => {
    return (
        <InstituteTemplate Element={AboutInstructorPage} BG="white" mt="0" />
    );
};
export default AboutInstructor;
