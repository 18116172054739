import React from "react";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const CustomeTable = ({ columns, dataSource }) => {
  return (
    <TableContainer>
      <MuiTable>
        <TableHead className="bg-[#9C43F5]">
          <TableRow>
            {columns?.map((column, index) => (
              <TableCell
                key={index}
                sx={{ color: "white", border: "1px solid #A5C9FF" }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataSource?.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns?.map((column, colIndex) => (
                <TableCell
                  key={colIndex}
                  style={{ border: "1px solid #A5C9FF" }}
                >
                  {column.render
                    ? column.render(row[column.field], row)
                    : row[column.field]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default CustomeTable;
