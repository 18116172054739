import React from "react";
import Chart from "react-apexcharts";

const PieDonutChart = ({ plotOptions, colors, labels, series, responsive }) => {
    const options = {
        chart: {
            type: "donut",
            width: "80%",
            height: "70%",
        },
        plotOptions: {
            pie: {
                donut: {
                    size: "65%",
                },
            },
        },
        series,
        labels,
        colors,
        responsive: [
            {
                breakpoint: 0,
                options: {
                    chart: {
                        width: "100%",
                    },
                    legend: {
                        position: "bottom",
                    },
                },
            },
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: "100%",
                        height: "400px",
                    },
                    legend: {
                        position: "bottom",
                    },
                },
            },
            {
                breakpoint: 768,
                options: {
                    chart: {
                        width: "100%",
                        height: "100%",
                    },
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + "% courses";
                },
            },
        },
        dataLabels: {
            enabled: true,
        },
        legend: {
            show: true,
            position: "right",
            fontSize: "12px",
            labels: {
                colors: ["#333"],
            },
        },
    };

    return (
        <div id="chart">
            <Chart
                options={options}
                series={options.series}
                type="donut"
                height={550}
                width={350}
            />
        </div>
    );
};

export default PieDonutChart;
