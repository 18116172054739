import SearchIcon from "./Assets/SearchIcon.svg";
// import Logo from "./Assets/vli_logo.png";
import { Link } from "react-router-dom";
function StudentLeftSide({ setShowSidebar }) {
    return (
        <nav
            className={`ml-0 flex w-full items-center gap-0 
                    py-2  px-3  pt-3 
                    sm:gap-10  md:py-0
                    lg:w-full
                    lg:gap-[10px] xl:ml-10 xl:gap-[10px] 2xl:ml-36 2xl:gap-[10px]`}
        >
            <img
                src={require("./Assets/HamburgerIcon.svg").default}
                onClick={() => setShowSidebar(true)}
                className=" mx-2 lg:hidden"
                alt=""
            />
            <Link to="/" className="flex items-center justify-center">
              
                    <img
                        className="w-[150px] sm:w-[300px] lg:w-[240px] "
                        alt="Logo"
                        id="Logo"
                        src={"/assets/vli-logo.png"}
                    />
               
            </Link>

            <img
                className="mx-1 mr-2 cursor-pointer text-xs sm:mr-4 xl:mx-1 "
                alt="SearchIcon"
                id="SearchIcon"
                src={SearchIcon}
            />
        </nav>
    );
}

export default StudentLeftSide;
