import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteInstituteCoursesA,
  InstituteCoursesA,
} from "../../../../../Actions/CourseA";
import { GeneralEvent } from "../../../../../Actions/Events/FilterA";
import { SocketContext } from "../../../../../App";
import LoadingSpinner from "../../../../../Components/LoadingSpinner/LoadingSpinner";
import NoDataResponse from "../../../../../Components/NoDataResponse";
import CoursesTiles from "./Components/CoursesTiles";
import { Option, Select } from "../../../../../Components/SelectList";
import "./recentCourses.css";

function RecentCourses({ setInstituteCourseId, setEdit }) {
  const [filterCourses, setFilterCourse] = useState({});
  const { Courses, loading } = useSelector((Store) => Store.CourseReducer);
  const Dispatch = useDispatch();
  const [CourseType, setCourseType] = useState("All Courses");
  const Socket = useContext(SocketContext);
  const { UserInfo } = useSelector((Store) => Store.LoginSignupReducer);
  const [DeleteCourseId, setDeleteCourseId] = useState();
  const [showPopup, setShowPopup] = useState(false);
  // const [LicenceType, setLicenceType] = useState()

  const { LicenseTypes } = useSelector((Store) => Store.LicenseTypeReducer);

  const handleDeleteCourse = () => {
    Dispatch(DeleteInstituteCoursesA(DeleteCourseId)).then(() =>
      Dispatch(InstituteCoursesA())
    );
    setShowPopup(false);
    //   .catch((error) => console.error("Error deleting course", error));
  };
  const DeleteCourse = (InstituteCourseId) => {
    setDeleteCourseId(InstituteCourseId);
    setShowPopup(true);
    // Dispatch(DeleteInstituteCoursesA(InstituteCourseId))
    //   .then(() => Dispatch(InstituteCoursesA()))
    //   .catch((error) => console.error("Error deleting course", error));
  };

  const OnClick = (Value) => {
    console.log("Value", Value);
    setCourseType(Value);
    Dispatch(GeneralEvent(null, "GetInstituteCoursesRequest"));
    Socket.emit("FilterInsAdminCourses", {
      Course: Value,
      InstituteId: UserInfo?.Institute?.InstituteId,
    });
  };
  // useEffect(()=> {
  //    setLicenceType(LicenseTypes)
  // }, [LicenseTypes])

  useEffect(() => {
    Dispatch(InstituteCoursesA());
  }, [Dispatch]);
  useEffect(() => {
    Socket?.on("FilteredInsAdminCourses", (CourseArr) =>
      Dispatch(GeneralEvent(CourseArr, "GetInstituteCoursesSuccess"))
    );
  }, [Dispatch, Socket]);

  const CourseEditClick = (Id) => {
    setEdit(true);
    setInstituteCourseId(Id);
  };
  // bg-[#F7F7F7]

  // hover
  const [isPopupVisible, setPopupVisibility] = useState(false);

  const handleMouseEnter = () => {
    setPopupVisibility(true);
  };

  const handleMouseLeave = () => {
    setPopupVisibility(false);
  };

  //select options
  const courseProgress = ["Inprogress", "Approved", "Rejected"];
  const SortByDate = ["Old to New", "New to Old"];

  const onChange = (value, fieldName) => {
    if (fieldName === "LicenseTypeFK") {
      setFilterCourse((prev) => ({
        ...prev,
        [fieldName]: value?.text,
      }));
    } else {
      setFilterCourse((prev) => ({
        ...prev,
        [fieldName]: value?.text,
      }));
    }
  };

  const [ApplyFilterCourses, setApplyFilterCourses] = useState([]);

  useEffect(() => {
    submitFilters();
  }, [Courses, filterCourses]);

  const submitFilters = () => {
    let filteredCourses = Courses;

    filteredCourses = filterByCourseProgress(filteredCourses);
    filteredCourses = filterByLicenseType(filteredCourses);
    filteredCourses = sortByDate(filteredCourses, filterCourses.sortByDate);

    setApplyFilterCourses(filteredCourses);
  };

  const filterByCourseProgress = (courses) => {
    if (filterCourses.courseProgress) {
      const filtered = courses.filter(
        (course) => course.Publish === filterCourses.courseProgress
      );
      return checkEmptyArray(filtered);
    }
    return courses;
  };

  const filterByLicenseType = (courses) => {
    if (filterCourses.LicenseTypeFK) {
      const newLicenseType = filterCourses.LicenseTypeFK.split(" ").pop();
      const filtered = courses.filter(
        (course) => course.Course.LicenseType.LicenseTypeName === newLicenseType
      );
      return checkEmptyArray(filtered);
    }
    return courses;
  };

  const sortByDate = (courses, sortOrder) => {
    if (sortOrder === "Old to New") {
      return courses.slice(); // No change, array is already in ascending order
    } else if (sortOrder === "New to Old") {
      return courses.slice().reverse(); // Reverse the array
    }
    return courses; // Default to no sorting
  };

  const checkEmptyArray = (filtered) => {
    return filtered && filtered.length > 0 ? filtered : [];
  };

  // console.log("Courses:", ApplyFilterCourses);

  // console.log("Courses,", ApplyFilterCourses);
  const allCourses = () => {
    setApplyFilterCourses(null);
  };

  return (
    <div className="max-w-[1300px] px-3 pt-5 2xs:px-4 xs:px-5 sm:px-6 md:px-7 lg:px-8 xl:px-9 2xl:px-10 3xl:px-11">
      {/* top */}
      {showPopup && (
        <CourseDeletePopup
          type={"course"}
          onConfirm={handleDeleteCourse}
          onCancel={() => setShowPopup(false)}
        />
      )}
      <div className="mr-4 flex justify-between px-4">
        <h3
          style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
          className="text-xs font-bold text-[#4C34AD] sm:text-sm md:text-base lg:text-[29px] xl:text-lg 2xl:text-xl"
        >
          Recent Courses
        </h3>

        <div></div>

        <div
          className="flex"
          style={{
            position: "relative",
            zIndex: isPopupVisible ? 1 : "auto",
          }}
        >
          <h2 className="mr-4">Filter</h2>
          <div>
            <span
              onMouseEnter={handleMouseEnter} //handleMouseEnter
              onMouseLeave={handleMouseLeave}
              class="material-symbols-outlined cursor-pointer font-extrabold"
              style={{ fontSize: "36px" }}
            >
              filter_list
            </span>

            {isPopupVisible && (
              <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="popup hoverPopup flex h-[580px] w-[380px] flex-col gap-8 px-10 py-10"
                style={{
                  position: "absolute",
                  top: "90%",
                  left: "-250px",
                  zIndex: 2,
                  backgroundColor: "white",
                }}
              >
                <h2 className=" text-2xs font-bold text-black md:text-2xs lg:text-sm 2xl:text-base">
                  Filter here
                </h2>
                <div className="">
                  <h2 className=" text-3xs font-semibold text-black md:text-3xs lg:text-xs 2xl:text-sm">
                    Course Status
                  </h2>
                  <div className="mt-3 shadow">
                    <Select
                      placeholder="In Progress"
                      onChange={(value) => onChange(value, "courseProgress")}
                      className="cursor-pointer"
                    >
                      {courseProgress?.map((value) => {
                        return (
                          <Option key={value} value={value}>
                            {value}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>

                {/* Popup content */}
                <div className="">
                  <h2 className="text-3xs font-semibold text-black md:text-3xs lg:text-xs 2xl:text-sm">
                    License Type
                  </h2>
                  <div className="mt-3 shadow">
                    <Select
                      placeholder="License type G"
                      onChange={(value) => onChange(value, "LicenseTypeFK")}
                      className="cursor-pointer"
                    >
                      {LicenseTypes?.map((value) => {
                        {
                          /* if (CourseData?.LicenseTypeFK === value?.LicenseTypeId)
                                        console.log(CourseData?.LicenseTypeFK) */
                        }
                        return (
                          <Option
                            key={value?.LicenseTypeId}
                            value={value?.LicenseTypeId}
                          >
                            L Type {value?.LicenseTypeName}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>

                <div className="">
                  <h2 className="text-3xs font-semibold text-black md:text-3xs lg:text-xs 2xl:text-sm">
                    Sort by Date Modified
                  </h2>
                  <div className="mt-3 shadow">
                    <Select
                      placeholder="Old to new"
                      onChange={(value) => onChange(value, "sortByDate")}
                      className="cursor-pointer"
                    >
                      {SortByDate?.map((value) => {
                        return (
                          <Option key={value} value={value}>
                            {value}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>

                <div className="mt-5 flex w-full items-center justify-center gap-2">
                  <div className="basis-[50%]">
                    <button
                      type="button"
                      class="focus:ring-blue-300 dark:bg-blue-600  dark:hover:bg-blue-700 dark:focus:ring-blue-800 flex  w-full  cursor-pointer items-center justify-center rounded-lg border-0 bg-[#4c34ad] px-2 py-2 text-6xs font-medium text-white outline-none hover:bg-[#a1a3ef] focus:outline-none focus:ring-4 md:text-4xs lg:text-4xs"
                    >
                      <span class="material-symbols-outlined ">search</span>
                      <span onClick={submitFilters} className="text-center ">
                        Apply Filter
                      </span>
                    </button>
                  </div>

                  <div className="basis-[50%]">
                    <button
                      onClick={allCourses}
                      type="button"
                      class="focus:ring-blue-300 dark:bg-blue-600  dark:hover:bg-blue-700 dark:focus:ring-blue-800 w-full cursor-pointer rounded-lg border-0 bg-[#4c34ad] px-2 py-2 text-6xs font-medium  text-white outline-none hover:bg-[#a1a3ef] focus:outline-none focus:ring-4 md:py-2  md:text-4xs"
                    >
                      Clear Filter
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className="mx-5">

        <div className="flex justify-between mr-9">
          <h3 className="text-[22px] font-medium leading-5">
            Recent Courses
          </h3>
          <button className="text-[20px] border-none leading-[18px] bg-[#A1A3EF] rounded-t-[12px] rounded-b-[16px] px-[31px] py-[14px] text-white">
            Show All
          </button>
        </div>
        <div className="flex gap-1 mt-[10px]">
          <CustomButton
            text="All Courses"
            OnClick={() => OnClick("All Courses")}
            CourseType={CourseType}
          />
          <CustomButton
            text="To be reviewed"
            OnClick={() => OnClick("To be reviewed")}
            CourseType={CourseType}
          />
          <CustomButton
            text="Approved"
            OnClick={() => OnClick("Approved")}
            CourseType={CourseType}
          />
          <CustomButton
            text="Rejected"
            OnClick={() => OnClick("Rejected")}
            CourseType={CourseType}
          />

          <CustomButton
            text="Vehicle Dashboard"
            OnClick={() => OnClick("Vehicle Dashboard")}
            CourseType={CourseType}
          />
        </div>
      </div> */}
      {/* recent courses list */}
      <div className="flex flex-col items-center bg-white px-2 py-[37px] xs:px-3  sm:px-4 md:px-5 lg:px-[21px]">
        {loading ? (
          <LoadingSpinner
            Position="fixed"
            Left="0"
            Bg="white"
            Height="screen"
            Width="full"
          />
        ) : Courses?.length === 0 ? (
          <NoDataResponse
            topText="Sorry, courses aren't published yet!"
            bottomText="Admin/Institute can add courses by click on the 'Create Course' button from Dashboard."
          />
        ) : (
          <CoursesTiles
            Courses={ApplyFilterCourses ? ApplyFilterCourses : Courses}
            OnClick={CourseEditClick}
            DeleteCourse={DeleteCourse}
          />
        )}
      </div>
    </div>
  );
}

export default RecentCourses;

const CustomButton = ({ text, CourseType, OnClick }) => {
  const BgColor = CourseType !== text ? "bg-white" : "bg-[#C7DDFC]";
  return (
    <button
      className={`${BgColor} w-[19%] cursor-pointer rounded-t-[12px] border-none px-[25px] py-[31px] text-center text-3xs text-[#576271]`}
      onClick={OnClick}
    >
      {text}
    </button>
  );
};

const CourseDeletePopup = ({ popupDetail, onCancel, onConfirm, type }) => {
  return (
    <div className="fixed left-0 top-0 z-30 flex h-screen w-screen items-center justify-center bg-[#00000029]">
      <div className="relative flex aspect-[353_/_221] h-fit w-full max-w-[360px] flex-col gap-[10px] rounded-[20px] bg-white px-8 py-5">
        <div className="flex flex-col items-center gap-3">
          <h3 className="HeadingBorder text-5xs font-normal">
            Delete Course !!
          </h3>
          <h3 className="mt-10 text-4xs font-normal ">
            Are you sure you want to delete
          </h3>
        </div>
        <div className="mt-5 flex flex-col items-center gap-[6px]">
          {/* )} */}
          <div className="flex items-center justify-center gap-3">
            <button
              type="button"
              className="BrandingButton SemiBold w-fit cursor-pointer rounded-[14px] py-1 px-8 text-6xs"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="SemiBold w-fit cursor-pointer  rounded-[14px] bg-[#4C34AD] py-1 px-8 text-6xs text-white"
              onClick={onConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
