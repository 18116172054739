// react States
import React, { Component, useState } from "react";
import { CheckInput } from "../Helpers/InputCheckers";

// css
// import "../Pricing.css";

const CourseDescription = ({
  CourseDetailsData,
  setCourseDetailsData,
  CourseDetailsErr,
  setCourseDetailsErr,
  update,
}) => {
  const [TextLength, setTextLengthc] = useState({});
  const OnChangeCheck = (e, Key, MaxLength, MinLength, Name) => {
    CheckInput(
      e,
      Key,
      MaxLength,
      MinLength,
      Name,
      true,
      CourseDetailsData,
      setCourseDetailsData,
      CourseDetailsErr,
      setCourseDetailsErr,
      TextLength,
      setTextLengthc
    );
  };

  return (
    <div className="">
      {/* addCourse2Main */}
      <div className="">
        {/* col5Descriptions */}
        {/* <div className="row"> */}
        <div className=" mt-10 flex flex-col   rounded-[10px] border border-solid border-[#D4D4D4] p-5">
          <div className="installmentsTxt">
            <h2 className="font-semibold text-[#4C34AD]">Short Description</h2>
            <p className="text-[20px]  text-[#4C34AD]">
              Enter a short detail about <br /> the course
            </p>
          </div>
          <div className="flex items-center justify-center">
            <div className=" mt-10 w-[85%]">
              <textarea
                // className="addCourse2Texterea1 resize-none"
                className="courseDetail min-h-[100px] w-[100%] resize-none p-2 text-sm "
                disabled={update}
                onChange={(e) =>
                  OnChangeCheck(
                    e,
                    "ShortDescription",
                    50,
                    0,
                    "Short Description"
                  )
                }
                value={CourseDetailsData?.ShortDescription}
              ></textarea>
              <h3 className="h-3 font-normal text-[red]">
                {CourseDetailsErr.ShortDescription}
              </h3>
              <p className="addCourse2Texterea1WordsCount">
                {!TextLength?.ShortDescription
                  ? 0
                  : TextLength?.ShortDescription}{" "}
                / 50 characters
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className=""> */}
      <div className=" mt-10 flex flex-col   rounded-[10px] border border-solid border-[#D4D4D4] p-5">
        <div className="installmentsTxt">
          <h2 className="font-semibold text-[#4C34AD]">Long Description</h2>
          <p className="text-[20px]  text-[#4C34AD]">
            Enter a detailed detail about <br /> the course
          </p>
        </div>
        {/* <div className="col-7"> */}
        <div className="flex items-center justify-center">
          <div className=" mt-10 w-[85%]">
            <textarea
              // className="addCourse2Texterea1 addCourse2Texterea2 resize-none"

              className="courseDetail min-h-[200px] w-[100%] resize-none p-2 text-sm"
              onChange={(e) =>
                OnChangeCheck(e, "LongDescription", 1500, 0, "Long Description")
              }
              disabled={update}
              value={CourseDetailsData?.LongDescription}
            ></textarea>
            <h3 className="h-3 font-normal text-[red]">
              {CourseDetailsErr.LongDescription}
            </h3>
            <p className="addCourse2Texterea1WordsCount">
              {!TextLength?.LongDescription ? 0 : TextLength?.LongDescription} /
              1500 characters
            </p>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default CourseDescription;
