export const validateData =(awardData)=> {
    const errors = {};
        
    if (!awardData.AwardThumbnail) {
        errors.AwardThumbnail = "AwardThumbnail cannot be empty";
    }
    
    if (!awardData.Title) {
        errors.Title = "Title cannot be empty";
    }

    if (!awardData.Description) {
        errors.Description = "Description cannot be empty";
    }

    return errors;
}