import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { BaseUrl } from "../../../../Actions/Base";
import { SetImagePreview } from "../../../../Helpers/ImagePreview/ImagePreview";
import { RemoveImage } from "./Helpers/ClickEvent";
import { DragLeave, DragOver, Drop } from "./Helpers/DragEvents";
import { OnImageChange } from "./Helpers/OnChangeEvent";
import "./steps.css";

const VehicleImages = ({
    VehicleImagesState,
    setVehicleImagesState,
    readOnly,
}) => {
    return (
        <div className="back mt-10 w-11/12 py-6 px-0 sm:bg-white sm:px-4 md:px-8 lg:px-12 xl:px-16 2xl:px-[75px]">
            <div className="flex w-full flex-col items-center">
                <h2 className="self-start text-sm font-bold text-[#4C34AD]">
                    Vehicle Gallery (Upload Images)
                </h2>
                <p className="mt-2 self-start text-5xs">
                    Select minimum 1 image
                </p>
            </div>
            <div className="mt-12 flex w-full flex-col flex-wrap items-center justify-evenly gap-10 sm:flex-row">
                {new Array(6).fill(0).map((value, index) => (
                    <ImageSelector
                        readOnly={readOnly}
                        key={index}
                        Index={index}
                        Name={"Image" + ++index}
                        setVehicleImagesState={setVehicleImagesState}
                        VehicleImagesState={VehicleImagesState}
                    />
                ))}
            </div>
        </div>
    );
};

function ImageSelector({
    Index,
    Name,
    setVehicleImagesState,
    VehicleImagesState,
    readOnly,
}) {
    const [Image, setImage] = useState();
    const [Load, setLoad] = useState(false);

    useEffect(() => {
        if (VehicleImagesState[Index]?.[Name]) {
            SetImagePreview(setImage, VehicleImagesState[Index]?.[Name]);
            setLoad(true);
        }
    }, [VehicleImagesState, Name, Index, Load]);

    return (
        <div
            className={`DragAndDrop flex aspect-[8_/_7] w-11/12 min-w-[260px] max-w-[405px]
                            flex-col   gap-8 
                            bg-[#ECECEC]    p-5
                            xl:w-4/5`}
        >
            <p className="text-3xs text-[#4D4F5C]">Upload</p>
            <div
                className="ChangeBg relative flex h-full flex-col items-center justify-center gap-5 overflow-hidden bg-[#FFFFFF]"
                onDragOver={DragOver}
                onDragLeave={DragLeave}
                onDragEnd={DragLeave}
                onDrop={(e) =>
                    Drop(
                        e,
                        VehicleImagesState,
                        setVehicleImagesState,
                        setImage,
                        Index
                    )
                }
            >
                {!VehicleImagesState[Index]?.VehicleImageLink &&
                !VehicleImagesState[Index]?.[Name] ? (
                    <>
                        <input
                            disabled={readOnly}
                            type="file"
                            className="hidden"
                            name={Name}
                            id={`Img${Index}`}
                            onChange={
                                !readOnly
                                    ? (e) =>
                                          OnImageChange(
                                              e,
                                              VehicleImagesState,
                                              setVehicleImagesState,
                                              setImage,
                                              Index
                                          )
                                    : null
                            }
                        />

                        <label htmlFor={`Img${Index}`}>
                            <img
                                className="HideOnDrag w-36 cursor-pointer"
                                src={
                                    require("../Assets/UploadIcon.svg").default
                                }
                                alt=""
                            />
                        </label>
                        <span className="HideOnDrag flex flex-col items-center">
                            <p className="text-base text-[#4D4F5C]">
                                DRAG & DROP
                            </p>
                            <p className="text-3xs text-[#4D4F5C]">
                                your files to assets, or &nbsp;
                                <label
                                    htmlFor={`Img${Index}`}
                                    className="SemiBold cursor-pointer text-3xs text-[#3B86FF] underline"
                                >
                                    browse
                                </label>
                            </p>
                        </span>
                    </>
                ) : (
                    <>
                        <span className="flex items-center justify-center">
                            {Image === undefined ? (
                                <img
                                    className="w-full"
                                    src={`${BaseUrl}/api/vehicleimage?url=${VehicleImagesState[Index]?.VehicleImageLink}`}
                                    alt=""
                                />
                            ) : (
                                <img className="w-full" src={Image} alt="" />
                            )}
                        </span>
                        <span
                            className="absolute right-0 top-0 flex cursor-pointer items-center justify-center rounded-full bg-violet-100 p-2"
                            onClick={
                                !readOnly
                                    ? (e) =>
                                          RemoveImage(
                                              e,
                                              VehicleImagesState,
                                              setVehicleImagesState,
                                              Index,
                                              Name
                                          )
                                    : null
                            }
                            // onClick={(e) =>
                            //     RemoveImage(
                            //         e,
                            //         VehicleImagesState,
                            //         setVehicleImagesState,
                            //         Index,
                            //         Name
                            //     )
                            // }
                        >
                            <img
                                className="pointer-events-none"
                                src={
                                    require("../../../../Components/Header/Components/StudentHeader/Components/RightSide/Assets/CrossIcon.svg")
                                        .default
                                }
                                alt=""
                            />
                        </span>
                    </>
                )}
            </div>
        </div>
    );
}
export default VehicleImages;
