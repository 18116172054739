import React, { useState } from "react";
import DropDown from "../../../../Components/custom-drop-down/DropDown";

const StaffFilterArr = ({
    setSortBy,
    setSortByTime,
    setSearchQuery,
    setSuspend,
    suspend,
    sortByTime,
    sortBy,
    searchQuery,
    handleApplyClick,
}) => {
    const [filtersApplied, setFiltersApplied] = useState(false); // New state t
    // console.log("Data", data);
    const IconSrc = require("../Assets/DropDown.svg").default;
    // const [status, setStatus] = useState(null);
    // const [sortByDateModified, setSortByDateModified] = useState(null);
    // const [filterByAlphabeticOrder, setFilterByAlphabeticOrder] =

    //     useState(null);

    // const [status, setStatus] = useState(false);
    // const [sortByDateModified, setSortByDateModified] = useState("newest");
    // const [filterByAlphabeticOrder, setFilterByAlphabeticOrder] =
    //     useState("a_to_z");

    const statusOptions = [
        { value: true, label: "Suspend" },
        { value: false, label: "In Progress" },
    ];

    const sortByDateModifiedOptions = [
        { value: "newest", label: "New to Old" },
        { value: "oldest", label: "Old to New" },
    ];

    const filterByAlphabeticOrderOptions = [
        { value: "atoz", label: "A to Z" },
        { value: "ztoa", label: "Z to A" },
    ];

    const handleStatusChange = (value) => {
        console.log("setsuspendtriggered", value);
        setSuspend(value);
    };

    const handleSortByDateModifiedChange = (value) => {
        console.log("setModifiedDaa", value);

        // setSortByDateModified(value);
        setSortByTime(value);
    };

    const handleFilterByAlphabeticOrderChange = (value) => {
        // setFilterByAlphabeticOrder(value);
        setSortBy(value);
    };

    const handleSearch = (e) => {
        // e.preventDefault();
        console.log("searchqueryvalue", e.target.value);
        setSearchQuery(e.target.value);
    };

    return (
        <>
            <div className="">
                {/* <h4 className="text-left text-4xs font-normal text-[#22232B]">
                    Status
                </h4> */}
                <input
                    type="text"
                    placeholder="Search"
                    className="TeacherFilterFieldsBorder w-full p-2 text-5xs"
                    onChange={handleSearch}
                    value={searchQuery}
                />
            </div>
            <span className="flex w-full flex-col">
                {/* <h4 className=" text-left text-4xs font-normal text-[#22232B]">
                    Status
                </h4> */}
                <DropDown
                    Label="Status"
                    DropDownOptions={statusOptions}
                    // Name="Status"
                    TextStyle="text-left text-[#22232B] text-5xs TeacherFilterFieldsBorder"
                    styles="w-full min-w-[275px]"
                    IconSrc={IconSrc}
                    onChange={handleStatusChange}
                    StateValue={suspend}
                />
            </span>
            <span className="flex w-full flex-col">
                {/* <h4 className=" text-left text-4xs font-normal text-[#22232B]">
                    Sort by Date Modified
                </h4> */}
                <DropDown
                    Label="New or Old"
                    DropDownOptions={sortByDateModifiedOptions}
                    // Name="DateModified"
                    TextStyle="text-left text-[#22232B] text-5xs TeacherFilterFieldsBorder"
                    styles="w-full min-w-[275px]"
                    IconSrc={IconSrc}
                    onChange={handleSortByDateModifiedChange}
                    StateValue={sortByTime}
                />
            </span>
            <span className="flex w-full flex-col">
                <DropDown
                    Label="Filter by Alphabetic Order"
                    DropDownOptions={filterByAlphabeticOrderOptions}
                    // Name="AlphabeticalOrder"
                    // Name="Filter by Alphabetic Order"
                    TextStyle="text-left text-[#22232B] text-5xs TeacherFilterFieldsBorder"
                    styles="w-full min-w-[275px]"
                    IconSrc={IconSrc}
                    onChange={handleFilterByAlphabeticOrderChange}
                    StateValue={sortBy}
                />
            </span>
        </>
    );
};

export default StaffFilterArr;
