import React, { useRef, useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Button,
  IconButton,
  useMediaQuery 
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { verifingOtp } from "./api";
import { GetStudentNumberAndSendOTP } from "../../Actions/InstructorA";
import { useDispatch } from "react-redux"; // For dispatching actions
import { GetBypassOtpFeedBackUpdate } from "../../Actions/InstructorA";


const BypassOTPModal = ({ open, setOpenBypassOTPModal, setOpenCamera, setOpenOTPModal,
   setOtpAPIResponse, EventId }) => {
    const isMobile = useMediaQuery("(max-width:635px)");
    const [otpBypassReason, setOtpBypassReason] = useState(""); // Track selected radio button
    const [otpBypassFeedback, setOtpBypassFeedback] = useState(""); // Track textarea input
    console.log("EventId in bypass is:",EventId)
    const Dispatch = useDispatch();

    // Determine if the button should be disabled
      const isButtonDisabled = otpBypassReason === "" || 
      (otpBypassReason === "Other/Explain reason" && otpBypassFeedback.trim() === "");


    const handleBypassModalClose = () => {
        setOpenBypassOTPModal(false)
    }

    const handleBypassBackClick = () => {
      setOpenOTPModal(true)
      setOpenBypassOTPModal(false)
  }

  const handleBypassSubmit = async () => {
    setOpenCamera(true);

    // Prepare data to be sent to the backend
    let bypassOtpFeedbackData = {
        bypassReason: otpBypassReason, // Send the selected radio button reason
    };

    // If "Other" is selected, include the textarea feedback
    if (otpBypassReason === "Other/Explain reason" && otpBypassFeedback.trim() !== "") {
        bypassOtpFeedbackData.bypassOtpFeedback = otpBypassFeedback; // Include feedback
    }

    await Dispatch(GetBypassOtpFeedBackUpdate(EventId, bypassOtpFeedbackData));


    // After successful feedback submission, execute further actions
    setOtpAPIResponse("verified");
    setOpenBypassOTPModal(false);

    // try {
    //     // Await Redux dispatch so it completes before proceeding
    //     const response = await Dispatch(GetBypassOtpFeedBackUpdate(EventId, bypassOtpFeedbackData));

    //     console.log("Feedback update successful:", response);

    //     // After successful feedback submission, execute further actions
    //     setOtpAPIResponse("verified");
    //     setOpenBypassOTPModal(false);
        
    // } catch (error) {
    //     console.error("Failed to submit feedback:", error);
    //     // Handle the error if needed, e.g., show an error message to the user
    // }
};


  return (
    <Dialog open={open} aria-labelledby="dialog-title"
    PaperProps={{
        style: {
          // width: "35%", // Set dialog width to 30% of the screen
          width: isMobile ? "98%" : "28%", // Change width to 80% for mobile screens, 35% otherwise
          borderRadius: "19px", // Border radius of 20px
          paddingInline: isMobile ? "5px" : "",
        },
      }}>
      <DialogTitle
        id="dialog-title"
        className="w-full flex justify-center relative"
        style={{ textAlign: "center", marginBottom: "0px" }} // Center the title text
      >
        <span className="text-center mt-[10px] font-bold">OTP verification Bypass</span>
        <IconButton
          onClick={handleBypassModalClose}
          size="small"
          // sx={{ backgroundColor: "transparent" }}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            backgroundColor: "transparent",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
        <DialogContent style={{ marginTop: "-10px" }}>
        <Typography className="lg:hidden text-center mt-[-10px]">
            Please provide a reason, why do you want to bypass OTP.
          </Typography>
          <Typography className="hidden lg:block text-center mt-[-10px]">
            Please provide a reason, why do you want to 
          </Typography>
          <Typography className="hidden lg:block text-center">
             bypass OTP.
          </Typography>
           {/* Radio buttons for selection */}
            <div className="w-full flex flex-col mt-4">
              <label className="mb-2 text-[16px] lg:text-[18px] text-[#464646]" >
                <input
                  type="radio"
                  value="The student didn't received OTP."
                  checked={otpBypassReason === "The student didn't received OTP."}
                  onChange={(e) => setOtpBypassReason(e.target.value)}
                  className="mr-[10px]"
                />
                The student didn't received OTP.
              </label>
              <label className="mb-2 text-[16px] lg:text-[18px] text-[#464646]">
                <input
                  type="radio"
                  value="The student don't have a phone."
                  checked={otpBypassReason === "The student don't have a phone."}
                  onChange={(e) => setOtpBypassReason(e.target.value)}
                  className="mr-[10px]"
                />
                The student don't have a phone.
              </label>
              <label className="mb-2 text-[16px] lg:text-[18px] text-[#464646]">
                <input
                  type="radio"
                  value="Other/Explain reason"
                  checked={otpBypassReason === "Other/Explain reason"}
                  onChange={(e) => setOtpBypassReason(e.target.value)}
                  className="mr-[10px]"
                />
                Other/Explain reason
              </label>
            </div>

            {/* Conditionally show textarea if "Other" is selected */}
            {otpBypassReason === "Other/Explain reason" && (
              <div className="w-full flex justify-center items-center">
                <textarea
                  className="mt-4 h-24 w-[95%] lg:w-[90%] resize-none rounded-md border-2 border-[#4C34AD] p-2 focus:outline-none"
                  placeholder="Please provide additional details."
                  value={otpBypassFeedback}
                  onChange={(e) => setOtpBypassFeedback(e.target.value)}
                />
              </div>
            )}
        </DialogContent>
        <DialogActions>
          <div className="flex gap-[10px] lg:gap-[15px] w-full justify-center pb-5">
          <button
              className="w-[120px] lg:w-[150px] h-[35px] lg:h-[40px] rounded-[12px] text-[16px] lg:text-[20px]"
              style={{ border: "1px solid #B5AAFF" }}
              onClick={handleBypassBackClick}
            >
              Back
            </button>

            <button
              className="w-[120px] lg:w-[150px] h-[35px] lg:h-[40px] rounded-[12px] text-[16px] lg:text-[20px] text-[#FFFFFF] bg-[#B5AAFF]"
              onClick={handleBypassSubmit}
              disabled={isButtonDisabled} // Disable the button based on condition
            >
              Bypass
            </button>

            {/* <Button
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: "#B5AAFF", // Apply your color
                color:"#FFFFFF",
                width:"150px",
                height:"40px",
                fontSize:"20px"
              }}
            >
              Bypass
            </Button> */}
          </div>
        </DialogActions>
    </Dialog>
  );
};

export default BypassOTPModal;
