import React from "react";
import { BaseUrl } from "../../../../../Actions/Base";

const EnrolledCourseDetails = ({ InstituteCourse }) => {
    return (
        <div className="flex w-[64%] flex-col items-center gap-5 md:flex-row">
            <span className="flex h-full max-h-[150px] w-4/5 max-w-[230px] items-center justify-center overflow-hidden">
                <img
                    className="w-full"
                    src={
                        InstituteCourse
                            ? `${BaseUrl}/api/vehicletype/image?url=${InstituteCourse?.Course?.CourseThumbnail}`
                            : null
                    }
                    alt="Course Thumbnail"
                />
            </span>
            <CorseDetailsText InstituteCourse={InstituteCourse} />
        </div>
    );
};

function CorseDetailsText({ InstituteCourse }) {
    let Description = InstituteCourse?.Course?.Description;
    if (Description?.length >= 220)
        Description = Description.split("").slice(0, 215).join("") + "...";
    let CourseName = InstituteCourse?.Course?.CourseName;
    if (CourseName?.length >= 40)
        CourseName = CourseName.split("").slice(0, 37).join("") + "...";

    return (
        <span className="flex flex-col    items-center justify-between gap-2 sm:gap-5 md:gap-8 lg:gap-11 xl:gap-14 2xl:gap-16">
            <h3
                className={`Boldfont           text-center  text-3xs
                        md:text-2xs    lg:text-start                 xl:text-xs `}
            >
                {CourseName ? CourseName : "Automotive Course"}
            </h3>

            <p
                className={`w-11/12
                       max-w-[650px]      text-center  text-4xs 
                       sm:w-11/12                 md:w-auto 
                       md:text-3xs                           lg:text-start`}
            >
                {Description
                    ? Description
                    : "Class G. Allowed to drive any car, van or small truck or combination of vehicle and towed vehicle up to 11,000 kilograms provided the vehicle towed is not over 4,600 kilograms"}
            </p>
        </span>
    );
}

export default EnrolledCourseDetails;
