import React from "react";

const StudentReviews = ({ StudentReviewRef }) => {
  const text = [
    {img: require("./Assets/gettyimages-1318535969-612x612.jpg"),
    name : "Sarah Patel",
    des : "VLI made my driving education straightforward and stress-free. Being able to schedule lessons and see my progress in one place was fantastic. It really boosted my confidence knowing I was making steady progress."
  },
  {
    img: require("./Assets/User Image.png"),
    name: "Jessica Li",
    des: "VLI has completely changed my driving education experience. Everything I needed was right at my fingertips, from lesson scheduling to progress updates. I felt more organized and confident, and the support from my instructors was amazing."
  },
  {
    img: require("./Assets/1.jpg"),
    name: "Michael Brown",
    des: "Enrolling in driving courses through VLI was the best decision I made. The platform is user-friendly and made scheduling lessons around my schoolwork effortless. Keeping track of my progress was motivating and helped me stay on top of my learning goals."
  }
]
  return (
    <div
      className="flex flex-col items-center gap-10 pt-10 pb-40"
      ref={StudentReviewRef}
    >
      <h2 className="font-bold text-[38px] text-black pb-4 ">
        Students Reviews
      </h2>
      <div className="flex w-[95%] flex-wrap justify-center gap-x-6 gap-y-16">
        <div className="RevewTileShadow relative flex h-fit max-w-[500px] flex-col items-start gap-5 rounded-[18px] bg-white  px-10 pt-12 pb-4 ">
          <div className="w-full">
          <TopSection data={text[0]} />
          </div>
          <div>
            <div className="flex flex-col gap-3">
              <img
                className="absolute left-[43px]  -top-[-5.8rem] w-8"
                src={require("./Assets/QuoteIcon.svg").default}
                alt=""
              />
              <UserReview data={text[0]}   />
              <div className="flex w-full justify-center">
                <StarIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="RevewTileShadow relative flex h-fit max-w-[500px] flex-col items-start gap-5 rounded-[18px] bg-white  px-10 pt-12 pb-4 ">
          <div className="w-full">
          <TopSection data={text[1]} />
          </div>
          <div>
            <div className="flex flex-col gap-3">
              <img
                className="absolute left-[43px]  -top-[-5.8rem] w-8"
                src={require("./Assets/QuoteIcon.svg").default}
                alt=""
              />
              <UserReview data={text[1]} />
              <div className="flex w-full justify-center">
                <StarIcon   />
              </div>
            </div>
          </div>
        </div>
        <div className="RevewTileShadow relative flex h-fit max-w-[500px] flex-col items-start gap-5 rounded-[18px] bg-white  px-10 pt-12 pb-4 ">
          <div className="w-full">
          <TopSection data={text[2]} />
          </div>
          <div>
            <div className="flex flex-col gap-3">
              <img
                className="absolute left-[43px]  -top-[-5.8rem] w-8"
                src={require("./Assets/QuoteIcon.svg").default}
                alt=""
              />
              <UserReview  data={text[2]} />
              <div className="flex w-full justify-center">
                <StarIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
function UserImageName() {
  return (
    <div className="relative -left-5 flex w-fit items-center gap-3">
      <div className="h-20 w-20 overflow-hidden rounded-[40px]">
        <img src={require("./Assets/User Image.png")} alt="" />
      </div>
      <div className="flex flex-col items-start gap-2">
        <span className="flex flex-col items-start">
          <h4 className="text-2xs font-normal">Ayesha</h4>
          <h4 className="text-2xs font-normal text-[#848484]">
            Project Manager
          </h4>
        </span>

        <StarIcon />
      </div>
    </div>
  );
}
function TopSection({data}){
    return(
        <>
        <div className="absolute top-[-36px] left-[42%] h-20 w-20 overflow-hidden rounded-[40px]">
              <img
                className="h-full w-full"
                src={data.img}
                alt=""
              />
            </div>
            <div className="flex w-full justify-center">
              <div className="flex w-full flex-col items-start justify-center gap-2">
                <h4 className="self-center text-2xs text-[#424242]  font-normal">
                  {data.name}
                </h4>
                <h4 className="self-center text-2xs font-normal text-[#848484]">
                  VLI Client
                </h4>
              </div>
            </div>
        </>
    )
}

function UserReview({data}) {
  return (
    <>
      <span className="flex">
        <p className="max-w-[350px] text-[#848484] indent-12">
          {data.des}
        </p>
      </span>
    </>
  );
}

function StarIcon() {
  return (
    <div className="flex w-fit gap-1">
      <img
        className="w-5"
        src={require("../CourseIntro/Assets/StarIcon.svg").default}
        alt=""
        srcSet=""
      />
      <img
        className="w-5"
        src={require("../CourseIntro/Assets/StarIcon.svg").default}
        alt=""
        srcSet=""
      />
      <img
        className="w-5"
        src={require("../CourseIntro/Assets/StarIcon.svg").default}
        alt=""
        srcSet=""
      />
      <img
        className="w-5"
        src={require("../CourseIntro/Assets/StarIcon.svg").default}
        alt=""
        srcSet=""
      />
      <img
        className="w-5"
        src={require("../CourseIntro/Assets/StarIcon.svg").default}
        alt=""
        srcSet=""
      />
    </div>
  );
}
export default StudentReviews;
